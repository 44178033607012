import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentBannerPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-admin-edit-data-list',
  templateUrl: './editDataList.component.html',
  styleUrls: ['./editDataList.component.scss']
})
export class EditDataListComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  isLoadingUsersData: boolean = false;
  getUsersData$: Subscription = new Subscription;
  usersData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 15,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 30,
      title: 30
    },
  ]
  selectedRows: { id: number, title: number };
  structureForModal: any;
  searchedText: string = '';
  userProcessModalData: any;


  // Filtri
  isLoadingFilters: boolean = true;
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};
  genderFilterTooltipModal: ApplicationModalMessage = {
    modalId: 'cal021',
    title: '',
    text: ''
  }
  selectedFiltersNumber: number = 0;
  areaTooltipModal: ApplicationModalMessage = {
    modalId: 'cal023',
    title: '',
    text: ''
  }
  structureLevel5TooltipModal: ApplicationModalMessage = {
    modalId: 'cal024',
    title: '',
    text: ''
  }

  whoTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal014',
    title: '',
    text: ''
  };
  structureTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal015',
    title: '',
    text: ''
  };
  structureLevel3TooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal016',
    title: '',
    text: ''
  };
  jobTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal017',
    title: '',
    text: ''
  };
  functionTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal019',
    title: '',
    text: ''
  };
  isFilterOpened: boolean = false;
  translations: any;
  // fine filtri

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    public urlService: UrlService,
    private router: Router,
  ) {

    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const runningYear$: Observable<number> = this.store.select(fromApp.getRunningYear);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, runningYear$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningYear, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.runningYear = runningYear;
            this.translate.get([
              'filter.ALL',
              'filter.M',
              'filter.F',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_PROFESSIONAL',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_PROFESSIONAL_NO_COMPANY',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_MANAGERS',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_TEAM_LEADER',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_PLATFORM_MANAGER',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_RETAIL',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF_STI_TEAM_LEADER_NO_ROLLING',
              'editDataList.roles.PERFORMANCECARE_USER_STAFF',
              'editDataList.roles.PERFORMANCECARE_USER_OPERATIONS',
            ]).subscribe((translations) => {
              this.translations = translations;
              this.getFilters()
              this.getUsersData();
            })
          }
        });
  }

  ngOnInit() {
  }


  getUsersData() {
    this.isLoadingUsersData = true;
    if (this.getUsersData$) {
      this.getUsersData$.unsubscribe();
    }
    let parsedFilters = this.parseFilters();
    this.getUsersData$ = this.adminService.countUserInProcess(this.runningYear.toString(), [], this.searchedText, undefined, undefined, parsedFilters.denominazioneSO3, parsedFilters.denominazioneSO5,
      parsedFilters.funzione, parsedFilters.mansione, parsedFilters.area, parsedFilters.chiaveSesso, undefined, parsedFilters.structures)
      .pipe(
        switchMap(
          (counter: SenecaResponse<any>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersData.counter = counter.response;
              // 
              // Calcolo la paginazione
              this.usersData.fromRecord = (this.usersData.page - 1) * this.usersData.numRecords;

              if (this.usersData.counter) {
                return this.adminService.listUserInProcess(this.runningYear.toString(), this.usersData.fromRecord, this.usersData.numRecords, [], this.searchedText, undefined, undefined,
                  parsedFilters.denominazioneSO3, parsedFilters.denominazioneSO5, parsedFilters.funzione, parsedFilters.mansione, parsedFilters.area,
                  parsedFilters.chiaveSesso, undefined, parsedFilters.structures);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a009",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsersData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a010",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // "calcolo" il ruolo che arriva per anno
            // i profili arrivano come un array di chiavi per anno
            data.response.forEach((user: any) => {
              // Controllo se l'utente ha l'sti
              if (user.stiAmountByYear && Object.keys(user.stiAmountByYear).length > 0 && user.stiAmountByYear[this.runningYear] > 0) {
                user.isSti = true;
              } else {
                user.isSti = false;
              }
              if (user.profileByYear && Object.keys(user.profileByYear).length > 0, user.profileByYear[this.runningYear]) {
                user.currentRole = this.translate.instant("profiles." + user.profileByYear[this.runningYear]);
              }
            })
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.usersData.list = data.response || [];
          }
          this.isLoadingUsersData = false;
        }
        , (err: any) => {
          this.isLoadingUsersData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a011",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.usersData.page = page;
    this.usersData.list = [];
    this.getUsersData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersData.numRecords = item.id;
    this.usersData.list = [];
    this.usersData.fromRecord = 0;
    this.usersData.page = 1;
    this.getUsersData();
  }

  // resetta la paginazione
  restPagination() {
    this.usersData.list = [];
    this.usersData.fromRecord = 0;
    this.usersData.page = 1;
  }

  getModalDescription(feedback: any, translations: any) {
    let text = '';
    if (feedback) {
      text += translations['monitoring.modals.PEER_REC'] + ' <b>' + '-' + '</b><br>';
      text += translations['monitoring.modals.PEER_REQ'] + ' <b>' + '-' + '</b><br>';
      text += translations['monitoring.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseFeedbacks || '-') + '</b><br>';
      text += translations['monitoring.modals.INTERVIEW'] + ' <b>' + (feedback.feedbackDialogueRentCount || '-') + '</b><br>';
      text += translations['monitoring.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceFeedbacks || '-') + '</b><br>';
    }
    return text;
  }

  orderBy(fieldName: string) {
  }

  onSearch() {
    this.restPagination();
    this.getUsersData();
  }

  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onActionClicked(userId: string) {
    this.router.navigate(["admin/editData/" + userId]);
  }


  openUserProcessModal(user: any) {
    this.userProcessModalData = user;
    this.modalService.open('userProcessInfo');
  }

  closeUserProcessModal() {
    this.modalService.close('userProcessInfo')
    this.userProcessModalData = null;
  }


  getIsProcessActive(process: any) {
    if (process && process.phases && process.phases) {
      let activePhases = process.phases.filter((phase: any) => phase.statusType !== "PHASE_INACTIVE");
      if (activePhases && activePhases.length) {
        return this.translate.instant('impersonate.modals.ACTIVE');
      }
    }
    return this.translate.instant('impersonate.modals.INACTIVE');
  }


  // recupera il distinct dei filtri disponibili
  getFilters() {
    this.isLoadingFilters = true;

    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }

    this.getFiltersData$ = this.adminService.getDistinctFilters(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let all = { id: 'all', title: this.translations['filter.ALL'], value: null }
          let gender = [all, { id: 'M', title: this.translations['filter.M'], value: 'M' }, { id: 'F', title: this.translations['filter.F'], value: 'F' }]
          let denominazioneSO3: any = [all]
          if (data.response.denominazioneSO3 && data.response.denominazioneSO3.length) {
            for (let i = 0; i < data.response.denominazioneSO3.length; i++) {
              denominazioneSO3.push({
                id: data.response.denominazioneSO3[i],
                title: data.response.denominazioneSO3[i],
                value: data.response.denominazioneSO3[i]
              })
            }
          }
          let denominazioneSO5: any = [all]
          if (data.response.denominazioneSO5 && data.response.denominazioneSO5.length) {
            for (let i = 0; i < data.response.denominazioneSO5.length; i++) {
              denominazioneSO5.push({
                id: data.response.denominazioneSO5[i],
                title: data.response.denominazioneSO5[i],
                value: data.response.denominazioneSO5[i]
              })
            }
          }
          let funzione: any = [all]
          if (data.response.funzione && data.response.funzione.length) {
            for (let i = 0; i < data.response.funzione.length; i++) {
              funzione.push({
                id: data.response.funzione[i],
                title: data.response.funzione[i],
                value: data.response.funzione[i]
              })
            }
          }
          let mansione: any = [all]
          if (data.response.mansione && data.response.mansione.length) {
            for (let i = 0; i < data.response.mansione.length; i++) {
              mansione.push({
                id: data.response.mansione[i],
                title: data.response.mansione[i],
                value: data.response.mansione[i]
              })
            }
          }
          let area: any = [all]
          if (data.response.area && data.response.area.length) {
            for (let i = 0; i < data.response.area.length; i++) {
              area.push({
                id: data.response.area[i],
                title: data.response.area[i],
                value: data.response.area[i]
              })
            }
          }
          let structures: any = [all]
          if (data.response.structures && data.response.structures.length) {
            for (let i = 0; i < data.response.structures.length; i++) {
              structures.push({
                id: data.response.structures[i].structureId,
                title: data.response.structures[i].title,
                value: data.response.structures[i].structureId
              })
            }
          }
          this.filtersObject = {
            chiaveSesso: gender,
            denominazioneSO3: denominazioneSO3,
            denominazioneSO5: denominazioneSO5,
            funzione: funzione,
            mansione: mansione,
            area: area,
            structures: structures
          }
          this.selectedFilters = {
            chiaveSesso: this.filtersObject.chiaveSesso[0],
            denominazioneSO3: this.filtersObject.denominazioneSO3[0],
            denominazioneSO5: this.filtersObject.denominazioneSO5[0],
            funzione: this.filtersObject.funzione[0],
            mansione: this.filtersObject.mansione[0],
            area: this.filtersObject.area[0],
            structures: this.filtersObject.structures[0],
          }
          this.selectedFiltersNumber = 0;
        }
        this.isLoadingFilters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFilters = false;
      });
  }


  selectFilter(data: any, id: any) {
    if (data.id == 'all') {
      this.selectedFiltersNumber--;
    }
    switch (id) {
      case 'structures':
        if (!this.selectedFilters.structures.value && this.selectedFilters.structures != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.structures = data;
        break;
      case 'denominazioneSO3':
        if (!this.selectedFilters.denominazioneSO3.value && this.selectedFilters.denominazioneSO3 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO3 = data;
        break;
      case 'denominazioneSO5':
        if (!this.selectedFilters.denominazioneSO5.value && this.selectedFilters.denominazioneSO5 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO5 = data;
        break;
      case 'mansione':
        if (!this.selectedFilters.mansione.value && this.selectedFilters.mansione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.mansione = data;
        break;
      case 'funzione':
        if (!this.selectedFilters.funzione.value && this.selectedFilters.funzione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.funzione = data;
        break;
      case 'area':
        if (!this.selectedFilters.area.value && this.selectedFilters.area != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.area = data;
        break;
      case 'sti':
        if (!this.selectedFilters.sti.value && this.selectedFilters.sti != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.sti = data;
        break;
      case 'chiaveSesso':
        if (!this.selectedFilters.chiaveSesso.value && this.selectedFilters.chiaveSesso != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.chiaveSesso = data;
        break;
      case 'calibrationStatusTypes':
        if (!this.selectedFilters.calibrationStatusTypes.value && this.selectedFilters.calibrationStatusTypes != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.calibrationStatusTypes = data;
        break;
      default:
        break;
    }
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.getUsersData();
  }

  clearFilters() {
    this.selectedFilters = {
      chiaveSesso: this.filtersObject.chiaveSesso[0],
      denominazioneSO3: this.filtersObject.denominazioneSO3[0],
      denominazioneSO5: this.filtersObject.denominazioneSO5[0],
      area: this.filtersObject.area[0],
      funzione: this.filtersObject.funzione[0],
      mansione: this.filtersObject.mansione[0],
      structures: this.filtersObject.structures[0],
    }
    // this.selectedUserList = [];
    this.selectedFiltersNumber = 0;
    this.isFilterOpened = false;
    this.getUsersData();
  }

  // apre i filtri
  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }


  // Torna un oggetto che associa campo/id da passare
  parseFilters() {
    let keys = Object.keys(this.selectedFilters);
    if (this.selectedFilters && keys && keys.length) {
      return {
        area: this.selectedFilters.area.id == 'all' ? null : this.selectedFilters.area.id,
        chiaveSesso: this.selectedFilters.chiaveSesso.id == 'all' ? null : this.selectedFilters.chiaveSesso.id,
        denominazioneSO3: this.selectedFilters.denominazioneSO3.id == 'all' ? null : this.selectedFilters.denominazioneSO3.id,
        denominazioneSO5: this.selectedFilters.denominazioneSO5.id == 'all' ? null : this.selectedFilters.denominazioneSO5.id,
        funzione: this.selectedFilters.funzione.id == 'all' ? null : this.selectedFilters.funzione.id,
        mansione: this.selectedFilters.mansione.id == 'all' ? null : this.selectedFilters.mansione.id,
        structures: this.selectedFilters.structures.id == 'all' ? null : this.selectedFilters.structures.id,
      }
    } else {
      return {
        area: null,
        chiaveSesso: null,
        denominazioneSO3: null,
        denominazioneSO5: null,
        funzione: null,
        mansione: null,
        structures: null,
      }
    }
  }

  ngOnDestroy() {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.getUsersData$) {
      this.getUsersData$.unsubscribe();
    }
  }
}
