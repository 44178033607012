<page-container>

  <div class="sidenav-information">
    <sidenav-informations *ngIf="personDetails"
      (onBackFunction)="(goalId && goalId.length) ? goToUpdateGoal(true): goToCreateGoal(true)" [isBackButton]="true"
      title="{{(isEditApprove ? isManagerOperationsApprove ? ('managerOperations.SIDENAV_TITLE_IN_EDIT_TO_APPROVE_OPERATIONS' | translate) : ('goalSetting.createGoal.SIDENAV_TITLE_IN_EDIT_TO_APPROVE' | translate) : isEdit ? ('goalSetting.createGoal.SIDENAV_TITLE_IN_EDIT' | translate) :  ('goalSetting.createGoal.SIDENAV_TITLE' | translate)) + ' ' + personDetails.forename + ' ' + personDetails.surname}}"
      [description]="isManagerOperationsApprove ? (('managerOperations.SIDENAV_DESCRIPTION_APPROVE_1' | translate) + personDetails.forename + ('managerOperations.SIDENAV_DESCRIPTION_APPROVE_2' | translate) + runningYear) : ('goalSetting.createGoal.SIDENAV_DESCRIPTION' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingUpdateGoal && !isFetchingCreateGoal && !isFetchingApproveGoal">
        <div *ngIf="!isHowSection && !isEditApprove" class="form-container-what">
          <div *ngIf="!isEmpowermentGoal" class="tabs-container">
            <tab-section *ngFor="let tab of tabSectionsList" (onClick)="onTabClicked($event)" [tab]="tab">
            </tab-section>
          </div>
          <input-container
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="titleTooltipModal" [isValued]="goal.title && goal.title.trim().length"
            [isObbligatory]="true" title="{{'goalSetting.createGoal.OBJECTIVE_TITLE' | translate}}">
            <europ-input [bindValue]="goal.title" (onModelChanged)="goalTitleChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_TITLE_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <input-container [numberCharsTextArea]="charsGoalDescription" [isFromTextArea]="true"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="goal.description && goal.description.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.DESCRIPTION' | translate}}">
            <europ-text-area [bindValue]="goal.description" [maxlength]="600"
              (onModelChanged)="goalDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.DESCRIPTION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <input-container *ngIf="!isEmpowermentGoal" [numberCharsTextArea]="charsGoalObjectiveDescription"
            [isFromTextArea]="true"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="objCompletedTooltipModal"
            [isValued]="goal.objectiveDescription && goal.objectiveDescription.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_DESCRIPTION' | translate}}">
            <europ-text-area [bindValue]="goal.objectiveDescription" [maxlength]="600"
              (onModelChanged)="goalObjectiveDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_DESCRIPTION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <input-container *ngIf="!isManagerOperations" [readonly]="isEmpowermentGoal"
            [instructions]="('goalSetting.createGoal.REMAIN_TO_ASSIGN' | translate) + (differenceWeightSum > 0 ? differenceWeightSum : 0) + '%'"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="weightTooltipModal" [isValued]="weightOptionSelected" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_WEIGHT' | translate}}">
            <europ-select (onSelectedItem)="goalWeightChanged($event)" [selectedItem]="weightOptionSelected"
              [items]="weightOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_WEIGHT_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>
          <input-container [readonly]="isEmpowermentGoal"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="deadlineTooltipModal" [isValued]="goal.deadlineDate" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE' | translate}}">
            <europ-datepicker [value]="goal.deadlineDate" (dateChanged)="goalDeadlineDateChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_PLACEHOLDER' | translate}}">
            </europ-datepicker>
          </input-container>
          <ng-container *ngIf="!isEmpowermentGoal">
            <div class="button-container">
              <europ-button
                [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
                iconRight="assets/img/icons/arrow-right.svg" type="primary default" size="large"
                label="{{'generic.HOW' | translate}}" (onButtonClicked)="goToHowSection()">
              </europ-button>
            </div>
          </ng-container>
          <ng-container *ngIf="isEmpowermentGoal">
            <div class="button-container centered">
              <europ-button
                [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal || noDataInserted()"
                type="primary default" size="large"
                label="{{(goalId && goalId.length) ? (getButtonUpdateText()) : (getButtonSaveText())}}"
                (onButtonClicked)="(goalId && goalId.length) ? goToUpdateGoal(): goToCreateGoal()">
              </europ-button>
            </div>
          </ng-container>
        </div>

        <div *ngIf="isHowSection && !isEditApprove" class="form-container-how">
          <div class="tabs-container">
            <tab-section *ngFor="let tab of tabSectionsList" (onClick)="onTabClicked($event)" [tab]="tab">
            </tab-section>
          </div>
          <input-container *ngIf="!isEmpowermentGoal"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal || isFetchingCareOptions"
            [tooltipModalMessage]="careTooltipModal" [isValued]="goal.careTag" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_CARE' | translate}}">
            <europ-select (onSelectedItem)="changeCareTag($event)" [selectedItem]="goal.careTag" [items]="careOptions"
              [loadingData]="isFetchingCareOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_CARE_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>
          <!--input-container *ngIf="!isEmpowermentGoal" [readonly]="!goal.careTag && isEmpowermentGoal"
            [disabled]="!goal.careTag && !isEmpowermentGoal"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal || isFetchingBehaviourOptions"
            [tooltipModalMessage]="behaviourTooltipModal" [isValued]="goal.behaviorTag"
            title="{{'goalSetting.createGoal.OBJECTIVE_BEHAVIOUR' | translate}}">
            <europ-select [disabled]="!goal.careTag" [notSeeDescription]="true"
              (onSelectedItem)="changeBehaviourTag($event)" [selectedItem]="goal.behaviorTag" [items]="behaviourOptions"
              [loadingData]="isFetchingCareOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_BEHAVIOUR_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container-->
          <input-container *ngIf="!isEmpowermentGoal" [numberCharsTextArea]="charsGoalHowToUseDescription"
            [isFromTextArea]="true"
            [isLoading]="isLoadingTotalWeightSection || isFetchingWeightSums || isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="applyCareTooltipModal"
            [isValued]="goal.howToUseCareTag && goal.howToUseCareTag.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.HOW_TO_USE_CARE' | translate}}">
            <europ-text-area [bindValue]="goal.howToUseCareTag" [maxlength]="600"
              (onModelChanged)="goalHowToUseCareChanged($event)"
              placeholder="{{'goalSetting.createGoal.HOW_TO_USE_CARE_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <div class="button-container-how">
            <europ-button
              [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
              iconLeft="assets/img/icons/arrow-left.svg" type="primary default" size="large"
              label="{{'generic.WHAT' | translate}}" (onButtonClicked)="goToWhatSection()"></europ-button>
            <europ-button
              [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal || noDataInserted()"
              type="primary default" size="large"
              label="{{(goalId && goalId.length) ? (getButtonUpdateText()) : (getButtonSaveText())}}"
              (onButtonClicked)="(goalId && goalId.length) ? goToUpdateGoal(): goToCreateGoal()">
            </europ-button>
          </div>
        </div>

        <div *ngIf="isEditApprove" class="form-container-approve">
          <input-container *ngIf="goal.title && goal.title.length" [readonly]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="titleTooltipModal" [isValued]="goal.title && goal.title.trim().length"
            [isObbligatory]="true"
            [title]="isManagerOperationsApprove ? (('setChallenge.YOUR_CHALLENGE' | translate) + runningYear) : 'goalSetting.createGoal.OBJECTIVE_TITLE' | translate">
            <europ-input [readonly]="true" [bindValue]="goal.title" (onModelChanged)="goalTitleChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_TITLE_PLACEHOLDER' | translate}}">
            </europ-input>
          </input-container>
          <input-container *ngIf="goal.description && goal.description.length" [readonly]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="goal.description && goal.description.trim().length" [isObbligatory]="true"
            [title]="isManagerOperationsApprove ? ('qualifyCompetences.BEHAVIOUR' | translate) : ('goalSetting.createGoal.DESCRIPTION' | translate)">
            <europ-text-area [isInnerHTML]="true" [readonly]="true" [bindValue]="goal.description" [maxlength]="600"
              (onModelChanged)="goalDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.DESCRIPTION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <input-container
            *ngIf="isManagerOperationsApprove && goal.describeWhatYouIntendToDoForWinChallenge && goal.describeWhatYouIntendToDoForWinChallenge.length"
            [readonly]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="describeWhatTooltipModal"
            [isValued]="goal.describeWhatYouIntendToDoForWinChallenge && goal.describeWhatYouIntendToDoForWinChallenge.trim().length"
            [isObbligatory]="true" title="{{'setChallenge.WIN_CHALLENGE' | translate}}">
            <europ-text-area [readonly]="true" [bindValue]="goal.describeWhatYouIntendToDoForWinChallenge"
              placeholder=""></europ-text-area>
          </input-container>
          <!-- <input-container *ngIf="goal.objectiveRelevantMotivation && goal.objectiveRelevantMotivation.length" [readonly]="true"
        [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
        [tooltipModalMessage]="objCompletedTooltipModal" [isValued]="goal.objectiveRelevantMotivation && goal.objectiveRelevantMotivation.trim().length"
        [isObbligatory]="true" title="{{'goalSetting.createGoal.OBJECTIVE_RELEVANT_MOTIVATION' | translate}}">
        <europ-text-area [readonly]="true" [bindValue]="goal.objectiveRelevantMotivation" [maxlength]="600"
          placeholder=""></europ-text-area>
      </input-container> -->
          <input-container [numberCharsTextArea]="charsGoalManagerComment" [isFromTextArea]="true"
            [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal"
            [tooltipModalMessage]="managerTooltipModal"
            [isValued]="goal.managerComment && goal.managerComment.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.MANAGER_COMMENT' | translate}}">
            <europ-text-area [bindValue]="goal.managerComment" [maxlength]="600"
              (onModelChanged)="goalManagerCommentChanged($event)"
              placeholder="{{'goalSetting.createGoal.MANAGER_COMMENT_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>
          <div class="button-container centered">
            <europ-button
              [disabled]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal || !goal.managerComment || !goal.managerComment.trim().length"
              type="primary default" size="large" label="{{'goalSetting.createGoal.APPROVE_WITH_COMMENT' | translate}}"
              (onButtonClicked)="goToUpdateGoal()"></europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="modalUpdateGoalForManager" (onCancel)="goBackBrowser()" (onClose)="closeModalGoalUpdate()"
  (onConfirm)="isEditApprove ? goToApproveGoal() : (goalId && goalId.length) ? updateGoal() : createGoal()"
  cancelLabel="{{modalUpdateGoalTextCloseTextButton}}" confirmLabel="{{modalUpdateGoalTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalUpdateGoalId" [title]="modalUpdateGoalTitle" [subtitle]="''"
    [text]="modalUpdateGoalText">
  </modal-text-content>
</modal>