<div class="goal-container">
  <div class="title-container">
    <!-- TItolo-->
    <p class="title">{{ goal.title }}</p>
    <!-- Stato  restyle -->
    <!--item-list-team-status styleItem="border" [message]="goal.statusMessage" [type]="goal.status">
    </item-list-team-status-->
    <ng-container *ngIf="cardEvaluation">
      <evaluation [evaluation]="cardEvaluation.evaluationScore" [scale]="5" customClass="medium">
      </evaluation>
    </ng-container>
    <ng-container *ngIf="!cardEvaluation">
      <evaluation [evaluation]="''" [scale]="5" customClass="medium">
      </evaluation>
    </ng-container>
  </div>
  <div class="goal-various-info-container">
    <ng-container *ngIf="goal.miniCards">
      <div class="detail-card" *ngFor="let miniCard of goal.miniCards">
        <p>{{ miniCard.title | translate }}</p>
      </div>
    </ng-container>
    <div *ngIf="goal.weight" class="detail-card">
      <svg-icon src="./assets/img/icons/pie-chart.svg" class="detail-icon" [applyClass]="true">
      </svg-icon>
      <p>
        <span fxHide.xs translate="goalSetting.WEIGHT"></span>
        <span class="bold-text">{{goal.weight}}%</span>
      </p>
    </div>
    <div *ngIf="goal.deadline" class="detail-card">
      <svg-icon src="./assets/img/icons/calendar.svg" class="detail-icon" [applyClass]="true">
      </svg-icon>
      <p>
        <span class="bold-text">{{ goal.deadline || '-'}}</span>
      </p>
    </div>
  </div>
  <!-- Descrizione -->
  <ng-container *ngIf="goal.description">
    <div class="description-container">
      <div class="description">
        <p>
          <span class="title" translate="generic.DESCRIPTION"></span>
          <span *ngIf="!isInnerHtml">{{ goal.description }}</span>
          <span *ngIf="isInnerHtml" [innerHTML]="goal.description"></span>
          <span *ngIf="!isOperations" class="title padding-top7">{{ goal.careTitle }}</span>
          <span *ngIf="!isOperations">{{ goal.careDescription }}</span>
          <span *ngIf="goal.type != 'OPERATIONS_COMPETENCES' && goal.objectiveDescription" class="title"
            translate="goal.RAGGIUNGEMENT_GOAL_TEXT"></span>
          <span *ngIf="goal.type != 'OPERATIONS_COMPETENCES' && goal.objectiveDescription">{{
            goal.objectiveDescription }}</span>
        </p>
      </div>
    </div>
  </ng-container>
  <!-- file-->
  <ng-container *ngIf="goal.files && goal.files.length && goal.type != 'DEVELOPMENT_OPERATIONS'">
    <div class="goal-files-container">
      <div class="files">
        <div class="file-container" *ngFor="let file of goal.files">
          <list-doc-item [canDoActions]="canDoActions(file)" id="file.uploadId"
            srcIcon="assets/img/icons/file/{{getExtensionString(file.originalFileName)}}.svg" [fileName]="file.fileName"
            (onEdit)="emitOnEditFile(file.uploadId)" (onDelete)="emitOnDeleteFile(file.uploadId)"
            (onDownload)="emitOnFileDownload(file)"
            [chargedTime]="(!!file.creationDate ? (file.creationDate | date: 'dd.MM.YYYY') : '-')">
          </list-doc-item>
          <div class="divisor-line"></div>
        </div>
        <!--div class="file-container" *ngFor="let link of goal.links">
          <list-doc-item id="link.linkId" srcIcon="assets/img/icons/link.svg" [fileName]="link.linkName"
            (onDownload)="emitOnLinkOpen(link.url)" (onEdit)="emitOnEditLink(link.linkId)"
            (onDelete)="emitOnDeleteLink(link.linkId)"
            [chargedTime]="(!!link.creationDate ? (link.creationDate | date: 'dd.MM.YYYY') : '-')">
          </list-doc-item>
          <div class="divisor-line"></div>
        </div-->
      </div>
    </div>
  </ng-container>

  <!-- link -->
  <ng-container *ngIf="goal.links && goal.links.length && goal.type != 'DEVELOPMENT_OPERATIONS'">
    <div class="files-container">
      <div class="files">
        <div class="file-container" *ngFor="let link of goal.links">
          <list-doc-item [canDoActions]="canDoActions(link)" id="link.linkId" srcIcon="assets/img/icons/link.svg"
            [fileName]="link.linkName" (onDownload)="emitOnLinkOpen(link.url)" (onEdit)="emitOnEditLink(link.linkId)"
            (onDelete)="emitOnDeleteLink(link.linkId)" [isLink]="true"
            [chargedTime]="(!!link.creationDate ? (link.creationDate | date: 'dd.MM.YYYY') : '-')">
          </list-doc-item>
          <div class="divisor-line"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Upload link o file-->
  <ng-container *ngIf="goal.isOperation">
    <div class="file-upload-container">
      <div class="info-content">
        <p translate="goalCard.FILE_UPLOAD_INFO"></p>
      </div>
      <div class="uploader-component">
        <div class="text-content">
          <p [innerHTML]="'goalCard.UPLOAD_FILE_TITLE' | translate"></p>
        </div>
        <europ-button type="primary" [label]="'goalCard.UPLOAD_BUTTON' | translate"
          (onButtonClicked)="emitOnUploadFile()">
        </europ-button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="commentData">
    <!-- Solo se ho commento -->
    <div class="line-divisor">
      <div class="line"></div>
    </div>
    <!-- Commento approvazione manager -->
    <div class="comment-container">
      <!-- Lato sinisto con avatar, nome-->
      <div class="comment-left">
        <!-- Leggi commento + dati manager -->
        <div class="text-content">
          <avatar-img [user]="managerData" size="small"></avatar-img>
          <p class="read-comment" (click)="emitOnReadComment()">{{'goalSetting.READ_COMMENT' | translate }}
          </p>
          <p>{{'goalSetting.BY' | translate }}</p>
          <p *ngIf="managerData">{{ managerData.forename && managerData.forename[0] + '. ' +
            managerData.surname }}</p>
        </div>
      </div>
      <!-- Lato destro con data e azione sul commento-->
      <div class="comment-right">
        <p class="date-text">{{ ('goalSetting.COMMENT_INSERTED' | translate) + ' ' + commentData.date }}</p>
        <p *ngIf="isManager && canEdit()" class="action-text" (click)="emitOnEditComment()">{{'generic.EDIT' |
          translate}}
        </p>
      </div>
    </div>
  </ng-container>
  <div class="bottom-container">
    <div class="line-divisor">
      <div class="line"></div>
    </div>
    <div class="bottom-info">
      <div class="info">
        <p *ngIf="goal.evidencesCounter >= 0">
          <span class="bold-text">{{goal.evidencesCounter}}</span>&nbsp;
          <span translate="goalSetting.EVIDENCES"></span>
        </p>
        <p *ngIf="goal.feedbackCounter >= 0">
          <span class="bold-text">{{goal.feedbackCounter}}</span>&nbsp;
          <span translate="goalSetting.FEEDBACK"></span>
        </p>
      </div>
      <div class="detail-button">
        <!-- Feedback buttons -->
        <ng-container *ngIf="goal.isFeedback">
          <europ-button iconLeft="assets/img/icons/refresh-cw.svg" type="primary link" size="medium"
            label="{{'generic.UPDATE' | translate}}"></europ-button>
        </ng-container>
        <!-- Edit obiettivo creato dal manager -->
        <europ-button *ngIf="canEdit()" (click)="emitOnEditClicked()" iconLeft="assets/img/icons/edit.svg"
          type="primary link" size="medium" label="{{'generic.EDIT' | translate}}"></europ-button>
        <europ-button *ngIf="canDelete()" (click)="emitOnDeleteClicked()" iconLeft="assets/img/icons/trash-2.svg"
          type="primary link" size="medium" label="{{'generic.DELETE' | translate}}"></europ-button>
        <!-- Obiettivo da approvare -->
        <ng-container *ngIf="goal.sharedObjective">
          <europ-button (click)="emitOnApproveClicked()" iconLeft="assets/img/icons/edit.svg" type="primary link"
            size="medium" label="{{'goal.APPROVE_COMMENT' | translate}}"></europ-button>
        </ng-container>
      </div>
    </div>
  </div>
</div>