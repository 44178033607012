<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails && personDetails">
    <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="personDetails"
      [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
      [interview]="interview" (onNewInterview)="goToInterview()" (onAnnullInterview)="goToDeleteInterview($event)">
    </header-dossier>
    <div class="main-content-wrapper"
      [ngClass]="{'no-sti-padding': !isFeedbackSection && contentTabList && contentTabList[0] && contentTabList[0].noSti, 'sti-label-wrapper': isObjectiveSection && stiAmount > 0}">
      <!-- label sti -->
      <ng-container *ngIf="isObjectiveSection && stiAmount > 0">
        <div class="sti-wrapper">
          <p class="sti-label">STI</p>
          <p class="sti-value">€ {{ stiAmount }}</p>
        </div>
      </ng-container>

      <ng-container *ngIf="isObjectiveSection">
        <!-- Contenitore dati caricati -->
        <ng-container *ngIf="!isLoadingTab && contentTabList && contentTabList.length">
          <child-content [tabList]="contentTabList" (onTabChanged)="onContentTabChanged($event)"
            [isEditObjective]="currentContentTab && currentContentTab.isEditObjective">

            <ng-container *ngIf="isLoadingUserGoals">
              <div class="loader spinner-big"></div>
            </ng-container>
            <ng-container *ngIf="!isLoadingUserGoals">
              <!-- Modifica obiettivi post condivisione -->
              <ng-container *ngIf="currentContentTab && currentContentTab.id=='DRAFT_INDIVIDUAL_TAB'">
                <!-- Lista obiettivi post condivisione -->
                <p class="goal-edit-info" translate="goal.editObjective.SUBTITLE"></p>

                <!-- Accordion obiettivi di ruolo -->
                <europ-accordion [title]="editObjectiveAccordionTitle" [tooltipModalMessage]="roleGoalsTooltipModal"
                  [isChallengeAccordion]="true" [isAccordionOpened]="editObjectiveAccordionOpen">
                  <div class="challenge-accordion-container">

                    <!-- Contenitore obiettivi manager -->
                    <ng-container *ngFor="let goal of draftGoalList">
                      <goal-card [goal]="goal" (onOptionClicked)="onObjectiveOptionClicked($event, goal)"
                        (onDetailClicked)="openObjectiveDetails(goal)" [loggedUser]="loggedUser"
                        [isOperations]="personDetails.isOperation">
                      </goal-card>
                    </ng-container>
                    <!-- Placeholder se non ho obiettivi manager -->
                    <ng-container *ngIf="!draftGoalList || !draftGoalList.length">
                      <no-data [title]="('goal.PAGE_TITLE' | translate)" src="/assets/img/section/goal-setting.svg"
                        [description]="('goal.ADD_OBJECTIVE' | translate) + '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>'">
                      </no-data>
                    </ng-container>
                  </div>
                </europ-accordion>

                <!-- Pulsanti -->
                <div class="button-container multiple-rows" *ngIf="isInCurrentYear()">
                  <div class="row">
                    <europ-button type="btn-white" [label]="'goal.ADD_OBJECTIVES' | translate"
                      (onButtonClicked)="addObjective(true)">
                    </europ-button>
                    <europ-button type="btn-white bordless" [label]="('goal.editObjective.CANCEL_EDIT' | translate)"
                      (onButtonClicked)="openCancelEditObjective()"></europ-button>
                  </div>
                  <div class="row">
                    <europ-button type="secondary" [label]="'goal.SHARE_OBJECTIVES' | translate"
                      (onButtonClicked)="shareObjectives(true)">
                    </europ-button>
                  </div>
                </div>
              </ng-container>

              <!-- Lista goal Individual tab -->
              <ng-container *ngIf="currentContentTab.id=='INDIVIDUAL_TAB'">
                <!-- Post calibration -->
                <ng-container *ngIf="!isLoadingUserFinalEvaluation && !isLoadingUserCalibration">
                  <ng-container *ngIf="calibrationActive">
                    <europ-accordion [title]="('performance.ACCORDION_TITLE' | translate)"
                      [statusData]="postCalibrationData.status" [tooltipModalMessage]="performanceLevelTooltip"
                      [isAccordionOpened]="performanceLevelOpened" [grayBackground]="true">
                      <div class="challenge-accordion-container no-padding">
                        <!--h2>sono in calibration</h2-->
                        <calibration-level [calibrationLevelValue]="postCalibrationData.level"
                          [calibrationLevelObservations]="postCalibrationData.observations"
                          [payout]="postCalibrationData.payout" [year]="selectedYear.id"
                          [canConfirm]="!postCalibrationData.isComplete" (onConfirmEvaluation)="goToPostCalibration()"
                          (onReadObservations)="openPerformanceLevelModal()"
                          [isComplete]="postCalibrationData.isComplete || isProcessClosed "
                          [sti]="postCalibrationData.stiAmount" [dateEnd]="formatDate(runningPhase.phaseEndDate)">
                        </calibration-level>
                      </div>
                    </europ-accordion>
                  </ng-container>

                  <!-- Performance Level -->
                  <ng-container *ngIf="!calibrationActive && performanceLevelActive">
                    <europ-accordion [title]="('performance.ACCORDION_TITLE' | translate)"
                      [statusData]="performanceLevelStatus" [tooltipModalMessage]="performanceLevelTooltip"
                      [isAccordionOpened]="performanceLevelOpened" [grayBackground]="true">
                      <div class="challenge-accordion-container no-padding">
                        <ng-container *ngIf="!performanceLevelDone">
                          <!--h2>sono nel performance level, non completo</h2-->
                          <!-- Placeholder valutazione per utenti non sti -->
                          <ng-container *ngIf="!stiAmount">
                            <no-data [title]="('performance.ACCORDION_TITLE' | translate)" [fullSizeImg]="true"
                              src="/assets/img/section/performance-level.svg"
                              [description]="('performance.MANAGER_ACCORDION_PLACEHOLDER' | translate)">
                            </no-data>
                          </ng-container>
                          <!-- Placeholder valutazione per utenti sti con suggerimento TODO -->
                          <ng-container *ngIf="stiAmount">
                            <no-data [title]="('performance.ACCORDION_TITLE' | translate)" [fullSizeImg]="true"
                              src="/assets/img/section/performance-level.svg"
                              [performanceLevelValue]="suggestedFinalEvaluation" [payout]="suggestedPayout"
                              [description]="('performance.PRE_EVALUATION_MANAGER' | translate)">
                            </no-data>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="performanceLevelDone">
                          <!--h2>sono nel performance level, completo</h2-->
                          <no-data [title]="('performance.ACCORDION_TITLE' | translate)" [fullSizeImg]="true"
                            src="/assets/img/section/performance-level.svg"
                            [description]="('performance.MANAGER_POST_EVALUATION_PLACEHOLDER' | translate)"
                            [payout]="payoutChosen || null"
                            [performanceLevelObservations]="managerFinalEvaluationObservation"
                            [performanceLevelValue]="managerFinalEvaluation"
                            (onManagerObservationClicked)="openFinalEvaluationModal(null, true, false, true)">
                          </no-data>
                        </ng-container>
                        <!-- Valutazione utente -->
                        <div class="valutation-container" *ngIf="userFinalEvaluation">
                          <div class="final-evaluation-row white-background">
                            <div class="left-side">
                              <avatar-img [user]="personDetails" size="small"></avatar-img>
                              <p (click)="openFinalEvaluationModal(null,true,  true)" class="user-name">
                                {{'final.SELF_OBS_BY' | translate }}
                                {{ personDetails.forename[0] + '. ' + personDetails.surname }}
                              </p>
                              <evaluation [evaluation]="userFinalEvaluation" [scale]="5" customClass="medium">
                              </evaluation>
                            </div>
                            <p class="compiled">
                              {{ ('final.COMPILED_IN' | translate) + formatDate(userFinalEvaluationDate) }}
                            </p>
                          </div>
                        </div>
                        <!-- Definisci livello performance -->
                        <ng-container *ngIf="!performanceLevelDone">
                          <div class="button-container"
                            *ngIf="currentContentTab && currentContentTab.id == 'INDIVIDUAL_TAB'">
                            <europ-button type="primary" size="medium" [label]="'performance.DEFINE_LEVEL' | translate"
                              (onButtonClicked)="definePerformanceLevel()">
                            </europ-button>
                          </div>
                        </ng-container>
                      </div>
                    </europ-accordion>
                  </ng-container>

                </ng-container>

                <!-- Nuova visualizzazione obiettivi -->
                <div class="child-wrapper" *ngIf="!personDetails.isOperation && this.selectedYear.id >= 2023">
                  <!-- Box obiettivi cascading -->
                  <ng-container *ngIf="cascadingGoal && cascadingGoal.description">
                    <div class="cascading-objective-box">
                      <p translate="cascading.TILE_TITLE"></p>
                      <div class="show-container" (click)="openCascadingModal()">
                        <p translate="cascading.SHOW_OBJECTIVES"></p>
                        <svg-icon src="assets/img/icons/info.svg" class="show-icon" [applyClass]="true">
                        </svg-icon>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Immagine placeholder -->
                  <svg-icon *ngIf="isWaitingForProposal()" class="awaiting-image" [applyClass]="true"
                    src="assets/img/section/goal-setting.svg">
                  </svg-icon>
                  <!-- Messaggio in attesa della proposta del collaboratore-->
                  <div class="child-text-content">
                    <h4 translate="phases.GOAL_SETTING" *ngIf="isWaitingForProposal()"></h4>
                    <p class="text" [innerHTML]="getGoalSettingText()"></p>
                  </div>

                </div>

                <!-- Sfida per collaboratori operations -->
                <ng-container *ngIf="challengeList && challengeList.id">
                  <goal-card [goal]="challengeList" [proposalStatus]="proposalStatus.status"
                    [menuButtonDisabled]="proposalStatus.status == 'PROPOSED'"
                    (onOptionClicked)="onObjectiveOptionClicked($event, challengeList)"
                    (onDetailClicked)="openObjectiveDetails(challengeList)" [loggedUser]="loggedUser">
                  </goal-card>
                </ng-container>

                <!-- Accordion per tipo di obiettivo (team, commerciale ecc) -->
                <ng-container *ngFor="let tabType of currentContentTab.goalTypes">
                  <ng-container *ngIf="tabType.type == 'INDIVIDUAL'">
                    <!-- Nuova lista obiettivi staff sti ecc-->
                    <ng-container *ngFor="let objective of goalList">
                      <goal-card [goal]="objective" [proposalStatus]="proposalStatus.status"
                        [menuButtonDisabled]="proposalStatus.status == 'PROPOSED'"
                        (onOptionClicked)="onObjectiveOptionClicked($event, objective)"
                        (onDetailClicked)="openObjectiveDetails(objective)" [loggedUser]="loggedUser">
                      </goal-card>
                    </ng-container>

                    <!-- Approva e nega obiettivi condivisi -->
                    <div class="action-container" *ngIf="proposalStatus.status == 'PROPOSED'">
                      <europ-button [label]="'objectiveWizard.manager.DENY' | translate" type="secondary"
                        (onButtonClicked)="openDenySharingModal()">
                      </europ-button>
                      <europ-button [label]="'objectiveWizard.manager.APPROVE' | translate" type="primary"
                        (onButtonClicked)="openApproveSharingModal()">
                      </europ-button>
                    </div>
                    <!-- Aggiungi obiettivi senza peso -->
                    <div class="action-container"
                      *ngIf="proposalStatus.status == 'APPROVED' && canAddOptionalObjective()">
                      <europ-button [label]="'goal.ADD_OBJECTIVES' | translate" type="primary"
                        (onButtonClicked)="goToAddNewObjective()">
                      </europ-button>
                    </div>
                  </ng-container>


                  <!-- Accordion obiettivi di team -->
                  <ng-container *ngIf="tabType.type == 'TEAM' || tabType.type == 'COMMERCIAL'">
                    <europ-accordion [title]="tabType.accordionTitle" [infoIcon]="false"
                      [tooltipModalMessage]="tabType.type == 'TEAM' ? teamGoalsTooltipModal : commercialGoalsTooltipModal"
                      [isChallengeAccordion]="true" [isAccordionOpened]="goalAccordionOpened">
                      <div class="challenge-accordion-container">
                        <!-- Contenitore obiettivi team -->
                        <europ-accordion [noInfo]="true" [statusData]="goalObject.statusData" [isObjectiveTable]="true"
                          *ngFor="let goalObject of goalListTeamForFE" [title]="goalObject.accordionTitle"
                          [isChallengeAccordion]="true" [isAccordionOpened]="goalAccordionOpened">
                          <objective-table (onManage)="goToManageObjective($event)" [isEdit]="isInCurrentYear()"
                            [objectives]="goalObject.goals">
                          </objective-table>
                        </europ-accordion>
                      </div>
                    </europ-accordion>
                  </ng-container>

                  <!-- Accordion obiettivi di ruolo OPERATIONS-->
                  <ng-container *ngIf="tabType.type == 'OPERATIONS'">


                    <europ-accordion [title]="tabType.accordionTitle" [tooltipModalMessage]="roleGoalsTooltipModal"
                      [isChallengeAccordion]="true" [isAccordionOpened]="goalAccordionOpened">
                      <div class="challenge-accordion-container">

                        <ng-container *ngFor="let goal of goalList">
                          <goal-card [goal]="goal" (onOptionClicked)="onObjectiveOptionClicked($event, goal)"
                            (onDetailClicked)="openObjectiveDetails(goal)" [isOperations]="personDetails.isOperation"
                            [loggedUser]="loggedUser"
                            (finalEvaluationClicked)="openFinalEvaluationModal($event, false)">
                          </goal-card>
                        </ng-container>

                        <ng-container *ngIf="!goalList || !goalList.length">
                          <no-data [title]="('goal.PAGE_TITLE' | translate)" src="/assets/img/section/goal-setting.svg"
                            [description]="noDataPlaceholder">
                          </no-data>
                        </ng-container>
                        <ng-container *ngIf="!isInFinalEvaluationPhase() && isInCurrentYear()">
                          <div class="button-container">
                            <europ-button type="primary" [label]="'goal.ADD_OBJECTIVES' | translate"
                              (onButtonClicked)="addObjective()">
                            </europ-button>
                            <europ-button *ngIf="goalList && goalList.length && !alreadyShared" type="secondary"
                              [label]="'goal.SHARE_OBJECTIVES' | translate" (onButtonClicked)="shareObjectives()">
                            </europ-button>
                          </div>
                        </ng-container>
                      </div>
                    </europ-accordion>
                  </ng-container>

                </ng-container>

                <!-- People empowerment -->
                <ng-container *ngIf="!!empowerementGoal">
                  <goal-card [goal]="empowerementGoal" [proposalStatus]="proposalStatus.status"
                    [menuButtonDisabled]="proposalStatus.status == 'PROPOSED'"
                    (onOptionClicked)="onObjectiveOptionClicked($event, empowerementGoal)"
                    (onDetailClicked)="openObjectiveDetails(empowerementGoal)" [loggedUser]="loggedUser">
                  </goal-card>
                </ng-container>

                <!-- Competenze -->
                <europ-accordion
                  [isNew]="competenceList[0] && competenceList[0].consuntivation && !competenceList[0].consuntivation.hasBeenSaw"
                  *ngIf="competenceList && competenceList.length" [title]="careAccordionTitle"
                  [tooltipModalMessage]="operationsModalTooltip"  [isChallengeAccordion]="true"
                  [isAccordionOpened]="careAccordionOpened">
                  <div class="accordion-content">
                    <!-- Messaggio post valutazione -->
                    <div class="message-container" *ngIf="!isLoadingPersonDetails">
                      <svg-icon src="./assets/img/section/op-competence-qualified.svg" class="section-icon"
                        [applyClass]="true"></svg-icon>
                      <div class="message-content">
                        <p class="title">{{ personDetails.forename + ' ' +
                          ('managerOperations.SHARED_COMPETENCE' |
                          translate) }}</p>
                        <p class="descr" translate="managerOperations.SHARED_COMPETENCE_DESCR">
                        </p>
                      </div>
                    </div>
                    <!-- Mi sento forte in -->
                    <div class="competence-level-section">
                      <svg-icon class="level-image" src="/assets/img/icons/radio/feel-strong.svg" [applyClass]="true">
                      </svg-icon>
                      <div class="level-content">
                        <p class="level-title" translate="qualifyCompetences.FEEL_STRONG"></p>
                        <div class="tag-competence-content">
                          <ng-container *ngFor="let competence of strengthCompetences; trackBy competenceTagId">
                            <tag-competence [title]="competence.title" [isComplete]="competence.isComplete"
                              [isEditable]="competence.isEdit"
                              [leftIcon]="competence.isComplete ? 'assets/img/icons/chat.svg' : ''"
                              (onOptionClicked)="onCompetenceOptionsClicked($event, competence)">
                            </tag-competence>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <!-- La mia zona di comfort -->
                    <div class="competence-level-section">
                      <svg-icon class="level-image" src="/assets/img/icons/radio/comfort.svg" [applyClass]="true">
                      </svg-icon>
                      <div class="level-content">
                        <p class="level-title" translate="qualifyCompetences.COMFORT_ZONE"></p>
                        <div class="tag-competence-content">
                          <ng-container *ngFor="let competence of comfortCompetences; trackBy competenceTagId">
                            <tag-competence [title]="competence.title" [isComplete]="competence.isComplete"
                              [isEditable]="competence.isEdit && isInCurrentYear()"
                              [leftIcon]="competence.isComplete ? 'assets/img/icons/chat.svg' : ''"
                              (onOptionClicked)="onCompetenceOptionsClicked($event, competence)">
                            </tag-competence>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <!-- Vorrei migliorare in -->
                    <div class="competence-level-section last-container">
                      <svg-icon class="level-image" src="/assets/img/icons/radio/get-better.svg" [applyClass]="true">
                      </svg-icon>
                      <div class="level-content">
                        <p class="level-title" translate="qualifyCompetences.IMPROVE"></p>
                        <div class="tag-competence-content">
                          <ng-container *ngFor="let competence of improvementCompetences; trackBy competenceTagId">
                            <tag-competence [title]="competence.title" [isComplete]="competence.isComplete"
                              [isEditable]="competence.isEdit && isInCurrentYear()"
                              [leftIcon]="competence.isComplete ? 'assets/img/icons/chat.svg' : ''"
                              (onOptionClicked)="onCompetenceOptionsClicked($event, competence)">
                            </tag-competence>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </europ-accordion>

                <!-- Sezione KPI e competenze tecniche -->
                <ng-container *ngIf="kpiGoal && kpiGoal.title">
                  <goal-card [goal]="kpiGoal" (onOptionClicked)="onObjectiveOptionClicked($event, kpiGoal)"
                    (onDetailClicked)="openObjectiveDetails(kpiGoal)" [isOperations]="personDetails.isOperation"
                    [loggedUser]="loggedUser" (finalEvaluationClicked)="openFinalEvaluationModal($event, false)"
                    [hideOptions]="false">
                  </goal-card>
                </ng-container>

                <ng-container *ngIf="techGoal && techGoal.title">
                  <goal-card [goal]="techGoal" (onOptionClicked)="onObjectiveOptionClicked($event, techGoal)"
                    (onDetailClicked)="openObjectiveDetails(techGoal)" [isOperations]="personDetails.isOperation"
                    [loggedUser]="loggedUser" (finalEvaluationClicked)="openFinalEvaluationModal($event, false)">
                  </goal-card>
                </ng-container>

              </ng-container>
              <!-- Componente Company Tab-->
              <ng-container *ngIf="currentContentTab.id=='COMPANY_TAB'">
                <ng-container *ngIf="!goalList || !goalList.length">
                  <no-data [title]="('goal.COMPANY_GOALS' | translate)"
                    src="/assets/img/icons/company_tab_placeholder.svg"
                    [description]="('goal.COMPANY_GOALS_DESCRIPTION' | translate)">
                  </no-data>
                </ng-container>
                <ng-container *ngIf="goalList && goalList.length">
                  <!-- Contenitore obiettivi manager -->
                  <ng-container *ngFor="let goal of goalList">
                    <goal-card [goal]="goal" (onOptionClicked)="onObjectiveOptionClicked($event, goal)"
                      [loggedUser]="loggedUser" (onDetailClicked)="openObjectiveDetails(goal)"
                      [isOperations]="personDetails.isOperation"
                      (finalEvaluationClicked)="openFinalEvaluationModal($event, false)">
                    </goal-card>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- Componente Structure Tab -->
              <ng-container *ngIf="currentContentTab.id=='STRUCTURE_TAB'">
                <ng-container *ngIf="!goalList || !goalList.length">
                  <no-data [title]="('goal.STRUCTURE_GOALS' | translate)"
                    src="/assets/img/icons/company_tab_placeholder.svg"
                    [description]="('goal.STRUCTURE_GOALS_DESCRIPTION' | translate)">
                  </no-data>
                </ng-container>
                <ng-container *ngIf="goalList && goalList.length">
                  <!-- Contenitore obiettivi manager -->
                  <ng-container *ngFor="let goal of goalList">
                    <goal-card [goal]="goal" (onOptionClicked)="onObjectiveOptionClicked($event, goal)"
                      (onDetailClicked)="openObjectiveDetails(goal)" [loggedUser]="loggedUser">
                      [isOperations]="personDetails.isOperation">
                    </goal-card>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- Componente STI Tab -->
              <ng-container *ngIf="currentContentTab.id=='STI'">
                <ng-container>
                  <europ-accordion [title]="roleStiTitle" [tooltipModalMessage]="challengeTooltipModal"
                     [isChallengeAccordion]="true" [withOutRightIcon]="true" [infoIcon]="false"
                    [isAccordionOpened]="true">
                    <div class="challenge-accordion-container">
                      <!-- Sfida -->
                      <ng-container>
                        <div class="guidelines">
                          <svg-icon src="../../../assets/img/section/sti_guidelines.svg">
                          </svg-icon>
                          <div class="text-wrapper">
                            <div [innerHTML]="roleStiDescr"></div>
                          </div>
                        </div>
                        <p class="person-title-text">{{personDetails.forename}}
                          {{personDetails.surname}}</p>
                        <div class="rule-content">
                          <svg-icon class="medium-size" [ngClass]="userWatchedSTI ? 'green-success-svg' : 'red-error'"
                            [applyClass]="true"
                            [src]="userWatchedSTI ? 'assets/img/icons/check.svg' : 'assets/img/icons/x.svg'">
                          </svg-icon>
                          <p>
                            {{userWatchedSTI ? ('goalSetting.HE_WATCHED_RULE' | translate) :
                            ('goalSetting.HE_NOT_WATCHED_RULE' | translate)}}
                          </p>
                        </div>
                      </ng-container>
                    </div>
                  </europ-accordion>
                </ng-container>
              </ng-container>
            </ng-container>
          </child-content>

        </ng-container>
        <!-- Caricamento tab child content -->
        <ng-container *ngIf="isLoadingTab">
          <div class="loader spinner-big"></div>
        </ng-container>

        <!-- Se il profilo utente non è definito-->
        <ng-container *ngIf="!isLoadingTab && (!contentTabList || !contentTabList.length)">
          <child-content>
            <no-data [title]="('generic.WARNING' | translate)" src="/assets/img/section/goal-setting.svg"
              [description]="'errors.USER_TAB_NOT_FOUND' | translate">
            </no-data>
          </child-content>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isIndividualPlanSection">
        <child-content [isDevelopmentSection]="true">
          <!-- Loader contenuto -->
          <ng-container *ngIf="isLoadingUserDevelopmentActions || isLoadingDeleteDevelopAction">
            <div class="loader spinner-medium"></div>
          </ng-container>
          <!-- Lista azioni di sviluppo -->
          <ng-container *ngIf="!isLoadingUserDevelopmentActions && !isLoadingDeleteDevelopAction">
            <div *ngIf="developmentActionsList && developmentActionsList.length" class="challenge-accordion-container">
              <div class="development-action-box">
                <div class="box-content">
                  <div class="img-content"
                    style="background-image:url('assets/img/icons/homeIcons/development-plan-img.png')">
                  </div>
                  <div class="development-box-text">
                    <p class="title" translate="developmentPlan.PAGE_TITLE"></p>
                    <p class="description"
                      [innerHTML]="('developmentPlan.BOX_DESCRIPTION_1' | translate) + '<b>' + personDetails.forename + ' ' + personDetails.surname + '</b>' + ('developmentPlan.BOX_DESCRIPTION_2' | translate)">
                    </p>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let action of developmentActionsList">
                <card-action [isHistory]="!isInCurrentYear()" (onDeleteAction)="openDeleteModalDevelopAction($event)"
                  (onEditAction)="goToEditDevelopmentAction($event)" [loggedUserId]="loggedUser.userId"
                  (onDeleteComment)="openDeleteCommentModalDevelopAction($event)" [isManager]="true"
                  (onEditComment)="openEditCommentToDevelopAction($event)" (onReadComment)="showComment($event)"
                  (onInsertCommentToDevelopAction)="openInsertCommentToDevelopAction($event)" [object]="action">
                </card-action>
              </ng-container>
            </div>
            <!-- Placeholder se non ho azioni di sviluppo manager -->
            <ng-container *ngIf="!developmentActionsList || !developmentActionsList.length">
              <no-data [title]="('developmentPlan.PAGE_TITLE' | translate)"
                src="/assets/img/section/development-plan.svg" [description]="noDataDevelopmentActionsPlaceholder">
              </no-data>
            </ng-container>
            <!-- Pulsanti -->
            <div class="button-container development" *ngIf="isInCurrentYear()">
              <europ-button [disabled]="isFetchingCanAddActions" type="primary"
                [label]="'developmentPlan.ADD_ACTION' | translate" (onButtonClicked)="addDevelopmentAction()">
              </europ-button>
              <europ-button [disabled]="isFetchingCanShareActions" (onButtonClicked)="openDevelopActionsShareModal()"
                *ngIf="developmentActionsList && developmentActionsList.length && canShareDevActions" type="secondary"
                [label]="'developmentPlan.SHARE_DEVELOPMENT_ACTIONS' | translate">
              </europ-button>
            </div>
          </ng-container>
        </child-content>
      </ng-container>

      <!-- Contenitore dati caricati sezione feedback -->
      <ng-container *ngIf="isFeedbackSection">
        <child-content *ngIf="!isLoadingFeedbackTabsCounters" [tabList]="contentFeedbackTabList"
          (onTabChanged)="onContentFeedbackTabChanged($event)">

          <!-- Tab applausi / smart feedback-->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='CLAP_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingFeedbackClaps">
              <div class="loader spinner-medium"></div>
            </ng-container>
            <!-- Lista applausi -->
            <ng-container *ngIf="!isLoadingFeedbackClaps">
              <div class="sent-received-tab-container">
                <tab-light [tabTitle]="('feedbackSection.RECEIVED' | translate)" [isDisabled]="!isReceiving"
                  (onClick)="changeApplauseTab(true)"></tab-light>
                <tab-light [tabTitle]="('feedbackSection.SENT' | translate)" [isDisabled]="isReceiving"
                  (onClick)="changeApplauseTab(false)"></tab-light>
              </div>
              <div *ngIf="feedbackClapList && feedbackClapList.length" class="challenge-accordion-container">
                <ng-container *ngFor="let clap of feedbackClapList">
                  <card-feedback [readonly]="!isInCurrentYear()" [isClap]="true" [loggedUser]="loggedUser"
                    (onEditObject)="goToEditClap($event)" [isReceiving]="isReceiving"
                    (onDeleteObject)="goToDeleteClap($event)" [object]="clap" [isHistory]="!isInCurrentYear()">
                  </card-feedback>
                </ng-container>
              </div>
              <!-- Placeholder se non ho azioni di sviluppo manager -->
              <ng-container *ngIf="!feedbackClapList || !feedbackClapList.length">
                <no-data [title]="('feedbackSection.smartFeedback.PAGE_TITLE' | translate)"
                  src="/assets/img/section/clap-section-placeholder.svg"
                  [description]="isReceiving ? noDataFeedbackPlaceholderReceived : noDataFeedbackPlaceholderSent">
                </no-data>
              </ng-container>
              <!-- Pulsanti -->
              <div class="button-container development" *ngIf="isInCurrentYear()">
                <europ-button type="primary" [label]="('feedbackSection.SEND_CLAP' | translate)"
                  (onButtonClicked)="sendClap()"></europ-button>
              </div>
            </ng-container>
          </ng-container>

          <!-- Tab evidenze -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='EVIDENCES_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingFeedbackEvidences">
              <div class="loader spinner-medium"></div>
            </ng-container>
            <ng-container *ngIf="!isLoadingFeedbackEvidences">
              <div *ngIf="evidencesList && evidencesList.length" class="challenge-accordion-container">
                <ng-container *ngFor="let evidence of evidencesList">
                  <card-evidence [isHistory]="!isInCurrentYear()" [object]="evidence" [loggedUser]="loggedUser"
                    (onDeleteFile)="deleteFileOnEvidence($event, evidence.feedbackId)"
                    (onEdit)="goToEditEvidence($event)" (onUploadFile)="onFileUpload(evidence.feedbackId)"
                    (onFileDownload)="downloadFileOnEvidences($event, evidence.feedbackId)">
                  </card-evidence>
                </ng-container>
              </div>
              <!-- Placeholder se non ho evidenze -->
              <ng-container *ngIf="!evidencesList || !evidencesList.length">
                <no-data [title]="('evidences.PLACEHOLDER_PAGE_TITLE' | translate)"
                  src="/assets/img/section/share-goal-female.svg"
                  [description]="('evidences.PLACEHOLDER_PAGE_DESCRIPTION_1' | translate) + '<b>' + personDetails.forename + ' ' + personDetails.surname + '.</b> <br>' + ('evidences.PLACEHOLDER_PAGE_DESCRIPTION_2' | translate)">
                </no-data>
              </ng-container>
              <!-- Pulsanti -->
              <ng-container *ngIf="isInCurrentYear()">
                <div class="button-container development">
                  <europ-button type="primary" [label]="('evidences.INSERT_EVIDENCE' | translate)"
                    (onButtonClicked)="goToAddEvidence()"></europ-button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Appunti privati -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='PRIVATE_NOTES_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingPrivateNotes">

              <!-- Lista appunti privati -->
              <ng-container *ngIf="privateNotesList && privateNotesList.length">
                <card-notes *ngFor="let note of privateNotesList" [note]="note" [loggedUser]="loggedUser"
                  (onDelete)="openDeleteNoteModal($event)" (onEdit)="goToEditPrivateNote($event)"></card-notes>
              </ng-container>

              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                <no-data [title]="('privateNotes.TITLE' | translate)"
                  src="/assets/img/section/private-notes-placeholder.svg"
                  [description]="('privateNotes.PLACEHOLDER' | translate)">
                </no-data>
              </ng-container>

              <!-- Pulsanti -->

              <div class="button-container development">
                <europ-button type="primary" [label]="('privateNotes.INSERT_NOTE' | translate)"
                  (onButtonClicked)="createPrivateNote()"></europ-button>
              </div>

            </ng-container>
          </ng-container>


          <!-- Colloqui -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='INTERVIEW_TAB'">
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingInterviewsList">
              <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingInterviewsList">
              <!-- Lista Colloqui -->
              <ng-container *ngIf="interviewList && interviewList.length">
                <card-interview *ngFor="let interview of interviewList" [interview]="interview"
                  (onCancel)="goToDeleteInterview($event)"></card-interview>
              </ng-container>

              <!-- Placeholder se non Colloqui -->
              <ng-container *ngIf="!interviewList || !interviewList.length">
                <no-data [title]="('interview.TITLE' | translate)" src="/assets/img/section/feedback.svg"
                  [description]="('interview.PLACEHOLDER' | translate)">
                </no-data>
              </ng-container>

              <!-- Pulsanti -->
              <ng-container *ngIf="isInCurrentYear()">
                <div class="button-container development">
                  <europ-button type="primary" [label]="('interview.CREATE_INTERVIEW' | translate)"
                    (onButtonClicked)="goToInterview()"></europ-button>
                </div>
              </ng-container>
            </ng-container>

          </ng-container>


          <!-- Peer Feedback -->
          <ng-container *ngIf="currentContentFeedbackTab && currentContentFeedbackTab.id=='PEER_FEEDBACK_TAB'">
            <!--div class="sent-received-tab-container">
              <tab-light [tabTitle]="('peerFeedback.SENT' | translate)" [isDisabled]="peerSent"
                (onClick)="changePeerTab(false)"></tab-light>
              <tab-light [tabTitle]="('peerFeedback.RECEIVED' | translate)" [isDisabled]="!peerSent"
                (onClick)="changePeerTab(true)"></tab-light>
            </div-->
            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPeerFeedback">
              <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingPeerFeedback">

              <!-- Lista peer feedback -->
              <ng-container *ngIf="peerFeedbackList && peerFeedbackList.length">
                <card-peer *ngFor="let feedback of peerFeedbackList" [isHistory]="!isInCurrentYear()"
                  [object]="feedback" [loggedUser]="loggedUser" [isManager]="true"
                  [isReceived]="feedback.senderUserId == userId" (onDelete)="openDeletePeerFeedbackModal($event)"
                  (onEdit)="goToPeerFeedbackAnswer($event)" (onShowRequest)="openShowFeedbackRequest($event)"
                  (onFeedbackSend)="goToPeerFeedbackAnswer($event)">
                </card-peer>
              </ng-container>

              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!peerFeedbackList || !peerFeedbackList.length">
                <no-data [title]="('peerFeedback.TITLE' | translate)"
                  src="/assets/img/section/private-notes-placeholder.svg" [description]="peerFeedbackPlaceholder">
                </no-data>
              </ng-container>
            </ng-container>
          </ng-container>
        </child-content>

        <!-- Caricamento tab child content -->
        <ng-container *ngIf="isLoadingFeedbackTabsCounters">
          <child-content>
            <div class="loader spinner-medium"></div>
          </child-content>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <!-- Loader caricamento dati utente -->
  <ng-container *ngIf="isLoadingPersonDetails || isLoadingYearList">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale condivisione -->
<modal id="confirmShareModalManagerOperations" (onClose)="closeShareModal()" (onConfirm)="confirmShareModal()"
  confirmLabel="{{ 'generic.SHARE' | translate}}">
  <modal-text-content *ngIf="shareModalContent" [noBackground]="true" [image]="shareModalContent.image"
    [title]="shareModalContent.title" [subtitle]="shareModalContent.subtitle" [text]="shareModalContent.text">
  </modal-text-content>
</modal>

<!-- Modale per errore condivisione -->
<modal id="errorShareModalManagerOperations" (onClose)="closeErrorModal()" (onConfirm)="closeErrorModal()"
  confirmLabel="{{ 'generic.GOT_IT' | translate}}">
  <modal-text-content *ngIf="shareModalContent" [noBackground]="true" [image]="shareModalContent.image"
    [title]="shareModalContent.title" [subtitle]="shareModalContent.subtitle" [text]="shareModalContent.text">
  </modal-text-content>
</modal>

<!-- Modale per annullare il colloquio -->
<modal id="deleteInterviewModal" [cancelLabel]="'No'" (onCancel)="closeModalDeleteInterview()"
  (onClose)="closeModalDeleteInterview()" (onConfirm)="deleteInterview()" confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="601" title="{{ 'goalSetting.organizeInterview.DELETE_INTERVIEW' | translate}}"
    text="{{ 'goalSetting.organizeInterview.DELETE_INTERVIEW_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale feedback competenze -->
<modal id="competenceDetails" (onClose)="closeCompetenceDetailsModal()"
  confirmLabel="{{ competenceForModal && competenceForModal.managerCommentDate ? ('managerOperations.modals.UPDATE_FEEDBACK' | translate) : ('managerOperations.modals.SEND_FEEDBACK' | translate)}}"
  (onConfirm)="sendCompetenceFeedback()" [isDisabled]="!competenceFeedback || !competenceFeedback.trim().length">

  <div class="competence-detail-modal-wrapper">
    <p class="title" translate="managerOperations.modals.COMPETENCE_DETAIL"></p>
    <div class="description-wrapper" *ngIf="competenceForModal">
      <div class="care-content"
        [innerHTML]="'<h3>' + competenceForModal.title + '</h3>' + competenceForModal.description">
      </div>
      <!--div class="section-wrapper">
        <p class="descr-title">{{('qualifyCompetences.caring.TITLE' | translate)}}</p>
        <p class="descr-subtitle">{{('qualifyCompetences.caring.SUBTITLE' | translate)}}</p>
      </div>
      <div class="section-wrapper">
        <p class="descr-info" translate="qualifyCompetences.BEHAVIOUR"></p>
        <p class="descr-content" [innerHTML]="'qualifyCompetences.caring.DESCR' | translate"></p>
      </div-->
    </div>
    <!-- [isLoading]="isFetchingGetGoal || isFetchingUpdateGoal || isFetchingApproveGoal || isFetchingCreateGoal" -->
    <input-container [isObbligatory]="true" [isWithoutInfoIcon]="true" [numberCharsTextArea]="competenceFeedbackChars"
      [isFromTextArea]="true" [tooltipModalMessage]="comptenceFeedbackModalTooltip"
      [isValued]="competenceFeedback && competenceFeedback.trim().length"
      title="{{'goalSetting.createGoal.MANAGER_COMMENT' | translate}}">
      <europ-text-area [bindValue]="competenceFeedback" [maxlength]="600"
        (onModelChanged)="competenceFeedbackChanged($event)"
        placeholder="{{'managerOperations.modals.COMPETENCE_FEEDBACK_PLACEHOLDER' | translate}}">
      </europ-text-area>
    </input-container>
  </div>

</modal>

<!-- Modale per eliminare l'applauso -->
<modal id="deleteClapModal" [cancelLabel]="'No'" (onCancel)="closeDeleteClapModal()" (onClose)="closeDeleteClapModal()"
  (onConfirm)="deleteClap()" confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="1001d" title="{{ 'feedbackSection.DELETE_CLAP' | translate}}"
    text="{{ 'feedbackSection.DELETE_CLAP_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per aggiungere un commento all'azione di sviluppo -->
<modal id="insertCommentDevelopmentPlanModal"
  [isDisabled]="developAction && (!newActionComment || !newActionComment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeInserCommentModalDevelopAction(true)"
  (onClose)="closeInserCommentModalDevelopAction(true)" (onConfirm)="addCommentToDevelopAction()"
  confirmLabel="{{ 'developmentPlan.INSERT_COMMENT_ACTION' | translate}}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.INSERT_COMMENT_TITLE' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="developAction">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="newActionComment && newActionComment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.INSERT_COMMENT_TITLE' | translate}}">
      <europ-text-area [bindValue]="newActionComment" [maxlength]="600"
        (onModelChanged)="developActionCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per modificare un commento all'azione di sviluppo -->
<modal id="editCommentDevelopmentPlanModal"
  [isDisabled]="commentToEdit && (!commentToEdit.comment || !commentToEdit.comment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeEditCommentModalDevelopAction(true)"
  (onClose)="closeEditCommentModalDevelopAction(true)" (onConfirm)="updateCommentForDevelopAction()"
  confirmLabel="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate }}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="commentToEdit">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="commentToEdit.comment && commentToEdit.comment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}">
      <europ-text-area [bindValue]="commentToEdit.comment" [maxlength]="600"
        (onModelChanged)="developActionEditCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per cancellare il commento dell'azione di sviluppo -->
<modal id="deleteDevelopActionCommentModal" [cancelLabel]="'No'" (onCancel)="closeDeleteCommentModalDevelopAction()"
  (onClose)="closeDeleteCommentModalDevelopAction()" (onConfirm)="deleteCommentDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d032" title="{{ 'developmentPlan.DELETE_COMMENT' | translate}}"
    text="{{ 'developmentPlan.DELETE_COMMENT_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per cancellare l'azione di sviluppo -->
<modal id="deleteDevelopmentAction" [cancelLabel]="'No'" (onCancel)="closeDeleteModalDevelopAction()"
  (onClose)="closeDeleteModalDevelopAction()" (onConfirm)="deleteDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d150" title="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION' | translate}}"
    text="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per condividere le azioni di sviluppo -->
<modal id="developActionsShareModal" (onCancel)="closeDevelopActionsShareModal()"
  (onClose)="closeDevelopActionsShareModal()" (onConfirm)="shareDevelopActions()"
  confirmLabel="{{ 'generic.SHARE' | translate}}" [cancelLabel]="'generic.ANNULL' | translate">
  <modal-text-content modalId="031" *ngIf="personDetails" [noBackground]="true"
    image="assets/img/section/development-plan.svg" [title]="'developmentPlan.SHARE_DEVELOPMENT_PLAN' | translate"
    [subtitle]="'developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN' | translate"
    [text]="('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_1' | translate) + personDetails.forename + ' ' + personDetails.surname + ('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_2' | translate)">
  </modal-text-content>
</modal>

<!-- Modale aggiunta/modifica file -->
<modal id="uploadFileOnEvidences"
  [isDisabled]="(!fileObject || !fileObject.fileExtension || !fileTitle || !fileTitle.trim().length)"
  (onClose)="closeUploadFileModal()" (onConfirm)="confirmUploadFile()" [confirmLabel]="('generic.CONFIRM' | translate)">
  <div class="file-upload-modal-wrapper">
    <p class="title align-center" translate="generic.CHARGE_FILE"></p>
    <!-- Upload file -->
    <ng-container>
      <input-container [isWithoutInfoIcon]="true" [isValued]="fileTitle && fileTitle.trim().length"
        [isObbligatory]="true" title="{{'managerOperations.modals.FILE_TITLE' | translate}}">
        <europ-input [bindValue]="fileTitle" (onModelChanged)="onFileTitleChanged($event)"
          placeholder="{{'managerOperations.modals.TITLE_PLACEHOLDER' | translate}}">
        </europ-input>
      </input-container>
      <input-container [instructions]="'goalSetting.UPLOAD_FILES_ACCEPTED' | translate"
        [isValued]="fileObject && fileObject.fileExtension" [isWithoutInfoIcon]="true" [isObbligatory]="true"
        title="{{'managerOperations.modals.FILE_IN_AREA' | translate}}">
        <europ-upload [isToReset]="isToResetUploadComponent" (onUpload)="assignFile($event)"></europ-upload>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Modale elimina file -->
<modal id="deleteFileModal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeleteFileModalOnEvidence(false)" (onClose)="closeDeleteFileModalOnEvidence(false)"
  (onConfirm)="closeDeleteFileModalOnEvidence(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="opm017" [title]="'managerOperations.modals.DELETE_FILE_TITLE' | translate"
    [text]="'managerOperations.modals.DELETE_FILE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="cancelEditObjective" [cancelLabel]="('goal.editObjective.MODAL_CANCEL' | translate)"
  (onCancel)="closeCancelEditObjective(false)" (onClose)="closeCancelEditObjective(false)"
  (onConfirm)="closeCancelEditObjective(true)" confirmLabel="{{ 'goal.editObjective.MODAL_CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'goal.editObjective.CANCEL_EDIT' | translate"
    [text]="'goal.editObjective.CANCEL_EDIT_MODAL_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale eliminazione peer feedback -->
<modal id="deletePeerFeedback" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeletePeerFeedbackModal()" (onClose)="closeDeletePeerFeedbackModal(false)"
  (onConfirm)="closeDeletePeerFeedbackModal(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pf013" [title]="'peerFeedback.modals.DELETE_FEEDBACK_TITLE' | translate"
    [text]="'peerFeedback.modals.DELETE_FEEDBACK_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale mostra richiesta peer feedback -->
<modal id="modalShowRequest" (onClose)="closeShowFeedbackRequest()" (onConfirm)="closeShowFeedbackRequest()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <div class="peer-feedback-modal-request">
    <div class="title-code-wrapper full-width">
      <div class="title-container" translate="peerFeedback.card.REQUEST_TITLE"></div>
    </div>
    <div class="confirm-request-content" *ngIf="feedbackForRequestModal">
      <div class="section users">
        <div class="title" translate="peerFeedback.modals.REQUEST_FROM"></div>
        <div class="content">
          <div class="mini-user-card">
            <avatar-img size="small" [user]="feedbackForRequestModal.recipientUser"></avatar-img>
            <p class="name">{{ feedbackForRequestModal.recipientUser.forename + ' ' +
              feedbackForRequestModal.recipientUser.surname}}</p>
          </div>
        </div>
      </div>
      <div class="section" *ngIf="feedbackForRequestModal.goal">
        <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
        <div class="content">{{ feedbackForRequestModal.goal && feedbackForRequestModal.goal.title}}</div>
      </div>
      <div class="section">
        <div class="title" translate="peerFeedback.modals.WHY"></div>
        <div class="content">{{ feedbackForRequestModal.request }}</div>
      </div>
    </div>
  </div>
</modal>

<!-- Modale per valutazione finale -->
<modal id="finalEvaluationModal" (onClose)="closeFinalEvaluationModal()" (onConfirm)="closeFinalEvaluationModal()"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <div class="final-evaluation-modal-container" *ngIf="finalEvaluationForModal">
    <p class="modal-title"
      translate="{{personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS' : 'final.TITLE'}}">
    </p>
    <ng-container *ngIf="finalEvaluationForModal.evaluationScore">
      <evaluation [evaluation]="finalEvaluationForModal.evaluationScore" [scale]="finalEvaluationForModal.final ? 5 : 3"
        customClass="big"></evaluation>
      <div class="divisor-line"></div>
    </ng-container>
    <div class="observation-container">
      <p class="title">
        {{ ((personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS_BY' : 'final.EVALUATION_BY')
        | translate) + finalEvaluationForModal.user.forename + ' ' +
        finalEvaluationForModal.user.surname
        }}
      </p>
      <p class="descr">{{ ('final.INSERT_DATE' | translate) + formatDate(finalEvaluationForModal.creationDate) }}</p>
      <p class="title" translate="final.OBSERVATION"></p>
      <p class="descr">{{finalEvaluationForModal.observation}}</p>
    </div>
  </div>
</modal>

<!-- Modale per il performance level -->
<modal id="performance-level-modal" (onClose)="closerPerformanceLevelModal()"
  (onConfirm)="closerPerformanceLevelModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <ng-container *ngIf="postCalibrationData">
    <p class="modal-title">
      {{ ('calibration.modals.CONFIRM_EVAL' | translate)}}</p>
    <div class="confirmation-container calibration">
      <div class="observation-container">
        <p class="subtitle" translate="calibration.modals.OBSERVATIONS"></p>
        <p class="text">{{ postCalibrationData.observations || ('calibration.NO_OBSERVATION' | translate)}}</p>
      </div>
      <div class="value-container">
        <evaluation customClass="big" [scale]="5" [evaluation]="postCalibrationData.level"></evaluation>
        <ng-container *ngIf="postCalibrationData.payout && stiAmount">
          <div class="payout-label">
            <p>{{postCalibrationData.payout.title + ' ' + ('performance.modals.OF_PAYOUT' | translate)}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</modal>

<!-- Modale per negare l'approvazione -->
<modal id="deny-modal" (onClose)="closeDenySharingModal()" (onConfirm)="closeDenySharingModal(true)"
  (onCancel)="closeDenySharingModal()" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'objectiveWizard.manager.modals.DENY_TITLE' | translate"
  [isDisabled]="!denyComment || !denyComment?.trim()?.length">
  <modal-text-content *ngIf="personDetails" modalId="deny001"
    [title]="'objectiveWizard.manager.modals.DENY_TITLE' | translate"
    [subtitle]="'objectiveWizard.manager.modals.DENY_SUBTITLE' | translate" [text]="getDenyModalDescr()">
  </modal-text-content>
  <input-container [isWithoutInfoIcon]="true" [isFromTextArea]="true"
    [isValued]="denyComment && denyComment.trim().length"
    [title]="'objectiveWizard.manager.modals.INSERT_DETAILED_MOTIVE' | translate" [isObbligatory]="true">
    <europ-text-area [bindValue]="denyComment" (onModelChanged)="denyCommentChanged($event)"
      [placeholder]="'objectiveWizard.manager.modals.INSERT_MOTIVE_PLACEHOLDER' | translate">
    </europ-text-area>
  </input-container>
</modal>

<!-- Modale per approvare gli obiettivi condivisi -->
<modal id="approve-modal" (onClose)="closeApproveSharingModal()" (onConfirm)="closeApproveSharingModal(true)"
  (onCancel)="closeApproveSharingModal()" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'objectiveWizard.manager.modals.APPROVE_TITLE' | translate">
  <modal-text-content *ngIf="personDetails" modalId="appr001"
    [title]="'objectiveWizard.manager.modals.APPROVE_TITLE' | translate"
    [subtitle]="'objectiveWizard.manager.modals.APPROVE_SUBTITLE' | translate" [text]="getApproveModalDescr()">
  </modal-text-content>
</modal>

<!-- Dettagli obiettivo -->
<modal id="objectiveDetails" (onClose)="closeObjectiveDetails()" (onConfirm)="closeObjectiveDetails()"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <div class="objective-details" *ngIf="objectiveForModal">
    <h3 translate="generic.OBJECTIVE_DETAILS"></h3>

    <div class="section-container">
      <!-- Descrizione -->
      <div class="section" *ngIf="objectiveForModal.description">
        <h5 translate="generic.DESCRIPTION"></h5>
        <p
          [innerHTML]="objectiveForModal.description ? objectiveForModal.description : ('generic.NO_DESCRIPTION_ADDED' | translate)">
        </p>
      </div>
      <!-- Descrizione "Cosa ti farà dire che l’obiettivo è stato raggiunto? "-->
      <!-- OBJECTIVE_DESCRIPTION_COLLABORATOR Sfida -->
      <div class="section" *ngIf="objectiveForModal.describeWhatYouIntendToDoForWinChallenge">
        <h5 translate="goalSetting.createGoal.OBJECTIVE_DESCRIPTION_COLLABORATOR"></h5>
        <p [innerHTML]="objectiveForModal.describeWhatYouIntendToDoForWinChallenge"></p>
      </div>
      <!-- Commento del manager -->
      <div class="section" *ngIf="objectiveForModal.managerComment && objectiveForModal.managerComment.length">
        <h5 translate="goal.MANAGER_COMMENT"></h5>
        <p [innerHTML]="objectiveForModal.managerComment"></p>
      </div>
      <!-- Attributo CARE -->
      <div class="section" *ngIf="objectiveForModal.careTitle">
        <h5 translate="generic.CARE_ATTRIBUTE"></h5>
        <p>{{ objectiveForModal.careTitle }}</p>
      </div>
      <!-- Evidenze -->
      <div class="section" *ngIf="objectiveForModal.evidenceFeedbacks && objectiveForModal.evidenceFeedbacks.length">
        <h5 translate="accordion.EVIDENCES"></h5>
        <ng-container *ngFor="let evidence of objectiveForModal.evidenceFeedbacks">
          <card-evidence [goalTitle]="objectiveForModal.title" [isInChallengeDetail]="true" [plain]="true"
            [object]="evidence" (onFileDownload)="downloadFileOnEvidences($event, evidence.feedbackId)"
            [isHistory]="!isInCurrentYear()">
          </card-evidence>
        </ng-container>
      </div>
      <div class="section" *ngIf="hasAnyEvaluation()">
        <h5 translate="phases.FINAL_EVALUATION"></h5>
        <!-- se non ho nessuna valutazione -->
        <ng-template #noEvaluations>
          <p translate="generic.NO_EVALUATION_INSERTED"></p>
        </ng-template>
        <!-- Se ho almeno una valutazione -->
        <ng-container *ngIf="hasAnyEvaluation(); else noEvaluations">
          <ng-container *ngFor="let evaluation of objectiveForModal.goalEvaluation">
            <!-- Controllo se il performance level è fatto per mostrare la valutazione del manager -->
            <!--*ngIf="evaluation.evaluationScore && (evaluation.user.userId == loggedUser.userId || postCalibrationData?.isComplete && evaluation.user.userId != loggedUser.userId)"-->
            <div class="final-evaluation-row white-background">
              <div class="left-side">
                <p class="user-name">
                  {{ evaluation.user.forename[0] + '. ' + evaluation.user.surname }}
                </p>
                <evaluation *ngIf="evaluation.evaluationScore" [evaluation]="evaluation.evaluationScore" [scale]="3"
                  customClass="medium">
                </evaluation>
              </div>
              <p class="compiled">
                {{ ('final.COMPILED_IN' | translate) + formatDate(evaluation.creationDate)}}
              </p>
              <p *ngIf="evaluation.observation" class="compiled">
                {{ evaluation.observation }}
              </p>
            </div>
            <div class="divisor-line"></div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</modal>

<!-- Modale visualizzazione obiettivi cascading -->
<modal id="cascading-modal" (onCancel)="closeCascadingModal()" (onClose)="closeCascadingModal()"
  (onConfirm)="closeCascadingModal()" [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content *ngIf="cascadingGoal" modalId="ccasc001" [title]="'cascading.TILE_TITLE' | translate"
    [subtitle]="'cascading.WHICH' | translate" [text]="formatCascadingDescription()">
  </modal-text-content>
</modal>

<!-- Modale eliminazione obiettivo -->
<modal id="deleteObjective" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteObjectiveModal()"
  (onClose)="closeDeleteObjectiveModal()" (onConfirm)="closeDeleteObjectiveModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="delo001" [title]="'objectiveWizard.sharedModals.DELETE_OBJECTIVE_TITLE' | translate"
    [subtitle]="'objectiveWizard.sharedModals.DELETE_OBJECTIVE_SUB' | translate"
    [text]="'objectiveWizard.sharedModals.DELETE_OBJECTIVE_TEXT' | translate">
  </modal-text-content>
</modal>