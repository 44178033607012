import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GetUserTalentHrInterviewQuestionsResponse,
  ListEvaluatorUsersForTalentInterviewResponse,
  PerformanceCareTalent,
  SenecaResponse,
  User,
} from 'atfcore-commonclasses';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';

type Tab = { id: string; title: string, pageTitle?: string };

const AREAS_INNER_TEXT = new Map<string, string>([
  ['Collaborazione', `<ol style="padding-left: 2em"> <li>Attenzione al lavoro degli altri, sostegno allo spirito di gruppo</li> <li>Immedesimarsi nell'altro identificando cause e soluzioni</li> </ol>`],
  ['Sfida', `<ol style="padding-left: 2em"> <li>Coraggio e determinazione nel perseguire obiettivi sfidanti</li> <li>Entusiasmo e disponibilità a lasciare la propria comfort zone</li> </ol>`],
  ['Indipendenza', `<ol style="padding-left: 2em"> <li>Autonomia e assertività</li> <li>Consapevolezza rispetto ai passi necessari per avere successo</li> </ol>`],
  ['Work-life balance', `<ol style="padding-left: 2em"> <li>Disponibilità a  correre l'extra mile</li> </ol>`],
  ['Affiliazione', `<ol style="padding-left: 2em"> <li>Livello del senso di appartenenza</li> <li>Livello di senso di fiducia e adesione</li> </ol>`],
  ['Potere', `<ol style="padding-left: 2em"> <li>Desiderio di influenzare / tenacia</li> <li>Comprensione delle dinamiche organizzative</li> </o`]
]);

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.scss'],
})
export class InterviewComponent implements OnInit {
  _interview: PerformanceCareTalent.UpdateTalentInterviewQuestionsByYear | null =
    null;

  failedToSaveAnswersModalId = 'failed-to-save-answers-modal';

  failedToRetrieveInterviewModalId = 'failed-to-retrieve-interview-modal';

  saveInterviewModalId = 'save-interview-modal';

  infoOfAreaModalId = 'info-of-area-modal';

  navigateBackModalId = 'navigate-back-modal';

  tabList$: BehaviorSubject<Tab[]> = new BehaviorSubject<Tab[]>([]);

  currentTab!: Tab;

  selectedEvaluator: User | null = null;

  isSaveButtonDisabled: boolean = true;

  private yearAndUserId$: Observable<[number, string]> = this.route.paramMap.pipe(
    switchMap((params) => {
      const processYear = params.get('processYear');
      const userId = params.get('userId');

      if (processYear && userId) {
        const result: [number, string] = [parseInt(processYear, 10), userId];
        return of(result);
      }
      return throwError('No userId or processYear found');
    })
  );

  private tabIndex$: Observable<number> = this.route.queryParamMap.pipe(
    map(params => {
      const tab = params.get('tab');
      if(tab) {
        return parseInt(tab, 10);
      }
      return 0;
    })
  );

  interviewQuestions$!: Observable<GetUserTalentHrInterviewQuestionsResponse>;

  evaluators$!: Observable<ListEvaluatorUsersForTalentInterviewResponse>;


  constructor(
    public redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private potentialTalentSvc: PotentialTalentsService
  ) {}

  ngOnInit(): void {
    this.evaluators$ = this.potentialTalentSvc.getInterviewEvaluators().pipe(
      switchMap(({ response, error }) => {
        if (error) {
          return throwError(error);
        }
        return of(response);
      }),
      map(({ users, usersCount }) => {
        return {
          users: users.map((evaluator) => ({
            ...evaluator,
            title: `${evaluator.forename} ${evaluator.surname}`,
          })),
          usersCount,
        };
      }),
      catchError((err) => {
        console.error('[interview]', err);
        this.modalService.open(this.failedToSaveAnswersModalId);
        this.router.navigate(['../'], { relativeTo: this.route });
        return EMPTY;
      })
    );

    combineLatest([
      this.tabIndex$,
      this.tabList$
    ]).pipe(
      tap(([tabIdx, tabList]) => {
        if(tabList.length > 0) {
          this.setCurrentTab(tabIdx)
        }
      })
    ).subscribe();

    this.interviewQuestions$ = this.yearAndUserId$.pipe(
      switchMap(([processYear, userId]) => {
        return combineLatest([
          of(processYear),
          this.potentialTalentSvc.getHRInterviewQuestions(userId, processYear),
        ]);
      }),
      switchMap(([processYear, { response, error }]) => {
        if (error) {
          return throwError(error);
        }
        // europ-select component requires a title key to be present in the object
        const resp = {
          ...response,
          evaluationUser: response.evaluationUser
            ? {
                ...response.evaluationUser,
                title: `${response?.evaluationUser?.forename || ''} ${response?.evaluationUser?.surname || ''}`,
              }
            : response.evaluationUser,
        };
        return of({ processYear, response: resp });
      }),
      tap(({ processYear, response}) => {
        this.tabList$.next(this.tabs(response.pages));
        this._interview = {
          evaluatorUserId: response?.evaluationUser?.userId,
          pages: response?.pages,
          perfCareYear: processYear,
          userId: response.user.userId,
        };
        this.isSaveButtonDisabled = response?.evaluationUser === null;
      }),
      map(({ response }) => response),
      catchError((err) => {
        console.error('[interview]', err);
        this.modalService.open(this.failedToRetrieveInterviewModalId);
        this.router.navigate(['../'], { relativeTo: this.route });
        return EMPTY;
      })
    );



  }

  onTabChange(tabIdx: number) {
    this.currentTab = this.tabList$.getValue()[tabIdx];
    // update the query param to the selected tab
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabIdx },
      replaceUrl: true,
    });
  }

  onEvaluatorChange(evaluator: any) {
    const { chiaveSesso, isChecked, stiAmount, ...rest } = evaluator;
    this.selectedEvaluator = rest;

    this._interview!.evaluatorUserId = rest.userId;

    this.isSaveButtonDisabled = false;
  }

  updateAnswerOfQuestionOfPage(
    pageId: number,
    questionId: number,
    selectedAnswerText: string
  ) {
    // update the _interview object with the new answer
    const page = this._interview?.pages[pageId];
    if (page) {
      const question = page?.questions[questionId];
      if (question) {
        question.selectedAnswerText = selectedAnswerText;
      }
    }
  }

  saveInterview(): Observable<SenecaResponse<boolean>> {
    // check if the currentTab is the last tab in tabList
    const _tabList = this.tabList$.getValue();
    const isLastTab =
      this.currentTab?.id === _tabList[_tabList.length - 1].id;

    if (this._interview) {
      const payload = isLastTab
        ? { ...this._interview, closeSurvey: true }
        : this._interview;

      return this.potentialTalentSvc.updateInterviewAnswers(payload)
    }
    return EMPTY;
  }

  // MODALS METHODS

  openSaveInterviewModal() {
    this.modalService.open(this.saveInterviewModalId);
  }

  saveInterviewQuestions() {
    this.saveInterview().subscribe(({ error }) => {
      if (error) {
        console.error('[interview]', error);
        this.modalService.open(this.failedToSaveAnswersModalId);
      }

      this.closeSaveInterviewModal();
      this.router.navigate(['../'], { relativeTo: this.route });
    });
  }

  closeSaveInterviewModal() {
    this.modalService.close(this.saveInterviewModalId);
  }

  failedToSaveAnswersModal() {
    this.modalService.close(this.failedToSaveAnswersModalId);
  }

  closeFailedToRetrieveInterviewModalId() {
    this.modalService.close(this.failedToRetrieveInterviewModalId);
  }

  openInfoOfAreaModal() {
    this.modalService.open(this.infoOfAreaModalId);
  }

  closeInfoOfAreaModal() {
    this.modalService.close(this.infoOfAreaModalId);
  }

  getInfoAreaTitle(): string {
    const currentTabIdx = this.tabList$.getValue().findIndex(
      (tab) => tab.id === this.currentTab?.id
    );
    return `Area ${currentTabIdx} - ${this.currentTab?.pageTitle || ''}`;
  }

  getInfoAreaText(): string {
    const whatToNote = `<p class="bold-text primary-text-color">Cosa notare</p>`;
    return `${whatToNote} ${AREAS_INNER_TEXT.get(this.currentTab?.pageTitle || '') ?? 'NOT FOUND'}`;
  }


  navigateBack(wantsToSave: boolean = false) {
    if(wantsToSave) {
      this.saveInterview().pipe(
        tap(() => this.redirectService.goBackBrowser())
      ).subscribe();
    } else {
      this.redirectService.goBackBrowser();
    }
  }

  openNavigateBackModal() {
    this.modalService.open(this.navigateBackModalId);
  }

  closeNavigateBackModal() {
    this.modalService.close(this.navigateBackModalId);
  }

  private tabs(
    tabs: GetUserTalentHrInterviewQuestionsResponse['pages']
  ): Tab[] {
    const infoTab = { id: 'INFO', title: 'INFO' };
    const _tabs = tabs.map((page, index) => {
      return {
        id: page.pageId,
        title: `Area ${index + 1}`,
        pageTitle: page.pageTitle,
      };
    });
    return [infoTab, ..._tabs];
  }

  private setCurrentTab(tabIdx: number) {
    const _tabList = this.tabList$.getValue();
    if(_tabList.length > 0) {
      if(tabIdx >= 0 && tabIdx < _tabList.length) {
        this.currentTab = _tabList[tabIdx];
      } else {
        this.currentTab = _tabList[0];
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { tab: 0 },
          replaceUrl: true,
        });
      }
    }
  }

}
