<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [hasTabs]="false" [adminPageTitle]="('monitoring.TITLE' | translate)"
    [adminPageSubtitle]="processYear ? (('monitoring.PERFORMANCE' | translate) + processYear) : ('monitoring.WHAT_PROCESS' | translate)"
    [adminBack]="true" [adminActionLabel]="processYear ? ('monitoring.DOWNLOAD' | translate) : ''"
    (onAdminAction)="downloadReport()" [adminActionDisabled]="isDownloadingReport"></header-dossier>
  <div class="main-content-wrapper admin">
    <!-- Home processi -->
    <ng-container *ngIf="!processYear">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content responsive">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.YEAR' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.OPEN_CLOSE' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PHASE_HIGH' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                  <!--th>
                    <div class="sorting" (click)="orderBy('status')">
                      <p>{{ ('adminTable.STATUS' | translate)}}</p>
                      <div class="arrows-container">
                        <svg-icon class="arrow" [applyClass]="true" src="/assets/img/icons/sorting-arrow-up.svg">
                        </svg-icon>
                        <svg-icon class="arrow" [applyClass]="true" src="/assets/img/icons/sorting-arrow-down.svg">
                        </svg-icon>
                      </div>
                    </div>
                  </th-->
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                            slice:0:processData.numRecords | 
                            paginate: { id: 'processList',
                                    itemsPerPage: processData.numRecords,
                                    currentPage: processData.page,
                                    totalItems: processData.counter }">
                  <td>{{ process.year }}</td>
                  <td>{{ process.title }}</td>
                  <td>
                    <div class="date">
                      <p>{{ process.startDate ? process.startDate : '--'}}
                      </p>
                      <p>{{ process.endDate ? process.endDate : '--'}}</p>
                    </div>
                  </td>
                  <td>
                    {{ process.activePhaseKey ?
                    ( 'phases.' + process.activePhaseKey | translate) : '--'}}
                  </td>
                  <td>
                    <div class="count">
                      {{ process.usersInGroupCount || 0 }}
                    </div>
                  </td>
                  <!--td>
                    <item-list-team-status type="inProgress"></item-list-team-status>
                  </td-->
                  <td class="link" (click)="goToProcessDetail(process.year)">{{
                    ('adminTable.MONITOR' | translate)}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </europ-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </ng-container>
    <!-- dettaglio struttura del processo -->
    <ng-container *ngIf="processYear">
      <ng-container *ngIf="!isLoadingProcessDetails">
        <div class="admin-table-container">
          <ng-container *ngIf="processDetail && processDetail.length">
            <div class="table-content">
              <table>
                <thead>
                  <tr>
                    <th translate="{{ ('adminTable.STRUCTURE' | translate)}}"></th>
                    <th translate="{{ ('phases.GOAL_SETTING' | translate)}}"></th>
                    <th translate="{{ ('phases.DEVELOPMENT_PLAN' | translate)}}"></th>
                    <th translate="{{ ('phases.MID_TERM_REVIEW' | translate)}}"></th>
                    <th translate="{{ ('phases.FINAL_EVALUATION' | translate)}}"></th>
                    <th translate="{{ ('phases.FEEDBACK' | translate)}}"></th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let structure of processDetail"
                    [ngClass]="{'child-structure': structure.isChild || structure.hasChildren}">
                    <ng-container *ngIf="structure.isChild">
                      <td class="structure-title">
                        <ul>
                          <li>{{ structure.name }}</li>
                        </ul>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="!structure.isChild">
                      <td class="structure-title">{{ structure.name}}</td>
                    </ng-container>
                    <td>
                      <item-list-team-status [type]="structure.goalSettingStatus.status" styleItem="border"
                        [message]="structure.goalSettingStatus.statusMessage">
                      </item-list-team-status>
                    </td>
                    <td>
                      <item-list-team-status [type]="structure.developmentPlanStatus.status" styleItem="border"
                        [message]="structure.developmentPlanStatus.statusMessage">
                      </item-list-team-status>
                    </td>
                    <td>
                      <item-list-team-status [type]="structure.midTermReviewStatus.status" styleItem="border"
                        [message]="structure.midTermReviewStatus.statusMessage">
                      </item-list-team-status>
                    </td>
                    <td>
                      <item-list-team-status [type]="structure.finalEvaluationStatus.status" styleItem="border"
                        [message]="structure.finalEvaluationStatus.statusMessage">
                      </item-list-team-status>
                    </td>
                    <td class="link" (click)="openFeedbackDetailsModal(structure.modalData)">
                      {{ structure.totalFeedback || 0}}
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-container *ngIf="!processDetail || !processDetail.length">
            <div class="no-data-wrapper">
              <p translate="adminTable.NO_DATA_PROCESS_DETAIL"></p>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- loader dettaglio -->
      <ng-container *ngIf="isLoadingProcessDetails">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="feedbackDetails" (onClose)="closeFeedbackDetailsModal()" (onConfirm)="closeFeedbackDetailsModal()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <ng-container *ngIf="structureForModal">
    <modal-text-content [modalId]="structureForModal.modalId" [title]="structureForModal.title"
      [subtitle]="structureForModal.subtitle" [text]="structureForModal.description">
    </modal-text-content>
  </ng-container>
</modal>