<div class="avatar-notification-container" [ngClass]="{'white-background': whiteBackground}">
  <ng-container *ngIf="src && !isSvgImg">
    <div class="avatar-wrapper background" [ngClass]="size" [ngStyle]="{'background-image': 'url(' + src + ')'}">
    </div>
  </ng-container>
  <ng-container *ngIf="isSvgImg || !src">
    <div class="avatar-wrapper" [ngClass]="size">
      <svg-icon *ngIf="src && isSvgImg" [src]="src" class="avatar" [applyClass]="true"></svg-icon>
      <p *ngIf="!src">{{ initials }}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="_user && _user.hasNotification">
    <div class="dot" [ngClass]="size"></div>
    <div *ngIf="!hideNew" class="new" [ngClass]="size">
      <p translate="generic.AVATAR_NEW"></p>
    </div>
  </ng-container>
</div>