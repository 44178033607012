import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SenecaResponse } from 'src/commonclasses';
import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'app-speed-screening',
  templateUrl: './speedScreening.component.html',
  styleUrls: ['./speedScreening.component.scss']
})
export class SpeedScreeningComponent implements OnInit, OnDestroy {
  runningYear: number = 0;
  applicationLang: string = '';
  applicationLang$: Subscription;
  loggedUser: any;
  userId: any;
  currentPercentage: number = 10;
  isImpersonate: boolean = false;

  isLoadingUserIds: boolean = false;
  listUserIdsInSpeedScreening$: any;
  userIds: any;
  userIdsNumber: any;
  currentUserNumber: any;
  getUserSpeedScreeningQuestions$: Subscription = new Subscription;
  isLoadingUserQuestions: boolean = false;
  selectedCardId: string = '';

  questionList: any[] = [];
  userNote: string = '';
  fileObject: any;
  resetIncludeUpload: boolean = true;
  selectedUser: any;
  modalAction: string = '';
  withRequirements: boolean = false;

  constructor(
    public translate: TranslateService,
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private managerService: ManagerService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router
  ) {
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.store.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
      }
    });

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.runningYear = params.perfCareYear;
        this.userId = params.userId
        this.withRequirements = window.location.href.indexOf('noRequirement') < 0;
        this.listUserIdsInSpeedScreening();
      });

  }

  listUserIdsInSpeedScreening() {
    this.isLoadingUserIds = true;

    if (this.listUserIdsInSpeedScreening$) {
      this.listUserIdsInSpeedScreening$.unsubscribe();
    }

    this.listUserIdsInSpeedScreening$ = this.managerService.listAllUserIdsInSpeedScreening(this.runningYear, this.withRequirements)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "lus001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.userIds = data.response;
          this.currentUserNumber = this.userIds.indexOf(this.userId);
          this.currentPercentage = Math.floor((this.currentUserNumber + 1) * 100 / this.userIdsNumber);
          this.userIdsNumber = data.response.length;
        }
        this.getUserSpeedScreeningQuestions(this.userId);
        this.isLoadingUserIds = false;
      }
        , (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "lus002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUserIds = false;
        }
      );
  }

  prevUser() {
    this.modalAction = 'prev';
    if (this.selectedCardId == 'ELIGIBLE') {
      this.openCloseSaveModal();
    } else {
      this.saveSpeedData();
    }
  }

  nextUser() {
    this.modalAction = 'next';
    if (this.selectedCardId == 'ELIGIBLE') {
      this.openCloseSaveModal();
    } else {
      this.saveSpeedData();
    }
  }


  getUserSpeedScreeningQuestions(userId: string) {
    this.isLoadingUserQuestions = true;

    if (this.getUserSpeedScreeningQuestions$) {
      this.getUserSpeedScreeningQuestions$.unsubscribe();
    }

    this.selectedCardId = '';
    this.userNote = '';
    this.fileObject = null;

    this.getUserSpeedScreeningQuestions$ = this.managerService.getUserSpeedScreeningQuestions(this.runningYear, userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "soq001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.currentUserNumber = this.userIds.indexOf(userId);
          this.currentPercentage = Math.floor((this.currentUserNumber + 1) * 100 / this.userIdsNumber);
          this.selectedUser = data.response.user;
          this.selectedCardId = this.selectedUser.status;

          this.questionList = [];
          for (let i = 0; i < data.response.questions.length; i++) {
            let question = data.response.questions[i];
            question.answersList = question.answers.map((x: any) => { return { id: x, value: x } });
            if (question.selectedAnswerIndex >= 0) {
              question.selectedAnswer = question.answersList[question.selectedAnswerIndex];
            } else {
              // question.selectedAnswer = question.answersList[1];
              question.selectedAnswer = null;
            }
            this.questionList.push(question);
          }
        }
        this.isLoadingUserQuestions = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "soq002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingUserQuestions = false;
      });
  }

  selectTalentCard(id: string) {
    this.selectedCardId = id;
  }

  userNoteChanged(text: string) {
    this.userNote = text;
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  selectAnswer(answer: any, index: any) {
    this.questionList[index].selectedAnswer = this.questionList[index].answersList.find((x: any) => x.id == answer.id);
  }

  openNextStepTooltip() {
    this.modalService.open('next-step-tooltip');
  }

  closeNextStepTooltip() {
    this.modalService.close('next-step-tooltip');
  }

  canCloseSave() {
    for (let i = 0; i < this.questionList.length; i++) {
      if (this.questionList[i].selectedAnswerIndex < 0) {
        return false;
      }
    }
    if (!this.selectedCardId) {
      return false;
    }
    return true;
  }

  openCloseSaveModal(fromBack?: boolean) {
    if (fromBack) {
      this.modalAction = 'back';
    }
    this.modalService.open('close-save');
  }

  closeSaveModal(confirm?: boolean) {
    this.modalService.close('close-save');
    if (confirm) {
      this.saveSpeedData();
    }
  }

  saveSpeedData() {
    this.isLoadingUserQuestions = true;
    let questionForService = [];
    for (let i = 0; i < this.questionList.length; i++) {
      let answer;
      if (this.questionList[i].selectedAnswer && this.questionList[i].selectedAnswer.id == 'No') {
        answer = 1;
      } else if (this.questionList[i].selectedAnswer && this.questionList[i].selectedAnswer.id == 'Si') {
        answer = 0;
      }
      questionForService.push({
        questionId: this.questionList[i].questionId,
        selectedAnswerIndex: answer
      })
    }
    this.managerService.updateSpeedScreeningQuestions(this.runningYear, this.selectedUser.userId, questionForService, this.selectedCardId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "cls001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {

          if (this.userNote && this.userNote.length) {
            this.managerService.createTalentNote(this.runningYear, this.selectedUser.userId, this.userNote, this.fileObject || null)
              .subscribe((data: any) => {
                if (data && data.error) {
                  // Vedo se c'è la traduzione dell'errore
                  const messageObj: ApplicationModalMessage = {
                    modalId: "in001",
                    text: this.translate.instant("errors." + data.error),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  this.isLoadingUserQuestions = false;
                }
                this.manageAction();
              }, (err: any) => {
                if (err && err.message) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "in002",
                    text: this.translate.instant("errors." + ((err && err.message) || err)),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                }
                this.isLoadingUserQuestions = false;
              });
          } else {
            this.manageAction();
          }
        }
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cls002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingUserQuestions = false;
      });
  }

  manageAction() {
    if (this.modalAction == 'prev' && this.userIds[this.currentUserNumber - 1]) {
      this.getUserSpeedScreeningQuestions(this.userIds[this.currentUserNumber - 1]);
    } else if (this.modalAction == 'next' && this.userIds[this.currentUserNumber + 1]) {
      this.getUserSpeedScreeningQuestions(this.userIds[this.currentUserNumber + 1]);
    } else {
      if (this.modalAction == 'back') {
        this.router.navigate(['manager/talentScouting/', this.runningYear]);
      } else {
        this.isLoadingUserQuestions = false;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
  }
}
