import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SenecaResponse, CanProposeGoalsToManagerResponse, GetMyManagerUserDataResponse, PerfCareMacroGoalTypes, PerfCareGoalRentStatuses, sortByRules, ArrayUtils, PerfCareGoalConsuntivationStatuses, CancelUserFeedbackDialogueForUserResponse, PerfCareDevelopmentActionStatuses, CanShareDevelopmentActionToManagerResponse, PerfCareApplauseFeedback, ListEvidenceFeedbackForUserResponse, ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse, CountFeedbacksForUserResponse, PerfCareFeedbackDialogueTypes, DeletePrivateNoteFeedbackForUserResponse, ListPrivateNoteFeedbackForUserResponse, JwtPayload, PerformanceAssessmentPhaseKeys, PerfCareAssessmentPhaseKeys, PerfCareFinalEvaluationRentStatuses, RequiredAuth, GoalSettingsProposalStatusTypes } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../core/profile/ngrx/profile.actions";
import { Observable, Subscription, of } from 'rxjs';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActionUtils } from 'src/app/utils/action.utils';
import * as moment from 'moment';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { combineLatest } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-collaborator-personDetails',
  templateUrl: './personDetails.component.html',
  styleUrls: ['./personDetails.component.scss']
})
export class CollaboratorPersonDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  personDetails: any;
  isLoadingPersonDetails = false;
  isCollaborator: boolean = false;
  routeParams$!: Subscription;
  userId: string = '';
  runningYear$: any;
  loggedUser$: any;
  runningYear: number = 0;
  getUserGoalList$: Subscription = new Subscription;
  updateUserAck$: Subscription = new Subscription;
  userAcknowledges: any;
  isLoadingUserGoals: boolean;
  goalList: any;
  getUserProfileTab$: Subscription = new Subscription;
  isLoadingTab: boolean;
  contentTabList!: TabDossier[];
  currentContentTab!: TabDossier;
  noDataPlaceholder: any;
  goalTitleInfo: string = "";
  canShareGoals$: Subscription = new Subscription;
  loggedUser: any;
  shareModalContent: any = {
    image: '',
    title: '',
    subtitle: '',
    text: '',
  };
  getManagerData$!: Subscription;
  isLoadingManagerInfo: boolean = true;
  managerData: any;
  getClosestInterview$!: Subscription;
  interview: any;
  isLoadingInterview: boolean = true;
  getUserInterview$: any;
  challengeList: any;
  challengeTitle: string = '';
  roleStiTitle: string = '';
  goalListTeam: any = [];
  goalListTeamForFE: any[] = [];
  challengeTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  roleGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  teamGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  commercialGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  commentDevelopPlanTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  noDataImage: string = '';
  goalAccordionOpened: boolean = false;
  empowerementGoal: any;
  isFetchingDeleteInterview: boolean = false;
  deleteInterview$: Subscription = new Subscription;
  interviewIdToDelete: string = '';
  stiRulesSeen: any;
  isDownloadingRules: boolean = false;

  isFeedbackSection: boolean = false;
  noDataFeedbackPlaceholder: string = '';
  isLoadingFeedbackClaps: boolean = false;
  feedbackClapList: PerfCareApplauseFeedback[] = [];
  clapIdToDelete: string = '';
  getFeedbackClapList$: Subscription = new Subscription;
  isReceiving: boolean = true;
  feedbackUserIdToDelete: string = '';
  isObjectiveSection: boolean = false;
  isIndividualPlanSection: boolean = false;
  isLoadingDevelopmentActions: boolean = false;
  developmentActionsList: any[] = [];
  getDevelopmentActions$: Subscription = new Subscription;
  canCreateDevActions: boolean = false;
  isLoadingCanCreateDevelopmentActions: boolean = false;
  canCreateDevelopmentActions$: Subscription = new Subscription;
  isFetchingAddCommentToDevelopPlan: boolean = false;
  addCommentToDevelopPlan$: Subscription = new Subscription;
  developAction: any = {
    comment: ''
  };
  developActionModalText: string = '';
  isLoadingShareDevelopActions: boolean = false;
  shareDevelopActions$: Subscription = new Subscription;
  noDataDevelopmentActionsPlaceholder: string = '';
  developActionEdit: any;
  commentToEdit: any;
  actionForCommentDelete: any;
  commentIdToDelete: any;
  addCommentToDevelopAction$: Subscription = new Subscription;
  newActionComment: string = '';
  isLoadingDeleteDevelopAction: boolean = false;
  deleteDevelopAction$: Subscription = new Subscription;
  developActionIdToDelete: string = '';
  developActionStatusesOptions: any[] = [];
  currentActionStatus: any;
  changeActionStatus$: Subscription = new Subscription;
  isFetchingCanShareActions: boolean = false;
  canShareDevelopActions$: Subscription = new Subscription;
  canShareDevActions: boolean = false;
  isFetchingFeedbackTabs: boolean = false;
  evidencesList: any[] = [];
  isLoadingFeedbackEvidences: boolean = false;
  getFeedbackEvidencesList$: Subscription = new Subscription;
  fileTitle: string = '';
  fileObject: any;
  isToResetUploadComponent: boolean = false;
  isSmartFeedback: boolean = false;
  isEvidencesFeedback: boolean = false;
  contentFeedbackTabList!: TabDossier[];
  currentContentFeedbackTab!: TabDossier;
  isLoadingUploadFileOnEvidences: boolean = false;
  evidenceIdToManageFile: string = '';
  evidenceUploadIdToDelete: string = '';
  isLoadingDeleteFileOnEvidence: boolean = false;
  isLoadingGetDownloadUrlOnEvidence: boolean = false;
  evidenceIdOfFileToDelete: string = '';
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToEvidence$: Subscription = new Subscription;
  canGoAddEvidence: boolean = false;
  getCounterFeedbackTabs$: Subscription = new Subscription;
  isLoadingFeedbackTabsCounters: boolean = false;
  feedbackTabCounters: any;
  isPrivateNotes: boolean = false;
  isLoadingPrivateNotes: boolean = false;
  privateNotesList: any[] = [];
  noteIdToDelete: string = '';
  // tab feedback colloqui
  isInterview: boolean = false;
  isLoadingInterviewsList: boolean = true;
  getInterviewsList$: Subscription = new Subscription;
  interviewList: any[] = [];
  getPrivateNotesList$: any;
  // Peer Feedback
  isPeerFeedback: boolean = false;
  isLoadingPeerFeedback: boolean = false;
  peerFeedbackList: any[] = [];
  peerFeedbackPlaceholder: string = '';
  peerFeedbackToDelete: string = '';
  getPeerFeedbackList$: Subscription = new Subscription;
  feedbackForRequestModal: any;
  deletePeerFeedback$: Subscription = new Subscription;
  peerSent: boolean = false;
  peerFeedbackAllowed$: Subscription = new Subscription;
  peerFeedbackAllowed: boolean = false;
  combinedSelected$: Subscription = new Subscription;
  isChief: boolean = false;
  isManager: boolean = false;
  // performance level
  perfromanceLevelActive: boolean = false;
  performanceLevelTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  performanceLevelOpened: boolean = true;
  performanceLevelDone: boolean = false;
  performanceStatus: { status: string; statusMessage: any; } = {
    status: "",
    statusMessage: ""
  };
  performanceLevelEvaluation: string = "";
  getRunningPhase$: Subscription;
  runningPhase: any;
  finalEvaluationForModal: any;
  isLoadingMyEvaluation: boolean = false;
  getMyFinalEvaluation$: Subscription = new Subscription;
  finalEvaluationStatus: any;
  sharedStatuses = [
    PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_HIDDEN,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED,
  ]
  noEvaluationPost: boolean = false;
  managerFinalEvaluation: any;
  managerFinalEvaluationObservation: any;
  userFinalEvaluationDate: any;
  performanceLevelObservation: any;
  performanceLevelEvaluationDate: any;
  managerFinalEvaluationDate: any;
  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  // Post calibration
  isLoadingUserCalibration: boolean = false;
  calibrationActive: boolean = false;
  postCalibrationData: {
    level: string;
    status: {
      statusMessage: string;
      status: string; // 'inProgress, completed, toStart
    };
    observations: string,
    payout: {
      id: number,
      title: string
    }
  } | any;
  translations: any;
  getPostCalibrationData$: Subscription = new Subscription();

  calibrationStatuses = [
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_TO_CONFIRM_EVALUATION,
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_IN_CALIBRATION,
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_BY_MANAGER,
    PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED
  ]
  isProcessClosed$: Subscription = new Subscription;
  isProcessClosed: boolean = false;
  stiAmount: number = 0;
  roleStiDescr: string = '';
  deleteId: string = '';
  deleteObjective$: Subscription = new Subscription;
  objectiveForModal: any;
  proposalStatus: any;
  getProposalStatus$: any;
  proposalStatusComment: any;
  getCascadingGoals$: Subscription = new Subscription;
  isLoadingCascading: boolean = false;
  cascadingGoal: any;
  getObjectiveOnHold$: Subscription = new Subscription;
  draftGoalList: any[] = [];
  maxZeroWeightedGoals: number = 3;
  maxRoleTypeNumber: number = 0;
  roleObjectiveWeight: number = 0;
  totalWeight: number = 0;
  getFinalEvaluationDate$: any;
  finalEvaluationDate: any;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private collaboratorService: CollaboratorService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private http: HttpClient) {
    this.isLoadingUserGoals = true;
    this.isLoadingTab = true;
    this.contentTabList = [];
    this.goalList = [];
    this.developmentActionsList = [];
    this.contentFeedbackTabList = [];

    this.peerFeedbackAllowed$ = this.store.select(fromApp.getPeerFeedbackAllowed)
      .subscribe((peerFeedbackAllowed) => {
        this.peerFeedbackAllowed = peerFeedbackAllowed;
      })

    this.getRunningPhase$ = this.store.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
      this.runningPhase = runningPhase;
    })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;

      if (this.runningYear) {
        this.routeParams$ = this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.getPerformanceAssessmentYears();

              if (params.perfCareYear) {
                this.selectedYear = {
                  id: params.perfCareYear,
                  name: 'Anno ' + params.perfCareYear
                }
              } else {
                this.selectedYear = {
                  id: this.runningYear,
                  name: 'Anno ' + this.runningYear
                }
              }
              this.checkProcessClosed();

              if (window.location.href.indexOf('feedback') > -1) {
                this.isFeedbackSection = true;
                if (window.location.href.indexOf('smartFeedback') > -1) {
                  this.isSmartFeedback = true;
                } else if (window.location.href.indexOf('evidences') > -1) {
                  this.isEvidencesFeedback = true;
                } else if (window.location.href.indexOf('privateNotes') > -1) {
                  this.isPrivateNotes = true;
                } else if (window.location.href.indexOf('interview') > -1) {
                  this.isInterview = true;
                } else if (window.location.href.indexOf('peerFeedback') > -1) {
                  this.isPeerFeedback = true;
                }
              } else if (window.location.href.indexOf('developmentPlan') > -1) {
                this.isIndividualPlanSection = true;
              } else {
                this.isObjectiveSection = true
              }
              this.isLoadingPersonDetails = true;

              // RESET dati performance level
              this.resetData();

              const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
              const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager);
              const isChief$: Observable<boolean> = this.store.select(fromApp.getIsChief);
              this.combinedSelected$ = combineLatest([loggedUser$, isManager$, isChief$]).subscribe(
                ([loggedUser, isManager, isChief]) => {
                  this.personDetails = loggedUser && loggedUser.user;
                  this.stiAmount = this.personDetails?.stiAmount?.low || loggedUser?.params?.userStiAmountByYear?.[this.selectedYear.id] || 0;

                  if (loggedUser && loggedUser.params && loggedUser.params.userProfilesByYear && Object.keys(loggedUser.params.userProfilesByYear).length) {
                    let auths = loggedUser.params.userProfilesByYear[this.selectedYear.id];
                    if (auths && auths.find((x: string) => x == RequiredAuth.PERFORMANCECARE_USER_OPERATIONS)) {
                      this.router.navigate(['collaboratorOperations/personDetails/' + loggedUser.user.userId + '/' + this.selectedYear.id]);
                    }
                  }

                  this.isManager = isManager;
                  this.isChief = isChief;

                  this.store.select(fromApp.getUserAcknowledges)
                    .subscribe((userAck: any) => {
                      this.userAcknowledges = userAck;
                      if (this.selectedYear.id >= 2024) {
                        this.stiRulesSeen = userAck && userAck.data && !!userAck.data['STI_RULE_WATCHED_' + this.selectedYear.id];
                      } else {
                        this.stiRulesSeen = userAck && userAck.data && !!userAck.data['STI_RULE_WATCHED'];
                      }
                    })

                  this.isLoadingPersonDetails = false;
                  // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                  if (this.personDetails && this.personDetails.userId) {
                    this.noDataFeedbackPlaceholder = '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>' + this.translate.instant('feedbackSection.PAGE_SUBTITLE');
                    this.noDataPlaceholder = this.translate.instant('goal.ADD_OBJECTIVE') + '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>';

                    this.isLoadingPersonDetails = false;
                    this.perfromanceLevelActive = false;
                    this.performanceLevelDone = false;
                    this.postCalibrationData = {};

                    this.getClosestInterview();
                    this.getManagerData();
                    this.getProposalStatus();
                    if (this.isObjectiveSection) {
                      this.getMyFinalEvaluation()
                      this.getCascadingGoals();
                    } else if (this.isIndividualPlanSection) {
                      this.canShareActions();
                      this.canCreateActions();
                      this.getDevelopmentActions();
                    } else if (this.isFeedbackSection) {
                      this.isLoadingTab = false;
                      this.getFeedbackTabsCounters();
                    }
                  }
                });
            }
          );
      }
    });

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
      }
    })
  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        "accordion.GUIDELINES_STI",
        "modals.CHALLENGE.TITLE",
        "modals.CHALLENGE.SUBTITLE",
        "modals.CHALLENGE.TEXT",
        "modals.ROLE.TITLE",
        "modals.ROLE.SUBTITLE",
        "modals.ROLE.TEXT",
        "developmentPlan.PAGE_SUBTITLE_COLLABORATOR",
        "developmentPlan.statuses.toStart",
        "developmentPlan.statuses.inProgress",
        "developmentPlan.statuses.completed",
        "performance.ACCORDION_TITLE",
        "performance.ACCORDION_TOOLTIP",
        "performance.statuses.TO_DEFINE",
        "performance.statuses.TO_CONFIRM",
        'performance.statuses.COMPLETED',
        'goal.STI_RULE_CONTENT_1',
        'goal.STI_RULE_CONTENT_2',
        'objectiveWizard.1.modal.W_ERR_TITLE',
        'objectiveWizard.1.modal.W_ERR_SUBTITLE',
        'objectiveWizard.1.modal.W_ERR_DESCR',
      ])
      .subscribe(translations => {
        this.translations = translations;
        this.roleStiTitle = translations['accordion.GUIDELINES_STI'];
        this.roleStiDescr = translations['goal.STI_RULE_CONTENT_1'] + this.selectedYear.id + translations['goal.STI_RULE_CONTENT_2'];
        this.challengeTitle = translations['accordion.CHALLENGE'] + ' ' + this.runningYear;
        this.challengeTooltipModal = {
          modalId: "154",
          title: translations["modals.CHALLENGE.TITLE"] + this.runningYear,
          subtitle: translations["modals.CHALLENGE.SUBTITLE"],
          text: translations["modals.CHALLENGE.TEXT"]
        };
        this.roleGoalsTooltipModal = {
          modalId: "168",
          title: translations["modals.ROLE.TITLE"],
          text: translations["modals.ROLE.TEXT"]
        };
        this.teamGoalsTooltipModal = {
          modalId: "500",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.commercialGoalsTooltipModal = {
          modalId: "501",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.commentDevelopPlanTooltipModal = {
          modalId: "d027",
          title: 'Commento azione di sviluppo',
          subtitle: 'Lorem Ipsum',
          text: 'Lorem Ipsum'
        };
        this.performanceLevelTooltip = {
          modalId: "pl001",
          title: translations["performance.ACCORDION_TITLE"],
          text: translations["performance.ACCORDION_TOOLTIP"]
        };
        this.performanceStatus = {
          status: "toStart",
          statusMessage: translations["performance.statuses.TO_DEFINE"]
        }

        this.developActionStatusesOptions = [
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_START,
            title: translations["developmentPlan.statuses.toStart"]
          },
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_STARTED,
            title: translations["developmentPlan.statuses.inProgress"]
          },
          {
            id: PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_COMPLETED,
            title: translations["developmentPlan.statuses.completed"]
          }
        ]
        this.noDataDevelopmentActionsPlaceholder = translations['developmentPlan.PAGE_SUBTITLE_COLLABORATOR'];
      })
  };

  ngAfterViewChecked() {
    this.challengeTitle = this.translate.instant('accordion.CHALLENGE') + ' ' + this.selectedYear.id;
  }

  getFeedbackTabsCounters() {
    this.isLoadingFeedbackTabsCounters = true;
    if (this.getCounterFeedbackTabs$) {
      this.getCounterFeedbackTabs$.unsubscribe();
    }
    this.getCounterFeedbackTabs$ = this.collaboratorService.getCounterFeedbackTabs(this.selectedYear.id)
      .subscribe((data: SenecaResponse<CountFeedbacksForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          this.feedbackTabCounters = data.response;
        } else {
          this.feedbackTabCounters = null;
        }
        this.setFeedbackList();
        this.isLoadingFeedbackTabsCounters = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackTabsCounters = false;
      });
  }

  setFeedbackList() {
    this.isFetchingFeedbackTabs = true;
    this.translate.get(
      [
        "goal.tabs.PEER_FEEDBACK_TAB",
        "goal.tabs.CLAP_TAB",
        "goal.tabs.EVIDENCES_TAB",
        "goal.tabs.PRIVATE_NOTES_TAB",
        "goal.tabs.INTERVIEW_TAB"
      ])
      .subscribe(translations => {
        if (!this.isChief) {
          this.contentFeedbackTabList = [
            {
              id: 'PEER_FEEDBACK_TAB',
              title: translations["goal.tabs.PEER_FEEDBACK_TAB"],
              attrAriaLabel: translations["goal.tabs.PEER_FEEDBACK_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.peerFeedbackCount) || 0,
              badgeGrey: true
            },
            {
              id: 'CLAP_TAB',
              title: translations["goal.tabs.CLAP_TAB"],
              attrAriaLabel: translations["goal.tabs.CLAP_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.applauseFeedbackCount) || 0,
              badgeGrey: true
            },
            {
              id: 'EVIDENCES_TAB',
              title: translations["goal.tabs.EVIDENCES_TAB"],
              attrAriaLabel: translations["goal.tabs.EVIDENCES_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.evidenceFeedbackCount) || 0,
              badgeGrey: true
            },
            {
              id: 'PRIVATE_NOTES_TAB',
              title: translations["goal.tabs.PRIVATE_NOTES_TAB"],
              attrAriaLabel: translations["goal.tabs.PRIVATE_NOTES_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.privateNoteCount) || 0,
              badgeGrey: true
            },
            {
              id: 'INTERVIEW_TAB',
              title: translations["goal.tabs.INTERVIEW_TAB"],
              attrAriaLabel: translations["goal.tabs.INTERVIEW_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.feedbackDialogueCount) || 0,
              badgeGrey: true
            }
          ];

          if (this.isPeerFeedback) {
            this.getPeerFeedbackList();
            this.peerFeedbackPlaceholder = this.translate.instant('peerFeedback.PLACEHOLDER_COLLABORATOR');
            this.currentContentFeedbackTab = this.contentFeedbackTabList[0]
            this.contentFeedbackTabList[0].isDisabled = false;
          } else if (this.isSmartFeedback) {
            this.getFeedbackClapList();
            this.currentContentFeedbackTab = this.contentFeedbackTabList[1];
            this.contentFeedbackTabList[1].isDisabled = false;
          } else if (this.isEvidencesFeedback) {
            this.getFeedbackEvidencesList();
            this.getGoalsToAssociate();
            this.currentContentFeedbackTab = this.contentFeedbackTabList[2];
            this.contentFeedbackTabList[2].isDisabled = false;
          } else if (this.isPrivateNotes) {
            this.getPrivateNotesList();
            this.currentContentFeedbackTab = this.contentFeedbackTabList[3];
            this.contentFeedbackTabList[3].isDisabled = false;
          } else if (this.isInterview) {
            this.getInterviewsList();
            this.currentContentFeedbackTab = this.contentFeedbackTabList[4];
            this.contentFeedbackTabList[4].isDisabled = false;
          }
        } else {
          this.contentFeedbackTabList = [
            {
              id: 'PEER_FEEDBACK_TAB',
              title: translations["goal.tabs.PEER_FEEDBACK_TAB"],
              attrAriaLabel: translations["goal.tabs.PEER_FEEDBACK_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.peerFeedbackCount) || 0,
              badgeGrey: true
            },
            {
              id: 'CLAP_TAB',
              title: translations["goal.tabs.CLAP_TAB"],
              attrAriaLabel: translations["goal.tabs.CLAP_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.applauseFeedbackCount) || 0,
              badgeGrey: true
            },
            {
              id: 'PRIVATE_NOTES_TAB',
              title: translations["goal.tabs.PRIVATE_NOTES_TAB"],
              attrAriaLabel: translations["goal.tabs.PRIVATE_NOTES_TAB"],
              isDisabled: true,
              badgeNumberVisible: true,
              badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.privateNoteCount) || 0,
              badgeGrey: true
            }
          ];

          if (this.isPeerFeedback) {
            this.getPeerFeedbackList();
            this.peerFeedbackPlaceholder = this.translate.instant('peerFeedback.PLACEHOLDER_COLLABORATOR');
            this.currentContentFeedbackTab = this.contentFeedbackTabList[0]
            this.contentFeedbackTabList[0].isDisabled = false;
          } else if (this.isSmartFeedback) {
            this.getFeedbackClapList();
            this.currentContentFeedbackTab = this.contentFeedbackTabList[1];
            this.contentFeedbackTabList[1].isDisabled = false;
          } else if (this.isPrivateNotes) {
            this.getPrivateNotesList();
            this.currentContentFeedbackTab = this.contentFeedbackTabList[2];
            this.contentFeedbackTabList[2].isDisabled = false;
          }
        }
        this.isFetchingFeedbackTabs = false;
      })
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociate() {
    this.isLoadingGoalsToAssociate = true;
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    this.getGoalsToAssociateToEvidence$ = this.collaboratorService.getGoalsToAssociateWithEvidence()
      .subscribe((data: SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.canGoAddEvidence = true;
        } else {
          this.canGoAddEvidence = false;
        }
        this.isLoadingGoalsToAssociate = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsToAssociate = false;
      });
  }

  // ritorna la lista di evidenze
  getFeedbackEvidencesList() {
    this.isLoadingFeedbackEvidences = true;
    if (this.getFeedbackEvidencesList$) {
      this.getFeedbackEvidencesList$.unsubscribe();
    }
    this.getFeedbackEvidencesList$ = this.collaboratorService.getEvidences(this.selectedYear.id).
      subscribe((data: SenecaResponse<ListEvidenceFeedbackForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.evidencesList = data.response;

          this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
            moment.locale(applicationLang);

            for (let i = 0; i < this.evidencesList.length; i++) {
              this.evidencesList[i].formattedCreationDate = moment(this.evidencesList[i].creationDate).fromNow();
            }
          })
        } else {
          this.evidencesList.length = 0;
        }
        this.isLoadingFeedbackEvidences = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackEvidences = false;
      });
  }

  // Se il manager può condividere le azioni di sviluppo
  canShareActions() {
    this.isFetchingCanShareActions = true;

    this.canShareDevelopActions$ = this.collaboratorService.canShareDevelopmentActions(this.selectedYear.id)
      .subscribe((data: SenecaResponse<CanShareDevelopmentActionToManagerResponse>) => {
        if (data && data.error) {
          this.canShareDevActions = false;
          this.isFetchingCanShareActions = false;
        } else if (data && data.response) {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = true;
        } else {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = false;
        }
      }, (err?: any) => {
        this.isFetchingCanShareActions = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "000",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  // Recupera stato per item list team status dell'azione di sviluppo
  getDevelopmentPlanStatus(developmentStatus: string) {
    if (developmentStatus == 'toStart') {
      return {
        status: 'toStart',
        statusMessage: 'developmentPlan.statuses.' + developmentStatus
      }
    } else {
      return {
        status: '',
        statusMessage: ''
      }
    }
  }

  // TODO solo per test
  resetOnboardingAck() {
    if (this.updateUserAck$) {
      this.updateUserAck$.unsubscribe();
    }
    let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
    acks.data['PERFORMANCE_CARE_ONBOARDING'] = null
    this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
      subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "096",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          window.location.reload();
        }
      })
  }

  // Recupera l'ultimo colloquio
  getClosestInterview() {
    this.isLoadingInterview = true;
    if (this.getClosestInterview$) {
      this.getClosestInterview$.unsubscribe();
    }
    this.getClosestInterview$ = this.collaboratorService.getClosestInterview(this.selectedYear.id.toString())
      .subscribe((interviewData: SenecaResponse<any>) => {
        if (interviewData && interviewData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "055",
            text: this.translate.instant("errors." + interviewData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.interview = interviewData.response;
          if (this.interview && this.interview.creationUserId == this.loggedUser.userId) {
            this.interview.isEditabled = true;
          }
        }
        this.isLoadingInterview = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "056",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingInterview = false;
      });
  }

  // Quando cambi bottone nell'header dossier
  changeButtonHeaderDossier(id: string, redirectOnly?: boolean) {
    this.isObjectiveSection = false
    this.isIndividualPlanSection = false
    this.isFeedbackSection = false
    switch (id) {
      case 'objectives':
        this.isObjectiveSection = true;
        if (parseInt(this.selectedYear.id, 10) < 2023 || redirectOnly) { // || (this.proposalStatus && this.proposalStatus.status == 'APPROVED' || this.proposalStatus.status == 'PROPOSED')
          this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.selectedYear.id);
        } else {
          this.getMyFinalEvaluation()
          this.getCascadingGoals();
          // sposto qui il controllo, devo recuperare la proposta per l'anno scelto
          this.getProposalStatus();
          // this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.selectedYear.id]);
        }
        break;
      case 'feedback':
        this.isFeedbackSection = true;
        this.redirectService.goToPersonDetailsFeedbackCollaborator(this.userId, false, this.selectedYear.id);
        break;
      case 'individualPlan':
        this.isIndividualPlanSection = true;
        this.redirectService.goToPersonDetailsDevelopmentPlanCollaborator(this.userId, false, this.selectedYear.id);
        break;
    }
  }

  // Mostra commento azione di sviluppo
  showActionComment(object: any) {
    let objectModal: ApplicationModalMessage = {
      modalId: "d031",
      title: this.translate.instant("developmentPlan.DEVELOP_ACTION_COMMENT"),
      subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + moment(object.creationDate).format('DD/MM/YYYY'),
      text: object.comment
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: objectModal }));
  }

  // Recupera un booleano per sapere se si possono creare azioni di sviluppo
  canCreateActions() {
    this.isLoadingCanCreateDevelopmentActions = true;
    if (this.canCreateDevelopmentActions$) {
      this.canCreateDevelopmentActions$.unsubscribe();
    }

    this.canCreateDevelopmentActions$ = this.collaboratorService.canCreateDevelopmentActions(this.selectedYear.id)
      .subscribe((data: any) => {
        if (data.error) {
        } else if (data.response) {
          this.canCreateDevActions = true;
        } else {
          this.canCreateDevActions = false;
        }
        this.isLoadingCanCreateDevelopmentActions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCanCreateDevelopmentActions = false;
      }
      );
  }

  // Apre la modale per condividere le azioni di sviluppo
  openDevelopActionsShareModal() {
    this.modalService.open('developActionsShareModal');
  }

  // Chiude la modale per condividere le azioni di sviluppo
  closeDevelopActionsShareModal() {
    this.modalService.close('developActionsShareModal');
  }

  // Condividi le azioni di sviluppo
  shareDevelopActions() {
    this.closeDevelopActionsShareModal();
    this.isLoadingDevelopmentActions = true;
    if (this.shareDevelopActions$) {
      this.shareDevelopActions$.unsubscribe();
    }

    this.shareDevelopActions$ = this.collaboratorService.shareDevelopActions(this.selectedYear.id.toString())
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        if (data.obtainedBadges) {
          // controllo se sono stati rilasciati badge
          let badges = data.obtainedBadges;
          let tmpBadgesList: BadgeObject[] = [];
          if (badges && badges.length) {
            tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
            this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
          }
        }
        if (data.obtainedBadges) {
          // controllo se sono stati rilasciati badge
          let badges = data.obtainedBadges;
          let tmpBadgesList: BadgeObject[] = [];
          if (badges && badges.length) {
            tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
            this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
          }
        }
        this.canShareActions();
        this.getDevelopmentActions();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDevelopmentActions = false;
      }
      );
  }

  // Recupera le azioni di sviluppo
  getDevelopmentActions() {
    this.isLoadingDevelopmentActions = true;
    if (this.getDevelopmentActions$) {
      this.getDevelopmentActions$.unsubscribe();
    }

    this.getDevelopmentActions$ = this.collaboratorService.getDevelopmentActions(this.selectedYear.id, this.userId)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.developmentActionsList = ActionUtils.formatActionList(data.response, this.personDetails, false, this.translate);
        } else {
          this.developmentActionsList.length = 0;
        }
        this.isLoadingDevelopmentActions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDevelopmentActions = false;
      }
      );
  }

  // Cambia option stato azione di sviluppo
  changeDevelopActionStatus(data: any) {
    this.currentActionStatus = data;
  }

  updateStatusDevelopAction() {
    this.isLoadingDevelopmentActions = true;
    this.modalService.close('updateStatusDevelopmentPlanModal')
    if (this.changeActionStatus$) {
      this.changeActionStatus$.unsubscribe();
    }
    this.changeActionStatus$ = this.collaboratorService.changeDevelopmentActionStatus(this.selectedYear.id.toString(), this.developAction.id, this.currentActionStatus.id)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d039",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d040",
            title: this.translate.instant("developmentPlan.STATUS_CHANGED"),
            text: this.translate.instant("developmentPlan.STATUS_CHANGED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.getDevelopmentActions();
        this.developAction = null;
        this.currentActionStatus = null;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d041",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.developAction = null;
        this.currentActionStatus = null;
        this.getDevelopmentActions();
      });
  }

  // Apri la modale per aggiornare l'avanzamento dell'azione di sviluppo
  openModalUpdateStatusDevelopAction(developAction: any) {
    this.developAction = developAction;
    this.currentActionStatus = this.developActionStatusesOptions.filter((x: any) => x.id == developAction.status)[0];
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + developAction.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      developAction.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + developAction.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + developAction.toStartDate + '</p>';
    this.modalService.open('updateStatusDevelopmentPlanModal');
  }

  // Chiudi la modale per aggiornare l'avanzamento dell'azione di sviluppo
  closeModalUpdateStatusDevelopAction() {
    this.modalService.close('updateStatusDevelopmentPlanModal');
    this.developAction = null;
    this.developActionModalText = '';
  }

  // Recupera i dati del manager
  getManagerData() {
    this.isLoadingManagerInfo = true;
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((managerData: SenecaResponse<GetMyManagerUserDataResponse>) => {
        if (managerData && managerData.error) {
          if (!this.isManager) {
            const messageObj: ApplicationModalMessage = {
              modalId: "057",
              text: this.translate.instant("errors." + managerData.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.isChief = true;
            this.store.dispatch(ProfileActions.SetIsChief({ payload: true }));
            this.isLoadingManagerInfo = false;
          }
        } else {
          this.managerData = managerData.response;
          this.noDataPlaceholder = this.translate.instant('goal.PROPOSE_TO_1') + '<b>' + this.managerData.forename + ' ' + this.managerData.surname + '</b>' + this.translate.instant('goal.PROPOSE_TO_2_CHALLENGE');
          this.noDataImage = this.personDetails.chiaveSesso === 'F' ? '/assets/img/section/share-goal-female.svg' : '/assets/img/section/share-goal-male.svg';
        }
        this.isLoadingManagerInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerInfo = false;
      });
  }

  // Recupera la lista degli obiettivi
  getUserGoalList() {
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    this.getUserGoalList$ = this.collaboratorService.listUserGoals(this.selectedYear.id, (this.currentContentTab && this.currentContentTab.id))
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "059",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let tempList = GoalUtils.formatGoalCardList(this.translate, goalList.response, false, this.userId);
          this.goalList = [];
          if (!this.currentContentTab.id.includes('DRAFT')) {
            this.draftGoalList = [];
          }
          if (this.personDetails.isOperation) {
            this.goalListTeamForFE.length = 0;
            this.goalListTeam = tempList.filter((x: any) => x.creationUserId !== this.userId && (x.type == PerfCareMacroGoalTypes.TEAM || x.type == PerfCareMacroGoalTypes.COMMERCIAL));
            this.goalListTeam = ArrayUtils.groupByKey((this.goalListTeam), (goal: any) => goal.consuntivation?.consuntivationId);
            for (const consuntivationId in this.goalListTeam) {
              const goals = this.goalListTeam[consuntivationId];
              let res = {
                list: goals[0].consuntivation,
                order: goals[0].consuntivation?.order,
                goals: goals.sort((a: any, b: any) => sortByRules(a, b, false, [{ fieldExtractor: (x) => -x.weight }, { fieldExtractor: (x) => x.title }])),
                statusData: this.getTeamSemesterStatusData(goals[0].consuntivation?.consuntivationStatus),
                accordionTitle: ((goals[0] && goals[0].consuntivation && goals[0].consuntivation?.order) + 1) + '° ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0]) + ' ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0], true)
              }
              this.goalListTeamForFE.push(res);
            }
            if (this.goalListTeamForFE && this.goalListTeamForFE.length > 0) {
              this.goalListTeamForFE.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.order }));
            }

            this.empowerementGoal = tempList.filter((x: any) => x.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
            this.goalList = tempList.filter((x: any) => x.creationUserId !== this.userId && x.type != PerfCareMacroGoalTypes.TEAM && x.type != PerfCareMacroGoalTypes.COMMERCIAL && x.type != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
            this.challengeList = tempList.filter((x: any) => x.creationUserId == this.userId && x.type != PerfCareMacroGoalTypes.TEAM && x.type != PerfCareMacroGoalTypes.COMMERCIAL && x.type != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);

            let maxLength = Math.max(this.goalListTeamForFE.length, this.empowerementGoal?.length, this.goalList?.length, this.challengeList?.length);
            let data;
            for (let i = 0; i < maxLength; i++) {
              data = null;
              if (this.goalListTeamForFE[i]) {
                data = sessionStorage.getItem(this.goalListTeamForFE[i].id);
                if (data) {
                  this.goalListTeamForFE[i].finalEvaluation = JSON.parse(data);
                }
              }
              if (this.empowerementGoal[i]) {
                data = sessionStorage.getItem(this.empowerementGoal[i].id);
                if (data) {
                  this.empowerementGoal[i].finalEvaluation = JSON.parse(data);
                }
              }
              if (this.goalList[i]) {
                data = sessionStorage.getItem(this.goalList[i].id);
                if (data) {
                  this.goalList[i].finalEvaluation = JSON.parse(data);
                }
              }
              if (this.challengeList[i]) {
                data = sessionStorage.getItem(this.challengeList[i].id);
                if (data) {
                  this.challengeList[i].finalEvaluation = JSON.parse(data);
                }
              }
            }
          } else {
            this.empowerementGoal = tempList.find((obj: any) => obj.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
            this.goalListTeamForFE.length = 0;
            this.goalListTeam = tempList.filter((x: any) => x.creationUserId !== this.userId && (x.type == PerfCareMacroGoalTypes.TEAM || x.type == PerfCareMacroGoalTypes.COMMERCIAL));
            this.goalListTeam = ArrayUtils.groupByKey((this.goalListTeam), (goal: any) => goal.consuntivation?.consuntivationId);
            for (const consuntivationId in this.goalListTeam) {
              const goals = this.goalListTeam[consuntivationId];
              let res = {
                list: goals[0].consuntivation,
                order: goals[0].consuntivation?.order,
                goals: goals.sort((a: any, b: any) => sortByRules(a, b, false, [{ fieldExtractor: (x) => -x.weight }, { fieldExtractor: (x) => x.title }])),
                statusData: this.getTeamSemesterStatusData(goals[0].consuntivation?.consuntivationStatus),
                accordionTitle: ((goals[0] && goals[0].consuntivation && goals[0].consuntivation?.order) + 1) + '° ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0]) + ' ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0], true)
              }
              this.goalListTeamForFE.push(res);
            }
            if (this.goalListTeamForFE && this.goalListTeamForFE.length > 0) {
              this.goalListTeamForFE.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.order }));
            }

            let excludedTypes = [PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT, PerfCareMacroGoalTypes.TEAM, PerfCareMacroGoalTypes.COMMERCIAL, PerfCareMacroGoalTypes.TEMP_INDIVIDUAL];
            this.goalList = tempList.filter((obj: any) => !excludedTypes.includes(obj.type) && obj.type);
            this.roleObjectiveWeight = 0;
            for (let i = 0; i < this.goalList.length; i++) {
              this.roleObjectiveWeight += this.goalList[i].weight;
            }
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "060",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Organizza colloquio
  goToInterview(id?: string) {
    if (!id) {
      this.router.navigate(['./collaborator/organizeInterview/' + this.userId]);
    } else {
      this.router.navigate(['./collaborator/organizeInterview/' + this.userId + '/' + id]);
    }
  }


  // Formatta i goalType delle tab
  getGoalTypes(goalTypes: any[]) {
    let tmpMessages = [];
    for (let i = 0; i < goalTypes.length; i++) {
      let accordionTitle = '';
      if (goalTypes[i].goalType == PerfCareMacroGoalTypes.TEAM || goalTypes[i].goalType == PerfCareMacroGoalTypes.COMMERCIAL) {
        if (goalTypes[i].maxNumberOfGoals) {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals + ' a ' + this.translate.instant("goalSetting.updatePMGoal." + goalTypes[i].consuntivazione);
        } else {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% ' + 'a ' + this.translate.instant("goalSetting.updatePMGoal." + goalTypes[i].consuntivazione);
        }
      } else {
        if (goalTypes[i].goalType == PerfCareMacroGoalTypes.INDIVIDUAL) {
          this.maxZeroWeightedGoals = goalTypes[i].maxZeroWeightedGoals;
          this.maxRoleTypeNumber = goalTypes[i].maxNumberOfGoals;
        }
        if (goalTypes[i].maxNumberOfGoals) {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals;
        } else {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '%';
        }
      }
      if (goalTypes[i].weight && goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          message: this.translate.instant("goal.messages.WEIGHT_INFO_1") + ' ' + goalTypes[i].weight + '% ' +
            this.translate.instant("goal.messages.WEIGHT_INFO_2") + ' ' + goalTypes[i].maxNumberOfGoals + ' ' +
            this.translate.instant("goal.messages.WEIGHT_INFO_3"),
          weight: goalTypes[i].weight,
          maxNumberOfGoals: goalTypes[i].maxNumberOfGoals
        })
      } else if (!goalTypes[i].weight && goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          message: this.translate.instant("goal.messages.WEIGHT_DEV_1") + ' ' + goalTypes[i].maxNumberOfGoals + ' ' +
            this.translate.instant("goal.messages.WEIGHT_DEV_2"),
          weight: goalTypes[i].weight,
          maxNumberOfGoals: goalTypes[i].maxNumberOfGoals
        })
      } else if (goalTypes[i].weight && !goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          weight: goalTypes[i].weight
        })
      }
    }
    return tmpMessages;
  }

  // Recupera un oggetto per lo stato nell'accordion
  getTeamSemesterStatusData(consuntivationStatus: string) {
    if (consuntivationStatus == PerfCareGoalConsuntivationStatuses.PERFCARE_CONSUNTIVATION_COMPLETED) {
      return {
        status: 'completed',
        statusMessage: 'goalSetting.updatePMGoal.consuntivations.' + consuntivationStatus
      }
    } else if (consuntivationStatus == PerfCareGoalConsuntivationStatuses.PERFCARE_CONSUNTIVATION_RUNNING) {
      return {
        status: 'inProgress',
        statusMessage: 'goalSetting.updatePMGoal.consuntivations.' + consuntivationStatus
      }
    } else if (consuntivationStatus == PerfCareGoalConsuntivationStatuses.PERFCARE_CONSUNTIVATION_TO_DECLINE) {
      return {
        status: 'toStart',
        statusMessage: 'goalSetting.updatePMGoal.consuntivations.' + consuntivationStatus
      }
    } else {
      return {
        status: '',
        statusMessage: ''
      }
    }
  }

  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab() {
    this.isLoadingTab = true;
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    this.getUserProfileTab$ = this.collaboratorService.getObjectiveWeightsByProfile(this.selectedYear.id)
      .subscribe((tabList: SenecaResponse<any>) => {
        if (tabList.error || !tabList.response) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(tabList && tabList.error);
          }
        } else {
          this.contentTabList = []
          // Tab obiettivi in bozza
          if (this.draftGoalList && this.draftGoalList.length > 0) {
            this.contentTabList.push({
              id: 'DRAFT_INDIVIDUAL_TAB',
              title: this.translate.instant('goal.tabs.DRAFT_INDIVIDUAL_TAB'),
              attrAriaLabel: this.translate.instant('goal.tabs.DRAFT_INDIVIDUAL_TAB'),
              isDisabled: false,
              isEditObjective: true
            })
          }
          let isFirst = true;
          let haveSTI = false;

          for (let tab of tabList.response) {
            if (!!tab.noSti && tab.noSti) {
              haveSTI = false;
            } else {
              haveSTI = true;
            }
            let title = this.translate.instant('goal.tabs.' + tab.tabName);
            let weight = 0;
            for (let i = 0; i < tab.goalTypes.length; i++) {
              if (tab.goalTypes[i].goalType == PerfCareMacroGoalTypes.INDIVIDUAL) {
                this.totalWeight = tab.goalTypes[i].weight;
              }
              weight += tab.goalTypes[i].weight;
            }
            title += ' ' + weight + '%';
            let goalTypes = this.getGoalTypes(tab.goalTypes);
            this.contentTabList.push({
              id: tab.tabName,
              title: title,
              attrAriaLabel: title,
              goalTypes: goalTypes.filter((gt: any) => gt.type != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT),
              noSti: tab.noSti,
              isDisabled: !(this.draftGoalList && this.draftGoalList.length > 0) && isFirst ? false : true
            })
            isFirst = false;
          }
          if (haveSTI) {
            this.contentTabList.push({
              id: 'STI',
              title: this.translate.instant('goal.tabs.STI'),
              attrAriaLabel: this.translate.instant('goal.tabs.STI'),
              isDisabled: true
            })
          }
          this.currentContentTab = this.contentTabList[0];
          this.getUserGoalList();
        }
        this.isLoadingTab = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "061",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }


  onContentTabChanged(tab: TabDossier) {
    this.isLoadingUserGoals = true;
    this.currentContentTab = tab;
    if (this.currentContentTab.id != 'STI') {
      if (tab.id == 'DRAFT_INDIVIDUAL_TAB') {
        this.getObjectiveOnHold(true);
      } else {
        this.getUserGoalList();
      }
    } else {
      this.isLoadingUserGoals = false;
    }
  }


  // Controlla se è possibile condividere gli obiettivi con il subordinato (e li condivide)
  shareObjectives() {
    this.isLoadingUserGoals = true;
    if (this.canShareGoals$) {
      this.canShareGoals$.unsubscribe();
    }
    // Controllo se gli obiettivi possono essere condivisi
    this.canShareGoals$ = this.collaboratorService.canProposeGoalAfterSharing(this.selectedYear.id)
      .subscribe((canShare: SenecaResponse<CanProposeGoalsToManagerResponse>) => {
        if (canShare.error || !canShare.response) {
          this.shareModalContent = {
            // image: this.personDetails.chiaveSesso === 'F' ? '/assets/img/modal/error-female.svg' : '/assets/img/modal/error-male.svg',
            modalId: 'shareError',
            title: this.translate.instant('goal.errors.CANT_SHARE_OBJECTIVES'),
            subtitle: this.translate.instant('goal.errors.CANT_SHARE_OBJECTIVES_SUB'),
            text: this.translate.instant('goal.errors.CANT_SHARE_CHECK_CONDITION')
          }
          this.modalService.open("errorShareModal");
        } else {
          this.isLoadingTab = true;
          this.confirmShareModal()
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "062",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      }
      );

  }

  // Apre modale indicatori info
  openModalInfoIndicators() {
    const messageObj: ApplicationModalMessage = {
      modalId: "139",
      title: 'Lorem ipsum',
      text: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum'
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  confirmShareModal() {
    // Se posso condividere gli obiettivi procedo
    this.collaboratorService.poposeGoalAfterSharing(this.selectedYear.id)
      .subscribe((shared: any) => {
        if (!shared) {
          const messageObj: ApplicationModalMessage = {
            modalId: "063",
            text: this.translate.instant("errors." + shared),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // controllo se sono stati rilasciati badge
          let badges = shared.obtainedBadges;
          let tmpBadgesList: BadgeObject[] = [];
          if (badges && badges.length) {
            tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
            this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
          }
          // se ho condiviso corretamente gli obiettivi aggiorno la lista
          this.getObjectiveOnHold();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "064",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      }
      );
  }

  // Ritorna la lista di applausi per lo smart feedback
  getFeedbackClapList() {
    this.isLoadingFeedbackClaps = true;
    if (this.getFeedbackClapList$) {
      this.getFeedbackClapList$.unsubscribe();
    }
    this.getFeedbackClapList$ = this.collaboratorService.getClapList(this.selectedYear.id, [this.userId], this.isReceiving, !this.isReceiving).
      subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackClapList = data.response;
        }
        this.isLoadingFeedbackClaps = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackClaps = false;
      });
  }

  goToDeleteClap(id: string, feedbackUser: any) {
    this.clapIdToDelete = id;
    this.feedbackUserIdToDelete = feedbackUser.userId;
    this.openDeleteClapModal();
  }

  openDeleteClapModal() {
    this.modalService.open('deleteClapModal');
  }

  closeDeleteClapModal() {
    this.clapIdToDelete = '';
    this.feedbackUserIdToDelete = '';
    this.modalService.close('deleteClapModal');
  }

  // Elimina applauso
  deleteClap() {
    this.isLoadingFeedbackClaps = true;
    this.collaboratorService.deleteClap(this.clapIdToDelete, this.feedbackUserIdToDelete, this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.closeDeleteClapModal();
        this.getFeedbackClapList();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackClaps = false;
      });
  }

  changeApplauseTab(isReceived: boolean) {
    this.isReceiving = isReceived;
    this.getFeedbackClapList();
  }

  // Invia applauso
  sendClap() {
    this.redirectService.goToSendClapPageCollaborator();
  }

  // Vai a modificare l'applauso
  goToEditClap(id: string, user: any) {
    this.redirectService.goToSendClapPageCollaborator(user.userId, id);
  }


  // Recupera il colloquio dell'utente
  getUserInterview() {
    if (this.getUserInterview$) {
      this.getUserInterview$.unsubscribe();
    }
    this.getUserInterview$ = this.collaboratorService.getClosestInterview(this.selectedYear.id.toString())
      // ListUserFeedbackDialogueForManager aggiungere commonclasses appena c'è
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "065",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.interview = data.response;
          this.isLoadingPersonDetails = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      }
      );
  }

  closeShareModal() {
    this.modalService.close("confirmShareModal");
  }

  closeErrorModal() {
    this.modalService.close('errorShareModal');
  }

  onGoalDetailClicked(goal: any) {
    this.router.navigate(['/collaborator/goalDetails/' + this.userId + '/' + goal.id + '/' + this.selectedYear.id]);
  }

  // crea nuovo obiettivo con check sul peso
  goToCreateEditObjective() {
    if (this.roleObjectiveWeight < this.totalWeight && ((this.maxRoleTypeNumber && this.goalList.length < this.maxRoleTypeNumber) || !this.maxRoleTypeNumber)) {
      this.router.navigate(["collaborator/createUpdateGoal/" + this.selectedYear.id]);
    } else {
      const modalObj: ApplicationModalMessage = {
        modalId: 'weighterr001',
        title: this.translations['objectiveWizard.1.modal.W_ERR_TITLE'],
        subtitle: this.translations['objectiveWizard.1.modal.W_ERR_SUBTITLE'],
        text: this.translations['objectiveWizard.1.modal.W_ERR_DESCR'] + '<b>' + moment(this.finalEvaluationDate).format('DD/MM/yyyy') + '</b>',
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: modalObj }));
    }
  }

  goToCreateEditDraft() {
    this.router.navigate(["collaborator/createUpdateGoal/" + this.selectedYear.id]);
    this.router.navigate(["collaborator/createUpdateGoal/" + this.selectedYear.id]);
  }

  openCancelEditObjective() {
    this.modalService.open('cancelEditObjective');
  }

  closeCancelEditObjective(confirm?: boolean) {
    this.modalService.close('cancelEditObjective');
    if (confirm) {
      this.isLoadingUserGoals = true;

      let idList = this.draftGoalList.map((goal: any) => goal.id);
      this.collaboratorService.cancelGoalAfterSharing(this.selectedYear.id, idList)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "as001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            this.draftGoalList = [];
            this.getUserProfileTab();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "as002",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUserGoals = false;
          })
    }
  }

  devGoalInserted() {
    if (this.challengeList && this.challengeList.length > 0) {
      return true;
    }
    return false;
  }

  devGoalShared() {
    if (this.challengeList && this.challengeList.length) {
      let devGoal = this.challengeList.filter((x: any) => this.sharedStatuses.includes(x.goalStatus));
      if (devGoal && devGoal[0]) {
        return true;
      }
    }
    return false;
  }

  goalId(index: number, el: any) {
    return el.id;
  }

  // Vai a annullare il colloquio
  goToDeleteInterview(interviewId: string) {
    this.interviewIdToDelete = interviewId;
    this.openModalDeleteInterview();
  }

  // Apre la modale per annullare il colloquio
  openModalDeleteInterview() {
    this.modalService.open('deleteInterviewModal');
  }

  // Apre la modale per annullare il colloquio
  closeModalDeleteInterview() {
    this.modalService.close('deleteInterviewModal');
    this.interviewIdToDelete = '';
  }

  // Annulla il colloquio
  deleteInterview() {
    this.isFetchingDeleteInterview = true;
    let interviewIdToDelete = this.interviewIdToDelete;
    if (this.isInterview) {
      this.isLoadingInterviewsList = true;
    }
    this.closeModalDeleteInterview();
    if (this.deleteInterview$) {
      this.deleteInterview$.unsubscribe();
    }
    this.deleteInterview$ = this.collaboratorService.deleteInterview(this.selectedYear.id.toString(), interviewIdToDelete)
      .subscribe((data: SenecaResponse<CancelUserFeedbackDialogueForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "065",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "602",
            text: this.translate.instant("goalSetting.organizeInterview.DELETED_INTERVIEW_CORRECTLY_TEXT"),
            title: this.translate.instant("goalSetting.organizeInterview.DELETED_INTERVIEW_CORRECTLY")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.getClosestInterview();
          if (this.isInterview) {
            this.getInterviewsList();
          }
        }
        this.isFetchingDeleteInterview = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingDeleteInterview = false;
      }
      );
  }


  // Controlla se un file esiste
  fileExists(url: string): Observable<boolean> {
    return this.http.get(url).pipe(
      map(() => true),
      catchError((err: HttpErrorResponse) => {
        const success: boolean = err.status.toString().startsWith('2')
        return of(success)
      })
    )
  }

  downloadRules() {
    this.isDownloadingRules = true;
    let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
    acks.data['STI_RULE_WATCHED'] = true;
    this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
      subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "044",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let filePath = '/assets/docs/sti_rules_' + this.selectedYear.id + '.pdf';
          let link = document.createElement('a');
          link.href = filePath;
          this.fileExists(filePath).subscribe((response) => {
            if (!response) {
              filePath = '/assets/docs/sti_rules.pdf';
              link.href = filePath;
            }
            link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
            link.click();
            this.stiRulesSeen = true;
            this.isDownloadingRules = false;
          })
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingRules = false;
      }
      );
  }

  // funzioni commento azioni

  // Mostra commento
  showComment(object: any) {
    let objectModal: ApplicationModalMessage = {
      modalId: "d031",
      title: this.translate.instant("developmentPlan.DEVELOP_ACTION_COMMENT"),
      subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + moment(object.creationDate).format('DD/MM/YYYY'),
      text: object.comment
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: objectModal }));
  }


  // Apre la modale per cancellare il commento dell'azione di sviluppo
  openDeleteCommentModalDevelopAction(actionObject: any) {
    this.actionForCommentDelete = actionObject.action;
    this.commentIdToDelete = actionObject.commentId;
    this.modalService.open('deleteDevelopActionCommentModal');
  }

  // Chiude la modale per cancellare il commento dell'azione di sviluppo
  closeDeleteCommentModalDevelopAction() {
    this.modalService.close('deleteDevelopActionCommentModal');
  }

  // Cancella commento dell'azione di sviluppo
  deleteCommentDevelopAction() {
    this.isLoadingDevelopmentActions = true;
    this.closeDeleteCommentModalDevelopAction();
    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.collaboratorService.deleteCommentToDevelopPlan(this.selectedYear.id.toString(), this.actionForCommentDelete.id, this.commentIdToDelete)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d033",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d034",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getDevelopmentActions();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d035",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getDevelopmentActions();
      });
  }
  // Apre modale per aggiungere il commento all'azione di sviluppo
  openInsertCommentToDevelopAction(developAction: any) {
    this.developAction = developAction;
    this.newActionComment = '';
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + developAction.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      developAction.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + developAction.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + developAction.toStartDate + '</p>';
    this.modalService.open('insertCommentDevelopmentPlanModal');
  }

  // Aggiungi il commento all'azione di sviluppo
  addCommentToDevelopAction() {
    this.isLoadingDevelopmentActions = true;
    this.closeInserCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.collaboratorService.addCommentToDevelopPlan(this.selectedYear.id.toString(), this.developAction.id, this.newActionComment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d024",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d025",
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT_CORRECTLY"),
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.newActionComment = '';
        this.developAction = null;
        this.getDevelopmentActions();
      }, (err?: any) => {
        this.newActionComment = '';
        const messageObj: ApplicationModalMessage = {
          modalId: "d026",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.newActionComment = '';
        this.developAction = null;
        this.getDevelopmentActions();
      });
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeInserCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('insertCommentDevelopmentPlanModal');
    if (cancel) {
      this.newActionComment = '';
      this.developAction = null;
    }
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionCommentChanged(text: string) {
    this.newActionComment = text;
  }

  // Apre modale per modificare il commento all'azione di sviluppo
  openEditCommentToDevelopAction(actionObject: any) {
    this.developActionEdit = actionObject.action;
    this.commentToEdit = actionObject.comment;
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + this.developActionEdit.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      this.developActionEdit.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + this.developActionEdit.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + this.developActionEdit.toStartDate + '</p>';
    this.modalService.open('editCommentDevelopmentPlanModal');
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeEditCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('editCommentDevelopmentPlanModal');
    if (cancel) {
      this.developActionEdit = null;
      this.commentToEdit = null;
    }
  }

  // Aggiungi il commento all'azione di sviluppo
  updateCommentForDevelopAction() {
    this.isLoadingDevelopmentActions = true;
    this.closeEditCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.collaboratorService.updateCommentForDevelopPlan(this.selectedYear.id.toString(), this.developActionEdit.id, this.commentToEdit.commentId, this.commentToEdit.comment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d036",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d037",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED_DESCR"),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getDevelopmentActions();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d038",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getDevelopmentActions();
      });
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionEditCommentChanged(text: string) {
    this.commentToEdit.comment = text;
  }

  // Aggiungi un azione di sviluppo
  addDevelopmentAction() {
    if (this.canCreateDevActions) {
      this.router.navigate(['./collaborator/createUpdateDevelopmentAction/' + this.userId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "603",
        title: this.translate.instant("developmentPlan.ADD_DEVELOPMENT_PLAN"),
        subtitle: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION"),
        text: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION_TEXT_COLL"),
        confirmLabel: this.translate.instant("generic.CLOSE")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // Chiudi modale per la cancellazione dell'azione di sviluppo
  closeDeleteModalDevelopAction() {
    this.modalService.close("deleteDevelopmentAction");
    this.developActionIdToDelete = '';
  }

  // Apri modale per la cancellazione dell'azione di sviluppo
  openDeleteModalDevelopAction(developActionIdToDelete: string) {
    this.developActionIdToDelete = developActionIdToDelete;
    this.modalService.open("deleteDevelopmentAction");
  }

  // Cancella azione di sviluppo
  deleteDevelopAction() {

    let idToDelete = this.developActionIdToDelete;
    this.closeDeleteModalDevelopAction();
    this.isLoadingDeleteDevelopAction = true;
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }

    this.deleteDevelopAction$ = this.collaboratorService.deleteDevelopmentActionForUser(this.selectedYear.id.toString(), idToDelete)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.getDevelopmentActions();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingDeleteDevelopAction = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDeleteDevelopAction = false;
      }
      );
  }

  goToEditAction(action: any) {
    this.router.navigate(['./collaborator/createUpdateDevelopmentActionEdit/' + this.userId + '/' + action.id]);
  }

  // Cambi le tabs della sezione del feedback
  onContentFeedbackTabChanged(tab: TabDossier) {
    this.currentContentFeedbackTab = tab;
    if (this.currentContentFeedbackTab.id == 'CLAP_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/smartFeedback/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'EVIDENCES_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/evidences/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'PRIVATE_NOTES_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/privateNotes/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'INTERVIEW_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/interview/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'PEER_FEEDBACK_TAB') {
      this.router.navigate(['collaborator/personDetails/feedback/peerFeedback/' + this.userId + '/' + this.selectedYear.id]);
    }
  }

  // Vai alla pagina di crezione evidenza
  goToAddEvidence() {
    if (this.canGoAddEvidence) {
      this.router.navigate(["collaborator/createUpdateEvidenceOnEvidence/" + this.userId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "d152",
        title: this.translate.instant("evidences.NOT_CAN_CREATE_EVIDENCES"),
        text: this.translate.instant("evidences.NOT_CAN_CREATE_EVIDENCES_DESCRIPTION")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // Vai alla pagina di aggiornamento evidenza
  goToEditEvidence(id: string) {
    this.router.navigate(["collaborator/createUpdateEvidenceOnEvidence/" + this.userId + '/' + id]);
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  onFileTitleChanged(newTitle: string) {
    this.fileTitle = newTitle;
  }

  // funzioni modale upload file in evidenze
  onFileUpload(id: string) {
    this.fileTitle = '';
    this.fileObject = null;
    this.isToResetUploadComponent = true;
    this.evidenceIdToManageFile = id;
    this.modalService.open('uploadFileOnEvidences');
  }

  closeUploadFileModal() {
    this.isToResetUploadComponent = false;
    this.evidenceIdToManageFile = '';
    this.modalService.close('uploadFileOnEvidences');
  }

  confirmUploadFile() {
    this.uploadFileOnEvidences();
  }

  uploadFileOnEvidences() {
    this.isLoadingUploadFileOnEvidences = true;
    this.isToResetUploadComponent = false;

    let evidenceId = this.evidenceIdToManageFile;
    this.closeUploadFileModal();

    this.collaboratorService.uploadFileForEvidence(this.selectedYear.id.toString(), evidenceId, this.fileTitle, this.fileObject)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUploadFileOnEvidences = false;
        } else {
          this.getFeedbackEvidencesList();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm011",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUploadFileOnEvidences = false;
        })
  }

  // Cancella file evidenza
  deleteFileOnEvidence(uploadId: string, evidenceId: string) {
    this.evidenceUploadIdToDelete = uploadId;
    this.evidenceIdOfFileToDelete = evidenceId;
    this.modalService.open('deleteFileModal');
  }

  // Cancella file evidenza
  closeDeleteFileModalOnEvidence(confirmDelete: boolean) {
    if (confirmDelete) {
      this.isLoadingDeleteFileOnEvidence = true;
      this.collaboratorService.deleteFileForEvidence(this.selectedYear.id.toString(), this.evidenceIdOfFileToDelete, this.evidenceUploadIdToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm014",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingDeleteFileOnEvidence = false;
          } else {
            this.getFeedbackEvidencesList();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingDeleteFileOnEvidence = false;
          })
    }
    this.evidenceUploadIdToDelete = '';
    this.evidenceIdOfFileToDelete = '';
    this.modalService.close('deleteFileModal');
  }

  // Download file nell'evidenza
  downloadFileOnEvidences(file: any, feedbackId: string) {
    this.isLoadingGetDownloadUrlOnEvidence = true;

    this.collaboratorService.retrieveNewMaskedUrlForUploadForEvidence(file.uploadId, feedbackId, this.selectedYear.id.toString())
      .subscribe((data: any) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          let downloadUrl = this.authService.getMaskedUrlDownload(data.response, file.fileName);
          setTimeout(() => {
            document.location.assign(downloadUrl);
          }, 500)
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGetDownloadUrlOnEvidence = false;
        })
  }

  getPrivateNotesList() {
    this.isLoadingPrivateNotes = true;
    this.privateNotesList = [];

    if (this.getPrivateNotesList$) {
      this.getPrivateNotesList$.unsubscribe();
    }
    this.privateNotesList = [];
    this.getPrivateNotesList$ = this.collaboratorService.getPrivateNotesList(this.selectedYear.id)
      .subscribe((data: SenecaResponse<ListPrivateNoteFeedbackForUserResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          for (let i = 0; i < data.response.length; i++) {
            this.privateNotesList.push({
              noteId: data.response[i].feedbackId,
              comment: data.response[i].comment,
              motivation: data.response[i].motivation,
              formattedCreationDate: moment(data.response[i].creationDate).fromNow()
            })
          }
        }
        this.isLoadingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPrivateNotes = false;
        })
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.collaboratorService.deletePrivateNote(this.noteIdToDelete)
        .subscribe((data: SenecaResponse<DeletePrivateNoteFeedbackForUserResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getPrivateNotesList();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNote(this.personDetails.userId, noteId);
  }


  createPrivateNote() {
    this.redirectService.goToCreatePrivateNote(this.personDetails.userId);
  }

  // Tab colloqui feedback
  getInterviewsList() {
    this.isLoadingInterviewsList = true;
    if (this.getInterviewsList$) {
      this.getInterviewsList$.unsubscribe();
    }
    this.getInterviewsList$ = this.collaboratorService.getInterviewsForUser(this.selectedYear.id.toString())
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          this.interviewList = [];
          if (data.response.length) {
            this.translate.get([
              "interview.card.FROM",
              "interview.card.TO"
            ])
              .subscribe((translations) => {
                for (let i = 0; i < data.response.length; i++) {
                  let tmp = data.response[i];
                  let when = moment(tmp.startDate).format('DD/MM/YYYY') + ' ' + translations["interview.card.FROM"] + ' ' + moment(tmp.startDate).format('HH:mm') + ' ' + translations["interview.card.TO"] + ' ' + moment(tmp.endDate).format('HH:mm');
                  this.interviewList.push({
                    feedbackDialogueId: tmp.feedbackDialogueId,
                    formattedCreationDate: moment(tmp.creationDate).fromNow(),
                    status: tmp.feedbackDialogueStatus,
                    when: when,
                    where: tmp.link,
                    argument: tmp.argument,
                    user: tmp.creationUserId == this.userId ? this.personDetails : this.managerData,
                    canCancel: tmp.creationUserId == this.loggedUser.userId ? true : false,
                    isPlaceLink: tmp.type === PerfCareFeedbackDialogueTypes.ONLINE || tmp.type === PerfCareFeedbackDialogueTypes.MS_TEAMS,
                    type: tmp.type
                  })
                }
              })
          }
        }
        this.isLoadingInterviewsList = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingInterviewsList = false;
        })
  }

  // Peer feedback
  getPeerFeedbackList() {
    this.isLoadingPeerFeedback = true;
    this.peerFeedbackList = [];
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }

    this.getPeerFeedbackList$ = this.collaboratorService.listPeerFeedback(this.selectedYear.id, true, true)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.peerFeedbackList = [];
          if (data.response && data.response.length) {
            for (let i = 0; i < data.response.length; i++) {
              this.peerFeedbackList.push({
                ...data.response[i],
                formattedCreationDate: moment(data.response[i].creationDate).fromNow()
              });
            }
          }
        }
        this.isLoadingPeerFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPeerFeedback = false;
        })
  }

  goToPeerFeedbackAnswer(feedbackId: string) {
    this.router.navigate(['/collaborator/createUpdatePeerFeedback/response/' + this.userId + '/' + feedbackId]);
  }

  createPeerFeedback(feedbackId: string, feedback?: any) {
    if (feedback?.approverFeedbackUser?.userId == this.loggedUser.userId) {
      this.goToPeerFeedbackAnswer(feedbackId);
    } else {
      if (feedbackId) {
        this.redirectService.goToCreatePeerFeedback(this.userId, feedbackId);
      } else {
        this.redirectService.goToCreatePeerFeedback(this.userId);
      }
    }
  }

  openDeletePeerFeedbackModal(id: string) {
    this.peerFeedbackToDelete = id;
    this.modalService.open('deletePeerFeedback');
  }

  closeDeletePeerFeedbackModal(confirm?: boolean) {
    this.modalService.close('deletePeerFeedback');
    if (confirm) {
      this.isLoadingPeerFeedback = true;
      if (this.deletePeerFeedback$) {
        this.deletePeerFeedback$.unsubscribe();
      }
      this.deletePeerFeedback$ = this.collaboratorService.deletePeerFeedback(this.selectedYear.id, this.peerFeedbackToDelete)
        .subscribe(
          (data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pf021",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else if (data.response) {
              const messageObj: ApplicationModalMessage = {
                modalId: "d028",
                title: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED"),
                text: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED_DESCR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "d029",
                title: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_ERROR"),
                text: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_TEXT_ERROR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getPeerFeedbackList();
          },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf022",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingPeerFeedback = false;
          }
        )
    }
    this.peerFeedbackToDelete = '';
  }

  openShowFeedbackRequest(feedback: any) {
    this.feedbackForRequestModal = feedback;
    this.modalService.open('modalShowRequest')
  }

  closeShowFeedbackRequest() {
    this.modalService.close('modalShowRequest')
    this.feedbackForRequestModal = null;
  }

  changePeerTab(isSent: boolean) {
    this.peerSent = isSent;
    this.getPeerFeedbackList();
  }

  definePerformanceLevel() {
    this.router.navigate(['/collaborator/definePerformanceLevel', this.selectedYear.id]);
  }

  openFinalEvaluationModal(evaluation: any, final: boolean, isUser?: boolean, isManager?: boolean) {
    this.finalEvaluationForModal = {};

    if (!isUser && !isManager) {
      this.finalEvaluationForModal = evaluation;
    } else {
      this.finalEvaluationForModal.final = true;
      if (isUser) {
        this.finalEvaluationForModal.user = this.personDetails;
        this.finalEvaluationForModal.evaluationScore = this.performanceLevelEvaluation;
        this.finalEvaluationForModal.observation = this.performanceLevelObservation;
        this.finalEvaluationForModal.creationDate = this.performanceLevelEvaluationDate;
      } else if (isManager) {
        this.finalEvaluationForModal.isManager = true;
        this.finalEvaluationForModal.user = this.loggedUser;
        this.finalEvaluationForModal.evaluationScore = this.managerFinalEvaluation;
        this.finalEvaluationForModal.observation = this.managerFinalEvaluationObservation;
        this.finalEvaluationForModal.creationDate = this.managerFinalEvaluationDate;
      }
    }
    this.modalService.open('finalEvaluationModal')
  }

  closeFinalEvaluationModal() {
    this.modalService.close('finalEvaluationModal');
    this.finalEvaluationForModal = null;
  }

  isInFinalEvaluationPhase() {
    if (this.runningPhase.phaseKey == 'FINAL_EVALUATION') {
      let startDate = moment(this.runningPhase.phaseStartDateForUser).format();
      let endDate = moment(this.runningPhase.phaseStartDateForUser).format();
      let today = moment().format();
      if (moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)) {
        return true;
      }
    }
    return false;
  }

  // Data valutazione finale
  getFinalEvaluationDate() {
    if (this.getFinalEvaluationDate$) {
      this.getFinalEvaluationDate$.unsubscribe();
    }
    this.collaboratorService.getFinalEvaluationDate(this.selectedYear?.id || this.runningYear)
      .subscribe((data) => {
        this.finalEvaluationDate = data.response?.finalEvaluationUserPhaseDates?.startDate;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "fevda001",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  getMyFinalEvaluation() {
    this.isLoadingMyEvaluation = true;
    if (this.getMyFinalEvaluation$) {
      this.getMyFinalEvaluation$.unsubscribe();
    }
    this.getMyFinalEvaluation$ = this.collaboratorService.getMyFinalEvaluation(this.selectedYear.id)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.postCalibrationData = {};

          if (feData.response) {
            this.perfromanceLevelActive = true;
            this.finalEvaluationStatus = {
              statusMessage: this.translate.instant('performance.statuses.TO_COMPILE'),
              status: 'toStart'
            }
            if (!feData.response.subordinateObservationLevel && feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_TO_CONFIRM_EVALUATION) {
              this.performanceLevelDone = true;
              this.noEvaluationPost = true;
              this.finalEvaluationStatus = {
                statusMessage: this.translations['performance.statuses.TO_CONFIRM'],
                status: 'inProgress'
              }
            }
            if (feData.response.subordinateObservationLevel) {
              this.performanceLevelDone = true;
              this.performanceLevelEvaluation = feData.response.subordinateObservationLevel;
              this.performanceLevelEvaluationDate = feData.response.creationDate;
              this.performanceLevelObservation = feData.response.subordinateObservation;
              this.finalEvaluationStatus = {
                statusMessage: this.translations['performance.statuses.TO_CONFIRM'],
                status: 'inProgress'
              }
            }
            if (this.calibrationStatuses.includes(feData.response.finalEvaluationStatus)) {
              this.calibrationActive = true;
              this.postCalibrationData.isComplete = false;
              this.postCalibrationData.status = {
                statusMessage: feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED ? this.translations['performance.statuses.COMPLETED'] : this.translations['performance.statuses.TO_CONFIRM'],
                status: feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED ? 'completed' : 'inProgress'
              };
              this.postCalibrationData.observations = null;
              this.postCalibrationData.level = null;
              this.postCalibrationData.payout = null;
            }

            if (feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED) {
              this.performanceLevelDone = true; // verifica bugfix 
              this.calibrationActive = true;
              this.postCalibrationData.isComplete = true;
              this.postCalibrationData.status = {
                statusMessage: this.translations['performance.statuses.COMPLETED'],
                status: 'completed'
              };
              if (feData.response.payout >= 0) {
                this.postCalibrationData.payout = {
                  id: feData.response.payout,
                  title: feData.response.payout + '%'
                };
              }
              this.getPostCalibrationData()
            }
          }
          if (this.isProcessClosed && !this.performanceLevelDone) {
            this.perfromanceLevelActive = false;
            this.calibrationActive = false;
          }
        }
        this.getUserProfileTab();
        this.isLoadingMyEvaluation = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMyEvaluation = false;
      });
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.collaboratorService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          let keys = data.response.map((x) => x.assessmentYear);
          keys = keys.sort();
          for (let i = (keys.length - 1); i >= 0; i--) {
            let yearData = data.response.find((x) => x.assessmentYear == keys[i])
            this.yearList.push({
              id: yearData.assessmentYear,
              name: 'Anno ' + yearData.assessmentYear,
              disabled: !yearData.isInProcess
            });
          }
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  changeSelectedYear(year: any) {
    this.resetData();
    this.selectedYear = year;
    // let url = this.router.url.split(('/' + previous))[0];
    this.roleStiDescr = this.translations['goal.STI_RULE_CONTENT_1'] + this.selectedYear.id + this.translations['goal.STI_RULE_CONTENT_2'];
    // this.router.navigate([url + '/' + this.selectedYear.id])
    if (window.location.href.includes('/feedback/')) {
      this.changeButtonHeaderDossier('feedback');
    } else if (window.location.href.includes('/developmentPlan/')) {
      this.changeButtonHeaderDossier('individualPlan');
    } else {
      // MMTODO 
      this.changeButtonHeaderDossier('objectives', true);
    }
  }


  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  openPerformanceLevelModal() {
    this.modalService.open('performance-level-modal');
  }

  closerPerformanceLevelModal() {
    this.modalService.close('performance-level-modal');
  }

  getPostCalibrationData() {
    this.isLoadingUserGoals = true;
    if (this.getPostCalibrationData$) {
      this.getPostCalibrationData$.unsubscribe();
    }
    this.getPostCalibrationData$ = this.collaboratorService.getPostCalibrationData(this.selectedYear.id)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error || !feData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          if (feData.response.finalEvaluation) {
            let managerCalibration = feData.response.finalEvaluation;
            this.postCalibrationData.observations = managerCalibration.managerObservation
            this.postCalibrationData.date = managerCalibration.creationDate;
          }
          if (feData.response.calibration) {
            let calibration = feData.response.calibration;
            this.postCalibrationData.level = calibration.adminObservationLevel;
          }
          // fallback percentuale payout
          if (parseInt(feData.response.calibration.payout) >= 0) {
            this.postCalibrationData.payout = {
              id: feData.response.calibration.payout,
              title: feData.response.calibration.payout + '%'
            };
          } else {
            if (parseInt(feData.response.finalEvaluation.payout) >= 0) {
              this.postCalibrationData.payout = {
                id: feData.response.finalEvaluation.payout,
                title: feData.response.finalEvaluation.payout + '%'
              };
            }
          }
          if (parseInt(feData.response.finalEvaluation.importedPayoutAmount) >= 0) {
            this.postCalibrationData.stiAmount = feData.response.finalEvaluation.importedPayoutAmount;
          }
          // se concluso senza dati
          if (this.isProcessClosed && parseInt(feData.response.finalEvaluation.importedPayoutAmount) >= 0) {
            this.performanceLevelDone = false; // verifica bugfix
            this.calibrationActive = true;
            this.postCalibrationData.isComplete = true;

            this.postCalibrationData.status = {
              statusMessage: this.translations['performance.statuses.COMPLETED'],
              status: 'completed'
            };
          }

          this.isLoadingUserGoals = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  checkProcessClosed() {
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    this.isProcessClosed$ = this.collaboratorService.isProcessClosed(this.selectedYear.id)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.response) {
          this.isProcessClosed = true;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pc003",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Azione su obiettivo
  onObjectiveOptionClicked(option: any, objective: any) {
    switch (option.id) {
      case 'edit':
        if (objective.type == PerfCareMacroGoalTypes.INDIVIDUAL && !objective.weight) {
          this.router.navigate(["/collaborator/createUpdateGoal/optional/" + this.userId + '/' + objective.id]);
        } else if (objective.type.indexOf('DEVELOPMENT') >= 0) {
          this.router.navigate(["/collaborator/createUpdateChallenge/" + this.userId + '/' + objective.id]);
        } else {
          this.router.navigate(["/collaborator/createUpdateGoal/" + this.userId + '/' + objective.id]);
        }
        break;
      case 'delete':
        this.openDeleteObjectiveModal(objective.id);
        break;
      case 'add_evidence':
        this.router.navigate(["/collaborator/createUpdateEvidence/" + this.userId + '/' + objective.id]);
        break;
      case 'link':
        console.log("TODO link"); // TODO Funzionalità rimandata
        break;
      case 'value':
        this.router.navigate(["/collaborator/finalEvaluation/" + this.selectedYear.id + '/' + objective.id]);
    }
  }


  openDeleteObjectiveModal(id: string) {
    this.deleteId = id;
    this.modalService.open('deleteObjective');
  }

  closeDeleteObjectiveModal(confirm?: boolean) {
    this.modalService.close('deleteObjective');
    if (confirm) {
      this.deleteObjective();
    }
  }


  deleteObjective() {
    this.isLoadingUserGoals = true;

    if (this.deleteObjective$) {
      this.deleteObjective$.unsubscribe();
    }

    this.deleteObjective$ = this.collaboratorService.deleteGoals(this.runningYear, [this.deleteId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUserGoals = false;
        } else {
          this.deleteId = '';
          this.getObjectiveOnHold()
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "delobj002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });

  }


  // Modale dettaglio obeittivi
  openObjectiveDetails(objective: any) {
    this.objectiveForModal = objective;
    this.modalService.open("objectiveDetails");
  }

  closeObjectiveDetails() {
    this.modalService.close("objectiveDetails");
    this.objectiveForModal = null;
  }

  // controllo se ci sono valutazioni nell'obiettivo
  hasAnyEvaluation() {
    let tmp = false;
    // controllo se esistono
    if (this.objectiveForModal && this.objectiveForModal.goalEvaluation) {
      for (let i = 0; i < this.objectiveForModal.goalEvaluation.length; i++) {
        // Se è un operations potrebbe non avere la valutazione, quindi visualizzo e basta
        if (this.personDetails.isOperation) {
          tmp = true;
          break;
        } else if (this.objectiveForModal.goalEvaluation[i].evaluationScore != null) {
          tmp = true;
          break;
        }
      }
    }
    return tmp;
  }

  // apre la modale degli obiettivi di cascading
  openCascadingModal() {
    this.modalService.open('cascading-modal');
  }

  closeCascadingModal() {
    this.modalService.close('cascading-modal');
  }

  getProposalStatus() {
    this.isLoadingTab = true;

    if (this.getProposalStatus$) {
      this.getProposalStatus$.unsubscribe();
    }

    this.getProposalStatus$ = this.collaboratorService.getGoalSettingProposalStatus(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingTab = false;
        } else {
          this.proposalStatus = data.response;
          // Stati possibili
          // TO_PROPOSE
          // PROPOSED
          // APPROVED
          // REJECTED
          // la funzione viene richiamata anche nel feedback e nelle azioni di sviluppo
          // per fare il redirect alla pagina corretta della tab obiettivi
          if (this.isObjectiveSection) {
            // if (this.proposalStatus.status == 'REJECTED') {
            if (this.proposalStatus.status != 'APPROVED' && this.proposalStatus.status != 'PROPOSED' && this.selectedYear.id >= 2023) {
              this.router.navigate(['collaborator/objectiveWizard/' + this.loggedUser.userId + '/' + this.selectedYear.id]);
            } else {
              this.getObjectiveOnHold();
            }
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }


  getCascadingGoals() {
    this.isLoadingCascading = true;

    if (this.getCascadingGoals$) {
      this.getCascadingGoals$.unsubscribe();
    }

    this.getCascadingGoals$ = this.collaboratorService.getCascadingGoals(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ccasc008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingTab = false;
        } else {
          this.cascadingGoal = data.response?.cascadingGoal;
          if (this.cascadingGoal && this.cascadingGoal.introductionText && this.cascadingGoal.description) {
            this.cascadingGoal.description = this.cascadingGoal.description.replace('\n', '<br>')
            this.cascadingGoal.introductionText = this.cascadingGoal.introductionText.replace('\n', '<br>')
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ccasc009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }


  canAddOptionalObjective() {
    let optionalObjectives = this.goalList.filter((obj: any) => !obj.weight && obj.type != PerfCareMacroGoalTypes.DEVELOPMENT);
    if (optionalObjectives.length >= this.maxZeroWeightedGoals) {
      return false;
    }
    return true;
  }

  addOptionalObjective() {
    this.router.navigate(['./collaborator/createUpdateGoal/optional/' + this.userId]);
  }

  getObjectiveOnHold(skipReload?: boolean) {
    if (this.getObjectiveOnHold$) {
      this.getObjectiveOnHold$.unsubscribe();
    }
    this.getObjectiveOnHold$ = this.collaboratorService.listUserGoals(this.selectedYear.id, "DRAFT_INDIVIDUAL_TAB")
      .subscribe((goalDrafts: SenecaResponse<any>) => {
        if (goalDrafts && goalDrafts.response) {
          let tempList = GoalUtils.formatGoalCardList(this.translate, goalDrafts.response, true, this.userId);
          this.draftGoalList = tempList;
        } else {
          this.draftGoalList = [];
        }
        if (!skipReload) {
          this.getUserProfileTab();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "111",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        window.history.back();
      });
  }


  isDraftTab() {
    return this.draftGoalList && this.draftGoalList.length > 0 && this.currentContentTab && this.currentContentTab.id == 'DRAFT_INDIVIDUAL_TAB';
  }

  isMainCardButtonDisabled() {
    return (this.draftGoalList && this.draftGoalList.length > 0) || (this.proposalStatus && this.proposalStatus.status == 'PROPOSED')
  }


  goToCreateDraft() {
    this.router.navigate(['collaborator/createUpdateGoal/draft/', this.userId]);
  }


  formatCascadingDescription() {
    if (this.cascadingGoal && (this.cascadingGoal.introductionText || this.cascadingGoal.description)) {
      const intro = this.cascadingGoal.introductionText?.replace(/\n/g, '<br>') || '';
      const descr = this.cascadingGoal.description?.replace(/\n/g, '<br>') || '';
      return intro + '<br><br>' + descr;
    } else {
      return '-';
    }
  }

  formatDate(date: any) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return '--';
    }
  }

  resetData() {
    this.perfromanceLevelActive = false;
    this.performanceLevelDone = false;
    this.calibrationActive = false;
    this.performanceLevelDone = false;
    this.performanceLevelEvaluation = "";
    this.performanceLevelEvaluationDate = null;
    this.postCalibrationData = {};
    this.performanceLevelDone = false;
    this.perfromanceLevelActive = false;
    this.cascadingGoal = null;
    this.stiAmount = 0;
  }

  ngOnDestroy(): void {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.canShareGoals$) {
      this.canShareGoals$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.deleteInterview$) {
      this.deleteInterview$.unsubscribe();
    }
    if (this.getDevelopmentActions$) {
      this.getDevelopmentActions$.unsubscribe();
    }
    if (this.canCreateDevelopmentActions$) {
      this.canCreateDevelopmentActions$.unsubscribe();
    }
    if (this.addCommentToDevelopPlan$) {
      this.addCommentToDevelopPlan$.unsubscribe();
    }
    if (this.shareDevelopActions$) {
      this.shareDevelopActions$.unsubscribe();
    }
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }
    if (this.changeActionStatus$) {
      this.changeActionStatus$.unsubscribe();
    }
    if (this.canShareDevelopActions$) {
      this.canShareDevelopActions$.unsubscribe();
    }
    if (this.getFeedbackEvidencesList$) {
      this.getFeedbackEvidencesList$.unsubscribe();
    }
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    if (this.peerFeedbackAllowed$) {
      this.peerFeedbackAllowed$.unsubscribe();
    }
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }
    if (this.deletePeerFeedback$) {
      this.deletePeerFeedback$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    if (this.getMyFinalEvaluation$) {
      this.getMyFinalEvaluation$.unsubscribe();
    }
    if (this.deleteObjective$) {
      this.deleteObjective$.unsubscribe();
    }
    if (this.getProposalStatus$) {
      this.getProposalStatus$.unsubscribe();
    }
    if (this.getCascadingGoals$) {
      this.getCascadingGoals$.unsubscribe();
    }
    if (this.getFinalEvaluationDate$) {
      this.getFinalEvaluationDate$.unsubscribe();
    }
  }
}