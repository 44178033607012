import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentBannerPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-cascading',
  templateUrl: './cascading.component.html',
  styleUrls: ['./cascading.component.scss']
})
export class CascadingComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: string = '';
  structureForModal: any;
  downloadReport$: any;
  isDownloadingReport: boolean = false;


  usersData: {
    list: any[],
    counter: number,
    fromRecord: number,
    numRecords: number,
    page: number
  } = {
      list: [],
      counter: 0,
      fromRecord: 0,
      numRecords: 20,
      page: 1
    };
  isLoadingUsersData: boolean = false;
  getUsersData$: any;
  searchedText: string | undefined;

  // Filtri
  isLoadingFilters: boolean = true;
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};
  genderFilterTooltipModal: ApplicationModalMessage = {
    modalId: 'cal021',
    title: '',
    text: ''
  }
  selectedFiltersNumber: number = 0;
  areaTooltipModal: ApplicationModalMessage = {
    modalId: 'cal023',
    title: '',
    text: ''
  }
  structureLevel5TooltipModal: ApplicationModalMessage = {
    modalId: 'cal024',
    title: '',
    text: ''
  }

  whoTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal014',
    title: '',
    text: ''
  };
  structureTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal015',
    title: '',
    text: ''
  };
  structureLevel3TooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal016',
    title: '',
    text: ''
  };
  jobTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal017',
    title: '',
    text: ''
  };
  functionTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal019',
    title: '',
    text: ''
  };
  isFilterOpened: boolean = false;
  translations: any;

  userSelectedRows;
  userRowPerPageOptions = [
    {
      id: 20,
      title: 20,
    },
    {
      id: 30,
      title: 30,
    },
    {
      id: 40,
      title: 40,
    },
    {
      id: 50,
      title: 50,
    },
  ]

  today: Date = new Date();


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    this.userSelectedRows = this.userRowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.route.params.subscribe((params: any) => {
              if (params.processYear) {
                this.processYear = params.processYear;
              }
              if (this.processYear) {
                this.getFilters();
                this.getUsersData();
              } else {
                this.getProcessesData();
              }
            })
          }
        });
  }

  ngOnInit() {
    this.translate.get([
      'calibration.DEFINE_PL',
      'calibration.CONFIRM_PL',
      'calibration.ACCESS_USER_DATA',
      'calibration.tooltip.WHO',
      'calibration.tooltip.WHO_DESCR',
      'calibration.filters.STRUCTURE',
      'calibration.filters.STRUCTURE_LEVEL',
      'calibration.filters.JOB',
      'calibration.filters.FUNCTION',
      'calibration.filters.STI_FILTER',
      'calibration.filters.DELETE_ALL_FILTERS',
      'calibration.filters.FILTER_ACTIVE',
      'calibration.tooltip.STRUCTURE_DESCR',
      'calibration.tooltip.STRUCTURE_LEVEL_DESCR',
      'calibration.tooltip.JOB_DESCR',
      'calibration.tooltip.FUNCTION_DESCR',
      'calibration.tooltip.STI_FILTER_DESCR',
      'calibration.tooltip.DELETE_ALL_FILTERS_DESCR',
      'calibration.tooltip.FILTER_ACTIVE_DESCR',
      'calibration.statuses.TO_COMPLETE',
      'calibration.statuses.TO_START',
      'calibration.statuses.TO_CONFIRM',
      'calibration.statuses.COMPELTED',
      'calibration.statuses.DISABLED',
      'calibration.modals.MASSIVE_PL_TEXT',
      'calibration.modals.CONTRIBUTORS',
      'performance.INSERT_OBSERVATION',
      'performance.OBSERVATION_TOOLTIP',
      'filter.ALL',
      'filter.M',
      'filter.F',
      'calibration.filters.GENDER',
      'calibration.tooltip.GENDER_DESCR',
      'calibration.filters.AREA',
      'calibration.tooltip.AREA_DESCR',
      'calibration.filters.STATUS',
      'calibration.tooltip.STATUS_DESCR',
      'calibration.filters.STRUCTURE_LEVEL_3',
      'calibration.tooltip.STRUCTURE_LEVEL_3_DESCR',
    ]).subscribe((translations: any) => {
      this.translations = translations

      this.whoTooltipModal = {
        modalId: "cal001",
        title: translations['calibration.tooltip.WHO'],
        text: translations['calibration.tooltip.WHO_DESCR'],
      }

      this.structureTooltipModal = {
        modalId: "cal002",
        title: translations['calibration.filters.STRUCTURE'],
        text: translations['calibration.tooltip.STRUCTURE_DESCR'],
      }

      this.structureLevel3TooltipModal = {
        modalId: "cal003",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }
      this.structureLevel5TooltipModal = {
        modalId: "cal003",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }

      this.jobTooltipModal = {
        modalId: "cal004",
        title: translations['calibration.filters.JOB'],
        text: translations['calibration.tooltip.JOB_DESCR'],
      }

      this.functionTooltipModal = {
        modalId: "cal005",
        title: translations['calibration.filters.FUNCTION'],
        text: translations['calibration.tooltip.FUNCTION_DESCR'],
      }

      this.genderFilterTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.GENDER'],
        text: translations['calibration.tooltip.GENDER_DESCR'],
      }

      this.areaTooltipModal = {
        modalId: "cal006",
        title: translations['calibration.filters.AREA'],
        text: translations['calibration.tooltip.AREA_DESCR'],
      }
    })
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  goToProcessDetail(processYear: string) {
    this.router.navigate(['admin/cascading/' + processYear])
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.downloadCascadingReport(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  orderBy(fieldName: string) {
  }

  // Lista utenti per cascading
  getUsersData() {
    this.isLoadingUsersData = true;
    if (this.getUsersData$) {
      this.getUsersData$.unsubscribe();
    }
    this.getUsersData$ = this.adminService.countUsersForCascading(this.processYear, this.parseFilters())
      .pipe(
        switchMap(
          (counter: SenecaResponse<any>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersData.counter = counter.response;
              // Calcolo la paginazione
              this.usersData.fromRecord = (this.usersData.page - 1) * this.usersData.numRecords;

              if (this.usersData.counter) {
                return this.adminService.listUserForCascading(this.processYear, this.usersData.fromRecord, this.usersData.numRecords, this.parseFilters());
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "casc001",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsersData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "casc003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            data.response.users.forEach((user: any) => {
              user.statusDate = user.statusDate ? moment(user.statusDate).format('DD/MM/YYYY') : null;
            })
            this.usersData.list = data.response.users || [];
          }
          this.isLoadingUsersData = false;
        }
        , (err: any) => {
          this.isLoadingUsersData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "casc002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  userPageChanged(page: number) {
    this.usersData.page = page;
    this.usersData.list = [];
    this.getUsersData();
  }

  changeUserNumRecords(item: any) {
    this.userSelectedRows = item;
    this.usersData.numRecords = item.id;
    this.usersData.list = [];
    this.usersData.fromRecord = 0;
    this.usersData.page = 0;
    this.getUsersData();
  }

  searchedTextChange(text: string) {
    this.selectedFilters.searchedText = text;
  }

  // Torna un oggetto che associa campo/id da passare
  parseFilters() {
    let keys = Object.keys(this.selectedFilters);
    if (this.selectedFilters && keys && keys.length) {
      return {
        searchedText: this.selectedFilters.searchedText,
        area: this.selectedFilters.area && this.selectedFilters.area.id != 'all' ? this.selectedFilters.area.id : null,
        chiaveSesso: this.selectedFilters.chiaveSesso && this.selectedFilters.chiaveSesso.id != 'all' ? this.selectedFilters.chiaveSesso.id : null,
        denominazioneSO3: this.selectedFilters.denominazioneSO3 && this.selectedFilters.denominazioneSO3.id != 'all' ? this.selectedFilters.denominazioneSO3.id : null,
        denominazioneSO5: this.selectedFilters.denominazioneSO5 && this.selectedFilters.denominazioneSO5.id != 'all' ? this.selectedFilters.denominazioneSO5.id : null,
        funzione: this.selectedFilters.funzione && this.selectedFilters.funzione.id != 'all' ? this.selectedFilters.funzione.id : null,
        mansione: this.selectedFilters.mansione && this.selectedFilters.mansione.id != 'all' ? this.selectedFilters.mansione.id : null,
        structures: this.selectedFilters.structures && this.selectedFilters.structures.id != 'all' ? this.selectedFilters.structures.id : null,
      }
    } else {
      return {
        searchedText: this.selectedFilters.searchedText || '',
        area: null,
        chiaveSesso: null,
        denominazioneSO3: null,
        denominazioneSO5: null,
        funzione: null,
        mansione: null,
        structures: null,
      }
    }
  }

  onSearch() {
    this.resetUserData();
    this.getUsersData();
  }

  clearFilters() {
    this.selectedFilters = {
      chiaveSesso: this.filtersObject.chiaveSesso[0],
      denominazioneSO3: this.filtersObject.denominazioneSO3[0],
      denominazioneSO5: this.filtersObject.denominazioneSO5[0],
      area: this.filtersObject.area[0],
      funzione: this.filtersObject.funzione[0],
      mansione: this.filtersObject.mansione[0],
      structures: this.filtersObject.structures[0],
      sti: this.filtersObject.sti[0],
      calibrationStatusTypes: this.filtersObject.calibrationStatusTypes[0]
    }
    this.selectedFiltersNumber = 0;
    this.resetUserData();
    this.getUsersData();
  }

  // apre i filtri
  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.resetUserData();
    this.getUsersData();
  }


  resetUserData() {
    this.usersData.numRecords = this.userSelectedRows.id;
    this.usersData.list = [];
    this.usersData.fromRecord = 0;
    this.usersData.page = 1;
  }


  selectFilter(data: any, id: any) {
    if (data.id == 'all') {
      this.selectedFiltersNumber--;
    }
    switch (id) {
      case 'structures':
        if (!this.selectedFilters.structures.value && this.selectedFilters.structures != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.structures = data;
        break;
      case 'denominazioneSO3':
        if (!this.selectedFilters.denominazioneSO3.value && this.selectedFilters.denominazioneSO3 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO3 = data;
        break;
      case 'denominazioneSO5':
        if (!this.selectedFilters.denominazioneSO5.value && this.selectedFilters.denominazioneSO5 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO5 = data;
        break;
      case 'mansione':
        if (!this.selectedFilters.mansione.value && this.selectedFilters.mansione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.mansione = data;
        break;
      case 'funzione':
        if (!this.selectedFilters.funzione.value && this.selectedFilters.funzione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.funzione = data;
        break;
      case 'area':
        if (!this.selectedFilters.area.value && this.selectedFilters.area != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.area = data;
        break;
      case 'sti':
        if (!this.selectedFilters.sti.value && this.selectedFilters.sti != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.sti = data;
        break;
      case 'chiaveSesso':
        if (!this.selectedFilters.chiaveSesso.value && this.selectedFilters.chiaveSesso != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.chiaveSesso = data;
        break;
      case 'calibrationStatusTypes':
        if (!this.selectedFilters.calibrationStatusTypes.value && this.selectedFilters.calibrationStatusTypes != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.calibrationStatusTypes = data;
        break;
      default:
        break;
    }
  }


  // Vai alla pagina di creazione/modifica obiettivi cascading
  goToEditCascading(userId: string) {
    this.router.navigate(['admin/cascadingCreateUpdate/', this.processYear, userId]);
  }



  // recupera il distinct dei filtri disponibili
  getFilters() {
    this.isLoadingFilters = true;

    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }

    this.getFiltersData$ = this.adminService.getDistinctFilters(parseInt(this.processYear))
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let all = { id: 'all', title: this.translations['filter.ALL'], value: null }
          let gender = [all, { id: 'M', title: this.translations['filter.M'], value: 'M' }, { id: 'F', title: this.translations['filter.F'], value: 'F' }]
          let denominazioneSO3: any = [all]
          if (data.response.denominazioneSO3 && data.response.denominazioneSO3.length) {
            for (let i = 0; i < data.response.denominazioneSO3.length; i++) {
              denominazioneSO3.push({
                id: data.response.denominazioneSO3[i],
                title: data.response.denominazioneSO3[i],
                value: data.response.denominazioneSO3[i]
              })
            }
          }
          let denominazioneSO5: any = [all]
          if (data.response.denominazioneSO5 && data.response.denominazioneSO5.length) {
            for (let i = 0; i < data.response.denominazioneSO5.length; i++) {
              denominazioneSO5.push({
                id: data.response.denominazioneSO5[i],
                title: data.response.denominazioneSO5[i],
                value: data.response.denominazioneSO5[i]
              })
            }
          }
          let funzione: any = [all]
          if (data.response.funzione && data.response.funzione.length) {
            for (let i = 0; i < data.response.funzione.length; i++) {
              funzione.push({
                id: data.response.funzione[i],
                title: data.response.funzione[i],
                value: data.response.funzione[i]
              })
            }
          }
          let mansione: any = [all]
          if (data.response.mansione && data.response.mansione.length) {
            for (let i = 0; i < data.response.mansione.length; i++) {
              mansione.push({
                id: data.response.mansione[i],
                title: data.response.mansione[i],
                value: data.response.mansione[i]
              })
            }
          }
          let area: any = [all]
          if (data.response.area && data.response.area.length) {
            for (let i = 0; i < data.response.area.length; i++) {
              area.push({
                id: data.response.area[i],
                title: data.response.area[i],
                value: data.response.area[i]
              })
            }
          }
          let structures: any = [all]
          if (data.response.structures && data.response.structures.length) {
            for (let i = 0; i < data.response.structures.length; i++) {
              structures.push({
                id: data.response.structures[i].structureId,
                title: data.response.structures[i].title,
                value: data.response.structures[i].structureId
              })
            }
          }
          this.filtersObject = {
            chiaveSesso: gender,
            denominazioneSO3: denominazioneSO3,
            denominazioneSO5: denominazioneSO5,
            funzione: funzione,
            mansione: mansione,
            area: area,
            structures: structures,
            sti: [
              all,
              {
                id: 'on',
                title: this.translations['process.filters.STI_ON'],
                value: 'WITH'
              },
              {
                id: 'off',
                title: this.translations['process.filters.STI_OFF'],
                value: 'WITHOUT'
              }
            ],
            calibrationStatusTypes: [
              all,
              {
                id: 'CALIBRATION_DISABLED',
                title: this.translations['calibration.statuses.DISABLED'],
                value: 'CALIBRATION_DISABLED'
              },
              {
                id: 'CALIBRATION_TODO',
                title: this.translations['calibration.statuses.TO_START'],
                value: 'CALIBRATION_TODO'
              },
              {
                id: 'CALIBRATION_TO_CONFIRM',
                title: this.translations['calibration.statuses.TO_CONFIRM'],
                value: 'CALIBRATION_TO_CONFIRM'
              },
              {
                id: 'CALIBRATION_CONFIRMED',
                title: this.translations['calibration.statuses.COMPELTED'],
                value: 'CALIBRATION_CONFIRMED'
              }]
          }
          this.selectedFilters = {
            chiaveSesso: this.filtersObject.chiaveSesso[0],
            denominazioneSO3: this.filtersObject.denominazioneSO3[0],
            denominazioneSO5: this.filtersObject.denominazioneSO5[0],
            funzione: this.filtersObject.funzione[0],
            mansione: this.filtersObject.mansione[0],
            area: this.filtersObject.area[0],
            structures: this.filtersObject.structures[0],
            sti: this.filtersObject.sti[0],
            calibrationStatusTypes: this.filtersObject.calibrationStatusTypes[0]
          }
          this.selectedFiltersNumber = 0;
        }
        this.isLoadingFilters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFilters = false;
      });
  }

  ngOnDestroy() {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    if (this.getUsersData$) {
      this.getUsersData$.unsubscribe();
    }
  }
}
