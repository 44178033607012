import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManagerService } from '../shared/services/manager.service';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { ManagerHomeComponent } from './home/home.component';
import { ManagerPersonDetailsComponent } from './personDetails/personDetails.component';
import { Subordinateservice } from '../manager/services/subordinates.service';
import { ManagerGoalDetailsComponent } from './goalDetails/goalDetails.component';
import { CollaboratorService } from '../shared/services/collaborator.service';
import { CanDeactivateUpdateInterviewManagerGuardService } from '../manager/services/can-deactivate-update-interview-manager-guard.component';
import { CanDeactivateUpdateClapManagerGuardService } from '../manager/services/can-deactivate-update-clap-guard.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { CanDeactivateUpdateGoalManagerGuardService } from '../manager/services/can-deactivate-update-goal-manager-guard.component';
import { DefinePerformanceLevelManagerComponent } from '../manager/definePerformanceLevel/definePerformanceLevel.component';
import { ManagerComponent } from './manager.component';
import { SendClapComponent } from './sendClap/sendClap.component';
import { ManagerRoutingModule } from './manager-routing.module';
import { CreateUpdateGoalComponent } from './createUpdateGoal/createUpdateGoal.component';
import { OrganizeInterviewComponent } from './organizeInterview/organizeInterview.component';
import { CreateUpdateGoalPlatformManagerComponent } from './createUpdateGoalPlatformManager/createUpdateGoalPlatformManager.component';
import { CreateUpdateDevelopmentActionManagerComponent } from './createUpdateDevelopmentActionManager/createUpdateDevelopmentActionManager.component';
import { AddMidTermReviewManagerComponent } from './addMidTermReview/addMidTermReview.component';
import { FinalEvaluationManagerComponent } from './finalEvaluation/finalEvaluation.component';
import { StoreModule } from '@ngrx/store';
import { managerReducer } from './ngrx/manager.reducers';
import { ManagerEffects } from './ngrx/manager.effects';
import { EffectsModule } from '@ngrx/effects';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DxChartModule, DxCircularGaugeModule, DxPieChartModule } from 'devextreme-angular';
import { PostCalibrationEvaluationComponent } from './postCalibrationEvaluation/postCalibrationEvaluation.component';
import { TalentScoutingComponent } from './talentScouting/talentScouting.component';
import { SpeedScreeningComponent } from './talentScouting/speedScreening/speedScreening.component';
import { ObservationGridComponent } from './talentScouting/observationGrid/observationGrid.component';
import { EvaluationGridComponent } from './evaluationGrid/evaluationGrid.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateOptionalObjectivesComponent } from './createOptionalObjectives/createOptionalObjectives.component';


@NgModule({
  declarations: [
    ManagerComponent,
    ManagerHomeComponent,
    ManagerPersonDetailsComponent,
    ManagerGoalDetailsComponent,
    SendClapComponent,
    CreateUpdateEvidenceComponent,
    CreateUpdateGoalComponent,
    OrganizeInterviewComponent,
    ManagerGoalDetailsComponent,
    CreateUpdateGoalPlatformManagerComponent,
    SendClapComponent,
    CreateUpdateDevelopmentActionManagerComponent,
    CreateUpdateEvidenceComponent,
    AddMidTermReviewManagerComponent,
    FinalEvaluationManagerComponent,
    DefinePerformanceLevelManagerComponent,
    DashboardComponent,
    PostCalibrationEvaluationComponent,
    TalentScoutingComponent,
    SpeedScreeningComponent,
    ObservationGridComponent,
    ObservationGridComponent,
    EvaluationGridComponent,
    CreateOptionalObjectivesComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ManagerRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    DxCircularGaugeModule,
    DxChartModule,
    DxPieChartModule,
    DragDropModule,
    StoreModule.forFeature('manager', managerReducer),
    EffectsModule.forFeature([ManagerEffects])
  ],
  providers: [
    Subordinateservice,
    ManagerService,
    PersonDetailsGuard,
    CollaboratorService,
    CanDeactivateUpdateInterviewManagerGuardService,
    CanDeactivateUpdateClapManagerGuardService,
    CanDeactivateUpdateGoalManagerGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class ManagerModule { }

