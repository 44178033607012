import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { PerfCareUserPhaseGoal, GetPerformanceCareTagsResponse, SenecaResponse, CreateUserGoalForUserResponse, PerfCareGoalRentStatuses, PerfCareMacroGoalTypes, GetMyGoalByIdResponse, UpdateMyGoalResponse, GetBehaviorCareTagsResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { Tag, PerfCareTag } from '../../../commonclasses';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import * as moment from 'moment';

@Component({
  selector: 'app-collaborator-createUpdateGoal',
  templateUrl: './createUpdateGoal.component.html',
  styleUrls: ['./createUpdateGoal.component.scss']
})
export class CreateUpdateGoalCollaboratorComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  userId: string = '';
  personDetails: any;
  goal: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.DEVELOPMENT
  };
  isFetchingCareOptions: boolean = false;
  getCareOptions$: Subscription | null = null;
  careOptionsCounter: number = 0;
  careOptions: PerfCareTag[] = [];
  createGoal$: Subscription | null = null;
  updateGoal$: Subscription | null = null;
  getGoal$: Subscription | null = null;
  goalId: string = '';
  goalCopy: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.DEVELOPMENT
  };
  charsGoalDescription: number = 600;
  charsGoalObjectiveDescription: number = 600;
  charsGoalHowToUseDescription: number = 600;
  charsGoalObjectiveRelevantMotivation: number = 600;
  maxTextAreaChars: number = 600;

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  careTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  applyCareTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  weightTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  deadlineTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isImpersonate: boolean = false;
  // peso
  differenceWeightSum: number = 0;
  weightOptions: any[] = [];
  weightOptionSelected: any = null;

  isLoadingTotalWeight: boolean = false;
  totalWeightSection: number = 0;
  weightSum: number = 0;
  // Data
  today: Date = new Date();
  selectedDate: Date = new Date();
  isChallenge: boolean = false;
  endYear: Date = new Date();
  getRemainingWeight$: Subscription = new Subscription;
  remainingWeight: any;
  isLoadingData: boolean = false;
  saveModal: any = {};
  isOptional: boolean = false;

  getProposalStatus$: Subscription = new Subscription;
  proposalStatus: any;
  isLoadingProposalStatus: boolean = false;
  isNewDraft: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      });

    if (window.location.href.indexOf('createUpdateChallenge') > 0) {
      this.isChallenge = true;
    }

    if (window.location.href.indexOf('optional') > 0) {
      this.isOptional = true;
    }

    if (window.location.href.indexOf('draft') > 0) {
      this.isNewDraft = true;
    }

    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      this.getProposalStatus();

      this.endYear = new Date(this.runningYear, 11, 31, 11, 59);
      this.selectedDate = this.endYear;

      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {

              this.userId = params.userId;
              this.goalId = params.goalId;

              this.appStore.select(fromApp.getLoggedUser)
                .subscribe(
                  (loggedUser: any) => {
                    this.personDetails = loggedUser && loggedUser.user;
                    this.getCareOptions();
                    // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                    if (this.personDetails && this.personDetails.userId && this.goalId) {
                      this.getGoalById();
                    } else {
                      this.getRemainingWeight();
                      this.goal = {
                        goalId: '',
                        creationUserId: '',
                        phaseId: '',
                        userId: '',
                        title: '',
                        description: '',
                        objectiveDescription: '',
                        howToUseCareTag: '',
                        goalStatus: '',
                        macroGoalType: this.isChallenge ? PerfCareMacroGoalTypes.DEVELOPMENT : PerfCareMacroGoalTypes.INDIVIDUAL
                      };
                    }
                  });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.WHAT',
        'generic.HOW',
        'goalSetting.createGoal.WHAT_TAB',
        'goalSetting.createGoal.HOW_TAB',
        'goalSetting.createGoal.CARE_ATTRIBUTES',
        'goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION',
        'generic.TITLE',
        'goalSetting.createGoal.OBJECTIVE_TITLE',
        'goalSetting.createGoal.OBJECTIVE_TITLE_TEXT',
        'generic.DESCRIPTION',
        'goalSetting.createGoal.DESCRIPTION_SUBTITLE',
        'goalSetting.createGoal.DESCRIPTION_TEXT',
        'goalSetting.createGoal.OBJECTIVE_DESCRIPTION_COLLABORATOR',
        'goalSetting.createGoal.WHAT_AND_HOW',
        'goalSetting.createGoal.INSERT_OBJECTIVE_COLLABORATOR_DESCRIPTION',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT_SUBTITLE',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT_TEXT',
        'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE',
        'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_TEXT',
      ])
      .subscribe(translations => {
        this.titleTooltipModal = {
          modalId: '127',
          title: translations['generic.TITLE'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_TITLE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_TITLE_TEXT']
        };

        this.descriptionTooltipModal = {
          modalId: '128',
          title: translations['generic.DESCRIPTION'],
          subtitle: translations['goalSetting.createGoal.DESCRIPTION_SUBTITLE'],
          text: translations['goalSetting.createGoal.DESCRIPTION_TEXT']
        };
        this.careTooltipModal = {
          modalId: '130',
          title: translations['goalSetting.createGoal.CARE_ATTRIBUTES'],
          text: translations['goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION']
        };
        this.weightTooltipModal = {
          modalId: '121',
          title: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT_SUBTITLE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT_TEXT']
        };
        this.deadlineTooltipModal = {
          modalId: 'objw001',
          title: translations['goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_TEXT']
        };

      });
  }


  // Se non hai inserito nessun dato
  noDataInserted() {
    if (!this.goal.title && !this.goal.description && !this.goal.careTag && !this.selectedDate && !this.weightOptionSelected) {
      return true;
    }
    return false;
  }

  getRemainingWeight() {
    this.isLoadingTotalWeight = true;

    if (this.getRemainingWeight$) {
      this.getRemainingWeight$.unsubscribe();
    }

    this.getRemainingWeight$ = this.collaboratorService.getRemainingWeight(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (this.goal.macroGoalType.includes('TEMP') || this.isNewDraft) {
            this.remainingWeight = data.response?.remainingWeights?.TEMP_INDIVIDUAL || 0;
          } else {
            this.remainingWeight = data.response?.remainingWeights?.INDIVIDUAL || 0;
          }

          this.weightOptions = [];

          if (this.goalId && this.goal?.weight) {
            this.weightOptions = [];
            for (let i = 10; i < 95; i += 5) {
              if (i <= (this.remainingWeight + this.goal.weight)) {
                this.weightOptions.push({
                  id: i,
                  title: i.toString(),
                  disabled: false
                })
              } else {
                this.weightOptions.push({
                  id: i,
                  title: i.toString(),
                  disabled: true
                })
              }
            }
          } else {
            for (let i = 10; i < 95; i += 5) {
              if (i <= this.remainingWeight) {
                this.weightOptions.push({
                  id: i,
                  title: i.toString(),
                  disabled: false
                })
              } else {
                this.weightOptions.push({
                  id: i,
                  title: i.toString(),
                  disabled: true
                })
              }
            }
          }


        }
        this.isLoadingTotalWeight = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw013",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTotalWeight = false;
      });
  }


  // Quando si scrive sull'input del titolo obiettivo
  goalTitleChanged(text: string) {
    this.goal.title = text;
  }

  // Quando si scrive sulla textarea della descrizione obiettivo
  goalDescriptionChanged(text: string) {
    this.goal.description = text;
    this.charsGoalDescription = this.maxTextAreaChars - text.length;
  }

  // Quando selezioni un altro Care tag
  changeCareTag(tag: Tag) {
    this.goal.careTag = tag;
    this.goal.careTagId = tag.tagId;
  }


  // Recupera i tag Care
  getCareOptions() {
    this.isFetchingCareOptions = true;
    this.getCareOptions$ = this.collaboratorService.getCareOptions('', '', true)
      .subscribe((data: SenecaResponse<GetPerformanceCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '028',
            text: this.translate.instant('errors.' + data.error),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.careOptionsCounter = data.response.totalRowsCount;
          this.careOptions = data.response.rows;
        }
        this.isFetchingCareOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: '029',
            text: this.translate.instant('errors.' + err),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCareOptions = false;
        });
  }

  // Recupera l'obiettivo dal goalId
  getGoalById() {
    this.isLoadingData = true;

    this.getGoal$ = this.collaboratorService.getGoalByIdForUser(this.runningYear.toString(), this.goalId)
      .subscribe((data: SenecaResponse<GetMyGoalByIdResponse>) => {
        if (data && data.error) {
          this.redirectService.goBackBrowser();
          const messageObj: ApplicationModalMessage = {
            modalId: '032',
            text: this.translate.instant('errors.' + data.error),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goal = data.response;

          if (!this.isChallenge && !this.isOptional) {
            this.getRemainingWeight();
          }

          if (this.goal.description && this.goal.description.length) {
            this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
          }

          if (this.goal.weight) {
            this.weightOptionSelected = {
              id: this.goal.weight,
              title: this.goal.weight?.toString()
            }
          }

          this.cdr.detectChanges();
          this.selectedDate = new Date(this.goal?.deadlineDate || '');
          this.goalCopy = JSON.parse(JSON.stringify(data.response));
        }
        this.isLoadingData = false;
      },
        (err: string) => {
          this.redirectService.goBackBrowser();
          const messageObj: ApplicationModalMessage = {
            modalId: '033',
            text: this.translate.instant('errors.' + err),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingData = false;
        });
  }

  isMandatoryDataInserted() {
    if (this.isChallenge) {
      return this.goal.title && this.goal.description && this.goal.careTag;
    } else if (this.getIsOptional()) {
      return this.goal.title && this.goal.description && this.goal.careTag && this.selectedDate;
    } else if (this.goal.macroGoalType == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
      return this.goal.title && this.goal.description;
    } else {
      return this.goal.title && this.goal.description && this.goal.careTag && this.selectedDate && this.weightOptionSelected && this.weightOptionSelected.id;
    }
  }

  checkIfDataChanged() {
    if (this.isChallenge) {
      return this.goal.title != this.goalCopy.title || this.goal.description != this.goalCopy.description || this.goal.careTagId != this.goalCopy.careTagId;
    } else if (this.getIsOptional()) {
      return this.goal?.title != this.goalCopy?.title || this.goal?.description != this.goalCopy?.description
        || this.goal?.careTagId != this.goalCopy?.careTagId || moment(this.goalCopy?.deadlineDate).format('DD.MM.YYYY') != moment(this.selectedDate).format('DD.MM.YYYY')
    } else {
      return this.goal?.title != this.goalCopy?.title || this.goal?.description != this.goalCopy?.description
        || this.goal?.careTagId != this.goalCopy?.careTagId || this.goalCopy?.weight != this.weightOptionSelected?.id || moment(this.goalCopy?.deadlineDate).format('DD.MM.YYYY') != moment(this.selectedDate).format('DD.MM.YYYY')
    }
  }

  // Aggiorna obiettivo
  updateGoal(goNext?: boolean) {
    this.isLoadingData = true;
    this.goal.userId = this.personDetails.userId;
    let serviceToCall;
    if (this.isChallenge) {
      serviceToCall = this.collaboratorService.updateDevelopmentGoal(this.runningYear, this.goal);
    } else {
      serviceToCall = this.collaboratorService.updateRoleGoal(this.runningYear, this.goal);
    }

    this.updateGoal$ = serviceToCall.subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: '034',
          text: this.translate.instant('errors.' + data.error),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else if (data && data.response) {
        const messageObj = {
          modalId: 'updobj002',
          title: this.translate.instant('goalSetting.createGoal.GOAL_UPDATED_COLLABORATOR'),
          text: this.translate.instant('goalSetting.createGoal.GOAL_UPDATED_DESCRIPTION_COLLABORATOR')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      }
      this.isLoadingData = false;
    },
      (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '036',
          text: this.translate.instant('errors.' + err),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingData = false;
      });
  }

  // Crea obiettivo
  createGoal() {
    this.isLoadingData = true;


    this.goal.userId = this.personDetails.userId;
    this.goal.deadlineDate = this.selectedDate;

    if (this.isNewDraft) {
      this.goal.macroGoalType = PerfCareMacroGoalTypes.TEMP_INDIVIDUAL;
    }

    let callToServices;
    if (this.isChallenge) {
      callToServices = this.collaboratorService.createDevelopmentGoal(this.runningYear, this.goal);;
    } else {
      callToServices = this.collaboratorService.createRoleGoal(this.runningYear, this.goal);
    }

    this.createGoal$ = callToServices.subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: '037',
          text: this.translate.instant('errors.' + data.error),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else if (data && data.response) {
        const messageObj: ApplicationModalMessage = {
          modalId: '038',
          title: this.translate.instant('goalSetting.createGoal.GOAL_CREATED'),
          text: this.translate.instant('goalSetting.createGoal.GOAL_CREATED_DESCRIPTION')
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      this.redirectService.goBackBrowser();
      this.isLoadingData = false;
    },
      (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '039',
          text: this.translate.instant('errors.' + err),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingData = false;
      });
  }

  tabId(index: number, el: any) {
    return el.id;
  }

  isLoading() {
    return this.isLoadingData;
  }

  // titolo sidebar
  getSidebarTitle() {
    if (this.goalId) {
      if (this.isChallenge) {
        return this.translate.instant('objectiveWizard.CRUD.UPDATE_CHALLENGE_SIDEBAR_TITLE');
      } else {
        return this.translate.instant('objectiveWizard.CRUD.UPDATE_OBJECTIVE_SIDEBAR_TITLE');
      }
    } else {
      if (this.isChallenge) {
        return this.translate.instant('objectiveWizard.CRUD.CHALLENGE_SIDEBAR_TITLE');
      } else {
        return this.translate.instant('objectiveWizard.CRUD.OBJECTIVE_SIDEBAR_TITLE');
      }
    }
  }

  getSidebarDescription() {
    if (this.goalId && this.getIsOptional()) {
      return this.translate.instant('objectiveWizard.CRUD.UPDATE_OPTIONAL_SIDEBAR_DESCR');
    } else {
      if (this.isChallenge) {
        return this.translate.instant('objectiveWizard.CRUD.CHALLENGE_SIDEBAR_DESCR');
      } else if (this.getIsOptional()) {
        return this.translate.instant('objectiveWizard.CRUD.OPTIONAL_SIDEBAR_DESCR');
      } else {
        return this.translate.instant('objectiveWizard.CRUD.OBJECTIVE_SIDEBAR_DESCR');
      }
    }
  }

  // Quando si scrive sull'input del peso obiettivo
  goalWeightChanged(weightOption: any) {
    let weightSelected = this.weightOptions.filter((x: any) => {
      return x.id == weightOption.id;
    });
    this.weightOptionSelected = weightSelected && weightSelected[0];
    this.goal.weight = this.weightOptionSelected.id;
    if (this.goalId && this.goalId.length) {
      this.differenceWeightSum = this.totalWeightSection - (this.weightSum - <number>(this.goalCopy.weight || 0) || 0) - (this.goal.weight || 0);
    } else {
      this.differenceWeightSum = this.totalWeightSection - (this.weightSum || 0) - (this.goal.weight || 0);
    }
  }

  selectedDateChanged(newDate: Date) {
    this.selectedDate = newDate;
    this.goal.deadlineDate = newDate;
  }


  onBackClicked() {
    // se ho dei dati inseriti chiedo all'utente se vuole perderli o restare in pagina
    if (this.checkIfDataChanged()) {
      this.modalService.open('back-modal');
    } else {
      // Altrimenti se non ha toccato la pagina torno indietro
      if (this.runningYear >= 2023 && this.proposalStatus && this.proposalStatus.status == 'APPROVED') {
        this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.runningYear);
      } else {
        this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.runningYear]);
      }
    }
  }

  closeBackModal(confirm?: boolean) {
    // Se decide di restare in pagina chiudo solo la modale
    this.modalService.close('back-modal');
    if (confirm) {
      // altrimenti torno indietro
      if (this.runningYear >= 2023 && this.proposalStatus && this.proposalStatus.status == 'APPROVED') {
        this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.runningYear);
      } else {
        this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.runningYear]);
      }
    }
  }

  createUpdate(isBack?: boolean) {
    this.saveModal = {};
    this.saveModal.title = this.goalId && this.goalId.length ? this.translate.instant('goalSetting.UPDATE_GOAL') : this.translate.instant('goalSetting.SAVE_GOAL');
    this.saveModal.confirmLabel = this.goalId && this.goalId.length ? this.translate.instant('generic.UPDATE') : this.translate.instant('generic.SAVE');
    if (isBack) {
      // Back e ho dati, compresi obbligatori
      if (this.checkIfDataChanged() && this.isMandatoryDataInserted()) {
        this.saveModal.text = this.translate.instant('goalSetting.createGoal.BACK_GENERIC_CREATE');
        this.modalService.open('save-modal');
      } else {
        // Back e non ho dati da salvare, torno semplicemente indietro
        if (this.runningYear >= 2023 && this.proposalStatus && this.proposalStatus.status == 'APPROVED') {
          this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.runningYear);
        } else {
          this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.runningYear]);
        }
      }
    } else {
      // sto salvando
      // Se è un update
      if (this.goalId && this.goalId.length) {
        // se è l'update di una sfida
        if (this.isChallenge) {
          this.saveModal.text = this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_CHALLENGE');
          this.saveModal.confirmLabel = this.translate.instant('goalSetting.createGoal.YES_UPDATE_CHALLENGE');
        } else {
          // se update di un obiettivo
          this.saveModal.text = this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_UPDATE_GOAL');
          this.saveModal.confirmLabel = this.translate.instant('goalSetting.createGoal.YES_UPDATE_THE_GOAL');
        }
      } else {
        // se è create di una sfida
        if (this.isChallenge) {
          this.saveModal.text = this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_CHALLENGE');
          this.saveModal.confirmLabel = this.translate.instant('goalSetting.createGoal.YES_SAVE_CHALLENGE');
        } else {
          // se è create di un obiettivo
          this.saveModal.text = this.translate.instant('goalSetting.createGoal.DO_YOU_WANT_CREATE_GOAL');
          this.saveModal.confirmLabel = this.translate.instant('goalSetting.createGoal.YES_SAVE_THE_GOAL');
        }
      }

      this.modalService.open('save-modal');
    }
  }

  closeSaveModal(confirm?: boolean, forceBack?: boolean) {
    this.modalService.close('save-modal');
    if (forceBack) {
      if (this.runningYear >= 2023 && this.proposalStatus.status != 'APPROVED') {
        this.router.navigate(['/collaborator/objectiveWizard/', this.userId, this.runningYear]);
      } else {
        this.redirectService.goToPersonDetailsCollaborator(this.userId, false, false, this.runningYear);
      }
    } else if (confirm) {
      if (this.goalId && this.goalId.length) {
        this.updateGoal();
      } else {
        this.createGoal();
      }
    }
  }

  getIsOptional() {
    return this.isOptional || (this.goal && this.goal.macroGoalType != PerfCareMacroGoalTypes.DEVELOPMENT && this.goalId && !this.goal.weight);
  }

  getProposalStatus() {
    this.isLoadingProposalStatus = true;
    if (this.getProposalStatus$) {
      this.getProposalStatus$.unsubscribe();
    }
    this.getProposalStatus$ = this.collaboratorService.getGoalSettingProposalStatus(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingProposalStatus = false;
        } else {
          this.proposalStatus = data.response;
          // Stati possibili
          // TO_PROPOSE
          // PROPOSED
          // APPROVED
          // REJECTED
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "objw009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProposalStatus = false;
      });
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getCareOptions$) {
      this.getCareOptions$.unsubscribe();
    }
    if (this.createGoal$) {
      this.createGoal$.unsubscribe();
    }
    if (this.updateGoal$) {
      this.updateGoal$.unsubscribe();
    }
    if (this.getGoal$) {
      this.getGoal$.unsubscribe();
    }
    if (this.getRemainingWeight$) {
      this.getRemainingWeight$.unsubscribe();
    }
  }
}