import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-admin-process-setup',
  templateUrl: './processSetup.component.html',
  styleUrls: ['./processSetup.component.scss']
})
export class ProcessSetupComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];
  processToDelete: any;

  processId: string = '';

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.getProcessesData();
          }
        });
  }

  ngOnInit() {
    this.translate.get([
      'generic.DETAILS',
      'generic.EDIT',
      'generic.DELETE'
    ]).subscribe((translations) => {
      this.menuOptions = [
        {
          id: 'details',
          title: translations['generic.DETAILS'],
          icon: '/assets/img/icons/clipboard.svg',
        },
        {
          id: 'edit',
          title: translations['generic.EDIT'],
          icon: '/assets/img/icons/edit.svg',
        }
      ]
    })
  }

  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  getStatus(phase: any) {
    if (phase && phase.status) {
      switch (phase.status) {
        case 'TO_START': {
          return {
            status: 'toStart',
            statusMessage: this.translate.instant('itemListTeamStatus.toStart')
          }
        }
        case 'STARTED': {
          return {
            status: 'inProgress',
            statusMessage: this.translate.instant('itemListTeamStatus.inProgress') + ' ' + (phase.percentage.toFixed(1)) + '%'
          }
        }
        case 'COMPLETED': {
          return {
            status: 'completed',
            statusMessage: this.translate.instant('itemListTeamStatus.completed')
          }
        }
        default:
          return {
            status: 'disabled',
            statusMessage: this.translate.instant('itemListTeamStatus.disabled')
          }
      }
    } else {
      return {
        status: 'disabled',
        statusMessage: this.translate.instant('itemListTeamStatus.disabled')
      }
    }

  }


  getModalDescription(feedback: any, translations: any) {
    let text = '';
    let peerFeedback = '';
    if (feedback && feedback.requestedPeerFeedbackCount > 0) {
      peerFeedback = '<b>' + feedback.requestedPeerFeedbackCount + '</b>' + translations['monitoring.modals.PEER_REQ1'] + '<b>' + feedback.requestedFeedbackNotesWithResponseCount + ' </b> ' + translations['monitoring.modals.PEER_REQ2'] + '</b><br>';
    }
    if (feedback) {
      if (peerFeedback && peerFeedback.length) {
        text += peerFeedback;
      }
      text += translations['monitoring.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseCount || '-') + '</b><br>';
      text += translations['monitoring.modals.INTERVIEW'] + ' <b>' + (feedback.createdFeedbackDialogueCount || '-') + '</b><br>';
      text += translations['monitoring.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceCount || '-') + '</b><br>';
    }
    return text;
  }

  openMenu(process: any) {
    this.closeAllMenus();
    process.isMenuOpen = !process.isMenuOpen;
  }

  goToProcessDetail(processYear: string) {
    this.router.navigate(['admin/processSetup/participantList/' + processYear])
  }


  goToNewProcess() {
    this.router.navigate(['admin/createUpdateProcess'])
  }

  orderBy(fieldName: string) {
  }

  showTabs() {
    return window.location.href.indexOf("participantList") >= 0 &&
      window.location.href.indexOf("objectiveDefinition") >= 0 &&
      window.location.href.indexOf("phaseDefinition") >= 0;
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, process: any) {
    process.isMenuOpen = false;
    if (options.id == "details") {
      this.router.navigate(['admin/processSetup/participantList/' + process.year]);
    } else if (options.id == "edit") {
      this.router.navigate(['admin/createUpdateProcess/' + process.year]);
    }
  }

  // Chiude i menù di tutti i processi se clicco fuori
  closeAllMenus() {
    if (this.processData?.list?.length) {
      this.processData.list.forEach((process) => {
        process.isMenuOpen = false;
      })
    }
  }

  ngOnDestroy() {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
  }

}


