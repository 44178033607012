<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="setValueAction(true)" [isBackButton]="true"
      [title]="('final.MAN_SIDENAV_TITLE' | translate) + (isLoadingPersonDetails ? '' : personDetails.forename + ' ' + personDetails.surname + '.')"
      [description]="('final.MAN_SIDENAV_DESCR' | translate) + (isLoadingUserGoals ? '' : (goalData && goalData.title || ''))">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserGoals">

        <ng-container *ngIf="goalData && goalData.objectiveDescription && goalData.objectiveDescription.length">
          <!-- Cosa ti farà dire di aver raggiunto l'obiettivo -->
          <input-container [isLoading]="isLoadingUserGoals" [tooltipModalMessage]="finalObservationTooltip"
            [isValued]="true" title="{{'final.WHAT_WILL_TELL' | translate}}" [readonly]="true">
            <p [innerHTML]="goalData.objectiveDescription"></p>
          </input-container>
        </ng-container>


        <!-- Osservazioni finali -->
        <input-container [isLoading]="isLoadingUserGoals" [tooltipModalMessage]="finalObservationTooltip"
          [isValued]="finalObservation && finalObservation.trim().length" [isObbligatory]="true"
          title="{{'final.WHAT_OBSERVE' | translate}}">
          <europ-text-area [bindValue]="finalObservation" [maxlength]="600"
            (onModelChanged)="finalObservationChanged($event)"
            placeholder="{{'final.WHAT_OBSERVE_PLACEHOLDER' | translate}}">
          </europ-text-area>
        </input-container>

        <ng-container *ngIf="!isOperationsSpecificGoal">
          <!-- Stato avanzamento -->
          <div class="objective-feedback-cards-title" fxLayout="row" fxLayoutAlign="start center">
            <p class="text-label-form primary-color" translate="final.CHOOSE_VALUE">
            </p>
            <p *ngIf="!cardSelected" class="text-label-form primary-color">*</p>
            <svg-icon (click)="openCardTooltip()" src="/assets/img/icons/info.svg"
              class="info-icon medium-size cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>
          <care-cards [isForFinalEvaluation]="true" [items]="careCards" (onCheck)="onSelectCareCard($event)">
          </care-cards>
        </ng-container>

        <div class="button-container">
          <europ-button
            [disabled]="isLoadingUserGoals || (!finalObservation || !finalObservation.length) || (!isOperationsSpecificGoal && (!cardSelected || !cardSelected.id))"
            (onButtonClicked)="setValueAction()" type="primary default" size="large"
            [label]="'final.VALUE' | translate">
          </europ-button>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingUserGoals">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per card -->
<modal id="cardTooltip" (onConfirm)="closeCardTooltip()" [confirmLabel]="'OK'" (onClose)="closeCardTooltip()">
  <div class="self-modal-content" *ngIf="cardTooltip && cardTooltip.title">
    <div class="title-code-wrapper full-width">
      <div class="title-container">{{ cardTooltip.title }}</div>
      <p class="modal-code-wrapper">{{ 'CODICE MODALE: ' + cardTooltip.modalId}}
      </p>
    </div>
    <div class="text-wrapper">
      <div class="subtitle">{{ cardTooltip.subtitle }}</div>
      <div class="option-container exceed">
        <div class="option-content">
          <p class="option-title" translate="final.guide.EXCEEDED_TITLE"></p>
          <p class="option-text" [innerHTML]="'final.guide.EXCEEDED' | translate"></p>
        </div>
        <svg-icon [applyClass]="true" src="assets/img/evaluation/modal-exceeded.svg"></svg-icon>
      </div>
      <div class="option-container achieved">
        <div class="option-content">
          <p class="option-title" translate="final.guide.ACHIEVED_TITLE"></p>
          <p class="option-text" [innerHTML]="'final.guide.ACHIEVED' | translate"></p>
        </div>
        <svg-icon [applyClass]="true" src="assets/img/evaluation/modal-achieved.svg"></svg-icon>
      </div>
      <div class="option-container not-achieved">
        <div class="option-content">
          <p class="option-title" translate="final.guide.NOT_ACHIEVED_TITLE"></p>
          <p class="option-text" [innerHTML]="'final.guide.NOT_ACHIEVED' | translate"></p>
        </div>
        <svg-icon [applyClass]="true" src="assets/img/evaluation/modal-not-achieved.svg"></svg-icon>
      </div>
    </div>
  </div>
</modal>

<!-- Modale generica uscita dalla pagina -->
<modal id="modalExitLoseData" (onClose)="closeExitModal()" (onCancel)="closeExitModal()"
  (onConfirm)="closeExitModal(true)" [cancelLabel]="'generic.STAY_IN_PAGE' | translate"
  [confirmLabel]="'generic.GO_BACK' | translate">
  <ng-container *ngIf="exitLoseDataModal">
    <modal-text-content [modalId]="exitLoseDataModal.modalId" [title]="exitLoseDataModal.title"
      [text]="exitLoseDataModal.text">
    </modal-text-content>
  </ng-container>
</modal>

<!-- Modale creazione osservazione -->
<modal id="modalSetObservation" (onClose)="closeSetObservation()" (onCancel)="closeSetObservation()"
  (onConfirm)="closeSetObservation(true)" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <ng-container *ngIf="confirmSetEvaluation">
    <modal-text-content [modalId]="confirmSetEvaluation.modalId" [title]="confirmSetEvaluation.title"
      [text]="confirmSetEvaluation.text">
    </modal-text-content>
  </ng-container>
</modal>