import { createAction, props } from '@ngrx/store';
import { Talent } from '../talentScoutingUsers/talentScouting.component';


export const GetMatrixData = createAction(
  '[admin] get stored matrix data with manager or users list'
);

export const SetMatrixDataManager = createAction(
  '[admin] set manager for matrix data',
  props<{ payload: any }>()
);

export const SetMatrixDataUserList = createAction(
  '[admin] set user list for matrix data',
  props<{ payload: any[] }>()
);


export const SetFiltersForMatrix = createAction(
  '[admin] set filter text for matrix',
  props<{ payload: string }>()
)

export const SetCandidate = createAction(
  '[talent-scouting] Set the selected candidate',
  props<{candidate: Talent}>()
)

export const SetProcessYear = createAction(
  '[talent-scouting] Set the selected process year',
  props<{processYear: number}>()
);
