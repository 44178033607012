import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import { Talent } from '../../talentScoutingUsers/talentScouting.component';
import * as fromApp from '../../../ngrx/app.reducers';
import { GetUserTalentPotentialQuestionsResponse } from 'atfcore-commonclasses';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-observation-grid',
  templateUrl: './evaluation-tab.component.html',
  styleUrls: ['./evaluation-tab.component.scss']
})
export class EvaluationTabComponent implements OnInit {

  private modalId = 'failed-to-fetch-talent-evaluation';

  translationPrefix = 'talent.obs-grid.';

  talentEvaluation$!: Observable<GetUserTalentPotentialQuestionsResponse>;

  selectedPerformanceYear$: Observable<number | null> = this.store.select(fromApp.getProcessYear);

  selectedCandidate$: Observable<Talent | null> = this.store.select(fromApp.getSelectedCandidate);

  constructor(
    private potentialTalentSvc: PotentialTalentsService,
    private store: Store<fromApp.AppState>,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.talentEvaluation$ =
      combineLatest([
        this.selectedPerformanceYear$,
        this.selectedCandidate$
      ]).pipe(
        filter(([performanceYear, candidate]) => performanceYear !== null && candidate !== null),
        switchMap(([perfCareYear, candidate]) => {
          if (perfCareYear && candidate) {
            const { userId } = candidate;
            return this.potentialTalentSvc.getTalentEvaluation({ perfCareYear, userId });
          } else {
            return EMPTY;
          }
        }),
        switchMap(({ response, error }) => {
          if (error) {
            return throwError(error);
          }
          return of(response);
        }),
        catchError(err => {
          console.error('[evaluation-tab]', err);
          this.modalService.open(this.modalId);
          return EMPTY
        })
      )

  }


  getQuestionAnswers(question: string[], answersIdx: number[]): string[] {
    return answersIdx.map(idx => question[idx]);
  }

  isObjectNotEmpty(obj: any): boolean {
    return Object.keys(obj).length !== 0;
  }

  closeModal() {
    this.modalService.close(this.modalId);
    this.router.navigate(['../../'], { relativeTo: this.route });
  }


}
