import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'talent-evaluator-avatar',
  template: `
    <div class="avatar">
      <avatar-img [user]="user" size="big"></avatar-img>
      <div>
        <p>{{ translationPrefix + 'EVALUATED_BY' | translate }} <span class="primary-text-color">{{ evaluatedBy }}</span></p>
        <p><em><strong>{{ translationPrefix + 'ON_DATE' | translate }}:</strong></em> {{ onDate }}</p>
        <p><em><strong>{{ translationPrefix + 'OUTCOME' | translate }}:</strong></em> {{ withLevel }}</p>
      </div>
    </div>
  `,
  styleUrls: ['./talent-evaluator-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TalentEvaluatorAvatarComponent {

  translationPrefix = 'talent.obs-grid.evaluator.';

  @Input() evaluatedBy: string = '';

  @Input() onDate: string | null = null;

  @Input() withLevel: string = '';
  @Input() user?: any;

  // TODO: replace the placeholder image
  @Input() userAvatar: string | undefined = 'assets/img/talent/avatar-placeholder.svg';

  constructor() { }

}
