import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../shared/services/admin-guard.service';
import { AdminComponent } from './admin.component';
import { CreateUpdateProcessComponent } from './createUpdateProcess/createUpdateProcess.component';
import { DeclineObjectivesComponent } from './declineObjectives/declineObjectives.component';
import { CalibrationComponent } from './calibration/calibration.component';
import { AdminHomeComponent } from './home/home.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { PeopleAnagComponent } from './peopleAnag/peopleAnag.component';
import { ProcessSetupComponent } from './processSetup/processSetup.component';
import { ProcessSetupDetailsComponent } from './processSetupDetails/processSetupDetails.component';
import { StructureAnagComponent } from './structure-anag/structure-anag.component';
import { CalibrationUserListComponent } from './calibration-user-list/calibration-user-list.component';
import { AdminDefinePerformanceLevelManagerComponent } from './definePerformanceLevel/definePerformanceLevel.component';
import { AdminConfirmPerformanceLevelManagerComponent } from './confirmPerformanceLevel/confirmPerformanceLevel.component';
import { CalibrationMatrixComponent } from './calibration-matrix/calibration-matrix.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminTalentScoutingComponent } from './talentScoutingUsers/talentScouting.component';
import { AdminTalentProcessComponent } from './talentScoutingProcess/talentScoutingProcess.component';
import { PersonPotentialDetailsComponent } from './talent/person-potential-details/person-potential-details.component';
import { EvaluationTabComponent } from './talent/evaluation-tab/evaluation-tab.component';
import { InterviewTabComponent } from './talent/interview-tab/interview-tab.component';
import { DevelopmentPlanTabComponent } from './talent/development-plan-tab/development-plan-tab.component';
import { HydrateCandidateGuard } from '../shared/services/hydrate-candidate-guard.service';
import { InterviewComponent } from './talent/interview/interview.component';
import { CreateDevelopmentActionComponent } from './talent/create-development-action/create-development-action.component';
import { EditDevelopmentActionComponent } from './talent/edit-development-action/edit-development-action.component';
import { EditDataListComponent } from './editDataList/editDataList.component';
import { EditDataDetailsComponent } from './editDataDetails/editDataDetails.component';
import { CascadingComponent } from './cascading/cascading.component';
import { CascadingCreateUpdateComponent } from './cascadingCreateUpdate/cascadingCreateUpdate.component';
import { EditPhaseDateComponent } from './processSetupPhases/editPhaseDate/editPhaseDate.component';

const adminRoutes: Routes = [
  {
    path: '', component: AdminComponent, children: [
      { path: 'home', component: AdminHomeComponent, canActivate: [AdminGuard] },
      { path: 'monitoring', component: MonitoringComponent, canActivate: [AdminGuard] },
      { path: 'monitoring/:processYear', component: MonitoringComponent, canActivate: [AdminGuard] },
      { path: 'processSetup', component: ProcessSetupComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/participantList/:processYear', component: ProcessSetupDetailsComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/objectiveDefinition/:processYear', component: ProcessSetupDetailsComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/phaseDefinition/:processYear', component: ProcessSetupDetailsComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/phaseDefinition/:processYear/editDate/:phaseKey', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/phaseDefinition/:processYear/editDate/:phaseKey/manager', component: EditPhaseDateComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateProcess', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },
      { path: 'createUpdateProcess/:processYear', component: CreateUpdateProcessComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/defineObjectives', component: DeclineObjectivesComponent, canActivate: [AdminGuard] },
      { path: 'processSetup/defineObjectives/:objectiveId', component: DeclineObjectivesComponent, canActivate: [AdminGuard] },
      { path: 'peopleAnagraphics', component: PeopleAnagComponent, canActivate: [AdminGuard] },
      { path: 'peopleAnagraphics/:userId', component: PeopleAnagComponent, canActivate: [AdminGuard] },
      { path: 'structureAnag', component: StructureAnagComponent, canActivate: [AdminGuard] },
      { path: 'structureAnag/:processYear', component: StructureAnagComponent, canActivate: [AdminGuard] },
      { path: 'impersonate', component: ImpersonateComponent, canActivate: [AdminGuard] },
      { path: 'calibration', component: CalibrationComponent, canActivate: [AdminGuard] },
      { path: 'calibrationUserList/:processYear', component: CalibrationUserListComponent, canActivate: [AdminGuard] },
      { path: 'definePerformanceLevel/:processYear/:userId', component: AdminDefinePerformanceLevelManagerComponent, canActivate: [AdminGuard] },
      { path: 'confirmPerformanceLevel/:userId', component: AdminConfirmPerformanceLevelManagerComponent, canActivate: [AdminGuard] },
      { path: 'calibrationMatrix/:processYear', component: CalibrationMatrixComponent, canActivate: [AdminGuard] },
      { path: 'dashboard', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      { path: 'dashboard/:processYear', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      { path: 'editData', component: EditDataListComponent },
      { path: 'editData/:userId', component: EditDataDetailsComponent },
      {
        path: 'talent', canActivate: [AdminGuard], children: [
          { path: '', component: AdminTalentProcessComponent },
          { path: ':processYear', component: AdminTalentScoutingComponent },
          {
            path: ':processYear/:userId',
            canActivate: [HydrateCandidateGuard],
            component: PersonPotentialDetailsComponent,
            children: [
              { path: 'evaluation', component: EvaluationTabComponent },
              { path: 'interview', component: InterviewTabComponent },
              { path: 'development-plan', component: DevelopmentPlanTabComponent },
              { path: '', redirectTo: 'evaluation', pathMatch: 'full' },
            ]
          },
          { path: ':processYear/:userId/interview/start', component: InterviewComponent },
          { path: ':processYear/:userId/development-plan/create-action', component: CreateDevelopmentActionComponent },
          { path: ':processYear/:userId/development-plan/edit-action/:actionId', component: EditDevelopmentActionComponent },
        ]
      },
      { path: 'cascading', component: CascadingComponent, canActivate: [AdminGuard] },
      { path: 'cascading/:processYear', component: CascadingComponent, canActivate: [AdminGuard] },
      { path: 'cascadingCreateUpdate/:processYear/:userId', component: CascadingCreateUpdateComponent, canActivate: [AdminGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
