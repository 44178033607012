<page-container class="impersonate-page-container-height">
  <div class="sidenav-information full-v-height">
    <ng-container *ngIf="selectedCandidate$ | async as selectedCandidate">
      <sidenav-informations
        (onBackFunction)="redirectService.goBackBrowser()"
        [isBackButton]="true"
        [title]="
          ('developmentPlan.createUpdateDevelopAction.SIDENAV_TITLE_FOR'
            | translate) +
          (selectedCandidate.forename + ' ' + selectedCandidate.surname)
        "
        [description]="
          'developmentPlan.createUpdateDevelopAction.SIDENAV_DESCRIPTION'
            | translate
        "
      >
      </sidenav-informations>
    </ng-container>
  </div>

  <div class="main-content with-fixed-sidenav full-v-height">
    <div class="content">
      <div class="content-wrapper">
        <input-container
          [isWithoutInfoIcon]="true"
          [title]="(translationPrefix + 'ACTIVITY_NAME' | translate) + ' *'"
        >
          <europ-input
            (onModelChanged)="activity.developmentAction = $event"
            [bindValue]="activity.developmentAction"
            [placeholder]="
              translationPrefix + 'ACTIVITY_NAME_PLACEHOLDER' | translate
            "
          ></europ-input>
        </input-container>

        <input-container
          [isWithoutInfoIcon]="true"
          [title]="translationPrefix + 'ACTIVITY_PERIOD' | translate"
        >
          <europ-input
            (onModelChanged)="activity.date = $event"
            [bindValue]="activity.date"
            [placeholder]="
              translationPrefix + 'ACTIVITY_PERIOD_PLACEHOLDER' | translate
            "
          ></europ-input>
        </input-container>

        <input-container
          [isWithoutInfoIcon]="true"
          [title]="translationPrefix + 'ACTIVITY_DURATION' | translate"
        >
          <europ-input
            (onModelChanged)="activity.duration = $event"
            [bindValue]="activity.duration"
            [placeholder]="
              translationPrefix + 'ACTIVITY_DURATION_PLACEHOLDER' | translate
            "
          ></europ-input>
        </input-container>

        <input-container
          [isWithoutInfoIcon]="true"
          [title]="translationPrefix + 'ACTIVITY_TYPE' | translate"
        >
          <europ-input
            (onModelChanged)="activity.type = $event"
            [bindValue]="activity.type"
            [placeholder]="
              translationPrefix + 'ACTIVITY_TYPE_PLACEHOLDER' | translate
            "
          ></europ-input>
        </input-container>

        <input-container
          [isWithoutInfoIcon]="true"
          [title]="translationPrefix + 'ACTIVITY_REFERENT' | translate"
        >
          <europ-input
            (onModelChanged)="activity.referentName = $event"
            [bindValue]="activity.referentName"
            [placeholder]="
              translationPrefix + 'ACTIVITY_REFERENT_PLACEHOLDER' | translate
            "
          ></europ-input>
        </input-container>

        <input-container
          [isWithoutInfoIcon]="true"
          [title]="translationPrefix + 'ACTIVITY_DESCRIPTION' | translate"
        >
          <europ-text-area
            (onModelChanged)="activity.description = $event"
            [bindValue]="activity.description"
            [placeholder]="
              translationPrefix + 'ACTIVITY_DESCRIPTION_PLACEHOLDER' | translate
            "
          ></europ-text-area>
        </input-container>

        <div fxFlex fxLayoutAlign="center center" class="full-width">
          <europ-button
            [disabled]="activity.developmentAction.trim().length === 0"
            [label]="translationPrefix + 'ADD_ACTIVITY' | translate"
            (onButtonClicked)="createActivity()"
            type="primary"
          ></europ-button>
        </div>

      </div>
    </div>
  </div>
</page-container>


<!-- Display http-error -->
<modal [id]="failedToCreateActivityModalId" (onClose)="closeFailedToCreateActivityModal()">
  <modal-text-content
    modalId="create-devplan001"
    [title]="'errors.WARNING' | translate"
    [text]="'errors.FAILED_TO_CREATE_ACTIVITY' | translate"
  >
  </modal-text-content>
</modal>

<!-- Failed to get userId and perfCareYear from route -->
<modal [id]="failedToGetUserIdAndPerCareYearFromRouteModalId" (onClose)="closeFailedToGetUserIdAndPerCareYearFromRouteModal()">
  <modal-text-content
    modalId="create-devplan002"
    [title]="'errors.WARNING' | translate"
    [text]="'errors.SOMETHING_WENT_WRONG' | translate"
  >
  </modal-text-content>
</modal>

