
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  constructor() {
  }
  public transform(user: any): string {
    if (user && user.forename && user.surname) {
      return `${user.forename} ${user.surname}`;
    }
    return 'err';
  }
}
