import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareMidTermReviewGoalAdvancementStatus, GetMyManagerUserDataResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, ParseUtils } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GoalUtils } from 'src/app/utils/goal.utils';
import * as moment from 'moment';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-admin-definePerformanceLevel',
  templateUrl: './definePerformanceLevel.component.html',
  styleUrls: ['./definePerformanceLevel.component.scss']
})
export class AdminDefinePerformanceLevelManagerComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserData$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;
  objectiveFeedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveOsservationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isLoadingUserData: boolean = false;
  goalList: any[] = [];

  careCards: any[] = [];
  cardSelected: any;

  osservation: string = '';

  isFromBack: boolean = false;
  sendRevision$: Subscription = new Subscription;
  statusForRecap: string = '';
  userRevisionDate: string = '';
  isLoadingManagerInfo: boolean = false;
  managerData: any;
  managerAnswer: boolean = false;
  managerRevisionDate: string = '';
  managerOsservation: string = '';
  isOperations: boolean = false;
  backWithData: boolean = false;

  charsFinalObservation: number = 600;
  finalObservation: string = '';
  observationTooltip: ApplicationModalMessage = {
    modalId: 'pl002',
    title: '',
    text: ''
  }
  selectedMark: any;
  userId: string = '';
  payoutTooltip: ApplicationModalMessage = {
    modalId: 'pl004',
    title: '',
    text: ''
  }
  selectedPayout: any;
  payoutOptions: any[] = [];
  isLoadingPayoutOptions: boolean = false;
  isLoadingUserFinalEvaluation: boolean = false;
  getFinalEvaluation$: Subscription = new Subscription;
  result$: Subscription = new Subscription;
  runningPhase: any;
  personDetails: any;
  getPersonDetails$: Subscription = new Subscription;
  payoutResponse: any;
  isLoadingPersonDetails: boolean = false;
  userFinalEvaluation: string = '';
  userFinalEvaluationObservation: any;
  userFinalEvaluationDate: any;
  isSti: boolean = false;
  userCalibrationRecap: { midTerm: any; finalEvaluation: any; } = {
    midTerm: {},
    finalEvaluation: {}
  };
  managerCalibrationRecap: { midTerm: any; finalEvaluation: any; } = {
    midTerm: {},
    finalEvaluation: {}
  };
  calibrationData: { observation: any; level: any; payout: any; calibrationId: string } = {
    calibrationId: '',
    observation: '',
    level: null,
    payout: null
  };
  processYear: any;

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.processYear = params.processYear;
              this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                this.loggedUser = loggedUser.user;
                this.getPayoutOptions();
              })
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT',
        'midTerm.addMidTermReview.IN_RETARD',
        'midTerm.addMidTermReview.IN_LINE',
        'midTerm.addMidTermReview.GREAT_POINT',
        'performance.PAYOUT_PLACEHOLDER',
        'performance.INSERT_OBSERVATION',
        'performance.OBSERVATION_TOOLTIP',
        'performance.SET_PAYOUT',
        'performance.SET_PAYOUT_TOOLTIP',
      ])
      .subscribe(translations => {
        this.objectiveFeedbackTooltipModal = {
          modalId: "mt002",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT'],
        };
        this.objectiveOsservationTooltipModal = {
          modalId: "mt003",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT'],
        }
        this.observationTooltip = {
          modalId: "pl002",
          title: translations['performance.INSERT_OBSERVATION'],
          text: translations['performance.OBSERVATION_TOOLTIP'],
        }
        this.payoutTooltip = {
          modalId: "pl004",
          title: translations['performance.SET_PAYOUT'],
          text: translations['performance.SET_PAYOUT_TOOLTIP'],
        }

        this.careCards = [{
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.LATE,
          title: translations['midTerm.addMidTermReview.IN_RETARD'],
          isChecked: false,
          icon: "assets/img/icons/red-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.ON_LINE,
          title: translations['midTerm.addMidTermReview.IN_LINE'],
          isChecked: false,
          icon: "assets/img/icons/yellow-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.WELL_ADVANCED,
          title: translations['midTerm.addMidTermReview.GREAT_POINT'],
          isChecked: false,
          icon: "assets/img/icons/green-face.svg"
        }]
        this.selectedPayout = {
          id: null,
          title: translations['performance.PAYOUT_PLACEHOLDER']
        }
      })
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.finalObservation && this.finalObservation.trim().length && this.calibrationData.level;
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return this.calibrationData.level || (this.finalObservation && this.finalObservation.trim().length);
  }

  sendRevision() {
    // this.goBackBrowser();
    this.isLoadingUserData = true;
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    /*
        this.sendRevision$ = this.adminService.setMidTermReviewRevision(this.runningYear, this.osservation, this.cardSelected.id)
          .subscribe((data: any) => {
            if (data.error || !data.response) {
              const messageObj: ApplicationModalMessage = {
                modalId: "mt004",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const messageObj = {
                modalId: "mt005",
                title: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS"),
                text: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS_DESCRIPTION")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    
              if (data.obtainedBadges && data.obtainedBadges.length) {
                let badges: any[] = [];
                if (data.obtainedBadges.length > 1) {
                  badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
                } else {
                  badges = data.obtainedBadges;
                }
    
                let tmpBadgesList: BadgeObject[] = [];
                if (badges && badges.length) {
                  tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
                  this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
                }
              }
            }
            this.goBackBrowser();
            this.isLoadingUserGoals = false;
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "mt006",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUserGoals = false;
          });
          */
  }

  sendRevisionAction(isBack?: boolean) {
    if (isBack && !this.isObbligatoryDataInserted()) {
      if (this.checkIfDataIsChanged()) {
        this.backWithData = true;
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      } else {
        this.goBackBrowser();
      }
    } else if (this.isObbligatoryDataInserted()) {
      if (isBack) {
        this.isFromBack = true;
      } else {
        this.isFromBack = false;
      }
      this.modalService.open("modalCreateRevisionForCollaborator");
    } else {
      if (isBack) {
        if (this.checkIfDataIsChanged()) {
          this.modalService.open("modalCreateRevisionNoDataForCollaborator");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      }
    }
  }

  closeModalCreateRevisionNoDataForCollaborator() {
    this.modalService.close("modalCreateRevisionNoDataForCollaborator");
    this.backWithData = false
  }

  closeModalCreateRevisionForCollaborator() {
    this.modalService.close("modalCreateRevisionForCollaborator");
  }

  osservationChanged(text: string) {
    this.osservation = text;
  }

  closeObjectiveFeedbackTooltip() {
    this.modalService.close("objectiveFeedbackModalTooltip");
  }

  openObjectiveFeedbackTooltip() {
    this.modalService.open("objectiveFeedbackModalTooltip");
  }

  onSelectCareCard(cards: any) {
    this.cardSelected = cards.filter((x: any) => {
      return x.isChecked == true;
    })[0];
  }

  getUserData() {
    this.isLoadingUserData = true;
    if (this.getUserData$) {
      this.getUserData$.unsubscribe();
    }
    this.getUserData$ = this.adminService.getUserCalibrationData(this.processYear, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.goalList = [];
          this.userCalibrationRecap = {
            midTerm: {},
            finalEvaluation: {}
          }
          this.managerCalibrationRecap = {
            midTerm: {},
            finalEvaluation: {}
          }
          // utente
          if (data.response.user) {
            this.personDetails = data.response.user;
            this.isSti = !isNaN(ParseUtils.parseInt(this.personDetails.stiAmount));
          }
          // manager
          if (data.response.managerUser) {
            this.managerData = data.response.managerUser;
          }

          if (data.response && data.response.goals && data.response.goals.length) {
            this.goalList = GoalUtils.formatGoalCardList(this.translate, data.response.goals, false, this.loggedUser.userId);
          }
          // Dati midterm utente
          if (data.response.midTermReviewUserGoalAdvancement && data.response.midTermReviewUserGoalAdvancement.length) {
            this.userCalibrationRecap.midTerm = {
              status: data.response.midTermReviewUserGoalAdvancement,
              observations: data.response.midTermReviewUserObservation,
              date: moment(data.response.midTermReviewUserObservationDate).format('DD.MM.YYYY')
            }
          }
          // Dati midterm manager
          if (data.response.midTermReviewManagerObservation || data.response.midTermReviewManagerObservationDate) {
            this.managerAnswer = true;
            this.managerCalibrationRecap.midTerm = {
              observations: data.response.midTermReviewManagerObservation,
              date: moment(data.response.midTermReviewManagerObservationDate).format('DD.MM.YYYY')
            }
          }
          // dati final evaluation
          if (data.response.finalEvaluation) {
            let feData = data.response.finalEvaluation;
            // utente
            if (feData.subordinateObservationLevel) {
              this.userCalibrationRecap.finalEvaluation = {
                level: feData.subordinateObservationLevel,
                observations: feData.subordinateObservation
              }
            }
            // manager
            if (feData.managerObservationLevel) {
              this.managerAnswer = true;
              this.managerCalibrationRecap.finalEvaluation = {
                level: feData.managerObservationLevel,
                observations: feData.managerObservation
              }
            }
          }
          // dati calibration
          if (data.response.calibration) {
            let level = data.response.calibration.adminObservationLevel ? data.response.calibration.adminObservationLevel : data.response.finalEvaluation.managerObservationLevel;
            this.calibrationData = {
              calibrationId: data.response.calibration.calibrationId,
              observation: data.response.calibration.adminObservation,
              level: level,
              payout: null
            }
            if (data.response.calibration.payout >= 0) {
              this.formatPayout(data.response.calibration.payout, level)
            } else {
              this.formatPayout(data.response.finalEvaluation.payout, data.response.finalEvaluation.managerObservationLevel)
            }
          }
        }
        this.isLoadingUserData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserData = false;
      });
  }

  formatPayout(payout: number, level: string) {
    if (this.payoutResponse && this.payoutResponse[level]) {
      this.calibrationData.payout = this.payoutResponse[level].find((x: any) => x.id == payout);
      this.payoutOptions = this.payoutResponse[level];
      if (!this.calibrationData.payout) {
        this.calibrationData.payout = this.payoutResponse[level][0];
        this.payoutOptions = this.payoutResponse[level];
      }
    }
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  calibrationObservationChanged(newText: string) {
    this.calibrationData.observation = newText;
  }

  selectPerformanceLevel(mark: any) {
    this.selectedPayout = {
      id: null,
      title: this.translate.instant('performance.PAYOUT_PLACEHOLDER')
    };
    this.calibrationData.level = mark;
    this.calibrationData.payout = null;
    this.payoutOptions = this.payoutResponse[mark];
    if (this.payoutOptions.length == 1) {
      this.calibrationData.payout = this.payoutOptions[0];
    } else {
      this.formatPayout(this.calibrationData.payout, this.calibrationData.level)
    }
  }

  getPayoutOptions() {
    this.isLoadingPayoutOptions = true;
    this.adminService.getPayoutRange()
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pl010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.payoutResponse = {};
          for (let i = 0; i < data.response.length; i++) {
            if (data.response[i].moreOrEqualPayout) {
              let tmpEvaluation = [];
              for (let j = data.response[i].moreOrEqualPayout; j < data.response[i].lessPayout; j++) {
                tmpEvaluation.push({
                  id: j,
                  title: j + '%'
                })
              }
              this.payoutResponse[data.response[i].finalEvaluation] = tmpEvaluation;
            } else {
              this.payoutResponse[data.response[i].finalEvaluation] = [{
                id: data.response[i].payout,
                title: data.response[i].payout + '%'
              }]
            }
          }
        }
        this.getUserData();
        this.isLoadingPayoutOptions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pl011",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPayoutOptions = false;
      });
  }

  changePayoutPercentage(newValue: any) {
    this.calibrationData.payout = newValue;
  }

  selectFinalEvaluation(calibrationValue: any) {
    this.calibrationData.level = calibrationValue;
  }

  openConfirmModal() {
    this.modalService.open('recapConfirm')
  };

  closeConfirmModal(save?: boolean, confirm?: boolean) {
    this.modalService.close('recapConfirm')
    if (save) {
      let calibration = {
        calibrationId: this.calibrationData.calibrationId,
        userId: this.userId,
        adminObservation: this.calibrationData.observation,
        adminObservationLevel: this.calibrationData.level,
        payout: this.isSti && this.calibrationData.payout && this.calibrationData.payout.title ? this.calibrationData.payout.id : null,
        confirmed: confirm
      }
      this.adminService.setCalibration(this.processYear, [calibration])
        .subscribe((setEvaluation: SenecaResponse<any>) => {
          if (setEvaluation && setEvaluation.error || !setEvaluation.response) {
            let error = setEvaluation.error ? this.translate.instant("errors." + setEvaluation.error) : this.translate.instant('errors.SOMETHING_WENT_WRONG');
            const messageObj: ApplicationModalMessage = {
              modalId: "cal008",
              text: error,
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.goBackBrowser();
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal009",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  getUserGoalEvaluation(goal: any) {
    let tmpData = goal.goalEvaluation?.find((x: any) => x.user?.userId == this.userId);
    return tmpData && tmpData.evaluationScore;
  }

  confirmDisabled() {
    if (this.isSti) {
      return !this.calibrationData || !this.calibrationData.payout || !this.calibrationData.level;
    } else {
      return !this.calibrationData.level;
    }
  }

  openMarkCardInfoModal() {
    const messageObj: ApplicationModalMessage = {
      modalId: "pl003",
      title: this.translate.instant('performance.INSERT_VALUE'),
      text: this.translate.instant("performance.VALUE_TOOLTIP"),
    }
    this.modalService.open('modal-info');
  }

  closeModalInfo() {
    this.modalService.close('modal-info');
  }

  ngOnDestroy() {
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserData$) {
      this.getUserData$.unsubscribe();
    }
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    if (this.getFinalEvaluation$) {
      this.getFinalEvaluation$.unsubscribe();
    }
  }
}