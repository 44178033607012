import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceCareTalent, User } from 'atfcore-commonclasses';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';

export type Activity = Omit<
  PerformanceCareTalent.CreateTalentIndividualDevelopmentPlan['idp'],
  'userId'
>;

@Component({
  selector: 'app-create-development-plan',
  templateUrl: './create-development-action.component.html',
  styleUrls: ['./create-development-action.component.scss'],
})
export class CreateDevelopmentActionComponent implements OnInit {
  readonly translationPrefix = 'talent.development-plan-tab.';

  readonly failedToCreateActivityModalId = 'failed-to-create-activity-modal';

  readonly failedToGetUserIdAndPerCareYearFromRouteModalId = 'failed-to-get-user-id-and-per-care-year-from-route-modal';

  selectedCandidate$!: Observable<User>;

  activity: Activity = {
    developmentAction: '',
    date: '',
    duration: '',
    type: '',
    referentName: '',
    description: '',
  };

  private userIdAndPerfYear$: Observable<[string, number]> =
    this.route.paramMap.pipe(
      switchMap((params) => {
        const userId = params.get('userId');
        const processYear = params.get('processYear');
        if (!userId || !processYear) {
          return throwError('Missing userId or processYear');
        }
        const result: [string, number] = [userId, parseInt(processYear, 10)];
        return of(result);
      })
    );

  constructor(
    public redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private modalSvc: ModalService,
    private potentialTalentSvc: PotentialTalentsService
  ) {}

  ngOnInit(): void {
    this.selectedCandidate$ = this.userIdAndPerfYear$.pipe(
      switchMap(([userId, perfCareYear]) =>
        this.potentialTalentSvc.getTalentDetails(userId, perfCareYear)
      ),
      switchMap(({ response, error }) => {
        if (error) {
          return throwError(error);
        }
        return of(response.user);
      }),
      catchError((err) => {
        console.error('[create-development plan]', err);
        this.openFailedToGetUserIdAndPerCareYearFromRouteModal();
        return EMPTY;
      })
    );
  }

  createActivity() {
    this.userIdAndPerfYear$.pipe(
      switchMap(([userId, perfCareYear]) => {
        const payload: PerformanceCareTalent.CreateTalentIndividualDevelopmentPlan = {
          perfCareYear,
          idp: {
            ...this.activity,
            userId,
          }
        }
        return this.potentialTalentSvc.createIndiviadualDevelopmentPlan(payload);
      }),
      switchMap(({error}) => {
        if(error) return throwError(error);
        return this.router.navigate(['../'], { relativeTo: this.route });
      }),
      catchError(err => {
        console.error('[create-development plan]', err);
        this.openFailedToCreateActivityModal();
        return EMPTY;
      }),
      finalize(() => this.resetActivity())
    ).subscribe()
  }


  resetActivity() {
    this.activity = {
      developmentAction: '',
      date: '',
      duration: '',
      type: '',
      referentName: '',
      description: '',
    };
  }

  closeFailedToCreateActivityModal() {
    this.modalSvc.close(this.failedToCreateActivityModalId);
  }

  openFailedToCreateActivityModal() {
    this.modalSvc.open(this.failedToCreateActivityModalId);
  }

  closeFailedToGetUserIdAndPerCareYearFromRouteModal() {
    this.modalSvc.close(this.failedToGetUserIdAndPerCareYearFromRouteModalId);
  }

  openFailedToGetUserIdAndPerCareYearFromRouteModal() {
    this.modalSvc.open(this.failedToGetUserIdAndPerCareYearFromRouteModalId);
  }

}
