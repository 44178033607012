<div class="card-container">
  <div class="card-wrapper" *ngFor="let item of items" (click)="setValue(item)"
    [ngClass]="{'selected': bindValue == item.id}">
    <div class="icon-container"
      [ngClass]="{'not-eligible': item.id == 'NOT_ELIGIBLE', 'to-decide': item.id == 'TO_DECIDE', 'eligible': item.id == 'ELIGIBLE'}">
      <svg-icon [src]="item.icon" class="card-icon" [applyClass]="true"></svg-icon>
    </div>
    <p [translate]="item.title"></p>
    <div class="europ-check-wrapper" [ngClass]="{'checked': bindValue == item.id}">
      <svg-icon *ngIf="bindValue == item.id" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true">
      </svg-icon>
    </div>
  </div>
</div>