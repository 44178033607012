<div class="page-content-wrapper admin-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('editDataList.PAGE_TITLE' | translate)"
    [adminPageSubtitle]="('editDataList.PAGE_SUBTITLE' | translate) + ' ' + runningYear" [adminBack]="true"
    [hasTabs]="false">
  </header-dossier>
  <div class="main-content-wrapper admin">
    <!-- contenitore tabella -->

    <div class="admin-table-container">
      <div class="structure-recap">
        <p class="structure-title" translate="editDataList.LIST_TITLE"></p>

        <div class="search-container">
          <div class="search-wrapper">
            <europ-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
              (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
              [placeholder]="'structureAnag.details.SEARCH_BY' | translate" [isSearchBar]="true">
            </europ-input>
            <!-- Commentato in attesa che ci dicano che filtri possono essere utili -->
            <!--p class="filter-recap" translate="structureAnag.details.FILTER_BY"></p-->

          </div>
        </div>
        <!-- Recap dei filtri attivi -->
        <div class="recap-container">
          <div class="racap-text-row">
            <div class="left-column">
              <ng-container *ngIf="!selectedFiltersNumber">
                <p class="placeholder" translate="adminSearch.NO_FILTER_ACTIVE"></p>
              </ng-container>
              <ng-container *ngIf="selectedFiltersNumber == 1">
                <p class="placeholder" [innerHTML]="'adminSearch.ONE_FILTER' | translate"></p>
              </ng-container>
              <ng-container *ngIf="selectedFiltersNumber > 1">
                <p class="placeholder"><b>{{selectedFiltersNumber}}</b>&nbsp;{{ 'adminSearch.MORE_FILTERS' |
                  translate
                  }}
                </p>
              </ng-container>
              <ng-container *ngIf="selectedFiltersNumber >= 1">
                <p class="delete-filters" translate="adminSearch.DELETE_FILTERS" (click)="clearFilters()"></p>
              </ng-container>
              <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
            </div>
            <div class="count-container">
              <p translate="dashboard.MANAGE_FILTERS"></p>
              <svg-icon src="assets/img/icons/chevron-down.svg" *ngIf="!isFilterOpened" [applyClass]="true" class="goto"
                (click)="openFiltersSection()"></svg-icon>
              <svg-icon src="assets/img/icons/chevron-up.svg" *ngIf="isFilterOpened" [applyClass]="true" class="goto"
                (click)="openFiltersSection()"></svg-icon>
            </div>
          </div>

          <ng-container *ngIf="isFilterOpened">
            <!-- Filtri per struttura, genere ecc-->
            <div class="filters-container">
              <div class="filters-content">
                <div class="generic-container">
                  <!-- Struttura -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureTooltipModal"
                    [isValued]="selectedFilters.structures" [isObbligatory]="true"
                    title="{{'calibration.filters.STRUCTURE' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'structures')"
                      [selectedItem]="selectedFilters.structures" [items]="filtersObject.structures"
                      [searchable]="false" bindLabel="id">
                    </europ-select>
                  </input-container>
                  <!-- Funzione -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="functionTooltipModal"
                    [isValued]="selectedFilters.funzione" [isObbligatory]="true"
                    title="{{'calibration.filters.FUNCTION' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'funzione')"
                      [selectedItem]="selectedFilters.funzione" [items]="filtersObject.funzione" [searchable]="true"
                      bindLabel="id">
                    </europ-select>
                  </input-container>

                </div>

                <div class="generic-container">

                  <!-- Mansione -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="jobTooltipModal"
                    [isValued]="selectedFilters.mansione" [isObbligatory]="true"
                    title="{{'calibration.filters.JOB' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'mansione')"
                      [selectedItem]="selectedFilters.mansione" [items]="filtersObject.mansione" [searchable]="true"
                      bindLabel="id">
                    </europ-select>
                  </input-container>

                  <!-- Area -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="areaTooltipModal"
                    [isValued]="selectedFilters.area" [isObbligatory]="true"
                    title="{{'calibration.filters.AREA' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'area')"
                      [selectedItem]="selectedFilters.area" [items]="filtersObject.area" [searchable]="true"
                      bindLabel="id">
                    </europ-select>
                  </input-container>

                </div>


                <div class="generic-container">
                  <!-- Struttura 3 livello -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel3TooltipModal"
                    [isValued]="selectedFilters.denominazioneSO3" [isObbligatory]="true"
                    title="{{'calibration.filters.STRUCTURE_LEVEL_3' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'denominazioneSO3')"
                      [selectedItem]="selectedFilters.denominazioneSO3" [items]="filtersObject.denominazioneSO3"
                      [searchable]="true" bindLabel="id">
                    </europ-select>
                  </input-container>

                  <!-- Struttura 5 livello -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel5TooltipModal"
                    [isValued]="selectedFilters.denominazioneSO5" [isObbligatory]="true"
                    title="{{'calibration.filters.STRUCTURE_LEVEL_5' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'denominazioneSO5')"
                      [selectedItem]="selectedFilters.denominazioneSO5" [items]="filtersObject.denominazioneSO5"
                      [searchable]="true" bindLabel="id">
                    </europ-select>
                  </input-container>

                </div>

                <div class="generic-container">
                  <!-- Sesso -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="genderFilterTooltipModal"
                    [isValued]="selectedFilters.chiaveSesso" [isObbligatory]="true"
                    title="{{'calibration.filters.GENDER' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'chiaveSesso')"
                      [selectedItem]="selectedFilters.chiaveSesso" [items]="filtersObject.chiaveSesso"
                      [searchable]="false" bindLabel="id">
                    </europ-select>
                  </input-container>
                </div>

                <div class="button-container">
                  <europ-button [label]="'generic.APPLY_FILTERS' | translate" type="primary"
                    (onButtonClicked)="applyFilters()" [disabled]="isLoadingUsersData">
                  </europ-button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="!isLoadingUsersData">
        <ng-container *ngIf="usersData.counter > 0">
          <div class="table-content responsive">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.FULL_NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                  <th class="small-col" translate="{{ ('adminTable.ROLE' | translate)}}">
                  </th>
                  <th translate="{{ ('adminTable.TASK' | translate)}}"></th>
                  <th translate="{{ ('adminTable.STI' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of usersData.list | 
                              slice:0:usersData.numRecords | 
                              paginate: { id: 'usersList',
                                      itemsPerPage: usersData.numRecords,
                                      currentPage: usersData.page,
                                      totalItems: usersData.counter }">
                  <td>{{ user.surname + ' ' + user.forename }}</td>
                  <td>
                    <div class="count">
                      {{ user.cid }}
                    </div>
                  </td>
                  <!--td class="small-col link" (click)="openUserProcessModal(user)">{{user.numerOfProcess ||0 }}</td-->
                  <td>{{ user.currentRole || '--'}}</td>
                  <td>{{ user.mansione || '--'}}</td>
                  <td>
                    <item-list-team-status [message]="((user.isSti ? 'generic.YES' : 'generic.NO' ) | translate)"
                      [type]="user.isSti ? 'completed' : 'disabled'" styleItem="tag">
                    </item-list-team-status>
                  </td>
                  <td class="link" (click)="onActionClicked(user.userId)">{{
                    ('editDataList.ACTION' | translate)}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="usersList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </europ-select>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!usersData.counter">
          <div class="no-data-wrapper">
            <p translate="adminTable.NO_DATA_USER"></p>
          </div>
        </ng-container>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsersData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>

  </div>
</div>



<modal id="userProcessInfo" (onClose)="closeUserProcessModal()" (onConfirm)="closeUserProcessModal()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <ng-container *ngIf="userProcessModalData">
    <p class="modal-title">
      {{ ('impersonate.modals.PROCESS' | translate) + ' ' + userProcessModalData.forename + ' '+
      userProcessModalData.surname}}</p>
    <div class="assessment-scrollable-wrapper">
      <div class="assessment-container" *ngFor="let data of userProcessModalData.userAssessments">
        <p class="year">{{ data.year }}</p>
        <!-- Titolo -->
        <div class="entry">
          <p class="title" translate="impersonate.modals.PROCESS_NAME"></p>
          <p class="value">{{ data.title }}</p>
        </div>
        <!-- Stato -->
        <div class="entry">
          <p class="title" translate="impersonate.modals.STATUS"></p>
          <p class="value">{{ getIsProcessActive(data)}}</p>
        </div>
        <!-- Fase -->
        <div class="entry divider">
          <p class="title" translate="impersonate.modals.PHASE"></p>
          <p class="value">{{ 'phases.' + data.statusType | translate }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</modal>