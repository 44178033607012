import { OnDestroy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from "../ngrx/app.reducers";
import * as fromManager from "./ngrx/manager.reducers";
import { GetSubordinatesWithPhaseStatusesForManagerResponse, GetPerformanceAssessmentBannerPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as ManagerActions from "./ngrx/manager.actions";
import { Router } from '@angular/router';
import { RedirectService } from '../shared/services/redirect.service';
import moment = require('moment-timezone');

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnDestroy {
  applicationLang$: Subscription;
  runningYear$: Subscription;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription;
  applicationLang: string = '';
  runningYear: number = 0;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  isLoadingTeam: boolean = true;
  activeUserId: string | null = null;
  combinedSelected$: Subscription;
  isClickedTeamButton$: Subscription;
  loggedUser: any;
  onboardingDone: any;
  activeUserId$: Subscription;
  runningPhase$: Subscription;
  runningPhase: GetPerformanceAssessmentBannerPhaseResponse | null = null;
  isLoadingCurrentPhase: boolean;
  userAcknowledges$: Subscription;
  isTeamButtonClicked: boolean = false;

  constructor(
    private appStore: Store<fromApp.AppState>,
    private managerStore: Store<fromManager.ManagerState>,
    private translate: TranslateService,
    public router: Router,
    private redirectService: RedirectService
  ) {
    this.isLoadingTeam = true;
    this.isLoadingCurrentPhase = true;
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.appStore.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
        moment.locale(applicationLang);
      }
    });

    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        // Recupero dai servizi la lista di sottoposti
        this.managerStore.dispatch(ManagerActions.RetrieveSuboardinatesWithPhaseStatuses());
      }
    });

    this.runningPhase$ = this.appStore.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
      this.runningPhase = runningPhase;
      this.isLoadingCurrentPhase = false;
    })

    this.isClickedTeamButton$ = this.appStore.select(fromApp.getIsButtonTeamClicked).subscribe((isButtonClicked) => {
      this.isTeamButtonClicked = isButtonClicked;
    })

    // Recupero la lista di subordinati con lo stato delle fasi
    this.subordinatesWithPhaseStatuses$ = this.appStore.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
      this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
      this.isLoadingTeam = false;
    });

    // Recupero l'id dell'utente attualmente selezionato
    this.activeUserId$ = this.appStore.select(fromApp.getActiveUserId).subscribe((activeUserId: string) => {
      this.activeUserId = activeUserId;
    })

    this.userAcknowledges$ = this.appStore.select(fromApp.getUserAcknowledges).subscribe((userAck) => {
      if (userAck && userAck.data) {
        this.onboardingDone = !!userAck.data["PERFORMANCE_CARE_ONBOARDING"];
      }
    })

    const loggedUser$: Observable<JwtPayload> = this.appStore.select(fromApp.getLoggedUser);
    // const userAck$: Observable<any> = this.appStore.select(fromApp.getUserAcknowledges);
    this.combinedSelected$ = combineLatest([loggedUser$])
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  // Se mostrare o no la sidebar degli utenti a sinistra
  showSidebar() {
    // && !this.isInGoalDetail() 
    return this.onboardingDone && !this.redirectService.isThisCurrentPage('createUpdateGoal') && !this.redirectService.isThisCurrentPage('organizeInterview')
      && !this.redirectService.isThisCurrentPage('notifications') && !this.redirectService.isThisCurrentPage('goalDetails')
      && !this.redirectService.isThisCurrentPage('sendApplause')
      && !this.redirectService.isThisCurrentPage('onboarding')
      && !this.redirectService.isThisCurrentPage('createUpdateDevelopmentAction')
      && !this.redirectService.isThisCurrentPage('profile')
      && !this.redirectService.isThisCurrentPage('myTeam')
      && !this.redirectService.isThisCurrentPage('createUpdateEvidence')
      && !this.redirectService.isThisCurrentPage('createUpdatePrivateNote')
      && !this.redirectService.isThisCurrentPage('createUpdatePeerFeedback')
      && !this.redirectService.isThisCurrentPage('feedbackRequest')
      && !this.redirectService.isThisCurrentPage('midTermList')
      && !this.redirectService.isThisCurrentPage('addMidTermReview')
      && !this.redirectService.isThisCurrentPage('finalEvaluation')
      && !this.redirectService.isThisCurrentPage('definePerformanceLevel')
      && !this.redirectService.isThisCurrentPage('postCalibrationEvaluation')
      && !this.redirectService.isThisCurrentPage('talentScouting')
      && !this.redirectService.isThisCurrentPage('talentScoutingGrid')
      && !this.redirectService.isThisCurrentPage('createOptionalObjectives');
  }

  isInGoalDetail() {
    if (window.location.href.indexOf('personDetails') > -1 && window.location.href.indexOf('feedback') < 0 && window.location.href.indexOf('developmentPlan') < 0) {
      let currentUrl = window.location.href.split('personDetails')[1];
      if (currentUrl.split('/').length > 2) {
        return true;
      }
      return false;
    }
    return false;
  }

  // Porta al dettaglio di una persona dato il suo id
  onUserClicked(user: GetSubordinatesWithPhaseStatusesForManagerResponse) {
    this.isTeamButtonClicked = !this.isTeamButtonClicked;
    this.appStore.dispatch(ManagerActions.SetHeaderTeamClickedButton({ payload: this.isTeamButtonClicked }));
    if (user && user.userId) {
      this.appStore.dispatch(ManagerActions.SetActiveUserId({ payload: user.userId }));
      this.router.navigate(['manager/personDetails/' + user.userId + '/' + this.runningYear]);
    }
  }
  ngOnDestroy(): void {
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.isClickedTeamButton$) {
      this.isClickedTeamButton$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningPhase$) {
      this.runningPhase$.unsubscribe();
    }
    if (this.activeUserId$) {
      this.activeUserId$.unsubscribe();
    }
  }
}
