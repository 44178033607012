<div class="banner-home" [ngClass]="{'narrow': isNarrow, 'final-evaluation': phaseName == 'FINAL_EVALUATION' }">
  <!--Banner maschera immagine-->
  <div class="img-banner-mask" [ngStyle]="{'background-image': 'url(' + backgroundSrc + ')'}"
    [ngClass]="{'mid-term': phaseName == 'MID_TERM_REVIEW'}"></div>
  <!--Banner testo banner-->
  <div class="text-banner-mask blue-bg"
    [ngClass]="{'mid-term': phaseName == 'MID_TERM_REVIEW' || phaseName == 'FINAL_EVALUATION'}">
    <!--Decorazione Banner-->
    <svg fxHide.xs class="decoration-img" preserveAspectRatio="none" width="33px" height="100%" viewBox="0 0 33 400"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 200C0 274.643 12.1388 343.713 32.7478 400V0C12.1388 56.2869 0 125.357 0 200Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 200C0 125.357 12.1388 56.2869 32.7478 0V400C12.1388 343.713 0 274.643 0 200Z" />
    </svg>
    <!-- Contenuto "default" del banner -->
    <div class="text-banner">
      <ng-container *ngIf="phaseName !== 'TUTORIAL'">
        <div class="banner-header">
          <!-- Data e colloqui -->
          <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
            <div class="meetings">
              <p *ngIf="!meetingNumber" class="text-small" translate="banner.NO_MEETINGS"></p>
              <p *ngIf="meetingNumber == 1" class="text-small">{{ meetingNumber + ('banner.MEETING' |
                translate)}}</p>
              <p *ngIf="meetingNumber > 1" class="text-small">{{ meetingNumber + ('banner.MEETINGS' |
                translate)}}</p>
            </div>
          </div>
          <div class="europ-logo-wrapper">
            <svg-icon src="/assets/img/icons/app-logo-white.svg" class="europ-logo"></svg-icon>
          </div>
        </div>
        <!-- Titolo banner -->
        <h2 class="title-page full-width">{{ ('banner.' + phaseName + '.title') | translate }}</h2>
        <!-- Contenuto banner -->
        <div class="banner-content">
          <ng-container *ngIf="phaseName == 'CONTINUOS_FEEDBACK'">
            <h3 class="text-big">
              {{ (('banner.' + phaseName + '.description_1') | translate)
              + (('banner.' + phaseName + '.description_2') | translate) }}
            </h3>
          </ng-container>
          <ng-container *ngIf="phaseName != 'CONTINUOS_FEEDBACK'">
            <h3 class="text-big" *ngIf="endDate">
              {{ (('banner.' + phaseName + '.description_1') | translate)
              + ' ' + (formatDate(endDate)) + ' ' +
              (('banner.' + phaseName + '.description_2') | translate) }}
            </h3>
          </ng-container>
          <!-- Testo specifico valutazione finale utenti -->
          <ng-container *ngIf="phaseName == 'FINAL_EVALUATION'">
            <ng-container *ngIf="isCollaborator">
              <p class="list text-small" [innerHTML]="('banner.FINAL_EVALUATION.list_collab' | translate)">
              </p>
            </ng-container>
            <ng-container *ngIf="!isCollaborator">
              <p class="list text-small" [innerHTML]="('banner.FINAL_EVALUATION.list' | translate)"></p>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="phaseName != 'FINAL_EVALUATION'">
            <p class="list text-small"
              [innerHTML]="('banner.' + phaseName + (phaseName == 'GOAL_SETTING' && isCollaborator ? '_COL' : '') + '.list' | translate)">
            </p>
          </ng-container>
        </div>
        <europ-button *ngIf="hasButton" type="secondary medium" [label]="buttonLabel"
          (onButtonClicked)="emitOnButtonClicked($event)">
        </europ-button>
      </ng-container>
      <!-- Per la fase tutorial che contiene testi formattati diversmante c'è contenitore a parte -->
      <ng-container *ngIf="phaseName === 'TUTORIAL'">
        <div class="banner-header">
          <div class="banner-header-text">
            <h1>{{ today }}</h1>
          </div>
          <div class="europ-logo-wrapper">
            <svg-icon src="/assets/img/icons/app-logo-white.svg" class="europ-logo"></svg-icon>
          </div>
        </div>
        <h2 class="title-page full-width">{{ (('banner.' + phaseName + '.title_1') | translate)
          + ' ' + (userName) + ' ' +
          (('banner.' + phaseName + '.title_2') | translate) }}</h2>
        <div class="banner-content">
          <h3 class="text-big">
            {{ (('banner.' + phaseName + '.description') | translate) }}
          </h3>
        </div>
        <europ-button type="secondary" [label]="'banner.TUTORIAL.button' | translate"
          (onButtonClicked)="emitOnButtonClicked($event)"></europ-button>
      </ng-container>
    </div>
  </div>
</div>