import { Store } from '@ngrx/store';
import { Subscription, combineLatest, Observable, of } from 'rxjs';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from '@angular/core';
import { CountUsersForImpersonateResponse, CreateUserGoalForManagerResponse, DeleteSubordinateGoalForManagerResponse, ListUsersForImpersonateResponse, PerfCareAssessment, PerfCareUserPhaseGoal, PerformanceCareTalent, SenecaResponse, UpdateUserGoalForManagerResponse } from '../../../commonclasses';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import * as moment from 'moment';

@Injectable()
export class AdminService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>, private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  countPerformanceProcesses() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-performance-assessments-for-admin/');
  }


  listPerformanceProcesses(fromRecord: number, numRecords: number, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-performance-assessments-for-admin/', {
      params: httpParams
    });
  }

  processStructureDetail(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/monitoring-structures-for-admin/', {
      params: httpParams
    });
  }

  exportStructureReport(perfCareYear: string, preSharedKey?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/export-monitoring-structures-for-admin-report/', {
      params: httpParams
    });
  }


  // // Recupera le Behaviour options per gli obiettivi
  // getBehaviourOptions(fromRecord: string, numRecords: string, allData?: boolean, parentTagId?: string) {
  //     let httpParams = new HttpParams();
  //     httpParams = httpParams.append('fromRecord', fromRecord);
  //     httpParams = httpParams.append('numRecords', numRecords);
  //     if (allData) {
  //         httpParams = httpParams.append('allData', allData.toString());
  //     }
  //     if (parentTagId && parentTagId.length) {
  //         httpParams = httpParams.append('parentTagId', parentTagId);
  //     }
  //     return this.http.get<SenecaResponse<GetBehaviorCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-behavior-care-tags/', {
  //         params: httpParams
  //     });
  // }

  // // Crea un obiettivo
  // createGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
  //     return this.http.post<SenecaResponse<CreateUserGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-goal-for-manager', {
  //         perfCareYear: perfCareYear,
  //         perfCareUserPhaseGoals: goal
  //     });
  // }

  countUserImpersonate(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    return this.http.get<SenecaResponse<CountUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-users-for-impersonate/', {
      params: httpParams
    });
  }


  listUserImpersonate(fromRecord: number, numRecords: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    return this.http.get<SenecaResponse<ListUsersForImpersonateResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-users-for-impersonate/', {
      params: httpParams
    });
  }

  impersonateUserForAdmin(userIdToImpersonate: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userIdToImpersonate', userIdToImpersonate);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/impersonate-user-for-admin/', {
      params: httpParams
    });
  }

  // contatori utenti calibration
  calibrationUsersCounter(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-calibration-users-count-for-admin', {
      params: httpParams
    });
  }

  countCalibrationUser(perfCareYear: number, searchedText: string, managerUserId?: string, filters?: any, userIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchedText);
    if (managerUserId) {
      httpParams = httpParams.append('managerUserId', managerUserId);
    }
    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }
    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('funzione', filters.funzione.value);
      }
      0;
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('area', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.sti && filters.sti.value) {
        httpParams = httpParams.append('sti', filters.sti.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('structureId', filters.structures.value);
      }
      if (filters.calibrationStatusTypes && filters.calibrationStatusTypes.value) {
        httpParams = httpParams.append('calibrationStatusTypes', filters.calibrationStatusTypes.value);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-calibration-users-for-admin', {
      params: httpParams
    });
  }


  // Lista utenti calibration
  listCalibrationUser(perfCareYear: number, searchedText: string, fromRecord: number, numRecords: number, managerUserId?: string, userIds?: string[], filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (managerUserId) {
      httpParams = httpParams.append('managerUserId', managerUserId);
    }

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('funzione', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('area', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.sti && filters.sti.value) {
        httpParams = httpParams.append('sti', filters.sti.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('structureId', filters.structures.value);
      }
      if (filters.calibrationStatusTypes && filters.calibrationStatusTypes.value) {
        httpParams = httpParams.append('calibrationStatusTypes', filters.calibrationStatusTypes.value);
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-calibration-users-for-admin', {
      params: httpParams
    });
  }

  // Ritorna range payout
  getPayoutRange() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-payout-range-by-final-evaluation', {});
  }

  // recap di tutti i dati necessari per definire la calibration
  getUserCalibrationData(perfCareYear: number | string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-calibration-data-for-admin', {
      params: httpParams
    });
  }

  setCalibration(perfCareYear: number, perfCareUserCalibrationToUpdate: any) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-calibration-for-admin', {
      perfCareYear: perfCareYear,
      perfCareUserCalibrationToUpdate: perfCareUserCalibrationToUpdate
    });
  }

  massiveConfirmCalibration(perfCareYear: number, userIdsToConfirmCalibration: string[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/massive-confirm-calibration-for-admin', {
      perfCareYear: perfCareYear,
      userIdsToConfirmCalibration: userIdsToConfirmCalibration
    });
  }

  getDistinctFilters(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-users-distincti-field-filter-for-admin', {
      params: httpParams
    });
  }


  exportCalibrationReport(perfCareYear: number, userIds?: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (!!userIds) {
      if (userIds && userIds.length && Array.isArray(userIds)) {
        userIds && userIds.forEach(type => {
          httpParams = httpParams.append('userIds', type);
        });
      } else if (userIds && userIds.length && !Array.isArray(userIds)) {
        httpParams = httpParams.append('userIds', userIds);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/export-calibration-report', {
      params: httpParams
    });
  }


  getDashboardGoalSetting(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        httpParams = httpParams.append('userIds', filters.userIds.toString());
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-dashboard-goals-for-admin', {
      params: httpParams
    });
  }

  getDashboardMidTerm(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        httpParams = httpParams.append('userIds', filters.userIds.toString());
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-dashboard-mid-term-review-for-admin', {
      params: httpParams
    });
  }

  getDashboardFinalEvaluation(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());


    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        httpParams = httpParams.append('userIds', filters.userIds.toString());
      }
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-dashboard-final-evaluation-for-admin', {
      params: httpParams
    });
  }

  getDashboardDevelopmentPlan(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());


    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        httpParams = httpParams.append('userIds', filters.userIds.toString());
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-dashboard-development-actions-for-admin', {
      params: httpParams
    });
  }

  getDashboardFeedback(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        httpParams = httpParams.append('userIds', filters.userIds.toString());
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-dashboard-feedback-for-admin', {
      params: httpParams
    });
  }

  getPerformanceAssessmentYears(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-assessment-years', {
      params: httpParams
    });
  }

  // Lista usenti paginata e ricerca per appunti privati
  countFilterUsers(perfCareYear: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchUserText);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-company-users-for-admin/', {
      params: httpParams
    });
  }

  listFilterUsers(fromRecord: number, numRecords: number, perfCareYear: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchUserText);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-company-users-for-admin/', {
      params: httpParams
    });
  }

  getDashboardOverview(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        for (let i = 0; i < filters.userIds.length; i++) {
          httpParams = httpParams.append('userIds', filters.userIds[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-dashboard-calibration-overview-for-admin', {
      params: httpParams
    });
  }

  isProcessClosed(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/is-process-closed-by-year', {
      params: httpParams
    });
  }

  downloadDashboardReport(perfCareYear: number, filters?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (filters) {
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('chief', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('ramo', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('groupId', filters.structures.value);
      }
      if (filters.userIds && filters.userIds.length) {
        for (let i = 0; i < filters.userIds.length; i++) {
          httpParams = httpParams.append('userIds', filters.userIds[i]);
        }
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/download-dashboard-report-for-admin', {
      params: httpParams
    });
  }


  // Count utenti talent
  countUsersForTalentInterview(perfCareYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('searchedText', searchedText || '');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-users-for-talent-interview', {
      params: httpParams
    });
  }

  // list utenti talent
  listUsersForTalentInterview(perfCareYear: number, searchedText: string, fromRecord: number, numRecords: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-users-for-talent-interview', {
      params: httpParams
    });
  }


  updateTalentInterviewStatus(perfCareYear: number, userId: string, status: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-talent-interview-status', {
      perfCareYear: perfCareYear,
      userId: userId,
      status: status
    });
  }


  getUserTalentNote(perfCareYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-talent-notes/', {
      params: httpParams
    });
  }


  downloadTalentInterviewReport(payload: PerformanceCareTalent.ExportUsersForTalentInterview): Observable<SenecaResponse<string>> {
    return this.http.get<SenecaResponse<string>>(
      this.applicationData.applicationContext + 'rest-api/performancecare-mediator/export-users-for-talent-interview/', {
      params: {
        perfCareYear: payload.perfCareYear.toString(),
      }
    });
  }

  downloadProcessParticipantReport(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/download-process-participants-report/', {
      params: httpParams
    });
  }


  // Ritorna il count per la paginazione degli utenti nel modifica dati
  countUserInProcess(performanceAssessmentYear: string, userIds?: string[], searchedText?: string, managerUserId?: string, calibrationStatusTypes?: string, denominazioneSO3?: string, denominazioneSO5?: string, funzione?: string, mansione?: string, area?: string, chiaveSesso?: string, sti?: string, structureId?: string, ignoreInProcess?: boolean) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('performanceAssessmentYear', performanceAssessmentYear);

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (managerUserId) {
      httpParams = httpParams.append('managerUserId', managerUserId);
    }
    if (calibrationStatusTypes) {
      httpParams = httpParams.append('calibrationStatusTypes', calibrationStatusTypes);
    }
    if (denominazioneSO3) {
      httpParams = httpParams.append('denominazioneSO3', denominazioneSO3);
    }
    if (denominazioneSO5) {
      httpParams = httpParams.append('denominazioneSO5', denominazioneSO5);
    }
    if (funzione) {
      httpParams = httpParams.append('funzione', funzione);
    }
    if (mansione) {
      httpParams = httpParams.append('mansione', mansione);
    }
    if (area) {
      httpParams = httpParams.append('area', area);
    }
    if (chiaveSesso) {
      httpParams = httpParams.append('chiaveSesso', chiaveSesso);
    }
    if (sti) {
      httpParams = httpParams.append('sti', sti);
    }
    if (structureId) {
      httpParams = httpParams.append('structureId', structureId);
    }

    if (ignoreInProcess) {
      httpParams = httpParams.append('ignoreInProcess', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-users-in-process-for-admin/', {
      params: httpParams
    });

  }

  // Ritorna la lista di utenti del modifica dati
  listUserInProcess(performanceAssessmentYear: string, fromRecord: number, numRecords: number, userIds?: string[], searchedText?: string, managerUserId?: string, calibrationStatusTypes?: string, denominazioneSO3?: string, denominazioneSO5?: string, funzione?: string, mansione?: string, area?: string, chiaveSesso?: string, sti?: string, structureId?: string, ignoreInProcess?: boolean) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('performanceAssessmentYear', performanceAssessmentYear);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (userIds && userIds.length) {
      for (let i = 0; i < userIds.length; i++) {
        httpParams = httpParams.append('userIds', userIds[i]);
      }
    }

    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (managerUserId) {
      httpParams = httpParams.append('managerUserId', managerUserId);
    }
    if (calibrationStatusTypes) {
      httpParams = httpParams.append('calibrationStatusTypes', calibrationStatusTypes);
    }
    if (denominazioneSO3) {
      httpParams = httpParams.append('denominazioneSO3', denominazioneSO3);
    }
    if (denominazioneSO5) {
      httpParams = httpParams.append('denominazioneSO5', denominazioneSO5);
    }
    if (funzione) {
      httpParams = httpParams.append('funzione', funzione);
    }
    if (mansione) {
      httpParams = httpParams.append('mansione', mansione);
    }
    if (area) {
      httpParams = httpParams.append('area', area);
    }
    if (chiaveSesso) {
      httpParams = httpParams.append('chiaveSesso', chiaveSesso);
    }
    if (sti) {
      httpParams = httpParams.append('sti', sti);
    }
    if (structureId) {
      httpParams = httpParams.append('structureId', structureId);
    }
    if (ignoreInProcess) {
      httpParams = httpParams.append('ignoreInProcess', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-users-in-process-for-admin/', {
      params: httpParams
    });
  }


  listEditableGoal(userId: string, goalTab: string, onlyGoalsWithEvaluation?: boolean) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('goalTab', goalTab);

    if (onlyGoalsWithEvaluation) {
      httpParams = httpParams.append('onlyGoalsWithEvaluation', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-editable-user-goals-for-admin/', {
      params: httpParams
    });
  }


  // Recupera le Care options per gli obiettivi
  getCareOptions(fromRecord: string, numRecords: string, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-care-tags/', {
      params: httpParams
    });
  }

  getUserDetailForAdmin(perfCareYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-detail-for-admin/', {
      params: httpParams
    });
  }

  // Crea un obiettivo
  createGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-goal-for-admin', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo
  updateGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-user-goal-for-admin', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoal: goal
    });
  }

  // elimina obiettivo
  deleteGoal(goalId: string[]) {
    return this.http.post<SenecaResponse<DeleteSubordinateGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-user-goal-for-admin', {
      goalId: goalId
    });
  }
  // aggiorna la valutazione di un obiettivo
  updateGoalEvaluation(userId: string, goalId: string, selfAssessmentEvaluation: string, managerEvaluation: string, managerEvaluationComment: string) {
    return this.http.post<SenecaResponse<DeleteSubordinateGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-user-evaluation-for-admin', {
      userId: userId,
      goalId: goalId,
      selfAssessmentEvaluation: selfAssessmentEvaluation,
      managerEvaluation: managerEvaluation,
      managerEvaluationComment: managerEvaluationComment
    });
  }

  getSetupProcessCounter(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-users-detail/', {
      params: httpParams
    });
  }

  getUserDetailsForAdmin(perfCareYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-detail-for-admin/', {
      params: httpParams
    });
  }

  // Ritorna il count degli utenti con obiettivi cascading
  countUsersForCascading(perfCareYear: string, filters: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    if (filters) {
      if (filters.searchedText) {
        httpParams = httpParams.append('searchedText', filters.searchedText);
      }
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('funzione', filters.funzione.value);
      }
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('area', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.sti && filters.sti.value) {
        httpParams = httpParams.append('sti', filters.sti.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('structureId', filters.structures.value);
      }
      if (filters.calibrationStatusTypes && filters.calibrationStatusTypes.value) {
        httpParams = httpParams.append('calibrationStatusTypes', filters.calibrationStatusTypes.value);
      }
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-users-for-cascading-goals-for-admin/', {
      params: httpParams
    });
  }

  // Ritorna la listat di utenti per cui è possibile inserire obiettivi cascading
  listUserForCascading(perfCareYear: string, fromRecord: number, numRecords: number, filters?: any, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (filters) {
      if (filters.searchedText) {
        httpParams = httpParams.append('searchedText', filters.searchedText);
      }
      if (filters.denominazioneSO3 && filters.denominazioneSO3.value) {
        httpParams = httpParams.append('denominazioneSO3', filters.denominazioneSO3.value);
      }
      if (filters.denominazioneSO5 && filters.denominazioneSO5.value) {
        httpParams = httpParams.append('denominazioneSO5', filters.denominazioneSO5.value);
      }
      if (filters.funzione && filters.funzione.value) {
        httpParams = httpParams.append('funzione', filters.funzione.value);
      }
      0;
      if (filters.mansione && filters.mansione.value) {
        httpParams = httpParams.append('mansione', filters.mansione.value);
      }
      if (filters.area && filters.area.value) {
        httpParams = httpParams.append('area', filters.area.value);
      }
      if (filters.chiaveSesso && filters.chiaveSesso.value) {
        httpParams = httpParams.append('chiaveSesso', filters.chiaveSesso.value);
      }
      if (filters.sti && filters.sti.value) {
        httpParams = httpParams.append('sti', filters.sti.value);
      }
      if (filters.structures && filters.structures.value) {
        httpParams = httpParams.append('structureId', filters.structures.value);
      }
      if (filters.calibrationStatusTypes && filters.calibrationStatusTypes.value) {
        httpParams = httpParams.append('calibrationStatusTypes', filters.calibrationStatusTypes.value);
      }
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-users-for-cascading-goals-for-admin/', {
      params: httpParams
    });
  }

  getUserCascadingGoals(perfCareYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-cascading-goals-for-admin', {
      params: httpParams
    });
  }

  setUserCascadingGoals(perfCareYear: string, userId: string, intro: string, list: string) {
    return this.http.post<SenecaResponse<DeleteSubordinateGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-user-cascading-goals-for-admin', {
      perfCareYear: perfCareYear,
      userId: userId,
      introductionText: intro,
      description: list
    });
  }


  downloadCascadingReport(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/export-users-for-cascading-goals-for-admin', {
      params: httpParams
    });
  }

  // Recupera i dettagli del processo in corso (performance assessment) e le fasi (con withLoadedPhases)
  getPerformanceAssessmentInfo(performanceAssessmentYear?: string, performanceAssessmentId?: string, withPhasesLoaded?: boolean) {
    let httpParams = new HttpParams();

    if (performanceAssessmentYear) {
      httpParams = httpParams.append('performanceAssessmentYear', performanceAssessmentYear);
    }

    if (performanceAssessmentId) {
      httpParams = httpParams.append('performanceAssessmentId', performanceAssessmentId);
    }

    if (withPhasesLoaded) {
      httpParams = httpParams.append('withPhasesLoaded', 'true');
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-assessment-info-for-admin', {
      params: httpParams
    });
  }


  // Ritorna la lista dei manager
  getManagersListForAdmin(perfCareYear: string, searchedText?: string, fromRecord?: number, numRecords?: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    if (fromRecord) {
      httpParams = httpParams.append('fromRecord', fromRecord.toString());
    }
    if (numRecords) {
      httpParams = httpParams.append('numRecords', numRecords.toString());
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-managers-list-for-admin', {
      params: httpParams
    });
  }

  // crea il processo
  getProcess(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("perfCareYear", perfCareYear);

    return this.http.get<SenecaResponse<PerfCareAssessment>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-process', { params: httpParams });
  }

  // crea il processo
  createProcess(perfCareYear: number, processName: string) {
    return this.http.post<SenecaResponse<PerfCareAssessment>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-process', {
      perfCareYear: perfCareYear,
      processName: processName
    });
  }

  // Aggiorna titolo e stato del processo
  updateProcess(perfCareYear: number, processName: string, processStatus: string) {
    return this.http.post<SenecaResponse<PerfCareAssessment>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-process', {
      perfCareYear: perfCareYear,
      processName: processName,
      processStatus: processStatus
    });
  }

  // Ritorna l'anno più recente per cui è stato creato un processo
  lastCreatedProcessYear() {
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/last-created-performance-assessment-year', {});
  }

  // lista delle fasi del processo
  getPerformanceAssessmentPhasesList(performanceAssessmentYear?: string, performanceAssessmentId?: string) {
    let httpParams = new HttpParams();

    if (performanceAssessmentYear) {
      httpParams = httpParams.append('performanceAssessmentYear', performanceAssessmentYear);
    }
    if (performanceAssessmentId) {
      httpParams = httpParams.append('performanceAssessmentId', performanceAssessmentId);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-assessment-phases-for-admin', {
      params: httpParams
    });
  }


  // Recupera i dettagli della fase del processo
  getPerformanceAssessmentPhaseInfo(performanceAssessmentYear: string, phaseKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('performanceAssessmentYear', performanceAssessmentYear);
    httpParams = httpParams.append('phaseKey', phaseKey);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-assessment-phase-info-for-admin', {
      params: httpParams
    });
  }

  // Aggiorna le date delle fasi del processo
  updatePerformanceAssessmentPhaseDate(phaseId: string, phaseStartDate: Date, phaseEndDate: Date, phaseClosingDate: Date, isManagerDate?: boolean) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-process-phase-date-for-admin', {
      phaseId: phaseId,
      phaseStartDate: phaseStartDate,
      phaseEndDate: phaseEndDate,
      phaseClosingDate: phaseClosingDate,
      isManagerDate: isManagerDate
    });
  }


  // Recupera la lista degli obiettivi company nella definizione obiettivi del processo
  getCompanyGoalsForAdmin(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-company-goals-for-admin', {
      params: httpParams
    });
  }

  // aggiorna gli obiettivi company nella definizione obiettivi del processo
  updateCompanyGoalForAdmin(goalData: any) {// goalId: string, title: string, unit: string, target: number, rangeMinus: number, rangePlus: number, results: string, achieved: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-company-goal-for-admin', {
      goalId: goalData.goalId,
      title: goalData.title,
      unit: goalData.unit,
      target: goalData.target,
      rangeMinus: goalData.rangeMinus,
      rangePlus: goalData.rangePlus,
      results: goalData.results,
      achieved: goalData.goalAchieved,
    });
  }

  // Recupera la lista degli obiettivi company nella definizione obiettivi del processo
  getOperationsGoalsForAdmin(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-operation-goals-for-admin', {
      params: httpParams
    });
  }

  // aggiorna gli obiettivi company nella definizione obiettivi del processo
  updateOperationsGoalForAdmin(goalData: any) {// goalId: string, title: string, unit: string, target: number, rangeMinus: number, rangePlus: number, results: string, achieved: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-operation-goal-for-admin', {
      goalId: goalData.goalId,
      title: goalData.title,
      description: goalData.description
    });
  }

  // setta una fase in highlight

  setPhaseAsHighlighted(performanceAssessmentYear: string, phaseKey: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-phase-as-highlighted-for-admin', {
      performanceAssessmentYear: performanceAssessmentYear,
      phaseKey: phaseKey,
    });
  }

  // Imposta un manager custom per l'utente
  setCustomManagerForAdmin(userId: string, perfCareYear: string, managerUserId: string) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("perfCareYear", perfCareYear);
    // Manager custom
    httpParams = httpParams.append("managerUserId", managerUserId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-custom-manager-for-admin', {
      params: httpParams
    });
  }

  // Includi utente nel processo
  addUserToProcess(perfCareYear: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/add-user-to-process-for-admin', {
      perfCareYear: perfCareYear,
      userId: userId
    });
  }

  // Rimuove utebnte dal processo
  removeUserFromProcess(perfCareYear: string, userId: string, reasonForExclusion: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/remove-user-from-process-for-admin', {
      perfCareYear: perfCareYear,
      userId: userId,
      reasonForExclusion: reasonForExclusion
    });
  }


  // Aggiunge (o aggiorna) utenti massivamente al processo
  addParticipantToProcessByFile(perfCareYear: string, file: any, keepGoalsForAll?: boolean) {
    let formData = new FormData();
    formData.append('file', file);

    let params = new HttpParams();
    params = params.append('perfCareYear', perfCareYear);
    if (keepGoalsForAll) {
      params = params.append('keepGoalsForAll', 'true');
    }

    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/add-or-update-participants-to-process-by-file', formData, options);
    return this.http.request(req);
  }

  // Rimuove utenti massivamente dal processo
  removeParticipantFromProcessByFile(perfCareYear: string, file: any) {

    let formData = new FormData();
    formData.append('file', file);

    let params = new HttpParams();
    params = params.append('perfCareYear', perfCareYear);
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/remove-participants-from-process-by-file', formData, options);
    return this.http.request(req);
  }

  // recupera i profili disponibili da assegnare agli utenti del processo per l'amministratore
  getAllowedProfilesForAdmin() {
    let httpParams = new HttpParams();
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-allowed-profiles-for-admin', {
      params: httpParams
    });
  }


  updateUserStiAndRoleForAdmin(perfCareYear: string, userId: string, profile: string, stiValue?: number | null, isPlannedChange?: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-user-sti-and-role-for-admin', {
      perfCareYear: perfCareYear,
      userId: userId,
      stiValue: stiValue,
      profile: profile,
      isPlannedChange: isPlannedChange
    });
  }

  setUserSyncForAdmin(userIds: string[], setSync: boolean) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-users-sync-for-admin', {
      userIds: userIds,
      setSync: setSync
    });
  }

}