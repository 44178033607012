import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { GetSubordinatesWithPhaseStatusesForManagerResponse, SenecaResponse, PerfCareMacroGoalTypes, PerfCareGoalConsuntivationStatuses, CancelUserFeedbackDialogueForManagerResponse, PerfCareGoalRentStatuses, PerfCareCareAttributesAreaTypes, sortByRules, ListDevelopmentActionForManagerResponse, PerfCareDevelopmentAction, CanCreateDevelopmentActionForManagerResponse, CanShareDevelopmentActionToUserResponse, PerfCareApplauseFeedback, ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse, ListEvidenceFeedbackForManagerResponse, CountFeedbacksForManagerResponse, PerfCareFeedbackDialogueTypes, DeletePrivateNoteFeedbackForManagerResponse, ListPrivateNoteFeedbackForManagerResponse, PerfCarePeerFeedback, ArrayUtils, PerfCareAssessmentPhaseKeys, PerfCareFinalEvaluationRentStatuses, GoalSettingsProposalStatusTypes } from 'src/commonclasses';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { Subordinateservice } from '../../manager/services/subordinates.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ManagerActions from "../../manager/ngrx/manager.actions";
import { Subscription } from 'rxjs';
import { GoalUtils, SHARED_STATUS } from 'src/app/utils/goal.utils';
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ActionUtils } from 'src/app/utils/action.utils';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-manager-operations-personDetails',
  templateUrl: './personDetails.component.html',
  styleUrls: ['./personDetails.component.scss']
})
export class ManagerPersonDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  getPersonDetails$: any;
  personDetails$!: Subscription;
  personDetails: any;
  isLoadingPersonDetails = false;
  isCollaborator: boolean = false;
  routeParams$!: Subscription;
  userId: string = '';
  runningYear$: any;
  loggedUser$: any;
  runningYear: number = 0;
  getUserGoalList$!: Subscription;
  isLoadingUserGoals: boolean;
  goalList: any;
  goalListTeam: any = [];
  goalListTeamForFE: any = [];
  getUserProfileTab$!: Subscription;
  isLoadingTab: boolean;
  contentTabList!: TabDossier[];
  currentContentTab!: TabDossier;
  noDataPlaceholder: any;
  goalTitleInfo: string = "";
  canShareGoals$!: Subscription;
  loggedUser: any;
  shareModalContent!: any;
  getUserInterview$!: Subscription;
  interview: any;
  challengeList: any = null;
  challengeTitle: string = '';
  challengeTooltipModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  roleGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  teamGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  commercialGoalsTooltipModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  careTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  comptenceFeedbackModalTooltip: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  operationsModalTooltip: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  challengeAccordionOpened: boolean = true;
  goalAccordionOpened: boolean = true;
  alreadyShared: boolean = false;
  empowerementGoal: any;
  isFetchingDeleteInterview: boolean = false;
  deleteInterview$: Subscription = new Subscription;
  interviewIdToDelete: string = '';
  operationsGoalList: any[] = [];
  careAccordionTitle: string = '';
  qualifiedMessageTitle: string = '';
  careAccordionOpened: boolean = true;
  kpiGoal: any;
  competenceGoal: any;
  competenceFeedback: string = '';
  competenceFeedbackChars: number = 600;
  maxTextAreaChars: number = 600;
  competenceList: any[] = [];
  careAccordionStatus: any;
  techGoal: any = null;
  competenceForModal: any;
  isFetchingGetCareTag: boolean = false;
  getCareTag$: Subscription = new Subscription;
  sendCompetenceFeedback$: Subscription = new Subscription;
  translations: any;
  strengthCompetences: any[] = [];
  comfortCompetences: any[] = [];
  improvementCompetences: any[] = [];
  // aggiunte per Feedback
  isFeedbackSection: boolean = false;
  isObjectiveSection: boolean = false;
  noDataFeedbackPlaceholderReceived: string = '';
  noDataFeedbackPlaceholderSent: string = '';
  isLoadingFeedbackClaps: boolean = false;
  feedbackClapList: PerfCareApplauseFeedback[] = [];
  clapIdToDelete: string = '';
  getFeedbackClapList$: Subscription = new Subscription;
  isReceiving: boolean = true;
  isIndividualPlanSection: boolean = false;
  // variabili Piano di sviluppo
  noDataDevelopmentActionsPlaceholder: any;
  isLoadingUserDevelopmentActions: boolean = false;
  newActionComment: string = '';
  getDevelopmentActions$: Subscription = new Subscription;
  developmentActionsList: any[] = [];
  canCreateDevActions: boolean = false;
  addCommentToDevelopAction$: Subscription = new Subscription;
  developAction: any = {
    comment: ''
  };
  developActionModalText: string = '';
  isLoadingShareDevelopActions: boolean = false;
  shareDevelopActions$: Subscription = new Subscription;
  actionForCommentDelete: any;
  commentIdToDelete: string = '';
  commentToEdit: any;
  developActionEdit: any;
  isFetchingCanAddActions: boolean = false;
  canAddDevelopActions$: Subscription = new Subscription;
  isLoadingDeleteDevelopAction: boolean = false;
  deleteDevelopAction$: Subscription = new Subscription;
  developActionIdToDelete: string = '';
  isFetchingCanShareActions: boolean = false;
  canShareDevelopActions$: Subscription = new Subscription;
  canShareDevActions: boolean = false;
  isFetchingFeedbackTabs: boolean = false;
  evidencesList: any[] = [];
  isLoadingFeedbackEvidences: boolean = false;
  getFeedbackEvidencesList$: Subscription = new Subscription;
  isUpdateFileUploadOnEvidences: boolean = false;
  fileTitle: string = '';
  fileObject: any;
  isToResetUploadComponent: boolean = false;
  isSmartFeedback: boolean = false;
  isEvidencesFeedback: boolean = false;
  contentFeedbackTabList!: TabDossier[];
  currentContentFeedbackTab!: TabDossier;
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToEvidence$: Subscription = new Subscription;
  canGoAddEvidence: boolean = false;
  isLoadingUploadFileOnEvidences: boolean = false;
  evidenceIdToManageFile: string = '';
  evidenceUploadIdToDelete: string = '';
  evidenceIdOfFileToDelete: string = '';
  isLoadingDeleteFileOnEvidence: boolean = false;
  isLoadingGetDownloadUrlOnEvidence: boolean = false;
  getCounterFeedbackTabs$: Subscription = new Subscription;
  isLoadingFeedbackTabsCounters: boolean = false;
  feedbackTabCounters: any;
  isPrivateNotes: boolean = false;
  isLoadingPrivateNotes: boolean = false;
  privateNotesList: any[] = [];
  noteIdToDelete: string = '';
  // tab feedback colloqui
  isInterview: boolean = false;
  isLoadingInterviewsList: boolean = true;
  getInterviewsList$: Subscription = new Subscription;
  interviewList: any[] = [];
  getPrivateNotesList$: Subscription = new Subscription;
  // Obiettivi in modifica
  getObjectiveOnHold$: Subscription = new Subscription;
  draftGoalList: any[] = [];
  editObjectiveAccordionTitle: string = '';
  editObjectiveAccordionOpen: boolean = true;
  editObjectiveAlreadyShared: boolean = false;
  isAfterSharing: boolean = false;
  // Peer Feedback
  isPeerFeedback: boolean = false;
  isLoadingPeerFeedback: boolean = false;
  peerFeedbackList: PerfCarePeerFeedback[] = [];
  peerFeedbackPlaceholder: string = '';
  peerFeedbackToDelete: string = '';
  getPeerFeedbackList$: Subscription = new Subscription;
  feedbackForRequestModal: any;
  deletePeerFeedback$: Subscription = new Subscription;
  peerSent: boolean = true;
  // STI
  stiAmount: number = 0;
  getUserWatchedSTIRule$: Subscription = new Subscription;
  isLoadingPersonWatchedSTI: boolean = false;
  userWatchedSTI: boolean = false;
  roleStiTitle: string = '';

  // performance level
  performanceLevelActive: boolean = false;
  performanceLevelTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  performanceLevelOpened: boolean = true;
  performanceLevelDone: boolean = false;
  performanceStatus: { status: string; statusMessage: any; } = {
    status: "",
    statusMessage: ""
  };
  performanceLevelEvaluation: string = "";
  getRunningPhase$: Subscription;
  runningPhase: any;
  finalEvaluationForModal: any;
  isLoadingUserFinalEvaluation: boolean = false;
  getUserFinalEvaluation$: Subscription = new Subscription;
  performanceLevelStatus: any;
  userFinalEvaluation: string = '';
  userFinalEvaluationObservation: string = '';
  userFinalEvaluationDate: string = '';
  managerFinalEvaluation: any;
  payoutChosen: any;
  managerFinalEvaluationObservation: any;
  suggestedPayout: any;
  suggestedFinalEvaluation: any;
  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  // Post calibration
  isLoadingUserCalibration: boolean = false;
  calibrationActive: boolean = false;
  postCalibrationData: {
    level: string;
    status: {
      statusMessage: string;
      status: string; // 'inProgress, completed, toStart
    };
    observations: string,
    payout: {
      id: number,
      title: string
    }
  } | any;
  getPostCalibrationData$: Subscription = new Subscription;
  isProcessClosed$: Subscription = new Subscription;
  isProcessClosed: boolean = false;
  roleStiDescr: any;
  getGoalSettingProposalStatus$: Subscription = new Subscription;
  proposalStatus: any;
  denyComment: string = '';
  approveComment: string = '';
  objectiveForModal: any;
  cascadingGoal: any;
  getCascadingGoals$: Subscription = new Subscription;
  isLoadingCascadingGoals: boolean = false;
  maxZeroWeightedGoals: any;
  deleteId: string = '';
  deleteObjective$: Subscription = new Subscription;
  goalSettingDeadline: any;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private managerService: ManagerService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    public redirectService: RedirectService) {

    this.isLoadingUserGoals = true;
    this.isLoadingTab = true;
    this.contentTabList = [];
    this.goalList = [];
    this.goalListTeam = [];
    this.contentFeedbackTabList = [];
    let yearString = this.translate.instant('generic.YEAR');
    this.getRunningPhase$ = this.store.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
      this.runningPhase = runningPhase;
      this.goalSettingDeadline = this.runningPhase && this.runningPhase.phaseKey == 'GOAL_SETTING' ? this.runningPhase.deadlineDate : '--';
    })


    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear)
      .subscribe((runningYear) => {
        this.runningYear = runningYear;
        if (this.runningYear) {
          this.routeParams$ = this.route.params
            .subscribe(
              (params: Params) => {
                this.userId = params.userId;
                this.getPerformanceAssessmentYears();
                if (params.perfCareYear && params.perfCareYear.length > 4) {
                  this.router.navigate(['manager/goalDetails/' + this.userId + '/' + this.runningYear]);
                } else {
                  if (params.perfCareYear) {
                    this.selectedYear = {
                      id: params.perfCareYear,
                      name: 'Anno ' + params.perfCareYear
                    }
                  } else {
                    this.selectedYear = {
                      id: this.runningYear,
                      name: 'Anno ' + this.runningYear
                    }
                  }
                }

                this.checkProcessClosed();

                if (window.location.href.indexOf('feedback') > -1) {
                  this.isFeedbackSection = true;
                  if (window.location.href.indexOf('smartFeedback') > -1) {
                    this.isSmartFeedback = true;
                  } else if (window.location.href.indexOf('evidences') > -1) {
                    this.isEvidencesFeedback = true;
                  } else if (window.location.href.indexOf('privateNotes') > -1) {
                    this.isPrivateNotes = true;
                  } else if (window.location.href.indexOf('interview') > -1) {
                    this.isInterview = true;
                  } else if (window.location.href.indexOf('peerFeedback') > -1) {
                    this.isPeerFeedback = true;
                  }
                } else if (window.location.href.indexOf('developmentPlan') > -1) {
                  this.isIndividualPlanSection = true;
                } else {
                  this.isObjectiveSection = true;
                }
                this.isLoadingPersonDetails = true;

                // RESET dati performance level
                this.performanceLevelDone = false;
                this.performanceLevelActive = false;
                this.stiAmount = 0;

                this.store.dispatch(ManagerActions.SetActiveUserId({ payload: this.userId }));
                // Recupero la persona selezionata, se è presente nello store
                this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                  this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                    return person.userId === this.userId;
                  })[0];
                  // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                  if (!this.personDetails || this.selectedYear.id != this.runningYear) {
                    this.getPersonDetails();
                  }
                  // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                  if (this.personDetails && this.personDetails.userId) {
                    this.stiAmount = this.personDetails.stiAmount || 0;

                    this.noDataPlaceholder = this.translate.instant('goal.ADD_OBJECTIVE') + '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>';
                    this.noDataFeedbackPlaceholderReceived = '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>' + this.translate.instant('feedbackSection.smartFeedback.MANAGER_RECEIVED_PLACEHOLDER');
                    this.noDataFeedbackPlaceholderSent = '<b>' + this.personDetails.forename + ' ' + this.personDetails.surname + '</b>' + this.translate.instant('feedbackSection.smartFeedback.MANAGER_SENT_PLACEHOLDER');
                    this.noDataDevelopmentActionsPlaceholder = this.translate.instant('developmentPlan.PAGE_SUBTITLE');
                    this.getUserInterview();
                    this.initializeData();
                  }
                });
              }
            )
        }
      });

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
      }
    })
  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        "modals.CHALLENGE.TITLE",
        "modals.CHALLENGE.SUBTITLE",
        "modals.CHALLENGE.TEXT",
        "modals.ROLE.TITLE",
        "modals.ROLE.TEXT",
        "managerOperations.CARE_TITLE",
        "managerOperations.SHARED_COMPETENCE",
        'managerOperations.SHARED_COMPETENCE',
        "modals.OPERATIONS.TITLE",
        "modals.OPERATIONS.SUBTITLE",
        "modals.OPERATIONS.TEXT",
        'accordion.GUIDELINES_STI',
        "performance.ACCORDION_TITLE",
        "performance.ACCORDION_TOOLTIP",
        "performance.statuses.TO_DEFINE",
        "performance.statuses.TO_CONFIRM",
        "performance.statuses.COMPLETED",
        "calibration.statuses.TO_CONFIRM",
        'goal.STI_RULE_CONTENT_1',
        'goal.STI_RULE_CONTENT_2',
        // NUove chiavi flusso ownership collaboratore
        "objectiveWizard.manager.modals.DENY_DESCR_1",
        "objectiveWizard.manager.modals.DENY_DESCR_2",
        "objectiveWizard.manager.modals.APPROVE_DESCR",
      ])
      .subscribe(translations => {
        this.translations = translations;
        this.roleStiTitle = translations['accordion.GUIDELINES_STI'];
        this.roleStiDescr = translations['goal.STI_RULE_CONTENT_1'] + this.selectedYear.id + translations['goal.STI_RULE_CONTENT_2'];
        this.careAccordionTitle = translations['managerOperations.CARE_TITLE'];
        this.careAccordionOpened = true;
        this.careTooltipModal = {
          modalId: "opm001",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.challengeTitle = translations['accordion.CHALLENGE'] + ' ' + this.selectedYear.id;
        this.challengeTooltipModal = {
          modalId: "153",
          title: translations["modals.CHALLENGE.TITLE"] + this.selectedYear.id,
          subtitle: translations["modals.CHALLENGE.SUBTITLE"],
          text: translations["modals.CHALLENGE.TEXT"]
        };
        this.roleGoalsTooltipModal = {
          modalId: "168",
          title: translations["modals.ROLE.TITLE"],
          text: translations["modals.ROLE.TEXT"]
        };
        this.teamGoalsTooltipModal = {
          modalId: "500",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.commercialGoalsTooltipModal = {
          modalId: "501",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.comptenceFeedbackModalTooltip = {
          modalId: "op001",
          title: 'Lorem Ipsum',
          subtitle: 'Lorem Ipsum',
          text: translations["modals.ROLE.TEXT"]
        };
        this.operationsModalTooltip = {
          modalId: "502",
          title: translations["modals.OPERATIONS.TITLE"],
          subtitle: translations["modals.OPERATIONS.SUBTITLE"],
          text: translations["modals.OPERATIONS.TEXT"]
        };
        this.performanceLevelTooltip = {
          modalId: "pl001",
          title: translations["performance.ACCORDION_TITLE"],
          text: translations["performance.ACCORDION_TOOLTIP"]
        };
        this.performanceStatus = {
          status: "toStart",
          statusMessage: translations["performance.statuses.TO_DEFINE"]
        }
      })
  }

  ngAfterViewChecked() {
    this.challengeTitle = this.translate.instant('accordion.CHALLENGE') + ' ' + this.selectedYear.id;
  }

  getFeedbackTabsCounters() {
    this.isLoadingFeedbackTabsCounters = true;
    if (this.getCounterFeedbackTabs$) {
      this.getCounterFeedbackTabs$.unsubscribe();
    }
    this.getCounterFeedbackTabs$ = this.managerService.getCounterFeedbackTabs(this.userId, this.selectedYear.id)
      .subscribe((data: SenecaResponse<CountFeedbacksForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          this.feedbackTabCounters = data.response;
        } else {
          this.feedbackTabCounters = null;
        }
        this.setFeedbackList();
        this.isLoadingFeedbackTabsCounters = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackTabsCounters = false;
      });
  }

  setFeedbackList() {
    this.isFetchingFeedbackTabs = true;
    this.translate.get(
      [
        "goal.tabs.PEER_FEEDBACK_TAB",
        "goal.tabs.CLAP_TAB",
        "goal.tabs.EVIDENCES_TAB",
        "goal.tabs.PRIVATE_NOTES_TAB",
        "goal.tabs.INTERVIEW_TAB"
      ])
      .subscribe(translations => {
        this.contentFeedbackTabList = [
          {
            id: 'PEER_FEEDBACK_TAB',
            title: translations["goal.tabs.PEER_FEEDBACK_TAB"],
            attrAriaLabel: translations["goal.tabs.PEER_FEEDBACK_TAB"],
            isDisabled: true,
            badgeNumberVisible: true,
            badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.peerFeedbackCount) || 0,
            badgeGrey: true
          },
          {
            id: 'CLAP_TAB',
            title: translations["goal.tabs.CLAP_TAB"],
            attrAriaLabel: translations["goal.tabs.CLAP_TAB"],
            isDisabled: false,
            badgeNumberVisible: true,
            badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.applauseFeedbackCount) || 0,
            badgeGrey: true
          },
          {
            id: 'EVIDENCES_TAB',
            title: translations["goal.tabs.EVIDENCES_TAB"],
            attrAriaLabel: translations["goal.tabs.EVIDENCES_TAB"],
            isDisabled: true,
            badgeNumberVisible: true,
            badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.evidenceFeedbackCount) || 0,
            badgeGrey: true
          },
          {
            id: 'PRIVATE_NOTES_TAB',
            title: translations["goal.tabs.PRIVATE_NOTES_TAB"],
            attrAriaLabel: translations["goal.tabs.PRIVATE_NOTES_TAB"],
            isDisabled: true,
            badgeNumberVisible: true,
            badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.privateNoteCount) || 0,
            badgeGrey: true
          },
          {
            id: 'INTERVIEW_TAB',
            title: translations["goal.tabs.INTERVIEW_TAB"],
            attrAriaLabel: translations["goal.tabs.INTERVIEW_TAB"],
            isDisabled: true,
            badgeNumberVisible: true,
            badgeNumber: (this.feedbackTabCounters && this.feedbackTabCounters.feedbackDialogueCount) || 0,
            badgeGrey: true
          }
        ]
        this.contentFeedbackTabList[0].isDisabled = true;
        this.contentFeedbackTabList[1].isDisabled = true;
        this.contentFeedbackTabList[2].isDisabled = true;
        this.contentFeedbackTabList[3].isDisabled = true;
        if (this.isPeerFeedback) {
          this.getPeerFeedbackList();
          this.peerFeedbackPlaceholder = this.translate.instant('peerFeedback.PLACEHOLDER_MANAGER') + ' <b>' + this.personDetails.surname + ' ' + this.personDetails.forename + '</b>';
          this.currentContentFeedbackTab = this.contentFeedbackTabList[0]
          this.contentFeedbackTabList[0].isDisabled = false;
        } else if (this.isSmartFeedback) {
          this.getFeedbackClapList();
          this.currentContentFeedbackTab = this.contentFeedbackTabList[1];
          this.contentFeedbackTabList[1].isDisabled = false;
        } else if (this.isEvidencesFeedback) {
          this.getFeedbackEvidencesList();
          this.getGoalsToAssociate();
          this.currentContentFeedbackTab = this.contentFeedbackTabList[2];
          this.contentFeedbackTabList[2].isDisabled = false;
        } else if (this.isPrivateNotes) {
          this.getPrivateNotesList();
          this.currentContentFeedbackTab = this.contentFeedbackTabList[3];
          this.contentFeedbackTabList[3].isDisabled = false;
        } else if (this.isInterview) {
          this.getInterviewsList();
          this.currentContentFeedbackTab = this.contentFeedbackTabList[4];
          this.contentFeedbackTabList[4].isDisabled = false;
        }
        this.isFetchingFeedbackTabs = false;
      })
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociate() {
    this.isLoadingGoalsToAssociate = true;
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    this.getGoalsToAssociateToEvidence$ = this.managerService.getGoalsToAssociateWithEvidence(this.userId)
      .subscribe((data: SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.canGoAddEvidence = true;
        } else {
          this.canGoAddEvidence = false;
        }
        this.isLoadingGoalsToAssociate = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsToAssociate = false;
      });
  }

  // Cambi le tabs della sezione del feedback
  onContentFeedbackTabChanged(tab: TabDossier) {
    this.currentContentFeedbackTab = tab;
    if (this.currentContentFeedbackTab.id == 'CLAP_TAB') {
      this.router.navigate(['manager/personDetails/feedback/smartFeedback/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'EVIDENCES_TAB') {
      this.router.navigate(['manager/personDetails/feedback/evidences/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'PRIVATE_NOTES_TAB') {
      this.router.navigate(['manager/personDetails/feedback/privateNotes/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'INTERVIEW_TAB') {
      this.router.navigate(['manager/personDetails/feedback/interview/' + this.userId + '/' + this.selectedYear.id]);
    } else if (this.currentContentFeedbackTab.id == 'PEER_FEEDBACK_TAB') {
      this.router.navigate(['manager/personDetails/feedback/peerFeedback/' + this.userId + '/' + this.selectedYear.id]);
    }
  }


  // Vai alla pagina di crezione evidenza
  goToAddEvidence() {
    if (this.canGoAddEvidence) {
      this.router.navigate(["manager/createUpdateEvidenceOnEvidence/" + this.userId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "d152",
        title: this.translate.instant("evidences.NOT_CAN_CREATE_EVIDENCES"),
        text: this.translate.instant("evidences.NOT_CAN_CREATE_EVIDENCES_DESCRIPTION")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // Vai alla pagina di aggiornamento evidenza
  goToEditEvidence(id: string) {
    this.router.navigate(["manager/createUpdateEvidenceOnEvidence/" + this.userId + '/' + id]);
  }

  // Vai a gestire l'obiettivo di tipo Team
  goToManageObjective(itemId: string) {
    this.router.navigate(['manager/createUpdateGoalPlatformManager/' + this.personDetails.userId + '/' + itemId]);
  }

  // ritorna la lista di evidenze
  getFeedbackEvidencesList() {
    this.isLoadingFeedbackEvidences = true;
    if (this.getFeedbackEvidencesList$) {
      this.getFeedbackEvidencesList$.unsubscribe();
    }
    this.getFeedbackEvidencesList$ = this.managerService.getEvidences(this.userId, this.selectedYear.id).
      subscribe((data: SenecaResponse<ListEvidenceFeedbackForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.evidencesList = data.response;

          this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
            moment.locale(applicationLang);

            for (let i = 0; i < this.evidencesList.length; i++) {
              this.evidencesList[i].formattedCreationDate = moment(this.evidencesList[i].creationDate).fromNow();
            }
          })
        } else {
          this.evidencesList.length = 0;
        }
        this.isLoadingFeedbackEvidences = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackEvidences = false;
      });
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  onFileTitleChanged(newTitle: string) {
    this.fileTitle = newTitle;
  }

  // funzioni modale upload file in evidenze
  onFileUpload(id: string) {
    this.fileTitle = '';
    this.fileObject = null;
    this.evidenceIdToManageFile = id;
    this.isToResetUploadComponent = true;
    this.modalService.open('uploadFileOnEvidences');
  }

  closeUploadFileModal() {
    this.isToResetUploadComponent = false;
    this.evidenceIdToManageFile = '';
    this.modalService.close('uploadFileOnEvidences');
  }

  confirmUploadFile() {
    this.uploadFileOnEvidences();
  }

  uploadFileOnEvidences() {
    this.isLoadingUploadFileOnEvidences = true;
    this.isToResetUploadComponent = false;

    let evidenceId = this.evidenceIdToManageFile;
    this.closeUploadFileModal();

    this.managerService.uploadFileForEvidence(this.userId, this.runningYear.toString(), evidenceId, this.fileTitle, this.fileObject)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUploadFileOnEvidences = false;
        } else {
          this.getFeedbackEvidencesList();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm011",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUploadFileOnEvidences = false;
        })
  }

  // Cancella file evidenza
  deleteFileOnEvidence(uploadId: string, evidenceId: string) {
    this.evidenceUploadIdToDelete = uploadId;
    this.evidenceIdOfFileToDelete = evidenceId;
    this.modalService.open('deleteFileModal');
  }

  // Cancella file evidenza
  closeDeleteFileModalOnEvidence(confirmDelete: boolean) {
    if (confirmDelete) {
      this.isLoadingDeleteFileOnEvidence = true;
      this.managerService.deleteFileForEvidence(this.userId, this.selectedYear.id.toString(), this.evidenceIdOfFileToDelete, this.evidenceUploadIdToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm014",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingDeleteFileOnEvidence = false;
          } else {
            this.getFeedbackEvidencesList();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingDeleteFileOnEvidence = false;
          })
    }
    this.evidenceUploadIdToDelete = '';
    this.evidenceIdOfFileToDelete = '';
    this.modalService.close('deleteFileModal');
  }

  // Download file nell'evidenza
  downloadFileOnEvidences(file: any, feedbackId: string) {
    this.isLoadingGetDownloadUrlOnEvidence = true;

    this.managerService.retrieveNewMaskedUrlForUploadForEvidence(this.userId, file.uploadId, feedbackId, this.selectedYear.id.toString())
      .subscribe((data: any) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          let downloadUrl = this.authService.getMaskedUrlDownload(data.response, file.fileName);
          setTimeout(() => {
            document.location.assign(downloadUrl);
          }, 500)
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGetDownloadUrlOnEvidence = false;
        })
  }

  // Se il manager può condividere le azioni di sviluppo
  canShareActions() {
    this.isFetchingCanShareActions = true;

    this.canShareDevelopActions$ = this.managerService.canShareDevelopmentActions(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<CanShareDevelopmentActionToUserResponse>) => {
        if (data && data.error) {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = false;
        } else if (data && data.response) {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = true;
        } else {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = false;
        }
      }, (err?: any) => {
        this.isFetchingCanShareActions = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "000",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  // Se il manager può creare azioni di sviluppo
  canAddActions() {
    this.isFetchingCanAddActions = true;

    this.canAddDevelopActions$ = this.managerService.canCreateDevelopmentActions(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<CanCreateDevelopmentActionForManagerResponse>) => {
        if (data && data.error) {
          this.isFetchingCanAddActions = false;
        } else {
          this.isFetchingCanAddActions = false;
          this.canCreateDevActions = true;
        }
      }, (err?: any) => {
        this.isFetchingCanAddActions = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "000",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  getPersonDetails() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.selectedYear.id)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData && personDetailsData.error) {
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.stiAmount = this.personDetails.stiAmount || 0;
          /* this.qualifiedMessageTitle = this.personDetails.forename + ' ' + this.translations['managerOperations.SHARED_COMPETENCE']; */
          this.resetFinalEvaluationData();
          this.getUserInterview();
          this.initializeData();
          this.isLoadingPersonDetails = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Quando vado a modificare la sfida
  onChallengeDetailClicked(goal: any) {
    this.router.navigate(['/manager/personDetails/' + this.userId + '/' + goal.id + '/' + this.selectedYear.id]);
  }

  getObjectiveOnHold(skipReload?: boolean) {
    if (this.getObjectiveOnHold$) {
      this.getObjectiveOnHold$.unsubscribe();
    }
    this.getObjectiveOnHold$ = this.managerService.listUserGoalForManager(this.selectedYear.id, this.personDetails.userId, "DRAFT_INDIVIDUAL_TAB")
      .subscribe((goalDrafts: SenecaResponse<any>) => {
        if (goalDrafts && goalDrafts.response) {
          let tempList = GoalUtils.formatGoalCardList(this.translate, goalDrafts.response, true, this.userId);
          this.draftGoalList = tempList;
          if (this.draftGoalList && this.draftGoalList.length) {
            this.isAfterSharing = true;
          }
        }
        this.goalList = [];
        if (!skipReload) {
          this.getUserProfileTab();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "111",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        window.history.back();
      });
  }

  // Recupera la lista degli obiettivi
  getUserGoalList() {
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    this.alreadyShared = false;
    this.getUserGoalList$ = this.managerService.listUserGoalForManager(this.selectedYear.id, this.personDetails.userId, this.currentContentTab && this.currentContentTab.id)
      .subscribe((goalList: any) => {

        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "110",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUserGoals = false;
        } else {
          this.competenceList = [];
          this.goalList = [];
          this.challengeList = [];
          this.kpiGoal = [];
          this.techGoal = [];
          this.goalListTeamForFE = [];
          this.goalListTeam = [];
          this.empowerementGoal = {};

          let tempList = GoalUtils.formatGoalCardList(this.translate, goalList.response, true, this.userId);

          if (this.personDetails.isOperation) {
            // Obiettivi per utenti operation con vecchia logica
            this.goalListTeam = tempList.filter((x: any) => x.creationUserId !== this.userId && (x.type == PerfCareMacroGoalTypes.TEAM || x.type == PerfCareMacroGoalTypes.COMMERCIAL));
            this.goalListTeam = ArrayUtils.groupByKey((this.goalListTeam), (goal: any) => goal.consuntivation?.consuntivationId);
            for (const consuntivationId in this.goalListTeam) {
              const goals = this.goalListTeam[consuntivationId];
              let res = {
                list: goals[0].consuntivation,
                order: goals[0].consuntivation?.order,
                goals: goals.sort((a: any, b: any) => sortByRules(a, b, false, [{ fieldExtractor: (x) => -x.weight }, { fieldExtractor: (x) => x.title }])),
                statusData: this.getTeamSemesterStatusData(goals[0].consuntivation?.consuntivationStatus),
                accordionTitle: ((goals[0] && goals[0].consuntivation && goals[0].consuntivation?.order) + 1) + '° ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0]) + ' ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0], true)
              }
              this.goalListTeamForFE.push(res);
            }
            if (this.goalListTeamForFE && this.goalListTeamForFE.length > 0) {
              this.goalListTeamForFE.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.order }));
            }

            let otherGoals = [PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS, PerfCareMacroGoalTypes.DEVELOPMENT, PerfCareMacroGoalTypes.TEAM, PerfCareMacroGoalTypes.COMMERCIAL, PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT, PerfCareMacroGoalTypes.OPERATIONS_KPI, PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES]
            this.goalList = tempList.filter((x: any) => x.creationUserId !== this.userId && !otherGoals.includes(x.type));
            let shared = this.goalList.filter((x: any) => SHARED_STATUS.includes(x.goalStatus));
            if (shared && shared[0]) {
              this.alreadyShared = true;
            }
            this.competenceList = tempList.filter((x: any) => x.type == PerfCareMacroGoalTypes.MY_CARE_ATTRIBUTES);
            this.challengeList = tempList.find((x: any) => x.type == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS || x.type == PerfCareMacroGoalTypes.DEVELOPMENT);

            if (this.competenceList && this.competenceList[0] && this.competenceList[0].consuntivation && this.competenceList[0].consuntivation?.hasBeenSaw) {
              this.careAccordionStatus = {
                status: 'toStart',
                statusMessage: this.translate.instant('goal.status.NEW')
              };
            }
            this.strengthCompetences = this.competenceList.filter((x: any) => {
              return x.evaluation === PerfCareCareAttributesAreaTypes.STRENGTH_AREA;
            });
            this.strengthCompetences.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.title }));
            this.comfortCompetences = this.competenceList.filter((x: any) => {
              return x.evaluation === PerfCareCareAttributesAreaTypes.COMFORT_ZONE_AREA;
            });
            this.comfortCompetences.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.title }));
            this.improvementCompetences = this.competenceList.filter((x: any) => {
              return x.evaluation === PerfCareCareAttributesAreaTypes.IMPROVEMENT_AREA;
            });
            this.improvementCompetences.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.title }));
            this.kpiGoal = tempList.find((x: any) => x.type == PerfCareMacroGoalTypes.OPERATIONS_KPI);
            this.techGoal = tempList.find((x: any) => x.type == PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES);
            this.empowerementGoal = tempList.find((x: any) => x.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
            if (this.competenceList[0] && this.competenceList[0].consuntivation && !this.competenceList[0].consuntivation?.hasBeenSaw) {
              this.setCareAttributeGoalsAsSeenForManager();
            }

          } else {
            this.goalListTeam = tempList.filter((x: any) => x.creationUserId !== this.userId && (x.type == PerfCareMacroGoalTypes.TEAM || x.type == PerfCareMacroGoalTypes.COMMERCIAL));
            this.goalListTeam = ArrayUtils.groupByKey((this.goalListTeam), (goal: any) => goal.consuntivation?.consuntivationId);
            for (const consuntivationId in this.goalListTeam) {
              const goals = this.goalListTeam[consuntivationId];
              let res = {
                list: goals[0].consuntivation,
                order: goals[0].consuntivation?.order,
                goals: goals.sort((a: any, b: any) => sortByRules(a, b, false, [{ fieldExtractor: (x) => -x.weight }, { fieldExtractor: (x) => x.title }])),
                statusData: this.getTeamSemesterStatusData(goals[0].consuntivation?.consuntivationStatus),
                accordionTitle: ((goals[0] && goals[0].consuntivation && goals[0].consuntivation?.order) + 1) + '° ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0]) + ' ' + GoalUtils.calculateMesterAndMonths(this.translate, goals[0], true)
              }
              this.goalListTeamForFE.push(res);
            }
            if (this.goalListTeamForFE && this.goalListTeamForFE.length > 0) {
              this.goalListTeamForFE.sort((a: any, b: any) => sortByRules(a, b, false, { fieldExtractor: (x) => x.order }));
            }
            this.empowerementGoal = tempList.find((obj: any) => obj.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT);
            let excludedTypes = [PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT, PerfCareMacroGoalTypes.TEAM, PerfCareMacroGoalTypes.COMMERCIAL];
            this.goalList = tempList.filter((obj: any) => !excludedTypes.includes(obj.type));
          }

          this.isLoadingUserGoals = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "111",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Cancella la novitò dagli attributi CARE del manager
  setCareAttributeGoalsAsSeenForManager() {

    this.managerService.setCareAttributeGoalsAsSeenForManager(this.selectedYear.id.toString(), this.userId)
      .subscribe((shared: any) => {
        if (!shared) {
          const messageObj: ApplicationModalMessage = {
            modalId: "114",
            text: this.translate.instant("errors." + shared.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "115",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  // Recupera un oggetto per lo stato nell'accordion
  getTeamSemesterStatusData(consuntivationStatus: string) {
    if (consuntivationStatus == PerfCareGoalConsuntivationStatuses.PERFCARE_CONSUNTIVATION_COMPLETED) {
      return {
        status: 'completed',
        statusMessage: 'goalSetting.updatePMGoal.consuntivations.' + consuntivationStatus
      }
    } else if (consuntivationStatus == PerfCareGoalConsuntivationStatuses.PERFCARE_CONSUNTIVATION_RUNNING) {
      return {
        status: 'inProgress',
        statusMessage: 'goalSetting.updatePMGoal.consuntivations.' + consuntivationStatus
      }
    } else if (consuntivationStatus == PerfCareGoalConsuntivationStatuses.PERFCARE_CONSUNTIVATION_TO_DECLINE) {
      return {
        status: 'toStart',
        statusMessage: 'goalSetting.updatePMGoal.consuntivations.' + consuntivationStatus
      }
    } else {
      return {
        status: '',
        statusMessage: ''
      }
    }
  }

  // Formatta i goalType delle tab
  getGoalTypes(goalTypes: any[]) {
    let tmpMessages = [];
    for (let i = 0; i < goalTypes.length; i++) {
      let accordionTitle = '';
      if (goalTypes[i].goalType == PerfCareMacroGoalTypes.TEAM || goalTypes[i].goalType == PerfCareMacroGoalTypes.COMMERCIAL) {
        accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals + ' a ' + this.translate.instant("goalSetting.updatePMGoal." + goalTypes[i].consuntivazione);
      } else if (goalTypes[i].goalType == PerfCareMacroGoalTypes.OPERATIONS) {
        accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + ' - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals;
      } else {
        if (goalTypes[i].maxNumberOfGoals) {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '% - ' + this.translate.instant("goal.accordionTitles.MAX") + goalTypes[i].maxNumberOfGoals;
        } else {
          accordionTitle = this.translate.instant("goal.accordionTitles." + goalTypes[i].goalType) + goalTypes[i].weight + '%';
        }
      }
      if (goalTypes[i].goalType == PerfCareMacroGoalTypes.INDIVIDUAL) {
        this.maxZeroWeightedGoals = goalTypes[i].maxZeroWeightedGoals || 0;
      }
      if (goalTypes[i].weight && goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          message: this.translate.instant("goal.messages.WEIGHT_INFO_1") + ' ' + goalTypes[i].weight + '% ' +
            this.translate.instant("goal.messages.WEIGHT_INFO_2") + ' ' + goalTypes[i].maxNumberOfGoals + ' ' +
            this.translate.instant("goal.messages.WEIGHT_INFO_3"),
          weight: goalTypes[i].weight,
          maxNumberOfGoals: goalTypes[i].maxNumberOfGoals
        })
      } else if (!goalTypes[i].weight && goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          message: this.translate.instant("goal.messages.WEIGHT_DEV_1") + ' ' + goalTypes[i].maxNumberOfGoals + ' ' +
            this.translate.instant("goal.messages.WEIGHT_DEV_2"),
          maxNumberOfGoals: goalTypes[i].maxNumberOfGoals
        })
      } else if (goalTypes[i].weight && !goalTypes[i].maxNumberOfGoals) {
        tmpMessages.push({
          type: goalTypes[i].goalType,
          accordionTitle: accordionTitle,
          weight: goalTypes[i].weight
        })
      }
    }
    return tmpMessages;
  }


  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab() {
    this.isLoadingTab = true;
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    this.getUserProfileTab$ = this.managerService.getObjectiveWeightsByProfile(this.userId, this.selectedYear.id)
      .subscribe((tabList: SenecaResponse<any>) => {
        if (tabList && (tabList.error || !tabList.response)) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(tabList && tabList.error);
          }
        } else {
          this.contentTabList = []
          if (this.draftGoalList && this.draftGoalList.length > 0) {
            this.contentTabList.push({
              id: 'DRAFT_INDIVIDUAL_TAB',
              title: this.translate.instant('goal.tabs.DRAFT_INDIVIDUAL_TAB'),
              attrAriaLabel: this.translate.instant('goal.tabs.DRAFT_INDIVIDUAL_TAB'),
              isDisabled: false,
              isEditObjective: true
            })
          }
          // se ho esattamente una tab (non è sti)
          // if (tabList.response && tabList.response.length <= 1) {
          //   let title = this.translate.instant('goal.tabs.CURRENT_INDIVIDUAL_TAB');
          //   let goalTypes = this.getGoalTypes(tabList.response[0].goalTypes);
          //   // recupero il titolo dell'accordion anche per la prima tab
          //   this.editObjectiveAccordionTitle = goalTypes[0].accordionTitle || '';
          //   this.contentTabList.push({
          //     id: tabList.response[0].tabName,
          //     title:s title,
          //     attrAriaLabel: title,
          //     goalTypes: goalTypes,
          //     noSti: tabList.response[0].noSti,
          //     isDisabled: true
          //   })
          //   if (this.draftGoalList && this.draftGoalList.length > 0) {
          //     this.contentTabList[0].goalTypes = goalTypes;
          //   }
          //   this.currentContentTab = this.contentTabList[0];
          // } else {
          let isFirst = true;
          if (tabList.response && tabList.response.length) {
            for (let tab of tabList.response) {
              let title = this.translate.instant('goal.tabs.' + tab.tabName);
              let weight = 0;
              for (let i = 0; i < tab.goalTypes.length; i++) {
                weight += tab.goalTypes[i].weight;
              }
              title += ' ' + weight + '%';
              let goalTypes = this.getGoalTypes(tab.goalTypes);
              // Prendo i dati degli obiettivi di ruolo per aggiungerli alla tab di modifica se c'è
              if (this.draftGoalList && this.draftGoalList.length > 0) {
                let individualGoalType = goalTypes.filter((x: any) => x.type == PerfCareMacroGoalTypes.INDIVIDUAL || x.type == PerfCareMacroGoalTypes.OPERATIONS);
                if (individualGoalType && individualGoalType[0] && this.draftGoalList && this.draftGoalList.length > 0) {
                  if (individualGoalType && individualGoalType.length) {
                    title = this.translate.instant('goal.tabs.CURRENT_INDIVIDUAL_TAB');
                    this.editObjectiveAccordionTitle = individualGoalType[0] && individualGoalType[0].accordionTitle || '';
                    this.contentTabList[0].goalTypes = individualGoalType;
                  }
                }
              }

              this.contentTabList.push({
                id: tab.tabName,
                title: title,
                attrAriaLabel: title,
                goalTypes: goalTypes,
                noSti: tab.noSti,
                isDisabled: !(this.draftGoalList && this.draftGoalList.length > 0) && isFirst ? false : true
              })
              isFirst = false;
            }
            this.currentContentTab = this.contentTabList[0];
            if (this.currentContentTab.goalTypes && this.currentContentTab.goalTypes.length) {
              this.currentContentTab.goalTypes = this.currentContentTab.goalTypes.filter((x: any) => {
                return x.type != PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT;
              })
            }
          }
          if (tabList.response && tabList.response[0] && !tabList.response[0].noSti) {
            this.contentTabList.push({
              id: 'STI',
              title: this.translate.instant('goal.tabs.STI'),
              attrAriaLabel: this.translate.instant('goal.tabs.STI'),
              isDisabled: true
            })
          }
          // }
          this.isLoadingPersonDetails = false;
        }
        this.getUserGoalList();
        this.isLoadingTab = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }

  onContentTabChanged(tab: TabDossier) {
    this.isLoadingUserGoals = true;
    this.currentContentTab = tab;
    if (this.currentContentTab.id != 'STI') {
      if (tab.id == 'DRAFT_INDIVIDUAL_TAB') {
        this.getObjectiveOnHold(true);
      } else {
        this.getUserGoalList();
      }
    } else {
      this.getUserWatchedSTIRule();
      this.isLoadingUserGoals = false;
    }
  }

  // Controlla se è possibile condividere gli obiettivi con il subordinato (e li condivide)
  shareObjectives(afterSharing?: boolean) {
    this.isLoadingUserGoals = true;
    if (this.canShareGoals$) {
      this.canShareGoals$.unsubscribe();
    }
    let functionToCall;
    if (afterSharing) {
      this.isAfterSharing = true;
      functionToCall = this.managerService.canShareEditedObjectives(this.selectedYear.id, this.userId);
    } else {
      functionToCall = this.managerService.canShareGoal(this.selectedYear.id, this.userId);
    }
    // Controllo se gli obiettivi possono essere condivisi
    this.canShareGoals$ = functionToCall.subscribe((canShare: any) => {
      if (canShare.error || !canShare.response) {
        this.shareModalContent = {
          image: this.personDetails.chiaveSesso === 'F' ? '/assets/img/modal/error-female.svg' : '/assets/img/modal/error-male.svg',
          title: this.translate.instant('goal.SHARE_OBJECTIVES'),
          subtitle: this.translate.instant('goal.errors.CANT_SHARE') + this.personDetails.forename + ' ' + this.personDetails.surname,
          text: this.translate.instant('goal.errors.CANT_SHARE_INFO_1') + this.personDetails.forename + this.translate.instant('goal.errors.CANT_SHARE_INFO_2')
        }
        this.modalService.open("errorShareModalManagerOperations");
      } else {
        // Se posso condividere gli obiettivi allora apro la modale di conferma
        let description = this.translate.instant('goal.shareModal.DESCRIPTION_1') + this.personDetails.forename + this.translate.instant('goal.shareModal.DESCRIPTION_2');
        description += "<ul>"
        for (let i = 0; i < this.goalList.length; i++) {
          description += "<li>" + this.goalList[i].title + "</li>";
        }
        description += "</ul>";
        this.shareModalContent = {
          image: this.personDetails.chiaveSesso === 'F' ? '/assets/img/section/share-goal-female.svg' : '/assets/img/section/share-goal-male.svg',
          title: this.translate.instant('goal.SHARE_OBJECTIVES'),
          subtitle: this.translate.instant('goal.shareModal.SUBTITLE') + this.personDetails.forename + ' ' + this.personDetails.surname,
          text: description
        }
        this.modalService.open("confirmShareModalManagerOperations");
      }
      this.isLoadingUserGoals = false;
    }, (err?: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "113",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingUserGoals = false;
    }
    );

  }

  confirmShareModal() {
    this.closeShareModal();
    this.isLoadingTab = true;
    let functionToCall;
    if (this.isAfterSharing) {
      functionToCall = this.managerService.afterSharingGoalToSubordinate(this.selectedYear.id, this.userId)
    } else {
      functionToCall = this.managerService.shareGoalToSubordinate(this.selectedYear.id, this.userId);
    }
    functionToCall.subscribe((shared: any) => {
      if (!shared) {
        const messageObj: ApplicationModalMessage = {
          modalId: "114",
          text: this.translate.instant("errors." + shared.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.draftGoalList = [];
        this.isAfterSharing = false;
        this.isLoadingUserGoals = true;
        // se ho condiviso corretamente gli obiettivi aggiorno la lista
        this.getUserProfileTab();
      }
    }, (err?: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "115",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingUserGoals = false;
    }
    );
  }

  onAfterSharingGoalClicked(goal: any) {
    this.router.navigate(['/manager/goalDetails/afterSharing/' + this.userId + '/' + goal.id + '/' + this.selectedYear.id]);
  }

  openCancelEditObjective() {
    this.modalService.open('cancelEditObjective');
  }

  closeCancelEditObjective(confirm?: boolean) {
    this.modalService.close('cancelEditObjective');
    if (confirm) {
      this.isLoadingUserGoals = true;
      this.managerService.cancelGoalAfterSharing(this.selectedYear.id, this.userId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "as001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response) {
            this.draftGoalList = [];
            this.isAfterSharing = false;
            this.getUserProfileTab();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "as002",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUserGoals = false;
          })
    }
  }

  closeShareModal() {
    this.modalService.close("confirmShareModalManagerOperations");
  }

  closeErrorModal() {
    this.modalService.close('errorShareModalManagerOperations');
  }

  // TODO non viene più usata la pagina di dettaglio di un obiettivo, le card hanno tutto
  onGoalDetailClicked(goal: any) {
    this.router.navigate(['/manager/goalDetails/' + this.userId + '/' + goal.id + '/' + this.selectedYear.id]);
  }

  addObjective(afterSharing?: boolean) {
    if (afterSharing && this.canAddObjective()) {
      if (!this.personDetails.isOperation) {
        this.router.navigate(['./manager/createUpdateGoalManager/afterSharing/individual/' + this.userId]);
      } else {
        this.router.navigate(['./manager/createUpdateGoalManager/afterSharing/' + this.userId]);
      }
    } else if (this.canAddObjective()) {
      if (!this.personDetails.isOperation) {
        this.router.navigate(['./manager/createUpdateGoalManager/individual/' + this.userId]);
      } else {
        this.router.navigate(['./manager/createUpdateGoalManager/' + this.userId]);
      }
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "135",
        text: this.translate.instant("errors.MAX_OBJECTIVE_NUMBER"),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  canAddObjective() {
    if (this.currentContentTab && this.currentContentTab.id == 'DRAFT_INDIVIDUAL_TAB' && this.currentContentTab.goalTypes) {
      let max = this.currentContentTab?.goalTypes[0]?.maxNumberOfGoals;
      if (!max) {
        return true;
      } else {
        if (this.draftGoalList.length >= max) {
          return false;
        } else {
          return true;
        }
      }
    } else if (this.currentContentTab && this.currentContentTab.goalTypes && this.currentContentTab.goalTypes.length) {
      let objectiveCount = 0;
      for (let i = 0; i < this.currentContentTab.goalTypes.length; i++) {
        if (this.currentContentTab.goalTypes[i] && this.currentContentTab.goalTypes[i].weight == 100 && !this.currentContentTab.goalTypes[i].maxNumberOfGoals) {
          return true;
        }
        let checkType = this.currentContentTab.goalTypes && this.currentContentTab.goalTypes[i] && this.currentContentTab.goalTypes[i].type || 'none';
        let max = this.currentContentTab.goalTypes && this.currentContentTab.goalTypes[i] && this.currentContentTab.goalTypes[i].maxNumberOfGoals || 0;
        if (checkType === 'none') {
          return false;
        } else {
          objectiveCount = this.goalList.filter((x: any) =>
            x.type == checkType
          ).length;
          if (objectiveCount >= max) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  }

  // Apre modale indicatori info
  openModalInfoIndicators() {
    let message = this.currentContentTab.goalTypes && this.currentContentTab.goalTypes[0] && this.currentContentTab.goalTypes[0].message;
    const messageObj: ApplicationModalMessage = {
      modalId: "134",
      title: 'Lorem ipsum',
      text: message
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  }

  // Recupera il colloquio dell'utente
  getUserInterview() {
    if (this.getUserInterview$) {
      this.getUserInterview$.unsubscribe();
    }
    this.getUserInterview$ = this.managerService.getClosestInterviewByUserId(this.selectedYear.id.toString(), this.userId)
      // ListUserFeedbackDialogueForManager aggiungere commonclasses appena c'è
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "116",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.interview = data.response;
          if ((this.interview && this.interview.creationUserId) == this.loggedUser.userId) {
            this.interview.isEditabled = true;
          }
          this.isLoadingPersonDetails = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "117",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      }
      );
  }

  // Vai a annullare il colloquio
  goToDeleteInterview(interviewId: string) {
    this.interviewIdToDelete = interviewId;
    this.openModalDeleteInterview();
  }

  // Apre la modale per annullare il colloquio
  openModalDeleteInterview() {
    this.modalService.open('deleteInterviewModal');
  }

  // Apre la modale per annullare il colloquio
  closeModalDeleteInterview() {
    this.modalService.close('deleteInterviewModal');
    this.interviewIdToDelete = '';
  }

  // Annulla il colloquio
  deleteInterview() {
    this.isFetchingDeleteInterview = true;
    if (this.isInterview) {
      this.isLoadingInterviewsList = true;
    }
    let interviewIdToDelete = this.interviewIdToDelete;
    this.closeModalDeleteInterview();
    if (this.deleteInterview$) {
      this.deleteInterview$.unsubscribe();
    }
    this.deleteInterview$ = this.managerService.deleteInterview(this.selectedYear.id.toString(), interviewIdToDelete)
      .subscribe((data: SenecaResponse<CancelUserFeedbackDialogueForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "065",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "602",
            text: this.translate.instant("goalSetting.organizeInterview.DELETED_INTERVIEW_CORRECTLY_TEXT"),
            title: this.translate.instant("goalSetting.organizeInterview.DELETED_INTERVIEW_CORRECTLY")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.getUserInterview();
          if (this.isInterview) {
            this.getInterviewsList();
          }
        }
        this.isFetchingDeleteInterview = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "066",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingDeleteInterview = false;
      }
      );
  }

  // click opzione menu tag competenza
  onCompetenceOptionsClicked(menuItem: any, competence: any) {
    this.competenceForModal = competence;
    if (menuItem.id == 'detail') {
      if (this.competenceForModal && this.competenceForModal.managerComment) {
        this.competenceFeedback = this.competenceForModal.managerComment;
        this.competenceFeedbackChars = this.maxTextAreaChars - this.competenceFeedback.length;
      } else {
        this.competenceFeedback = '';
        this.competenceFeedbackChars = this.maxTextAreaChars;
      }
      this.openCompetenceDetailModal();
    }
  }



  openCompetenceDetailModal() {
    this.modalService.open('competenceDetails')
  }

  // Text area modale feedback competenza
  competenceFeedbackChanged(newText: string) {
    this.competenceFeedback = newText;
    this.competenceFeedbackChars = this.maxTextAreaChars - newText.length;
  }

  sendCompetenceFeedback() {
    if (this.sendCompetenceFeedback$) {
      this.sendCompetenceFeedback$.unsubscribe();
    }
    this.sendCompetenceFeedback$ = this.managerService.setUserCareCompetenceFeedback(this.selectedYear.id.toString(), this.userId, this.competenceFeedback, this.competenceForModal.id)
      .subscribe((feedbackResponse: SenecaResponse<any>) => {
        if (feedbackResponse && feedbackResponse.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm002",
            text: this.translate.instant("errors." + feedbackResponse.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.closeCompetenceDetailsModal();
          this.getUserGoalList();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "opm003",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  closeCompetenceDetailsModal() {
    this.modalService.close('competenceDetails');
    this.competenceFeedback = '';
    this.competenceFeedbackChars = this.maxTextAreaChars;
  }

  // ritorna la lista di applausi inviati e ricevuti dall'utente
  getFeedbackClapList() {
    this.isLoadingFeedbackClaps = true;
    if (this.getFeedbackClapList$) {
      this.getFeedbackClapList$.unsubscribe();
    }
    this.feedbackClapList = [];
    this.getFeedbackClapList$ = this.managerService.getClapList(this.selectedYear.id, [this.userId], this.isReceiving, !this.isReceiving).
      subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackClapList = data.response;
        }
        this.isLoadingFeedbackClaps = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c002",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackClaps = false;
      });
  }

  // Invia applauso
  sendClap() {
    this.redirectService.goToSendClapPageManager(this.userId, '', true);
  }

  // Vai a modificare l'applauso
  goToEditClap(id: string) {
    this.redirectService.goToSendClapPageManager(this.userId, id, true);
  }

  goToDeleteClap(id: string) {
    this.clapIdToDelete = id;
    this.openDeleteClapModal();
  }

  openDeleteClapModal() {
    this.modalService.open('deleteClapModal');
  }

  closeDeleteClapModal() {
    this.clapIdToDelete = '';
    this.modalService.close('deleteClapModal');
  }

  // Elimina applauso
  deleteClap() {
    this.isLoadingFeedbackClaps = true;
    this.managerService.deleteClap(this.clapIdToDelete, this.userId, this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.closeDeleteClapModal();
        this.getFeedbackClapList();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedbackClaps = false;
      });
  }

  changeApplauseTab(isReceived: boolean) {
    this.isReceiving = isReceived;
    this.getFeedbackClapList();
  }
  goToInterview(id?: string) {
    if (!id) {
      this.router.navigate(['./manager/organizeInterview/' + this.userId]);
    } else {
      this.router.navigate(['./manager/organizeInterview/' + this.userId + '/' + id]);
    }
  }

  // Quando cambi bottone nell'header dossier
  changeButtonHeaderDossier(id: string) {
    switch (id) {
      case 'objectives':
        this.redirectService.goToPersonDetails(this.userId, this.selectedYear.id);
        break;
      case 'individualPlan':
        this.redirectService.goToPersonDetailsDevelopmentPlan(this.userId, this.selectedYear.id);
        break;
      case 'feedback':
        this.redirectService.goToPersonDetailsFeedback(this.userId, this.selectedYear.id);
        break;
    }
  }

  // Recupera le azioni di sviluppo
  getDevelopmentActions() {
    this.isLoadingUserDevelopmentActions = true;
    if (this.getDevelopmentActions$) {
      this.getDevelopmentActions$.unsubscribe();
    }

    this.getDevelopmentActions$ = this.managerService.getDevelopmentActions(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<ListDevelopmentActionForManagerResponse>) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // QUAAAAAAA
          this.developmentActionsList = ActionUtils.formatActionList(data.response, this.loggedUser, true, this.translate);
        }
        this.isLoadingUserDevelopmentActions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserDevelopmentActions = false;
      }
      );
  }

  // Aggiungi un azione di sviluppo
  addDevelopmentAction() {
    if (this.canCreateDevActions) {
      this.router.navigate(['./manager/createUpdateDevelopmentAction/' + this.userId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "603",
        title: this.translate.instant("developmentPlan.ADD_DEVELOPMENT_PLAN"),
        subtitle: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION"),
        text: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION_TEXT"),
        confirmLabel: this.translate.instant("generic.CLOSE")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }

  // Chiudi modale per la cancellazione dell'azione di sviluppo
  closeDeleteModalDevelopAction() {
    this.modalService.close("deleteDevelopmentAction");
    this.developActionIdToDelete = '';
  }

  // Apri modale per la cancellazione dell'azione di sviluppo
  openDeleteModalDevelopAction(developActionIdToDelete: string) {
    this.developActionIdToDelete = developActionIdToDelete;
    this.modalService.open("deleteDevelopmentAction");
  }

  // Cancella azione di sviluppo
  deleteDevelopAction() {

    let idToDelete = this.developActionIdToDelete;
    this.closeDeleteModalDevelopAction();
    this.isLoadingDeleteDevelopAction = true;
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }

    this.deleteDevelopAction$ = this.managerService.deleteDevelopmentActionForManager(this.selectedYear.id.toString(), idToDelete, this.userId)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.getDevelopmentActions();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingDeleteDevelopAction = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDeleteDevelopAction = false;
      }
      );
  }

  // Vai ad aggiornare l'azione di sviluppo
  goToEditDevelopmentAction(developmentAction: any) {
    this.router.navigate(['manager/createUpdateDevelopmentActionEdit/' + this.personDetails.userId + '/' + developmentAction.id]);
  }

  // funzioni commento azioni

  // Mostra commento azione di sviluppo
  showComment(object: any) {
    let objectModal: ApplicationModalMessage = {
      modalId: "d031",
      title: this.translate.instant("developmentPlan.DEVELOP_ACTION_COMMENT"),
      subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + moment(object.creationDate).format('DD/MM/YYYY'),
      text: object.comment
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: objectModal }));
  }


  // Apre la modale per cancellare il commento dell'azione di sviluppo
  openDeleteCommentModalDevelopAction(actionObject: any) {
    this.actionForCommentDelete = actionObject.action;
    this.commentIdToDelete = actionObject.commentId;
    this.modalService.open('deleteDevelopActionCommentModal');
  }

  // Chiude la modale per cancellare il commento dell'azione di sviluppo
  closeDeleteCommentModalDevelopAction() {
    this.modalService.close('deleteDevelopActionCommentModal');
  }

  // Cancella commento dell'azione di sviluppo
  deleteCommentDevelopAction() {
    this.isLoadingUserDevelopmentActions = true;
    this.closeDeleteCommentModalDevelopAction();
    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.managerService.deleteCommentToDevelopPlan(this.selectedYear.id.toString(), this.actionForCommentDelete.id, this.commentIdToDelete, this.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d033",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d034",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getDevelopmentActions();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d035",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getDevelopmentActions();
      });
  }
  // Apre modale per aggiungere il commento all'azione di sviluppo
  openInsertCommentToDevelopAction(developAction: any) {
    this.developAction = developAction;
    this.newActionComment = '';
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + developAction.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      developAction.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + developAction.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + developAction.toStartDate + '</p>';
    this.modalService.open('insertCommentDevelopmentPlanModal');
  }

  // Aggiungi il commento all'azione di sviluppo
  addCommentToDevelopAction() {
    this.isLoadingUserDevelopmentActions = true;
    this.closeInserCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.managerService.addCommentToDevelopPlan(this.selectedYear.id.toString(), this.userId, this.developAction.id, this.newActionComment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d024",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d025",
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT_CORRECTLY"),
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.newActionComment = '';
        this.developAction = null;
        this.getDevelopmentActions();
      }, (err?: any) => {
        this.newActionComment = '';
        const messageObj: ApplicationModalMessage = {
          modalId: "d026",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.newActionComment = '';
        this.developAction = null;
        this.getDevelopmentActions();
      });
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeInserCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('insertCommentDevelopmentPlanModal');
    if (cancel) {
      this.newActionComment = '';
      this.developAction = null;
    }
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionCommentChanged(text: string) {
    this.newActionComment = text;
  }

  // Apre modale per modificare il commento all'azione di sviluppo
  openEditCommentToDevelopAction(actionObject: any) {
    this.developActionEdit = actionObject.action;
    this.commentToEdit = actionObject.comment;
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + this.developActionEdit.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      this.developActionEdit.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + this.developActionEdit.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + this.developActionEdit.toStartDate + '</p>';
    this.modalService.open('editCommentDevelopmentPlanModal');
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeEditCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('editCommentDevelopmentPlanModal');
    if (cancel) {
      this.developActionEdit = null;
      this.commentToEdit = null;
    }
  }

  // Aggiungi il commento all'azione di sviluppo
  updateCommentForDevelopAction() {
    this.isLoadingUserDevelopmentActions = true;
    this.closeEditCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.managerService.updateCommentForDevelopPlan(this.selectedYear.id.toString(), this.userId, this.developActionEdit.id, this.commentToEdit.commentId, this.commentToEdit.comment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d036",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d037",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED_DESCR"),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getDevelopmentActions();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d038",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getDevelopmentActions();
      });
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionEditCommentChanged(text: string) {
    this.commentToEdit.comment = text;
  }

  // Apre la modale per condividere le azioni di sviluppo
  openDevelopActionsShareModal() {
    this.modalService.open('developActionsShareModal');
  }

  // Chiude la modale per condividere le azioni di sviluppo
  closeDevelopActionsShareModal() {
    this.modalService.close('developActionsShareModal');
  }

  // Condividi le azioni di sviluppo
  shareDevelopActions() {
    this.closeDevelopActionsShareModal();
    this.isLoadingUserDevelopmentActions = true;
    if (this.shareDevelopActions$) {
      this.shareDevelopActions$.unsubscribe();
    }

    this.shareDevelopActions$ = this.managerService.shareDevelopActions(this.selectedYear.id.toString(), this.userId)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.canShareActions();
        this.getDevelopmentActions();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserDevelopmentActions = false;
      }
      );
  }


  goalId(index: number, el: any) {
    return el.id;
  }

  competenceTagId(index: number, el: any) {
    return el.tagId;
  }


  getPrivateNotesList() {
    this.isLoadingPrivateNotes = true;
    this.privateNotesList = [];

    if (this.getPrivateNotesList$) {
      this.getPrivateNotesList$.unsubscribe();
    }
    this.privateNotesList = [];
    this.getPrivateNotesList$ = this.managerService.getPrivateNotesList(this.userId, this.selectedYear.id)
      .subscribe((data: SenecaResponse<ListPrivateNoteFeedbackForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          for (let i = 0; i < data.response.length; i++) {
            this.privateNotesList.push({
              noteId: data.response[i].feedbackId,
              comment: data.response[i].comment,
              motivation: data.response[i].motivation,
              formattedCreationDate: moment(data.response[i].creationDate).fromNow()
            })
          }
        }
        this.isLoadingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPrivateNotes = false;
        })
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.managerService.deletePrivateNote(this.noteIdToDelete, this.userId)
        .subscribe((data: SenecaResponse<DeletePrivateNoteFeedbackForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getPrivateNotesList();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNoteForManager(this.personDetails.userId, noteId, true);
  }


  createPrivateNote() {
    this.redirectService.goToCreatePrivateNoteForManager(this.personDetails.userId, '', true);
  }

  // Tab colloqui feedback
  getInterviewsList() {
    this.isLoadingInterviewsList = true;
    if (this.getInterviewsList$) {
      this.getInterviewsList$.unsubscribe();
    }
    this.getInterviewsList$ = this.managerService.getInterviewsByUserId(this.selectedYear.id.toString(), this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          this.interviewList = [];
          if (data.response.length) {
            this.translate.get([
              "interview.card.FROM",
              "interview.card.TO"
            ])
              .subscribe((translations) => {
                // this.interviewList = data.response;
                for (let i = 0; i < data.response.length; i++) {
                  let tmp = data.response[i];
                  let when = moment(tmp.startDate).format('DD/MM/YYYY') + ' ' + translations["interview.card.FROM"] + ' ' + moment(tmp.startDate).format('HH:mm') + ' ' + translations["interview.card.TO"] + ' ' + moment(tmp.endDate).format('HH:mm');
                  this.interviewList.push({
                    feedbackDialogueId: tmp.feedbackDialogueId,
                    formattedCreationDate: moment(tmp.creationDate).fromNow(),
                    status: tmp.feedbackDialogueStatus,
                    when: when,
                    where: tmp.link,
                    argument: tmp.argument,
                    user: tmp.creationUserId == this.userId ? this.personDetails : this.loggedUser,
                    canCancel: tmp.creationUserId == this.loggedUser.userId ? true : false,
                    isPlaceLink: tmp.type === PerfCareFeedbackDialogueTypes.ONLINE || tmp.type === PerfCareFeedbackDialogueTypes.MS_TEAMS,
                    type: tmp.type
                  })
                }
              })
          }
        }
        this.isLoadingInterviewsList = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingInterviewsList = false;
        })
  }

  // Peer feedback
  getPeerFeedbackList() {
    this.isLoadingPeerFeedback = true;
    this.peerFeedbackList = [];
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }

    this.getPeerFeedbackList$ = this.managerService.listPeerFeedback(this.selectedYear.id, this.userId, true, true)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.peerFeedbackList = [];
          if (data.response && data.response.length) {
            for (let i = 0; i < data.response.length; i++) {
              this.peerFeedbackList.push({
                ...data.response[i],
                formattedCreationDate: moment(data.response[i].creationDate).fromNow()
              });
            }
          }
        }
        this.isLoadingPeerFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPeerFeedback = false;
        })
  }

  goToPeerFeedbackAnswer(feedbackId: string) {
    this.redirectService.goToCreatePeerFeedbackManager(this.userId, feedbackId, true);
  }

  openDeletePeerFeedbackModal(id: string) {
    this.peerFeedbackToDelete = id;
    this.modalService.open('deletePeerFeedback');
  }

  closeDeletePeerFeedbackModal(confirm?: boolean) {
    this.modalService.close('deletePeerFeedback');
    if (confirm) {
      this.isLoadingPeerFeedback = true;
      if (this.deletePeerFeedback$) {
        this.deletePeerFeedback$.unsubscribe();
      }
      this.deletePeerFeedback$ = this.managerService.deletePeerFeedback(this.selectedYear.id, this.userId, this.peerFeedbackToDelete)
        .subscribe(
          (data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pf021",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else if (data.response) {
              const messageObj: ApplicationModalMessage = {
                modalId: "d028",
                title: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED"),
                text: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED_DESCR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "d029",
                title: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_ERROR"),
                text: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_TEXT_ERROR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getPeerFeedbackList();
          },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf022",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingPeerFeedback = false;
          }
        )
    }
    this.peerFeedbackToDelete = '';
  }

  openShowFeedbackRequest(feedback: any) {
    this.feedbackForRequestModal = feedback;
    this.modalService.open('modalShowRequest')
  }

  changePeerTab(isSent: boolean) {
    this.peerSent = isSent;
    this.getPeerFeedbackList();
  }

  closeShowFeedbackRequest() {
    this.modalService.close('modalShowRequest')
    this.feedbackForRequestModal = null;
  }

  // Ritorna un booleano per sapere se l'utente ha già visto il regolamento STI
  getUserWatchedSTIRule() {

    this.isLoadingPersonWatchedSTI = true;
    if (this.getUserWatchedSTIRule$) {
      this.getUserWatchedSTIRule$.unsubscribe();
    }
    this.getUserWatchedSTIRule$ = this.managerService.getUserWatchedSTIRule(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "116",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          this.userWatchedSTI = true;
        } else {
          this.userWatchedSTI = false;
        }
        this.isLoadingPersonWatchedSTI = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "117",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPersonWatchedSTI = false;
      }
      );
  }

  definePerformanceLevel() {
    this.router.navigate(['/manager/definePerformanceLevel/', this.selectedYear.id, this.userId]);
  }

  isInFinalEvaluationPhase() {
    if (this.runningPhase.phaseKey == 'FINAL_EVALUATION') {
      let startDate = moment(this.runningPhase.phaseStartDateForManager).format();
      let endDate = moment(this.runningPhase.phaseStartDateForManager).format();
      let today = moment().format();
      if (moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)) {
        return true;
      }
    }
    return false;
  }

  openFinalEvaluationModal(evaluation: any, final: boolean, isUser?: boolean, isManager?: boolean) {
    this.finalEvaluationForModal = {};
    if (!isUser && !isManager) {
      this.finalEvaluationForModal = evaluation;
    } else {
      this.finalEvaluationForModal.final = true;
      if (isUser) {
        this.finalEvaluationForModal.user = this.personDetails;
        this.finalEvaluationForModal.evaluationScore = this.userFinalEvaluation;
        this.finalEvaluationForModal.observation = this.userFinalEvaluationObservation;
        this.finalEvaluationForModal.creationDate = this.userFinalEvaluationDate;
      } else if (isManager) {
        this.finalEvaluationForModal.isManager = true;
        this.finalEvaluationForModal.user = this.loggedUser;
        this.finalEvaluationForModal.evaluationScore = this.managerFinalEvaluation;
        this.finalEvaluationForModal.observation = this.managerFinalEvaluationObservation;
        this.finalEvaluationForModal.creationDate = this.userFinalEvaluationDate;
      }
    }
    this.modalService.open('finalEvaluationModal');
  }

  closeFinalEvaluationModal() {
    this.modalService.close('finalEvaluationModal');
    this.finalEvaluationForModal = null;
  }

  getUserFinalEvaluation() {
    this.isLoadingUserFinalEvaluation = true;
    if (this.getUserFinalEvaluation$) {
      this.getUserFinalEvaluation$.unsubscribe();
    }
    this.getUserFinalEvaluation$ = this.managerService.getUserFinalEvaluation(this.selectedYear.id, this.userId)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.performanceLevelActive = false;
          this.performanceLevelDone = false;
          this.userFinalEvaluation = '';
          this.userFinalEvaluationObservation = '';
          this.userFinalEvaluationDate = '';
          this.managerFinalEvaluation = '';
          this.managerFinalEvaluationObservation = '';
          this.payoutChosen = '';
          if (feData.response) {
            this.performanceLevelActive = true;
            this.performanceLevelStatus = {
              statusMessage: this.translate.instant('performance.statuses.TO_DEFINE'),
              status: 'toStart'
            }
            this.userFinalEvaluationDate = feData.response.creationDate;
            if (feData.response.subordinateObservationLevel) {
              this.userFinalEvaluation = feData.response.subordinateObservationLevel;
              this.userFinalEvaluationObservation = feData.response.subordinateObservation;
            }
            if (feData.response.managerObservationLevel) {
              this.performanceLevelDone = true;
              this.managerFinalEvaluation = feData.response.managerObservationLevel;
              this.managerFinalEvaluationObservation = feData.response.managerObservation;
              this.payoutChosen = feData.response.payout;
              this.performanceLevelStatus = {
                statusMessage: this.translations['performance.statuses.TO_CONFIRM'],
                status: 'inProgress'
              }
            }
            let calibrationStatuses = [PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_IN_CALIBRATION, PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED, PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_BY_MANAGER];

            if (calibrationStatuses.includes(feData.response.finalEvaluationStatus)) {
              this.calibrationActive = true;
              let completeStatuses = [PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_COMPLETED, PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_BY_MANAGER]
              this.postCalibrationData.isComplete = completeStatuses.includes(feData.response.finalEvaluationStatus);
              this.postCalibrationData.status = {
                statusMessage: completeStatuses.includes(feData.response.finalEvaluationStatus) ? this.translations['performance.statuses.COMPLETED'] : this.translations['performance.statuses.TO_CONFIRM'],
                status: completeStatuses.includes(feData.response.finalEvaluationStatus) ? 'completed' : 'inProgress'
              };
              if (feData.response.finalEvaluationStatus == PerfCareFinalEvaluationRentStatuses.FINAL_EVALUATION_CONFIRMED_IN_CALIBRATION) {
                this.postCalibrationData.status.statusMessage = this.translations["calibration.statuses.TO_CONFIRM"]
              }
              this.postCalibrationData.level = feData.response.managerObservationLevel;
              this.postCalibrationData.observations = feData.response.managerObservation;
              if (feData.response.payout >= 0) {
                this.postCalibrationData.payout = {
                  id: feData.response.payout,
                  title: feData.response.payout + '%'
                }
              }

              if (parseInt(feData.response.importedPayoutAmount) >= 0) {
                this.postCalibrationData.stiAmount = feData.response.importedPayoutAmount;
              }

              if (calibrationStatuses.includes(feData.response.finalEvaluationStatus)) {
                this.getPostCalibrationData();
              }
            }
            if (parseInt(feData.response.importedPayoutAmount) >= 0) {
              this.postCalibrationData.stiAmount = feData.response.importedPayoutAmount;
            }
            // se chief e concluso senza dati o senza dati e basta
            if (this.isProcessClosed && parseInt(feData.response.importedPayoutAmount) >= 0) {
              this.calibrationActive = true;
              this.postCalibrationData.isComplete = true;
              this.postCalibrationData.status = {
                statusMessage: this.translations['performance.statuses.COMPLETED'],
                status: 'completed'
              };
            }
          }
          this.getObjectiveOnHold();
        }
        this.isLoadingUserFinalEvaluation = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserFinalEvaluation = false;
      });
  }


  getSuggestedFinalEvaluation() {
    this.managerService.getSuggestedFinalEvaluation(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response && data.response.finalEvaluation) {
            this.suggestedFinalEvaluation = data.response.finalEvaluation;
            this.suggestedPayout = data.response.moreOrEqualPayout;
          }
          this.getUserFinalEvaluation();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserFinalEvaluation = false;
      });
  }

  resetFinalEvaluationData() {
    this.performanceLevelActive = false;
    this.performanceLevelDone = false;
    this.userFinalEvaluation = '';
    this.userFinalEvaluationObservation = '';
    this.userFinalEvaluationDate = '';
    this.managerFinalEvaluation = '';
    this.managerFinalEvaluationObservation = '';
    this.payoutChosen = '';
    this.suggestedFinalEvaluation = null;
    this.suggestedPayout = null;
    this.postCalibrationData = {};
    this.calibrationActive = false;
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.roleStiDescr = this.translations['goal.STI_RULE_CONTENT_1'] + this.selectedYear.id + this.translations['goal.STI_RULE_CONTENT_2'];
    this.router.navigate([url + '/' + this.selectedYear.id])
    this.initializeData();
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.managerService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          let keys = data.response.map((x) => x.assessmentYear);
          keys = keys.sort();
          for (let i = (keys.length - 1); i >= 0; i--) {
            let yearData = data.response.find((x) => x.assessmentYear == keys[i])
            this.yearList.push({
              id: yearData.assessmentYear,
              name: 'Anno ' + yearData.assessmentYear,
              disabled: !yearData.isInProcess
            });
          }
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  goToPostCalibration() {
    this.router.navigate(['manager/postCalibrationEvaluation/' + this.selectedYear.id + '/' + this.userId]);
  }

  openPerformanceLevelModal() {
    this.modalService.open('performance-level-modal');
  }

  closerPerformanceLevelModal() {
    this.modalService.close('performance-level-modal');
  }

  getPostCalibrationData() {
    this.isLoadingUserFinalEvaluation = true;
    if (this.getPostCalibrationData$) {
      this.getPostCalibrationData$.unsubscribe();
    }
    this.getPostCalibrationData$ = this.managerService.getCalibrationDataForManager(this.selectedYear.id, this.userId)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error || !feData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          if (feData.response.finalEvaluation) {
            let managerCalibration = feData.response.finalEvaluation;
            this.postCalibrationData.observations = managerCalibration.managerObservation
            this.postCalibrationData.date = managerCalibration.creationDate;
            if (!!managerCalibration.importedPayoutAmount) {
              this.postCalibrationData.stiAmount = managerCalibration.importedPayoutAmount;
            }
          }

          if (feData.response.calibration) {
            let calibration = feData.response.calibration;
            this.postCalibrationData.level = calibration.adminObservationLevel;
          }
          if (parseInt(feData.response.calibration.payout) >= 0) {
            this.postCalibrationData.payout = {
              id: feData.response.calibration.payout,
              title: feData.response.calibration.payout + '%'
            };
          } else {
            if (parseInt(feData.response.finalEvaluation.payout) >= 0) {
              this.postCalibrationData.payout = {
                id: feData.response.finalEvaluation.payout,
                title: feData.response.finalEvaluation.payout + '%'
              };
            }
          }

          this.isLoadingUserFinalEvaluation = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserFinalEvaluation = false;
      });
  }


  checkProcessClosed() {
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    this.isProcessClosed$ = this.managerService.isProcessClosed(this.selectedYear.id)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.response) {
          this.isProcessClosed = true;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pc003",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserFinalEvaluation = false;
      });
  }

  initializeData() {
    this.getGoalSettingProposalStatus();
    if (this.isObjectiveSection) {
      this.resetFinalEvaluationData();
      if (this.stiAmount) {
        this.getSuggestedFinalEvaluation();
      } else {
        this.getUserFinalEvaluation()
      }
    } else if (this.isFeedbackSection) {
      this.getFeedbackTabsCounters();
    } else if (this.isIndividualPlanSection) {
      this.canShareActions();
      this.canAddActions();
      this.getDevelopmentActions();
    }
  }

  // Nuove funzioni flusso centrato collaboratore
  getGoalSettingText() {
    if (this.proposalStatus && this.proposalStatus.status == 'PROPOSED') {
      return '<b>' + this.personDetails.surname + ' ' + this.personDetails.forename + '</b> ' + this.translate.instant('objectiveWizard.manager.ACCORDION_DESCR_SHARED') + '<b>' + moment(this.goalSettingDeadline).format('DD.MM.yyyy') + '</b>';
    } else if (this.proposalStatus && this.proposalStatus.status == 'APPROVED') {
      return this.translate.instant('objectiveWizard.manager.ACCORDION_DESCR_APPROVED');
    } else {
      return this.translate.instant('objectiveWizard.manager.ACCORDION_DESCR_1') + '<b>' + this.personDetails.surname + ' ' + this.personDetails.forename + '</b> ' + this.translate.instant('objectiveWizard.manager.ACCORDION_DESCR_2');
    }
  }

  getGoalSettingProposalStatus() {
    this.isLoadingTab = true;

    if (this.getGoalSettingProposalStatus$) {
      this.getGoalSettingProposalStatus$.unsubscribe();
    }

    this.getGoalSettingProposalStatus$ = this.managerService.getGoalSettingProposalStatus(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '037',
            text: this.translate.instant('errors.' + data.error),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.proposalStatus = data.response;
          // la funzione viene richiamata anche nel feedback e nelle azioni di sviluppo
          // per fare il redirect alla pagina corretta della tab obiettivi
          if (this.isObjectiveSection) {
            if (this.proposalStatus.status != 'TO_PROPOSE') {
              this.getObjectiveOnHold();
              if (this.proposalStatus.status != 'REJECTED') {
                this.getCascadingGoals();
              }
            };
          }
        }
        this.isLoadingTab = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: '039',
            text: this.translate.instant('errors.' + err),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingTab = false;
        });
  }



  openDenySharingModal() {
    this.denyComment = '';
    this.modalService.open("deny-modal");
  }

  getDenyModalDescr() {
    return this.translations["objectiveWizard.manager.modals.DENY_DESCR_1"] + this.personDetails.forename + ' ' + this.personDetails.surname + this.translations["objectiveWizard.manager.modals.DENY_DESCR_2"];
  }

  denyCommentChanged(newString: string) {
    this.denyComment = newString;
  }

  closeDenySharingModal(confirm?: boolean) {
    this.modalService.close("deny-modal");
    if (confirm) {
      this.isLoadingTab = true;

      this.managerService.refuseGoalSettingProrosal(this.selectedYear.id, this.userId, this.denyComment)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: 'deny002',
              title: this.translate.instant('generic.WARNING'),
              text: this.translate.instant('errors.' + data.error)
            };
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.getGoalSettingProposalStatus();
          }
          this.isLoadingTab = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: 'deny003',
              text: this.translate.instant('errors.' + err),
              title: this.translate.instant('generic.WARNING')
            };
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingTab = false;
          });
    }
  }

  openApproveSharingModal() {
    this.approveComment = '';
    this.modalService.open("approve-modal");
  }

  getApproveModalDescr() {
    return this.translations["objectiveWizard.manager.modals.APPROVE_DESCR"] + this.personDetails.forename + ' ' + this.personDetails.surname;
  }

  approveCommentChanged(newString: string) {
    this.approveComment = newString;
  }

  closeApproveSharingModal(confirm?: boolean) {
    this.modalService.close("approve-modal");
    if (confirm) {
      this.isLoadingTab = true;

      this.managerService.acceptGoalSettingProrosal(this.selectedYear.id, this.userId, '')
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: 'appr002',
              title: this.translate.instant('generic.WARNING'),
              text: this.translate.instant('errors.' + data.error)
            };
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.getGoalSettingProposalStatus();
          }
          this.isLoadingTab = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: 'appr003',
              text: this.translate.instant('errors.' + err),
              title: this.translate.instant('generic.WARNING')
            };
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingTab = false;
          });
    }
  }

  // Modale dettaglio obeittivi
  openObjectiveDetails(objective: any) {
    this.objectiveForModal = objective;
    this.modalService.open("objectiveDetails");
  }

  closeObjectiveDetails() {
    this.modalService.close("objectiveDetails");
    this.objectiveForModal = null;
  }

  // controllo se ci sono valutazioni nell'obiettivo
  hasAnyEvaluation() {
    let tmp = false;
    // controllo se esistono
    if (this.objectiveForModal && this.objectiveForModal.goalEvaluation) {
      for (let i = 0; i < this.objectiveForModal.goalEvaluation.length; i++) {
        // Se è un operations potrebbe non avere la valutazione, quindi visualizzo e basta
        if (this.personDetails.isOperation) {
          tmp = true;
          break;
        } else if (this.objectiveForModal.goalEvaluation[i].evaluationScore != null) {
          tmp = true;
          break;
        }
      }
    }
    return tmp;
  }

  onObjectiveOptionClicked(option: any, objective: any) {
    switch (option.id) {
      case 'approve':
        this.router.navigate(['./manager/createUpdateGoalManager/', this.userId, objective.id]);
        break;
      case 'edit':
        if (objective.type.includes('PEOPLE_EMPOWERMENT')) {
          this.router.navigate(['./manager/createUpdateGoalManager/', this.userId, objective.id]);
        } else if (objective.type.includes('OPERATIONS')) {
          this.router.navigate(['./manager/createUpdateGoalManager/', this.userId, objective.id]);
        } else {
          this.router.navigate(['/manager/createOptionalObjectives/', this.selectedYear.id, this.userId, objective.id])
        }
        break;
      case 'delete':
        this.openDeleteObjectiveModal(objective.id);
        break;
      case 'add_evidence':
        this.router.navigate(["/manager/createUpdateEvidence/" + this.userId + '/' + objective.id]);
        break;
      // case 'link':
      //   console.log("TODO link"); funzionalità rimandata
      //   break;
      case 'value':
        this.router.navigate(["/manager/finalEvaluation/" + this.selectedYear.id + '/' + this.userId + '/' + objective.id]);
        break;
      default:
        break;
    }
  }

  isWaitingForProposal() {
    return ['APPROVED', 'PROPOSED'].indexOf(this.proposalStatus?.status) < 0 && (!this.goalList || !this.goalList.length)
  }

  goToAddNewObjective() {
    this.router.navigate(['/manager/createOptionalObjectives/' + this.selectedYear.id + '/' + this.userId])
  }


  getCascadingGoals() {
    this.isLoadingCascadingGoals = true;

    if (this.getCascadingGoals$) {
      this.getCascadingGoals$.unsubscribe();
    }

    this.getCascadingGoals$ = this.managerService.getCascadingGoals(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: 'appr002',
            title: this.translate.instant('generic.WARNING'),
            text: this.translate.instant('errors.' + data.error)
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cascadingGoal = data.response?.cascadingGoal || {};
          if (this.cascadingGoal && this.cascadingGoal.introductionText && this.cascadingGoal.description) {
            this.cascadingGoal.description = this.cascadingGoal.description.replace('\n', '<br>')
            this.cascadingGoal.introductionText = this.cascadingGoal.introductionText.replace('\n', '<br>')
          }
        }
        this.isLoadingCascadingGoals = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: 'appr003',
            text: this.translate.instant('errors.' + err),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCascadingGoals = false;
        });
  }

  // apre la modale degli obiettivi di cascading
  openCascadingModal() {
    this.modalService.open('cascading-modal');
  }

  closeCascadingModal() {
    this.modalService.close('cascading-modal');
  }

  canAddOptionalObjective() {
    let optionalObjectives = this.goalList.filter((obj: any) => !obj.weight && obj.type == PerfCareMacroGoalTypes.INDIVIDUAL);
    if (optionalObjectives.length >= this.maxZeroWeightedGoals) {
      return false;
    }
    return true;
  }


  openDeleteObjectiveModal(id: string) {
    this.deleteId = id;
    this.modalService.open('deleteObjective');
  }

  closeDeleteObjectiveModal(confirm?: boolean) {
    this.modalService.close('deleteObjective');
    if (confirm) {
      this.deleteObjective();
    }
  }

  deleteObjective() {
    this.isLoadingUserGoals = true;

    if (this.deleteObjective$) {
      this.deleteObjective$.unsubscribe();
    }

    this.deleteObjective$ = this.managerService.deleteSubordinateGoal(this.selectedYear.id, this.userId, [this.deleteId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "objw008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUserGoals = false;
        } else {
          this.deleteId = '';
          this.getUserGoalList();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "delobj002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  formatCascadingDescription() {
    if (this.cascadingGoal && (this.cascadingGoal.introductionText || this.cascadingGoal.description)) {
      const intro = this.cascadingGoal.introductionText?.replace(/\n/g, '<br>') || '';
      const descr = this.cascadingGoal.description?.replace(/\n/g, '<br>') || '';
      return intro + '<br><br>' + descr;
    } else {
      return '-';
    }
  }

  formatDate(date: any) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return '--';
    }
  }

  ngOnDestroy(): void {
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.personDetails$) {
      this.personDetails$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.canShareGoals$) {
      this.canShareGoals$.unsubscribe();
    }
    if (this.deleteInterview$) {
      this.deleteInterview$.unsubscribe();
    }
    if (this.getDevelopmentActions$) {
      this.getDevelopmentActions$.unsubscribe();
    }
    if (this.canAddDevelopActions$) {
      this.canAddDevelopActions$.unsubscribe();
    }
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }
    if (this.canShareDevelopActions$) {
      this.canShareDevelopActions$.unsubscribe();
    }
    if (this.deletePeerFeedback$) {
      this.deletePeerFeedback$.unsubscribe();
    }
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }
    if (this.getInterviewsList$) {
      this.getInterviewsList$.unsubscribe();
    }
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }
    if (this.deletePeerFeedback$) {
      this.deletePeerFeedback$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    if (this.getCascadingGoals$) {
      this.getCascadingGoals$.unsubscribe();
    }
    if (this.deleteObjective$) {
      this.deleteObjective$.unsubscribe();
    }
  }
}