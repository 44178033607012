<div class="page-content-wrapper">

  <div class="back-browser-container">
    <europ-button iconLeft="assets/img/icons/arrow-left.svg" [label]="('generic.GO_BACK' | translate)"
      (onButtonClicked)="goToUserList()" type="primary link" size="medium">
    </europ-button>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container>
    <ng-container *ngIf="headerDossier$ | async as header">
      <header-dossier (onMenuButtonClicked)="onTabChange($event)" [user]="header.candidate.user"
        [talentStatus]="header.candidate.interviewStatus" [menuButtons]="header.tabs" [isTalent]="true">
      </header-dossier>
    </ng-container>

    <div class="main-content-wrapper">

      <router-outlet></router-outlet>

    </div>
  </ng-container>

</div>

<modal id="failed-to-retrieve-candidate-details" (onClose)="closeModal()">
  <modal-text-content modalId="talent001" [title]="'errors.SOMETHING_WENT_WRONG' | translate"
    [text]="'errors.TALENT_COULD_NOT_FETCH_USER_DATA' | translate">
  </modal-text-content>
</modal>