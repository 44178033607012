<div class="page-content-wrapper">

  <header-dossier [user]="loggedUser" [isAdminHeader]="true" [adminBack]="true"
    [adminPageTitle]="'dashboard.TITLE' | translate" [hasTabs]="false"
    [adminPageSubtitle]="processYear ? (('monitoring.PERFORMANCE' | translate) + processYear) : ('dashboard.WHAT_PROCESS' | translate)"
    [adminActionLabel]="processYear ? ('dashboard.DOWNLOAD_TOTAL_REPORT' | translate) : ''"
    (onAdminAction)="downloadReport()" [isLoadingAdminAction]="isDownloadingReport">
  </header-dossier>

  <!-- Home processi -->
  <ng-container *ngIf="!processYear">
    <div class="main-content-wrapper admin">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th>{{'adminTable.YEAR' | translate}}</th>
                  <th>{{'adminTable.PROCESS_NAME' | translate}}</th>
                  <th>{{'adminTable.OPEN_CLOSE' | translate}}</th>
                  <th>{{'adminTable.PEOPLE_INVOLVED' | translate}}</th>
                  <th>{{'adminTable.ACTIONS' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                          slice:0:processData.numRecords | 
                          paginate: { id: 'processList',
                                  itemsPerPage: processData.numRecords,
                                  currentPage: processData.page,
                                  totalItems: processData.counter }">
                  <td>{{ process.year }}</td>
                  <td>{{ process.title }}</td>
                  <td>
                    <div class="date">
                      <p>{{ process.startDate ? process.startDate : '--'}}
                      </p>
                      <p>{{ process.endDate ? process.endDate : '--'}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="count">
                      {{ process.usersInGroupCount || 0 }}
                    </div>
                  </td>
                  <td class="link" (click)="goToProcessDetail(process.year)">{{
                    ('adminTable.ACCESS' | translate)}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </europ-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <ng-container *ngIf="processYear">
    <div class="main-content-wrapper">

      <!-- FIltri -->
      <div class="filters-recap">
        <div class="icon-container">
          <svg-icon src="assets/img/icons/dashboard.svg" [applyClass]="true" class="dashboard-icon"></svg-icon>
        </div>
        <!-- Contenuto filtri -->
        <div class="filter-content">
          <div class="title-container">
            <div class="info-title">
              <p class="title">
                {{ ('dashboard.TITLE') | translate }}
              </p>
              <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                (click)="openDashboardTooltip()">
              </svg-icon>
            </div>
          </div>
          <!-- Recap dei filtri attivi -->
          <div class="recap-container">
            <div class="racap-text-row">
              <div class="left-column">
                <ng-container *ngIf="!getSelectedFiltersNumber()">
                  <p class="placeholder" translate="adminSearch.NO_FILTER_ACTIVE"></p>
                </ng-container>
                <ng-container *ngIf="getSelectedFiltersNumber() == 1">
                  <p class="placeholder" [innerHTML]="'adminSearch.ONE_FILTER' | translate"></p>
                </ng-container>
                <ng-container *ngIf="getSelectedFiltersNumber() > 1">
                  <p class="placeholder"><b>{{getSelectedFiltersNumber()}}</b>&nbsp;{{ 'adminSearch.MORE_FILTERS' |
                    translate
                    }}
                  </p>
                </ng-container>
                <ng-container *ngIf="getSelectedFiltersNumber() >= 1">
                  <p class="delete-filters" translate="adminSearch.DELETE_FILTERS" (click)="clearFilters()"></p>
                </ng-container>
                <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
              </div>
              <div class="count-container">
                <p translate="dashboard.MANAGE_FILTERS"></p>
                <svg-icon src="assets/img/icons/chevron-down.svg" *ngIf="!isFilterOpened" [applyClass]="true"
                  class="goto" (click)="openFiltersSection()"></svg-icon>
                <svg-icon src="assets/img/icons/chevron-up.svg" *ngIf="isFilterOpened" [applyClass]="true" class="goto"
                  (click)="openFiltersSection()"></svg-icon>
              </div>
            </div>

            <ng-container *ngIf="isFilterOpened">
              <div class="filter-user-switch-container">
                <p translate="dashboard.USE_FILTER"></p>
                <europ-switch [leftLabelObject]="('calibration.GENERIC_FILTER' | translate)"
                  [rightLabelObject]="('calibration.PERSON_FILTER' | translate)" [checked]="personFilter"
                  (onSwitch)="switchFilterType()">
                </europ-switch>
              </div>
              <!-- switch filtri -->
              <!-- Filtri per struttura, genere ecc-->
              <ng-container *ngIf="!personFilter">
                <div class="filters-container">
                  <div class="filters-content">
                    <div class="generic-container">
                      <!-- Struttura -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureTooltipModal"
                        [isValued]="selectedFilters.structures" [isObbligatory]="true"
                        title="{{'calibration.filters.STRUCTURE' | translate}}">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'structures')"
                          [selectedItem]="selectedFilters.structures" [items]="filtersObject.structures"
                          [searchable]="false" bindLabel="id">
                        </europ-select>
                      </input-container>
                      <!-- Funzione -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="functionTooltipModal"
                        [isValued]="selectedFilters.funzione" [isObbligatory]="true"
                        title="{{'calibration.filters.FUNCTION' | translate}}">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'funzione')"
                          [selectedItem]="selectedFilters.funzione" [items]="filtersObject.funzione" [searchable]="true"
                          bindLabel="id">
                        </europ-select>
                      </input-container>

                    </div>

                    <div class="generic-container">

                      <!-- Mansione -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="jobTooltipModal"
                        [isValued]="selectedFilters.mansione" [isObbligatory]="true"
                        title="{{'calibration.filters.JOB' | translate}}">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'mansione')"
                          [selectedItem]="selectedFilters.mansione" [items]="filtersObject.mansione" [searchable]="true"
                          bindLabel="id">
                        </europ-select>
                      </input-container>

                      <!-- Area -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="areaTooltipModal"
                        [isValued]="selectedFilters.area" [isObbligatory]="true"
                        title="{{'calibration.filters.AREA' | translate}}">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'area')"
                          [selectedItem]="selectedFilters.area" [items]="filtersObject.area" [searchable]="true"
                          bindLabel="id">
                        </europ-select>
                      </input-container>

                    </div>


                    <div class="generic-container">
                      <!-- Struttura 3 livello -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel3TooltipModal"
                        [isValued]="selectedFilters.denominazioneSO3" [isObbligatory]="true"
                        title="{{'calibration.filters.STRUCTURE_LEVEL_3' | translate}}">
                        <europ-select [inMinWidthForIe]="true"
                          (onSelectedItem)="selectFilter($event, 'denominazioneSO3')"
                          [selectedItem]="selectedFilters.denominazioneSO3" [items]="filtersObject.denominazioneSO3"
                          [searchable]="true" bindLabel="id">
                        </europ-select>
                      </input-container>

                      <!-- Struttura 5 livello -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel5TooltipModal"
                        [isValued]="selectedFilters.denominazioneSO5" [isObbligatory]="true"
                        title="{{'calibration.filters.STRUCTURE_LEVEL_5' | translate}}">
                        <europ-select [inMinWidthForIe]="true"
                          (onSelectedItem)="selectFilter($event, 'denominazioneSO5')"
                          [selectedItem]="selectedFilters.denominazioneSO5" [items]="filtersObject.denominazioneSO5"
                          [searchable]="true" bindLabel="id">
                        </europ-select>
                      </input-container>

                    </div>

                    <div class="generic-container">
                      <!-- Sesso -->
                      <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="genderFilterTooltipModal"
                        [isValued]="selectedFilters.chiaveSesso" [isObbligatory]="true"
                        title="{{'calibration.filters.GENDER' | translate}}">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'chiaveSesso')"
                          [selectedItem]="selectedFilters.chiaveSesso" [items]="filtersObject.chiaveSesso"
                          [searchable]="false" bindLabel="id">
                        </europ-select>
                      </input-container>
                    </div>

                    <div class="button-container">
                      <europ-button [label]="'generic.APPLY_FILTERS' | translate" type="primary"
                        (onButtonClicked)="applyFilters()" [disabled]="isLoading()">
                      </europ-button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- Filtra per persona -->
              <ng-container *ngIf="personFilter">
                <div class="form-container">
                  <!-- Contenitore card urenti  -->
                  <input-container [tooltipModalMessage]="whoNoteModal" [isObbligatory]="true"
                    [isValued]="selectedUserList && selectedUserList.length"
                    [title]="'privateNotes.createUpdate.WHO_NOTE' | translate">
                    <!-- ricerca -->
                    <europ-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                      (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                      placeholder="{{'privateNotes.createUpdate.SEARCH_PLACEHOLDER' | translate}}">
                    </europ-input>
                    <p>
                      <span class="text-label-form primary-color"
                        translate="privateNotes.createUpdate.WHO_NOTE_SUB"></span>
                      <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length == 1"
                        class="text-label-form primary-text-color error-color">{{ ' ' +
                        selectedUserList.length + ' ' +
                        ('generic.SELECTED' |
                        translate)}}</span>
                      <span *ngIf="selectedUserList && selectedUserList.length && selectedUserList.length > 1"
                        class="text-label-form primary-text-color error-color">{{ ' ' +
                        selectedUserList.length + ' ' +
                        ('generic.SELECTEDS' |
                        translate)}}</span>
                    </p>
                    <div class="user-card-collection-container">
                      <!-- Card utente -->
                      <div *ngIf="!isFetchingUsers" class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid"
                        fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                        fxLayoutGap.lt-md="16px">
                        <user-card *ngFor="let user of userCardData.list | slice:0:userCardData.numRecords | paginate: { id: 'noteUsers',
                                itemsPerPage: userCardData.numRecords,
                                currentPage: userCardData.currentPage,
                                totalItems: userCardData.counter }" [user]="user" [selectedUserList]="selectedUserList"
                          [isFeedbackCard]="true" (onCheck)="selectUser($event)"></user-card>
                      </div>
                      <div *ngIf="isFetchingUsers" class="full-width min-height500" fxLayout="row wrap"
                        fxLayoutGap="16px grid" fxLayoutAlign="start center" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="16px">
                        <div class="loader spinner-medium"></div>
                      </div>
                      <div *ngIf="!isFetchingUsers && (!userCardData.list || !userCardData.list.length)"
                        class="full-width min-height500" fxLayout="row wrap" fxLayoutGap="16px grid"
                        fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                        fxLayoutGap.lt-md="16px">
                        <p translate="privateNotes.createUpdate.NO_RESULTS"></p>
                      </div>
                      <div class="full-width" *ngIf="!isFetchingUsers && userCardData.list && userCardData.list.length"
                        fxLayout="row" fxLayoutAlign="center center">
                        <table-pagination id="noteUsers" (pageChanged)="usersPageChanged($event)">
                        </table-pagination>
                      </div>
                    </div>

                  </input-container>
                  <div class="button-container">
                    <europ-button [label]="'generic.APPLY_FILTERS' | translate" type="primary"
                      (onButtonClicked)="applyFilters()" [disabled]="isLoading()">
                    </europ-button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="accordion-list">
        <ng-container *ngIf="isProcessClosed">
          <!-- Osservazioni finali -->
          <div class="final-overview-container">
            <!--<div class="title-container">
              <p class="title" translate="dashboard.FINAL_OVERVIEW"></p>
              <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
            </div>-->

            <ng-container *ngIf="!isLoadingOverviewData">
              <dx-chart id="finalChart" [dataSource]="productionData" [palette]="finalPalette"
                (onInitialized)="saveInstance($event, 'finalChart')">
                <dxo-title [text]="'dashboard.FINAL_OVERVIEW' | translate">
                  <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                  </dxo-font>
                  <dxo-subtitle [text]="'dashboard.FINAL_OVERVIEW_DESCR' | translate">
                    <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                    </dxo-font>
                  </dxo-subtitle>
                </dxo-title>
                <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                <dxo-common-series-settings argumentField="status" type="bar" valueField="value">
                </dxo-common-series-settings>
                <dxo-series-template nameField="structure" [customizeSeries]="customizeSeries">
                </dxo-series-template>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                </dxo-legend>
                <dxi-value-axis [visualRange]="[0,100]" [showZero]="true" [tickInterval]="20"
                  [valueMarginsEnabled]="false">
                  <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                </dxi-value-axis>
                <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
              </dx-chart>
            </ng-container>
            <ng-container *ngIf="isLoadingOverviewData">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </ng-container>

        <!-- Goal setting -->
        <percentage-accordion [title]="'dashboard.accordion.GOAL_SETTING' | translate"
          [percentage]="goalSettingPercentage" [isAccordionOpened]="goalSettingOpen"
          [isLoading]="isLoadingGoalSettingData" (onAccordionOpen)="redrawGraphs('goal-setting')">
          <div class="accordion-content">
            <ng-container *ngIf="!isLoadingGoalSettingData">
              <div class="dashboard">

                <!-- Obiettivi individuali -->
                <div class="section">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.INDIVIDUAL_OBJECTIVE"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-circular-gauge id="individualObjective" [value]="individualObjectives.completedPercentage"
                    (onInitialized)="saveInstance($event, 'individualObjective')">
                    <dxo-title [text]="'dashboard.INDIVIDUAL_OBJECTIVE' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.INDIVIDUAL_OBJECTIVE' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                    <dxo-value-indicator type="triangleNeedle" color="#6E6F71">
                    </dxo-value-indicator>
                    <dxo-range-container palette="pastel">
                      <dxi-range [startValue]="0" [endValue]="50" color="#E4002B"></dxi-range>
                      <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
                    </dxo-range-container>
                    <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                    <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                      <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                    </dxo-scale>
                  </dx-circular-gauge>


                  <div class="more-graph-info">
                    <p class="number primary">{{ individualObjectives.toShare}}</p>
                    <p class="text" translate="dashboard.TO_SHARE"></p>
                  </div>
                </div>

                <!-- Care attributes / Attributi care -->
                <div class="section right">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.CARE_ATTRIBUTES"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-chart id="individualObjetiveCare" [dataSource]="individualObjectives.careData"
                    [palette]="careColors" (onInitialized)="saveInstance($event, 'individualObjetiveCare')">
                    <dxo-title [text]="'dashboard.CARE_ATTRIBUTES' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.CARE_ATTRIBUTES_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                    <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                      [ignoreEmptyPoints]="true" [barPadding]="0.5">
                    </dxo-common-series-settings>
                    <dxo-series-template nameField="attribute"></dxo-series-template>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                  </dx-chart>

                  <div class="more-graph-info"></div>
                </div>
              </div>

              <!-- Sfide -->
              <div class="dashboard">
                <div class="section">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.CHALLENGE"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-pie-chart id="challenge" type="doughnut" [dataSource]="challengeObjectives.sharedData"
                    centerTemplate="centerTemplate" [palette]="actionColor"
                    (onInitialized)="saveInstance($event, 'challenge')">
                    <dxo-title [text]="'dashboard.CHALLENGE' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.CHALLENGE_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>

                    <dxi-series argumentField="status" valueField="total">
                      <dxo-small-values-grouping mode="topN" [topCount]="3"></dxo-small-values-grouping>
                      <dxo-label [visible]="false" format="fixedPoint" [customizeText]="customizePercentageText">
                        <dxo-connector [visible]="false"></dxo-connector>
                      </dxo-label>
                    </dxi-series>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipChallenge">
                    </dxo-tooltip>
                    <svg *dxTemplate="let pieChart of 'centerTemplate'">
                      <circle cx="131" cy="131" [attr.r]="22" fill="#8ED2E8"></circle>
                      <text text-anchor="middle" style="font-size: 18px; font-weight: 600;" x="131" y="131" fill="#fff">
                        <tspan x="131" y="137" class="pie-inside-text">{{ challengeObjectives.total }}</tspan>
                      </text>
                    </svg>

                  </dx-pie-chart>

                </div>
                <!-- Care attributes / Attributi care -->
                <div class="section right">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.CARE_ATTRIBUTES"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-chart id="challengeCare" [dataSource]="challengeObjectives.careData" [palette]="careColors"
                    (onInitialized)="saveInstance($event, 'challengeCare')">
                    <dxo-title [text]="'dashboard.CARE_ATTRIBUTES' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle text="Caring, Available, Reliable, Easy to work with">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                    <!--dxo-size [height]="400" [width]="300"></dxo-size-->
                    <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                      [ignoreEmptyPoints]="true" [barPadding]="0.5">
                    </dxo-common-series-settings>
                    <dxo-series-template nameField="attribute"></dxo-series-template>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                  </dx-chart>

                  <div class="more-graph-info"></div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isLoadingGoalSettingData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>






        <!-- Mid term review -->
        <percentage-accordion [title]="'dashboard.accordion.MID_TERM' | translate" [percentage]="midTermPercentage"
          [isAccordionOpened]="midTermOpen" [isLoading]="isLoadingMidTermData"
          (onAccordionOpen)="redrawGraphs('mid-term')" [isDisabled]="!isMidTermActive">
          <div class="accordion-content">

            <ng-container *ngIf="!isLoadingMidTermData">
              <div class="dashboard">
                <!-- Progresso mid term review -->
                <div class="section">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.PROGRESS"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-pie-chart id="midTermProgress" type="doughnut" [dataSource]="midTermData.completeData"
                    centerTemplate="centerTemplate" [palette]="midTermColor"
                    (onInitialized)="saveInstance($event, 'midTermProgress')">
                    <dxo-title [text]="'dashboard.PROGRESS' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.PROGRESS_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>

                    <dxi-series argumentField="status" valueField="total">
                      <dxo-small-values-grouping mode="topN" [topCount]="3"></dxo-small-values-grouping>
                      <dxo-label [visible]="true" format="fixedPoint" [customizeText]="customizePercentageText">
                        <dxo-connector [visible]="true"></dxo-connector>
                      </dxo-label>
                    </dxi-series>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipChallenge">
                    </dxo-tooltip>
                    <svg *dxTemplate="let pieChart of 'centerTemplate'">
                      <circle cx="131" cy="131" [attr.r]="22" fill="#8ED2E8"></circle>
                      <text text-anchor="middle" style="font-size: 18px; font-weight: 600;" x="131" y="131" fill="#fff">
                        <tspan x="131" y="137" class="pie-inside-text">{{ midTermData.total }}</tspan>
                      </text>
                    </svg>

                  </dx-pie-chart>
                </div>

                <!-- card mid term -->
                <div class="section right card-section-height">
                  <div class="title-container">
                    <p class="title"></p>
                  </div>
                  <div class="midterm-card-container">
                    <!-- In ritardo -->
                    <div class="card">
                      <svg-icon src="assets/img/icons/red-face.svg" [applyClass]="true" class="icon"></svg-icon>
                      <div class="text-container">
                        <p translate="midTerm.statuses.LATE"></p>
                        <p class="value">{{ midTermData.lateCount}}</p>
                      </div>
                    </div>
                    <!-- In linea -->
                    <div class="card">
                      <svg-icon src="assets/img/icons/yellow-face.svg" [applyClass]="true" class="icon"></svg-icon>
                      <div class="text-container">
                        <p translate="midTerm.statuses.ON_LINE"></p>
                        <p class="value">{{ midTermData.onLineCount}}</p>
                      </div>
                    </div>
                    <!-- A buon punto -->
                    <div class="card">
                      <svg-icon src="assets/img/icons/green-face.svg" [applyClass]="true" class="icon"></svg-icon>
                      <div class="text-container">
                        <p translate="midTerm.statuses.WELL_ADVANCED"></p>
                        <p class="value">{{ midTermData.advancedCount}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="more-graph-info">
                    <p class="number">{{ midTermData.skipped }}</p>
                    <p class="text" translate="dashboard.USER_MID_SKIP"></p>
                  </div>
                </div>
              </div>

            </ng-container>

            <ng-container *ngIf="isLoadingMidTermData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>




        <!-- Valutazione finale -->
        <percentage-accordion [title]="'dashboard.accordion.FINAL_EVALUATION' | translate"
          [percentage]="finalEvaluationPercentage" [isLoading]="isLoadingFinalEvaluation"
          (onAccordionOpen)="redrawGraphs('final-evaluation')" [isDisabled]="!isFinalEvaluationActive">
          <div class="accordion-content">
            <ng-container *ngIf="!isLoadingFinalEvaluation">
              <!-- Valutazione finale obiettivi -->
              <div class="dashboard big">
                <div class="section">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.OBJECTIVE_FINAL"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-chart id="objectiveFinalEvaluation" [dataSource]="objectiveFEData"
                    (onInitialized)="saveInstance($event, 'objectiveFinalEvaluation')">
                    <dxo-title [text]="'dashboard.OBJECTIVE_FINAL' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.OBJECTIVE_FINAL_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                    <!--<dxo-size [height]="280"></dxo-size>-->
                    <dxi-series valueField="selfObservations" [name]="('dashboard.SELF_OBSERVATIONS' | translate)"
                      color="#F7B435">
                    </dxi-series>
                    <dxi-series valueField="evaluations" [name]="('dashboard.EVALUATIONS' | translate)" color="#88DBFF">
                    </dxi-series>
                    <dxo-common-series-settings argumentField="label" type="bar" [barPadding]="0.5">
                      <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                    </dxo-common-series-settings>
                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                    </dxo-legend>
                    <dxi-value-axis [visualRange]="[0,100]" [showZero]="true" [tickInterval]="20"
                      [valueMarginsEnabled]="false">
                      <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                    </dxi-value-axis>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipLevel">
                    </dxo-tooltip>
                  </dx-chart>


                </div>
              </div>


              <!-- Performance level -->
              <div class="dashboard big">
                <div class="section">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.PERFORMANCE_LEVEL"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->

                  <dx-chart id="performanceLevel" [dataSource]="performanceLevelData"
                    (onInitialized)="saveInstance($event, 'performanceLevel')">
                    <dxo-title [text]="'dashboard.PERFORMANCE_LEVEL' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.PERFORMANCE_LEVEL_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                    <!--<dxo-size [height]="230"></dxo-size>-->
                    <dxi-series valueField="selfObservations" [name]="('dashboard.SELF_OBSERVATIONS' | translate)"
                      color="#F7B435">
                    </dxi-series>
                    <dxi-series valueField="evaluations" [name]="('dashboard.EVALUATIONS' | translate)" color="#88DBFF">
                    </dxi-series>
                    <dxo-common-series-settings argumentField="label" type="bar" [barPadding]="0.5">
                      <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                    </dxo-common-series-settings>
                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                    </dxo-legend>
                    <dxi-value-axis [visualRange]="[0,100]" [showZero]="true" [tickInterval]="20"
                      [valueMarginsEnabled]="false">
                      <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                    </dxi-value-axis>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipLevel">
                    </dxo-tooltip>
                  </dx-chart>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isLoadingFinalEvaluation">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>





        <!-- Piano di sviluppo del team -->
        <percentage-accordion [title]="'dashboard.accordion.TEAM_IDP' | translate" [hidePercentage]="true"
          [isLoading]="isLoadingDevelopmentPlanData" (onAccordionOpen)="redrawGraphs('idp')">
          <div class="accordion-content">
            <ng-container *ngIf="!isLoadingDevelopmentPlanData">
              <div class="dashboard big">

                <div class="section">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.MANAGER_ACTION_TITLE"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->
                  <!-- azioni del manager -->


                  <dx-pie-chart id="actionPie" type="doughnut" [dataSource]="managerActions"
                    centerTemplate="centerTemplate" [palette]="actionColor"
                    (onInitialized)="saveInstance($event, 'actionPie')">
                    <dxo-title [text]="'dashboard.MANAGER_ACTION_TITLE' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.MANAGER_ACTION_TITLE_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"></dxo-legend>

                    <dxi-series argumentField="status" valueField="total">
                      <dxo-small-values-grouping mode="topN" [topCount]="3"></dxo-small-values-grouping>
                      <!--<dxo-label [visible]="true" format="fixedPoint" [customizeText]="customizePercentageText">
                        <dxo-connector [visible]="true"></dxo-connector>
                      </dxo-label>-->
                    </dxi-series>
                    <svg *dxTemplate="let pieChart of 'centerTemplate'">
                      <circle cx="131" cy="131" [attr.r]="22" fill="#8ED2E8"></circle>
                      <text text-anchor="middle" style="font-size: 18px; font-weight: 600;" x="131" y="131" fill="#fff">
                        <tspan x="131" y="137" class="pie-inside-text">{{managerActionTotal}}</tspan>
                      </text>
                    </svg>
                    <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltipChallenge">
                    </dxo-tooltip>
                  </dx-pie-chart>
                </div>


                <div class="section right">
                  <!--<div class="title-container">
                    <p class="title" translate="dashboard.CONTRIBUTOR_ACTION_TITLE"></p>
                    <svg-icon src="assets/img/icons/info.svg" [applyClass]="true" class="icon"></svg-icon>
                  </div>-->


                  <dx-chart id="contributorActionChart" [dataSource]="contributorAction" [rotated]="true"
                    [palette]="actionColor" (onInitialized)="saveInstance($event, 'contributorActionChart')">
                    <dxo-title [text]="'dashboard.CONTRIBUTOR_ACTION_TITLE' | translate">
                      <dxo-font color="#17238c" family="DaxlinePro" weight="600" size="20px">
                      </dxo-font>
                      <dxo-subtitle [text]="'dashboard.CONTRIBUTOR_ACTION_TITLE_DESCR' | translate">
                        <dxo-font color="#727272" family="Lato" weight="300" size="13px">
                        </dxo-font>
                      </dxo-subtitle>
                    </dxo-title>
                    <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                    <dxo-common-series-settings argumentField="status" type="bar" valueField="total"
                      [ignoreEmptyPoints]="true" [barPadding]="0.3">
                    </dxo-common-series-settings>
                    <dxo-series-template nameField="status"></dxo-series-template>

                    <dxi-value-axis [showZero]="true"></dxi-value-axis>

                    <dxo-argument-axis>
                      <dxo-label [visible]="false"></dxo-label>
                    </dxo-argument-axis>

                    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                    </dxo-legend>
                    <dxo-tooltip [enabled]="true" [shared]="true">
                    </dxo-tooltip>
                  </dx-chart>


                </div>

              </div>
            </ng-container>
            <ng-container *ngIf="isLoadingDevelopmentPlanData">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
        </percentage-accordion>




        <!-- Feedback di team -->
        <percentage-accordion [title]="'dashboard.accordion.TEAM_FEEDBACK' | translate" [hidePercentage]="true"
          (onAccordionOpen)="redrawGraphs('feedback')">
          <div class="accordion-content">

            <dx-chart id="feedbackChart" [dataSource]="feedbackData" [palette]="feedbackColors"
              (onInitialized)="saveInstance($event, 'feedbackChart')">
              <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
              <dxo-size [height]="230"></dxo-size>
              <dxo-legend [visible]="false"></dxo-legend>

              <dxo-common-series-settings argumentField="name" type="bar" valueField="value" [ignoreEmptyPoints]="true"
                [barPadding]="0.3">
              </dxo-common-series-settings>
              <dxo-series-template nameField="name"></dxo-series-template>

              <dxo-argument-axis [visualRange]="[0,100]" [tickInterval]="200">
                <dxo-label>
                  <dxo-format type="decimal"></dxo-format>
                </dxo-label>
              </dxo-argument-axis>
              <dxo-tooltip [enabled]="true" [shared]="true">
              </dxo-tooltip>
            </dx-chart>

          </div>
        </percentage-accordion>

      </div>


    </div>


  </ng-container>
</div>


<modal id="dashboard-tooltip" (onClose)="closeDashboardTooltip()">
  <modal-text-content modalId="dash001" [title]="'dashboard.modals.DASH_TOOL_TITLE' | translate"
    [text]="'dashboard.modals.DASH_TOOL_TEXT' | translate"></modal-text-content>
</modal>