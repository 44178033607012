import { CommonConstants } from "../../../commonclasses";

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
export class UrlService {
  constructor() {
    this.checkHostName();
  }

  checkHostName() {
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
  }

  // Ritorna l'URL dell'applicazione
  getApplicationUrl() {
    let applicationUrl: any = {
      url: null,
      baseUrl: null,
      protocol: '',
      domain: ''
    }

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }

    const isLocalhost = window.location.hostname.indexOf("localhost") == 0 || window.location.hostname.indexOf("192.168.1.16") == 0 || window.location.hostname.indexOf("192.168.1.50") == 0;

    if (isLocalhost) {
      if (window.location.hostname.indexOf("localhostcare") == 0) {
        applicationUrl.domain = "localhostcare.alloy.it";
      }
      else {
        // applicationUrl.domain = "testcare.genurl.it";
        applicationUrl.domain = "atfcare.alloy.it";
        // applicationUrl.domain = "localhost:10233";
      }
    } else {
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      applicationUrl.domain = url + secondLevelDomain;
    }

    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    applicationUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

    // Forzo https nel caso di localhost per evitare problemi di CORS
    if (isLocalhost) {
      applicationUrl.protocol = window.location.hostname.indexOf("localhostcare") == 0 ? "http" : "https";
    }

    applicationUrl.baseUrl = applicationUrl.protocol + "://" + applicationUrl.domain + "/";
    applicationUrl.url = applicationUrl.protocol + "://" + applicationUrl.domain;
    applicationUrl.domain = applicationUrl.domain;

    return applicationUrl;
  }

  // Ritorna l'URL dell'applicazione
  getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = "";
    let ssoUrl: string = '';
    let ssoReturnPage: string = '';
    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio Ã¨ "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "localhosts.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      domain = "atfs.alloy.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      domain = "testsso." + secondLevelDomain;
    }
    else {
      domain = "sso." + secondLevelDomain;
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    };
    ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_PERFORMANCECARE) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    return ssoUrl;
  }

  // Proxa l'url di un'immagine
  getExternalResourceName = (requestUrl: string, requestName: string) => {
    return this.getApplicationUrl().baseUrl + 'rest-api/coursemanager-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
  }

  // Torna la pagina di default per il redirect (ad esempio dopo il login) in base all'auth dell'utente
  getDefaultRedirectPage = (isManager?: boolean, isCollaborator?: boolean, isAdmin?: boolean, isOperation?: boolean, isSti?: boolean) => {
    if (isManager) {
      return "/manager/home";
    } else if (isCollaborator && isOperation) {
      return "/collaboratorOperations/home";
    } else if (isCollaborator && !isOperation) {
      return "/collaborator/home";
    } else if (isAdmin) {
      return "/admin/home";
    } else {
      // Nessuna auth compatibile
      return "/403";
    }
  }
}
