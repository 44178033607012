 <div *ngIf="!readonly" class="europ-text-input-wrapper"
  [ngClass]="{'disabled': disabled, 'invalid': isInvalid, 'search-bar': isSearchBar}">
  <input [placeholder]="placeholder" [type]="typeInput || 'text'" (ngModelChange)="emitOnModelChanged($event)"
    [(ngModel)]="bindValue" (keyup.enter)="emitOnIconClicked($event)">
  <ng-container *ngIf="!isSearchBar">
    <svg-icon *ngIf="iconRight" [src]="iconRight" (click)="emitOnIconClicked($event)" class="regular-size"
      [applyClass]="true"></svg-icon>
  </ng-container>
  <ng-container *ngIf="isSearchBar">
    <div class="search-icon-wrapper">
      <svg-icon *ngIf="iconRight" [src]="iconRight" (click)="emitOnIconClicked($event)" class="regular-size"
        [applyClass]="true"></svg-icon>
    </div>
  </ng-container>
</div>

<p *ngIf="readonly">{{bindValue}}</p>