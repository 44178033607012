import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagerHomeComponent } from './home/home.component';
import { ManagerGuard } from '../shared/services/manager-guard.service';
import { OnboardingComponent } from '../shared/components/onboarding/onboarding.component';
import { OnboardingVideoComponent } from '../shared/components/onboarding-video/onboarding-video.component';
import { NotificationsComponent } from '../core/notifications/notifications.component';
import { OnBoardingGuard } from '../shared/services/onboarding-guard.service';
import { ManagerComponent } from './manager.component';
import { ManagerPersonDetailsComponent } from './personDetails/personDetails.component';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { ManagerGoalDetailsComponent } from './goalDetails/goalDetails.component';
import { CreateUpdateGoalComponent } from '../manager/createUpdateGoal/createUpdateGoal.component';
import { OrganizeInterviewComponent } from '../manager/organizeInterview/organizeInterview.component';
import { CanDeactivateUpdateInterviewManagerGuardService } from '../manager/services/can-deactivate-update-interview-manager-guard.component';
import { SendClapComponent } from './sendClap/sendClap.component';
import { CanDeactivateUpdateClapManagerGuardService } from '../manager/services/can-deactivate-update-clap-guard.component';
import { CreateUpdateDevelopmentActionManagerComponent } from '../manager/createUpdateDevelopmentActionManager/createUpdateDevelopmentActionManager.component';
import { ProfilePageComponent } from '../profilePage/profilePage.component';
import { TeamListComponent } from '../manager/teamList/teamList.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { CreateUpdatePrivateNoteManagerComponent } from '../manager/createUpdatePrivateNote/createUpdatePrivateNote.component';
import { CanDeactivateUpdateGoalManagerGuardService } from '../manager/services/can-deactivate-update-goal-manager-guard.component';
import { MidTermListComponent } from '../manager/midTermList/midTermList.component';
import { AddMidTermReviewManagerComponent } from '../manager/addMidTermReview/addMidTermReview.component';
import { FinalEvaluationManagerComponent } from '../manager/finalEvaluation/finalEvaluation.component';
import { CreateUpdatePeerFeedbackManagerComponent } from '../manager/createUpdatePeerFeedback/createUpdatePeerFeedback.component';
import { PeerFeedbackRequestComponent } from '../manager/peerFeedbackRequest/peerFeedbackRequest.component';
import { CreateUpdateGoalPlatformManagerComponent } from '../manager/createUpdateGoalPlatformManager/createUpdateGoalPlatformManager.component';
import { DefinePerformanceLevelManagerComponent } from '../manager/definePerformanceLevel/definePerformanceLevel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PostCalibrationEvaluationComponent } from './postCalibrationEvaluation/postCalibrationEvaluation.component';
import { TalentScoutingComponent } from './talentScouting/talentScouting.component';
import { SpeedScreeningComponent } from './talentScouting/speedScreening/speedScreening.component';
import { ObservationGridComponent } from './talentScouting/observationGrid/observationGrid.component';
import { EvaluationGridComponent } from './evaluationGrid/evaluationGrid.component';
import { TalentScoutingChiefGuard } from '../shared/services/talent-scouting-chief-guard-guard.service';
import { TalentScoutingHeadManagerGuard } from '../shared/services/talent-scouting-head-manager-guard-guard.service';
import { CreateOptionalObjectivesComponent } from './createOptionalObjectives/createOptionalObjectives.component';

const managerRoutes: Routes = [
  {
    path: '', component: ManagerComponent, children: [
      { path: 'home', component: ManagerHomeComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'profile', component: ProfilePageComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'myTeam', component: TeamListComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'onboarding', component: OnboardingComponent, canActivate: [ManagerGuard] },
      { path: 'onboardingVideo', component: OnboardingVideoComponent, canActivate: [ManagerGuard, OnBoardingGuard] },

      { path: 'personDetails/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'personDetails/feedback/smartFeedback/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/smartFeedback/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/evidences/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/evidences/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/privateNotes/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/privateNotes/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/interview/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/interview/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/peerFeedback/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/peerFeedback/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'personDetails/developmentPlan/:userId', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/developmentPlan/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },


      { path: 'personDetails/:userId/:perfCareYear', component: ManagerPersonDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'goalDetails/:userId/:goalId', component: ManagerGoalDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'goalDetails/:userId/:goalId/:perfCareYear', component: ManagerGoalDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'goalDetails/afterSharing/:userId/:goalId/:perfCareYear', component: ManagerGoalDetailsComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'createUpdateGoalManager/afterSharing/individual/:userId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalManagerGuardService] },
      { path: 'createUpdateGoalManager/afterSharing/individual/:userId/:goalId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalManagerGuardService] },
      { path: 'createUpdateGoalManager/afterSharing/:userId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalManagerGuardService] },
      { path: 'createUpdateGoalManager/afterSharing/:userId/:goalId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalManagerGuardService] },
      { path: 'createUpdateGoalManager/individual/:userId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalManagerGuardService] },
      { path: 'createUpdateGoalManager/individual/:userId/:goalId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateGoalManagerGuardService] },
      { path: 'createUpdateGoalManager/:userId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateGoalManager/:userId/:goalId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateGoalManager/:userId', component: CreateUpdateGoalComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'organizeInterview/:userId', component: OrganizeInterviewComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewManagerGuardService] },
      { path: 'organizeInterview/:userId/:interviewId', component: OrganizeInterviewComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewManagerGuardService] },
      { path: 'sendApplause', component: SendClapComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapManagerGuardService] },
      { path: 'sendApplause/:userId', component: SendClapComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapManagerGuardService] },
      { path: 'sendApplause/:userId/:clapId', component: SendClapComponent, canActivate: [ManagerGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapManagerGuardService] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateGoalPlatformManager/:userId/:goalId', component: CreateUpdateGoalPlatformManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId', component: CreateUpdateDevelopmentActionManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId/:goalId', component: CreateUpdateDevelopmentActionManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId/:goalId/:developmentActionId', component: CreateUpdateDevelopmentActionManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentActionEdit/:userId/:developmentActionId', component: CreateUpdateDevelopmentActionManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },

      { path: 'createUpdateEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidence/:userId/:goalId', component: CreateUpdateEvidenceComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidenceOnEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidenceOnEvidence/:userId/:evidenceId', component: CreateUpdateEvidenceComponent, canActivate: [ManagerGuard, OnBoardingGuard] },

      { path: 'createUpdatePrivateNote', component: CreateUpdatePrivateNoteManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote/:userId', component: CreateUpdatePrivateNoteManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote/:userId/:noteId', component: CreateUpdatePrivateNoteManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId', component: CreateUpdatePeerFeedbackManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId/:feedbackId', component: CreateUpdatePeerFeedbackManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'feedbackRequest/:year', component: PeerFeedbackRequestComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'midTermList', component: MidTermListComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'addMidTermReview/:userId', component: AddMidTermReviewManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      // Disponibili anche dopo inizio nuovo processo
      { path: 'finalEvaluation/:perfCareYear/:userId/:goalId', component: FinalEvaluationManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'definePerformanceLevel/:perfCareYear/:userId', component: DefinePerformanceLevelManagerComponent, canActivate: [ManagerGuard, OnBoardingGuard] },

      { path: 'dashboard/:perfCareYear', component: DashboardComponent, canActivate: [ManagerGuard, OnBoardingGuard] },

      { path: 'postCalibrationEvaluation/:perfCareYear/:userId', component: PostCalibrationEvaluationComponent, canActivate: [ManagerGuard, OnBoardingGuard] },
      { path: 'talentScouting/:perfCareYear', component: TalentScoutingComponent, canActivate: [TalentScoutingHeadManagerGuard, OnBoardingGuard] },
      { path: 'talentScouting/speedScreening/:perfCareYear/:userId', component: SpeedScreeningComponent, canActivate: [TalentScoutingHeadManagerGuard, OnBoardingGuard] },
      { path: 'talentScouting/speedScreening/noRequirements/:perfCareYear/:userId', component: SpeedScreeningComponent, canActivate: [TalentScoutingHeadManagerGuard, OnBoardingGuard] },
      { path: 'talentScouting/observationGrid/:perfCareYear/:userId', component: ObservationGridComponent, canActivate: [TalentScoutingHeadManagerGuard, OnBoardingGuard] },
      { path: 'talentScoutingGrid/:perfCareYear', component: EvaluationGridComponent, canActivate: [TalentScoutingChiefGuard, OnBoardingGuard] },
      // Creazione obiettivi senza peso
      { path: 'createOptionalObjectives/:perfCareYear/:userId', component: CreateOptionalObjectivesComponent, canActivate: [OnBoardingGuard] },
      { path: 'createOptionalObjectives/:perfCareYear/:userId/:goalId', component: CreateOptionalObjectivesComponent, canActivate: [OnBoardingGuard] },

      { path: "", redirectTo: "home", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(managerRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class ManagerRoutingModule { }
