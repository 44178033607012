<page-container *ngIf="interviewQuestions$ | async as interview; else loading">
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="openNavigateBackModal()" [isBackButton]="true"
      [title]="('talent.interview.SIDE_BAR_TITLE' | translate) + ' ' + (interview.user | fullName)"
      [description]="'talent.interview.SIDE_BAR_DESCRIPTION' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <div class="content-wrapper">
        <div class="tab-container">
          <ng-container *ngFor="let tab of tabList$ | async; let tabIdx = index">
            <div class="tab-content" [ngClass]="{'active': currentTab.id == tab.id}" (click)="onTabChange(tabIdx)">
              <p>{{ tab.title }}</p>
              <div class="bottom-indicator"></div>
            </div>
          </ng-container>
        </div>


        <!-- INFO GENERALI -->
        <ng-container *ngIf="currentTab && currentTab.id == 'INFO'">
          <div class="section-header full-width">
            <p class="section-title">Info</p>
            <h3 class="section-argument">GENERALI</h3>
          </div>


          <input-container [isWithoutInfoIcon]="true" [title]="'talent.interview.INTERVIEWER'| translate">

            <ng-container *ngIf="evaluators$ | async as _evaluators">
              <europ-select (onSelectedItem)="onEvaluatorChange($event)" [selectedItem]="_interview?.evaluatorUserId"
                [items]="_evaluators.users" bindLabel="userId" [searchable]="true">
              </europ-select>
            </ng-container>

          </input-container>
          <!-- Save button -->
          <div fxFlex fxLayoutAlign="center start" class="full-width save-interview">
            <europ-button [disabled]="isSaveButtonDisabled" [label]="'talent.interview.SAVE_INTERVIEW' | translate"
              (onButtonClicked)="openSaveInterviewModal()"></europ-button>
          </div>

          <div class="action-container experience">
            <europ-button type="primary link" [label]="interview.pages[0].pageTitle" (onButtonClicked)="onTabChange(1)"
              iconRight="assets/img/icons/arrow-right.svg">
            </europ-button>
          </div>

        </ng-container>


        <!-- Dynamic areas -->
        <ng-container *ngFor="let page of interview.pages; let pageIdx = index; let first = first; let last = last">

          <ng-container *ngIf="currentTab?.id == page.pageId">

            <div class="section-header full-width">
              <p class="section-title full-width">
                {{ 'Area ' + (pageIdx + 1) }}
                <svg-icon *ngIf="!last" src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                  (click)="openInfoOfAreaModal()">
                </svg-icon>
              </p>
              <h3 class="section-argument">{{ page.pageTitle }}</h3>
            </div>

            <input-container *ngFor="let question of page.questions; let questionIdx = index" [isWithoutInfoIcon]="true"
              [title]="question.text">

              <ng-container [ngSwitch]="question.answerType">
                <!-- Textarea -->
                <ng-container *ngSwitchCase="'E'">
                  <europ-text-area [bindValue]="question.selectedAnswerText"
                    (onModelChanged)="updateAnswerOfQuestionOfPage(pageIdx, questionIdx, $event)"></europ-text-area>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <europ-text-area [bindValue]="question.selectedAnswerText"></europ-text-area>
                </ng-container>

              </ng-container>

            </input-container>

            <!-- Save button -->
            <div fxFlex fxLayoutAlign="center start" class="full-width save-interview">
              <europ-button [label]="'talent.interview.SAVE_INTERVIEW' | translate" [disabled]="isSaveButtonDisabled"
                (onButtonClicked)="openSaveInterviewModal()"></europ-button>
            </div>


            <!-- Bottom navigation -->
            <div class="action-container personality">

              <europ-button type="primary link"
                [label]="first ? 'Info' : interview.pages[pageIdx - 1].pageTitle || 'Area precedente'"
                (onButtonClicked)="onTabChange(first ? 0 : pageIdx)" iconLeft="assets/img/icons/arrow-left.svg">
              </europ-button>

              <ng-container *ngIf="!last">
                <europ-button type="primary link" [label]="interview.pages[pageIdx + 1].pageTitle || 'Area successiva'"
                  (onButtonClicked)="onTabChange(pageIdx + 2)" iconRight="assets/img/icons/arrow-right.svg">
                </europ-button>
              </ng-container>

            </div>

          </ng-container>

        </ng-container>


      </div>

    </div>
  </div>
</page-container>


<!-- Loader caricamento dati utente -->
<ng-template #loading>
  <div class="loader spinner-big"></div>
</ng-template>


<!-- Save questions -->
<modal [id]="saveInterviewModalId" (onClose)="closeSaveInterviewModal()" (onCancel)="closeSaveInterviewModal()"
  (onConfirm)="saveInterviewQuestions()" [cancelLabel]="'generic.CLOSE' | translate"
  [confirmLabel]="'generic.SAVE' | translate">
  <modal-text-content modalId="interview001" [title]="'talent.modals.SAVE_INTERVIEW' | translate"
    [text]="'talent.modals.SAVE_INTERVIEW_DESCRIPTION' | translate">
  </modal-text-content>
</modal>

<!-- Area info -->
<modal [id]="infoOfAreaModalId" (onClose)="closeInfoOfAreaModal()" (onConfirm)="closeInfoOfAreaModal()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <ng-container *ngIf="{title: getInfoAreaTitle(), innerText: getInfoAreaText()} as area">
    <modal-text-content modalId="interview002" [title]="area.title" [text]="area.innerText">
    </modal-text-content>
  </ng-container>
</modal>


<!-- Failed to save interview answers modal -->
<modal [id]="failedToSaveAnswersModalId" (onClose)="failedToSaveAnswersModal()" (onConfirm)="failedToSaveAnswersModal()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content modalId="interview003" [title]="'generic.WARNING' | translate"
    [text]="'talent.modals.COULD_NOT_SAVE_INTERVIEW_ANSWERS' | translate">
  </modal-text-content>
</modal>


<!-- failed-to-retrieve-interview -->
<modal [id]="failedToRetrieveInterviewModalId" (onClose)="closeFailedToRetrieveInterviewModalId()"
  (onConfirm)="closeFailedToRetrieveInterviewModalId()" [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content modalId="interview004" [title]="'generic.WARNING' | translate"
    [text]="'talent.modals.COULD_NOT_FETCH_INTERVIEW' | translate">
  </modal-text-content>
</modal>


<!-- Navigate back -->
<modal [id]="navigateBackModalId" (onClose)="closeNavigateBackModal()" (onCancel)="navigateBack()"
  (onConfirm)="navigateBack(true)" [confirmLabel]="'talent.modals.INTERVIEW_GO_BACK_CONFIRM_LABEL' | translate"
  [cancelLabel]="'talent.modals.INTERVIEW_GO_BACK_CANCEL_LABEL' | translate">
  <modal-text-content modalId="interview005" [title]="'generic.GO_BACK' | translate"
    [text]="'talent.modals.INTERVIEW_GO_BACK_DESCRIPTION' | translate">
  </modal-text-content>
</modal>