import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DropdownItem } from '../../models/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '../../services/redirect.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

export interface DossierMenuButton {
  id: string,
  name: string,
  badgeNumber?: number,
  isDisabled?: boolean,
  [key: string]: any
};

@Component({
  selector: 'header-dossier',
  templateUrl: 'header-dossier.component.html',
  styleUrls: ['./header-dossier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDossierComponent implements OnInit {
  @Input() interview?: any;
  @Input() user: any = null;
  @Input() counter?: number;
  @Input() counterLabel?: string;
  @Input() selectedYear?: number;
  @Input() yearListDisabled: boolean = false;
  @Input() yearItems: any[] = [];
  // used in person-potential-details to render the status of the selected person
  @Input() talentStatus: string | undefined;
  @Input() isTalent: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() menuButtons: DossierMenuButton[] = [];
  @Input() hasTabs: boolean = true;
  @Input() isAdminHeader: boolean = false;
  @Input() isChief: boolean = false;
  @Input() adminActionLabel: string = '';
  @Input() adminActionIcon: string = '';
  @Input() adminPageTitle: string = '';
  @Input() adminPageSubtitle: string = '';
  @Input() adminBack: boolean = false;
  @Input() adminActionDisabled: boolean = false;
  @Input() isDashboard: boolean = false;
  @Input() isLoadingAdminAction: boolean = false;
  @Input() isLoadingManagerAction: boolean = false;
  @Input() isTalentHeader: boolean = false;

  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onNewInterview: EventEmitter<any> = new EventEmitter();
  @Output() onAnnullInterview: EventEmitter<any> = new EventEmitter();
  @Output() onMenuButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() onAdminAction: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedYear: EventEmitter<any> = new EventEmitter();
  @Output() downloadReport: EventEmitter<any> = new EventEmitter();

  selectedMenuButton: any;
  noDataObject: DropdownItem = {
    id: '',
    name: '',
    icon: ''
  };
  mobileMenuOpened: boolean = false;
  mobileMenuItems: any[] = [];

  constructor(
    public translate: TranslateService,
    public redirectService: RedirectService,
    public router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    if (!this.menuButtons || !this.menuButtons.length) {
      this.menuButtons = [
        {
          id: 'objectives',
          name: this.translate.instant('headerDossier.generic.OBJECTIVE')
        },
        {
          id: 'feedback',
          name: this.translate.instant('headerDossier.generic.FEEDBACK')
        },
        {
          id: 'individualPlan',
          name: this.translate.instant('headerDossier.generic.IDP')
        }
      ];
    }
  }

  ngOnInit() {
    if (!this.isAdminHeader) {
      if (window.location.href.indexOf('feedback') > -1) {
        this.selectedMenuButton = this.menuButtons[1];
      } else if (window.location.href.indexOf('developmentPlan') > -1) {
        this.selectedMenuButton = this.menuButtons[2];
      } else if (window.location.href.indexOf('evaluation') > -1) {
        this.selectedMenuButton = this.menuButtons[0];
      } else if (window.location.href.indexOf('interview') > -1) {
        this.selectedMenuButton = this.menuButtons[1];
      } else if (window.location.href.indexOf('development-plan') > -1) {
        this.selectedMenuButton = this.menuButtons[2];
      } else {
        this.selectedMenuButton = this.menuButtons[0];
      }
    } else {
      if (window.location.href.indexOf('objectiveDefinition') >= 0) {
        this.selectedMenuButton = this.menuButtons[1];
      } else if (window.location.href.indexOf('phaseDefinition') >= 0) {
        this.selectedMenuButton = this.menuButtons[2];
      } else {
        this.selectedMenuButton = this.menuButtons[0];
      }
    }
    this.translate.get(
      [
        'generic.NO_DATA_FOUND',
        'admin.filters.EQUAL',
        'admin.SCHEDULE',
      ]).subscribe(translations => {
        this.noDataObject = {
          id: 'none',
          name: translations['generic.NO_DATA_FOUND'],
          icon: '/assets/img/icons/coffee.svg'
        }
      });
  }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  emitOnSelectedYearItem(item: any) {
    this.selectedYear = item;
    this.onSelectedYear.emit(item);
    this.cdr.detectChanges();
  }

  emitOnOptionClicked(item: any) {
    this.mobileMenuOpened = !this.mobileMenuOpened;
    item.badgeNumber = 0;
    this.selectedMenuButton = item;
    this.mobileMenuItems = this.menuButtons.filter((x: any) => {
      return x.id != item.id;
    })
    this.onMenuButtonClicked.emit(item.id);
  }

  emitOnOrganizeinterview() {
    this.onNewInterview.emit();
  }

  emitOnAnnullInterview(feedbackDialogueId: string) {
    this.onAnnullInterview.emit(feedbackDialogueId);
  }

  // Quando premi nel mobile un pulsante del menu bottom
  menuButtonClicked(selectedItem: any) {
    if (selectedItem.id != this.selectedMenuButton.id) {
      if (window.screen.width <= 600 || document.documentElement.clientWidth <= 600) {
        this.mobileMenuOpened = !this.mobileMenuOpened;
      }
      selectedItem.badgeNumber = 0;
      this.selectedMenuButton = selectedItem;
      this.mobileMenuItems = this.menuButtons.filter((x: any) => {
        return x.id != selectedItem.id;
      })
      this.onMenuButtonClicked.emit(selectedItem.id);
    }
  }

  emitOnAdminAction() {
    this.onAdminAction.emit();
  }

  emitOnDownloadReport() {
    this.downloadReport.emit();
  }

  formatDate(date: any) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return '--';
    }
  }

  goBackAdmin() {
    if (window.location.href.includes('participantList') ||
      window.location.href.includes('objectiveDefinition') ||
      window.location.href.includes('phaseDefinition')) {
      this.router.navigate(['admin/processSetup']);
    } else if (window.location.href.includes('processSetup')) {
      this.router.navigate(['admin/home']);
    } else {
      this.redirectService.goBackBrowser()
    }
  }
}
