import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UrlService } from 'src/app/shared/services/url.service';
import * as moment from 'moment';
import { ManagerService } from 'src/app/shared/services/manager.service';


@Component({
  selector: 'app-evaluation-grid',
  templateUrl: './evaluationGrid.component.html',
  styleUrls: ['./evaluationGrid.component.scss']
})
export class EvaluationGridComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;

  translations: any;

  searchedText: string = '';
  // matrixData: any = {};
  columnList = [
    "MEET",
    "EXCEEDED",
    "EXCEPTIONAL"
  ]
  keys = [
    "HIGH",
    "MEDIUM",
    "REALIZED"
  ]
  selectedUser: any;
  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;
  userList: any;
  isLoadingUsers: boolean = false;
  getUsersList$: any;
  noData: boolean = false;

  matrixData: any = {
    HIGH: [
      {
        id: 'label',
        title: 'ALTO'
      },
      {

        id: 'MEET',
        list: [],
        color: '#F4E07C'
      },
      {
        id: 'EXCEEDED',
        list: [],
        color: '#BEDDAF'
      },
      {
        id: 'EXCEPTIONAL',
        list: [],
        color: '#BEDDAF'
      },
    ],
    MEDIUM: [
      {
        id: 'label',
        title: 'MEDIO'
      },
      {
        id: 'MEET',
        list: [],
        color: '#F4E07C'
      },
      {
        id: 'EXCEEDED',
        list: [],
        color: '#BEDDAF'
      },
      {
        id: 'EXCEPTIONAL',
        list: [],
        color: '#BEDDAF'
      },
    ],
    REALIZED: [
      {
        id: 'label',
        title: 'BASSO'
      },
      {
        id: 'MEET',
        list: [],
        color: '#F4E07C'
      },
      {
        id: 'EXCEEDED',
        list: [],
        color: '#F4E07C'
      },
      {
        id: 'EXCEPTIONAL',
        list: [],
        color: '#F4E07C'
      },
    ]
  }
  saveEvaluationGrid$: Subscription = new Subscription;
  currentMoreData: any;

  noteModalUser: any;
  isLoadingNoteList: boolean = false;
  getNoteListModal$: Subscription = new Subscription;
  noteListModalData: {
    user: any,
    list: any[]
  } = {
      user: null,
      list: []
    };
  noteToDelete: any;
  modalNote: string = '';
  fileObject: any;
  resetIncludeUpload: boolean = true;
  showFile: boolean = false;
  charsNote: number = 600;
  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private managerService: ManagerService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private urlService: UrlService,
  ) {

    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const runningYear$: Observable<number> = this.store.select(fromApp.getRunningYear);
    this.combinedSelected$ = combineLatest([loggedUser$, runningYear$])
      .subscribe(
        ([loggedUser, runningYear]) => {
          if (loggedUser && loggedUser.user && runningYear) {
            this.loggedUser = loggedUser && loggedUser.user;

            this.route.params
              .subscribe((params: Params) => {
                if (params.perfCareYear) {
                  this.selectedYear = {
                    id: params.perfCareYear,
                    name: 'Anno ' + params.perfCareYear
                  }
                } else if (this.runningYear) {
                  this.selectedYear = {
                    id: this.runningYear,
                    name: 'Anno ' + this.runningYear
                  }
                }
                this.getPerformanceAssessmentYears();
              })
          }
        });
  }


  ngOnInit() { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }


  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.managerService.getPerformanceAssessmentYears(this.loggedUser.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          let keys = data.response.map((x) => x.assessmentYear);
          keys = keys.sort();
          for (let i = (keys.length - 1); i >= 0; i--) {
            let yearData = data.response.find((x) => x.assessmentYear == keys[i])
            this.yearList.push({
              id: yearData.assessmentYear,
              name: 'Anno ' + yearData.assessmentYear,
              disabled: !yearData.isInProcess
            });
          }
          this.getUsersList();
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.router.navigate([url + '/' + this.selectedYear.id])
  }

  // ricerca
  searchedTextChange(text: string) {
    this.searchedText = text;
  }


  // Recupera una lista di utenti 
  getUsersList() {
    this.isLoadingUsers = true;

    if (this.getUsersList$) {
      this.getUsersList$.unsubscribe();
    }
    this.matrixData.HIGH[3].list = [];
    this.matrixData.HIGH[2].list = [];
    this.matrixData.HIGH[1].list = [];
    this.matrixData.MEDIUM[3].list = [];
    this.matrixData.MEDIUM[2].list = [];
    this.matrixData.MEDIUM[1].list = [];
    this.matrixData.REALIZED[3].list = [];
    this.matrixData.REALIZED[2].list = [];
    this.matrixData.REALIZED[1].list = [];
    // Avvio il loader
    this.getUsersList$ = this.managerService.getEvaluationGridUser(this.selectedYear.id, this.searchedText)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.userList = data.response;

          for (let i = 0; i < this.userList.length; i++) {
            let tmpUserList = this.userList[i];

            if (tmpUserList.potentialLevel == "HIGH") {
              if (tmpUserList.performanceLevel == 'EXCEPTIONAL') {
                this.matrixData.HIGH[3].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.HIGH[3].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.HIGH[3].cellId = 'cell-HIGH-3';
              } else if (tmpUserList.performanceLevel == 'EXCEEDED') {
                this.matrixData.HIGH[2].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.HIGH[2].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.HIGH[2].cellId = 'cell-HIGH-2';
              } else {
                this.matrixData.HIGH[1].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.HIGH[1].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.HIGH[1].cellId = 'cell-HIGH-1';
              }
            } else if (tmpUserList.potentialLevel == "MEDIUM") {
              if (tmpUserList.performanceLevel == 'EXCEPTIONAL') {
                this.matrixData.MEDIUM[3].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.MEDIUM[3].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.MEDIUM[3].cellId = 'cell-MEDIUM-3';
              } else if (tmpUserList.performanceLevel == 'EXCEEDED') {
                this.matrixData.MEDIUM[2].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.MEDIUM[2].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.MEDIUM[2].cellId = 'cell-MEDIUM-2';
              } else {
                this.matrixData.MEDIUM[1].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.MEDIUM[1].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.MEDIUM[1].cellId = 'cell-MEDIUM-1';
              }
            } else {
              if (tmpUserList.performanceLevel == 'EXCEPTIONAL') {
                this.matrixData.REALIZED[3].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.REALIZED[3].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.REALIZED[3].cellId = 'cell-REALIZED-3';
              } else if (tmpUserList.performanceLevel == 'EXCEEDED') {
                this.matrixData.REALIZED[2].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.REALIZED[2].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.REALIZED[2].cellId = 'cell-REALIZED-2';
              } else {
                this.matrixData.REALIZED[1].list = tmpUserList.users.slice(0, 3) || [];
                this.matrixData.REALIZED[1].more = tmpUserList.users.slice(3, tmpUserList.users.length) || [];
                this.matrixData.REALIZED[1].cellId = 'cell-REALIZED-1';
              }
            }
          }
          this.cdr.detectChanges();
        }
        this.isLoadingUsers = false;
        this.cdr.detectChanges();
      }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Gestisce lo spostamento dell'utente da una colonna all'altra
  // Nel caso in cui venga spostato in una colonna diversa dall'originale
  // salvo la nuova valutazione
  drop(event: any, row: string, column: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else if (event.previousContainer && !event.previousContainer.data) {
      if (this.matrixData[row][column].list && this.matrixData[row][column].list.length < 3) {
        this.selectedUser.edited = true;
        this.selectedUser.hasNotification = true;
        let temp = [];
        for (let i = 0; i < this.matrixData[row][column].more.length; i++) {
          if (this.matrixData[row][column].more[i].userId != this.selectedUser.userId) {
            temp.push(this.matrixData[row][column].more[i])
          }
        }
        this.matrixData[row][column].more = temp;
        transferArrayItem(
          [this.selectedUser],
          event.container.data,
          column,
          event.currentIndex
        )
      } else {
        this.selectedUser.edited = true;
        this.selectedUser.hasNotification = true;
        this.matrixData[row][column].more.push(this.selectedUser);
        transferArrayItem(
          [this.selectedUser],
          [],
          column,
          event.currentIndex
        );
      }
    } else if (event.container && !event.container.data) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (this.matrixData[row][column].list && this.matrixData[row][column].list.length >= 3) {
        this.selectedUser.edited = true;
        this.selectedUser.hasNotification = true;
        this.matrixData[row][column].more.push(this.selectedUser);
        transferArrayItem(
          [this.selectedUser],
          event.container.data,
          column,
          event.currentIndex
        )
      } else {
        this.selectedUser.edited = true;
        this.selectedUser.hasNotification = true;
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
      // Originale
      // transferArrayItem(
      //   event.previousContainer.data,
      //   event.container.data,
      //   event.previousIndex,
      //   event.currentIndex
      // );
    }
    if (event.previousContainer.id != event.container.id) {
      this.selectedUser.edited = true;
      this.selectedUser.hasNotification = true;
    }
    this.selectedUser.potentialLevel = row;
    this.refactMatrix();
    this.cdr.detectChanges();
  }

  refactMatrix() {
    for (let key of this.keys) {
      for (let i = 0; i < this.matrixData[key].length; i++) {
        if (this.matrixData[key][i].id != 'label') {
          let users = this.matrixData[key][i].list.concat(this.matrixData[key][i].more);
          this.matrixData[key][i].list = users.slice(0, 3) || [];
          this.matrixData[key][i].more = users.slice(3, users.length - 1) || [];
        }
      }
    }
  }

  // Ritorna le liste collegate alla colonna attuale
  getConnectedList(row: any, column: any) {
    let connectedList: any = [];
    for (let i = 0; i < this.keys.length; i++) {
      connectedList.push('cell-' + this.keys[i] + '-' + column)
      connectedList.push('more-cell-' + this.keys[i] + '-' + column)
    }
    return connectedList;
  }

  // Quando comincio a trascinare un utente lo salvo, in questo modo posso salvare le modifiche
  setSelected(user: any) {
    this.selectedUser = user;
  }

  // Salvo ad ogni spostamento degli utenti, quindi il conferma sessione di fatto è un back
  confirmSession() {
    this.redirectService.goBackBrowser();
  }

  saveEvaluationGrid(confirmUsers?: boolean) {
    this.isLoadingUsers = true;

    if (this.saveEvaluationGrid$) {
      this.saveEvaluationGrid$.unsubscribe();
    }

    let users = [];
    for (let i = 0; i < this.keys.length; i++) {
      for (let j = 0; j < this.matrixData[this.keys[i]].length; j++) {
        let item = this.matrixData[this.keys[i]][j];
        if (item.id != 'label') {
          for (let k = 0; k < item.list.length; k++) {
            users.push({
              userId: item.list[k].userId,
              potentialLevel: item.list[k].potentialLevel
            })
          }
        }
      }
    }

    this.saveEvaluationGrid$ = this.managerService.saveEvaluationGrid(this.selectedYear.id, users, confirmUsers || false)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUsers = false;
        } else {
          if (confirmUsers) {
            this.redirectService.goBackBrowser();
          } else {
            this.getUsersList();
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsers = false;
      });

  }

  openConfimModal() {
    this.modalService.open('confirm-modal');
  }

  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('confirm-modal');
    if (confirm) {
      this.saveEvaluationGrid(true);
    }
  }


  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.managerService.downloadEvaluationGridReport(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  openMatrixTooltip() {
    this.modalService.open('matrix-tooltip');
  }

  closeMatrixTooltip() {
    this.modalService.close('matrix-tooltip');
  }

  openObservationGridTooltip() {
    this.modalService.open('grid-tooltip');
  }

  closeObservationGridTooltip() {
    this.modalService.close('grid-tooltip');
  }

  // per gestire clickOutside
  closeUserMenu(user: any) {
    user.isMenuOpen = false;
  }

  openMore(column: any, rowId: any, columnIndex: number) {
    this.currentMoreData = {};
    this.currentMoreData.id = column.cellId;
    this.currentMoreData.rowId = rowId;
    this.currentMoreData.columnIndex = columnIndex;
    this.currentMoreData.title = this.translate.instant('talent.potentialLevel.' + rowId) + ' - ' + this.translate.instant('evaluations.' + column.id);
    this.currentMoreData.list = column.more;
    this.currentMoreData.isOpen = true;
  }

  closeMore() {
    this.currentMoreData = {};
    this.currentMoreData.isOpen = false;
  }

  openInsertNote(user: any) {
    this.modalService.close('note-list');
    this.resetIncludeUpload = true;
    this.noteModalUser = user;
    this.fileObject = null;
    this.showFile = true;
    this.modalService.open('insert-note');
  }

  closeInsertNote(confirm?: boolean) {
    this.modalService.close('insert-note');
    this.showFile = false;
    if (confirm) {
      this.isLoadingNoteList = true;
      this.managerService.createTalentNote(this.selectedYear.id, this.noteModalUser.userId, this.modalNote, this.fileObject || null)
        .subscribe((data: any) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "in001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.openNoteListModal(this.noteModalUser);
          // this.noteModalUser = null;
          this.modalNote = '';
          this.fileObject = null;
          //this.getNoteListModal(this.noteModalUser);
        }
          , (err: any) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "in002",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.isLoadingNoteList = false;
            return throwError(new Error(err.message));
          }
        );
    }
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  modalNoteChanged(text: string) {
    this.modalNote = text;
  }

  openNoteListModal(user: any) {
    this.isLoadingNoteList = true;
    this.noteListModalData.user = user;
    this.modalService.open('note-list');
    this.getNoteListModal(user);
  }

  getNoteListModal(user: any) {
    if (this.getNoteListModal$) {
      this.getNoteListModal$.unsubscribe();
    }

    this.getNoteListModal$ = this.managerService.getUserTalentNote(this.selectedYear.id, user.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "in001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.noteListModalData.list = [];
          if (data.response && data.response.notes && data.response.notes.length) {
            for (let i = 0; i < data.response.notes.length; i++) {
              let tempNote = data.response.notes[i];
              this.noteListModalData.list.push({
                ...tempNote,
                insertDate: moment(tempNote.creationDate).format('D MMMM YYYY')
              })
            }
          }
        }
        this.isLoadingNoteList = false;
      }
        , (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "in002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingNoteList = false;
          return throwError(new Error(err.message));
        }
      );
  }

  closeNoteListModal() {
    this.modalService.close('note-list');
  }

  openDeleteNoteModal(note: any) {
    this.noteToDelete = note;
    this.modalService.open('confirm-delete-note');
  }

  closeDeleteNoteModal(confirm?: boolean) {
    this.modalService.close('confirm-delete-note');
    if (confirm) {
      this.isLoadingNoteList = true;
      this.managerService.deleteUserTalentNote(this.noteToDelete.noteId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "deln001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getNoteListModal(this.noteListModalData.user);
        }, (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "deln002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingNoteList = false;
          return throwError(new Error(err.message));
        }
        );
    }
  }

  // Recupera la stringa per l'estensione del file
  getExtensionString(fileName: string) {
    let extension = fileName.slice(fileName.lastIndexOf('.'), fileName.length);
    extension = extension.replace('.', '');
    if (extension == 'pptx' || extension == 'PPTX') {
      extension = 'ppt';
    } else if (extension == 'jpg' || extension == 'JPG') {
      extension = 'jpeg';
    } else if (extension == 'xlsx' || extension == 'XLSX') {
      extension = 'xls';
    } else if (extension == 'docx' || extension == 'DOCX') {
      extension = 'doc';
    } else if (extension == 'eml' || extension == 'EML') {
      extension = 'eml';
    } else if (extension == 'msg' || extension == 'MSG') {
      extension = 'msg';
    } else if (extension == 'txt' || extension == 'TXT') {
      extension = 'txt';
    } else if (extension == 'rtf' || extension == 'RTF') {
      extension = 'rtf';
    } else if (extension == 'png' || extension == 'PNG') {
      extension = 'png';
    }
    return extension;
  }

  downloadLinkedFile(file: any) {
    let link = document.createElement('a');
    link.href = file.url;
    link.download = file.url.substr(file.url.lastIndexOf('/') + 1);
    link.click();
  }


  ngOnDestroy() {
    if (this.getUsersList$) {
      this.getUsersList$.unsubscribe();
    }
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    if (this.saveEvaluationGrid$) {
      this.saveEvaluationGrid$.unsubscribe();
    }
  }
}
