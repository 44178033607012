import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'app-admin-process-setup-details',
  templateUrl: './processSetupDetails.component.html',
  styleUrls: ['./processSetupDetails.component.scss']
})
export class ProcessSetupDetailsComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  processYear: string = '';
  tabList: any[] = [];
  currentTab: string = 'participant';
  isLoadingProcessInfo: boolean = false;
  getProcessInfo$: Subscription = new Subscription();
  processData: any;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            if (window.location.href.indexOf('participantList') >= 0) {
              this.currentTab = 'participant';
            } else if (window.location.href.indexOf('objectiveDefinition') >= 0) {
              this.currentTab = 'definition';
            } else if (window.location.href.indexOf('phaseDefinition') >= 0) {
              this.currentTab = 'phases';
            }

            this.route.params
              .subscribe(
                (params: Params) => {
                  this.processYear = params.processYear;
                  this.getProcessInfo();
                })
          }
        });
  }

  changeCurrentTab(id: any) {
    switch (id) {
      case 'participant':
        this.currentTab = 'participant';
        this.router.navigate(['/admin/processSetup/participantList/' + this.processYear]);
        break;
      case 'definition':
        this.currentTab = 'definition';
        this.router.navigate(['/admin/processSetup/objectiveDefinition/' + this.processYear]);
        break;
      case 'phases':
        this.currentTab = 'phases';
        this.router.navigate(['/admin/processSetup/phaseDefinition/' + this.processYear]);
        break;
    }
  }

  ngOnInit() {
    this.translate.get([
      'headerDossier.processSetup.PARTICIPANT',
      'headerDossier.processSetup.OBJECTIVE_DEFINITION',
      'headerDossier.processSetup.PROCESS_PHASES'
    ]).subscribe((translations) => {
      this.tabList = [
        {
          id: 'participant',
          name: translations['headerDossier.processSetup.PARTICIPANT']
        },
        {
          id: 'definition',
          name: translations['headerDossier.processSetup.OBJECTIVE_DEFINITION']
        },
        {
          id: 'phases',
          name: translations['headerDossier.processSetup.PROCESS_PHASES']
        }
      ]
    })
  }

  // Ritorna i dettagli del processo
  getProcessInfo() {
    this.isLoadingProcessInfo = true;

    if (this.getProcessInfo$) {
      this.getProcessInfo$.unsubscribe();
    }
    // Se sono nella tab delle fasi voglio recuperare anche quel dato, altrimenti non serve
    this.getProcessInfo$ = this.adminService.getPerformanceAssessmentInfo(this.processYear, undefined)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "a002",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.processData = data.response;
        }
        this.isLoadingProcessInfo = false;
      }, (err) => {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "a002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProcessInfo = false;
      })

  }

  editProcess() {
    this.router.navigate(['admin/createUpdateProcess/' + this.processYear]);
  }

  orderBy(fieldName: string) {
  }


  ngOnDestroy() {
    if (this.getProcessInfo$) {
      this.getProcessInfo$.unsubscribe();
    }
  }
}