import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SenecaResponse } from 'src/commonclasses';
import * as CoreActions from "../../../core/ngrx/core.actions";
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'app-observation-grid-question',
  templateUrl: './observationGrid.component.html',
  styleUrls: ['./observationGrid.component.scss']
})
export class ObservationGridComponent implements OnInit, OnDestroy {
  runningYear: number = 0;
  applicationLang: string = '';
  applicationLang$: Subscription;
  loggedUser: any;
  userId: any;
  isLoadingUserQuestions: boolean = false;
  getUserTalentPotentialQuestions$: Subscription = new Subscription;
  isImpersonate: boolean = false;

  tabList: any[] = [];
  currentTab: any;

  experienceTooltip: any;
  potentialTooltip: any;

  selectedUser: any;
  pages: any;
  areasOfStrength: any;
  pointsOfImprovement: any;
  potentialLevel: any;
  saveCurrentAnswer$: any;
  potentialLevelList: { id: string; title: any; }[] = [];
  selectedPotentialLevel: any;


  constructor(
    public translate: TranslateService,
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService,
    private managerService: ManagerService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router
  ) {
    // Recupero la lingua dell'appplicazione
    this.applicationLang$ = this.store.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
      }
    });

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.runningYear = params.perfCareYear;
        this.userId = params.userId
        this.translate.get([
          "grid.tab.EXPERIENCES",
          "grid.tab.CAPABILITIES",
          "grid.tab.PERSONALITY",
          "grid.tab.EVALUATION",
          'grid.modals.EXPERIENCE_TITLE',
          'grid.modals.EXPERIENCE_SUBTITLE',
          'grid.modals.EXPERIENCE_DESCR',
          "grid.potentialLevel.HIGH",
          "grid.potentialLevel.MEDIUM",
          "grid.potentialLevel.REALIZED",
          "grid.potentialLevel.TOOLTIP",
          "grid.CURRENT_POTENTIAL",
          "grid.modals.MISSING_MANDATORY_DESCR"
        ]).subscribe((translations) => {

          this.tabList = [
            {
              id: 'experience',
              title: translations["grid.tab.EXPERIENCES"]
            },
            {
              id: 'capabilities',
              title: translations["grid.tab.CAPABILITIES"]
            },
            {
              id: 'personality',
              title: translations["grid.tab.PERSONALITY"]
            },
            {
              id: 'evaluation',
              title: translations["grid.tab.EVALUATION"]
            }
          ]
          this.currentTab = this.tabList[0];
          this.experienceTooltip = {
            modalId: "expt001",
            title: translations['grid.modals.EXPERIENCE_TITLE'],
            subtitle: translations['grid.modals.EXPERIENCE_SUBTITLE'],
            text: translations['grid.modals.EXPERIENCE_DESCR'],
          }

          this.potentialTooltip = {
            modalId: "pott001",
            title: translations['grid.CURRENT_POTENTIAL'],
            text: translations['grid.potentialLevel.TOOLTIP'],
          }

          this.potentialLevelList = [
            {
              id: 'HIGH',
              title: translations['grid.potentialLevel.HIGH']
            },
            {
              id: 'MEDIUM',
              title: translations['grid.potentialLevel.MEDIUM']
            },
            {
              id: 'REALIZED',
              title: translations['grid.potentialLevel.REALIZED']
            }
          ]
          this.getUserTalentPotentialQuestions();
        })
      });
  }


  getUserTalentPotentialQuestions() {
    this.isLoadingUserQuestions = true;

    if (this.getUserTalentPotentialQuestions$) {
      this.getUserTalentPotentialQuestions$.unsubscribe();
    }

    this.getUserTalentPotentialQuestions$ = this.managerService.getUserTalentPotentialQuestions(this.runningYear, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "tpq001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.selectedUser = data.response.user;
          this.pages = {};
          for (let i = 0; i < data.response.pages.length; i++) {
            let tempPage: any = {};
            tempPage.id = data.response.pages[i].pageId;
            tempPage.title = data.response.pages[i].pageTitle;
            let pageQuestions = data.response.pages[i].questions;
            let tempQuestions = [];
            for (let j = 0; j < pageQuestions.length; j++) {
              let question = { ...pageQuestions[j] };
              question.answersList = [];
              for (let k = 0; k < question.answers.length; k++) {
                question.answersList.push({
                  id: k,
                  value: question.answers[k]
                })
              }
              if (question.answerType == 'S' && question.selectedAnswersIndex && question.selectedAnswersIndex.length) {
                question.selectedAnswer = question.answersList[question.selectedAnswersIndex[0]];
              } else if (question.answerType == 'M' && question.selectedAnswersIndex && question.selectedAnswersIndex.length) {
                question.selectedAnswers = [];
                for (let i = 0; i < question.selectedAnswersIndex.length; i++) {
                  question.answersList[question.selectedAnswersIndex[i]].isChecked = true;
                  question.selectedAnswers.push(question.answersList[question.selectedAnswersIndex[i]]);
                }
              } else if (!question.answerType) {
                question.selectedAnswerText = question.selectedAnswerText;
              }
              tempQuestions.push(question);
            }
            tempPage.questions = tempQuestions;
            if (i == 0) {
              this.pages['experience'] = tempPage;
            } else if (i == 1) {
              this.pages['capabilities'] = tempPage;
            } else {
              this.pages['personality'] = tempPage;
            }
          }
          if (data.response.lastPage) {
            this.pages['evaluation'] = {
              areasOfStrength: data.response.lastPage.areasOfStrength,
              pointsOfImprovement: data.response.lastPage.pointsOfImprovement,
              potentialLevel: data.response.lastPage.potentialLevel,
            };
            if (this.pages['evaluation'].potentialLevel) {
              this.selectedPotentialLevel = this.potentialLevelList.find((x: any) => x.id == this.pages['evaluation'].potentialLevel)
            }
          }
        }
        this.isConcludeDisabled()
        this.isLoadingUserQuestions = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "tpq002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingUserQuestions = false;
      });
  }

  openExperienceModal() {
    this.modalService.open('experience-tootlip');
  }

  closeExperienceModal() {
    this.modalService.close('experience-tootlip');
  }

  selectTab(tab: any) {
    this.currentTab = tab;
  }

  // Scegli risposta
  selectAnswer(newAnswer: any, question: any) {
    question.selectedAnswer = newAnswer;
    question.selectedAnswerIndex = newAnswer.id
  }

  selectMultipleAnswer(question: any, answer: any) {
    answer.isChecked = !answer.isChecked;
    question.selectedAnswers = question.answersList.filter((x: any) => x.isChecked);
    // if (!question.selectedAnswers) {
    //   question.selectedAnswers = [];
    // }
    // if (question.selectedAnswers.indexOf(answer) < 0) {
    //   question.selectedAnswers.push(answer);
    // } else {
    //   
    // }
  }

  // cambia tab 
  nextPrevTab(tab: any) {
    this.isLoadingUserQuestions = true;
    this.currentTab = tab;
    this.isLoadingUserQuestions = false;
  }

  areasOfStrengthChanged(text: string) {
    this.pages['evaluation'].areasOfStrength = text;
  }

  pointsOfImprovementChanged(text: string) {
    this.pages['evaluation'].pointsOfImprovement = text;
  }

  onTextChanged(text: string, question: any) {
    question.selectedAnswerText = text;
  }

  openCapabilitiesTooltip() { this.modalService.open('capabilities-tooltip') }
  closeCapabilitiesTooltip() { this.modalService.close('capabilities-tooltip') }

  openPersonalityTooltip() { this.modalService.open('personality-tooltip') }
  closePersonalityTooltip() { this.modalService.close('personality-tooltip') }

  openEvaluationTooltip() { this.modalService.open('evaluation-tooltip') }
  closeEvaluationTooltip() { this.modalService.close('evaluation-tooltip') }

  selectPotentialLevel(newPotential: any) {
    this.selectedPotentialLevel = newPotential;
  }


  saveCurrentAnswer(back?: boolean) {
    this.isLoadingUserQuestions = true;

    let pageForService: Array<{ pageId: string, questions: Array<{ questionId: string, selectedAnswersIndex?: number[], selectedAnswerText?: string }> }> = [];


    let keys = Object.keys(this.pages);

    for (let j = 0; j < keys.length; j++) {
      let currentPage = this.pages[keys[j]];

      //if (keys[j] == 'evaluation') {
      //  areasOfStrength = currentPage.areasOfStrength;
      //  pointsOfImprovement = currentPage.pointsOfImprovement;
      //} else {
      if (keys[j] != 'evaluation') {
        let questions = [];
        for (let i = 0; i < currentPage.questions.length; i++) {
          if (currentPage.questions[i].answerType == 'S') {
            questions.push({
              questionId: currentPage.questions[i].questionId,
              selectedAnswersIndex: currentPage.questions[i]?.selectedAnswer?.id >= 0 ? [currentPage.questions[i].selectedAnswer.id] : []
            })
          } else if (currentPage.questions[i].answerType == 'M') {
            if (currentPage.questions[i]?.selectedAnswers && currentPage.questions[i]?.selectedAnswers.lenght) {
              questions.push({
                questionId: currentPage.questions[i].questionId,
                selectedAnswersIndex: currentPage.questions[i]?.selectedAnswers.map((x: any) => x.id) || []
              })
            } else {
              questions.push({
                questionId: currentPage.questions[i].questionId,
                selectedAnswersIndex: undefined
              })
            }
          } else {
            questions.push({
              questionId: currentPage.questions[i].questionId,
              selectedAnswerText: currentPage.questions[i].selectedAnswerText
            })
          }
        }

        pageForService.push(
          {
            pageId: currentPage.id,
            questions: questions
          }
        );
      }
    }

    this.saveCurrentAnswer$ = this.managerService.updateTalentPotentialQuestion(this.runningYear, this.userId, pageForService, this.pages['evaluation'].areasOfStrength, this.pages['evaluation'].pointsOfImprovement, this.selectedPotentialLevel && this.selectedPotentialLevel.id || undefined)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "tpq001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        if (back) {
          this.router.navigate(['manager/talentScouting/', this.runningYear]);
        }
        this.isLoadingUserQuestions = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "tpq002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingUserQuestions = false;
      });
  }

  isConcludeDisabled() {
    let keys = Object.keys(this.pages);
    for (let i = 0; i < keys.length; i++) {
      let page = this.pages[keys[i]];
      if (page.questions && page.questions.length) {
        for (let j = 0; j < page.questions.length; j++) {
          let question = page.questions[j];
          // manca una risposta singola
          if (question.answerType == 'S') {
            if (!(question.selectedAnswersIndex >= 0) || !(question.selectedAnswer && question.selectedAnswer.id >= 0)) {
              return true;
            }
          }
        }
      }
    }
    // Manca un campo della valutazione
    if (!this.pages['evaluation'].areasOfStrength || !this.pages['evaluation'].areasOfStrength.length
      || !this.pages['evaluation'].pointsOfImprovement || !this.pages['evaluation'].pointsOfImprovement.length
      || !this.selectedPotentialLevel || !this.selectedPotentialLevel.id
    ) {
      return true;
    }
    // tutto ok
    return false;
  }

  openConcludeObservation() {
    if (this.isConcludeDisabled()) {
      const messageObj: ApplicationModalMessage = {
        modalId: "tpq002",
        title: this.translate.instant("generic.WARNING"),
        text: this.translate.instant("grid.modals.MISSING_MANDATORY_DESCR"),
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    } else {
      this.saveCurrentAnswer(true)
    }

  }

  ngOnDestroy(): void {
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
    if (this.getUserTalentPotentialQuestions$) {
      this.getUserTalentPotentialQuestions$.unsubscribe();
    }
  }
}

