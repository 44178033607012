import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { SendClapComponent } from '../sendClap/sendClap.component';

@Injectable()
export class CanDeactivateUpdateClapManagerGuardService implements CanDeactivate<SendClapComponent> {
  constructor() { }

  canDeactivate(component: SendClapComponent): boolean | Promise<boolean> {
    if (!component.forceBack) {
      if (!component.noDataInserted() && !component.alreadySaved &&
        component.checkObbligatoryData() &&
        (((component.clap.comment != component.clapCopy.comment) ||
          (component.cardSelected && component.cardSelectedCopy &&
            component.cardSelected.careId != component.cardSelectedCopy.careId))
          || (!component.userId && component.selectedUserList && component.selectedUserList.length))
      ) {
        component.formatDataOpenUpdateModal();
        return new Promise<boolean>((resolve, reject) => {
          let clickedButton = document.getElementById("confirmBtn");
          if (clickedButton) {
            clickedButton.onclick = ((e: any) => {
              resolve(false);
            });
          }
        });
      } else {
        return true;
      }
      return true;
    }
    return true;
  }
}