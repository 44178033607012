import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { GetSubordinatesWithPhaseStatusesForManagerResponse, SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { CKEditorConfig } from 'src/app/shared/models/general-data.model';
import * as moment from 'moment';
import { Subordinateservice } from '../services/subordinates.service';

@Component({
  selector: 'app-manager-addMidTermReview',
  templateUrl: './addMidTermReview.component.html',
  styleUrls: ['./addMidTermReview.component.scss']
})
export class AddMidTermReviewManagerComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserGoalList$: Subscription = new Subscription();
  runningYear: number = 0;
  objectiveFeedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveOsservationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isLoadingUserGoals: boolean = false;
  goalList: any[] = [];

  osservation: string = '';

  modalCreateRevisionId: string = '';
  modalCreateRevisionTitle: string = '';
  modalCreateRevisionSubtitle: string = '';
  modalCreateRevisionText: string = '';
  modalCreateRevisionTitleConfirmTextButton: string = '';
  modalCreateRevisionTextCloseTextButton: string = '';

  isFromBack: boolean = false;

  userId: string = '';

  editorConfig: any;
  ckEditorValue: string = '';
  alreadySent: boolean = false;
  userStatusForRecap: string = '';
  userRevisionDate: string = '';
  userOsservation: string = '';
  personDetails$: Subscription = new Subscription;
  personDetails: any;
  chargeSelectedUser: boolean = false;
  getPersonDetails$: any;
  sendRevision$: Subscription = new Subscription;
  getLoggedUser$: Subscription = new Subscription;
  loggedUser: any;
  isOperations: boolean = false;
  backWithData: boolean = false;

  constructor(
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private subordinatesService: Subordinateservice,
    private modalService: ModalService
  ) {

    this.editorConfig = CKEditorConfig;

    this.isOperations = window.location.href.indexOf('managerOperations') > -1;

    this.getLoggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser: any) => {
      this.loggedUser = loggedUser.user;
    })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];


                // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                if (!this.personDetails) {
                  this.chargeSelectedUser = false;
                  this.getPersonDetails();
                }
              })
              if (this.userId) {
                this.getMidTermGoals();
              } else {
                this.goBackBrowser();
              }
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_SUBTITLE',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT',
        'midTerm.addMidTermReview.IN_RETARD',
        'midTerm.addMidTermReview.IN_LINE',
        'midTerm.addMidTermReview.GREAT_POINT'
      ])
      .subscribe(translations => {
        this.objectiveFeedbackTooltipModal = {
          modalId: "d1035",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT'],
        };
        this.objectiveOsservationTooltipModal = {
          modalId: "d1036",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE'],
          subtitle: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_SUBTITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT'],
        }
      })
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return (this.osservation && this.osservation.trim().length);
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return (this.osservation && this.osservation.trim().length);
  }

  sendRevision() {
    this.isLoadingUserGoals = true;
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    this.sendRevision$ = this.managerService.setMidTermReviewRevision(this.runningYear, this.userId, this.osservation)
      .subscribe((data: any) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj = {
            modalId: "mt005",
            title: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS"),
            text: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.goBackBrowser();
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt006",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Recupara l'utente dal suo userId
  getPersonDetails() {
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.runningYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d009",
            text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.personDetails = personDetailsData.response;
          this.getMidTermGoals();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  sendRevisionAction(isBack?: boolean) {
    if (isBack && (this.alreadySent || !this.isObbligatoryDataInserted())) {
      this.backWithData = true;
      this.modalService.open("modalCreateRevisionNoDataForManager");
    } else if (this.isObbligatoryDataInserted()) {
      if (isBack) {
        this.isFromBack = true;
      } else {
        this.isFromBack = false;
      }
      this.modalService.open("modalCreateRevisionForManager");
    } else {
      if (isBack) {
        if (this.checkIfDataIsChanged()) {
          this.modalService.open("modalCreateRevisionNoDataForManager");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modalCreateRevisionNoDataForManager");
      }
    }
  }

  closeModalCreateRevisionNoDataForManager() {
    this.modalService.close("modalCreateRevisionNoDataForManager");
    this.backWithData = false;
  }

  closeModalCreateRevisionForManager() {
    this.modalService.close("modalCreateRevisionForManager");
  }

  osservationChanged(text: string) {
    this.osservation = text;
  }

  closeObjectiveFeedbackTooltip() {
    this.modalService.close("objectiveFeedbackModalTooltip");
  }

  openObjectiveFeedbackTooltip() {
    this.modalService.open("objectiveFeedbackModalTooltip");
  }

  getMidTermGoals() {
    this.isLoadingUserGoals = true;
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    this.getUserGoalList$ = this.managerService.getMidTermGoals(this.runningYear, this.userId)
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "059",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (goalList.response && goalList.response.goals && goalList.response.goals.length) {
            this.goalList = GoalUtils.formatGoalCardList(this.translate, goalList.response.goals, false, this.userId);
          } else {
            this.goalList = [];
          }
          if (goalList.response.midTermReviewUserGoalAdvancement && goalList.response.midTermReviewUserGoalAdvancement.length) {
            this.userOsservation = goalList.response.midTermReviewUserObservation;
            this.userStatusForRecap = goalList.response.midTermReviewUserGoalAdvancement;
            this.userRevisionDate = moment(goalList.response.midTermReviewUserObservationDate).format('DD.MM.YYYY');
            this.alreadySent = true;
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "060",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    if (this.getLoggedUser$) {
      this.getLoggedUser$.unsubscribe();
    }
  }
}