<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="personDetails" (onBackFunction)="onBackClicked()" [isBackButton]="true"
      [title]="getSidebarTitle()" [description]="getSidebarDescription()">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoading()">
        <div class="form-container-how">
          <!-- Titolo -->
          <input-container [isLoading]="isLoading()" [tooltipModalMessage]="titleTooltipModal"
            [isValued]="goal.title && goal.title.trim().length" [isObbligatory]="true"
            title="{{'objectiveWizard.CRUD.OBJECTIVE_TITLE' | translate}}">
            <europ-input [bindValue]="goal.title" (onModelChanged)="goalTitleChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_TITLE_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-input>
          </input-container>
          <!-- Descrizione -->
          <input-container [numberCharsTextArea]="charsGoalDescription" [isFromTextArea]="true"
            [isLoading]="isLoading()" [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="goal.description && goal.description.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.DESCRIPTION' | translate}}">
            <europ-text-area [bindValue]="goal.description" [maxlength]="600"
              (onModelChanged)="goalDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.DESCRIPTION_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-text-area>
          </input-container>
          <!-- Peso -->
          <input-container
            *ngIf="!isChallenge && !getIsOptional() && !(goal.goalId && goal.macroGoalType=='PEOPLE_EMPOWERMENT')"
            [isLoading]="isLoading() || isLoadingTotalWeight"
            [instructions]="('goalSetting.createGoal.REMAIN_TO_ASSIGN' | translate) + (remainingWeight > 0 ? remainingWeight : 0) + '%'"
            [tooltipModalMessage]="weightTooltipModal" [isValued]="weightOptionSelected" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.OBJECTIVE_WEIGHT' | translate}}">
            <europ-select (onSelectedItem)="goalWeightChanged($event)" [selectedItem]="weightOptionSelected"
              [items]="weightOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_WEIGHT_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>
          <!-- Data entro cui completare l'obiettivo -->
          <input-container *ngIf="!(goal.goalId && goal.macroGoalType=='PEOPLE_EMPOWERMENT')" [isLoading]="isLoading()"
            [isObbligatory]="true" [tooltipModalMessage]="deadlineTooltipModal" [isValued]="selectedDate"
            [isObbligatory]="true" title="{{'objectiveWizard.CRUD.DEADLINE' | translate}}">
            <europ-datepicker [value]="selectedDate" (dateChanged)="selectedDateChanged($event)">
            </europ-datepicker>
          </input-container>
          <!-- Attributo CARE -->
          <input-container *ngIf="!(goal.goalId && goal.macroGoalType=='PEOPLE_EMPOWERMENT')"
            [isLoading]="isLoading() || isFetchingCareOptions" [tooltipModalMessage]="careTooltipModal"
            [isValued]="goal.careTag" [isObbligatory]="true"
            title="{{'objectiveWizard.CRUD.OBJECTIVE_CARE' | translate}}">
            <europ-select (onSelectedItem)="changeCareTag($event)" [selectedItem]="goal.careTag" [items]="careOptions"
              [loadingData]="isFetchingCareOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_CARE_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>


          <!-- Aggiungi obiettivo -->
          <div class="button-container centered">
            <europ-button [disabled]="isLoading() || !isMandatoryDataInserted()" type="primary default" size="large"
              [label]="goalId && goalId.length ? ('goal.UPDATE_OBJECTIVES' | translate) : ('goal.ADD_OBJECTIVES' | translate)"
              (onButtonClicked)="createUpdate()">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoading()">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>


<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="save-modal" (onCancel)="closeSaveModal(false)" (onClose)="closeSaveModal()"
  (onConfirm)="closeSaveModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="saveModal.confirmLabel">
  <modal-text-content modalId="saveme" [title]="saveModal && saveModal.title" [text]="saveModal && saveModal.text">
  </modal-text-content>
</modal>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="back-modal" (onCancel)="closeBackModal()" (onClose)="closeBackModal()" (onConfirm)="closeBackModal(true)"
  [cancelLabel]="'generic.CANCEL' | translate" [confirmLabel]="'objectiveWizard.CRUD.backModal.YES_BACK' | translate">
  <modal-text-content modalId="backme" [title]="'objectiveWizard.CRUD.backModal.GO_BACK' | translate"
    [subtitle]="'objectiveWizard.CRUD.backModal.GO_BACK_SUB' | translate"
    [text]="'objectiveWizard.CRUD.backModal.GO_BACK_TEXT' | translate">
  </modal-text-content>
</modal>