import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';

@Component({
  selector: 'app-admin-create-update-cascading-objectives',
  templateUrl: './cascadingCreateUpdate.component.html',
  styleUrls: ['./cascadingCreateUpdate.component.scss']
})
export class CascadingCreateUpdateComponent implements OnInit {
  isLoadingProcess: boolean = true;
  processYear: any;
  userId: any;
  getAdminPersonDetails$: Subscription = new Subscription;
  isLoadingPersonDetails: boolean = true;
  personDetails: any;
  isLoadingData: boolean = false;
  setObjectives$: Subscription = new Subscription;
  cascadingGoal: any;
  cascadingGoalCopy: any;
  totalSubordinates: number = 0;

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.processYear = params.processYear;
          this.userId = params.userId;
          // recupero i dati dell'utente per l'header
          this.getAdminPersonDetails();
        }
      );
  }

  ngOnInit() {
  }

  // Dati utenti per l'header
  getAdminPersonDetails() {
    this.isLoadingPersonDetails = true;

    if (this.getAdminPersonDetails$) {
      this.getAdminPersonDetails$.unsubscribe();
    }

    this.getAdminPersonDetails$ = this.adminService.getUserCascadingGoals(this.processYear, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: '109',
            text: this.translate.instant('errors.' + (data.error)),
            title: this.translate.instant('generic.WARNING')
          };
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cascadingGoal = data.response?.cascadingGoal;
          this.personDetails = data.response?.user;
          this.totalSubordinates = data.response?.totalSubordinates || 0;
          this.cascadingGoalCopy = JSON.parse(JSON.stringify(this.cascadingGoal));
        }
        this.isLoadingPersonDetails = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: '111',
          text: this.translate.instant('errors.' + ((err && err.message) || err)),
          title: this.translate.instant('generic.WARNING')
        };
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPersonDetails = false;
      });
  }

  introTextChanged(newText: string) {
    this.cascadingGoal.introductionText = newText;
  }

  objectiveListChanged(newList: string) {
    this.cascadingGoal.description = newList;
  }



  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.cascadingGoal.description && this.cascadingGoal.description.trim().length;
  }

  checkIfDataIsChanged() {
    return this.cascadingGoal.introductionText && this.cascadingGoalCopy.introductionText && this.cascadingGoal.introductionText != this.cascadingGoalCopy.introductionText && this.cascadingGoal.description && this.cascadingGoalCopy.description && this.cascadingGoal.description != this.cascadingGoalCopy.description;
  }

  createUpdate() {
    this.modalService.open('confirm-modal');
  }

  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('confirm-modal');
    if (confirm) {
      this.setObjectives();
    }
  }


  setObjectives() {
    this.isLoadingData = true;

    if (this.setObjectives$) {
      this.setObjectives$.unsubscribe();
    }

    this.setObjectives$ = this.adminService.setUserCascadingGoals(this.processYear, this.userId, this.cascadingGoal.introductionText, this.cascadingGoal.description)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "casc002",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingData = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "casc003",
            title: this.translate.instant("cascading.CREATE_SUCCESS"),
            text: this.translate.instant("cascading.CREATE_SUCCESS_TEXT")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "casc004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingData = false;
      })
  }

  // Vai indietro back browser
  goBackBrowser() {
    if (this.checkIfDataIsChanged()) {
      this.modalService.open('')
    } else {
      this.redirectService.goBackBrowser();
    }
  }

  ngOnDestroy() {
    if (this.getAdminPersonDetails$) {
      this.getAdminPersonDetails$.unsubscribe();
    }
    if (this.setObjectives$) {
      this.setObjectives$.unsubscribe();
    }
  }
}