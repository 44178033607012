import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentBannerPhaseResponse, JwtPayload, PerfCareAssessmentPhaseKeys, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-talent-process',
  templateUrl: './talentScoutingProcess.component.html',
  styleUrls: ['./talentScoutingProcess.component.scss']
})
export class AdminTalentProcessComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: string = '';
  isLoadingProcessDetails: boolean = false;
  getProcessDetails$: Subscription = new Subscription;
  processDetail: any[] = [];
  structureForModal: any;
  downloadReport$: any;
  isDownloadingReport: boolean = false;
  menuOptions: any[] = [];
  translations: any;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
        });
  }

  ngOnInit() {

    this.route.params.subscribe((params: any) => {
      if (params.processYear) {
        this.processYear = params.processYear;
      }
      this.translate.get([
        'calibration.menu.ACCESS',
        'calibration.menu.SHOW',
      ]).subscribe((translations) => {
        this.translations = translations;
        this.getProcessesData();
      })
    })
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            for (let i = 0; i < data.response.length; i++) {
              let process = data.response[i];
              process.isMenuOpen = false;
              if (process.activePhaseKey == PerfCareAssessmentPhaseKeys.TALENT_SCOUTING) {
                process.status = 'active';
                // process.menuOptions = [{ id: 'access', title: this.translations['calibration.menu.ACCESS'] }]
              } else {
                process.status = 'standby';
                // process.menuOptions = [{ id: 'show', title: this.translations['calibration.menu.SHOW'] }]
              }
            }
            data.response.forEach((process: any) => {
              process.startDate = process.startDate ? moment(process.startDate).format("DD/MM/YYYY") : null;
              process.endDate = process.endDate ? moment(process.endDate).format("DD/MM/YYYY") : null;
            })
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  getStatus(phase: any) {
    if (phase && phase.status) {
      switch (phase.status) {
        case 'TO_START': {
          return {
            status: 'toStart',
            statusMessage: this.translate.instant('itemListTeamStatus.toStart')
          }
        }
        case 'STARTED': {
          return {
            status: 'inProgress',
            statusMessage: this.translate.instant('itemListTeamStatus.inProgress') + ' ' + (phase.percentage.toFixed(1)) + '%'
          }
        }
        case 'COMPLETED': {
          return {
            status: 'completed',
            statusMessage: this.translate.instant('itemListTeamStatus.completed')
          }
        }
        default:
          return {
            status: 'disabled',
            statusMessage: this.translate.instant('itemListTeamStatus.disabled')
          }
      }
    } else {
      return {
        status: 'disabled',
        statusMessage: this.translate.instant('itemListTeamStatus.disabled')
      }
    }

  }

  orderBy(fieldName: string) {
  }


  onAccess(process: any) {
    this.router.navigate(['admin/talent/' + process.year]);
  }

  ngOnDestroy() {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    if (this.getProcessDetails$) {
      this.getProcessDetails$.unsubscribe();
    }
  }
}
