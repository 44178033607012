import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { JwtPayload, PerfCareTalentIndividualDevelopmentPlanStatuses, PerformanceCareTalent, SenecaResponse, TalentIdp, TalentIdpNote, User, } from 'atfcore-commonclasses';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, tap, switchMap, delay } from 'rxjs/operators';
import { ApplicationModalMessage, getGlobalApplicationData } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import * as fromApp from '../../../ngrx/app.reducers';
import { Talent } from '../../talentScoutingUsers/talentScouting.component';
import * as CoreActions from "../../../core/ngrx/core.actions";

type DevelopmentAction = {
  id: string;
  type: string;
  idp: Partial<
    PerformanceCareTalent.CreateTalentIndividualDevelopmentPlan['idp']
  >;
  statusObject: {
    status?: string;
    statusMessage?: string;
  };
  status: PerfCareTalentIndividualDevelopmentPlanStatuses
  creationUserId: string;
  isDevelopmentAction: boolean;
  formattedCreationDate: string | null;
  creationUser: Partial<User>;
  iconTop: string;
  user: {
    forename: string;
    surname: string;
  };
  comment: {
    creationUser: Partial<User>;
    creationDate: string | null;
    commentId?: string;
    noteId: string;
    text?: string;
    idpTalentRentId: string;
  }[];
};

type Goal = {
  value: string;
  inEditMode: boolean;
  isNew?: boolean;
};

@Component({
  selector: 'app-development-plan',
  templateUrl: './development-plan-tab.component.html',
  styleUrls: ['./development-plan-tab.component.scss'],
})
export class DevelopmentPlanTabComponent implements OnInit {
  readonly translationPrefix = 'talent.development-plan-tab.';

  readonly failedToAddCommentModalId = 'failed-to-add-comment';

  readonly failedToDeleteActionModalId = 'failed-to-delete-action';

  readonly addOrEditCommentModalId = 'add-comment-modal';

  readonly updateActionStatusModalId = 'update-action-status-modal';

  readonly readCommentModalId = 'read-comment-modal';

  readonly confirmDeleteActionModalId = 'confirm-delete-action';


  readonly actionStatuses: { title: string; value: PerfCareTalentIndividualDevelopmentPlanStatuses }[] = [
    {
      title: this.translate.instant('talent.development-plan-tab.statuses.TO_START'),
      value: PerfCareTalentIndividualDevelopmentPlanStatuses.TO_START,
    },
    {
      title: this.translate.instant('talent.development-plan-tab.statuses.STARTED'),
      value: PerfCareTalentIndividualDevelopmentPlanStatuses.STARTED,
    },
    {
      title: this.translate.instant('talent.development-plan-tab.statuses.COMPLETED'),
      value: PerfCareTalentIndividualDevelopmentPlanStatuses.COMPLETED,
    },
  ];

  roleGoal: Goal = {
    value: '',
    inEditMode: true,
  };

  developmentGoal: Goal = {
    value: '',
    inEditMode: true,
  };

  // Used in modals
  selectedAction: DevelopmentAction | null = null;
  selectedActionCommentText: string = '';
  selectedActionCommentMode: 'add' | 'edit' | null = null;
  selectedActionStatus: { title: string; value: PerfCareTalentIndividualDevelopmentPlanStatuses } | null = null;
  selectedActionComment: DevelopmentAction['comment'][number] | null = null;
  selectedActionIdToDelete: string | null = null;
  // Observable<User> = combineLatest([
  //   this.selectedCandidate$,
  //   this.selectedPerformanceYear$,
  // ]).pipe(
  //   switchMap(([talent, performanceYear]) => {
  //     if (!talent || !performanceYear) {
  //       return throwError('No talent or performance year selected');
  //     }
  // 
  //     return this.potentialTalentSvc.getTalentDetails(
  //       talent.userId,
  //       performanceYear
  //     );
  //   }),
  //   switchMap(({ response, error }) => {
  //     if (error) {
  //       console.error('[development-plan-tab] getTalentDetails$ error', error);
  //       return throwError(error);
  //     }
  //     return of(response.user);
  //   })
  // );

  developmentGoal$: Subscription = new Subscription;

  roleGoal$: Subscription = new Subscription;
  originalRoleValue: string = '';
  originalGoalValue: string = '';
  loggedUser$: Subscription;
  loggedUser: any;
  processYear: string = '';
  selectedUserId: string = '';
  getRoleGoal$: Subscription = new Subscription;
  isLoadingRoleGoal: boolean = true;
  createRoleGoal$: Subscription = new Subscription;
  updateRoleGoal$: Subscription = new Subscription;
  isLoadingDevelopmentGoal: boolean = true;
  getDevelopmentGoal$: Subscription = new Subscription;
  createDevelopmentGoal$: Subscription = new Subscription;
  updateDevelopmentGoal$: any;
  isLoadingActions: boolean = true;
  deleteActions$: Subscription = new Subscription;
  getActions$: Subscription = new Subscription;
  developmentPlans: any;
  talentDetails: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private potentialTalentSvc: PotentialTalentsService,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) {
    this.loggedUser$ = this.store.select(fromApp.getLoggedUser)
      .subscribe((tmpLoggedUser) => {
        if (tmpLoggedUser) {
          this.loggedUser = tmpLoggedUser.user;
          // workaround per avere i parametri e non rifare anche il componente "padre"
          let tmpUrl = window.location.href;
          tmpUrl = tmpUrl.split("/talent/")[1].split("/development-plan")[0];
          this.processYear = tmpUrl.split('/')[0];
          this.selectedUserId = tmpUrl.split('/')[1]
          this.getGoalData();
        }
      })
  }

  ngOnInit(): void {
  }


  getGoalData() {
    this.getRoleGoal();
    this.getDevelopmentGoal();
    this.getActionsData();
  }

  getRoleGoal() {
    this.isLoadingRoleGoal = true;

    if (this.getRoleGoal$) {
      this.getRoleGoal$.unsubscribe();
    }

    this.getRoleGoal$ = this.potentialTalentSvc.getTalentRoleGoal(
      {
        perfCareYear: parseInt(this.processYear),
        userId: this.selectedUserId
      }
    ).subscribe((data: SenecaResponse<any>) => {
      if (data && data.response) {
        this.originalRoleValue = data.response.text;
        this.roleGoal.value = data.response.text;
        this.roleGoal.isNew = false;
      } else {
        this.roleGoal.isNew = true;
      }
      this.isLoadingRoleGoal = false;
    }, (err: string) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "dpt002",
        text: this.translate.instant("errors." + err),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingRoleGoal = false;
    })
  }


  // HANDLE GOALS

  toggleRoleGoalMode() {
    this.roleGoal.inEditMode = !this.roleGoal.inEditMode;
  }

  deleteRoleGoal() {
    this.roleGoal.value = '';
    this.roleGoal.isNew = false;
    this.saveRoleGoal();
  }

  saveRoleGoal() {
    if (!this.roleGoal.isNew && (this.roleGoal.value === '' || (this.roleGoal.value && this.roleGoal.value.length > 0))) {
      this.updateRoleGoal();
    } else {
      this.createRoleGoal()
    }
  }


  createRoleGoal() {
    this.isLoadingRoleGoal = true;

    if (this.createRoleGoal$) {
      this.createRoleGoal$.unsubscribe();
    }

    this.createRoleGoal$ = this.potentialTalentSvc.createTalentRoleGoal(
      {
        perfCareYear: parseInt(this.processYear),
        userId: this.selectedUserId,
        text: this.roleGoal.value
      }).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "dpt004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.openSnackbar();
        }
        this.getRoleGoal();

        // in qualche momento faceva isEditMode = false; credo in errore della chiamata
        this.isLoadingRoleGoal = false;
      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "opm011",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoleGoal = false;
      })
  }

  updateRoleGoal() {

    this.isLoadingRoleGoal = true;

    if (this.updateRoleGoal$) {
      this.updateRoleGoal$.unsubscribe();
    }

    this.potentialTalentSvc.updateTalentRoleGoal(
      {
        perfCareYear: parseInt(this.processYear),
        userId: this.selectedUserId,
        text: this.roleGoal.value
      })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "dpt003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.openSnackbar();
        }
        this.getRoleGoal();

        // in qualche momento faceva isEditMode = false; credo in errore della chiamata
        this.isLoadingRoleGoal = false;
      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "opm011",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoleGoal = false;
      })
  }

  toggleDevelopmentGoalMode() {
    this.developmentGoal.inEditMode = !this.developmentGoal.inEditMode;
  }

  deleteDevelopmentGoal() {
    this.developmentGoal.value = '';
    this.developmentGoal.isNew = false;
    this.saveDevelopmentGoal();
  }

  getDevelopmentGoal() {
    this.isLoadingDevelopmentGoal = true;

    if (this.getDevelopmentGoal$) {
      this.getDevelopmentGoal$.unsubscribe();
    }

    this.getDevelopmentGoal$ = this.potentialTalentSvc.getTalentDevelopmentGoal(
      {
        perfCareYear: parseInt(this.processYear),
        userId: this.selectedUserId
      }
    ).subscribe((data: SenecaResponse<any>) => {
      if (data && data.response) {
        this.originalGoalValue = data.response.text;
        this.developmentGoal.value = data.response.text;
        this.developmentGoal.isNew = false;
      } else {
        this.developmentGoal.isNew = true;
      }

      this.isLoadingDevelopmentGoal = false;
    }, (err: string) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "opm011",
        text: this.translate.instant("errors." + err),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingDevelopmentGoal = false;
    })
  }

  saveDevelopmentGoal() {
    if (!this.developmentGoal.isNew && (this.developmentGoal.value === '' || (this.developmentGoal.value && this.developmentGoal.value.length > 0))) {
      this.updateDevelopmentGoal();
    } else {
      this.createDevelopmentGoal()
    }
  }

  createDevelopmentGoal() {
    this.isLoadingDevelopmentGoal = true;

    if (this.createDevelopmentGoal$) {
      this.createDevelopmentGoal$.unsubscribe();
    }

    this.createDevelopmentGoal$ = this.potentialTalentSvc.createTalentDevelopmentGoal({
      perfCareYear: parseInt(this.processYear),
      userId: this.selectedUserId,
      text: this.developmentGoal.value
    }).subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "opm010",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.openSnackbar();
      }
      this.getDevelopmentGoal();

      // in qualche momento faceva isEditMode = false; credo in errore della chiamata?
      this.isLoadingDevelopmentGoal = false;
    }, (err: string) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "opm011",
        text: this.translate.instant("errors." + err),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingDevelopmentGoal = false;
    })
  }

  updateDevelopmentGoal() {
    this.isLoadingDevelopmentGoal = true;

    if (this.updateDevelopmentGoal$) {
      this.updateDevelopmentGoal$.unsubscribe();
    }

    this.updateDevelopmentGoal$ = this.potentialTalentSvc.updateTalentDevelopmentGoal({
      perfCareYear: parseInt(this.processYear),
      userId: this.selectedUserId,
      text: this.developmentGoal.value
    }).subscribe((data: SenecaResponse<any>) => {
      if (data && data.response) {
        this.openSnackbar();
      }
      this.getDevelopmentGoal();

      // in qualche momento faceva isEditMode = false; credo in errore della chiamata?
      this.isLoadingDevelopmentGoal = false;
    }, (err: string) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "dpt001",
        text: this.translate.instant("errors." + err),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingDevelopmentGoal = false;
    })
  }

  // END HANDLING GOALS
  getActionsData() {
    this.potentialTalentSvc.getTalentDetails(
      this.selectedUserId,
      parseInt(this.processYear)
    ).subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "opm010",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.talentDetails = data.response
        this.getActions();
      }
    }, (err: string) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "dpt001",
        text: this.translate.instant("errors." + err),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingDevelopmentGoal = false;
    })
  }

  getActions() {
    this.isLoadingActions = true;
    if (this.getActions$) {
      this.getActions$.unsubscribe();
    }

    this.getActions$ = this.potentialTalentSvc.listIndividualDevelopmentPlan(this.selectedUserId, parseInt(this.processYear))
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.developmentPlans = [];
          data.response.forEach((element: any) => {
            this.developmentPlans.push(this.mapper(element, this.talentDetails))
          });
        }
        this.isLoadingActions = false;
      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "opm011",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingActions = false;
      })
  }

  openDeleteActionModal(actionId: string) {
    this.selectedActionIdToDelete = actionId;
    this.modalService.open(this.confirmDeleteActionModalId);
  }


  onCloseDeleteActionModal() {
    this.selectedActionIdToDelete = null;
    this.modalService.close(this.confirmDeleteActionModalId)
  }

  onConfirmDeleteActionModal() {
    this.isLoadingActions = true;
    if (this.deleteActions$) {
      this.deleteActions$.unsubscribe();
    }

    this.deleteActions$ = this.potentialTalentSvc.deleteIndividualDevelopmentPlan({
      perfCareYear: parseInt(this.processYear),
      rentId: this.selectedActionIdToDelete || ""
    }).subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        throwError('Could not find candidate or performance year');
      } else {
        this.onCloseDeleteActionModal();
        this.getActions();
      }
    }, (err) => {
      console.error('[development-plan-tab] deleteIndividualDevelopmentPlan$ error', err);
      this.modalService.open(this.failedToDeleteActionModalId)
    })
  }

  navigateToEditPage(data: DevelopmentAction) {
    this.router.navigate(['edit-action', data.id], { relativeTo: this.route });
  }



  // UPDATE ACTION STATUS
  openUpdateActionStatusModal(action: DevelopmentAction) {
    this.selectedAction = action;
    this.modalService.open(this.updateActionStatusModalId);
  }

  onCloseUpdateActionStatus() {
    this.selectedAction = null;
    this.selectedActionStatus = null;
    this.modalService.close(this.updateActionStatusModalId);
  }

  // TODO: Refactor
  onConfirmUpdateActionStatus() {
    if (this.selectedAction && this.selectedActionStatus) {
      this.isLoadingActions = true;
      this.potentialTalentSvc.updateIndividualDevelopmentPlan({
        perfCareYear: parseInt(this.processYear),
        idp: {
          rentId: this.selectedAction.id,
          status: this.selectedActionStatus.value + '',
          developmentAction: this.selectedAction.idp.developmentAction ?? '',
          userId: this.selectedAction.idp.userId ?? '',
          perfCareYear: parseInt(this.processYear),
          date: this.selectedAction.idp.date,
          duration: this.selectedAction.idp.duration,
          referentName: this.selectedAction.idp.referentName,
          type: this.selectedAction.idp.type,
          description: this.selectedAction.idp.description,
        }
      }).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          console.error('[development-plan-tab] createNoteForIdp$ error', data.error);
          this.modalService.open(this.failedToAddCommentModalId);
        }
        this.onCloseUpdateActionStatus();
        this.getActions();

      }, (err) => {
        console.error('[development-plan-tab] createNoteForIdp$ error', err);
        this.modalService.open(this.failedToAddCommentModalId);
        this.onCloseUpdateActionStatus();
        this.isLoadingActions = false;
      })
    } else {
      this.modalService.open(this.failedToAddCommentModalId);
      this.onCloseUpdateActionStatus();
      this.isLoadingActions = false;
    }
  }
  // END UPDATE ACTION STATUS


  // READ COMMENT
  openReadCommentModal(comment: DevelopmentAction['comment'][number]) {
    if (this.processYear && comment.commentId) {
      this.potentialTalentSvc.getNoteByIdp(comment.commentId, parseInt(this.processYear))
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            throwError('Could not get note');
          } else {
            this.selectedActionComment = this.commentMapper(data.response);
            this.modalService.open(this.readCommentModalId);
          }
        }, (err: any) => {
          throwError('Could not get note');
        })
    }
  }

  onCloseReadCommentModal() {
    this.modalService.close(this.readCommentModalId);
    this.selectedActionComment = null;
  }
  // END READ COMMENT


  // ADD OR EDIT COMMENT
  openAddOrEditCommentModal(
    data: any,
    mode: 'add' | 'edit'
  ) {
    this.selectedActionCommentMode = mode;

    if (mode === 'add') {
      this.selectedAction = data;
      this.selectedActionCommentText = '';
    } else {
      this.selectedAction = data.action;
      this.selectedActionCommentText = data.comment.text;
      this.selectedActionComment = data.comment;
    }

    this.modalService.open(this.addOrEditCommentModalId)
  }

  openAddCommentModal(action: DevelopmentAction) {
    this.selectedAction = action;
    this.selectedActionCommentText = '';
    this.modalService.open(this.addOrEditCommentModalId);
  }

  onConfirmAddOrEditCommentModal() {
    this.selectedActionCommentMode === 'add'
      ? this.onAddComment()
      : this.onEditComment();
  }

  onCloseAddOrEditComment() {
    this.selectedAction = null;
    this.selectedActionCommentText = '';
    this.selectedActionCommentMode = null;
    this.selectedActionComment = null;
    this.modalService.close(this.addOrEditCommentModalId);
  }

  private onAddComment() {
    if (this.selectedAction) {
      this.potentialTalentSvc.createNoteForIdp({
        noteText: this.selectedActionCommentText,
        idpRentId: this.selectedAction.id,
        perfCareYear: parseInt(this.processYear),
      }).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          console.error('[development-plan-tab] createNoteForIdp$ error', data.error);
          this.modalService.open(this.failedToAddCommentModalId);
        }
        this.onCloseAddOrEditComment();
        this.getActions();
      }, (err) => {
        console.error('[development-plan-tab] createNoteForIdp$ error', err);
        this.modalService.open(this.failedToAddCommentModalId);
      })
    }

  }


  private onEditComment() {

    if (this.selectedActionComment) {
      this.potentialTalentSvc.updateNoteByIdp({
        noteText: this.selectedActionCommentText,
        noteId: this.selectedActionComment.noteId,
        perfCareYear: parseInt(this.processYear),
      }).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          console.error('[development-plan-tab] updateNoteByIdp$ error', data.error);
          this.onCloseAddOrEditComment();
          this.modalService.open(this.failedToAddCommentModalId);
        }
        this.onCloseAddOrEditComment();
        this.getActions();
      }, (err) => {
        console.error('[development-plan-tab] updateNoteByIdp$ error', err);
        this.onCloseAddOrEditComment();
        this.modalService.open(this.failedToAddCommentModalId);
      })
    }
  }
  // END ADD OR EDIT COMMENT


  // DELETE COMMENT
  deleteComment(data: { action: DevelopmentAction, commentId: DevelopmentAction['comment'][number]['commentId'] }) {
    if (data && data.commentId) {
      this.potentialTalentSvc.deleteNoteByIdp({
        noteId: data.commentId,
        perfCareYear: parseInt(this.processYear),
      }).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          console.error('[development-plan-tab] deleteNote', data.error);
          this.modalService.open(this.failedToAddCommentModalId);
        }
        this.getActions();
      }, (err) => {
        console.error('[development-plan-tab] deleteNote', err);
        this.modalService.open(this.failedToAddCommentModalId);
        this.getActions();
      })
    }

  }

  // END DELETE COMMENT


  closeModal(modalId: string) {
    this.modalService.close(modalId);
  }

  closeFailedToAddCommentModal() {
    this.modalService.close(this.failedToAddCommentModalId);
  }

  addAction() {
    this.router.navigate(['create-action'], { relativeTo: this.route });
  }

  private mapper(action: TalentIdp, user: User): DevelopmentAction {
    const placeholder = '-';

    return {
      iconTop: 'assets/img/icons/target.svg',
      type: 'developmentAction',
      id: action.rentId,
      status: action.status,
      statusObject: {
        status: action.status,
        statusMessage: `talent.development-plan-tab.statuses.${action.status}`
      },
      formattedCreationDate: this.datePipe.transform(
        action.creationDate,
        'dd/MM/yyyy'
      ),
      user,
      creationUserId: user.userId,
      idp: {
        developmentAction: action.developmentAction,
        date: action.date || placeholder,
        duration: action.duration || placeholder,
        type: action.type || placeholder,
        referentName: action.referentName || placeholder,
        userId: action.userId,
        description: action.description || placeholder,
      },
      isDevelopmentAction: true,
      creationUser: user,
      comment:
        action.notes?.map((comment) => this.commentMapper(comment)) ?? [],
    };
  }

  private commentMapper(note: TalentIdpNote): DevelopmentAction['comment'][number] {
    return {
      commentId: note.noteId,
      creationUser: note.creationUser,
      creationDate: this.datePipe.transform(note.creationDate, 'dd/MM/yyyy'),
      text: note.text,
      noteId: note.noteId,
      idpTalentRentId: note.idpTalentRentId,
    }
  }

  private openSnackbar() {
    let x: any = document.getElementById("snackbar");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 3000);
    }
  }

  ngOnDestroy() {
    if (this.getRoleGoal$) {
      this.getRoleGoal$.unsubscribe();
    }
    if (this.createRoleGoal$) {
      this.createRoleGoal$.unsubscribe();
    }
    if (this.updateRoleGoal$) {
      this.updateRoleGoal$.unsubscribe();
    }
    if (this.getDevelopmentGoal$) {
      this.getDevelopmentGoal$.unsubscribe();
    }
    if (this.createDevelopmentGoal$) {
      this.createDevelopmentGoal$.unsubscribe();
    }
    if (this.updateDevelopmentGoal$) {
      this.updateDevelopmentGoal$.unsubscribe();
    }
    if (this.getActions$) {
      this.getActions$.unsubscribe();
    }
    if (this.deleteActions$) {
      this.deleteActions$.unsubscribe();
    }
  }
}
