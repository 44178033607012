import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareMidTermReviewGoalAdvancementStatus, GetMyManagerUserDataResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, ParseUtils } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { GoalUtils } from 'src/app/utils/goal.utils';
import * as moment from 'moment';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { Subordinateservice } from 'src/app/manager/services/subordinates.service';

@Component({
  selector: 'app-admin-confirmPerformanceLevel',
  templateUrl: './confirmPerformanceLevel.component.html',
  styleUrls: ['./confirmPerformanceLevel.component.scss']
})
export class AdminConfirmPerformanceLevelManagerComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserGoalList$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;
  objectiveFeedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveOsservationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isLoadingUserGoals: boolean = false;
  goalList: any[] = [];

  careCards: any[] = [];
  cardSelected: any;

  osservation: string = '';

  isFromBack: boolean = false;
  sendRevision$: Subscription = new Subscription;
  statusForRecap: string = '';
  userRevisionDate: string = '';
  isLoadingManagerInfo: boolean = false;
  managerData: any;
  managerAnswer: boolean = false;
  managerRevisionDate: string = '';
  managerOsservation: string = '';
  isOperations: boolean = false;
  backWithData: boolean = false;

  charsFinalObservation: number = 600;
  finalObservation: string = '';
  observationTooltip: ApplicationModalMessage = {
    modalId: 'pl002',
    title: '',
    text: ''
  }
  selectedMark: any;
  userId: string = '';
  payoutTooltip: ApplicationModalMessage = {
    modalId: 'pl004',
    title: '',
    text: ''
  }
  selectedPayout: any;
  payoutOptions: any[] = [];
  isLoadingPayoutOptions: boolean = false;
  isLoadingUserFinalEvaluation: boolean = false;
  getFinalEvaluation$: Subscription = new Subscription;
  result$: Subscription = new Subscription;
  runningPhase: any;
  personDetails: any;
  getPersonDetails$: Subscription = new Subscription;
  payoutResponse: any;
  isLoadingPersonDetails: boolean = false;
  userFinalEvaluation: string = '';
  userFinalEvaluationObservation: any;
  userFinalEvaluationDate: any;
  isSti: boolean = false;

  constructor(
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private subordinatesService: Subordinateservice
  ) {
    this.isOperations = window.location.href.indexOf('collaboratorOperations') > -1;
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              const loggedUser$ = this.store.select(fromApp.getLoggedUser);
              const runningPhase$ = this.store.select(fromApp.getRunningPhase);
              const personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses);
              const combinedSelectes$ = combineLatest([loggedUser$, runningPhase$, personDetails$]);
              this.result$ = combinedSelectes$.subscribe(
                ([loggedUser, runningPhase, subordinatesList]) => {
                  this.loggedUser = loggedUser.user;
                  this.runningPhase = runningPhase;
                  this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                    return person.userId === this.userId;
                  })[0];
                  // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                  if (!this.personDetails) {
                    this.getPersonDetails();
                  }
                  if (this.personDetails) {
                    this.isSti = !isNaN(ParseUtils.parseInt(this.personDetails.stiAmount));
                    if (this.loggedUser) {
                      this.managerData = this.loggedUser;
                      this.getPayoutOptions();
                      this.getMidTermGoals();
                      this.getFinalEvaluation();
                    }
                  }
                });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT',
        'midTerm.addMidTermReview.IN_RETARD',
        'midTerm.addMidTermReview.IN_LINE',
        'midTerm.addMidTermReview.GREAT_POINT',
        'performance.PAYOUT_PLACEHOLDER',
        'performance.INSERT_OBSERVATION',
        'performance.OBSERVATION_TOOLTIP',
        'performance.SET_PAYOUT',
        'performance.SET_PAYOUT_TOOLTIP',
      ])
      .subscribe(translations => {
        this.objectiveFeedbackTooltipModal = {
          modalId: "mt002",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT'],
        };
        this.objectiveOsservationTooltipModal = {
          modalId: "mt003",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT'],
        }
        this.observationTooltip = {
          modalId: "pl002",
          title: translations['performance.INSERT_OBSERVATION'],
          text: translations['performance.OBSERVATION_TOOLTIP'],
        }
        this.payoutTooltip = {
          modalId: "pl004",
          title: translations['performance.SET_PAYOUT'],
          text: translations['performance.SET_PAYOUT_TOOLTIP'],
        }

        this.careCards = [{
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.LATE,
          title: translations['midTerm.addMidTermReview.IN_RETARD'],
          isChecked: false,
          icon: "assets/img/icons/red-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.ON_LINE,
          title: translations['midTerm.addMidTermReview.IN_LINE'],
          isChecked: false,
          icon: "assets/img/icons/yellow-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.WELL_ADVANCED,
          title: translations['midTerm.addMidTermReview.GREAT_POINT'],
          isChecked: false,
          icon: "assets/img/icons/green-face.svg"
        }]
        this.selectedPayout = {
          id: null,
          title: translations['performance.PAYOUT_PLACEHOLDER']
        }
      })
  }

  getPersonDetails() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData && personDetailsData.error) {
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.isSti = !isNaN(ParseUtils.parseInt(this.personDetails.stiAmount));
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.finalObservation && this.finalObservation.trim().length && this.selectedMark;
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return this.selectedMark || (this.finalObservation && this.finalObservation.trim().length);
  }

  sendRevision() {
    // this.goBackBrowser();
    this.isLoadingUserGoals = true;
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    this.sendRevision$ = this.managerService.setMidTermReviewRevision(this.runningYear, this.osservation, this.cardSelected.id)
      .subscribe((data: any) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj = {
            modalId: "mt005",
            title: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS"),
            text: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
        }
        this.goBackBrowser();
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt006",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  sendRevisionAction(isBack?: boolean) {
    if (isBack && !this.isObbligatoryDataInserted()) {
      if (this.checkIfDataIsChanged()) {
        this.backWithData = true;
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      } else {
        this.goBackBrowser();
      }
    } else if (this.isObbligatoryDataInserted()) {
      if (isBack) {
        this.isFromBack = true;
      } else {
        this.isFromBack = false;
      }
      this.modalService.open("modalCreateRevisionForCollaborator");
    } else {
      if (isBack) {
        if (this.checkIfDataIsChanged()) {
          this.modalService.open("modalCreateRevisionNoDataForCollaborator");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      }
    }
  }

  closeModalCreateRevisionNoDataForCollaborator() {
    this.modalService.close("modalCreateRevisionNoDataForCollaborator");
    this.backWithData = false
  }

  closeModalCreateRevisionForCollaborator() {
    this.modalService.close("modalCreateRevisionForCollaborator");
  }

  osservationChanged(text: string) {
    this.osservation = text;
  }

  closeObjectiveFeedbackTooltip() {
    this.modalService.close("objectiveFeedbackModalTooltip");
  }

  openObjectiveFeedbackTooltip() {
    this.modalService.open("objectiveFeedbackModalTooltip");
  }

  onSelectCareCard(cards: any) {
    this.cardSelected = cards.filter((x: any) => {
      return x.isChecked == true;
    })[0];
  }

  getMidTermGoals() {
    this.isLoadingUserGoals = true;
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    this.getUserGoalList$ = this.managerService.getMidTermGoals(this.runningYear, this.userId)
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (goalList.response && goalList.response.goals && goalList.response.goals.length) {
            this.goalList = GoalUtils.formatGoalCardList(this.translate, goalList.response.goals, false, this.loggedUser.userId);
            // Dati osservazione collaboratore
            if (goalList.response.midTermReviewUserGoalAdvancement && goalList.response.midTermReviewUserGoalAdvancement.length) {
              this.osservation = goalList.response.midTermReviewUserObservation;
              this.statusForRecap = goalList.response.midTermReviewUserGoalAdvancement;
              this.userRevisionDate = moment(goalList.response.midTermReviewUserObservationDate).format('DD.MM.YYYY');
            }
            // Dati risposta manager
            if (goalList.response.midTermReviewManagerObservationDate && goalList.response.midTermReviewManagerObservationDate.length) {
              this.managerAnswer = true;
              this.managerRevisionDate = moment(goalList.response.midTermReviewManagerObservationDate).format('DD.MM.YYYY');
              this.managerOsservation = goalList.response.midTermReviewManagerObservation;
            }
          } else {
            this.goalList = [];
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  getFinalEvaluation() {
    this.isLoadingUserFinalEvaluation = true;
    if (this.getFinalEvaluation$) {
      this.getFinalEvaluation$.unsubscribe();
    }
    this.getFinalEvaluation$ = this.managerService.getUserFinalEvaluation(this.runningYear, this.userId)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error || !feData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (feData.response.subordinateObservationLevel) {
            this.userFinalEvaluation = feData.response.subordinateObservationLevel;
            this.userFinalEvaluationObservation = feData.response.subordinateObservation;
            this.userFinalEvaluationDate = feData.response.creationDate;
          }
        }
        this.isLoadingUserFinalEvaluation = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserFinalEvaluation = false;
      });
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  finalObservationChanged(newText: string) {
    this.finalObservation = newText;
  }

  selectPerformanceLevel(mark: any) {
    this.selectedPayout = {
      id: null,
      title: this.translate.instant('performance.PAYOUT_PLACEHOLDER')
    };
    this.selectedMark = mark;
    this.payoutOptions = this.payoutResponse[mark];
    if (this.payoutOptions.length == 1) {
      this.selectedPayout = this.payoutOptions[0];
    }
  }

  getPayoutOptions() {
    this.isLoadingPayoutOptions = true;
    this.managerService.getPayoutRange()
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pl010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.payoutResponse = {};
          for (let i = 0; i < data.response.length; i++) {
            if (data.response[i].moreOrEqualPayout) {
              let tmpEvaluation = [];
              for (let j = data.response[i].moreOrEqualPayout; j <= data.response[i].lessPayout; j++) {
                tmpEvaluation.push({
                  id: j,
                  title: j + '%'
                })
              }
              this.payoutResponse[data.response[i].finalEvaluation] = tmpEvaluation;
            } else {
              this.payoutResponse[data.response[i].finalEvaluation] = [{
                id: data.response[i].payout,
                title: data.response[i].payout + '%'
              }]
            }
          }
        }
        this.isLoadingPayoutOptions = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pl011",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPayoutOptions = false;
      });
    // switch (mark) {
    //   case 'IMPROVABLE':
    //     this.payoutOptions = [
    //       {
    //         id: 40,
    //         title: "40%"
    //       },
    //       {
    //         id: 60,
    //         title: "60%"
    //       },
    //       {
    //         id: 80,
    //         title: "80%"
    //       }
    //     ];
    //     this.isLoadingPayoutOptions = false;
    //     break;
    //   case 'MEET':
    //     this.payoutOptions = [
    //       {
    //         id: 95,
    //         title: "95%"
    //       },
    //       {
    //         id: 100,
    //         title: "100%"
    //       },
    //       {
    //         id: 105,
    //         title: "105%"
    //       }
    //     ];
    //     this.isLoadingPayoutOptions = false;
    //     break;
    //   case 'EXCEEDED':
    //     this.payoutOptions = [
    //       {
    //         id: 110,
    //         title: "110%"
    //       },
    //       {
    //         id: 115,
    //         title: "115%"
    //       },
    //       {
    //         id: 120,
    //         title: "120%"
    //       }
    //     ];
    //     this.isLoadingPayoutOptions = false;
    //     break;
    //   case 'EXCEPTIONAL':
    //     this.payoutOptions = [
    //       {
    //         id: 130,
    //         title: "130%"
    //       },
    //       {
    //         id: 150,
    //         title: "150%"
    //       },
    //       {
    //         id: 200,
    //         title: "200%"
    //       }
    //     ];
    //     this.isLoadingPayoutOptions = false;
    //     break;
    //   default:
    //     this.payoutOptions = [
    //       {
    //         id: 0,
    //         title: "0%"
    //       }
    //     ];
    //     this.isLoadingPayoutOptions = false;
    //     break;
    // }
  }

  changePayoutPercentage(newValue: any) {
    this.selectedPayout = newValue;
  }

  selectFinalEvaluation(finalEvaluation: any) {
    this.selectedMark = finalEvaluation;
  }

  openConfirmModal() {
    this.modalService.open('recapConfirm')
  };

  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('recapConfirm')
    if (confirm) {
      let userFinalEvaluation = {
        userId: this.userId,
        subordinateObservationLevel: this.userFinalEvaluation,
        subordinateObservation: this.userFinalEvaluationObservation,
        managerObservation: this.finalObservation,
        managerObservationLevel: this.selectedMark,
        payout: this.selectedPayout.id
      }

      this.managerService.setUserFinalEvaluation(this.runningYear, userFinalEvaluation)
        .subscribe((setEvaluation: SenecaResponse<any>) => {
          if (setEvaluation && setEvaluation.error || !setEvaluation.response) {
            let error = setEvaluation.error ? this.translate.instant("errors." + setEvaluation.error) : this.translate.instant('errors.SOMETHING_WENT_WRONG');
            const messageObj: ApplicationModalMessage = {
              modalId: "pl012",
              text: error,
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.goBackBrowser();
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pl013",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  getUserGoalEvaluation(goal: any) {
    let tmpData = goal.goalEvaluation?.find((x: any) => x.user?.userId == this.userId);
    return tmpData && tmpData.evaluationScore;
  }

  confirmDisabled() {
    if (this.isSti) {
      return !(this.selectedPayout && this.selectedPayout.id) || !this.selectedMark || !this.finalObservation
    } else {
      return !this.selectedMark || !this.finalObservation;
    }
  }

  openMarkCardInfoModal() {
    const messageObj: ApplicationModalMessage = {
      modalId: "pl003",
      title: this.translate.instant('performance.INSERT_VALUE'),
      text: this.translate.instant("performance.VALUE_TOOLTIP"),
    }
    this.modalService.open('modal-info');
  }

  closeModalInfo() {
    this.modalService.close('modal-info');
  }

  ngOnDestroy() {
    if (this.result$) {
      this.result$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    if (this.getFinalEvaluation$) {
      this.getFinalEvaluation$.unsubscribe();
    }
  }
}