/*
*    Servizio che gestisce i vari redirect
*/

import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { JwtPayload } from "../../../commonclasses";
import { Router } from "@angular/router";

@Injectable()
export class RedirectService implements OnDestroy {
  isMainMenuSidebarOpened: boolean = false;
  loggedUser: any;
  isManager: boolean = false;
  isCollaborator: boolean = false;
  isAdmin: boolean = false;
  combinedSelectes$: Subscription;
  isOperation: boolean = false;
  isSti: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    const isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager)
    const isCollaborator$: Observable<boolean> = this.store.select(fromApp.getIsCollaborator);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const isSti$: Observable<any> = this.store.select(fromApp.getIsSti);
    const isOperations$: Observable<boolean> = this.store.select(fromApp.getIsOperation);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelectes$ = combineLatest([isMainMenuSidebarOpened$, loggedUser$, isManager$, isCollaborator$, isAdmin$, isOperations$, isSti$])
      .subscribe(
        ([isMainMenuSidebarOpened, loggedUser, isManager, isCollaborator, isAdmin, isOperation, isSti]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.isManager = isManager;
            this.isCollaborator = isCollaborator;
            this.isAdmin = isAdmin;
            this.isOperation = isOperation;
            this.isSti = isSti;
            this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
          }
        });
  }

  // Verifica se sono in una particolare pagina
  isThisCurrentPage(page: string) {
    if (page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }
    return false;
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(CoreActions.CloseMainMenuSidebar());
  }

  // Redirect alla home page dell'utente loggato
  goToHome(fromHeader?: boolean) {
    if (fromHeader) {
      // riporta alla home del modulo in cui sono, per risolvere problemi di redirect
      // per i profili con più auth
      if (window.location.href.indexOf('/manager/') >= 0) {
        this.router.navigate(['/manager/home']);
      } else if (window.location.href.indexOf('/collaboratorOperations/') >= 0) {
        this.router.navigate(['/collaboratorOperations/home']);
      } else if (window.location.href.indexOf('/collaborator/') >= 0) {
        this.router.navigate(['/collaborator/home']);
      } else if (window.location.href.indexOf('/admin/') >= 0) {
        this.router.navigate(['/admin/home']);
      }
    } else {
      if (this.isManager) {
        this.router.navigate(['/manager/home']);
      } else if (this.isAdmin) {
        this.router.navigate(['/admin/home']);
      } else if (this.isCollaborator && !this.isOperation) {
        this.router.navigate(['/collaborator/home']);
      } else {
        this.router.navigate(['/collaboratorOperations/home']);
      }
    }
  }

  // Vai al feedback section
  goToPersonDetailsFeedback(userId: string, perfCareYear?: number | string) {
    this.router.navigate(['/manager/personDetails/feedback/peerFeedback/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
  }

  goToPersonDetailsFeedbackCollaborator(userId: string, operations?: boolean, perfCareYear?: number) {
    if (operations) {
      this.router.navigate(['/collaboratorOperations/personDetails/feedback/peerFeedback/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
    } else {
      this.router.navigate(['/collaborator/personDetails/feedback/peerFeedback/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
    }
  }

  // Vai alla pagine crea/aggiorna applauso
  goToSendClapPageCollaborator(userId?: string, id?: string, operations?: boolean) {
    if (operations) {
      if (userId) {
        if (id) {
          this.router.navigate(['/collaboratorOperations/sendApplause/' + userId + '/' + id]);
        } else {
          this.router.navigate(['/collaboratorOperations/sendApplause/' + userId]);
        }
      } else {
        this.router.navigate(['/collaboratorOperations/sendApplause']);
      }
    } else {
      if (userId) {
        if (id) {
          this.router.navigate(['/collaborator/sendApplause/' + userId + '/' + id]);
        } else {
          this.router.navigate(['/collaborator/sendApplause/' + userId]);
        }
      } else {
        this.router.navigate(['/collaborator/sendApplause']);
      }
    }
  }

  goToFeedbackInterview(userId: string, isOperations?: boolean) {
    if (isOperations) {
      this.router.navigate(['/collaboratorOperations/personDetails/feedback/interview/' + userId]);
    } else {
      this.router.navigate(['/collaborator/personDetails/feedback/interview/' + userId]);
    }
  }

  // Vai alla pagine crea/aggiorna applauso
  goToSendClapPageManager(userId?: string, id?: string, operations?: boolean) {
    if (userId) {
      if (id) {
        this.router.navigate(['/manager/sendApplause/' + userId + '/' + id]);
      } else {
        this.router.navigate(['/manager/sendApplause/' + userId]);
      }
    } else {
      this.router.navigate(['/manager/sendApplause']);
    }
  }

  goToCreatePrivateNoteForManager(userId?: string, noteId?: string, isOperations?: boolean) {
    if (isOperations) {
      if (userId) {
        if (noteId) {
          this.router.navigate(['/manager/createUpdatePrivateNote/' + userId + '/' + noteId]);
        } else {
          this.router.navigate(['/manager/createUpdatePrivateNote/' + userId]);
        }
      } else {
        this.router.navigate(['/manager/createUpdatePrivateNote']);
      }
    } else {
      if (userId) {
        if (noteId) {
          this.router.navigate(['/manager/createUpdatePrivateNote/' + userId + '/' + noteId]);
        } else {
          this.router.navigate(['/manager/createUpdatePrivateNote/' + userId]);
        }
      } else {
        this.router.navigate(['/manager/createUpdatePrivateNote']);
      }
    }
  }

  goToCreatePrivateNote(userId?: string, noteId?: string, isOperations?: boolean) {
    if (isOperations) {
      if (userId) {
        if (noteId) {
          this.router.navigate(['/collaboratorOperations/createUpdatePrivateNote/' + userId + '/' + noteId]);
        } else {
          this.router.navigate(['/collaboratorOperations/createUpdatePrivateNote/' + userId]);
        }
      } else {
        this.router.navigate(['/collaboratorOperations/createUpdatePrivateNote']);
      }
    } else {
      if (userId) {
        if (noteId) {
          this.router.navigate(['/collaborator/createUpdatePrivateNote/' + userId + '/' + noteId]);
        } else {
          this.router.navigate(['/collaborator/createUpdatePrivateNote/' + userId]);
        }
      } else {
        this.router.navigate(['/collaborator/createUpdatePrivateNote']);
      }
    }
  }

  // Vai alle notifiche
  goToNotifications() {
    if (this.isManager) {
      this.router.navigate(['/manager/notifications']);
    } else if (this.isCollaborator && !this.isOperation) {
      this.router.navigate(['/collaborator/notifications']);
    } else {
      this.router.navigate(['/collaboratorOperations/notifications']);
    }
  }

  // Vai al video onboarding
  goToOnboardingVideo() {
    if (this.isManager) {
      this.router.navigate(['/manager/onboarding']);
    } else if (this.isCollaborator && !this.isOperation) {
      this.router.navigate(['/collaborator/onboarding']);
    } else {
      this.router.navigate(['/collaboratorOperations/onboarding']);
    }
  }

  // Vai alla pagina personDetails obiettivi
  goToPersonDetails(userId?: string, perfCareYear?: number | string) {
    if (userId) {
      this.router.navigate(['/manager/personDetails/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
    } else {
      this.router.navigate(['/manager/personDetails' + (perfCareYear ? ('/' + perfCareYear) : '')]);
    }
  }

  // Vai alla pagina personDetails piano di sviluppo
  goToPersonDetailsDevelopmentPlan(userId: string, perfCareYear?: number) {
    this.router.navigate(['/manager/personDetails/developmentPlan/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
  }

  goToOperationsPersonDetails(userId?: string, isCollaborator?: boolean) {
    if (isCollaborator && this.isOperation) {
      if (userId) {
        this.router.navigate(['/collaboratorOperations/personDetails/' + userId]);
      } else {
        this.router.navigate(['/collaboratorOperations/personDetails']);
      }
    } else if (isCollaborator && !this.isOperation) {
      if (userId) {
        this.router.navigate(['/collaborator/personDetails/' + userId]);
      } else {
        this.router.navigate(['/collaborator/personDetails']);
      }
    } else {
      if (userId) {
        this.router.navigate(['/manager/personDetails/' + userId]);
      } else {
        this.router.navigate(['/manager/personDetails']);
      }
    }
  }

  // Vai alla pagina personDetails collaboratore
  goToPersonDetailsCollaborator(userId?: string, operations?: boolean, feedback?: boolean, perfCareYear?: number) {
    if (operations && !this.isSti) {
      if (feedback) {
        this.router.navigate(['/collaboratorOperations/personDetails/feedback/smartFeedback/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
      } else {
        this.router.navigate(['/collaboratorOperations/personDetails/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
      }
    } else {
      if (feedback) {
        this.router.navigate(['/collaborator/personDetails/feedback/smartFeedback/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
      } else {
        this.router.navigate(['/collaborator/personDetails/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
      }
    }
  }


  goToPersonDetailsDevelopmentPlanCollaborator(userId: string, operations?: boolean, perfCareYear?: number) {
    if (operations) {
      this.router.navigate(['/collaboratorOperations/personDetails/developmentPlan/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
    } else {
      this.router.navigate(['/collaborator/personDetails/developmentPlan/' + userId + (perfCareYear ? ('/' + perfCareYear) : '')]);
    }
  }

  goToCreatePeerFeedbackManager(userId: string, feedbackId: string, isOperations?: boolean, isNoCollaboratorManager?: boolean) {
    if (isNoCollaboratorManager) {
      this.router.navigate(['/manager/createUpdatePeerFeedback/ext/' + userId + '/' + feedbackId]);
    } else {
      this.router.navigate(['/manager/createUpdatePeerFeedback/' + userId + '/' + feedbackId]);
    }
  }

  // Peer Feedback collaboratori
  goToCreatePeerFeedback(userId?: string, feedbackId?: string, isOperations?: boolean) {
    if (isOperations) {
      if (userId) {
        if (feedbackId) {
          this.router.navigate(['/collaboratorOperations/createUpdatePeerFeedback/' + userId + '/' + feedbackId]);
        } else {
          this.router.navigate(['/collaboratorOperations/createUpdatePeerFeedback/' + userId]);
        }
      } else {
        this.router.navigate(['/collaboratorOperations/createUpdatePeerFeedback']);
      }
    } else {
      if (userId) {
        if (feedbackId) {
          this.router.navigate(['/collaborator/createUpdatePeerFeedback/' + userId + '/' + feedbackId]);
        } else {
          this.router.navigate(['/collaborator/createUpdatePeerFeedback/' + userId]);
        }
      } else {
        this.router.navigate(['/collaborator/createUpdatePeerFeedback']);
      }
    }
  }
  goToMidTermCollaborator(isOperations?: boolean) {
    if (isOperations) {
      this.router.navigate(['/collaboratorOperations/addMidTermReview']);
    } else {
      this.router.navigate(['/collaborator/addMidTermReview']);
    }
  }

  goToMidTermManager(userId: string, isOperations?: boolean) {
    this.router.navigate(['/manager/addMidTermReview/' + userId]);
  }

  // Tora indietro history browser
  goBackBrowser() {
    window.history.back();
  }

  // E' nella pagina passata
  isInThisPage(page: string) {
    if (
      page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }

    return false;
  }

  // Pagina di errore generico
  goToErrorPage(errorCode?: string) {
    if (errorCode) {
      this.router.navigate(['/errorPage/' + errorCode]);
    } else {
      this.router.navigate(['/errorPage']);
    }
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    if (this.combinedSelectes$) {
      this.combinedSelectes$.unsubscribe();
    }
  }
}
