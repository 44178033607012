<!-- Toaster -->
<div id="snackbar">
  <p translate="generic.SAVE_SUCCESSFUL"></p>
</div>

<div class="page-content-wrapper admin-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <!--header-dossier *ngIf="!isLoadingPersonDetails" [hasTabs]="false" [user]="personDetails">
  </header-dossier-->

  <header-dossier *ngIf="!isLoadingPersonDetails" [isAdminHeader]="true"
    [adminPageTitle]="('editDataList.PAGE_TITLE' | translate)"
    [adminPageSubtitle]="('editDataList.PAGE_SUBTITLE' | translate) + ' ' + runningYear" [adminBack]="false"
    [hasTabs]="false" [user]="personDetails">
  </header-dossier>

  <div class="main-content-wrapper admin">
    <div class="page-action-row">
      <europ-button iconLeft="assets/img/icons/arrow-left.svg" [label]="('generic.GO_BACK' | translate)"
        (onButtonClicked)="redirectService.goBackBrowser()" type="primary link" size="medium">
      </europ-button>
    </div>

    <div class="grid-containers">
      <ng-container *ngIf="!isLoadingPersonDetails; else loading">

        <!-- Modifica obiettivi -->
        <div class="admin-table-container align-left">
          <!-- Titolo -->
          <p class="graph-title" translate="editDataList.component.EDIT_OBJECTIVES"> </p>


          <div class="data-grid-container" *ngIf="!isLoadingEditableGoals; else loadingMedium">
            <!-- Obiettivi modificabili, con possibilità di aggiungerne o rimuoverne -->
            <dx-data-grid id="objectiveGrid" class="min-size" [dataSource]="editableObjectives" keyExpr="ID"
              [allowColumnReordering]="true" [showBorders]="true" (onSaving)="onSaveObjectiveRow($event)"
              (onRowRemoving)="onDeleteRow($event)" [showRowLines]="true">
              <!-- caricamento integrato del componente, ma non funziona benissimo, c'è del delay
                (beginCustomLoading)="isLoadingEditableGoals"
              (endCustomLoading)="!isLoadingEditableGoals"-->

              <!--dxo-paging [enabled]="true"></dxo-paging-->
              <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
              </dxo-editing>

              <!-- nome obiettivo -->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataField="title"
                caption="{{ 'editDataList.component.OBJECTIVE' | translate }}">
              </dxi-column>
              <!-- Peso obiettivo-->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataField="weight" [width]="67"
                caption="{{ 'editDataList.component.WEIGHT' | translate }}">
              </dxi-column>
              <!-- Deadline / Scadenza -->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataType="date" dataField="deadlineDate"
                [width]="84" format="dd/MM/yyyy" caption="{{ 'editDataList.component.DEADLINE' | translate }}">
              </dxi-column>
              <!-- Descrizione -->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataField="description"
                caption="{{ 'editDataList.component.DESCRIPTION' | translate }}"></dxi-column>
              <!-- CARE tag -->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataField="careTagId"
                caption="{{ 'editDataList.component.CARE' | translate }}" [width]="103">
                <dxo-lookup [dataSource]="careOptions" displayExpr="title" valueExpr="id">
                </dxo-lookup>
              </dxi-column>
              <!-- Azioni -->
              <dxi-column type="buttons">
                <dxi-button name="save" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.SAVE' | translate }}">
                </dxi-button>
                <dxi-button name="cancel" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.CANCEL' | translate }}">
                </dxi-button>
                <dxi-button name="edit" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.EDIT' | translate }}">
                </dxi-button>
                <dxi-button name="delete" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.DELETE' | translate }}">
                </dxi-button>
              </dxi-column>
            </dx-data-grid>
          </div>
        </div>


        <!-- Modifica People empowerment - UPDATE 17/1/24: Eliminato dalle tavole -->
        <!--div class="admin-table-container align-left">
          <!-- Titolo ->
        <p class="graph-title margin" translate="editDataList.component.EDIT_PEOPLE_EMPOWERMENT"> </p>
        <div class="data-grid-container" *ngIf="!isLoadingEditableGoals; else loadingMedium">
          <!-- Data grid people empowerment ->
          <ng-container *ngIf="peopleEmpowerment && peopleEmpowerment.length; else noPeopleEmpowerment">
            <dx-data-grid id="objectiveGrid" class="data-grid-margin-no-button min-size"
              [dataSource]="peopleEmpowerment" keyExpr="ID" [allowColumnReordering]="true" [showBorders]="true"
              (onSaving)="onSaveObjectiveRow($event, true)" (onRowRemoving)="onDeleteRow($event)" [showRowLines]="true"
              (beginCustomLoading)="isLoadingEditableGoals" (endCustomLoading)="!isLoadingEditableGoals">

              <!--dxo-paging [enabled]="true"></dxo-paging->
              <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false">
              </dxo-editing>


              <!-- nome obiettivo ->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataField="title"
                caption="{{ 'editDataList.component.OBJECTIVE' | translate }}">
              </dxi-column>
              <!-- Descrizione ->
              <dxi-column alignment="center" cssClass="grid-cell-title" dataField="description"
                caption="{{ 'editDataList.component.DESCRIPTION' | translate }}"></dxi-column>

              <!-- Azioni ->
              <dxi-column type="buttons">
                <dxi-button name="save" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.SAVE' | translate }}">
                </dxi-button>
                <dxi-button name="cancel" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.CANCEL' | translate }}">
                </dxi-button>
                <dxi-button name="edit" cssClass="link-cell-text"
                  text="{{ 'editDataList.component.EDIT' | translate }}">
                </dxi-button>
              </dxi-column>

              <!-- Editing ->
              <dxo-editing>
                <dxo-texts confirmDeleteTitle="Elimina obiettivo"
                  confirmDeleteMessage="vuoi davvero eliminare questo oBiettivo?">
                </dxo-texts>
                <dxi-button name="yes" cssClass="link-cell-text" text="N as ds ds ds O">
                </dxi-button>
                <dxi-button name="no" cssClass="link-cell-text" text="SMODAMSODMAOSDMSAD">
                </dxi-button>
              </dxo-editing>

            </dx-data-grid>
          </ng-container>
          <!-- Placeholder nessun obiettivo people empowerment ->
          <ng-template #noPeopleEmpowerment>
            <p class="placeholder-no-data" translate="editDataList.component.NO_PEOPLE_EMPOWERMENT"></p>
          </ng-template>
        </div>
        </div-->

        <!-- Modifica valutazioni -->
        <div class="admin-table-container align-left">

          <!-- Titolo -->
          <p class="graph-title" translate="editDataList.component.EDIT_EVALUATIONS"> </p>
          <p class="graph-subtitle" translate="editDataList.component.EDIT_EVALUATIONS_SUBTITLE"> </p>

          <div class="data-grid-container evaluation-top-margin"
            *ngIf="!isLoadingEditableEvaluations; else loadingMedium">
            <!-- Tabella valutazioni -->
            <ng-container *ngIf="evaluationsObjectives && evaluationsObjectives.length; else noEvaluations">
              <dx-data-grid id="evaluationGrid" class="data-grid-margin-no-button min-size"
                [dataSource]="evaluationsObjectives" keyExpr="ID" [allowColumnReordering]="true" [showBorders]="true"
                (onSaving)="onSaveEvaluationRow($event)" [showRowLines]="true">

                <!--dxo-paging [enabled]="true"></dxo-paging-->
                <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">
                </dxo-editing>

                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="title" [allowEditing]="false"
                  caption="{{ 'editDataList.component.OBJECTIVE' | translate }}"></dxi-column>

                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="selfEvaluationId"
                  caption="{{ 'editDataList.component.SELF' | translate }}" [width]="125">
                  <dxo-lookup [dataSource]="evaluationOptions" displayExpr="title" valueExpr="id">
                  </dxo-lookup>
                </dxi-column>

                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="managerEvaluationId"
                  caption="{{ 'editDataList.component.EVALUATION' | translate }}" [width]="125">
                  <dxo-lookup [dataSource]="evaluationOptions" displayExpr="title" valueExpr="id">
                  </dxo-lookup>
                </dxi-column>

                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="managerObservation"
                  caption="{{ 'editDataList.component.OBSERVATION' | translate }}"></dxi-column>

                <!-- Azioni -->
                <dxi-column type="buttons">
                  <dxi-button name="save" cssClass="link-cell-text"
                    text="{{ 'editDataList.component.SAVE' | translate }}">
                  </dxi-button>
                  <dxi-button name="cancel" cssClass="link-cell-text"
                    text="{{ 'editDataList.component.CANCEL' | translate }}">
                  </dxi-button>
                  <dxi-button name="edit" cssClass="link-cell-text"
                    text="{{ 'editDataList.component.EDIT' | translate }}">
                  </dxi-button>
                </dxi-column>
              </dx-data-grid>
            </ng-container>
            <!-- Placeholder nessuna valutazione -->
            <ng-template #noEvaluations>
              <p class="placeholder-no-data" translate="editDataList.component.NO_EVALUATIONS"></p>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Placeholder caricamento grande -->
    <ng-template #loading>
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-template>
    <!-- Placegolder caricamento medio -->
    <ng-template #loadingMedium>
      <div class="loader-container">
        <div class="loader spinner-medium"></div>
      </div>
    </ng-template>
  </div>
</div>