<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.gt-xs="16px">

  <banner-home [phaseName]="phaseName" [backgroundSrc]="bannerImage"
    [endDate]="runningPhase.deadlineDate || runningPhase.phaseEndDate">
  </banner-home>

  <div class="box-collection-container">
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
      fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">
      <!-- Setup processi (onButtonClicked)="goToProcessSetup()"-->
      <!-- disabilita [isDisabled]="true" [disabledModalMessage]="boxDisabledModal"-->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/settings.svg"
        backgroundColor="#E0DAFF" title="{{'adminHome.cards.PROCESS_SETUP' | translate}}"
        description="adminHome.cards.PROCESS_SETUP_DESCR" [isNew]="false" (onButtonClicked)="goToProcessSetup()">
      </box-home>
      <!-- Anagrafica delle persone  (onButtonClicked)="goToPeopleAnag()" -->
      <!--box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" [isDisabled]="true"
        [disabledModalMessage]="boxDisabledModal" iconSrc="../../../../assets/img/icons/users.svg"
        backgroundColor="#FFD1D3" title="{{'adminHome.cards.ANAG' | translate}}"
        description="adminHome.cards.ANAG_DESCR" [isNew]="false"></box-home-->
      <!-- Impersonifica -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToImpersonate()"
        iconSrc="../../../../assets/img/icons/user.svg" backgroundColor="#FFECCB"
        title="{{'adminHome.cards.IMPERSONATE' | translate}}" description="adminHome.cards.IMPERSONATE_DESCR"
        [isNew]="false"></box-home>
      <!-- Modifica dati -->
      <!-- disabled  [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToEditData()"
        iconSrc="../../../../assets/img/icons/wand.svg" backgroundColor="#FFD1D3"
        title="{{'adminHome.cards.EDIT_DATA' | translate}}" description="adminHome.cards.EDIT_DATA_DESCR"
        [isNew]="false"></box-home>
      <!-- Monitoraggio -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToMonitoring()"
        iconSrc="../../../../assets/img/icons/activity.svg" backgroundColor="#E4F8E1"
        title="{{'adminHome.cards.MONITORING' | translate}}" description="adminHome.cards.MONITORING_DESCR"
        [isNew]="false"></box-home>
      <!-- Dashboard [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/pie-chart.svg"
        backgroundColor="#DDE0FF" title="{{'adminHome.cards.DASHBOARD' | translate}}"
        description="adminHome.cards.DASHBOARD_DESCR" [isNew]="false" (onButtonClicked)="goToDashboard()"></box-home>
      <!-- Calibration  [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
      <ng-container *ngIf="canAccessCalibration">
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/balance.svg"
          backgroundColor="#E1F1FD" title="{{'adminHome.cards.CALIBRATION' | translate}}"
          (onButtonClicked)="goToCalibration()" description="adminHome.cards.CALIBRATION_DESCR" [isNew]="false">
        </box-home>
      </ng-container>
      <!-- Talent -->
      <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/contact.svg"
        backgroundColor="#F3FFC3" title="{{'adminHome.cards.TALENT' | translate}}" (onButtonClicked)="goToTalent()"
        description="adminHome.cards.TALENT_DESCR" [isNew]="false">
      </box-home>
      <!-- Obiettivi cascading [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
      <!-- aggiornare condizione per mostrare cascading --- UPDATE: sembra si veda sempre -->
      <ng-container *ngIf="true">
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50"
          iconSrc="../../../../assets/img/icons/clipboard-list.svg" backgroundColor="#FFDEC7"
          title="{{'cascading.TILE_TITLE' | translate}}" (onButtonClicked)="goToCascading()"
          description="cascading.TILE_DESCR" [isNew]="false">
        </box-home>
      </ng-container>
    </div>
  </div>
</div>