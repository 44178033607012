import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse, User } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ManagerService } from 'src/app/shared/services/manager.service';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-talent-scounting',
  templateUrl: './talentScouting.component.html',
  styleUrls: ['./talentScouting.component.scss']
})
export class TalentScoutingComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  isLoadingUserData: boolean = false;
  getTalentUsersList: Subscription = new Subscription;
  userData: {
    list: any[],
    counter: number,
    fromRecord: number,
    numRecords: number,
    page: number
  } = {
      list: [],
      page: 1,
      counter: 0,
      fromRecord: 0,
      numRecords: 20
    };
  userFeedbackModal: any;
  translations: any;
  getLoggedUser$: Subscription = new Subscription;
  loggedUser: any;
  selectedYear: any;
  isLoadingYearList: boolean = false;
  getYearsList$: any;
  yearList: any;
  isLoadingStepper: boolean = false;
  getTalentStepData$: Subscription = new Subscription;
  stepList: { id: number; number: number; title: any; disabled?: boolean }[] = [];
  currentStep: any = {};
  searchedText: string = '';
  withRequirements: boolean = true;
  rowPerPageOptions = [
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]
  selectedRows: { id: number, title: number };

  selectedUserList: any[] = []
  allChecked: boolean = false;
  menuOptions: { id: string; title: any; icon: string; disabled?: boolean }[] = [];
  modalNote: string = '';
  fileObject: any;
  resetIncludeUpload: boolean = true;
  englishModalUser: any;
  charsNote: number = 600;
  noteModalUser: any;
  isLoadingNoteList: boolean = false;
  getNoteListModal$: Subscription = new Subscription;
  noteListModalData: {
    user: any,
    list: any[]
  } = {
      user: null,
      list: []
    };
  noteToDelete: any;
  getObservationGridData$: Subscription = new Subscription;
  isLoadingObservationGrid: boolean = false;
  gridMenuOptions: { id: string; title: any; icon: string; disabled?: boolean }[] = [];
  isConfirmed: boolean = false;
  showFile: boolean = false;
  isFinalConfirmDisabled: boolean = true;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public modalService: ModalService,
    private managerService: ManagerService,
    public redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.selectedRows = this.rowPerPageOptions[0];
    this.userData.numRecords = this.selectedRows.id;

    this.getLoggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser: any) => {
      this.loggedUser = loggedUser.user;
    })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {

              this.getPerformanceAssessmentYears();
              if (params.perfCareYear) {
                this.selectedYear = {
                  id: params.perfCareYear,
                  name: 'Anno ' + params.perfCareYear
                }
              } else {
                this.selectedYear = {
                  id: this.runningYear,
                  name: 'Anno ' + this.runningYear
                }
              }

              this.translate.get([
                'talent.TITLE',
                'talent.SPEED_SCREENING',
                'talent.OBS_GRID',
                'talent.menu.SPEED_SCREENING',
                'talent.menu.INSERT_NOTE',
                'talent.menu.REQUEST_ENGLISH_LEVEL',
                'talent.menu.OBSERVATION_GRID',
                'talent.menu.PROFILE',
              ]).subscribe((translations) => {
                this.translations = translations;
                this.menuOptions = [
                  {
                    id: 'speed',
                    title: translations['talent.menu.SPEED_SCREENING'],
                    icon: '/assets/img/icons/check-circle.svg',
                  },
                  {
                    id: 'notes',
                    title: translations['talent.menu.INSERT_NOTE'],
                    icon: '/assets/img/icons/message-square.svg',
                  },
                  {
                    id: 'request',
                    title: translations['talent.menu.REQUEST_ENGLISH_LEVEL'],
                    icon: '/assets/img/icons/edit.svg',
                  },
                ]

                this.gridMenuOptions = [
                  {
                    id: 'grid',
                    title: translations['talent.menu.OBSERVATION_GRID'],
                    icon: '/assets/img/icons/check-circle.svg',
                  },
                  {
                    id: 'profile',
                    title: translations['talent.menu.PROFILE'],
                    icon: '/assets/img/icons/contact.svg',
                  },
                  {
                    id: 'notes',
                    title: translations['talent.menu.INSERT_NOTE'],
                    icon: '/assets/img/icons/message-square.svg',
                  },
                ]

                this.stepList = [
                  {
                    id: 1,
                    number: 1,
                    title: this.translations['talent.SPEED_SCREENING']
                  },
                  {
                    id: 2,
                    number: 2,
                    title: this.translations['talent.OBS_GRID']
                  }
                ]

                let tempStep = sessionStorage.getItem('talentStep');
                if (tempStep) {
                  this.currentStep = JSON.parse(tempStep);
                } else {
                  this.currentStep = this.stepList[0];
                }
                this.getTalentStepData();
              });
            });
      }
    });

  }

  ngOnInit() { }



  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.managerService.getTalentScoutingYears()// (this.loggedUser.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          data.response.forEach(yearData => {
            this.yearList.push({
              id: yearData,
              name: 'Anno ' + yearData,
            });
          });
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  // cambio anno che sto osservando
  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.router.navigate([url + '/' + this.selectedYear.id])
  }

  // Cambio step
  stepClicked(newStep: any) {
    if (!this.isConfirmed) {
      this.currentStep = newStep;
      this.selectedUserList = [];
      this.searchedText = '';
      this.resetUserData()
      if (this.currentStep.id == 1) {
        this.currentStep = this.stepList[0];
        sessionStorage.setItem('talentStep', JSON.stringify(this.currentStep));
        this.getSpeedScreeningUserList();
      } else {
        this.currentStep = this.stepList[1];
        sessionStorage.setItem('talentStep', JSON.stringify(this.currentStep));
        this.getObservationGridData();
      }
    }
  }

  // Dati tabella speed screening
  getTalentStepData() {
    this.isLoadingStepper = true;

    if (this.getTalentStepData$) {
      this.getTalentStepData$.unsubscribe();
    }

    this.getTalentStepData$ = this.managerService.getTalentStepData(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "t001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response.hasManagerTalentScoutingConfirmed) {
            this.stepList[0].disabled = true;
            this.isConfirmed = true;
            this.currentStep = this.stepList[1];
          }
          if (data.response.activeStep && data.response.activeStep.toLowerCase().indexOf('screening') < 0 || this.currentStep.id == 2) {
            this.getObservationGridData();
          } else {
            this.getSpeedScreeningUserList();
          }
        }
        this.isLoadingStepper = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "t002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingStepper = false;
      });
  }

  onSearch(reset?: boolean) {
    if (this.currentStep.id == 1) {
      this.getSpeedScreeningUserList(reset);
    } else {
      this.getObservationGridData(reset);
    }
  }


  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  selectTab(isWithRequirement: boolean) {
    this.withRequirements = isWithRequirement;
    this.getSpeedScreeningUserList(true);
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.userData.numRecords = item.id;
    this.onSearch(true)
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.userData.fromRecord = 0;
    this.userData.page = 1;
    this.userData.counter = 0;
    this.userData.numRecords = this.selectedRows.id;
    this.userData.list = [];
  }

  // Cambia la paginazione alla lista 
  usersPageChanged(newPage: any) {
    this.userData.page = newPage;
    // Avvio una nuova ricerca
    this.onSearch();
    //this.getSpeedScreeningUserList();
  }

  getSpeedScreeningUserList(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetUserData();
    }
    // Avvio il loader
    this.isLoadingUserData = true;
    this.managerService.countSpeedScreeningUsers(this.selectedYear.id, this.searchedText, this.withRequirements)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.userData.counter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.userData.page && this.userData.numRecords) {
                fromRecord = (this.userData.page - 1) * this.userData.numRecords;
              } else {
                fromRecord = 0;
              }
              if (this.userData.counter) {
                return this.managerService.listSpeedScreeningUsers(this.selectedYear.id, fromRecord, this.userData.numRecords, this.searchedText, this.withRequirements);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "sp001",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUserData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "sp002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data.response && data.response.length) {
            this.userData.list = data.response;
          } else {
            this.userData.list = [];
            this.userData.counter = 0;
          }
          this.isLoadingUserData = false;
        }
        , (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "sp003",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUserData = false;
          return throwError(new Error(err.message));
        }
      );
  }


  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    this.selectedUserList = [];
    if (this.userData.list && this.userData.list.length > 0) {
      for (let i = 0; i < this.userData.list.length; i++) {
        this.userData.list[i].isChecked = this.allChecked;
        this.addRemoveUser(this.userData.list[i]);
      }

    }
  }

  addRemoveUser(user: any) {
    if (this.selectedUserList && this.selectedUserList.length) {
      if (!user.isChecked) {
        this.selectedUserList = this.selectedUserList.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedUserList.push(user);
      }
    } else {
      this.selectedUserList = [user];
    }
  }

  // Check singolo utente
  selectUser(user: any) {
    user.isChecked = !user.isChecked;
    this.addRemoveUser(user);
  }

  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid();
    }
    for (let i = 0; i < this.gridMenuOptions.length; i++) {
      this.gridMenuOptions[i].disabled = !this.isMenuOptionValid();
    }
    user.isMenuOpen = !user.isMenuOpen
  }

  // Associato alla direttiva clickOutside
  closeMenu(user: any) {
    user.isMenuOpen = false;
  }

  isMenuOptionValid() {
    return !this.isConfirmed;
  }


  onMenuOptionsClicked(option: any, user: any) {
    if (!option.disabled) {
      if (option.id == 'notes') {
        this.resetIncludeUpload = true;
        this.noteModalUser = user;
        this.fileObject = null;
        this.showFile = true;
        this.modalService.open('insert-note');
      } else if (option.id == 'speed') {
        if (this.withRequirements) {
          this.router.navigate(['manager/talentScouting/speedScreening', this.selectedYear.id, user.userId]);
        } else {
          this.router.navigate(['manager/talentScouting/speedScreening/noRequirements/', this.selectedYear.id, user.userId]);
        }
      } else if (option.id == 'request') {
        this.englishModalUser = user;
        this.modalService.open('request-english')
      }
    }
  }

  closeInsertNote(confirm?: boolean) {
    this.modalService.close('insert-note');
    this.showFile = false;
    if (confirm) {
      this.isLoadingUserData = true;
      this.managerService.createTalentNote(this.selectedYear.id, this.noteModalUser.userId, this.modalNote, this.fileObject || null)
        .subscribe((data: any) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "in001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.noteModalUser = null;
          this.modalNote = '';
          this.fileObject = null;
          this.onSearch();
        }
          , (err: any) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "in002",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.isLoadingUserData = false;
            return throwError(new Error(err.message));
          }
        );
    }
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  modalNoteChanged(text: string) {
    this.modalNote = text;
  }

  openNoteListModal(user: any) {
    if (user.notesCount) {
      this.isLoadingNoteList = true;
      this.noteListModalData.user = user;
      this.modalService.open('note-list');
      this.getNoteListModal(user);
    }
  }

  getNoteListModal(user: any) {
    if (this.getNoteListModal$) {
      this.getNoteListModal$.unsubscribe();
    }

    this.getNoteListModal$ = this.managerService.getUserTalentNote(this.selectedYear.id, user.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "in001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.noteListModalData.list = [];
          for (let i = 0; i < data.response.notes.length; i++) {
            let tempNote = data.response.notes[i];
            this.noteListModalData.list.push({
              ...tempNote,
              insertDate: moment(tempNote.creationDate).format('D MMMM YYYY')
            })
          }
        }
        if (this.noteListModalData.list && !this.noteListModalData.list.length) {
          this.closeNoteListModal();
        }
        this.isLoadingNoteList = false;
      }
        , (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "in002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingNoteList = false;
          return throwError(new Error(err.message));
        }
      );
  }

  closeNoteListModal() {
    this.modalService.close('note-list');
  }

  openDeleteNoteModal(note: any) {
    this.noteToDelete = note;
    this.modalService.open('confirm-delete-note');
  }

  closeDeleteNoteModal(confirm?: boolean) {
    this.modalService.close('confirm-delete-note');
    if (confirm) {
      this.isLoadingNoteList = true;
      this.managerService.deleteUserTalentNote(this.noteToDelete.noteId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "deln001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.onSearch();
          this.getNoteListModal(this.noteListModalData.user);
        }, (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "deln002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingNoteList = false;
          return throwError(new Error(err.message));
        }
        );
    }
  }


  closeSendEnglishLevelrequest(confirm?: boolean) {
    this.modalService.close('request-english');
    if (confirm) {
      let link = document.createElement('a');
      link.href = 'mailto:' + this.englishModalUser.email;
      link.click();
    }
  }



  getObservationGridData(fromSearch?: boolean) {
    this.isLoadingObservationGrid = true;

    if (fromSearch) {
      this.resetUserData();
    }

    if (this.getObservationGridData$) {
      this.getObservationGridData$.unsubscribe();
    }

    this.getObservationGridData$ = this.managerService.countUserInTalentPotential(this.selectedYear.id, this.searchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.userData.counter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.userData.page && this.userData.numRecords) {
                fromRecord = (this.userData.page - 1) * this.userData.numRecords;
              } else {
                fromRecord = 0;
              }
              if (this.userData.counter) {
                return this.managerService.listUserInTalentPotential(this.selectedYear.id, fromRecord, this.userData.numRecords, this.searchedText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "sp001",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingObservationGrid = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "sp002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.response.someSubordinatesNotObserved) {
              this.isFinalConfirmDisabled = data.response.someSubordinatesNotObserved
            } else {
              this.isFinalConfirmDisabled = false;
            }
            this.userData.list = data.response.users || [];
          }
          this.isLoadingObservationGrid = false;
        }
        , (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "sp003",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingObservationGrid = false;
          return throwError(new Error(err.message));
        }
      );
  }


  onGridMenuOptionsClicked(option: any, user: any) {
    if (!option.disabled) {
      if (option.id == 'notes') {
        this.resetIncludeUpload = true;
        this.noteModalUser = user;
        this.fileObject = null;
        this.showFile = true;
        this.modalService.open('insert-note');
      } else if (option.id == 'profile') {
        this.redirectService.goToPersonDetails(user.userId, this.selectedYear.id);
        // this.router.navigate(['manager/talentScouting/speedScreening', this.selectedYear.id, user.userId]);
      } else if (option.id == 'grid') {
        this.router.navigate(['manager/talentScouting/observationGrid', this.selectedYear.id, user.userId]);
      }
    }
  }


  openObservationGridTooltip() {
    this.modalService.open('grid-tooltip');
  }

  closeObservationGridTooltip() {
    this.modalService.close('grid-tooltip');
  }

  openConfirmPotentialModal() {
    this.modalService.open('confirm-potential');
  }

  closeConfirmPotentialModal(confirm?: boolean) {
    this.modalService.close('confirm-potential');
    if (confirm) {
      this.confirmPotential();
    }
  }

  confirmPotential() {
    this.isLoadingObservationGrid = true;
    this.managerService.confirmSubordinatePotentialLevel(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "confg001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.getTalentStepData();
        this.isLoadingObservationGrid = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "confg002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingObservationGrid = false;
        return throwError(new Error(err.message));
      }
      );

  }



















  // Recupera la stringa per l'estensione del file
  getExtensionString(fileName: string) {
    let extension = fileName.slice(fileName.lastIndexOf('.'), fileName.length);
    extension = extension.replace('.', '');
    if (extension == 'pptx' || extension == 'PPTX') {
      extension = 'ppt';
    } else if (extension == 'jpg' || extension == 'JPG') {
      extension = 'jpeg';
    } else if (extension == 'xlsx' || extension == 'XLSX') {
      extension = 'xls';
    } else if (extension == 'docx' || extension == 'DOCX') {
      extension = 'doc';
    } else if (extension == 'eml' || extension == 'EML') {
      extension = 'eml';
    } else if (extension == 'msg' || extension == 'MSG') {
      extension = 'msg';
    } else if (extension == 'txt' || extension == 'TXT') {
      extension = 'txt';
    } else if (extension == 'rtf' || extension == 'RTF') {
      extension = 'rtf';
    } else if (extension == 'png' || extension == 'PNG') {
      extension = 'png';
    }
    return extension;
  }

  downloadLinkedFile(file: any) {
    let link = document.createElement('a');
    link.href = file.url;
    link.download = file.url.substr(file.url.lastIndexOf('/') + 1);
    link.click();
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getTalentUsersList) {
      this.getTalentUsersList.unsubscribe();
    }
    if (this.getLoggedUser$) {
      this.getLoggedUser$.unsubscribe();
    }
    if (this.getTalentStepData$) {
      this.getTalentStepData$.unsubscribe();
    }
    if (this.getNoteListModal$) {
      this.getNoteListModal$.unsubscribe();
    }
  }
}