<div class="goal-container" *ngIf="goal">
  <div class="goal-main">
    <!--Contenitore titolo e descrizione-->
    <div class="title-description-wrapper">

      <!--Titolo-->
      <div class="title-container">
        <p class="title">{{ goal.title }}</p>
      </div>
      <!-- Descrizione -->
      <div class="description-container">
        <div class="spacing-container">
          <!-- Obiettivi di struttura -->
          <span *ngIf="goal.description" class="title" translate="generic.DESCRIPTION"></span>
          <span class="only-2-rows" [innerHTML]="goal.description"></span>
        </div>
        <span class="more-details-link" translate="generic.SHOW_DETAILS" (click)="emitOnDetailClicked()"></span>
      </div>
    </div>

    <!-- Footer della card obiettivi -->
    <div class="bottom-container" *ngIf="goal.type != 'COMPANY' && goal.type != 'STRUCTURE'">
      <div class="line-divisor">
        <div class="line"></div>
      </div>
      <div class="bottom-info">
        <div class="info">
          <!--p>
            <span class="bold-text">{{ goal.feedbackCounter || 0}}</span>&nbsp;
            <span translate="goalSetting.FEEDBACK"></span>
          </p-->
          <p>
            <span class="bold-text">{{ goal.evidencesCounter || 0}}</span>&nbsp;
            <span translate="goalSetting.EVIDENCES"></span>
          </p>
          <!-- Rimossa dalle tavole 
            <div class="new" *ngIf="goal.isNew">
            <p translate="generic.NEWS"></p>
          </div-->
        </div>

        <!-- Valutazione finale -->
        <div class="evaluation-container" *ngIf="!isKpiOrCompetences()">
          <evaluation *ngIf="getEvaluationScore(); else evalPlaceholder" [evaluation]="getEvaluationScore()"
            customClass="medium">
          </evaluation>
          <ng-template #evalPlaceholder>
            <evaluation [placeholder]="true" customClass="medium"></evaluation>
          </ng-template>

          <!--ng-container
            *ngif="goal.goalEvaluation && goal.goalEvaluation.length && goal.goalEvaluation[0].evaluationScore; else evaluationPlaceholder">
            <evaluation [evaluation]="goal.goalEvaluation[0].evaluationScore" customClass="medium"></evaluation>
            <div class="evaluation-names">
              <ng-container *ngFor="let eval of goal.goalEvaluation">
                <avatar-img [user]="eval.user" size="small"></avatar-img>
                <p (click)="onFinalEvaluationClicked(eval)" class="user-name">{{ eval.user.surname }}</p>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #evaluationPlaceholder>
            <evaluation [placeholder]="true" customClass="medium"></evaluation>
          </ng-template-->

          <!-- Valutazione utente -->
          <!--div class="final-evaluation-row">
            <div class="left-side">
            </div>
          </div-->
        </div>


      </div>
    </div>
  </div>
  <!-- Lato dx con dettagli -->
  <div class="goal-aside" [ngClass]="{'completed': goal.status && goal.status == 'completed'}">
    <!--Stato e tipologia-->
    <div class="status-tipology">
      <!--Stato dell'obiettivo-->
      <ng-container *ngIf="!isCompactView; else collapsedStatus">
        <item-list-team-status styleItem="border" [message]="goal.statusMessage" [type]="goal.status">
        </item-list-team-status>
      </ng-container>
      <ng-template #collapsedStatus>
        <div class="square-status" [ngClass]="goal.status">
        </div>
      </ng-template>
      <!--Tipologia dell'obiettivo-->
      <ng-container *ngIf="goal.miniCards">
        <div class="mini-card white" [ngClass]="{'color-primary': goal.status && goal.status == 'completed'}"
          *ngFor="let miniCard of goal.miniCards">
          <p>{{ miniCard.title | translate }}</p>
        </div>
      </ng-container>
    </div>

    <!-- Peso, deadline e azioni -->
    <div class="advanced-action">
      <div class="advanced-details" [ngClass]="{'completed': goal.status && goal.status == 'completed'}">
        <!--Peso dell'obiettivo-->
        <div *ngIf="goal.weight" class="tag-wrapper">
          <svg-icon src="./assets/img/icons/pie-chart.svg" class="small-size" [applyClass]="true">
          </svg-icon>
          <p>
            <span fxHide.xs translate="goalSetting.WEIGHT"></span>
            <span class="bold-text">&nbsp;{{ (goal.weight || 0) + '%'}}</span>
          </p>
        </div>
        <!--Scadenza dell'obiettivo-->
        <div *ngIf="getDeadlineDate()" class="tag-wrapper">
          <svg-icon src="./assets/img/icons/calendar.svg" class="small-size" [applyClass]="true">
          </svg-icon>
          <p>
            <span fxHide.xs translate="goalSetting.SCADENCE_AT"> </span>
            <!--span class="bold-text">{{scadenceDate}}</span-->
            <span class="bold-text">&nbsp;{{ getDeadlineDate() }}</span>
          </p>
        </div>
      </div>

      <!--Azione di dettaglio-->
      <div *ngIf="!hideOptions || (goal.type != 'COMPANY' && goal.type != 'STRUCTURE')" class="detail-button">

        <europ-button [disabled]="menuButtonDisabled" (onButtonClicked)="toggleMenu()" (clickOutside)="closeMenu()"
          iconRight="assets/img/icons/chevron-down.svg"
          [type]="(goal.status && goal.status == 'completed' ? 'primary' : 'btn-white') + ' link'" size="medium"
          label="{{'generic.MANAGE' | translate}}"></europ-button>


        <!-- Menu dropdown -->
        <ng-container *ngIf="isMenuOpen">
          <div class="dropdown-menu">
            <ng-container *ngFor="let option of menuOptions">
              <europ-dropdown-item (click)="onMenuOptionsClicked(option)" class="full-width" [item]="option"
                [disabled]="option.disabled">
              </europ-dropdown-item>
            </ng-container>
          </div>
        </ng-container>
        <!-- Vecchio onDetailClicked
        <ng-container *ngIf="goal.status && goal.status == 'completed'; else defaultAction">
          <europ-button (onButtonClicked)="emitOnDetailClicked(goal)" iconRight="assets/img/icons/arrow-right.svg"
            type="primary link" size="medium" label="{{'goalSetting.DETAIL' | translate}}"></europ-button>
        </ng-container>

        <ng-template #defaultAction>
          <europ-button (onButtonClicked)="emitOnDetailClicked(goal)" iconRight="assets/img/icons/arrow-right.svg"
            type="btn-clear link" size="medium" label="{{'goalSetting.DETAIL' | translate}}"></europ-button>
        </ng-template>
      -->
      </div>
    </div>
  </div>
</div>