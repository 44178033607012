<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [hasTabs]="false" [adminPageTitle]="('cascading.TILE_TITLE' | translate)"
    [adminPageSubtitle]="processYear ? (('cascading.PAGE_SUBTITLE' | translate)) : ('monitoring.WHAT_PROCESS' | translate)"
    [adminBack]="true" [adminActionLabel]="processYear ? ('monitoring.DOWNLOAD' | translate) : ''"
    (onAdminAction)="downloadReport()" [adminActionDisabled]="isDownloadingReport"></header-dossier>
  <div class="main-content-wrapper admin">
    <!-- Home processi -->
    <ng-container *ngIf="!processYear">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingProcessData">
        <div class="admin-table-container">
          <div class="table-content responsive">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('adminTable.YEAR' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                  <!--th>
                    <div class="sorting" (click)="orderBy('status')">
                      <p>{{ ('adminTable.STATUS' | translate)}}</p>
                      <div class="arrows-container">
                        <svg-icon class="arrow" [applyClass]="true" src="/assets/img/icons/sorting-arrow-up.svg">
                        </svg-icon>
                        <svg-icon class="arrow" [applyClass]="true" src="/assets/img/icons/sorting-arrow-down.svg">
                        </svg-icon>
                      </div>
                    </div>
                  </th-->
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of processData.list | 
                            slice:0:processData.numRecords | 
                            paginate: { id: 'processList',
                                    itemsPerPage: processData.numRecords,
                                    currentPage: processData.page,
                                    totalItems: processData.counter }">
                  <td>{{ process.year }}</td>
                  <td>{{ process.title }}</td>
                  <td>
                    {{ process.usersInGroupCount || 0 }}
                  </td>
                  <!--td>
                    <item-list-team-status type="inProgress" styleItem="tag"></item-list-team-status>
                  </td-->
                  <td class="link" (click)="goToProcessDetail(process.year)">{{
                    ('adminTable.ACCESS' | translate)}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </europ-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingProcessData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </ng-container>





    <!-- Lista utenti nel processo -->
    <ng-container *ngIf="processYear">
      <div class="structure-recap">
        <p class="structure-title" translate="editDataList.LIST_TITLE"></p>

        <div class="search-container">
          <div class="search-wrapper">
            <europ-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="selectedFilters.searchedText"
              (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
              [placeholder]="'structureAnag.details.SEARCH_BY' | translate" [isSearchBar]="true">
            </europ-input>
            <!-- Commentato in attesa che ci dicano che filtri possono essere utili -->
            <!--p class="filter-recap" translate="structureAnag.details.FILTER_BY"></p-->

          </div>
        </div>
        <!-- Recap dei filtri attivi -->
        <div class="recap-container">
          <div class="racap-text-row">
            <div class="left-column">
              <ng-container *ngIf="!selectedFiltersNumber">
                <p class="placeholder" translate="adminSearch.NO_FILTER_ACTIVE"></p>
              </ng-container>
              <ng-container *ngIf="selectedFiltersNumber == 1">
                <p class="placeholder" [innerHTML]="'adminSearch.ONE_FILTER' | translate"></p>
              </ng-container>
              <ng-container *ngIf="selectedFiltersNumber > 1">
                <p class="placeholder"><b>{{selectedFiltersNumber}}</b>&nbsp;{{ 'adminSearch.MORE_FILTERS' |
                  translate
                  }}
                </p>
              </ng-container>
              <ng-container *ngIf="selectedFiltersNumber >= 1">
                <p class="delete-filters" translate="adminSearch.DELETE_FILTERS" (click)="clearFilters()"></p>
              </ng-container>
              <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
            </div>
            <div class="count-container">
              <p translate="dashboard.MANAGE_FILTERS"></p>
              <svg-icon src="assets/img/icons/chevron-down.svg" *ngIf="!isFilterOpened" [applyClass]="true" class="goto"
                (click)="openFiltersSection()"></svg-icon>
              <svg-icon src="assets/img/icons/chevron-up.svg" *ngIf="isFilterOpened" [applyClass]="true" class="goto"
                (click)="openFiltersSection()"></svg-icon>
            </div>
          </div>

          <ng-container *ngIf="isFilterOpened">
            <!-- Filtri per struttura, genere ecc-->
            <div class="filters-container">
              <div class="filters-content">
                <div class="generic-container">
                  <!-- Struttura -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureTooltipModal"
                    [isValued]="selectedFilters.structures" [isObbligatory]="true"
                    title="{{'calibration.filters.STRUCTURE' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'structures')"
                      [selectedItem]="selectedFilters.structures" [items]="filtersObject.structures"
                      [searchable]="false" bindLabel="id">
                    </europ-select>
                  </input-container>
                  <!-- Funzione -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="functionTooltipModal"
                    [isValued]="selectedFilters.funzione" [isObbligatory]="true"
                    title="{{'calibration.filters.FUNCTION' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'funzione')"
                      [selectedItem]="selectedFilters.funzione" [items]="filtersObject.funzione" [searchable]="true"
                      bindLabel="id">
                    </europ-select>
                  </input-container>

                </div>

                <div class="generic-container">

                  <!-- Mansione -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="jobTooltipModal"
                    [isValued]="selectedFilters.mansione" [isObbligatory]="true"
                    title="{{'calibration.filters.JOB' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'mansione')"
                      [selectedItem]="selectedFilters.mansione" [items]="filtersObject.mansione" [searchable]="true"
                      bindLabel="id">
                    </europ-select>
                  </input-container>

                  <!-- Area -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="areaTooltipModal"
                    [isValued]="selectedFilters.area" [isObbligatory]="true"
                    title="{{'calibration.filters.AREA' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'area')"
                      [selectedItem]="selectedFilters.area" [items]="filtersObject.area" [searchable]="true"
                      bindLabel="id">
                    </europ-select>
                  </input-container>

                </div>


                <div class="generic-container">
                  <!-- Struttura 3 livello -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel3TooltipModal"
                    [isValued]="selectedFilters.denominazioneSO3" [isObbligatory]="true"
                    title="{{'calibration.filters.STRUCTURE_LEVEL_3' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'denominazioneSO3')"
                      [selectedItem]="selectedFilters.denominazioneSO3" [items]="filtersObject.denominazioneSO3"
                      [searchable]="true" bindLabel="id">
                    </europ-select>
                  </input-container>

                  <!-- Struttura 5 livello -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel5TooltipModal"
                    [isValued]="selectedFilters.denominazioneSO5" [isObbligatory]="true"
                    title="{{'calibration.filters.STRUCTURE_LEVEL_5' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'denominazioneSO5')"
                      [selectedItem]="selectedFilters.denominazioneSO5" [items]="filtersObject.denominazioneSO5"
                      [searchable]="true" bindLabel="id">
                    </europ-select>
                  </input-container>

                </div>

                <div class="generic-container">
                  <!-- Sesso -->
                  <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="genderFilterTooltipModal"
                    [isValued]="selectedFilters.chiaveSesso" [isObbligatory]="true"
                    title="{{'calibration.filters.GENDER' | translate}}">
                    <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'chiaveSesso')"
                      [selectedItem]="selectedFilters.chiaveSesso" [items]="filtersObject.chiaveSesso"
                      [searchable]="false" bindLabel="id">
                    </europ-select>
                  </input-container>
                </div>

                <div class="button-container center">
                  <europ-button [label]="'generic.APPLY_FILTERS' | translate" type="primary"
                    (onButtonClicked)="applyFilters()" [disabled]="isLoadingUsersData">
                  </europ-button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>




      <!-- Dettaglio utenti per anno -->

      <ng-container *ngIf="!isLoadingUsersData">
        <!-- Tabella utenti -->
        <ng-container *ngIf="usersData.counter > 0">
          <div class="admin-table-container">
            <div class="table-content responsive">
              <table>
                <thead>
                  <tr>
                    <th translate="{{ ('adminTable.FULL_NAME' | translate)}}"></th>
                    <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                    <th class="small-col" translate="{{ ('adminTable.ROLE' | translate)}}">
                    </th>
                    <th class="small-col" translate="{{ ('adminTable.COLLAB_NUMBER' | translate)}}"></th>
                    <th translate="{{ ('adminTable.UPDATED_ON' | translate)}}"></th>
                    <th translate="{{ ('adminTable.OBJECTIVE_STATUS' | translate)}}"></th>
                    <th class="center" translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let userData of usersData.list | 
                              slice:0:usersData.numRecords | 
                              paginate: { id: 'usersList',
                                      itemsPerPage: usersData.numRecords,
                                      currentPage: usersData.page,
                                      totalItems: usersData.counter }">
                    <td>{{ userData.user?.surname + ' ' + userData.user?.forename }}</td>
                    <td>
                      <div class="count">
                        {{ userData.user?.cid || '--' }}
                      </div>
                    </td>
                    <!--td class="small-col link" (click)="openUserProcessModal(userData)">{{userData.numerOfProcess ||0 }}</td-->
                    <td>{{ userData.user?.ruolo || '--'}}</td>
                    <td>{{ userData.directSubordinates + '/' + userData.indirectSubordinates }}</td>
                    <td>{{ userData.statusDate ? userData.statusDate : '--' }}</td>
                    <td>
                      <item-list-team-status [message]="('adminTable.' + userData.cascadingGoalStatuses) | translate"
                        [type]="userData.cascadingGoalStatuses == 'DECLINED'? 'completed' : 'disabled'"
                        [styleItem]="userData.cascadingGoalStatuses == 'DECLINED'? 'border' : 'bordless'">
                      </item-list-team-status>
                    </td>
                    <td class="action">
                      <svg-icon class="default-icon" [applyClass]="true"
                        (click)="goToEditCascading(userData.user?.userId)" src="/assets/img/icons/clipboard-list.svg">
                      </svg-icon>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="bottom-actions">
              <table-pagination id="usersList" (pageChanged)="userPageChanged($event)" [noPadding]="true">
              </table-pagination>
              <div class="per-page-selector">
                <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeUserNumRecords($event)"
                  [selectedItem]="userSelectedRows" [items]="userRowPerPageOptions" [searchable]="false" bindLabel="id"
                  [tablePagination]="true">
                </europ-select>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Se non ho utenti -->
        <ng-container *ngIf="!usersData.counter">
          <div class="no-data-wrapper">
            <p translate="adminTable.NO_DATA_USER"></p>
          </div>
        </ng-container>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsersData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>



    </ng-container>
  </div>
</div>