import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');

@Component({
    selector: 'admin-people-anagraphics',
    templateUrl: './peopleAnag.component.html',
    styleUrls: ['./peopleAnag.component.scss']
})
export class PeopleAnagComponent implements OnDestroy {
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;
    currentYear = new Date().getFullYear();

    isLoadingUserData: boolean = false;
    getUserData$: Subscription = new Subscription;
    userData: {
        fromRecord: number,
        numRecords: number,
        page: number,
        counter: number,
        list: any[]

    } = {
            fromRecord: 0,
            numRecords: 5,
            page: 1,
            counter: 0,
            list: []
        }

    rowPerPageOptions = [
        {
            id: 5,
            title: 5
        },
        {
            id: 10,
            title: 10
        },
        {
            id: 15,
            title: 15
        },
        {
            id: 20,
            title: 20
        }
    ]
    selectedRows: { id: number, title: number };
    userId: string = '';
    menuOptions: any[] = [];
    searchedText: string = '';
    stiDateTooltip: ApplicationModalMessage = {
        modalId: '',
        title: '',
        text: ''
    };
    today = moment().format();

    userDetails: any;
    isLoadingUserDetails: boolean = false;


    selectedApprover: any;
    approversList: any[] = [];
    approverSelectOpen: boolean = false;

    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        this.selectedRows = this.rowPerPageOptions[0];
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.userId = params.userId;
                                    this.getUserData();
                                })
                    }
                });
    }

    ngOnInit() {
        this.translate.get([
            'process.details.PERSON_DETAILS',
            'process.details.MANAGE_STI',
            'process.details.EXCLUDE_FROM_PROCESS',
            'process.sti.ENABLE_TITLE',
            'process.sti.ENABLE_INFO',
            'process.sti.GIVE_VALUE',
            'process.sti.GIVE_VALUE_INFO',
            'process.sti.SIGNED_DATE_TITLE',
            'process.sti.SIGNED_DATE_INFO',
            'process.sti.CONFIRM_ENABLE',
            'process.sti.CONFIRM_ENABLE_SUB',
            'process.sti.CONFIRM_ENABLE_TEXT',
            'process.manage.INCLUDE_TITLE',
            'process.manage.INCLUDE_INFO',
            'process.manage.EXCLUDE_TITLE',
            'process.manage.EXCLUDE_INFO',
            'headerDossier.processSetup.PARTICIPANT',
            'headerDossier.processSetup.OBJECTIVE_DEFINITION',
            'headerDossier.processSetup.PROCESS_PHASES'
        ]).subscribe((translations) => {
            this.stiDateTooltip = {
                modalId: 'ps012',
                title: translations['process.sti.SIGNED_DATE_TITLE'],
                text: translations['process.sti.SIGNED_DATE_INFO']
            }

            this.menuOptions = [
                {
                    id: 'details',
                    title: translations['process.details.PERSON_DETAILS'],
                    icon: '/assets/img/icons/clipboard.svg',
                },
                {
                    id: 'manageSTI',
                    title: translations['process.details.MANAGE_STI'],
                    icon: '/assets/img/icons/pie-chart.svg',
                },
                {
                    id: 'exclude',
                    title: translations['process.details.EXCLUDE_FROM_PROCESS'],
                    icon: '/assets/img/icons/trash-2.svg',
                }
            ]
        })
    }

    // ricerca
    searchedTextChange(text: string) {
        this.searchedText = text;
    }

    onSearch() {
    }

    // Recupera la lista utenti e altre info sul processo (?)
    getUserData() {
        this.isLoadingUserData = true;
        setTimeout(() => {
            this.userData.list.push({
                forename: "Pinco",
                surname: "Panco",
                cid: 12345,
                hasSTI: false,
                stiValue: null,
                stiDueDate: null,
                denominazioneSO3: "SERVIZIO CONT.CLIENTi",
                status: "EXCLUDED"
            },
                {
                    forename: "Panco",
                    surname: "Pinco",
                    cid: 54321,
                    hasSTI: true,
                    stiValue: 7800,
                    stiDueDate: new Date().toISOString(),
                    denominazioneSO3: "SERVIZIO CONT.CLIENTi",
                    status: "INCLUDED"
                }
            )
            this.isLoadingUserData = false;
        }, 1000)
    }

    userPageChanged(page: number) {
        this.userData.page = page;
        this.userData.list = [];
        this.getUserData();
    }

    changeNumRecords(item: any) {
        this.selectedRows = item;
        this.userData.numRecords = item.id;
        this.userData.list = [];
        this.userData.fromRecord = 0;
        this.getUserData();
    }

    // menu utente
    // dalla tabella apre il menu con le azioni sull'utente
    openCloseMenu(user: any) {
        user.isMenuOpen = !user.isMenuOpen;
    }

    // gestisce le opzioni del menu utente
    onMenuOptionsClicked(options: any, user: any) {
        user.isMenuOpen = false;
        if (options.id == 'details') {

        } else if (options.id == 'manageSTI') {
            this.modalService.open('manageSTI');
        } else {
            // this.modalService.open('deleteProcess');
        }
    }

    // Funzioni modale filtri
    openFiltersModal() {
        this.modalService.open('filtersModal')
    }

    applyFilters() {
        this.closeFiltersModal();
        this.getUserData();
    }

    clearFilters() {
        this.closeFiltersModal();
        this.getUserData();
    }

    closeFiltersModal() {
        this.modalService.close("filtersModal")
    }

    downloadList() {
    }

    showApproverSelect(){
        this.approverSelectOpen = !this.approverSelectOpen;
    }

    // seleziona l'utente approvatore nella pagina di dettaglio
    selectApprover(newApprover: any) {
        this.selectedApprover = newApprover;
    }

    goToUserDetails(userId: string) {
        this.router.navigate(['/admin/peopleAnagraphics/' + userId]);
    }

    orderBy(fieldName: string) {
    }

    ngOnDestroy() {
        if (this.getUserData$) {
            this.getUserData$.unsubscribe();
        }
    }
}


