import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, PerfCareMidTermReviewGoalAdvancementStatus, GetMyManagerUserDataResponse, PerfCareUserFinalEvaluation } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { GoalUtils } from 'src/app/utils/goal.utils';
import * as moment from 'moment';

@Component({
  selector: 'app-collaborator-definePerformanceLevel',
  templateUrl: './definePerformanceLevel.component.html',
  styleUrls: ['./definePerformanceLevel.component.scss']
})
export class DefinePerformanceLevelCollaboratorComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserGoalList$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;
  objectiveFeedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveOsservationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isLoadingUserGoals: boolean = false;
  goalList: any[] = [];

  careCards: any[] = [];
  cardSelected: any;

  osservation: string = '';

  isFromBack: boolean = false;
  sendRevision$: Subscription = new Subscription;
  statusForRecap: string = '';
  userRevisionDate: string = '';
  isLoadingManagerInfo: boolean = false;
  getManagerData$: Subscription = new Subscription();
  managerData: any;
  managerAnswer: boolean = false;
  managerRevisionDate: string = '';
  managerOsservation: string = '';
  isOperations: boolean = false;
  backWithData: boolean = false;

  charsFinalObservation: number = 600;
  finalObservation: string = '';
  observationTooltip: ApplicationModalMessage = {
    modalId: 'pl002',
    title: '',
    text: ''
  }
  selectedMark: any;
  isLoadingMyEvaluation: boolean = false;
  getMyFinalEvaluation$: any;
  result$: Subscription = new Subscription();
  runningPhase: any;
  selectedYear!: { id: any; name: string; };

  constructor(
    public translate: TranslateService,
    public collaboratorService: CollaboratorService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.isOperations = window.location.href.indexOf('collaboratorOperations') > -1;
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {

              if (params.perfCareYear) {
                this.selectedYear = {
                  id: params.perfCareYear,
                  name: 'Anno ' + params.perfCareYear
                }
              } else {
                this.selectedYear = {
                  id: this.runningYear,
                  name: 'Anno ' + this.runningYear
                }
              }

              const loggedUser$ = this.store.select(fromApp.getLoggedUser);
              const runningPhase$ = this.store.select(fromApp.getRunningPhase);
              const combinedSelectes$ = combineLatest([loggedUser$, runningPhase$]);
              this.result$ = combinedSelectes$.subscribe(
                ([loggedUser, runningPhase]) => {
                  this.runningPhase = runningPhase;
                  this.loggedUser = loggedUser.user;
                  if (this.loggedUser) {
                    this.getManagerData();
                    this.getMidTermGoals();
                    this.getMyFinalEvaluation()
                  }
                });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE',
        'midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT',
        'midTerm.addMidTermReview.IN_RETARD',
        'midTerm.addMidTermReview.IN_LINE',
        'midTerm.addMidTermReview.GREAT_POINT',
        'performance.INSERT_OBSERVATION',
        'performance.OBSERVATION_TOOLTIP',
      ])
      .subscribe(translations => {
        this.objectiveFeedbackTooltipModal = {
          modalId: "mt002",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_FEEDBACK_MODAL_TEXT'],
        };
        this.objectiveOsservationTooltipModal = {
          modalId: "mt003",
          title: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TITLE'],
          text: translations['midTerm.addMidTermReview.OBJECTIVE_OSSERVATION_MODAL_TEXT'],
        }
        this.observationTooltip = {
          modalId: "pl002",
          title: translations['performance.INSERT_OBSERVATION'],
          text: translations['performance.OBSERVATION_TOOLTIP'],
        }
        this.careCards = [{
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.LATE,
          title: translations['midTerm.addMidTermReview.IN_RETARD'],
          isChecked: false,
          icon: "assets/img/icons/red-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.ON_LINE,
          title: translations['midTerm.addMidTermReview.IN_LINE'],
          isChecked: false,
          icon: "assets/img/icons/yellow-face.svg"
        },
        {
          careId: '',
          id: PerfCareMidTermReviewGoalAdvancementStatus.WELL_ADVANCED,
          title: translations['midTerm.addMidTermReview.GREAT_POINT'],
          isChecked: false,
          icon: "assets/img/icons/green-face.svg"
        }]
      })
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.finalObservation && this.finalObservation.trim().length && this.selectedMark;
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return this.selectedMark || (this.finalObservation && this.finalObservation.trim().length);
  }

  sendRevision() {
    // this.goBackBrowser();
    this.isLoadingUserGoals = true;
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    this.sendRevision$ = this.collaboratorService.setMidTermReviewRevision(this.selectedYear.id, this.osservation, this.cardSelected.id)
      .subscribe((data: any) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj = {
            modalId: "mt005",
            title: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS"),
            text: this.translate.instant("midTerm.addMidTermReview.CREATE_SUCCESS_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
        }
        this.goBackBrowser();
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt006",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  sendRevisionAction(isBack?: boolean) {
    if (isBack && !this.isObbligatoryDataInserted()) {
      this.goBackBrowser();
    } else if (this.isObbligatoryDataInserted()) {
      if (isBack) {
        this.isFromBack = true;
      } else {
        this.isFromBack = false;
      }
      this.modalService.open("modal-exit");
    } else {
      if (isBack) {
        if (this.checkIfDataIsChanged()) {
          this.modalService.open("modal-exit");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modal-exit");
      }
    }
  }

  closeModalExit() {
    this.modalService.close("modal-exit");
  }

  osservationChanged(text: string) {
    this.osservation = text;
  }

  closeObjectiveFeedbackTooltip() {
    this.modalService.close("objectiveFeedbackModalTooltip");
  }

  openObjectiveFeedbackTooltip() {
    this.modalService.open("objectiveFeedbackModalTooltip");
  }

  onSelectCareCard(cards: any) {
    this.cardSelected = cards.filter((x: any) => {
      return x.isChecked == true;
    })[0];
  }

  getMidTermGoals() {
    this.isLoadingUserGoals = true;
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    this.getUserGoalList$ = this.collaboratorService.getMidTermGoals(this.selectedYear.id)
      .subscribe((goalList: SenecaResponse<any>) => {
        if (goalList.error || !goalList.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + goalList.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (goalList.response && goalList.response.goals && goalList.response.goals.length) {
            this.goalList = GoalUtils.formatGoalCardList(this.translate, goalList.response.goals, false, this.loggedUser.userId);
            // Dati osservazione collaboratore
            if (goalList.response.midTermReviewUserGoalAdvancement && goalList.response.midTermReviewUserGoalAdvancement.length) {
              this.osservation = goalList.response.midTermReviewUserObservation;
              this.statusForRecap = goalList.response.midTermReviewUserGoalAdvancement;
              this.userRevisionDate = moment(goalList.response.midTermReviewUserObservationDate).format('DD.MM.YYYY');
            }
            // Dati risposta manager
            if (goalList.response.midTermReviewManagerObservationDate && goalList.response.midTermReviewManagerObservationDate.length) {
              this.managerAnswer = true;
              this.managerRevisionDate = moment(goalList.response.midTermReviewManagerObservationDate).format('DD.MM.YYYY');
              this.managerOsservation = goalList.response.midTermReviewManagerObservation;
            }
          } else {
            this.goalList = [];
          }
        }
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  // Recupera i dati del manager
  getManagerData() {
    this.isLoadingManagerInfo = true;
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((managerData: SenecaResponse<GetMyManagerUserDataResponse>) => {
        if (managerData && managerData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + managerData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.managerData = managerData.response;
        }
        this.isLoadingManagerInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerInfo = false;
      });
  }


  getMyFinalEvaluation() {
    this.isLoadingMyEvaluation = true;
    if (this.getMyFinalEvaluation$) {
      this.getMyFinalEvaluation$.unsubscribe();
    }
    this.getMyFinalEvaluation$ = this.collaboratorService.getMyFinalEvaluation(this.selectedYear.id)
      .subscribe((feData: SenecaResponse<any>) => {
        if (feData.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "mt007",
            text: this.translate.instant("errors." + feData.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
        }
        this.isLoadingMyEvaluation = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "mt008",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMyEvaluation = false;
      });
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  finalObservationChanged(newText: string) {
    this.finalObservation = newText;
  }

  getUserGoalEvaluation(goal: any) {
    let tmpData = goal.goalEvaluation?.find((x: any) => x.user?.userId == this.loggedUser.userId);
    return tmpData && tmpData.evaluationScore;
  }

  selectFinalEvaluation(finalEvaluation: any) {
    this.selectedMark = finalEvaluation;
  }

  confirmValue() {
    let userFinalEvaluation = {
      userId: this.loggedUser.userId,
      phaseId: this.runningPhase.phaseId,
      subordinateObservation: this.finalObservation,
      subordinateObservationLevel: this.selectedMark
    }
    this.collaboratorService.setMyFinalEvaluation(this.selectedYear.id, userFinalEvaluation)
      .subscribe((setEvaluation: SenecaResponse<boolean>) => {
        if (setEvaluation && setEvaluation.error || !setEvaluation.response) {
          let error = setEvaluation.error ? this.translate.instant("errors." + setEvaluation.error) : this.translate.instant('errors.SOMETHING_WENT_WRONG');
          const messageObj: ApplicationModalMessage = {
            modalId: "pl010",
            text: error,
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.goBackBrowser();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pl009",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMyEvaluation = false;
      });

  };

  openMarkCardInfoModal() {
    const messageObj: ApplicationModalMessage = {
      modalId: "pl003",
      title: this.translate.instant('performance.INSERT_VALUE'),
      text: this.translate.instant("performance.VALUE_TOOLTIP"),
    }

    this.modalService.open('modal-info');
  }

  closeModalInfo() {
    this.modalService.close('modal-info');
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserGoalList$) {
      this.getUserGoalList$.unsubscribe();
    }
    if (this.sendRevision$) {
      this.sendRevision$.unsubscribe();
    }
    if (this.getMyFinalEvaluation$) {
      this.getMyFinalEvaluation$.unsubscribe();
    }
    if (this.result$) {
      this.result$.unsubscribe();
    }
  }
}