import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingComponent } from '../shared/components/onboarding/onboarding.component';
import { CollaboratorGuard } from '../shared/services/collaborator-guard.service';
import { CollaboratorComponent } from './collaborator.component';
import { OnboardingVideoComponent } from '../shared/components/onboarding-video/onboarding-video.component';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { CollaboratorHomeComponent } from './home/home.component';
import { CollaboratorPersonDetailsComponent } from './personDetails/personDetails.component';
import { CollaboratorGoalDetailsComponent } from './goalDetails/goalDetails.component';
import { CreateUpdateGoalCollaboratorComponent } from './createUpdateGoal/createUpdateGoal.component';
import { CollaboratorOrganizeInterviewComponent } from './organizeInterview/organizeInterview.component';
import { CanDeactivateUpdateInterviewCollaboratorGuardService } from './services/can-deactivate-update-interview-collaborator-guard.component';
import { CanDeactivateUpdateGoalCollaboratorGuardService } from './services/can-deactivate-update-goal-collaborator-guard.component';
import { NotificationsComponent } from '../core/notifications/notifications.component';
import { OnBoardingGuard } from '../shared/services/onboarding-guard.service';
import { SendClapCollaboratorComponent } from './sendClap/sendClap.component';
import { CanDeactivateUpdateClapCollaboratorGuardService } from './services/can-deactivate-update-clap-collaborator-guard.component';
import { CreateUpdateDevelopmentActionCollaboratorComponent } from './createUpdateDevelopmentActionCollaborator/createUpdateDevelopmentActionCollaborator.component';
import { ProfilePageComponent } from '../profilePage/profilePage.component';
import { CreateUpdateEvidenceComponent } from './createUpdateEvidence/createUpdateEvidence.component';
import { CreateUpdatePrivateNoteComponent } from './createUpdatePrivateNote/createUpdatePrivateNote.component';
import { AddMidTermReviewCollaboratorComponent } from './addMidTermReview/addMidTermReview.component';
import { FinalEvaluationCollaboratorComponent } from './finalEvaluation/finalEvaluation.component';
import { CreateUpdatePeerFeedbackComponent } from './createUpdatePeerFeedback/createUpdatePeerFeedback.component';
import { DefinePerformanceLevelCollaboratorComponent } from './definePerformanceLevel/definePerformanceLevel.component';
import { EvaluationGridComponent } from './evaluationGrid/evaluationGrid.component';
import { ObjectiveWizardComponent } from './objectiveWizard/objectiveWizard.component';


const collaboratorRoutes: Routes = [
  {
    path: '', component: CollaboratorComponent, children: [
      { path: 'home', component: CollaboratorHomeComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'profile', component: ProfilePageComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'onboarding', component: OnboardingComponent, canActivate: [CollaboratorGuard] },
      { path: 'onboardingVideo', component: OnboardingVideoComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },

      { path: 'personDetails/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/smartFeedback/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/smartFeedback/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/evidences/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/evidences/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/privateNotes/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/privateNotes/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/interview/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/interview/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/peerFeedback/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/feedback/peerFeedback/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/developmentPlan/:userId', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/developmentPlan/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'goalDetails/:userId/:goalId', component: CollaboratorGoalDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'goalDetails/:userId/:goalId/:perfCareYear', component: CollaboratorGoalDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'personDetails/:userId/:perfCareYear', component: CollaboratorPersonDetailsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'organizeInterview/:userId', component: CollaboratorOrganizeInterviewComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewCollaboratorGuardService] },
      { path: 'organizeInterview/:userId/:interviewId', component: CollaboratorOrganizeInterviewComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewCollaboratorGuardService] },
      { path: 'sendApplause', component: SendClapCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapCollaboratorGuardService] },
      { path: 'sendApplause/:userId', component: SendClapCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapCollaboratorGuardService] },
      { path: 'sendApplause/:userId/:clapId', component: SendClapCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapCollaboratorGuardService] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId/:goalId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentAction/:userId/:goalId/:developmentActionId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateDevelopmentActionEdit/:userId/:developmentActionId', component: CreateUpdateDevelopmentActionCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidence/:userId/:goalId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidenceOnEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateEvidenceOnEvidence/:userId/:evidenceId', component: CreateUpdateEvidenceComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote', component: CreateUpdatePrivateNoteComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote/:userId', component: CreateUpdatePrivateNoteComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePrivateNote/:userId/:noteId', component: CreateUpdatePrivateNoteComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/response/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdatePeerFeedback/:userId/:feedbackId', component: CreateUpdatePeerFeedbackComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'addMidTermReview', component: AddMidTermReviewCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'finalEvaluation/:perfCareYear/:goalId', component: FinalEvaluationCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'definePerformanceLevel/:perfCareYear', component: DefinePerformanceLevelCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'talentScoutingGrid/:perfCareYear', component: EvaluationGridComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      // wizard creazione obiettivi
      { path: 'objectiveWizard/:userId/:perfCareYear', component: ObjectiveWizardComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },

      { path: 'createUpdateGoal/draft/:userId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateGoal/optional/:userId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateGoal/optional/:userId/:goalId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateGoal/:userId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateGoal/:userId/:goalId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateChallenge/:userId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: 'createUpdateChallenge/:userId/:goalId', component: CreateUpdateGoalCollaboratorComponent, canActivate: [CollaboratorGuard, OnBoardingGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(collaboratorRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class CollaboratorRoutingModule { }
