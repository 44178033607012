<ng-container *ngIf="isInterviewDone$ | async as interview">
  <div class="interview-header">
    <talent-tab-header *ngIf="selectedPerformanceYear$ | async as perfYear"
      [title]="translationPrefix + 'TITLE' | translate"
      [description]="(translationPrefix + 'DESCRIPTION' | translate) + ' ' + perfYear">
    </talent-tab-header>

    <europ-button type="primary" [routerLink]="['start']" [label]="getInterviewActionLabel(interview) | translate">
    </europ-button>
  </div>



  <ng-container *ngIf="interview.isDone; else noInterviewFound">

    <div class="card-container" *ngIf="hrInterviewQuestions$ | async as interview; else loading ">


      <ng-container *ngIf="isObjectNotEmpty(interview.evaluationUser)">

        <div class="avatar" *ngIf="interview.evaluationUser as interviewer">
          <avatar-img [user]="interviewer" size="big"></avatar-img>
          <!--figure>
            <img [src]="getInterviewerAvatar(interviewer)" alt="user avatar">
          </figure-->
          <div class="interviewer-data-container">
            <p class="primary-text-color">{{ translationPrefix + 'INTERVIEWED_BY' | translate }} <span
                class="primary-text-color">{{ interviewer | fullName }}</span></p>
            <p class="text-gray"><em><strong class="text-gray">{{ 'talent.obs-grid.evaluator.ON_DATE' | translate
                  }}:</strong></em> {{ interview.evaluationDate | date: 'dd.MM.yyyy' }}</p>
          </div>
        </div>

      </ng-container>


      <div class="evaluation-answers" *ngFor="let page of interview.pages; let pageIdx = index">

        <h3 class="page-title"> {{ page.pageTitle }} </h3>
        <a (click)="navigateToInterview(pageIdx + 1)">{{ translationPrefix + "EDIT_AREA" | translate }}</a>

        <div class="question" *ngFor="let question of page.questions; let i = index; let last = last">
          <p class="question-title"><span *ngIf="pageIdx === 0">{{ i + 1 }}. </span> {{question.text}}</p>
          <p class="answer">
            {{ getAnswerOrPlaceholder(question.selectedAnswerText, last) }}
          </p>
        </div>

      </div>

    </div>

  </ng-container>



</ng-container>


<ng-template #noInterviewFound>

  <div class="card-container not-found">
    <figure>
      <img src="assets/img/section/op-goal-male.svg" alt="Interview" />
    </figure>

    <h2 class="primary-text-color bold">{{ translationPrefix + "NO_INTERVIEW_FOUND" | translate }}</h2>
    <p class="primary-text-color">{{ translationPrefix + "NO_INTERVIEW_FOUND_DESCR" | translate }}</p>
  </div>

</ng-template>



<!-- Loader caricamento dati utente -->
<ng-template #loading>
  <div class="loader spinner-big"></div>
</ng-template>

<!-- Display http-error -->
<modal id="failed-to-fetch-talent-interview" (onClose)="closeModal()">
  <modal-text-content modalId="cal022" [title]="'errors.WARNING' | translate"
    [text]="'errors.TALENT_COULD_NOT_FETCH_EVALUATION' | translate">
  </modal-text-content>
</modal>