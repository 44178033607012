<page-container>
  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="sendRevisionAction(true)" [isBackButton]="true"
      [title]="'performance.ACCORDION_TITLE' | translate" [description]="'performance.SIDENAV_SUBTITLE' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content card">
      <ng-container *ngIf="!isLoadingUserGoals && !isLoadingManagerInfo">
        <div *ngFor="let goal of goalList" fxLayout="column" class="goal-list">
          <objective-for-mid-term-review [goal]="goal" [isOperations]="isOperations" [isInnerHtml]="true">
          </objective-for-mid-term-review>
          <ng-container *ngIf="getUserGoalEvaluation(goal)">
            <evaluation class="margin-top10" [evaluation]="getUserGoalEvaluation(goal)"></evaluation>
          </ng-container>
          <div class="divisor-vertical-line"></div>
        </div>
        <!-- recap dati utente e mid term review -->
        <mid-term-recap [user]="loggedUser" [date]="userRevisionDate" [status]="statusForRecap"
          [observation]="osservation"></mid-term-recap>
        <!-- Risposta del manager alla review -->
        <ng-container *ngIf="managerAnswer">
          <div class="divisor-vertical-line"></div>
          <mid-term-recap [user]="managerData" [date]="managerRevisionDate" [observation]="managerOsservation">
          </mid-term-recap>
        </ng-container>
        <div class="performance-level-input">
          <!-- Osservazioni -->
          <input-container [numberCharsTextArea]="charsFinalObservation" [isFromTextArea]="true"
            [isLoading]="isLoadingUserGoals || isLoadingManagerInfo" [tooltipModalMessage]="observationTooltip"
            [isValued]="finalObservation && finalObservation.trim().length" [isObbligatory]="true"
            title="{{'performance.INSERT_OBSERVATION' | translate}}">
            <europ-text-area [bindValue]="finalObservation" [maxlength]="600"
              (onModelChanged)="finalObservationChanged($event)"
              placeholder="{{'performance.OBSERVATION_PLACEHOLDER' | translate}}">
            </europ-text-area>
          </input-container>

          <div class="pl-mark-card-container">
            <div class="title-container">
              <p translate="performance.INSERT_VALUE"></p>
              <!--span *ngIf="isObbligatory && !isValued">*</span-->
              <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer"
                [applyClass]="true" (click)="openMarkCardInfoModal()">
              </svg-icon>
            </div>
            <card-performance class="full-width" [bindValue]="selectedMark"
              (onItemChange)="selectFinalEvaluation($event)"></card-performance>
          </div>

          <div class="action-container">
            <europ-button type="primary" [label]="'performance.VALUE' | translate" (onButtonClicked)="confirmValue()"
              [disabled]="!finalObservation || !selectedMark">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingUserGoals || isLoadingManagerInfo">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'invio della revisione -->
<modal id="modal-exit" (onCancel)="isFromBack ? goBackBrowser() : closeModalExit()" (onClose)="closeModalExit()"
  (onConfirm)="sendRevision()" [cancelLabel]="'generic.ANNULL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content [modalId]="'d1060'" [title]="'performance.modals.GO_BACK' | translate"
    [subtitle]="'performance.modals.GO_BACK_SUB' | translate" [text]="'performance.modals.GO_BACK_DESCR' | translate">
  </modal-text-content>
</modal>


<modal id="modal-info" (onClose)="closeModalInfo()" (onConfirm)="closeModalInfo()" [confirmLabel]="'generic.CLOSE' | translate">
  <p class="modal-title" translate="evaluations.modals.INFO_TITLE"></p>
  <div class="divisor-line"></div>
  <div class="performance-level-mark-info-container">
    <div class="title-row">
      <evaluation evaluation="UNSATISFACTORY" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.UNSATISFACTORY"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="IMPROVABLE" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.IMPROVABLE"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="MEET" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.MEET"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEEDED" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEEDED"></p>
    <div class="divisor-line"></div>

    <div class="title-row">
      <evaluation evaluation="EXCEPTIONAL" [scale]="5"></evaluation>
    </div>
    <p class="mark-text" translate="evaluations.modals.EXCEPTIONAL"></p>
  </div>
</modal>