<div class="page-content-wrapper">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <ng-container
        *ngIf="!isLoadingYearList && !isLoadingPersonDetails && !isLoadingInterview && !isLoadingManagerInfo && personDetails">
        <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="personDetails"
            [interview]="interview" (onNewInterview)="goToInterview()" (onAnnullInterview)="goToDeleteInterview($event)"
            [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
            [isChief]="isChief">
        </header-dossier>
        <!-- 'no-sti-padding': contentTabList && contentTabList[0] && contentTabList[0].noSti, -->
        <div class="main-content-wrapper" [ngClass]="{'sti-label-wrapper': isObjectiveSection && stiAmount > 0}">

            <!-- label sti -->
            <ng-container *ngIf="isObjectiveSection && stiAmount > 0">
                <div class="sti-wrapper">
                    <p class="sti-label">STI</p>
                    <p class="sti-value">€ {{ stiAmount }}</p>
                </div>
            </ng-container>


            <!-- Contenitore goal setting -->
            <ng-container *ngIf="isObjectiveSection">
                <div class="wizard-page-content" *ngIf="!isLoadingSteps && stepList && stepList.length">
                    <stepper [statuses]="stepList" [currentStatus]="currentStep" (stepClicked)="stepClicked($event)">
                    </stepper>

                    <!-- 1 - Definizione obiettivi di ruolo -->
                    <ng-container *ngIf="!isLoadingUserGoals && currentStep && currentStep.id == 'GOAL_DEFINITION'">
                        <!-- Box obiettivi cascading -->
                        <div class="cascading-objective-box" *ngIf="cascadingGoal && cascadingGoal.description">
                            <p translate="cascading.TILE_TITLE"></p>
                            <div class="show-container" (click)="openCascadingModal()">
                                <p translate="cascading.SHOW_OBJECTIVES"></p>
                                <svg-icon src="assets/img/icons/info.svg" class="show-icon" [applyClass]="true">
                                </svg-icon>
                            </div>
                        </div>

                        <div class="wizard-accordion-container">
                            <!-- Titolo accordion-->
                            <div class="wizard-accordion-title-container">
                                <h2 class="wizard-accordion-title" [innerHTML]="getRoleAccordionTitle()">
                                </h2>
                                <!-- informaziva-->
                                <div class="wizard-info" (click)="openCardInfo()">
                                    <p class="info-text" translate="objectiveWizard.CARD_INFO"></p>
                                    <svg-icon class="icon" [applyClass]="true" src="assets/img/icons/info.svg">
                                    </svg-icon>
                                </div>
                            </div>

                            <!-- Contenuto accordion -->
                            <div class="wizard-accordion-content" [ngClass]="{'edit': roleObjectiveWeight > 0}">
                                <svg-icon *ngIf="roleObjectiveWeight == 0" class="step-icon" [applyClass]="true"
                                    src="assets/img/step/step1.svg">
                                </svg-icon>

                                <div class="text-content">
                                    <!-- Se ho inserito degli obiettivi -->
                                    <ng-container *ngIf="proposalStatus.status != 'REJECTED'">
                                        <h3 class="step-title" *ngIf="roleObjectiveWeight == 0"
                                            translate="objectiveWizard.1.PROPOSE_OBJECTIVE"></h3>
                                        <p class="step-descr" [innerHTML]="getStepDescription(currentStep.id)"></p>
                                    </ng-container>
                                    <!-- Se proposta negata-->
                                    <ng-container *ngIf="proposalStatus.status == 'REJECTED'">
                                        <h4 translate="objectiveWizard.1.DENIED_TITLE"></h4>
                                        <p class="step-descr" [innerHTML]="getDeniedAccordionDescr()"></p>
                                        <p class="step-link" translate="generic.READ_MOTIVE"
                                            (click)="openStatusCommentModal()"></p>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="roleObjectiveWeight == 0">
                                    <europ-button [label]="'objectiveWizard.1.ADD_OBJECTIVE' | translate" type="primary"
                                        (onButtonClicked)="goToCreateEditObjective()">
                                    </europ-button>
                                </ng-container>
                            </div>

                        </div>

                        <!-- Lista obiettivi di ruolo -->
                        <ng-container *ngFor="let objective of roleObjectiveList">
                            <goal-card [goal]="objective" [isCollaborator]="true" [loggedUser]="loggedUser"
                                [proposalStatus]="proposalStatus"
                                (onOptionClicked)="onObjectiveOptionClicked($event, objective)"
                                (onDetailClicked)="openObjectiveDetails(objective)">
                            </goal-card>
                        </ng-container>


                        <ng-container *ngIf="roleObjectiveWeight > 0">
                            <europ-button [label]="'objectiveWizard.1.ADD_OBJECTIVE' | translate" type="primary"
                                (onButtonClicked)="goToCreateEditObjective()">
                            </europ-button>
                        </ng-container>

                        <div class="divisor-line"></div>

                        <div class="wizard-page-action right">
                            <europ-button [label]="'objectiveWizard.NEXT' | translate" type="primary link"
                                iconRight="assets/img/icons/arrow-right.svg"
                                (onButtonClicked)="goToNextStep(currentStep.id)">
                            </europ-button>
                        </div>
                    </ng-container>


                    <!-- 2 - Definisci l'obiettivo di sviluppo -->
                    <ng-container
                        *ngIf="!isLoadingUserGoals && currentStep && currentStep.id == 'DEVELOPMENT_GOAL_DEFINITION'">

                        <div class="wizard-accordion-container">
                            <!-- Titolo accordion sfida -->
                            <div class="wizard-accordion-title-container">
                                <h2 class="wizard-accordion-title">
                                    <b>{{ ('objectiveWizard.2.ACCORDION_TITLE' | translate) + selectedYear.id}}</b>
                                </h2>
                                <!-- informativa sfida -->
                                <div class="wizard-info" (click)="openCardInfo()">
                                    <p class="info-text" translate="objectiveWizard.CARD_INFO"></p>
                                    <svg-icon class="icon" [applyClass]="true" src="assets/img/icons/info.svg">
                                    </svg-icon>
                                </div>
                            </div>

                            <!-- Contenuto accordion sfida -->
                            <div class="wizard-accordion-content" [ngClass]="{'edit': devObjective}">

                                <svg-icon *ngIf="!devObjective" class="step-icon" [applyClass]="true"
                                    src="assets/img/step/step1.svg">
                                </svg-icon>
                                <div class="text-content">
                                    <!-- Se sfida da inserire/inserita -->
                                    <ng-container *ngIf="proposalStatus.status != 'REJECTED'">
                                        <h3 class="step-title" *ngIf="!devObjective"
                                            translate="objectiveWizard.2.PROPOSE_CHALLENGE"></h3>
                                        <p class="step-descr" [innerHTML]="getStepDescription(currentStep.id)"></p>
                                    </ng-container>
                                    <!-- Se proposta negata-->
                                    <ng-container *ngIf="proposalStatus.status == 'REJECTED'">
                                        <h4 translate="objectiveWizard.1.DENIED_TITLE"></h4>
                                        <p class="step-descr" [innerHTML]="getDeniedAccordionDescr()"></p>
                                        <p class="step-link" translate="generic.READ_MOTIVE"
                                            (click)="openStatusCommentModal()"></p>
                                    </ng-container>

                                </div>
                                <europ-button *ngIf="!devObjective"
                                    [label]="'objectiveWizard.1.ADD_OBJECTIVE' | translate" type="primary"
                                    (onButtonClicked)="goToCreateEditChallenge()">
                                </europ-button>
                            </div>
                        </div>


                        <!-- Lista obiettivi di sviluppo -->
                        <goal-card [goal]="devObjective" (onDetailClicked)="openObjectiveDetails(devObjective)"
                            [loggedUser]="loggedUser" [isCollaborator]="true"
                            (onOptionClicked)="onObjectiveOptionClicked($event, devObjective)">
                        </goal-card>

                        <div class="divisor-line"></div>

                        <div class="wizard-page-action double">
                            <europ-button [label]="'objectiveWizard.PREV' | translate" type="primary link"
                                iconLeft="assets/img/icons/arrow-left.svg"
                                (onButtonClicked)="goToPrevStep(currentStep.id)">
                            </europ-button>

                            <europ-button [label]="'objectiveWizard.NEXT' | translate" type="primary link"
                                iconRight="assets/img/icons/arrow-right.svg"
                                (onButtonClicked)="goToNextStep(currentStep.id)">
                            </europ-button>
                        </div>
                    </ng-container>


                    <!-- 3 - Organizza il colloquio di feedback -->
                    <ng-container *ngIf="!isLoadingUserGoals && currentStep && currentStep.id == 'FEEDBACK_DIALOGUE'">
                        <!-- Contenuto accordion colloquio -->
                        <div class="wizard-accordion-container">
                            <!-- Titolo accordion coplloquio -->
                            <div class="wizard-accordion-title-container">
                                <h2 class="wizard-accordion-title">
                                    <b>{{ 'objectiveWizard.3.ACCORDION_TITLE' | translate}}</b>
                                </h2>
                                <div class="wizard-info" (click)="openCardInfo()">
                                    <p class="info-text" translate="objectiveWizard.CARD_INFO"></p>
                                    <svg-icon class="icon" [applyClass]="true" src="assets/img/icons/info.svg">
                                    </svg-icon>
                                </div>
                            </div>

                            <!-- Contenuto accordion colloquio di feedback-->
                            <div class="wizard-accordion-content">
                                <svg-icon class="step-icon" [applyClass]="true" src="assets/img/step/step3.svg">
                                </svg-icon>
                                <div class="text-content">
                                    <p class="step-descr" [innerHTML]="getStepDescription(currentStep.id)"></p>
                                </div>
                                <!-- data pianificazione colloquio di feedback -->
                                <input-container [isObbligatory]="true" [isValued]="feedbackMeetingDate"
                                    [isWithoutInfoIcon]="true" [isLoading]="isLoadingUserGoals" [isObbligatory]="true"
                                    title="{{'objectiveWizard.3.PLANNED_DATE' | translate}}">
                                    <europ-datepicker [value]="feedbackMeetingDate"
                                        (dateChanged)="feedbackMeetingDateChanged($event)">
                                    </europ-datepicker>
                                </input-container>

                                <europ-button [label]="'generic.CONFIRM' | translate" type="primary"
                                    (onButtonClicked)="confirmFeedbackMeeting()">
                                </europ-button>
                            </div>

                        </div>

                        <div class="divisor-line"></div>

                        <div class="wizard-page-action double">
                            <europ-button [label]="'objectiveWizard.PREV' | translate" type="primary link"
                                iconLeft="assets/img/icons/arrow-left.svg"
                                (onButtonClicked)="goToPrevStep(currentStep.id)">
                            </europ-button>

                            <europ-button [label]="'objectiveWizard.NEXT' | translate" type="primary link"
                                iconRight="assets/img/icons/arrow-right.svg"
                                (onButtonClicked)="goToNextStep(currentStep.id)">
                            </europ-button>
                        </div>

                    </ng-container>



                    <!-- 4 - Riepilogo -->
                    <ng-container *ngIf="!isLoadingUserGoals && currentStep && currentStep.id == 'GOAL_SHARING'">
                        <!-- Accordion riepilogo -->
                        <div class="wizard-accordion-container">
                            <!-- Titolo accordion riepilogo -->
                            <div class="wizard-accordion-title-container">
                                <h2 class="wizard-accordion-title">
                                    <b>{{ 'objectiveWizard.4.ACCORDION_TITLE' | translate}}</b>
                                </h2>
                                <div class="wizard-info" (click)="openCardInfo()">
                                    <p class="info-text" translate="objectiveWizard.CARD_INFO"></p>
                                    <svg-icon class="icon" [applyClass]="true" src="assets/img/icons/info.svg">
                                    </svg-icon>
                                </div>
                            </div>

                            <!-- Contenuto accordion riepilogo -->
                            <div class="wizard-accordion-content">
                                <svg-icon class="step-icon" [applyClass]="true" src="assets/img/step/step4.svg">
                                </svg-icon>
                                <div class="text-content">
                                    <p class="step-descr" [innerHTML]="getStepDescription(currentStep.id)"></p>
                                </div>

                                <div class="step-check-container">
                                    <!-- check obiettivi di ruolo -->
                                    <div class="step-check-row">
                                        <div class="check-container">
                                            <ng-container *ngIf="finalStepInfo.goalDefinitionCompleted">
                                                <svg-icon class="icon success" [applyClass]="true"
                                                    src="assets/img/icons/check.svg">
                                                </svg-icon>
                                            </ng-container>
                                            <ng-container *ngIf="!finalStepInfo.goalDefinitionCompleted">
                                                <svg-icon class="icon error" [applyClass]="true"
                                                    src="assets/img/icons/x.svg">
                                                </svg-icon>
                                            </ng-container>
                                        </div>
                                        <p class="text" translate="objectiveWizard.4.ROLE"></p>
                                    </div>
                                    <!-- check obiettivi di sviluppo / sfida -->
                                    <div class="step-check-row">
                                        <div class="check-container">
                                            <ng-container *ngIf="finalStepInfo.developmentGoalDefinitionCompleted">
                                                <svg-icon class="icon success" [applyClass]="true"
                                                    src="assets/img/icons/check.svg">
                                                </svg-icon>
                                            </ng-container>
                                            <ng-container *ngIf="!finalStepInfo.developmentGoalDefinitionCompleted">
                                                <svg-icon class="icon error" [applyClass]="true"
                                                    src="assets/img/icons/x.svg">
                                                </svg-icon>
                                            </ng-container>
                                        </div>
                                        <p class="text" translate="objectiveWizard.4.DEV"></p>
                                    </div>
                                    <!-- check colloquio di feedback -->
                                    <div class="step-check-row">
                                        <europ-check [disabled]="!checkFeedbackMeetingDate()"
                                            [checked]="finalStepInfo.meetingCheck"
                                            (onCheck)="toggleFeedbackMeetingDate()">
                                        </europ-check>
                                        <p class="text" translate="objectiveWizard.4.OBJECTIVE_REVIEWED"></p>
                                    </div>
                                    <!-- check regolamento sti -->
                                    <ng-container *ngIf="stiAmount > 0 || isSti">
                                        <div class="step-check-row" (click)="openStiRuleModal()">
                                            <europ-check [checked]="finalStepInfo.stiAccepted" [readonly]="true">
                                            </europ-check>
                                            <p class="text" translate="objectiveWizard.4.ACCEPT_STI"></p>
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Condividi obiettivi -->
                                <europ-button [disabled]="!canShareObjectives()"
                                    [label]="'objectiveWizard.4.SHARE_OBJECTIVES' | translate" type="primary"
                                    (onButtonClicked)="openShareWizardCardModal()">
                                </europ-button>
                            </div>

                        </div>

                        <div class="divisor-line"></div>

                        <div class="wizard-page-action double">
                            <europ-button [label]="'objectiveWizard.PREV' | translate" type="primary link"
                                iconLeft="assets/img/icons/arrow-left.svg"
                                (onButtonClicked)="goToPrevStep(currentStep.id)">
                            </europ-button>
                        </div>
                    </ng-container>

                    <!-- loader contenuto step -->
                    <ng-container *ngIf="isLoadingUserGoals">
                        <div class="wizard-page-content">
                            <div class="loader spinner-medium"></div>
                        </div>
                    </ng-container>

                </div>



                <!-- loader lista step -->
                <ng-container *ngIf="isLoadingSteps">
                    <div class="wizard-page-content">
                        <div class="loader spinner-medium"></div>
                    </div>
                </ng-container>
                <!-- Se il profilo utente non è definito-->
                <ng-container *ngIf="!isLoadingSteps && !isLoadingUserGoals && (!stepList || !stepList.length)">
                    <div class="wizard-page-content">
                        <no-data [title]="('generic.WARNING' | translate)" src="/assets/img/section/goal-setting.svg"
                            [description]="'errors.USER_TAB_NOT_FOUND' | translate">
                        </no-data>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <!-- Loader caricamento dati utente -->
    <ng-container *ngIf="isObjectiveSection && (isLoadingPersonDetails || isLoadingInterview ) ||isLoadingYearList">
        <div class="loader spinner-big"></div>
    </ng-container>
</div>

<!-- Modale per errore condivisione -->
<modal id="errorShareModal" (onClose)="closeErrorModal()" (onConfirm)="closeErrorModal()"
    confirmLabel="{{ 'generic.GOT_IT' | translate}}">
    <modal-text-content [noBackground]="true" [image]="shareModalContent.image" [title]="shareModalContent.title"
        [subtitle]="shareModalContent.subtitle" [text]="shareModalContent.text">
    </modal-text-content>
</modal>

<!-- Modale per annullare il colloquio -->
<modal id="deleteInterviewModal" [cancelLabel]="'No'" (onCancel)="closeModalDeleteInterview()"
    (onClose)="closeModalDeleteInterview()" (onConfirm)="deleteInterview()"
    confirmLabel="{{ 'generic.YES' | translate}}">
    <modal-text-content modalId="601" title="{{ 'goalSetting.organizeInterview.DELETE_INTERVIEW' | translate}}"
        text="{{ 'goalSetting.organizeInterview.DELETE_INTERVIEW_TEXT' | translate}}">
    </modal-text-content>
</modal>

<!-- Modale aggiunta/modifica file -->
<modal id="uploadFileOnEvidences"
    [isDisabled]="(!fileObject || !fileObject.fileExtension || !fileTitle || !fileTitle.trim().length)"
    (onClose)="closeUploadFileModal()" (onConfirm)="confirmUploadFile()"
    [confirmLabel]="('generic.CONFIRM' | translate)">
    <div class="file-upload-modal-wrapper">
        <p class="title align-center" translate="generic.CHARGE_FILE"></p>
        <!-- Upload file -->
        <ng-container>
            <input-container [isWithoutInfoIcon]="true" [isValued]="fileTitle && fileTitle.trim().length"
                [isObbligatory]="true" title="{{'managerOperations.modals.FILE_TITLE' | translate}}">
                <europ-input [bindValue]="fileTitle" (onModelChanged)="onFileTitleChanged($event)"
                    placeholder="{{'managerOperations.modals.TITLE_PLACEHOLDER' | translate}}">
                </europ-input>
            </input-container>
            <input-container [instructions]="'goalSetting.UPLOAD_FILES_ACCEPTED' | translate"
                [isValued]="fileObject && fileObject.fileExtension" [isWithoutInfoIcon]="true" [isObbligatory]="true"
                title="{{'managerOperations.modals.FILE_IN_AREA' | translate}}">
                <europ-upload [isToReset]="isToResetUploadComponent" (onUpload)="assignFile($event)"></europ-upload>
            </input-container>
        </ng-container>
    </div>
</modal>

<!-- Modale elimina file -->
<modal id="deleteFileModal" [cancelLabel]="('generic.CANCEL' | translate)"
    (onCancel)="closeDeleteFileModalOnEvidence(false)" (onClose)="closeDeleteFileModalOnEvidence(false)"
    (onConfirm)="closeDeleteFileModalOnEvidence(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
    <modal-text-content modalId="opm017" [title]="'managerOperations.modals.DELETE_FILE_TITLE' | translate"
        [text]="'managerOperations.modals.DELETE_FILE_TEXT' | translate">
    </modal-text-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
    (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
    confirmLabel="{{ 'generic.CONFIRM' | translate}}">
    <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
        [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
    </modal-text-content>
</modal>


<!-- Modale eliminazione peer feedback -->
<modal id="deletePeerFeedback" [cancelLabel]="('generic.CANCEL' | translate)"
    (onCancel)="closeDeletePeerFeedbackModal()" (onClose)="closeDeletePeerFeedbackModal(false)"
    (onConfirm)="closeDeletePeerFeedbackModal(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
    <modal-text-content modalId="pf013" [title]="'peerFeedback.modals.DELETE_FEEDBACK_TITLE' | translate"
        [text]="'peerFeedback.modals.DELETE_FEEDBACK_TEXT' | translate">
    </modal-text-content>
</modal>

<!-- Modale mostra richiesta peer feedback -->
<modal id="modalShowRequest" (onClose)="closeShowFeedbackRequest()" (onConfirm)="closeShowFeedbackRequest()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <div class="peer-feedback-modal-request">
        <div class="title-code-wrapper full-width">
            <div class="title-container" translate="peerFeedback.card.REQUEST_TITLE"></div>
        </div>
        <div class="confirm-request-content" *ngIf="feedbackForRequestModal">
            <div class="section users">
                <div class="title" translate="peerFeedback.modals.REQUEST_FROM"></div>
                <div class="content">
                    <div class="mini-user-card">
                        <avatar-img size="small" [user]="feedbackForRequestModal.recipientUser"></avatar-img>
                        <p class="name">{{ feedbackForRequestModal.recipientUser.forename + ' ' +
                            feedbackForRequestModal.recipientUser.surname}}</p>
                    </div>
                </div>
            </div>
            <div class="section" *ngIf="feedbackForRequestModal.goal">
                <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
                <div class="content">{{ feedbackForRequestModal.goal && feedbackForRequestModal.goal.title}}</div>
            </div>
            <div class="section">
                <div class="title" translate="peerFeedback.modals.WHY"></div>
                <div class="content">{{ feedbackForRequestModal.request }}</div>
            </div>
        </div>
    </div>
</modal>

<!-- Modale per valutazione finale -->
<modal id="finalEvaluationModal" (onClose)="closeFinalEvaluationModal()" (onConfirm)="closeFinalEvaluationModal()"
    confirmLabel="{{ 'generic.CLOSE' | translate}}">
    <div class="final-evaluation-modal-container" *ngIf="finalEvaluationForModal">
        <p class="modal-title"
            translate="{{personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS' : 'final.TITLE'}}">
        </p>
        <evaluation [evaluation]="finalEvaluationForModal.evaluationScore"
            [scale]="finalEvaluationForModal.final ? 5 : 3" customClass="big"></evaluation>
        <div class="divisor-line"></div>
        <div class="observation-container">
            <p class="title">
                {{ ('final.SELF_OBS_BY' | translate) + finalEvaluationForModal.user.forename + ' ' +
                finalEvaluationForModal.user.surname
                }}
            </p>
            <p class="descr">{{ ('final.INSERT_DATE' | translate) + (finalEvaluationForModal.creationDate | date:
                'dd/MM/YYYY') }}</p>
            <p class="title" translate="final.OBSERVATION"></p>
            <p class="descr">{{finalEvaluationForModal.observation}}</p>
        </div>
    </div>
</modal>

<!-- Modale per il performance level -->
<modal id="performance-level-modal" (onClose)="closerPerformanceLevelModal()"
    (onConfirm)="closerPerformanceLevelModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
    <ng-container *ngIf="postCalibrationData">
        <p class="modal-title">
            {{ ('calibration.modals.CONFIRM_EVAL' | translate)}}</p>
        <div class="confirmation-container calibration">
            <div class="observation-container">
                <p class="subtitle" translate="calibration.modals.OBSERVATIONS"></p>
                <p class="text">{{ postCalibrationData.observations || ('calibration.NO_OBSERVATION' | translate)}}</p>
            </div>
            <div class="value-container">
                <evaluation customClass="big" [scale]="5" [evaluation]="postCalibrationData.level"></evaluation>
                <ng-container *ngIf="postCalibrationData.payout">
                    <div class="payout-label">
                        <p>{{postCalibrationData.payout.title + ' ' + ('performance.modals.OF_PAYOUT' | translate)}}</p>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</modal>

<!-- Modale di dettaglio obiettivo -->
<modal id="objectiveDetails" (onClose)="closeObjectiveDetails()" (onConfirm)="closeObjectiveDetails()"
    confirmLabel="{{ 'generic.CONFIRM' | translate}}">
    <div class="objective-details" *ngIf="objectiveForModal">
        <h3 translate="generic.OBJECTIVE_DETAILS"></h3>

        <div class="section-container">
            <!-- Descrizione -->
            <div class="section" *ngIf="objectiveForModal.description">
                <h5 translate="generic.DESCRIPTION"></h5>
                <p
                    [innerHTML]="objectiveForModal.description ? objectiveForModal.description : ('generic.NO_DESCRIPTION_ADDED' | translate)">
                </p>
            </div>
            <!-- Descrizione "Cosa ti farà dire che l’obiettivo è stato raggiunto? "-->
            <!-- OBJECTIVE_DESCRIPTION_COLLABORATOR Sfida -->
            <div class="section" *ngIf="objectiveForModal.describeWhatYouIntendToDoForWinChallenge">
                <h5 translate="goalSetting.createGoal.OBJECTIVE_DESCRIPTION_COLLABORATOR"></h5>
                <p [innerHTML]="objectiveForModal.describeWhatYouIntendToDoForWinChallenge"></p>
            </div>
            <!-- Attributo CARE -->
            <div class="section" *ngIf="objectiveForModal.careTitle">
                <h5 translate="generic.CARE_ATTRIBUTE"></h5>
                <p>{{ objectiveForModal.careTitle }}</p>
            </div>
            <!-- Commento del manager -->
            <div class="section" *ngIf="objectiveForModal.managerComment">
                <h5 translate="goalSetting.MANAGER_COMMENT"></h5>
                <p [innerHTML]="objectiveForModal.managerComment"></p>
            </div>
            <!-- Evidenze -->
            <div class="section"
                *ngIf="objectiveForModal.evidenceFeedbacks && objectiveForModal.evidenceFeedbacks.length">
                <h5 translate="accordion.EVIDENCES"></h5>
                <ng-container *ngFor="let evidence of objectiveForModal.evidenceFeedbacks">
                    <card-evidence [goalTitle]="objectiveForModal.title" [isInChallengeDetail]="true" [plain]="true"
                        [object]="evidence" (onFileDownload)="downloadFileOnEvidences($event, evidence.feedbackId)"
                        [isHistory]="!isInCurrentYear()">
                    </card-evidence>
                </ng-container>
            </div>
            <div class="section" *ngIf="objectiveForModal.goalEvaluation && objectiveForModal.goalEvaluation.length">
                <h5 translate="phases.FINAL_EVALUATION"></h5>
                <ng-container *ngFor="let evaluation of objectiveForModal.goalEvaluation">
                    <!-- Controllo se il performance level è fatto per mostrare la valutazione del manager -->
                    <ng-container
                        *ngIf="evaluation.user.userId == loggedUser.userId || postCalibrationData?.isComplete && evaluation.user.userId != loggedUser.userId">
                        <div class="final-evaluation-row white-background">
                            <div class="left-side">
                                <p class="user-name">
                                    {{ evaluation.user.forename[0] + '. ' + evaluation.user.surname }}
                                </p>
                                <evaluation [evaluation]="evaluation.evaluationScore" [scale]="3" customClass="medium">
                                </evaluation>
                            </div>
                            <p class="compiled">
                                {{ ('final.COMPILED_IN' | translate) + formatDate(evaluation.creationDate) }}
                            </p>
                        </div>
                        <div class="divisor-line"></div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</modal>

<!-- Modale eliminazione obiettivo -->
<modal id="deleteObjective" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteObjectiveModal()"
    (onClose)="closeDeleteObjectiveModal()" (onConfirm)="closeDeleteObjectiveModal(true)"
    confirmLabel="{{ 'generic.CONFIRM' | translate}}">
    <modal-text-content modalId="delo001" [title]="'objectiveWizard.sharedModals.DELETE_OBJECTIVE_TITLE' | translate"
        [subtitle]="'objectiveWizard.sharedModals.DELETE_OBJECTIVE_SUB' | translate"
        [text]="'objectiveWizard.sharedModals.DELETE_OBJECTIVE_TEXT' | translate">
    </modal-text-content>
</modal>


<!-- Modale accettazione regolamento sti -->
<modal id="stiRuleModal" [cancelLabel]="('generic.DONT_ACCEPT' | translate)" (onCancel)="closeStiRuleModal(false)"
    (onClose)="closeStiRuleModal(false)" (onConfirm)="closeStiRuleModal(true)"
    confirmLabel="{{ 'generic.ACCEPT' | translate}}">
    <modal-text-content modalId="sti000" [title]="'objectiveWizard.4.modal.STI_TITLE' | translate"
        [text]="'objectiveWizard.4.modal.STI_DESCR' | translate">
    </modal-text-content>
</modal>

<!-- Modale di condivisione scheda-->
<modal id="share-wizard-card" (onClose)="closeShareObjectiveModal()" (onConfirm)="closeShareObjectiveModal(true)"
    confirmLabel="{{ 'generic.CONFIRM' | translate}}">
    <h4 class="modal-title center" translate="objectiveWizard.4.modal.SHARE_TITLE"></h4>
    <div class="wizard-modal-container">
        <svg-icon class="share-image" [applyClass]="true" src="assets/img/step/step4.svg">
        </svg-icon>
        <div class="text-container">
            <p class="subtitle" translate="objectiveWizard.4.modal.SHARE_SUBTITLE"></p>
            <p class="text" [innerHTML]="this.managerData  ? 
                (('objectiveWizard.4.modal.SHARE_DESCR_1' | translate) + this.managerData.forename + ' ' + this.managerData.surname +
                ('objectiveWizard.4.modal.SHARE_DESCR_2' | translate)) : '' "></p>
        </div>
    </div>
</modal>

<!-- Modale visualizzazione obiettivi cascading -->
<modal id="cascading-modal" (onCancel)="closeCascadingModal()" (onClose)="closeCascadingModal()"
    (onConfirm)="closeCascadingModal()" [confirmLabel]="'generic.CONFIRM' | translate">
    <modal-text-content *ngIf="cascadingGoal" modalId="ccasc001" [title]="'cascading.TILE_TITLE' | translate"
        [subtitle]="'cascading.WHICH' | translate" [text]="formatCascadingDescription()">
    </modal-text-content>
</modal>