<div class="page-content-wrapper">
    <div class="back-browser-container">
        <europ-button iconLeft="assets/img/icons/arrow-left.svg" [label]="('generic.GO_BACK' | translate)"
            (onButtonClicked)="redirectService.goBackBrowser()" type="primary link" size="medium">
        </europ-button>
    </div>

    <!-- [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)" -->
    <header-dossier (downloadReport)="onDowloadReport()" [isTalentHeader]="true" [isDashboard]="true" [hasTabs]="false">
    </header-dossier>

    <div class="main-content-wrapper admin">

        <div class="search-container">
            <europ-input (onIconClicked)="onSearch(true)" typeInput="text" [bindValue]="searchedText"
                (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                [placeholder]="'generic.SEARCH_BY_NAME_SURNAME_CID' | translate" [isSearchBar]="true">
            </europ-input>
        </div>

        <div class="admin-table-container column-spacing">
            <!-- Tabella lista utenti screening -->
            <ng-container *ngIf="!isLoadingUserData && userData && userData.counter">
                <div class="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                                <th translate="{{ ('adminTable.NOTES' | translate)}}"></th>
                                <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                                <th translate="{{ ('adminTable.AGE' | translate)}}"></th>
                                <th translate="{{ ('adminTable.MANAGER' | translate)}}"></th>
                                <th class="center" translate="{{ ('adminTable.POTENTIAL' | translate)}}"></th>
                                <th class="center" translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of userData.list |
                                        slice:0:userData.numRecords |
                                        paginate: { id: 'userList',
                                                    itemsPerPage: userData.numRecords,
                                                    currentPage: userData.page,
                                                    totalItems: userData.counter }">
                                <td>
                                    <div class="avatar-name-container">
                                        <avatar-img [user]="user" size="small"></avatar-img>
                                        <p>{{ user.surname + ' ' + user.forename }}</p>
                                    </div>
                                </td>
                                <td class="center">
                                    <div class="note-number-container" (click)="openNoteListModal(user)">
                                        <svg-icon src="assets/img/icons/message-square.svg" [applyClass]="true"
                                            class="note-icon">
                                        </svg-icon>
                                        <ng-container *ngIf="user.notesCount">
                                            <p class="note-number">{{user.notesCount}}</p>
                                        </ng-container>
                                    </div>
                                </td>
                                <td>
                                    <div class="count">
                                        {{ user.cid | truncate: [14, '...'] }}
                                    </div>
                                </td>
                                <td>{{ user.age }}</td>
                                <td>{{ user.managerSurname + ' ' + user.managerForename }}</td>
                                <td>
                                    <div class="status-evaluation">
                                        <item-list-team-status styleItem="border noMinWidth" type="primary"
                                            [showPoint]="false"
                                            [message]=" ('talent.potentialLevel.' + user.potentialLevel) | translate ">
                                        </item-list-team-status>
                                    </div>
                                </td>

                                <td>
                                    <div class="status-evaluation">
                                        <!-- non idoneo -->
                                        <ng-container *ngIf="user.interviewStatus == 'NOT_ELIGIBLE' ">
                                            <item-list-team-status styleItem="border noMinWidth" type="disabled"
                                                [showPoint]="false"
                                                [message]=" 'talent.interview.NOT_ELIGIBLE' | translate ">
                                            </item-list-team-status>
                                        </ng-container>
                                        <!-- Da intervistare-->
                                        <ng-container *ngIf="user.interviewStatus == 'TO_INTERVIEW'">
                                            <item-list-team-status styleItem="border noMinWidth" type="toStart"
                                                [showPoint]="false"
                                                [message]=" 'talent.interview.TO_INTERVIEW' | translate ">
                                            </item-list-team-status>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="user.interviewStatus == 'TO_PLAN' || user.interviewStatus == 'STAND_BY'">
                                            <item-list-team-status styleItem="border noMinWidth" type="inProgress"
                                                [showPoint]="false"
                                                [message]=" ('talent.interview.' + user.interviewStatus) | translate ">
                                            </item-list-team-status>
                                        </ng-container>
                                        <ng-container *ngIf="user.interviewStatus == 'INTERVIEWED'">
                                            <item-list-team-status styleItem="border noMinWidth" type="completed"
                                                [showPoint]="false"
                                                [message]=" 'talent.interview.INTERVIEWED' | translate ">
                                            </item-list-team-status>
                                        </ng-container>
                                    </div>
                                </td>
                                <td class="action">
                                    <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                                        (clickOutside)="closeMenu(user)" src="/assets/img/icons/more-horizontal.svg">
                                    </svg-icon>
                                    <ng-container *ngIf="user.isMenuOpen">
                                        <div class="dropdown-menu">
                                            <ng-container *ngFor="let option of menuOptions">
                                                <europ-dropdown-item [disabled]="option?.disabled || false"
                                                    (click)="onMenuOptionsClicked(option, user)" class="full-width"
                                                    [item]="option">
                                                </europ-dropdown-item>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="bottom-actions">
                    <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </europ-select>
                    </div>
                </div>


            </ng-container>

            <!-- Nessun dato -->
            <ng-container *ngIf="!isLoadingUserData && (!userData || !userData.counter)">
                <div class="admin-no-data-wrapper">
                    <p translate="process.details.NO_DATA"></p>
                </div>
            </ng-container>

            <!-- Loader caricamento -->
            <ng-container *ngIf="isLoadingUserData">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>


        </div>

    </div>
</div>



<!-- Modale richiesta livello inglese -->
<modal id="change-status" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeUpdateStatusModal()"
    (onClose)="closeUpdateStatusModal()" (onConfirm)="closeUpdateStatusModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate">
    <p class="modal-title" translate="talent.interview.CHANGE_STATUS_MODAL_TITLE"></p>
    <ng-container *ngIf="updateUser">
        <p class="modal-subtitle">
            {{ ('talent.interview.CHANGE_STATUS_MODAL_SUBTITLE' | translate) + updateUser.surname + ' ' +
            updateUser.forename}}
        </p>
        <div class="status-modal-container">
            <p translate="talent.interview.CHANGE_STATUS_MODAL_DESCR"></p>
            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectStatus($event)"
                [selectedItem]="selectedStatus" [items]="statusList" [searchable]="false" bindLabel="id">
            </europ-select>
            <!--input-container [isValued]="selectedStatus && selectedStatus.id"
                [title]="'grid.CURRENT_POTENTIAL' | translate" [isWithoutInfoIcon]="true">
            </input-container-->
        </div>
    </ng-container>
</modal>


<!-- Modale con elenco note per utente -->
<modal id="note-list" (onClose)="closeNoteListModal()" (onConfirm)="closeNoteListModal()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <ng-container *ngIf="noteListModalData && noteListModalData.user">
        <p class="modal-title" translate="talent.modals.NOTE_LIST_TITLE"></p>
        <p class="modal-subtitle"> {{ ('talent.modals.NOTE_LIST_DESCR' | translate) +
            noteListModalData.user.surname +
            ' ' + noteListModalData.user.forename}}</p>
        <div class="note-modal-container">
            <ng-container *ngIf="!isLoadingNoteList">

                <ng-container *ngFor="let note of noteListModalData.list">
                    <div class="note-container">
                        <svg-icon src="assets/img/icons/message-square.svg" [applyClass]="true" class="note-modal-icon">
                        </svg-icon>
                        <div class="note-content">
                            <div class="date-delete-container">
                                <p class="date">{{('talent.modals.NOTE_INSERT' | translate) + note.insertDate}}</p>
                                <!--svg-icon src="assets/img/icons/trash-2.svg" [applyClass]="true"
                                    class="note-modal-icon pointer" (click)="openDeleteNoteModal(note)">
                                </svg-icon-->
                            </div>
                            <p class="title">{{ 'talent.modals.NOTE' | translate }}</p>
                            <p class="content">{{ note.note }}</p>
                            <p class="created-by">{{('talent.modals.CREATED_BY' | translate) + note.authorSurname + ' '
                                +
                                note.authorForename}}</p>
                            <ng-container *ngIf="note.files && note.files.length">
                                <div class="file-list-container">
                                    <ng-container *ngFor="let file of note.files">
                                        <list-doc-item [canDoActions]="false" id="file.uploadId"
                                            srcIcon="assets/img/icons/file/{{getExtensionString(file.fileName)}}.svg"
                                            [fileName]="file.fileName" (onDownload)="downloadLinkedFile(file)">
                                        </list-doc-item>
                                    </ng-container>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isLoadingNoteList">
                <div class="loader spinner-medium"></div>
            </ng-container>
        </div>
    </ng-container>
</modal>