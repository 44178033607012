<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="'grid.SIDENAV_TITLE' | translate" [description]="'grid.SIDENAV_DESCR' | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <div class="content-wrapper" *ngIf="!isLoadingUserQuestions">

                <div class="user-info-container" *ngIf="selectedUser">
                    <avatar-img size="medium" [user]="selectedUser"></avatar-img>
                    <div class="section">
                        <p class="title">{{ selectedUser.surname + ' ' + selectedUser.forename}}</p>
                        <p class="text">{{ selectedUser.denominazioneSO3}}</p>
                    </div>
                    <div class="section">
                        <p class="title">{{ 'speed.AGE' | translate }}</p>
                        <p class="text">{{ selectedUser.age}}</p>
                    </div>
                    <div class="section">
                        <p class="title">{{ 'speed.ENGLISH' | translate }}</p>
                        <p class="text">{{ selectedUser.englishLevel ? (
                            ('talent.LEVEL' | translate) + ' ' + selectedUser.englishLevel) :
                            ('talent.NOT_DEFINED' |
                            translate) }}</p>
                    </div>
                    <!-- Idoneo -->
                    <ng-container *ngIf="selectedUser.potentialLevel == 'ELIGIBLE'">
                        <item-list-team-status styleItem="border noMinWidth" type="completed" [showPoint]="false"
                            [message]=" 'talent.potentialLevel.ELIGIBLE' | translate ">
                        </item-list-team-status>
                    </ng-container>
                    <!-- Da decidere -->
                    <ng-container *ngIf="selectedUser.potentialLevel == 'TO_OBSERVE'">
                        <item-list-team-status styleItem="border noMinWidth" type="" toStart [showPoint]="false"
                            [message]=" 'talent.potentialLevel.TO_OBSERVE' | translate ">
                        </item-list-team-status>
                    </ng-container>
                    <!-- Non idoneo -->
                    <ng-container *ngIf="selectedUser.potentialLevel == 'NOT_ELIGIBLE'">
                        <item-list-team-status styleItem="border noMinWidth" type="inProgress" [showPoint]="false"
                            [message]=" 'talent.potentialLevel.NOT_ELIGIBLE' | translate ">
                        </item-list-team-status>
                    </ng-container>
                </div>


                <div class="tab-container">
                    <ng-container *ngFor="let tab of tabList">
                        <div class="tab-content" [ngClass]="{'active': currentTab.id == tab.id}"
                            (click)="selectTab(tab)">
                            <p>{{ tab.title }}</p>
                            <div class="bottom-indicator"></div>
                        </div>
                    </ng-container>
                </div>

                <!-- Pagina esperienze -->
                <ng-container *ngIf="currentTab && currentTab.id == 'experience'">
                    <ng-container *ngFor="let question of pages['experience'].questions; let index = index;">
                        <!-- Prima domanda experience -->
                        <ng-container *ngIf="index == 0">
                            <input-container [isValued]="question.selectedAnswer"
                                [title]="(index + 1) + '. ' + question.text" [tooltipModalMessage]="experienceTooltip">

                                <ng-container *ngFor="let answer of question.answersList">
                                    <europ-check id="answer.id" [checked]="answer.isChecked" [label]="answer.value"
                                        (click)="selectMultipleAnswer(question, answer)">
                                    </europ-check>
                                </ng-container>

                            </input-container>
                        </ng-container>

                        <!-- Domande scelta singola -->
                        <ng-container *ngIf="question.answerType == 'S' && index != 0">
                            <input-container [isValued]="question.selectedAnswer" [isObbligatory]="true"
                                [title]="(index + 1) + '. ' + question.text" [isWithoutInfoIcon]="true">
                                <europ-radio [items]="question.answersList" [bindValue]="question.selectedAnswer"
                                    (onItemChange)="selectAnswer($event, question)" radioName="choice">
                                </europ-radio>
                            </input-container>
                        </ng-container>

                        <!-- Domande scelta multipla -->
                        <ng-container *ngIf="question.answerType == 'M' && index != 0">
                            <input-container [isValued]="question.selectedAnswer"
                                [title]="(index + 1) + '. ' + question.text" [isWithoutInfoIcon]="true">
                                <ng-container *ngFor="let answer of question.answersList">
                                    <europ-check [id]="answer.id" [checked]="answer.isChecked" [label]="answer.value"
                                        (click)="selectMultipleAnswer(question, answer)" [whiteBackground]="true">
                                    </europ-check>
                                </ng-container>
                            </input-container>
                        </ng-container>

                    </ng-container>

                    <div class="action-container experience">
                        <europ-button type="primary link" [label]="'grid.tab.CAPABILITIES' | translate"
                            (onButtonClicked)="nextPrevTab(tabList[1])" iconRight="assets/img/icons/arrow-right.svg">
                        </europ-button>
                    </div>
                </ng-container>



                <!-- Pagina Capabilities -->
                <ng-container *ngIf="currentTab && currentTab.id == 'capabilities'">

                    <div class="page-description">
                        <div class="title-container">
                            <p class="title" [translate]="'grid.CAPABILITIES_INFO_TITLE' | translate"></p>
                            <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                                (click)="openCapabilitiesTooltip()">
                            </svg-icon>
                        </div>
                        <p class="descr" [innerHTML]="'grid.CAPABILITIES_INFO_DESCR' | translate"></p>
                    </div>


                    <ng-container *ngFor="let question of pages['capabilities'].questions; let index = index;">

                        <!-- Domande scelta singola -->
                        <ng-container *ngIf="question.answerType == 'S'">
                            <input-container [isValued]="question.selectedAnswer" [title]="question.text"
                                [isWithoutInfoIcon]="true" [isObbligatory]="true">
                                <europ-radio [items]="question.answersList" [bindValue]="question.selectedAnswer"
                                    (onItemChange)="selectAnswer($event, question)" radioName="choice">
                                </europ-radio>
                            </input-container>
                        </ng-container>

                        <!-- Domande scelta multipla -->
                        <ng-container *ngIf="question.answerType == 'M'">
                            <input-container [isValued]="question.selectedAnswer" [title]="question.text"
                                [isWithoutInfoIcon]="true">
                                <ng-container *ngFor="let answer of question.answersList">
                                    <europ-check [id]="answer.id" [checked]="answer.isChecked" [label]="answer.value"
                                        [whiteBackground]="true" (click)="selectMultipleAnswer(question, answer)">
                                    </europ-check>
                                </ng-container>
                            </input-container>
                        </ng-container>

                        <!-- Text area  -->
                        <ng-container *ngIf="question.answerType == 'E'">
                            <input-container [isValued]="question.selectedAnswerText.length" [title]="question.text"
                                [isWithoutInfoIcon]="true">
                                <europ-text-area [bindValue]="question.selectedAnswerText" [maxlength]="600"
                                    (onModelChanged)="onTextChanged($event, question)"
                                    placeholder="{{'grid.DESCRIBE_EX' | translate}}">
                                </europ-text-area>
                            </input-container>
                        </ng-container>
                    </ng-container>

                    <div class="action-container capabilities">
                        <europ-button type="primary link" [label]="'grid.tab.EXPERIECES_BUTTON' | translate"
                            (onButtonClicked)="nextPrevTab(tabList[0])" iconLeft="assets/img/icons/arrow-left.svg">
                        </europ-button>

                        <europ-button type="primary link" [label]="'grid.tab.PERSONALITY' | translate"
                            (onButtonClicked)="nextPrevTab(tabList[2])" iconRight="assets/img/icons/arrow-right.svg">
                        </europ-button>
                    </div>

                </ng-container>





                <!-- Pagina personalità -->
                <ng-container *ngIf="currentTab && currentTab.id == 'personality'">

                    <div class="page-description">
                        <div class="title-container">
                            <p class="title" [translate]="'grid.PERSONALITY_INFO_TITLE' | translate"></p>
                            <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                                (click)="openPersonalityTooltip()">
                            </svg-icon>
                        </div>
                        <p class="descr" [innerHTML]="'grid.PERSONALITY_INFO_DESCR' | translate"></p>
                    </div>

                    <ng-container *ngFor="let question of pages['personality'].questions; let index = index;">

                        <!-- Domande scelta singola -->
                        <ng-container *ngIf="question.answerType == 'S'">
                            <input-container [isValued]="question.selectedAnswer" [title]="question.text"
                                [isWithoutInfoIcon]="true" [isObbligatory]="true">
                                <europ-radio [items]="question.answersList" [bindValue]="question.selectedAnswer"
                                    (onItemChange)="selectAnswer($event, question)" radioName="choice">
                                </europ-radio>
                            </input-container>
                        </ng-container>

                        <!-- Domande scelta multipla -->
                        <ng-container *ngIf="question.answerType == 'M'">
                            <input-container [isValued]="question.selectedAnswer" [title]="question.text"
                                [isWithoutInfoIcon]="true">
                                <ng-container *ngFor="let answer of question.answersList">
                                    <europ-check [id]="answer.id" [checked]="answer.isChecked" [label]="answer.value"
                                        [whiteBackground]="true" (click)="selectMultipleAnswer(question, answer)">
                                    </europ-check>
                                </ng-container>
                            </input-container>
                        </ng-container>

                        <!-- Text area  -->
                        <ng-container *ngIf="question.answerType == 'E'">
                            <input-container [isValued]="question.selectedAnswerText.length" [title]="question.text"
                                [isWithoutInfoIcon]="true">
                                <europ-text-area [bindValue]="question.selectedAnswerText" [maxlength]="600"
                                    (onModelChanged)="onTextChanged($event, question)"
                                    placeholder="{{'grid.DESCRIBE_EX' | translate}}">
                                </europ-text-area>
                            </input-container>
                        </ng-container>
                    </ng-container>

                    <div class="action-container personality">
                        <europ-button type="primary link" [label]="'grid.tab.CAPABILITIES' | translate"
                            (onButtonClicked)="nextPrevTab(tabList[1])" iconLeft="assets/img/icons/arrow-left.svg">
                        </europ-button>

                        <europ-button type="primary link" [label]="'grid.tab.EVALUATION' | translate"
                            (onButtonClicked)="nextPrevTab(tabList[3])" iconRight="assets/img/icons/arrow-right.svg">
                        </europ-button>
                    </div>
                </ng-container>







                <!-- Pagina valutazione -->
                <ng-container *ngIf="currentTab && currentTab.id == 'evaluation'">

                    <div class="page-description">
                        <div class="title-container">
                            <p class="title" [translate]="'grid.EVALUATION_INFO_TITLE' | translate"></p>
                            <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                                (click)="openEvaluationTooltip()">
                            </svg-icon>
                        </div>
                        <p class="descr" [innerHTML]="'grid.EVALUATION_INFO_DESCR' | translate"></p>
                    </div>



                    <!-- area di forza -->
                    <input-container [isObbligatory]="true"
                        [isValued]="pages['evaluation'].areasOfStrength && pages['evaluation'].areasOfStrength.length"
                        [title]="'grid.AREA_OF_STRENGTH' | translate" [isWithoutInfoIcon]="true">
                        <europ-text-area [bindValue]="pages['evaluation'].areasOfStrength" [maxlength]="600"
                            (onModelChanged)="areasOfStrengthChanged($event)"
                            placeholder="{{'grid.DESCRIBE_EX' | translate}}">
                        </europ-text-area>
                    </input-container>

                    <!-- Punti di miglioramento -->
                    <input-container [isObbligatory]="true"
                        [isValued]="pages['evaluation'].pointsOfImprovement && pages['evaluation'].pointsOfImprovement.length"
                        [title]="'grid.IMPROVEMENT' | translate" [isWithoutInfoIcon]="true">
                        <europ-text-area [bindValue]="pages['evaluation'].pointsOfImprovement" [maxlength]="600"
                            (onModelChanged)="pointsOfImprovementChanged($event)"
                            placeholder="{{'grid.DESCRIBE_EX' | translate}}">
                        </europ-text-area>
                    </input-container>

                    <!-- Livello di potenziale attuale -->
                    <input-container [isObbligatory]="true"
                        [isValued]="selectedPotentialLevel && selectedPotentialLevel.id"
                        [title]="'grid.CURRENT_POTENTIAL' | translate" [tooltipModalMessage]="potentialTooltip">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectPotentialLevel($event)"
                            [selectedItem]="selectedPotentialLevel" [items]="potentialLevelList" [searchable]="false"
                            bindLabel="id" [placeholder]="'grid.CURRENT_POTENTIAL_PLACEHOLDER' | translate">
                        </europ-select>
                    </input-container>

                    <div class="action-container evaluation">
                        <europ-button type="primary link" [label]="'grid.tab.PERSONALITY' | translate"
                            (onButtonClicked)="nextPrevTab(tabList[2])" iconLeft="assets/img/icons/arrow-left.svg">
                        </europ-button>
                    </div>
                </ng-container>


                <!-- salva risposte / chiudi -->
                <div class="action-container"
                    [ngClass]="{'center': currentTab && currentTab.id != 'evaluation', 'space': currentTab && currentTab.id == 'evaluation'}">
                    <europ-button type="primary bordless" [label]="'grid.SAVE_ANSWERS' | translate"
                        (onButtonClicked)="saveCurrentAnswer()">
                    </europ-button>
                    <ng-container *ngIf="currentTab && currentTab.id == 'evaluation'">
                        <europ-button type="primary" [label]="'grid.CLOSE_OBSERVATION' | translate"
                            (onButtonClicked)="openConcludeObservation()">
                        </europ-button>
                    </ng-container>
                </div>
            </div>


            <!-- Loader-->
            <ng-container *ngIf="isLoadingUserQuestions">
                <div class="loader spinner-big"></div>
            </ng-container>

        </div>
    </div>
</page-container>



<modal id="capabilities-tooltip" (onClose)="closeCapabilitiesTooltip()" (onConfirm)="closeCapabilitiesTooltip()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content modalId="exp001" [title]="'grid.modals.CAPABILITIES_TITLE' | translate"
        [text]="'grid.modals.CAPABILITIES_DESCR' | translate">
    </modal-text-content>
</modal>

<modal id="personality-tooltip" (onClose)="closePersonalityTooltip()" (onConfirm)="closePersonalityTooltip()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content modalId="exp001" [title]="'grid.modals.PERSONALITY_TITLE' | translate"
        [text]="'grid.modals.PERSONALITY_DESCR' | translate">
    </modal-text-content>
</modal>

<modal id="evaluation-tooltip" (onClose)="closeEvaluationTooltip()" (onConfirm)="closeEvaluationTooltip()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content modalId="exp001" [title]="'grid.modals.EVALUATION_TITLE' | translate"
        [text]="'grid.modals.EVALUATION_DESCR' | translate">
    </modal-text-content>
</modal>