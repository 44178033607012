<div class="card-container">
  <div class="header-type-container">
    <div class="type-container">
      <!-- Header Risposta -->
      <ng-container *ngIf="object.approverObservation && object.approverObservation.length > 0">
        <div class="type-container-text-icon">
          <svg-icon [src]="'assets/img/icons/message-circle.svg'" class="small-size gray-gray2" [applyClass]="true">
          </svg-icon>
          <p>{{'peerFeedback.card.FEEDBACK' | translate}}</p>
        </div>
        <div class="period-time">
          <p>{{object.formattedCreationDate}}</p>
          <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true">
          </svg-icon>
        </div>
      </ng-container>
      <!-- Header Richiesta -->
      <ng-container *ngIf="!object.approverObservation">
        <div class="type-container-text-icon">
          <svg-icon [src]="'assets/img/icons/hard-hat.svg'" class="small-size gray-gray2" [applyClass]="true">
          </svg-icon>
          <p>{{'peerFeedback.card.REQUEST_TITLE' | translate}}</p>
        </div>
        <div class="period-time">
          <p>{{object.formattedCreationDate}}</p>
          <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true">
          </svg-icon>
        </div>
        <!--
        <ng-container
          *ngIf="isManager && object.approverFeedbackUser && object.approverFeedbackUser.userId == loggedUser.userId">
          <item-list-team-status class="margin-left16" [styleItem]="'border'" [type]="object.status || 'toStart'"
            [message]="'peerFeedback.card.TO_SEND' | translate">
          </item-list-team-status>
        </ng-container>
        -->
      </ng-container>
    </div>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <!-- Risposta -->
    <ng-container *ngIf="object.approverObservation && object.approverObservation.length > 0">
      <avatar-img [user]="object.recipientUser" size="medium"></avatar-img>
    </ng-container>
    <!-- Richiesta -->
    <ng-container *ngIf="!object.approverObservation">

      <ng-container *ngIf="(isReceived && !isManager) || (isManager && !isReceived)">
        <avatar-img [user]="object.recipientUser" size="medium"></avatar-img>
      </ng-container>
      <ng-container *ngIf="(!isReceived && !isManager) || (isManager && isReceived)">
        <avatar-img [user]="object.approverFeedbackUser" size="medium"></avatar-img>
      </ng-container>
    </ng-container>
    <div class="information-text-container">
      <!-- Risposta -->
      <ng-container *ngIf="object.approverObservation && object.approverObservation.length > 0">
        <div class="specific-information">
          <ng-container
            *ngIf="(object.recipientUser.userId == loggedUser.userId) || (isManager && isReceived); else responseSent">
            <p
              [innerHTML]="('peerFeedback.card.FEEDBACK_FROM' | translate) + '<b>' + object.approverFeedbackUser.forename + ' ' + object.approverFeedbackUser.surname + '</b>'">
            </p>
          </ng-container>
          <ng-template #responseSent>
            <p
              [innerHTML]="('peerFeedback.card.SENT_TO' | translate) + '<b>' + object.recipientUser.forename + ' ' + object.recipientUser.surname + '</b>'">
            </p>
          </ng-template>

        </div>
        <p class="information-text">
          <span class="bold-text italic-text" translate="peerFeedback.card.FEEDBACK_LABEL"></span>
          <span [innerHTML]="object.approverObservation"></span>
        </p>
      </ng-container>
      <!-- Richiesta -->
      <ng-container *ngIf="!object.approverObservation">
        <ng-container *ngIf="(isReceived && !isManager) || (isManager && !isReceived)">
          <div class="specific-information">
            <p
              [innerHTML]="('peerFeedback.card.REQUEST_FROM' | translate) + '<b>' + object.recipientUser.forename + ' ' + object.recipientUser.surname + '</b>'">
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="(!isReceived && !isManager) || (isManager && isReceived)">
          <div class="specific-information">
            <p
              [innerHTML]="('peerFeedback.card.REQUEST_TO' | translate) + '<b>' + object.approverFeedbackUser.forename + ' ' + object.approverFeedbackUser.surname + '</b>'">
            </p>
          </div>
        </ng-container>
        <!-- A chi -->
        <!--p class="information-text" *ngIf="object.approverFeedbackUser">
          <span class="bold-text italic-text" translate="peerFeedback.card.TO_WHOM"></span> 
          <span>{{ object.approverFeedbackUser.forename + ' ' + object.approverFeedbackUser.surname }}</span>
        </p-->
        <p class="information-text" *ngIf="object.goal">
          <span class="bold-text italic-text" translate="peerFeedback.card.REFERS_TO"></span>
          <span [innerHTML]="object.goal && object.goal.title"></span>
        </p>
        <p class="information-text">
          <span class="bold-text italic-text" translate="peerFeedback.card.WHY"></span>
          <span [innerHTML]="object.request"></span>
        </p>
      </ng-container>
      <!--ng-container
        *ngIf="object.approverFeedbackUser.userId == loggedUser.userId || object.recipientUser.userId == loggedUser.userId"-->
      <!-- Richiesta -->
      <ng-container *ngIf="!object.approverObservation">
        <div class="action-footer"
          *ngIf="(!isManager && object.recipientUser.userId == loggedUser.userId) || (object.approverFeedbackUser.userId == loggedUser.userId)">
          <div class="feedback-actions">
            <!-- Richiesta inviata -->
            <ng-container *ngIf="!isManager && object.recipientUser.userId == loggedUser.userId">
              <p class="action" translate="generic.EDIT" (click)="emitOnEdit(object.feedbackId)"></p>
              <!--p class="action" translate="generic.DELETE" (click)="emitOnDelete(object.feedbackId)"></p-->
            </ng-container>
            <!-- Richiesta ricevuta -->
            <ng-container *ngIf="object.approverFeedbackUser.userId == loggedUser.userId && !isHistory">
              <europ-button (onButtonClicked)="emitOnFeedbackSend(object.feedbackId)" type="primary link" size="medium"
                [label]="'peerFeedback.card.SEND_FEEDBACK' | translate" iconLeft="assets/img/icons/message-circle.svg">
              </europ-button>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- Risposta -->
      <ng-container *ngIf="object.approverObservation && object.approverObservation.length > 0">
        <div class="action-footer">
          <!--ng-container *ngIf="object.approverFeedbackUser && object.approverFeedbackUser.userId == loggedUser.userId">
            <div class="feedback-actions response">
              <p class="action" translate="generic.EDIT" (click)="emitOnEdit(object.feedbackId)"></p>
              <!-- commentato altrimenti il manager poteva eliminare con la risposta anche la richiesta di feedback ->
              <!--p class="action" translate="generic.DELETE" (click)="emitOnDelete(object.feedbackId)"></p->
            </div>
            <div class="divisor-line"></div>
          </ng-container-->
          <div class="type-container">
            <div class="type-container-text-icon">
              <svg-icon [src]="'assets/img/icons/hard-hat.svg'" class="small-size gray-gray2" [applyClass]="true">
              </svg-icon>
              <p>{{'peerFeedback.card.REQUEST_TITLE' | translate}}</p>
            </div>
            <div class="feedback-actions">
              <p class="action" translate="peerFeedback.card.SHOW_REQUEST" (click)="emitOnShowRequest()">
              </p>
            </div>
          </div>
        </div>
      </ng-container>
      <!--/ng-container-->
    </div>
  </div>
</div>