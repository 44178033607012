<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingPersonDetails && personDetails">
    <header-dossier [user]="personDetails" [hasTabs]="false" (onNewInterview)="goToInterview()"></header-dossier>
    <div class="main-content-wrapper goal-details">
      <div class="back-button-container">
        <europ-button type="primary link" size="medium" iconLeft="assets/img/icons/arrow-left.svg"
          [label]="'generic.GO_BACK' | translate" (onButtonClicked)="goToPersonDetails()"></europ-button>
      </div>
      <ng-container *ngIf="!isLoadingGoalData">

        <!-- Banner scadenza valutazione finale -->
        <!--ng-container *ngIf="!isLoadingEvaluationDates && hasFinalEvaluation() && finalEvaluationEnabled()">
          <div class="final-notice-container">
            <p class="title">{{ ('final.ACTIVE_UNTIL' | translate) + (evalPhaseEnd |
              date:
              'dd/MM/YYYY')  }}</p>
            <p class="descr">{{ 'final.ACTIVE_UNTIL_DESCR' | translate  }}</p>
          </div>
        </ng-container-->

        <!-- Dettaglio card goal -->
        <goal-card-detail [isHistory]="!isInCurrentYear()" [hasDrafts]="hasDraft" [isAfterSharing]="isAfterSharing"
          [loggedUser]="managerData" [isOperations]="personDetails.isOperation" [isInnerHtml]="true" [goal]="goalData"
          (onEdit)="goToEditGoal()" (onDelete)="deleteGoal()" (onReadComment)="showComment()"
          (onEditComment)="goToEditGoal()" (onApprove)="goToEditGoal()" [isCollaborator]="false" [isManager]="true"
          [commentData]="commentData" [managerData]="managerData" (onUploadFile)="onFileLinkUpload()"
          (onDeleteLink)="deleteLink($event)" (onEditLink)="editLink($event)" (onLinkOpen)="openLink($event)"
          (onFileDownload)="downloadFile($event)" (onDeleteFile)="deleteFile($event)" (onEditFile)="editFile($event)"
          [runningPhase]="runningPhase">
        </goal-card-detail>

        <!-- Accordion attributi CARE con upload -->
        <europ-accordion [isAccordionOpened]="true" *ngIf="goalData.type === 'DEVELOPMENT_OPERATIONS'"
          [title]="'accordion.CARE_ATTR_EVALUATION' | translate" [tooltipModalMessage]="careAttributesTooltipModal">
          <div class="accordion-content">
            <div class="file-upload-container">
              <div class="info-content">
                <p translate="goalCard.FILE_UPLOAD_INFO"></p>
              </div>
              <!-- file-->
              <ng-container
                *ngIf="goalData.files && goalData.files.length && goalData.type == 'DEVELOPMENT_OPERATIONS'">
                <div class="files-container">
                  <div class="files">
                    <div class="file-container" *ngFor="let file of goalData.files">
                      <list-doc-item [canDoActions]="canDoActionOnLinkFiles(file)" id="file.uploadId"
                        srcIcon="assets/img/icons/file/{{getExtensionString(file.originalFileName)}}.svg"
                        [fileName]="file.fileName" (onEdit)="editFile(file.uploadId)" [toEndParagraph]="true"
                        (onDelete)="deleteFile(file.uploadId)" (onDownload)="downloadFile(file)"
                        [chargedTime]="(!!file.creationDate ? (file.creationDate | date: 'dd.MM.YYYY') : '-')">
                      </list-doc-item>
                      <div class="divisor-line"></div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- link -->
              <ng-container
                *ngIf="goalData.links && goalData.links.length && goalData.type == 'DEVELOPMENT_OPERATIONS'">
                <div class="files-container">
                  <div class="files">
                    <div class="file-container" *ngFor="let link of goalData.links">
                      <list-doc-item (onDownload)="openLink(link.url)" [isLink]="true" [toEndParagraph]="true"
                        srcIcon="assets/img/icons/link.svg" [fileName]="link.linkName"
                        [canDoActions]="canDoActionOnLinkFiles(link)" (onEdit)="editLink(link.linkId)"
                        (onDelete)="deleteLink(link.linkId)"
                        [chargedTime]="(!!link.creationDate ? (link.creationDate | date: 'dd.MM.YYYY') : '-')">
                      </list-doc-item>
                      <div class="divisor-line"></div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="uploader-component">
                <div class="text-content">
                  <p [innerHTML]="'goalCard.UPLOAD_FILE_TITLE' | translate"></p>
                </div>
                <europ-button type="primary" [label]="'goalCard.UPLOAD_BUTTON' | translate"
                  (onButtonClicked)="onFileLinkUpload()">
                </europ-button>
              </div>
            </div>
          </div>
        </europ-accordion>
        <!-- Cosa osserverai -->
        <!--europ-accordion *ngIf="goalData.type == 'OPERATIONS'" [title]="'accordion.OBJECTIVE_REACHED' | translate"
          [tooltipModalMessage]="objectiveCompleteTooltipModal">
          <div class="accordion-content text">
            <p class="text-default">{{ goalData.objectiveDescription || ('generic.TO_BE_COMPLETED' |
              translate)}}</p>
          </div>
        </europ-accordion-->

        <!-- Piano di sviluppo -->
        <europ-accordion
          *ngIf="goalData.isChallenge && goalData.type != 'OPERATIONS' && goalData.type != 'OPERATIONS_KPI' && goalData.type != 'OPERATIONS_COMPETENCES'"
          [title]="'accordion.IDP' | translate" [tooltipModalMessage]="idpTooltipModal">
          <ng-container *ngIf="developmentActionsList && developmentActionsList.length">
            <div class="accordion-content goal-details-section">
              <div class="gray-background">
                <card-action (onEditAction)="goToEditDevelopmentAction($event)"
                  (onDeleteAction)="openDeleteModalDevelopAction($event)" *ngFor="let action of developmentActionsList"
                  [loggedUserId]="managerData.userId" (onDeleteComment)="openDeleteCommentModalDevelopAction($event)"
                  [isManager]="true" (onEditComment)="openEditCommentToDevelopAction($event)"
                  (onReadComment)="showActionComment($event)"
                  (onInsertCommentToDevelopAction)="openInsertCommentToDevelopAction($event)" [object]="action">
                </card-action>
              </div>
              <div class="action-button-container" *ngIf="isInCurrentYear()">
                <europ-button [disabled]="isFetchingCanAddActions || isLoadingGoalData"
                  (onButtonClicked)="goToCreateActionToGoal()" type="primary default" size="medium"
                  [label]="'developmentPlan.ADD_ACTION_BUTTON' | translate"></europ-button>
                <europ-button [disabled]="isFetchingCanShareActions" (onButtonClicked)="openDevelopActionsShareModal()"
                  *ngIf="developmentActionsList && developmentActionsList.length && canShareDevActions"
                  type="secondary default" size="medium" [label]="'developmentPlan.SHARE_ACTIONS' | translate">
                </europ-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(!developmentActionsList || !developmentActionsList.length)">
            <div class="no-data-found">
              <div class="no-data">
                <svg-icon src="assets/img/icons/coffee.svg" class="big-size blue-primary" [applyClass]="true">
                </svg-icon>
                <p class="no-data-text" translate="developmentPlan.NO_ACTIONS_FOR_THIS_GOAL"></p>
              </div>
              <div class="button-add-action-container" *ngIf="isInCurrentYear()">
                <europ-button [disabled]="isFetchingCanAddActions || isLoadingGoalData"
                  (onButtonClicked)="goToCreateActionToGoal()" type="primary default" size="medium"
                  [label]="'developmentPlan.ADD_ACTION_BUTTON' | translate"></europ-button>
              </div>
            </div>
          </ng-container>
        </europ-accordion>

        <!-- Evidenze -->
        <europ-accordion [title]="'accordion.EVIDENCES' | translate" [tooltipModalMessage]="evidencesTooltipModal">
          <ng-container *ngIf="evidencesList && evidencesList.length">
            <div class="accordion-content goal-details-section">
              <div class="gray-background">
                <ng-container *ngFor="let evidence of evidencesList">
                  <card-evidence [goalTitle]="goalData.title" [isInChallengeDetail]="true" [object]="evidence"
                    (onFileDownload)="downloadFileOnEvidences($event, evidence.feedbackId)"
                    [isHistory]="!isInCurrentYear()">
                  </card-evidence>
                </ng-container>
              </div>
              <div fxLayout.xs="column" class="action-button-container" *ngIf="isInCurrentYear()">
                <europ-button ngClass.xs="margin-bottom10" ngClass.gt-xs="margin-right10" [disabled]="isLoadingGoalData"
                  (onButtonClicked)="goToManageEvidences()" type="primary default" size="medium"
                  [label]="'evidences.MANAGE_EVIDENCES' | translate"></europ-button>
                <ng-container *ngIf="canAddEvidence()">
                  <europ-button [disabled]="isLoadingGoalData" (onButtonClicked)="goToCreateEvidenceToGoal()"
                    type="primary default" size="medium" [label]="'evidences.ADD_EVIDENCE' | translate"></europ-button>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="(!evidencesList || !evidencesList.length)">
            <div class="no-data-found">
              <div class="no-data">
                <svg-icon src="assets/img/icons/coffee.svg" class="big-size blue-primary" [applyClass]="true">
                </svg-icon>
                <p class="no-data-text" translate="evidences.NO_EVIDENCES_FOR_THIS_GOAL"></p>
              </div>
              <div class="button-add-action-container" *ngIf="isInCurrentYear()">
                <europ-button (onButtonClicked)="goToCreateEvidenceToGoal()" type="primary default" size="medium"
                  [label]="'evidences.ADD_EVIDENCE' | translate"></europ-button>
              </div>
            </div>
          </ng-container>
        </europ-accordion>
        <!-- Feedback -->
        <europ-accordion [title]="'accordion.FEEDBACK' | translate" [tooltipModalMessage]="feedbackTooltipModal">
          <div class="accordion-content goal-details-section">
            <div class="gray-background">
              <!-- Lista peer feedback -->
              <ng-container *ngIf="peerFeedbackList && peerFeedbackList.length">
                <card-peer *ngFor="let feedback of peerFeedbackList" [object]="feedback" [loggedUser]="personDetails"
                  [isManager]="true" (onDelete)="openDeletePeerFeedbackModal($event)"
                  (onEdit)="goToPeerFeedbackAnswer($event)" (onShowRequest)="openShowFeedbackRequest($event)"
                  (onFeedbackSend)="goToPeerFeedbackAnswer($event)">
                </card-peer>
              </ng-container>

              <!-- Placeholder se non ho appunti privati -->
              <ng-container *ngIf="!peerFeedbackList || !peerFeedbackList.length">
                <no-data [title]="('peerFeedback.TITLE' | translate)"
                  src="/assets/img/section/private-notes-placeholder.svg" [description]="peerFeedbackPlaceholder">
                </no-data>
              </ng-container>
            </div>
          </div>
        </europ-accordion>
        <!-- Step praticabili (milestones) -->
        <!--europ-accordion [isDisabled]="true" [title]="'accordion.MILESTONES' | translate"
          [tooltipModalMessage]="milestonesTooltipModal">
        </europ-accordion-->

        <!-- Valutazione finale / auto osservazione -->
        <ng-container *ngIf="hasFinalEvaluation()">
          <europ-accordion [tooltipModalMessage]="evaluationTooltipModal" [title]="'accordion.EVALUTAIONS' | translate"
            [statusData]="finalEvaluationStatusData" [isDisabled]="!finalEvaluationEnabled()">
            <div class="accordion-content final-evaluation">

              <ng-container *ngIf="goalData.goalEvaluation && goalData.goalEvaluation.length">
                <ng-container *ngFor="let evaluation of goalData.goalEvaluation">

                  <!-- valutazione manager -->
                  <ng-container *ngIf="evaluation.user && evaluation.user.userId != userId">
                    <div class="final-evaluation-row clickable white-background"
                      (click)="openFinalEvaluationModal(evaluation)">
                      <div class="left-side">
                        <avatar-img [user]="evaluation.user" size="medium"></avatar-img>
                        <p class="user-name">{{ evaluation.user.forename[0] + '. ' +
                          evaluation.user.surname }}</p>
                        <evaluation [evaluation]="evaluation.evaluationScore"></evaluation>
                      </div>
                      <p class="compiled"> {{ ('final.COMPILED_IN' | translate) +
                        (evaluation.creationDate | date:
                        'dd.MM.YYYY') }}</p>
                    </div>
                  </ng-container>

                  <!-- Valutazione utente -->
                  <ng-container *ngIf="evaluation.user && evaluation.user.userId == userId">
                    <div class="title-container">
                      <p class="self-title" translate="final.SELF_OBS"></p>
                      <div class="divisor-line"></div>
                    </div>

                    <div class="final-evaluation-row clickable" (click)="openFinalEvaluationModal(evaluation)">
                      <div class="left-side">
                        <avatar-img [user]="evaluation.user" size="medium"></avatar-img>
                        <p class="user-name">{{ evaluation.user.forename[0] + '. ' +
                          evaluation.user.surname }}</p>
                        <evaluation [evaluation]="evaluation.evaluationScore"></evaluation>
                      </div>
                      <p class="compiled"> {{ ('final.COMPILED_IN' | translate) +
                        (evaluation.creationDate | date:
                        'dd.MM.YYYY') }}</p>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <div class="action-button-container" *ngIf="goalData.goalStatus == 'PERFCARE_GOAL_TO_EVALUATE'">
                <europ-button (onButtonClicked)="goToFinalEvaluation()" type="primary default" size="medium"
                  [label]="'final.INSERT_EVAL' | translate"></europ-button>
              </div>

            </div>
          </europ-accordion>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoadingGoalData">
        <div class="loader spinner-medium"></div>
      </ng-container>

    </div>
  </ng-container>
  <!-- Loader caricamento dati utente -->
  <ng-container *ngIf="isLoadingPersonDetails">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="confirmDeleteForManager" (onCancel)="closeDeleteModal()" (onClose)="closeDeleteModal()"
  (onConfirm)="confirmDeleteGoal()" cancelLabel="{{ 'generic.CANCEL' | translate}}"
  confirmLabel="{{ 'generic.DELETE' | translate}}">
  <modal-text-content [title]="deleteModalContent.title" [subtitle]="''" [text]="deleteModalContent.text">
  </modal-text-content>
</modal>

<!-- Modale aggiunta/modifica file e link -->
<modal id="uploadFileOrLink"
  [isDisabled]="isLinkUpload ? (!linkTitle || !linkTitle.trim().length || !linkToUpload || !linkToUpload.trim().length) : (!fileObject || !fileObject.fileExtension || !fileTitle || !fileTitle.trim().length)"
  (onClose)="closeUploadFileOrLinkModal()" (onConfirm)="confirmUploadLinkOrFile()"
  [confirmLabel]="('generic.CONFIRM' | translate)">
  <div class="file-upload-modal-wrapper">
    <p class="title" translate="managerOperations.modals.UPLOAD_MODAL_TITLE"></p>
    <input-container [isWithoutInfoIcon]="true" [disabled]="isUpdate" [tooltipModalMessage]="linkOrFileModalTooltip"
      [isValued]="true" [isObbligatory]="true" title="{{'managerOperations.modals.WHAT_TO_LINK' | translate}}">
      <europ-switch [leftLabelObject]="('managerOperations.modals.UPLOAD_FILE' | translate)"
        [rightLabelObject]="('managerOperations.modals.INSERT_LINK' | translate)" [checked]="isLinkUpload"
        (onSwitch)="changeTypeOfUpload()"></europ-switch>
    </input-container>
    <!-- Upload file -->
    <ng-container *ngIf="!isLinkUpload">
      <input-container [isWithoutInfoIcon]="true" [tooltipModalMessage]="fileTitleModalTooltip"
        [isValued]="fileTitle && fileTitle.trim().length" [isObbligatory]="true"
        title="{{'managerOperations.modals.FILE_TITLE' | translate}}">
        <europ-input [bindValue]="fileTitle" (onModelChanged)="onFileTitleChanged($event)"
          placeholder="{{'managerOperations.modals.TITLE_PLACEHOLDER' | translate}}">
        </europ-input>
      </input-container>
      <input-container [instructions]="'goalSetting.UPLOAD_FILES_ACCEPTED' | translate"
        [isValued]="fileObject && fileObject.fileExtension" [isWithoutInfoIcon]="true"
        [tooltipModalMessage]="uploadAreaModalTooltip" [isObbligatory]="true"
        title="{{'managerOperations.modals.FILE_IN_AREA' | translate}}">
        <europ-upload [isToReset]="isToResetUploadComponent" (onUpload)="assignFile($event)"></europ-upload>
      </input-container>
    </ng-container>
    <!-- Carica link -->
    <ng-container *ngIf="isLinkUpload">
      <input-container [isWithoutInfoIcon]="true" [tooltipModalMessage]="linkTitleModalTooltip"
        [isValued]="linkTitle && linkTitle.trim().length" [isObbligatory]="true"
        title="{{'managerOperations.modals.LINK_TITLE' | translate}}">
        <europ-input [bindValue]="linkTitle" (onModelChanged)="onLinkTitleChanged($event)"
          placeholder="{{'managerOperations.modals.LINK_TITLE_PLACEHOLDER' | translate}}">
        </europ-input>
      </input-container>
      <input-container [isWithoutInfoIcon]="true" [tooltipModalMessage]="uploadAreaModalTooltip"
        [isValued]="linkToUpload && linkToUpload.trim().length" [isObbligatory]="true"
        title="{{'managerOperations.modals.INSERT_LINK' | translate}}">
        <europ-input [bindValue]="linkToUpload" (onModelChanged)="onLinkChanged($event)"
          placeholder="{{'managerOperations.modals.LINK_PLACEHOLDER' | translate}}">
        </europ-input>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Modale elimina link -->
<modal id="deleteLinkModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteLinkModal(false)"
  (onClose)="closeDeleteLinkModal(false)" (onConfirm)="closeDeleteLinkModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="opm016" [title]="'managerOperations.modals.DELETE_LINK_TITLE' | translate"
    [text]="'managerOperations.modals.DELETE_LINK_TEXT' | translate">
  </modal-text-content>
</modal>
<!-- Modale elimina file -->
<modal id="deleteFileModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteFileModal(false)"
  (onClose)="closeDeleteFileModal(false)" (onConfirm)="closeDeleteFileModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="opm017" [title]="'managerOperations.modals.DELETE_FILE_TITLE' | translate"
    [text]="'managerOperations.modals.DELETE_FILE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale per aggiungere un commento all'azione di sviluppo -->
<modal id="insertCommentDevelopmentPlanModal"
  [isDisabled]="developAction && (!newActionComment || !newActionComment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeInserCommentModalDevelopAction(true)"
  (onClose)="closeInserCommentModalDevelopAction(true)" (onConfirm)="addCommentToDevelopAction()"
  confirmLabel="{{ 'developmentPlan.INSERT_COMMENT_ACTION' | translate}}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.INSERT_COMMENT_TITLE' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="developAction">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="newActionComment && newActionComment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.INSERT_COMMENT_TITLE' | translate}}">
      <europ-text-area [bindValue]="newActionComment" [maxlength]="600"
        (onModelChanged)="developActionCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per modificare un commento all'azione di sviluppo -->
<modal id="editCommentDevelopmentPlanModal"
  [isDisabled]="commentToEdit && (!commentToEdit.comment || !commentToEdit.comment.trim().length)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeEditCommentModalDevelopAction(true)"
  (onClose)="closeEditCommentModalDevelopAction(true)" (onConfirm)="updateCommentForDevelopAction()"
  confirmLabel="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate }}">
  <modal-text-content modalId="d024" [isRiepilogueDevelopAction]="true"
    title="{{ 'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}"
    subtitle="{{ 'developmentPlan.RIEPILOGUE' | translate}}" [text]="developActionModalText">
  </modal-text-content>
  <ng-container *ngIf="commentToEdit">
    <input-container class="margin-bottom24" [isWithoutInfoIcon]="true"
      [isValued]="commentToEdit.comment && commentToEdit.comment.trim().length" [isObbligatory]="true"
      title="{{'developmentPlan.UPDATE_COMMENT_ACTION' | translate}}">
      <europ-text-area [bindValue]="commentToEdit.comment" [maxlength]="600"
        (onModelChanged)="developActionEditCommentChanged($event)"
        placeholder="{{'developmentPlan.INSERT_COMMENT_ACTION_PLACEHOLDER' | translate}}"></europ-text-area>
    </input-container>
  </ng-container>
</modal>

<!-- Modale per cancellare il commento dell'azione di sviluppo -->
<modal id="deleteDevelopActionCommentModal" [cancelLabel]="'No'" (onCancel)="closeDeleteCommentModalDevelopAction()"
  (onClose)="closeDeleteCommentModalDevelopAction()" (onConfirm)="deleteCommentDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d032" title="{{ 'developmentPlan.DELETE_COMMENT' | translate}}"
    text="{{ 'developmentPlan.DELETE_COMMENT_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per cancellare l'azione di sviluppo -->
<modal id="deleteDevelopmentAction" [cancelLabel]="'No'" (onCancel)="closeDeleteModalDevelopAction()"
  (onClose)="closeDeleteModalDevelopAction()" (onConfirm)="deleteDevelopAction()"
  confirmLabel="{{ 'generic.YES' | translate}}">
  <modal-text-content modalId="d150" title="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION' | translate}}"
    text="{{ 'developmentPlan.DELETE_DEVELOPMENT_ACTION_TEXT' | translate}}">
  </modal-text-content>
</modal>

<!-- Modale per condividere le azioni di sviluppo -->
<modal id="developActionsShareModal" (onCancel)="closeDevelopActionsShareModal()"
  (onClose)="closeDevelopActionsShareModal()" (onConfirm)="shareDevelopActions()"
  confirmLabel="{{ 'generic.SHARE' | translate}}" [cancelLabel]="'generic.ANNULL' | translate">
  <modal-text-content modalId="031" *ngIf="managerData" [noBackground]="true"
    image="assets/img/section/development-plan.svg" [title]="'developmentPlan.SHARE_DEVELOPMENT_PLAN' | translate"
    [subtitle]="'developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN' | translate"
    [text]="('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_1' | translate) + managerData.forename + ' ' + managerData.surname + ('developmentPlan.YOU_WANT_SHARE_DEVELOPMENT_PLAN_TEXT_2' | translate)">
  </modal-text-content>
</modal>


<!-- Modale eliminazione peer feedback -->
<modal id="deletePeerFeedback" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeDeletePeerFeedbackModal()" (onClose)="closeDeletePeerFeedbackModal(false)"
  (onConfirm)="closeDeletePeerFeedbackModal(true)" confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pf013" [title]="'peerFeedback.modals.DELETE_FEEDBACK_TITLE' | translate"
    [text]="'peerFeedback.modals.DELETE_FEEDBACK_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale mostra richiesta peer feedback -->
<modal id="modalShowRequest" (onClose)="closeShowFeedbackRequest()" (onConfirm)="closeShowFeedbackRequest()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <div class="peer-feedback-modal-request">
    <div class="title-code-wrapper full-width">
      <div class="title-container" translate="peerFeedback.card.REQUEST_TITLE"></div>
    </div>
    <div class="confirm-request-content" *ngIf="feedbackForRequestModal">
      <div class="section users">
        <div class="title" translate="peerFeedback.modals.REQUEST_FROM"></div>
        <div class="content">
          <div class="mini-user-card">
            <avatar-img size="small" [user]="feedbackForRequestModal.recipientUser"></avatar-img>
            <p class="name">{{ feedbackForRequestModal.recipientUser.forename + ' ' +
              feedbackForRequestModal.recipientUser.surname}}</p>
          </div>
        </div>
      </div>
      <div class="section" *ngIf="feedbackForRequestModal.goal">
        <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
        <div class="content">{{ feedbackForRequestModal.goal && feedbackForRequestModal.goal.title}}</div>
      </div>
      <div class="section">
        <div class="title" translate="peerFeedback.modals.WHY"></div>
        <div class="content">{{ feedbackForRequestModal.request }}</div>
      </div>
    </div>
  </div>
</modal>

<!-- Modale per valutazione finale -->
<modal id="finalEvaluationModal" (onClose)="closeFinalEvaluationModal()" (onConfirm)="closeFinalEvaluationModal()"
  confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <div class="final-evaluation-modal-container" *ngIf="finalEvaluationForModal">
    <p class="modal-title"
      translate="{{personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS' : 'final.TITLE'}}">
    </p>
    <ng-container *ngIf="finalEvaluationForModal.evaluationScore">
      <div class="divisor-line"></div>
      <evaluation [evaluation]="finalEvaluationForModal.evaluationScore" customClass="big"></evaluation>
      <div class="divisor-line"></div>
    </ng-container>
    <div class="observation-container">
      <p class="title">
        {{ ((personDetails.userId == finalEvaluationForModal.user.userId ? 'final.SELF_OBS_BY' : 'final.EVALUATION_BY')
        |
        translate) + finalEvaluationForModal.user.forename + ' ' +
        finalEvaluationForModal.user.surname
        }}
      </p>
      <p class="descr">{{ ('final.INSERT_DATE' | translate) + (finalEvaluationForModal.creationDate | date:
        'dd/MM/YYYY') }}</p>
      <p class="title" translate="final.OBSERVATION"></p>
      <p class="descr">{{finalEvaluationForModal.observation}}</p>
    </div>
  </div>
</modal>