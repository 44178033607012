<page-container>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
            [title]="processId ? (('process.create.SIDENAV_TITLE_UPDATE' | translate) + ' ' + processName) : ('process.create.SIDENAV_TITLE' | translate)"
            [description]="('process.create.SIDENAV_DESCR' | translate)">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingProcess">
                <div class="form-container">
                    <!-- Nome del processo -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="processName && processName.trim().length"
                        [isObbligatory]="true" title="{{'process.create.NAME' | translate}}">
                        <europ-input [bindValue]="processName" (onModelChanged)="nameChanged($event)"
                            placeholder="{{'process.create.NAME_PLACEHOLDER' | translate}}">
                        </europ-input>
                    </input-container>
                    <!-- Anno di riferimento -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="selectedYear && selectedYear.id"
                        [isObbligatory]="true" title="{{'process.create.YEAR' | translate}}" [readonly]="!!processId"
                        [disabled]="isLoadingProcessYears">
                        <europ-select [placeholder]="'process.create.YEAR_PLACEHOLDER' | translate"
                            (onSelectedItem)="changeYear($event)" [selectedItem]="selectedYear" [items]="yearOptions"
                            bindLabel="title"></europ-select>
                    </input-container>
                    <!-- Stato del processo -->
                    <input-container [isWithoutInfoIcon]="true" [readonly]="!processId" [isValued]="selectedStatus"
                        [isObbligatory]="true" title="{{'process.create.STATUS' | translate}}">
                        <europ-select (onSelectedItem)="changeStatus($event)" [selectedItem]="selectedStatus"
                            [items]="statusOptions" bindLabel="title"></europ-select>
                    </input-container>
                    <div class="button-container">
                        <europ-button (onButtonClicked)="goToUpdateProcess()"
                            [disabled]="isLoadingProcess || !isObbligatoryDataInserted()" type="primary default"
                            size="large"
                            [label]="(processId && processId.length) ? ('process.create.UPDATE' | translate) : ('process.create.CREATE' | translate)">
                        </europ-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingProcess">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un processo -->
<modal id="processUpdateModal" (onCancel)="goBackBrowser()" (onClose)="closeUpdateModal()"
    (onConfirm)="(processId && processId.length) ? updateProcess() : createProcess()"
    cancelLabel="{{modalUpdateTextCloseTextButton}}" confirmLabel="{{modalUpdateTitleConfirmTextButton}}">
    <modal-text-content [modalId]="modalUpdateId" [title]="modalUpdateTitle" [subtitle]="modalUpdateSubtitle"
        [text]="modalUpdateText">
    </modal-text-content>
</modal>

<!-- Modale che avvisa che non tutte le date delle fasi sono inserite -->
<modal id="set-phases-dates-modal" (onCancel)="closeSetPhasesDatesModal()" (onClose)="closeSetPhasesDatesModal()"
    (onConfirm)="closeSetPhasesDatesModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
    [confirmLabel]="'processSetup.modals.SPM_ACTION' | translate">
    <modal-text-content modalId="spd001" [title]="'processSetup.modals.SPM_TITLE' | translate"
        [text]="'processSetup.modals.SPM_TEXT' | translate">
    </modal-text-content>
</modal>