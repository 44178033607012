<div class="card-container" [ngClass]="{'plain': plain}">
  <div class="header-type-container">
    <div class="type-container">
      <div class="type-container-text-icon">
        <svg-icon [src]="'assets/img/icons/eye.svg'" class="small-size gray-gray2" [applyClass]="true">
        </svg-icon>
        <p>{{('evidences.EVIDENCE') | translate}}</p>
      </div>
      <div class="period-time">
        <p>{{object.formattedCreationDate}}</p>
        <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
      </div>
    </div>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <avatar-img [user]="object.senderUser" size="medium"></avatar-img>
    <div class="information-text-container">
      <div class="specific-information">
        <p
          [innerHTML]="('evidences.RACCOLTED_FROM' | translate) + '<b>' + object.senderUser.forename + ' ' + object.senderUser.surname + '</b>'">
        </p>
      </div>
      <p class="information-text info-plain">
        <span class="bold-text italic-text" translate="evidences.REFERRED_TO"></span>
        <span [innerHTML]="(object.goal && object.goal.title) || goalTitle"></span>
      </p>
      <p class="information-text">
        <span class="bold-text italic-text" translate="evidences.CONTEST_IN_MODAL"></span>
        <span [innerHTML]="object.comment"></span>
      </p>
      <p class="information-text">
        <span class="bold-text italic-text" translate="evidences.OSSERVATION_IN_MODAL"></span>
        <span [innerHTML]="object.observations"></span>
      </p>

      <!-- file-->
      <ng-container *ngIf="object.upload && object.upload.length">
        <div class="goal-files-container full-width margin-top5">
          <div class="files">
            <div class="file-container" *ngFor="let file of object.upload; let i = index">
              <div *ngIf="i == 0" class="divisor-line margin-bottom10"></div>
              <list-doc-item [toEndParagraph]="true" [canDoActions]="canDoActions(file)" id="file.uploadId"
                srcIcon="assets/img/icons/file/{{getExtensionString(file.fileName)}}.svg" [fileName]="file.fileName"
                (onDelete)="emitOnDeleteFile(file.uploadId)" (onDownload)="emitOnFileDownload(file)"
                [chargedTime]="formatDate(file.creationDate)">
              </list-doc-item>
              <div *ngIf="!isInChallengeDetail && object.upload.length > 1 && i != (object.upload.length -1)"
                class="divisor-line margin-bottom10 margin-top10"></div>
              <div *ngIf="isInChallengeDetail" class="divisor-line margin-bottom10 margin-top10"></div>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!isInChallengeDetail && !isHistory" class="action-footer">
        <div class="feedback-actions">
          <europ-button (onButtonClicked)="emitOnUploadFile()" label="{{'evidences.CHARGE_A_FILE' | translate}}"
            type="primary link" size="medium" iconLeft="assets/img/icons/upload-file-cloud-primary.svg"></europ-button>

          <europ-button *ngIf="object.senderUserId == loggedUser.userId"
            (onButtonClicked)="emitOnEditAction(object.feedbackId)" label="{{'generic.EDIT' | translate}}"
            type="primary link" size="medium" iconLeft="assets/img/icons/edit.svg"></europ-button>
        </div>
      </div>
    </div>
  </div>
</div>