<div class="page-content-wrapper">

    <header-dossier [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
        [isTalentHeader]="true" [hasTabs]="false">
    </header-dossier>

    <div class="main-content-wrapper">
        <div class="stepper-search-container">
            <!-- Stepper -->
            <ng-container *ngIf="!isLoadingStepper">
                <div class="stepper-container">
                    <stepper [statuses]="stepList" [currentStatus]="currentStep" (stepClicked)="stepClicked($event)">
                    </stepper>
                </div>
            </ng-container>
            <!-- Loader step -->
            <ng-container *ngIf="isLoadingStepper">
                <div class="full-width min-height500" fxLayout="row wrap" fxLayoutGap="16px grid"
                    fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center"
                    fxLayoutGap.lt-md="16px">
                    <div class="loader spinner-medium"></div>
                </div>
            </ng-container>

            <div class="search-container">
                <ng-container *ngIf="currentStep && currentStep.id == 2">
                    <div class="grid-tooltip-container" (click)="openObservationGridTooltip()">
                        <svg-icon src="/assets/img/icons/info.svg" class="tooltip-icon" [applyClass]="true">
                        </svg-icon>
                        <svg-icon src="/assets/img/icons/observation-grid.svg" class="grid-icon" [applyClass]="true">
                        </svg-icon>
                    </div>
                </ng-container>
                <europ-input (onIconClicked)="onSearch(true)" typeInput="text" [bindValue]="searchedText"
                    (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                    [placeholder]="'generic.SEARCH_BY_NAME_SURNAME_CID' | translate" [isSearchBar]="true">
                </europ-input>
            </div>
        </div>

        <!-- Speed screening -->
        <ng-container *ngIf="currentStep.id == 1">
            <div class="tab-container">
                <div class="tab" (click)="selectTab(true)" [ngClass]="{'active': withRequirements}">
                    <p translate="talent.WITH_REQUISITE"></p>
                </div>
                <div class="tab" (click)="selectTab(false)" [ngClass]="{'active': !withRequirements}">
                    <p translate="talent.WITHOUT_REQUISITE"></p>
                </div>
            </div>


            <div class="admin-table-container column-spacing">
                <!-- Tabella lista utenti screening -->
                <ng-container *ngIf="!isLoadingUserData && userData && userData.counter">
                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <!--th>
                                        <europ-check (onCheck)="selectAll()"></europ-check>
                                    </th-->
                                    <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.NOTES' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.AGE' | translate)}}"></th>
                                    <th class="center">{{ ('adminTable.PERFORMANCE_LEVEL' | translate) + ' ' +
                                        selectedYear.id}}</th>
                                    <th class="center" translate="{{ ('adminTable.ENGLISH_LEVEL' | translate)}}"></th>
                                    <th class="center" translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of userData.list | 
                                    slice:0:userData.numRecords | 
                                    paginate: { id: 'userList',
                                                itemsPerPage: userData.numRecords,
                                                currentPage: userData.page,
                                                totalItems: userData.counter }">
                                    <!--td>
                                        <europ-check id="user.userId" [checked]="user.isChecked"
                                            (click)="selectUser(user)">
                                        </europ-check>
                                    </td-->
                                    <td>
                                        <div class="avatar-name-container">
                                            <avatar-img [user]="user" size="small"></avatar-img>
                                            <p>{{ user.surname + ' ' + user.forename }}</p>
                                        </div>
                                    </td>
                                    <td class="center">
                                        <div class="note-number-container" (click)="openNoteListModal(user)">
                                            <svg-icon src="assets/img/icons/message-square.svg" [applyClass]="true"
                                                class="note-icon">
                                            </svg-icon>
                                            <ng-container *ngIf="user.notesCount">
                                                <p class="note-number">{{user.notesCount}}</p>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="count">
                                            {{ user.cid | truncate: [14, '...'] }}
                                        </div>
                                    </td>
                                    <td>{{ user.age }}</td>
                                    <td>
                                        <div class="performance-level">
                                            <p>{{ user.performanceLevel ? user.performanceLevel : '--' }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="english-level" [ngClass]="{'undefined': !user.englishLevel}">
                                            <p>{{ user.englishLevel ? (
                                                ('talent.LEVEL' | translate) + ' ' + user.englishLevel) :
                                                ('talent.NOT_DEFINED' |
                                                translate) }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="status-evaluation">
                                            <!-- Idoneo -->
                                            <ng-container *ngIf="user.status == 'ELIGIBLE'">
                                                <item-list-team-status styleItem="border noMinWidth" type="completed"
                                                    [showPoint]="false"
                                                    [message]=" 'talent.status.ELIGIBLE' | translate ">
                                                </item-list-team-status>
                                            </ng-container>
                                            <!-- Da decidere -->
                                            <ng-container *ngIf="user.status == 'TO_DECIDE'">
                                                <item-list-team-status styleItem="border noMinWidth" type="inProgress"
                                                    [showPoint]="false"
                                                    [message]=" 'talent.status.TO_DECIDE' | translate ">
                                                </item-list-team-status>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="user.status == 'NOT_ELIGIBLE' || user.status == 'NO_REQUIREMENTS'">
                                                <item-list-team-status styleItem="border noMinWidth" type="toStart"
                                                    [showPoint]="false"
                                                    [message]=" 'talent.status.NOT_ELIGIBLE' | translate ">
                                                </item-list-team-status>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td class="action">
                                        <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                                            (clickOutside)="closeMenu(user)"
                                            src="/assets/img/icons/more-horizontal.svg">
                                        </svg-icon>
                                        <ng-container *ngIf="user.isMenuOpen">
                                            <div class="dropdown-menu">
                                                <ng-container *ngFor="let option of menuOptions">
                                                    <europ-dropdown-item (click)="onMenuOptionsClicked(option, user)"
                                                        class="full-width" [item]="option">
                                                    </europ-dropdown-item>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div class="bottom-actions">
                        <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
                        </table-pagination>
                        <div class="per-page-selector">
                            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                bindLabel="id" [tablePagination]="true">
                            </europ-select>
                        </div>
                    </div>
                </ng-container>


                <!-- Nessun dato -->
                <ng-container *ngIf="!isLoadingUserData && (!userData || !userData.counter)">
                    <div class="admin-no-data-wrapper">
                        <p translate="process.details.NO_DATA"></p>
                    </div>
                </ng-container>

                <!-- Loader caricamento -->
                <ng-container *ngIf="isLoadingUserData">
                    <div class="loader-container">
                        <div class="loader spinner-big"></div>
                    </div>
                </ng-container>


            </div>

        </ng-container>

        <!-- Griglia di osservazione -->
        <ng-container *ngIf="currentStep.id == 2">
            <div class="admin-table-container more-padding column-spacing">
                <!-- Tabella lista utenti screening -->
                <ng-container *ngIf="!isLoadingObservationGrid && userData && userData.counter">
                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <!--th>
                                        <europ-check (onCheck)="selectAll()"></europ-check>
                                    </th-->
                                    <th translate="{{ ('adminTable.NAME' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.NOTES' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.AGE' | translate)}}"></th>
                                    <th class="center">{{ ('adminTable.PERFORMANCE_LEVEL' | translate) + ' ' +
                                        selectedYear.id}}</th>
                                    <th class="center" translate="{{ ('adminTable.POTENTIAL' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of userData.list | 
                                        slice:0:userData.numRecords | 
                                        paginate: { id: 'userList',
                                                    itemsPerPage: userData.numRecords,
                                                    currentPage: userData.page,
                                                    totalItems: userData.counter }">
                                    <!--td>
                                        <europ-check id="user.userId" [checked]="user.isChecked"
                                            (click)="selectUser(user)">
                                        </europ-check>
                                    </td-->
                                    <td>
                                        <div class="avatar-name-container">
                                            <avatar-img [user]="user" size="small"></avatar-img>
                                            <p>{{ user.surname + ' ' + user.forename }}</p>
                                        </div>
                                    </td>
                                    <td class="center">
                                        <div class="note-number-container" (click)="openNoteListModal(user)">
                                            <svg-icon src="assets/img/icons/message-square.svg" [applyClass]="true"
                                                class="note-icon">
                                            </svg-icon>
                                            <ng-container *ngIf="user.notesCount">
                                                <p class="note-number">{{user.notesCount}}</p>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="count">
                                            {{ user.cid | truncate: [14, '...'] }}
                                        </div>
                                    </td>
                                    <td>{{ user.age }}</td>
                                    <td>
                                        <div class="performance-level">
                                            <p>{{ user.performanceLevel ? user.performanceLevel : '--' }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="status-evaluation">
                                            <!-- Idoneo -->
                                            <ng-container
                                                *ngIf="user.potentialLevel != 'IN_OBSERVATION' &&  user.potentialLevel != 'TO_OBSERVE'">
                                                <item-list-team-status styleItem="border noMinWidth"
                                                    [type]="user.isTalentPotentialConfirmedByChief ? 'primary' : 'completed'"
                                                    [showPoint]="false"
                                                    [message]=" ('grid.potentialLevel.' + user.potentialLevel) | translate ">
                                                </item-list-team-status>
                                            </ng-container>
                                            <!-- Da decidere -->
                                            <ng-container *ngIf="user.potentialLevel == 'IN_OBSERVATION'">
                                                <item-list-team-status styleItem="border noMinWidth" type="inProgress"
                                                    [showPoint]="false"
                                                    [message]=" 'talent.potentialLevel.IN_OBSERVATION' | translate ">
                                                </item-list-team-status>
                                            </ng-container>
                                            <ng-container *ngIf="user.potentialLevel == 'TO_OBSERVE'">
                                                <item-list-team-status styleItem="border noMinWidth" type="toStart"
                                                    [showPoint]="false"
                                                    [message]=" 'talent.potentialLevel.TO_OBSERVE' | translate ">
                                                </item-list-team-status>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td class="action">
                                        <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                                            (clickOutside)="closeMenu(user)"
                                            src="/assets/img/icons/more-horizontal.svg">
                                        </svg-icon>
                                        <ng-container *ngIf="user.isMenuOpen">
                                            <div class="dropdown-menu">
                                                <ng-container *ngFor="let option of gridMenuOptions">
                                                    <europ-dropdown-item
                                                        (click)="onGridMenuOptionsClicked(option, user)"
                                                        class="full-width" [item]="option">
                                                    </europ-dropdown-item>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div class="bottom-actions">
                        <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
                        </table-pagination>
                        <div class="per-page-selector">
                            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                bindLabel="id" [tablePagination]="true">
                            </europ-select>
                        </div>
                    </div>


                </ng-container>

                <!-- Nessun dato -->
                <ng-container *ngIf="!isLoadingObservationGrid && (!userData || !userData.counter)">
                    <div class="admin-no-data-wrapper">
                        <p translate="process.details.NO_DATA"></p>
                    </div>
                </ng-container>

                <!-- Loader caricamento -->
                <ng-container *ngIf="isLoadingObservationGrid">
                    <div class="loader-container">
                        <div class="loader spinner-big"></div>
                    </div>
                </ng-container>


            </div>

        </ng-container>
    </div>
    <ng-container *ngIf="currentStep && currentStep.id == 2 && !isLoadingObservationGrid && !isConfirmed">
        <div class="table-action-container">
            <europ-button [label]="'generic.CONFIRM' | translate" type="secondary"
                (onButtonClicked)="openConfirmPotentialModal()" [disabled]="isFinalConfirmDisabled">
            </europ-button>
        </div>
    </ng-container>
</div>





















<!-- Modale inserisci annotazioni -->
<modal id="insert-note" (onClose)="closeInsertNote()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeInsertNote(true)" [isDisabled]="!modalNote || !modalNote.trim().length">
    <p class="modal-title">
        {{ ('talent.modals.INSERT_NOTE_TITLE' | translate)}}</p>
    <div class="confirmation-container">
        <!--  Inserisci note -->
        <input-container [numberCharsTextArea]="charsNote" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
            [isValued]="modalNote && modalNote.trim().length" [title]="'talent.modals.INSERT_NOTE_INPUT' | translate">
            <europ-text-area [bindValue]="modalNote" [maxlength]="600" (onModelChanged)="modalNoteChanged($event)"
                [placeholder]="'talent.modals.INSERT_NOTE_INPUT_PLACEHOLDER' | translate">
            </europ-text-area>
        </input-container>
        <!-- Upload documento -->
        <ng-container *ngIf="showFile">
            <input-container [isWithoutInfoIcon]="true" [isValued]="fileObject"
                [title]="'talent.modals.INSERT_NOTE_DOC' | translate">
                <europ-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"></europ-upload>
            </input-container>
        </ng-container>
    </div>
</modal>


<!-- Modale richiesta livello inglese -->
<modal id="request-english" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeSendEnglishLevelrequest()"
    (onClose)="closeSendEnglishLevelrequest()" (onConfirm)="closeSendEnglishLevelrequest(true)"
    [confirmLabel]="'generic.SEND_REQUEST' | translate">
    <ng-container *ngIf="englishModalUser && englishModalUser.forename">
        <modal-text-content modalId="re001" [title]="'talent.modals.ENGLISH_TITLE' | translate"
            [subtitle]="('talent.modals.ENGLISH_SUBTITLE' | translate) + englishModalUser.surname + ' ' + englishModalUser.forename"
            [text]="'talent.modals.ENGLISH_DESCR' | translate">
        </modal-text-content>
    </ng-container>
</modal>

<!-- Modale con elenco note per utente -->
<modal id="note-list" (onClose)="closeNoteListModal()" (onConfirm)="closeNoteListModal()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <ng-container *ngIf="noteListModalData && noteListModalData.user">
        <p class="modal-title" translate="talent.modals.NOTE_LIST_TITLE"></p>
        <p class="modal-subtitle"> {{ ('talent.modals.NOTE_LIST_DESCR' | translate) +
            noteListModalData.user.surname +
            ' ' + noteListModalData.user.forename}}</p>
        <div class="note-modal-container">
            <ng-container *ngIf="!isLoadingNoteList">

                <ng-container *ngFor="let note of noteListModalData.list">
                    <div class="note-container">
                        <svg-icon src="assets/img/icons/message-square.svg" [applyClass]="true" class="note-modal-icon">
                        </svg-icon>
                        <div class="note-content">
                            <div class="date-delete-container">
                                <p class="date">{{('talent.modals.NOTE_INSERT' | translate) + note.insertDate}}</p>
                                <svg-icon src="assets/img/icons/trash-2.svg" [applyClass]="true"
                                    class="note-modal-icon pointer" (click)="openDeleteNoteModal(note)">
                                </svg-icon>
                            </div>
                            <p class="title">{{ 'talent.modals.NOTE' | translate }}</p>
                            <p class="content">{{ note.note }}</p>
                            <p class="created-by">{{('talent.modals.CREATED_BY' | translate) + note.authorSurname + ' '
                                +
                                note.authorForename}}</p>
                            <ng-container *ngIf="note.files && note.files.length">
                                <div class="file-list-container">
                                    <ng-container *ngFor="let file of note.files">
                                        <list-doc-item [canDoActions]="false" id="file.uploadId"
                                            srcIcon="assets/img/icons/file/{{getExtensionString(file.fileName)}}.svg"
                                            [fileName]="file.fileName" (onDownload)="downloadLinkedFile(file)">
                                        </list-doc-item>
                                    </ng-container>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isLoadingNoteList">
                <div class="loader spinner-medium"></div>
            </ng-container>
        </div>
    </ng-container>
</modal>

<!-- Conferma eliminazione nota -->
<modal id="confirm-delete-note" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeDeleteNoteModal()"
    (onClose)="closeDeleteNoteModal()" (onConfirm)="closeDeleteNoteModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate">
    <modal-text-content modalId="deln003" [title]="'talent.modals.CONFIRM_DELETE_NOTE_TITLE' | translate"
        [text]="'talent.modals.CONFIRM_DELETE_NOTE_DESCR' | translate">
    </modal-text-content>
</modal>

<!-- Tooltip conferma  -->
<modal id="confirm-potential" (onClose)="closeConfirmPotentialModal()" (onCancel)="closeConfirmPotentialModal()"
    [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeConfirmPotentialModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate">
    <modal-text-content modalId="cpot003" [title]="'talent.modals.CONFIRM_POTENTIAL' | translate"
        [text]="'talent.modals.CONFIRM_POTENTIAL_DESCR' | translate">
    </modal-text-content>
</modal>

<!-- Tooltip griglia pagina intera -->
<modal id="grid-tooltip" [fullPage]="true" (onClose)="closeObservationGridTooltip()"
    (onConfirm)="closeObservationGridTooltip()" [confirmLabel]="'generic.CLOSE' | translate">
    <p class="modal-title center">
        {{ ('grid.dowels.TITLE' | translate)}}</p>
    <div class="mapwrapper">
        <div class="row">
            <div class="card card1">
                <div class="header">
                    <h3 translate="grid.dowels.1_TITLE"></h3>

                </div>
                <div class="body">
                    <p [innerHTML]="'grid.dowels.1_DESCR' | translate"></p>
                </div>
            </div>
            <div class="card card2">
                <div class="header">
                    <h3 translate="grid.dowels.2_TITLE"></h3>

                </div>
                <div class="body">
                    <p [innerHTML]="'grid.dowels.2_DESCR' | translate"></p>
                </div>
            </div>
        </div>
        <div class="card card3">
            <div class="row">
                <div class="card card3">
                    <div class="header">
                        <h3 translate="grid.dowels.3_TITLE"></h3>
                    </div>
                    <div class="body">
                        <p [innerHTML]="'grid.dowels.3_DESCR' | translate"></p>
                    </div>
                </div>
                <div class="card card4">
                    <div class="header">
                        <h3 translate="grid.dowels.4_TITLE"></h3>

                    </div>
                    <div class="body">
                        <p [innerHTML]="'grid.dowels.4_DESCR' | translate"></p>
                    </div>
                </div>
            </div>
        </div>

        <svg-icon class="puzzle" src="assets/img/icons/puzzle.svg" [applyClass]="true">
        </svg-icon>

    </div>
</modal>