import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';
import { SenecaResponse, ListMyGoalsResponse, GetMaxGoalWeightsForUsersByProfileResponse, GetPerformanceCareTagsResponse, PerfCareUserPhaseGoal, CreateUserGoalForUserResponse, UpdateMyGoalResponse, GetMyGoalByIdResponse, CanProposeGoalsToManagerResponse, ProposeMyGoalsToManagerResponse, GetUserFeedbackDialogueForUserResponse, PerfCareFeedbackDialogue, CreateUserFeedbackDialogueForUserResponse, UpdateUserFeedbackDialogueForUserResponse, DeleteMyGoalsResponse, GetMyManagerUserDataResponse, GetBehaviorCareTagsResponse, GetBehaviorCareTagByIdResponse, CancelUserFeedbackDialogueForUserResponse, SetMyCompetencesResponse, CloseMyCompetencesQuestionsResponse, HasLoggedUserDoneQualificationForCompetencesResponse, UpdateApproveGoalCommentProposedBySubordinateUserResponse, PerfCareDevelopmentAction, CreateDevelopmentActionForUserResponse, UpdateDevelopmentActionForUserResponse, CanCreateDevelopmentActionForUserResponse, CanShareDevelopmentActionToManagerResponse, PerfCareApplauseFeedback, ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse, PerfCareEvidenceFeedback, CreateEvidenceFeedbackForUserResponse, ListEvidenceFeedbackForUserResponse, UpdateEvidenceFeedbackForUserResponse, DeleteFileForEvidenceFeedbackForUserResponse, CountFeedbacksForUserResponse, ListPrivateNoteFeedbackForUserResponse, CreatePrivateNoteFeedbackForUserResponse, PerfCarePrivateNoteFeedback, GetPrivateNoteFeedbackForUserResponse, UpdatePrivateNoteFeedbackForUserResponse, DeletePrivateNoteFeedbackForUserResponse, EnrichedSenecaResponseForBadges, PerformanceCareNote, PerfCareUserFinalEvaluation } from 'src/commonclasses';

@Injectable()
export class CollaboratorService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;
  isCollaborator: boolean = false;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  // Recupera i goals del collaboratore
  listUserGoals(year: number, goalTab?: string | string[], goalTypes?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', year.toString() || '');
    if (goalTab && goalTab.length && Array.isArray(goalTab)) {
      goalTab && goalTab.forEach(type => {
        httpParams = httpParams.append('goalTab', type);
      });
    } else if (goalTab && goalTab.length && !Array.isArray(goalTab)) {
      httpParams = httpParams.append('goalTab', goalTab);
    }
    if (goalTypes && goalTypes.length && Array.isArray(goalTypes)) {
      goalTypes && goalTypes.forEach(type => {
        httpParams = httpParams.append('goalTypes', type);
      });
    } else if (goalTypes && goalTypes.length && !Array.isArray(goalTypes)) {
      httpParams = httpParams.append('goalTypes', goalTypes);
    }
    return this.http.get<SenecaResponse<ListMyGoalsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-my-goals/', {
      params: httpParams
    });
  }

  // Recupera i pesi profilo
  getObjectiveWeightsByProfile(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<GetMaxGoalWeightsForUsersByProfileResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-goal-configs-by-user-profile/', {
      params: httpParams
    });
  }

  // Recupera le Care options per gli obiettivi
  getCareOptions(fromRecord: string, numRecords: string, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-care-tags/', {
      params: httpParams
    });
  }

  // Crea un obiettivo
  createGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-goal-for-user', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo
  updateGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<UpdateMyGoalResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-user-goal-for-user', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoal: goal
    });
  }

  // Recupera l'obiettivo per il collaboratore
  getGoalByIdForUser(perfCareYear: string, goalId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('goalId', goalId);

    return this.http.get<SenecaResponse<GetMyGoalByIdResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-my-goal-by-id/', {
      params: httpParams
    });
  }

  // Può condividere un obiettivo al manager
  canShareGoal(perfCareYear: number) {
    return this.http.post<SenecaResponse<CanProposeGoalsToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/can-propose-goals-to-manager', {
      perfCareYear: perfCareYear
    });
  }

  // Condivi obiettivo al Manager
  shareGoalToManager(perfCareYear: number) {
    return this.http.post<SenecaResponse<ProposeMyGoalsToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/propose-my-goals-to-manager', {
      perfCareYear: perfCareYear
    });
  }

  // Restituisce le giornate disponibili e non
  getSlotCards(attendees: string, startDate: string, endDate: string, availabilityViewInterval: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendees', attendees);
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    httpParams = httpParams.append('availabilityViewInterval', availabilityViewInterval);

    return this.http.get<SenecaResponse<CheckExchangeAgendaForFreeSlotsResponse[]>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-users-schedules-slots-availability', {
      params: httpParams
    });
  }

  // Recupera il colloquio da suo interviewId
  getInterviewByIdForUser(perfCareYear: string, feedbackDialogueId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('feedbackDialogueId', feedbackDialogueId);

    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-feedback-dialogue-for-user/', {
      params: httpParams
    });
  }

  // Crea il colloquio
  createInterviewForUser(perfCareYear: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<CreateUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-feedback-dialogue-for-user', {
      perfCareYear: perfCareYear,
      feedbackDialogue: interview
    });
  }

  // Aggiorna il colloquio
  updateInterviewForUser(perfCareYear: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<UpdateUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-user-feedback-dialogue-for-user', {
      perfCareYear: perfCareYear,
      feedbackDialogue: interview
    });
  }

  // Elimia gli obiettivi passati come parametri gli Id
  deleteGoals(perfCareYear: number, goalIds: string[]) {
    return this.http.post<SenecaResponse<DeleteMyGoalsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-my-goals', {
      perfCareYear: perfCareYear,
      goalIdsToDelete: goalIds
    });
  }

  // Recupera il dettaglio di un manager
  getManagerByUserId() {
    return this.http.get<SenecaResponse<GetMyManagerUserDataResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-my-manager-user-data', {
    });
  }

  getClosestInterview(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<GetMyManagerUserDataResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-closest-user-feedback-dialogue-for-user', {
      params: httpParams
    });
  }

  // Recupera le Behaviour options per gli obiettivi
  getBehaviourOptions(fromRecord: string, numRecords: string, allData?: boolean, parentTagId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    if (parentTagId && parentTagId.length) {
      httpParams = httpParams.append('parentTagId', parentTagId);
    }
    return this.http.get<SenecaResponse<GetBehaviorCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-behavior-care-tags/', {
      params: httpParams
    });
  }

  // Recupero info careTag dal suo id
  getCareTagById(tagId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('tagId', tagId);

    return this.http.get<SenecaResponse<GetBehaviorCareTagByIdResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-behavior-care-tag-by-id/', {
      params: httpParams
    });
  }

  // Crea sfida per il collaboratore partendo dal careTag
  createUserGoalForUser(perfCareYear: string, perfCareUserPhaseGoal: any) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse[]>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-goal-for-user', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoals: perfCareUserPhaseGoal
    });
  }

  // Annulla il colloquio
  deleteInterview(perfCareYear: string, feedbackDialogueId: string) {
    return this.http.post<SenecaResponse<CancelUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/cancel-user-feedback-dialogue-for-user', {
      perfCareYear: perfCareYear,
      feedbackDialogueId: feedbackDialogueId
    });
  }

  // Recupero le azioni di sviluppo
  getDevelopmentActions(perfCareYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('forUserId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-development-action-for-user/', {
      params: httpParams
    });
  }

  getCompetenceForQualifications(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-my-competences-for-qualifications/', {
      params: httpParams
    });
  }

  // Recupero il booleano per sapere se si possono creare azioni di sviluppo
  canCreateDevelopmentActions(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<CanCreateDevelopmentActionForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/can-create-development-action-for-user/', {
      params: httpParams
    });
  }

  // Crea un azione di sviluppo
  createDevelopAction(perfCareYear: number, developmentAction: PerfCareDevelopmentAction) {
    return this.http.post<SenecaResponse<CreateDevelopmentActionForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-development-action-for-user', {
      perfCareYear: perfCareYear,
      developmentAction: developmentAction
    });
  }

  // Aggiorna un azione di sviluppo
  updateDevelopAction(perfCareYear: number, developmentAction: PerfCareDevelopmentAction) {
    return this.http.post<SenecaResponse<UpdateDevelopmentActionForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-development-action-for-user', {
      perfCareYear: perfCareYear,
      developmentAction: developmentAction
    });
  }

  // Recupera l'obiettivo per il collaboratore
  getDevelopActionByIdForCollaborator(perfCareYear: string, developmentActionId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('developmentActionId', developmentActionId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-development-action-by-id-for-user/', {
      params: httpParams
    });
  }

  // Aggiunge il commento ad un azione di sviluppo
  addCommentToDevelopPlan(perfCareYear: string, developmentActionId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-development-action-comment-for-user', {
      perfCareYear: perfCareYear,
      developmentActionId: developmentActionId,
      comment: comment
    });
  }

  // Aggiorna il commento ad un azione di sviluppo
  updateCommentForDevelopPlan(perfCareYear: string, developmentActionId: string, commentId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-development-action-comment-for-user', {
      perfCareYear: perfCareYear,
      developmentActionId: developmentActionId,
      comment: comment,
      commentId: commentId
    });
  }

  deleteCommentToDevelopPlan(perfCareYear: string, developmentActionId: string, commentId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-development-action-comment-for-user', {
      perfCareYear: perfCareYear,
      developmentActionId: developmentActionId,
      commentId: commentId
    });
  }
  // Condividi le azioni di sviluppo
  shareDevelopActions(perfCareYear: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/share-development-actions-to-manager', {
      perfCareYear: perfCareYear
    });
  }

  setMyCompetence(perfCareYear: string, competencesToSet: { tagId: string, areaType: string }[]) {
    return this.http.post<SenecaResponse<SetMyCompetencesResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-my-competences', {
      perfCareYear: perfCareYear,
      competencesToSet: competencesToSet
    });
  }

  changeMyCompetenceArea(perfCareYear: string, competencesToSet: { tagId: string, areaType: string }[]) {
    return this.http.post<SenecaResponse<CancelUserFeedbackDialogueForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/change-my-care-competence-area', {
      perfCareYear: perfCareYear,
      competencesToSet: competencesToSet
    });
  }

  closeMyCompetenceQuestions(perfCareYear: string) {
    return this.http.post<SenecaResponse<CloseMyCompetencesQuestionsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/close-my-competences-questions/', {
      perfCareYear: perfCareYear
    });
  }

  hasLoggedUserDoneQualificationForCompetences(perfCareYear: string) {
    return this.http.post<SenecaResponse<HasLoggedUserDoneQualificationForCompetencesResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/has-logged-user-done-qualification-for-competences/', {
      perfCareYear: perfCareYear
    });
  }

  // Aggiunge link ai goal operation
  createLinkForGoal(perfCareYear: string, goalId: string, userId: string, nameLink: string, link: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-link-for-goal-for-user', {
      perfCareYear: perfCareYear,
      goalId: goalId,
      goalUserId: userId,
      nameLink: nameLink,
      link: link
    });
  }

  deleteLinkForGoal(perfCareYear: string, goalId: string, userId: string, linkId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-link-for-goal-for-user', {
      perfCareYear: perfCareYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId
    });
  }

  updateLinkForGoal(perfCareYear: string, goalId: string, userId: string, linkId: string, linkTitle: string, url: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-link-for-goal-for-user', {
      perfCareYear: perfCareYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId,
      nameLink: linkTitle,
      link: url
    });
  }


  uploadFileForGoal(perfCareYear: string, goalId: string, userId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfCareYear', perfCareYear);

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/upload-file-for-goal-for-user', formData, options);
    return this.http.request(req);
  }

  updateFileForGoal(perfCareYear: string, goalId: string, userId: string, fileTitle: string, uploadObj: any, uploadId: string) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfCareYear', perfCareYear);
    formData.append('uploadId', uploadId)

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-file-for-goal', formData, options);
    return this.http.request(req);
  }

  deleteFileForGoal(perfCareYear: string, userId: string, uploadId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-file-for-goal-for-user', {
      perfCareYear: perfCareYear,
      userId: userId,
      uploadId: uploadId
    });
  }

  // Recupera l'url per fare il download del file
  retrieveNewMaskedUrlForUploadForUser(uploadId: string, goalId: string, perfCareYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('goalId', goalId);
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/retrieve-new-masked-url-for-upload-for-user/', {
      params: httpParams
    });
  }

  // Crea applauso smart feedback
  createClap(applauseFeedback: PerfCareApplauseFeedback[], userIdsForCreateFeedback: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-applause-feedback-for-user', {
      applauseFeedback: applauseFeedback,
      userIdsForCreateFeedback: userIdsForCreateFeedback
    });
  }
  // Elimina applauso smart feedback
  deleteClap(applauseFeedbackId: string, userId: string, perfCareYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-applause-feedback-for-user', {
      applauseFeedbackId: applauseFeedbackId,
      userId: userId,
      perfCareYear: perfCareYear
    });
  }

  // Aggiorna applauso smart feedback
  updateClap(applauseFeedback: PerfCareApplauseFeedback) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-applause-feedback-for-user', {
      applauseFeedback: applauseFeedback
    });
  }

  // Ritorna applauso dato l'id per smart feedback
  getClapById(perfCareYear: string, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-applause-feedback-for-user/', {
      params: httpParams
    });
  }

  // Ritorna la lista di applausi di smart feedback
  getClapList(perfcareYear: number, userIds: string | string[], received?: boolean, sent?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfcareYear.toString());
    if (userIds && userIds.length && Array.isArray(userIds)) {
      userIds && userIds.forEach(type => {
        httpParams = httpParams.append('userIds', type);
      });
    } else if (userIds && userIds.length && !Array.isArray(userIds)) {
      httpParams = httpParams.append('userIds', userIds);
    }

    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-applause-feedback-for-user/', {
      params: httpParams
    });
  }

  listMyColleagueUsers(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per applausi
  countSendClapUsers(perfCareYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-my-collegue-users/', {
      params: httpParams
    });
  }

  // Elimina l'azione di sviluppo
  deleteDevelopmentActionForUser(perfCareYear: string, developmentActionId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-development-action-for-user', {
      perfCareYear: perfCareYear,
      developmentActionId: developmentActionId
    });
  }

  changeDevelopmentActionStatus(perfCareYear: string, developmentActionId: string, newStatus: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/change-development-action-status-for-user', {
      perfCareYear: perfCareYear,
      developmentActionId: developmentActionId,
      newStatus: newStatus
    });
  }

  // Se può condividere le azioni di sviluppo
  canShareDevelopmentActions(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<CanShareDevelopmentActionToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/can-share-development-action-to-manager/', {
      params: httpParams
    });
  }

  // Recupera la lista degli utenti per applausi
  listSendClapUsers(fromRecord: number, numRecords: number, perfCareYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '16');
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  uploadAvatar(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  listMyBadges(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-my-obtained-badges/', {
      params: httpParams
    });
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociateWithEvidence() {
    return this.http.get<SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForUserResponse[]>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-valid-goals-to-associate-for-evidence-feedback-for-user/');
  }

  // Crea l'evidenza
  createEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<CreateEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-evidence-feedback-for-user', {
      evidenceFeedback: feedbackObject
    });
  }

  // Aggiorna l'evidenza
  updateEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<UpdateEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-evidence-feedback-for-user', {
      evidenceFeedback: feedbackObject
    });
  }

  // Lista delle evidenze
  getEvidences(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-evidence-feedback-for-user/', {
      params: httpParams
    });
  }

  // Recupera l'evidenza
  getEvidenceFeedback(perfCareYear: number, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-evidence-feedback-for-user/', {
      params: httpParams
    });
  }

  // Upload file in una evidenza
  uploadFileForEvidence(perfCareYear: string, evidenceId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('evidenceId', evidenceId);
    formData.append('perfCareYear', perfCareYear);

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/upload-file-for-evidence-feedback-for-user', formData, options);
    return this.http.request(req);
  }

  // Download file nell'evidenza
  retrieveNewMaskedUrlForUploadForEvidence(uploadId: string, evidenceId: string, perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('evidenceId', evidenceId);
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/retrieve-new-evicende-feedback-masked-url-upload-for-user/', {
      params: httpParams
    });
  }

  // Cancella file nell'evidenza
  deleteFileForEvidence(perfCareYear: string, evidenceId: string, uploadId: string) {
    return this.http.post<SenecaResponse<DeleteFileForEvidenceFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-file-for-evidence-feedback-for-user', {
      perfCareYear: perfCareYear,
      evidenceId: evidenceId,
      uploadId: uploadId
    });
  }

  // Recupera i counter delle tab del feedback
  getCounterFeedbackTabs(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<CountFeedbacksForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-feedbacks-for-user/', {
      params: httpParams
    });
  }

  getInterviewsForUser(perfCareYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-user-feedback-dialogue-for-user/', {
      params: httpParams
    });
  }


  getPrivateNotesList(perfCareYear?: number) {
    let httpParams = new HttpParams();
    if (perfCareYear) {
      httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    }
    return this.http.get<SenecaResponse<ListPrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-private-note-feedback-for-user/', {
      params: httpParams
    });
  }

  createPrivateNote(privateNoteFeedback: PerfCarePrivateNoteFeedback[]) {
    return this.http.post<SenecaResponse<CreatePrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-private-note-feedback-for-user', {
      privateNoteFeedback: privateNoteFeedback
    });
  }

  getPrivateNote(feedbackId: string, perfCareYear?: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('feedbackId', feedbackId);
    if (perfCareYear) {
      httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    }
    return this.http.get<SenecaResponse<GetPrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-private-note-feedback-for-user/', {
      params: httpParams
    });
  }

  updatePrivateNote(privateNoteFeedback: PerfCarePrivateNoteFeedback) {
    return this.http.post<SenecaResponse<UpdatePrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-private-note-feedback-for-user', {
      privateNoteFeedback: privateNoteFeedback
    });
  }

  deletePrivateNote(feedbackId: string) {
    return this.http.post<SenecaResponse<DeletePrivateNoteFeedbackForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-private-note-feedback-for-user', {
      feedbackId: feedbackId
    });
  }

  // lista goal selezionabili su cui ricevere feedback
  listGoalForPeerFeedback() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-valid-goals-to-associate-for-peer-feedback-for-user/', {
    });
  }

  createPeerFeedback(userIdsForCreateFeedback: string | string[], peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-peer-feedback-for-user', {
      peerFeedback: peerFeedback,
      userIdsForCreateFeedback: userIdsForCreateFeedback
    });
  }

  listPeerFeedback(perfCareYear: number, received: boolean, sent: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }
    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-peer-feedback-for-user/', {
      params: httpParams
    });
  }

  // Dati box home page mid term review
  getMidTermReviewBoxData(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-mid-term-review-box-data-for-user/', {
      params: httpParams
    });
  }

  updatePeerFeedback(perfCareYear: number, peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-peer-feedback-for-user', {
      peerFeedback: peerFeedback,
      perfCareYear: perfCareYear
    });
  }

  getPeerFeedbackById(perfCareYear: number, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-peer-feedback-for-user', {
      params: httpParams
    });
  }
  getMidTermGoals(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-mid-term-review-revision-data-for-user/', {
      params: httpParams
    });
  }

  deletePeerFeedback(perfCareYear: number, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-peer-feedback-for-user', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per applausi
  countUserForPeerFeedback(perfCareYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/count-my-collegue-users-for-peer-feedback/', {
      params: httpParams
    });
  }

  // recupera la lista di personde a cui è possibile richiedere feedback
  listUserForPeerFeedback(fromRecord: number, numRecords: number, perfCareYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '16');
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-my-collegue-users-for-peer-feedback/', {
      params: httpParams
    });
  }

  setMidTermReviewRevision(perfCareYear: number, midTermReviewUserObservation: string, midTermReviewUserGoalAdvancement: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-mid-term-review-revision-data-for-user', {
      perfCareYear: perfCareYear,
      midTermReviewUserObservation: midTermReviewUserObservation,
      midTermReviewUserGoalAdvancement: midTermReviewUserGoalAdvancement,
    });
  }

  setSelfObservationForMyGoals(perfCareYear: number, goalId: string, autoObservation: string, autoObservationScore: string) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-self-observation-for-my-goal', {
      perfCareYear: perfCareYear,
      goalId: goalId,
      autoObservation: autoObservation,
      autoObservationScore: autoObservationScore
    });
  }


  getMyFinalEvaluation(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-my-final-evaluation', {
      params: httpParams
    });
  }

  setMyFinalEvaluation(perfCareYear: number, userFinalEvaluation: any) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-my-final-evaluation-self-observation', {
      perfCareYear: perfCareYear,
      userFinalEvaluation: userFinalEvaluation
    });
  }

  getFinalEvaluationDate(perfCareYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-final-evaluation-dates', {
      perfCareYear: perfCareYear
    });
  }

  getPerformanceAssessmentYears(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-performance-assessment-years', {
      params: httpParams
    });
  }

  getPostCalibrationData(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-post-calibration-data-for-user', {
      params: httpParams
    });
  }

  // Processo chiuso
  isProcessClosed(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/is-process-closed-by-year', {
      params: httpParams
    })
  }

  // Dati box in home del talent
  getTalentBoxDataForChief(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-talent-box-data-for-chief/', {
      params: httpParams
    });
  }

  // dati griglia di valutazione del talent
  getEvaluationGridUser(perfCareYear: number, searchedText?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-evaluation-grid-users-for-chief/', {
      params: httpParams
    });
  }

  // scarica report griglia valutazione talent chief
  downloadEvaluationGridReport(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/download-evaluation-grid-report-for-chief/', {
      params: httpParams
    });
  }

  // Salva dati griglia chief
  saveEvaluationGrid(perfCareYear: number, users: Array<{ userId: string; potentialLevel: string; }>, confirmUsers: boolean) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/save-evaluation-grid-users-for-chief', {
      perfCareYear: perfCareYear,
      confirmUsers: confirmUsers,
      users: users
    });
  }

  // aggiungi annotazione speed screening
  createTalentNote(perfCareYear: number, userId: string, note: string, file: any) {
    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('perfCareYear', perfCareYear.toString());
    formData.append('note', note);
    formData.append('file', file);

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-talent-note', formData, options);
    return this.http.request(req);
  }

  getUserTalentNote(perfCareYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-user-talent-notes/', {
      params: httpParams
    });
  }


  deleteUserTalentNote(noteId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-user-talent-note', {
      noteId: noteId
    });
  }



  // Wizard creazione obiettivi
  // Lista degli step del wizard di creazione obiettivi con stato
  getWizardStepList(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-goal-settings-steps-for-user/', {
      params: httpParams
    });
  }

  // Controlla lo stato della proposta degli obiettivi
  getGoalSettingProposalStatus(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-goal-settings-proposal-status-for-user/', {
      params: httpParams
    });
  }

  // Recupera gli obiettivi di ruolo del collaboratore
  getRoleObjectives(year: number, goalTab?: string | string[], goalTypes?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', year.toString() || '');
    if (goalTab && goalTab.length && Array.isArray(goalTab)) {
      goalTab && goalTab.forEach(type => {
        httpParams = httpParams.append('goalTab', type);
      });
    } else if (goalTab && goalTab.length && !Array.isArray(goalTab)) {
      httpParams = httpParams.append('goalTab', goalTab);
    }
    if (goalTypes && goalTypes.length && Array.isArray(goalTypes)) {
      goalTypes && goalTypes.forEach(type => {
        httpParams = httpParams.append('goalTypes', type);
      });
    } else if (goalTypes && goalTypes.length && !Array.isArray(goalTypes)) {
      httpParams = httpParams.append('goalTypes', goalTypes);
    }
    return this.http.get<SenecaResponse<ListMyGoalsResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/list-my-goals/', {
      params: httpParams
    });
  }

  // Crea un obiettivo di ruolo
  createRoleGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-user-goal-for-user', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo di ruolo
  updateRoleGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<UpdateMyGoalResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-my-goal', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoal: goal
    });
  }

  getRemainingWeight(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-remaining-goal-weights-for-user/', {
      params: httpParams
    });
  }

  // Recupera gli obiettivi di sviluppo (o sfide)
  getDevelopmentObjectives(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-development-goal-for-user/', {
      params: httpParams
    });
  }

  // Crea un obiettivo di sviluppo (sfida)
  createDevelopmentGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/create-development-goal-for-user', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo di sviluppo (sfida)
  updateDevelopmentGoal(perfCareYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<UpdateMyGoalResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/update-development-goal-for-user', {
      perfCareYear: perfCareYear,
      perfCareUserPhaseGoal: goal
    });
  }

  getGoalSettingFeedbackDate(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-goal-settings-feedback-dialogue-date/', {
      params: httpParams
    });
  }


  setGoalSettingFeedbackDate(perfCareYear: number, date: Date) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/set-goal-settings-feedback-dialogue-date', {
      perfCareYear: perfCareYear,
      date: date
    });
  }

  getGoalSettingFinalStepInfo(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-goal-settings-final-step-info-for-user/', {
      params: httpParams
    });
  }

  proposeGoalsToManager(perfCareYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/propose-my-goals-to-manager', {
      perfCareYear: perfCareYear
    });
  }

  getCascadingGoals(perfCareYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/get-cascading-goals-for-user/', {
      params: httpParams
    });
  }

  cancelGoalAfterSharing(perfCareYear: number, goalId?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfCareYear', perfCareYear.toString());

    if (goalId && goalId.length && Array.isArray(goalId)) {
      goalId && goalId.forEach((id: string) => {
        httpParams = httpParams.append('goalId', id);
      });
    } else if (goalId && goalId.length && !Array.isArray(goalId)) {
      httpParams = httpParams.append('goalId', goalId);
    }
    return this.http.delete<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/delete-draft-goals-for-user-after-sharing/', {
      params: httpParams
    });
  }

  // Può condividere un obiettivo al manager
  canProposeGoalAfterSharing(perfCareYear: number) {
    return this.http.post<SenecaResponse<CanProposeGoalsToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/can-propose-goals-to-manager-after-sharing', {
      perfCareYear: perfCareYear
    });
  }

  // Condivi obiettivo al Manager
  poposeGoalAfterSharing(perfCareYear: number) {
    return this.http.post<SenecaResponse<ProposeMyGoalsToManagerResponse>>(this.applicationData.applicationContext + 'rest-api/performancecare-mediator/propose-my-goals-to-manager-after-sharing', {
      perfCareYear: perfCareYear
    });
  }

}
export interface CheckExchangeAgendaForFreeSlotsResponse {
  start?: Date;
  end?: Date;
  startLabel?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
  persons: number;
  percentage: number;
  unavailablePersonList: Array<string>;
}