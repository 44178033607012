import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Column } from 'devextreme/ui/data_grid';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";

@Component({
  selector: 'admin-process-setup-objectives',
  templateUrl: './processSetupObjectives.component.html',
  styleUrls: ['./processSetupObjectives.component.scss']
})
export class ProcessSetupObjectivesComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();
  processYear: string = '';
  objectiveResultIds = [
    "UNSATISFACTORY",
    "IMPROVABLE",
    "MEET",
    "EXCEEDED",
    "EXCEPTIONAL",
  ]
  objectiveResultOptions: { id: string, name: string }[] = [];
  objectiveEvaluationIds: string[] = [
    "NOT_ACHIEVED",
    "ACHIEVED",
    "EXCEEDED"
  ]
  objectiveEvaluationOptions: { id: string, name: string }[] = [];

  isLoadingCompanyGoals: boolean = false;
  companyData: any[] = [];
  getCompanyGoals$: Subscription = new Subscription();
  // Instanza data grid obiettivi company
  companyGrid: any = null;
  // Instanza data grid obiettivi operations
  operationsGrid: any = null;
  isLoadingOperationsGoal: boolean = false;
  getOperationsGoals$: Subscription = new Subscription();
  operationsData: any;


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.route.params
              .subscribe(
                (params: Params) => {
                  this.processYear = params.processYear;
                  this.translate.get([
                    "boEvaluations.UNSATISFACTORY",
                    "boEvaluations.IMPROVABLE",
                    "boEvaluations.MEET",
                    "boEvaluations.EXCEEDED",
                    "boEvaluations.EXCEPTIONAL",
                    "boResults.NOT_ACHIEVED",
                    "boResults.ACHIEVED",
                    "boResults.EXCEEDED"
                  ]).subscribe((translations) => {
                    // lista opzioni per colonna risultati
                    this.objectiveResultOptions = [];
                    this.objectiveResultIds.forEach(element => {
                      this.objectiveResultOptions.push({
                        id: element,
                        name: translations["boEvaluations." + element]
                      })
                    });
                    this.objectiveEvaluationOptions = [];
                    this.objectiveEvaluationIds.forEach(element => {
                      this.objectiveEvaluationOptions.push({
                        id: element,
                        name: translations["boResults." + element]
                      })
                    });
                    // this.getCompanyStructureData()
                    this.getCompanyGoals();
                    this.getOperationsGoals();
                  });
                })
          }
        });
  }

  ngOnInit() {
  }

  // Recupera lal lista di obiettivi company
  getCompanyGoals() {
    this.isLoadingCompanyGoals = true;

    if (this.getCompanyGoals$) {
      this.getCompanyGoals$.unsubscribe();
    }

    this.getCompanyGoals$ = this.adminService.getCompanyGoalsForAdmin(this.processYear).subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "pso003",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        for (let i = 0; i < data.response.length; i++) {
          let companyGroup = data.response[i];
          for (let j = 0; j < companyGroup.companyAndStructureGoals.length; j++) {
            this.companyData.push({
              ...companyGroup.companyAndStructureGoals[j],
              groupName: data.response[i].groupName,
              groupId: data.response[i].groupId
            })
          }
        }
      }
      this.isLoadingCompanyGoals = false;
    }, (err) => {
      // Vedo se c'è la traduzione dell'errore
      const messageObj: ApplicationModalMessage = {
        modalId: "pso004",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingCompanyGoals = false;
    })
  }

  getOperationsGoals() {
    this.isLoadingOperationsGoal = true;
    if (this.getOperationsGoals$) {
      this.getOperationsGoals$.unsubscribe();
    }

    this.getOperationsGoals$ = this.adminService.getOperationsGoalsForAdmin(this.processYear).subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "pso003",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.operationsData = data.response;
      }
      this.isLoadingOperationsGoal = false;
    }, (err) => {
      // Vedo se c'è la traduzione dell'errore
      const messageObj: ApplicationModalMessage = {
        modalId: "pso004",
        text: this.translate.instant("errors." + ((err && err.message) || err)),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingOperationsGoal = false;
    })
  }

  orderBy(fieldName: string) {
  }

  // Aggiornare newData e currentRowData
  setObjectiveValue(current: Column, newData: any, currentRow: any, currentRowData: any) {
    currentRow.evaluation = currentRowData;
  }

  saveCompanyObjectives(event: any) {
    // se ho delle modifiche da salvare
    if (event?.changes && event?.changes.length && event?.changes[0] && event?.changes[0].data) {
      // this.isLoadingCompanyGoals = true;
      this.adminService.updateCompanyGoalForAdmin(event.changes[0].data).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "pso001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // Torna true
          // for (let i = 0; i < data.response.length; i++) {
          //   let companyGroup = data.response[i];
          //   for (let j = 0; j < companyGroup.companyGoals.length; j++) {
          //     this.companyData.push({
          //       ...companyGroup.companyGoals[j],
          //       groupName: data.response[i].groupName,
          //       groupId: data.response[i].groupId
          //     })
          //   }
          // }
        }
        this.isLoadingCompanyGoals = false;
      }, (err) => {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "pso002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCompanyGoals = false;
      })
    } else {
      // Non tornare modale se salvo senza fare modifiche
      // const messageObj: ApplicationModalMessage = {
      //   modalId: "a002",
      //   title: this.translate.instant("generic.WARNING"),
      //   text: this.translate.instant("errors.GOAL_DATA_ERROR")
      // }
      // this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingCompanyGoals = false;
    }
  }

  // salva modifiche degli obiettivi operations
  saveOperationsObjectives(event: any) {
    // se ho delle modifiche da salvare
    if (event?.changes && event?.changes.length && event?.changes[0] && event?.changes[0].data) {
      this.isLoadingOperationsGoal = true;

      this.adminService.updateOperationsGoalForAdmin(event.changes[0].data).subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "pso001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // for (let i = 0; i < data.response.length; i++) {
          //   let companyGroup = data.response[i];
          //   for (let j = 0; j < companyGroup.companyGoals.length; j++) {
          //     this.companyData.push({
          //       ...companyGroup.companyGoals[j],
          //       groupName: data.response[i].groupName,
          //       groupId: data.response[i].groupId
          //     })
          //   }
          // }
        }
        this.isLoadingOperationsGoal = false;
      }, (err) => {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "pso002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingOperationsGoal = false;
      })
    } else {
      // Non tornare modale se salvo senza fare modifiche
      // const messageObj: ApplicationModalMessage = {
      //   modalId: "a002",
      //   title: this.translate.instant("generic.WARNING"),
      //   text: this.translate.instant("errors.GOAL_DATA_ERROR")
      // }
      // this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.isLoadingOperationsGoal = false;
    }
  }


  saveCompanyGridInstance(event: any) {
    this.companyGrid = event.component;
  }

  saveOperationsGridInstance(event: any) {
    this.operationsGrid = event.component;
  }

  logEvent(event: string) {
  }

  saveCompanyStructure() {

  }

  ngOnDestroy() {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }

    if (this.getCompanyGoals$) {
      this.getCompanyGoals$.unsubscribe();
    }

    if (this.getOperationsGoals$) {
      this.getOperationsGoals$.unsubscribe();
    }

  }
}
