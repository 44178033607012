import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { PerfCareUserPhaseGoal, GetPerformanceCareTagsResponse, SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, CreateUserGoalForUserResponse, PerfCareGoalRentStatuses, PerfCareMacroGoalTypes, CreateUserGoalForManagerResponse, UpdateUserGoalForManagerResponse, GetGoalByIdForManagerResponse, GetUserGoalWeightSumForManagerResponse, GetBehaviorCareTagsResponse, ApproveGoalProposedBySubordinateUserResponse, UpdateApproveGoalCommentProposedBySubordinateUserResponse, RentStatusTypes } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Tag, PerfCareTag } from '../../../commonclasses';
import { Params, ActivatedRoute } from '@angular/router';
import { Subordinateservice } from '../services/subordinates.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

@Component({
  selector: 'app-manager-createOptionalObjectives',
  templateUrl: './createOptionalObjectives.component.html',
  styleUrls: ['./createOptionalObjectives.component.scss']
})
export class CreateOptionalObjectivesComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  userId: string = '';
  getPersonDetails$: any;
  personDetails$: any;
  personDetails: any;
  goal: PerfCareUserPhaseGoal = {
    goalId: '',
    creationUserId: '',
    phaseId: '',
    userId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL
  };
  isFetchingCareOptions: boolean = false;
  getCareOptions$: Subscription | null = null;
  careOptionsCounter: number = 0;
  careOptions: PerfCareTag[] = [];

  goalCopy: PerfCareUserPhaseGoal = {
    goalId: '',
    phaseId: '',
    userId: '',
    creationUserId: '',
    title: '',
    description: '',
    objectiveDescription: '',
    howToUseCareTag: '',
    goalStatus: '',
    macroGoalType: PerfCareMacroGoalTypes.INDIVIDUAL
  };
  isSharedGoal: boolean = false;

  titleTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  weightTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  deadlineTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  careTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  endYear: Date = new Date();
  goalId: any;
  charsGoalDescription: number = 600;
  maxTextAreaChars: number = 600;
  charsGoalObjectiveDescription: number = 600;
  isLoadingData: boolean = false;
  getGoal$: Subscription = new Subscription();
  updateGoal$: Subscription = new Subscription();
  createGoal$: Subscription = new Subscription();
  isImpersonate: boolean = false;
  isLoadingPersonDetails: boolean = true;
  selectedDate: Date = new Date();

  constructor(
    private subordinatesService: Subordinateservice,
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Controllo se sto impersonificando
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      });

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      this.endYear = new Date(this.runningYear, 11, 31, 11);
      this.selectedDate = this.endYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;
              this.goal.userId = params.userId;

              this.getCareOptions();
              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];

                if (this.personDetails) {
                  if (this.goalId) {
                    this.getGoalById();
                  }
                }
                // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                if (!this.personDetails) {
                  this.getPersonDetails();
                }
              });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.WHAT',
        'generic.HOW',
        'goalSetting.createGoal.CARE_ATTRIBUTES_MANAGER',
        'goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION',
        'goalSetting.createGoal.MANAGER_COMMENT',
        'generic.TITLE',
        'goalSetting.createGoal.OBJECTIVE_TITLE',
        'goalSetting.createGoal.OBJECTIVE_TITLE_TEXT',
        'generic.DESCRIPTION',
        'goalSetting.createGoal.DESCRIPTION_SUBTITLE',
        'goalSetting.createGoal.DESCRIPTION_TEXT',
        'goalSetting.createGoal.OBJECTIVE_DESCRIPTION',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT_SUBTITLE',
        'goalSetting.createGoal.OBJECTIVE_WEIGHT_TEXT',
        'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE',
        'goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_TEXT',
        'goalSetting.createGoal.MANAGER_COMMENT_SUBTITLE',
        'goalSetting.createGoal.MANAGER_COMMENT_TEXT'
      ])
      .subscribe(translations => {


        this.titleTooltipModal = {
          modalId: "118",
          title: translations['generic.TITLE'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_TITLE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_TITLE_TEXT']
        };

        this.descriptionTooltipModal = {
          modalId: "119",
          title: translations['generic.DESCRIPTION'],
          subtitle: translations['goalSetting.createGoal.DESCRIPTION_SUBTITLE'],
          text: translations['goalSetting.createGoal.DESCRIPTION_TEXT']
        };
        this.weightTooltipModal = {
          modalId: "121",
          title: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT'],
          subtitle: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT_SUBTITLE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_WEIGHT_TEXT']
        };
        this.deadlineTooltipModal = {
          modalId: "122",
          title: translations['goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE'],
          text: translations['goalSetting.createGoal.OBJECTIVE_DEADLINE_DATE_TEXT']
        };
        this.careTooltipModal = {
          modalId: "123",
          title: translations['goalSetting.createGoal.CARE_ATTRIBUTES_MANAGER'],
          text: translations['goalSetting.createGoal.CARE_ATTRIBUTES_DESCRIPTION']
        };

      })
  }

  findWithAttr(array: any, attr: string, value: any) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  // Recupara l'utente dal suo userId
  getPersonDetails() {
    this.isLoadingPersonDetails = true;

    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }

    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.runningYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "075",
            text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.personDetails = personDetailsData.response;
          if (!this.goalId) {
            this.goal.macroGoalType = PerfCareMacroGoalTypes.INDIVIDUAL;
          }
          if (this.goalId) {
            this.getGoalById();
          }
        }
        this.isLoadingPersonDetails = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "076",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPersonDetails = false;
      });
  }


  // Quando si scrive sull'input del titolo obiettivo
  goalTitleChanged(text: string) {
    this.goal.title = text;
  }

  // Quando si scrive sulla textarea della descrizione obiettivo
  goalDescriptionChanged(text: string) {
    this.goal.description = text;
    this.charsGoalDescription = this.maxTextAreaChars - text.length;
  }

  // Quando si scrive sulla textarea della descrizione obiettivo(la seconda)
  goalObjectiveDescriptionChanged(text: string) {
    this.goal.objectiveDescription = text;
    this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
  }


  // Quando selezioni un altro Care tag
  changeCareTag(tag: Tag) {
    this.goal.careTag = tag;
    this.goal.careTagId = tag.tagId;
    this.goal.behaviorTag = undefined;
    this.goal.behaviorTagId = '';
  }

  // Quando selezioni un altro Behaviour tag
  changeBehaviourTag(tag: Tag) {
    this.goal.behaviorTag = tag;
    this.goal.behaviorTagId = tag.tagId;
  }

  // Recupera i tag Care
  getCareOptions() {
    this.isFetchingCareOptions = true;
    this.getCareOptions$ = this.managerService.getCareOptions('', '', true)
      .subscribe((data: SenecaResponse<GetPerformanceCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "077",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.careOptionsCounter = data.response.totalRowsCount;
          this.careOptions = data.response.rows;
        }
        this.isFetchingCareOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "078",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCareOptions = false;
        })
  }


  // Recupera l'obiettivo dal goalId
  getGoalById() {
    this.isLoadingData = true;

    if (this.getGoal$) {
      this.getGoal$.unsubscribe();
    }

    this.getGoal$ = this.managerService.getGoalByIdForManager(this.runningYear.toString(), this.goalId, this.personDetails.userId)
      .subscribe((data: SenecaResponse<GetGoalByIdForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "081",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goToPersonDetails(this.userId, this.runningYear);
        } else if (data && data.response) {
          this.goal = data.response;
          if (this.goal.description && this.goal.description.length) {
            this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
          }
          if (this.goal.objectiveDescription) {
            this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
          }
          this.goalCopy = JSON.parse(JSON.stringify(this.goal));
        }
        this.isLoadingData = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "082",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goToPersonDetails(this.userId, this.runningYear);
          this.isLoadingData = false;
        })
  }

  selectedDateChanged(newDate: Date) {
    this.selectedDate = newDate;
    this.goal.deadlineDate = newDate;
  }

  // Aggiorna obiettivo
  updateGoal() {
    this.isLoadingData = true;

    if (this.updateGoal$) {
      this.updateGoal$.unsubscribe();
    }

    this.goal.deadlineDate = this.selectedDate;

    this.updateGoal$ = this.managerService.updateGoal(this.runningYear, this.goal)
      .subscribe((data: SenecaResponse<UpdateUserGoalForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "085",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goal = data.response;

          if (this.goal.objectiveDescription) {
            this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
          }
          this.goalCopy = JSON.parse(JSON.stringify(this.goal));

          const messageObj = {
            modalId: "086",
            title: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED"),
            text: this.translate.instant("goalSetting.createGoal.GOAL_UPDATED_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goToPersonDetails(this.userId, this.runningYear);
        }
        this.isLoadingData = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "087",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingData = false;
        })
  }

  // Crea obiettivo
  createGoal() {
    this.isLoadingData = true;

    if (this.createGoal$) {
      this.createGoal$.unsubscribe();
    }
    this.goal.deadlineDate = this.selectedDate;

    this.createGoal$ = this.managerService.createGoal(this.runningYear, this.goal)
      .subscribe((data: SenecaResponse<CreateUserGoalForManagerResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "088",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response && data.response.length) {
          this.goal = data.response[0];
          if (this.goal.description && this.goal.description.length) {
            this.charsGoalDescription = this.maxTextAreaChars - this.goal.description.length;
          }
          if (this.goal.objectiveDescription) {
            this.charsGoalObjectiveDescription = this.maxTextAreaChars - this.goal.objectiveDescription.length;
          }
          this.goalCopy = JSON.parse(JSON.stringify(this.goal));

          const messageObj: ApplicationModalMessage = {
            modalId: "089",
            title: this.translate.instant("goalSetting.createGoal.GOAL_CREATED"),
            text: this.translate.instant("goalSetting.createGoal.GOAL_CREATED_DESCRIPTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

          // this.store.dispatch(ManagerActions.UpdateCollaboratorUserStatusType({
          //   payload: {
          //     userId: this.userId,
          //     userStatusType: RentStatusTypes.STARTED
          //   }
          // }));
          this.redirectService.goToPersonDetails(this.userId, this.runningYear);
        }
        this.isLoadingData = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "090",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingData = false;
        })
  }

  createUpdate(isBack?: boolean) {
    if (isBack) {
      this.redirectService.goBackBrowser();
    } else {
      this.modalService.open('confirm-modal');
    }
  }


  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('confirm-modal');
    if (confirm) {
      if (this.goalId) {
        this.updateGoal();
      } else {
        this.createGoal();
      }
    }
  }

  isLoading() {
    return this.isFetchingCareOptions || this.isLoadingData;
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getCareOptions$) {
      this.getCareOptions$.unsubscribe();
    }
    if (this.getGoal$) {
      this.getGoal$.unsubscribe();
    }
    if (this.createGoal$) {
      this.createGoal$.unsubscribe();
    }
    if (this.updateGoal$) {
      this.updateGoal$.unsubscribe();
    }
  }
}