import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentBannerPhaseResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  boxDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: string = '';
  isLoadingProcessDetails: boolean = false;
  getProcessDetails$: Subscription = new Subscription;
  processDetail: any[] = [];
  structureForModal: any;
  downloadReport$: any;
  isDownloadingReport: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.route.params.subscribe((params: any) => {
              if (params.processYear) {
                this.processYear = params.processYear;
              }

              if (this.processYear) {
                this.getProcessDetails();
              } else {
                this.getProcessesData();
              }
            })
          }
        });
  }

  ngOnInit() {
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            data.response.forEach((process: any) => {
              process.startDate = process.startDate ? moment(process.startDate).format("DD/MM/YYYY") : null;
              process.endDate = process.endDate ? moment(process.endDate).format("DD/MM/YYYY") : null;
            })
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  getProcessDetails() {
    this.isLoadingProcessDetails = true;
    if (this.getProcessDetails$) {
      this.getProcessDetails$.unsubscribe();
    }
    this.processDetail = []
    this.getProcessDetails$ = this.adminService.processStructureDetail(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingProcessDetails = false;
        } else {
          let tmpList: any[] = [];
          if (data.response && data.response.length) {
            this.translate.get([
              'phases.FEEDBACK',
              'monitoring.modals.COUNTER_FEEDBACK_1',
              'monitoring.modals.COUNTER_FEEDBACK_2',
              'monitoring.modals.PEER_REC',
              'monitoring.modals.PEER_REQ',
              'monitoring.modals.PEER_REQ1',
              'monitoring.modals.PEER_REQ2',
              'monitoring.modals.CLAP_SENT',
              'monitoring.modals.INTERVIEW',
              'monitoring.modals.EVIDENCES',
            ]).subscribe((translations: any) => {
              for (let i = 0; i < data.response.length; i++) {
                tmpList.push({
                  id: data.response[i].structureId,
                  name: data.response[i].title,
                  totalFeedback: data.response[i].strutureData?.feedback?.totalFeedbackCount,
                  developmentPlanStatus: this.getStatus(data.response[i].strutureData?.developmentPlanStatus),
                  finalEvaluationStatus: this.getStatus(data.response[i].strutureData?.finalEvaluationStatus),
                  goalSettingStatus: this.getStatus(data.response[i].strutureData?.goalSettingStatus),
                  midTermReviewStatus: this.getStatus(data.response[i].strutureData?.midTermReviewStatus),
                  modalData: {
                    modalId: 'a006',
                    title: translations['phases.FEEDBACK'] + ' ' + data.response[i].title,
                    subtitle: translations['monitoring.modals.COUNTER_FEEDBACK_1'] + data.response[i].strutureData?.feedback?.totalFeedbackCount + translations['monitoring.modals.COUNTER_FEEDBACK_2'],
                    description: this.getModalDescription(data.response[i].strutureData?.feedback, translations)
                  }
                })
                if (data.response[i].childStructures && data.response[i].childStructures.length) {
                  tmpList[tmpList.length - 1].hasChildren = true
                  let childStructures = data.response[i].childStructures
                  for (let j = 0; j < childStructures.length; j++) {
                    tmpList.push({
                      id: childStructures[j].structureId,
                      name: childStructures[j].title,
                      isChild: true,
                      totalFeedback: childStructures[j].strutureData?.feedback?.totalFeedbackCount,
                      developmentPlanStatus: this.getStatus(childStructures[j].strutureData?.developmentPlanStatus),
                      finalEvaluationStatus: this.getStatus(childStructures[j].strutureData?.finalEvaluationStatus),
                      goalSettingStatus: this.getStatus(childStructures[j].strutureData?.goalSettingStatus),
                      midTermReviewStatus: this.getStatus(childStructures[j].strutureData?.midTermReviewStatus),
                      modalData: {
                        modalId: 'a006',
                        title: translations['phases.FEEDBACK'] + ' ' + childStructures[j].title,
                        subtitle: translations['monitoring.modals.COUNTER_FEEDBACK_1'] + childStructures[j].strutureData?.feedback?.totalFeedbackCount + translations['monitoring.modals.COUNTER_FEEDBACK_2'],
                        description: this.getModalDescription(childStructures[j].strutureData?.feedback, translations)
                      }
                    })
                  }
                }
              }
              this.processDetail = tmpList;
              this.isLoadingProcessDetails = false;
            })
          } else {
            this.processDetail = [];
            this.isLoadingProcessDetails = false;
          }
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProcessDetails = false;
      })
  }

  getStatus(phase: any) {
    if (phase && phase.status) {
      switch (phase.status) {
        case 'TO_START': {
          return {
            status: 'toStart',
            statusMessage: this.translate.instant('itemListTeamStatus.toStart')
          }
        }
        case 'STARTED': {
          return {
            status: 'inProgress',
            statusMessage: this.translate.instant('itemListTeamStatus.inProgress') + ' ' + (phase.percentage.toFixed(1)) + '%'
          }
        }
        case 'COMPLETED': {
          return {
            status: 'completed',
            statusMessage: this.translate.instant('itemListTeamStatus.completed')
          }
        }
        default:
          return {
            status: 'disabled',
            statusMessage: this.translate.instant('itemListTeamStatus.disabled')
          }
      }
    } else {
      return {
        status: 'disabled',
        statusMessage: this.translate.instant('itemListTeamStatus.disabled')
      }
    }

  }


  getModalDescription(feedback: any, translations: any) {
    let text = '';
    let peerFeedback = '';
    if (feedback && feedback.requestedPeerFeedbackCount > 0) {
      peerFeedback = '<b>' + feedback.requestedPeerFeedbackCount + '</b>' + translations['monitoring.modals.PEER_REQ1'] + '<b>' + feedback.requestedFeedbackNotesWithResponseCount + ' </b> ' + translations['monitoring.modals.PEER_REQ2'] + '</b><br>';
    }
    if (feedback) {
      if (peerFeedback && peerFeedback.length) {
        text += peerFeedback;
      }
      text += translations['monitoring.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseCount || '-') + '</b><br>';
      text += translations['monitoring.modals.INTERVIEW'] + ' <b>' + (feedback.createdFeedbackDialogueCount || '-') + '</b><br>';
      text += translations['monitoring.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceCount || '-') + '</b><br>';
    }
    return text;
  }

  goToProcessDetail(processYear: string) {
    this.router.navigate(['admin/monitoring/' + processYear])
  }

  openFeedbackDetailsModal(structureData: any) {
    this.structureForModal = structureData;
    this.modalService.open('feedbackDetails');
  }

  closeFeedbackDetailsModal() {
    this.modalService.close('feedbackDetails');
    this.structureForModal = null;
  }


  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.exportStructureReport(this.processYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  orderBy(fieldName: string) {
  }

  ngOnDestroy() {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    if (this.getProcessDetails$) {
      this.getProcessDetails$.unsubscribe();
    }
  }
}
