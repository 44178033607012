<div class="page-content-wrapper admin-wrapper">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [adminPageTitle]="('process.TITLE' | translate)"
        [adminPageSubtitle]="('process.LIST_SUBTITLE' | translate)" [adminBack]="true" [hasTabs]="showTabs()"
        [adminActionLabel]="('process.CREATE_NEW' | translate)" (onAdminAction)="goToNewProcess()"></header-dossier>

    <div class="main-content-wrapper admin">
        <!-- contenitore tabella -->
        <ng-container *ngIf="!isLoadingProcessData">
            <div class="admin-table-container">
                <div class="table-content process-structure">
                    <table (clickOutside)="closeAllMenus()">
                        <thead>
                            <tr>
                                <th translate="{{ ('adminTable.YEAR' | translate)}}"></th>
                                <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                                <!--th translate="{{ ('adminTable.OPEN_CLOSE' | translate)}}"></th>
                                <th translate="{{ ('adminTable.PHASE_HIGH' | translate)}}"></th-->
                                <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                                <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let process of processData.list | 
                            slice:0:processData.numRecords | 
                            paginate: { id: 'processList',
                                    itemsPerPage: processData.numRecords,
                                    currentPage: processData.page,
                                    totalItems: processData.counter }; let i = index ">
                                <td>{{ process.year }}</td>
                                <td>{{ process.title }}</td>
                                <td>
                                    <div class="count">
                                        {{ process.usersInGroupCount || 0 }}
                                    </div>
                                </td>
                                <td>
                                    <!-- Se processo in standby-->
                                    <ng-container *ngIf="process.statusType == 'STANDBY'">
                                        <item-list-team-status styleItem="border" type="inProgress"
                                            [message]="('process.create.STANDBY' | translate)">
                                        </item-list-team-status>
                                    </ng-container>
                                    <!-- Se processo attivo -->
                                    <ng-container
                                        *ngIf="process.statusType != 'STANDBY' && process.statusType != 'CLOSED'">
                                        <item-list-team-status styleItem="border" type="completed"
                                            [message]="('generic.ACTIVE' | translate)">
                                        </item-list-team-status>
                                    </ng-container>
                                    <!-- Se processo chiuso -->
                                    <ng-container *ngIf="process.statusType == 'CLOSED'">
                                        <item-list-team-status styleItem="border" type="disabled"
                                            [message]="('generic.CLOSED' | translate)">
                                        </item-list-team-status>
                                    </ng-container>
                                    <!--item-list-team-status type="inProgress"></item-list-team-status-->
                                </td>
                                <!--td class="link">
                                    <p (click)="goToProcessDetail(process.year)" translate="processSetup.ACCESS"></p>
                                </td-->
                                <td class="action">
                                    <svg-icon class="more-icon" [applyClass]="true" (click)="openMenu(process)"
                                        src="/assets/img/icons/more-horizontal.svg">
                                    </svg-icon>
                                    <ng-container *ngIf="process.isMenuOpen">
                                        <div class="dropdown-menu">
                                            <europ-dropdown-item (click)="onMenuOptionsClicked(option, process)"
                                                [ngClass]="{'last': last}" class="full-width"
                                                *ngFor="let option of menuOptions; let last = last" [item]="option">
                                            </europ-dropdown-item>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="bottom-actions">
                    <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </europ-select>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- loader -->
        <ng-container *ngIf="isLoadingProcessData">
            <div class="loader-container">
                <div class="loader spinner-big"></div>
            </div>
        </ng-container>
    </div>
</div>