<div class="page-content-wrapper admin-wrapper">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [adminPageTitle]="('process.TITLE' | translate)"
        [adminPageSubtitle]="processData && processData.title ? processData.title : ('process.PM' | translate)"
        [adminBack]="true" [hasTabs]="true" [menuButtons]="tabList" (onMenuButtonClicked)="changeCurrentTab($event)"
        [adminActionLabel]="('process.create.UPDATE' | translate)" (onAdminAction)="editProcess()"></header-dossier>
    <div class="main-content-wrapper">
        <!-- Tab partecipanti -->
        <ng-container *ngIf="currentTab == 'participant'">
            <admin-process-setup-participant [processData]="processData"></admin-process-setup-participant>
        </ng-container>
        <ng-container *ngIf="currentTab == 'definition'">
            <admin-process-setup-objectives></admin-process-setup-objectives>
        </ng-container>
        <ng-container *ngIf="currentTab == 'phases'">
            <admin-process-setup-phases></admin-process-setup-phases>
        </ng-container>
    </div>
</div>