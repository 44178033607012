<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="personDetails" (onBackFunction)="createUpdate(true)" [isBackButton]="true"
      [title]="'objectiveWizard.manager.OPTIONAL_SIDE_TITLE' | translate"
      [description]="'objectiveWizard.manager.OPTIONAL_SIDE_DESCR' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoading()">
        <div class="form-container-how">
          <!-- Titolo -->
          <input-container [isLoading]="isLoading()" [tooltipModalMessage]="titleTooltipModal"
            [isValued]="goal.title && goal.title.trim().length" [isObbligatory]="true"
            title="{{'objectiveWizard.CRUD.OBJECTIVE_TITLE' | translate}}">
            <europ-input [bindValue]="goal.title" (onModelChanged)="goalTitleChanged($event)"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_TITLE_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-input>
          </input-container>
          <!-- Descrizione -->
          <input-container [numberCharsTextArea]="charsGoalDescription" [isFromTextArea]="true"
            [isLoading]="isLoading()" [tooltipModalMessage]="descriptionTooltipModal"
            [isValued]="goal.description && goal.description.trim().length" [isObbligatory]="true"
            title="{{'goalSetting.createGoal.DESCRIPTION' | translate}}">
            <europ-text-area [bindValue]="goal.description" [maxlength]="600"
              (onModelChanged)="goalDescriptionChanged($event)"
              placeholder="{{'goalSetting.createGoal.DESCRIPTION_PLACEHOLDER_COLLABORATOR' | translate}}">
            </europ-text-area>
          </input-container>
          <!-- Data entro cui completare l'obiettivo -->
          <input-container [isLoading]="isLoading()" [isObbligatory]="true" [tooltipModalMessage]="deadlineTooltipModal"
            [isValued]="selectedDate" [isObbligatory]="true" title="{{'objectiveWizard.CRUD.DEADLINE' | translate}}">
            <europ-datepicker [value]="selectedDate" (dateChanged)="selectedDateChanged($event)">
            </europ-datepicker>
          </input-container>
          <!-- Attributo CARE -->
          <input-container [isLoading]="isLoading() || isFetchingCareOptions" [tooltipModalMessage]="careTooltipModal"
            [isValued]="goal.careTag" [isObbligatory]="true"
            title="{{'objectiveWizard.CRUD.OBJECTIVE_CARE' | translate}}">
            <europ-select (onSelectedItem)="changeCareTag($event)" [selectedItem]="goal.careTag" [items]="careOptions"
              [loadingData]="isFetchingCareOptions" [searchable]="true" bindLabel="title"
              placeholder="{{'goalSetting.createGoal.OBJECTIVE_CARE_PLACEHOLDER' | translate}}">
            </europ-select>
          </input-container>


          <!-- Aggiungi obiettivo -->
          <div class="button-container centered">
            <europ-button [disabled]="isLoading()" type="primary default" size="large"
              [label]="'goal.ADD_OBJECTIVES' | translate" (onButtonClicked)="createUpdate()">
            </europ-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoading()">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale di conferma -->
<modal id="confirm-modal" (onCancel)="closeConfirmModal()" (onClose)="closeConfirmModal()"
  (onConfirm)="closeConfirmModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content modalId="oobjm001" [title]="'objectiveWizard.manager.modals.OPTIONAL_CONFIRM_TITLE' | translate"
    [subtitle]="'objectiveWizard.manager.modals.OPTIONAL_CONFIRM_SUB' | translate"
    [text]="'objectiveWizard.manager.modals.OPTIONAL_CONFIRM_TEXT' | translate">
  </modal-text-content>
</modal>