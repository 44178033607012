import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, PerfCareAssessmentPhaseKeys, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import moment = require('moment-timezone');
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
    selector: 'admin-process-setup-phases',
    templateUrl: './processSetupPhases.component.html',
    styleUrls: ['./processSetupPhases.component.scss']
})
export class ProcessSetupPhasesComponent implements OnDestroy {
    phasesList: any[] = []; // TODO Non è PerfCareAssessmentPhase, cercare classe
    runningYear$: Subscription;
    combinedSelected$: Subscription;
    runningYear: number = 0;
    loggedUser: any;
    currentYear = new Date().getFullYear();

    processYear: string = '';

    isLoadingPhases: boolean = true;
    menuOptions: any[] = [];

    getPhasesData$: Subscription = new Subscription();
    modalPhase: any;
    phasesOrder = [PerfCareAssessmentPhaseKeys.GOAL_SETTING,
    PerfCareAssessmentPhaseKeys.DEVELOPMENT_PLAN,
    PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW,
    PerfCareAssessmentPhaseKeys.FINAL_EVALUATION,
    PerfCareAssessmentPhaseKeys.POST_CALIBRATION,
    PerfCareAssessmentPhaseKeys.TALENT_SCOUTING
    ]

    constructor(
        private store: Store<fromApp.AppState>,
        public translate: TranslateService,
        private router: Router,
        public redirectService: RedirectService,
        private authService: AuthService,
        private adminService: AdminService,
        public route: ActivatedRoute,
        public modalService: ModalService
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
        });
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
        this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
            .subscribe(
                ([loggedUser, runningPhase]) => {
                    if (runningPhase && loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        this.route.params
                            .subscribe(
                                (params: Params) => {
                                    this.processYear = params.processYear;
                                    this.getPhasesData();
                                })
                    }
                });
    }

    ngOnInit() {
        this.translate.get([
            'process.phases.ACTIVATE',
            'process.phases.DISABLE',
            'process.phases.DISABLE_PHASE_TITLE',
            'process.phases.DISABLE_PHASE_TEXT',
            'process.phases.ACTIVATE_PHASE_TITLE',
            'process.phases.ACTIVATE_PHASE_TEXT',
            'process.phases.PHASE_SETTING',
            'process.phases.SET_HIGHLIGHT',
        ]).subscribe((translations) => {

            this.menuOptions = [
                {
                    id: 'edit',
                    title: translations['process.phases.PHASE_SETTING'],
                    icon: '/assets/img/icons/settings.svg',
                },
                {
                    id: 'highlight',
                    title: translations['process.phases.SET_HIGHLIGHT'],
                    icon: '/assets/img/icons/pin.svg',
                },
                //{
                //    id: 'disable',
                //    title: translations['process.phases.DISABLE'],
                //    icon: '/assets/img/icons/arrow-right.svg',
                //}
            ]
        })
    }

    // Chiamata al servizio per il recupero delle fasi
    getPhasesData() {
        this.isLoadingPhases = true;
        if (this.getPhasesData$) {
            this.getPhasesData$.unsubscribe();
        }
        this.getPhasesData$ = this.adminService.getPerformanceAssessmentPhasesList(this.processYear)
            .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                    // Vedo se c'è la traduzione dell'errore
                    const messageObj: ApplicationModalMessage = {
                        modalId: "phd001",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else {
                    if (data.response?.length) {
                        this.phasesList = [];
                        for (let i = 0; i < this.phasesOrder.length; i++) {
                            let key = this.phasesOrder[i];
                            if (key == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW || key == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
                                let tmpPhases = data.response.filter((phase: any) => phase.phaseKey == key);
                                if (tmpPhases) {
                                    tmpPhases.forEach((element: any) => {
                                        let startDate;
                                        let endDate;
                                        if (element.isManagerPhase) {
                                            startDate = element.phaseStartDateForManager ? moment(element.phaseStartDateForManager).format('DD/MM/YYYY') : null;
                                            endDate = element.phaseEndDateForManager ? moment(element.phaseEndDateForManager).format('DD/MM/YYYY') : null;
                                        } else {
                                            startDate = element.phaseStartDateForUser ? moment(element.phaseStartDateForUser).format('DD/MM/YYYY') : null;;
                                            endDate = element.phaseEndDateForUser ? moment(element.phaseEndDateForUser).format('DD/MM/YYYY') : null;;
                                        }
                                        this.phasesList.push({
                                            ...element,
                                            phaseStartDate: startDate,
                                            phaseEndDate: endDate,
                                            phaseClosingDate: element.phaseClosingDate ? moment(element.phaseClosingDate).format('DD/MM/YYYY') : null
                                        });
                                    });
                                }
                            } else {
                                let tmpPhase = data.response.find((phase: any) => phase.phaseKey == key);
                                tmpPhase.phaseStartDate = tmpPhase.phaseStartDate ? moment(tmpPhase.phaseStartDate).format('DD/MM/YYYY') : null;
                                tmpPhase.phaseEndDate = tmpPhase.phaseEndDate ? moment(tmpPhase.phaseEndDate).format('DD/MM/YYYY') : null;
                                tmpPhase.phaseClosingDate = tmpPhase.phaseClosingDate ? moment(tmpPhase.phaseClosingDate).format('DD/MM/YYYY') : null;
                                this.phasesList.push(tmpPhase);
                            }
                        }
                    } else {
                        this.phasesList = [];
                    }
                }
                this.isLoadingPhases = false;
            }, (err) => {
                // Vedo se c'è la traduzione dell'errore
                const messageObj: ApplicationModalMessage = {
                    modalId: "phd002",
                    text: this.translate.instant("errors." + ((err && err.message) || err)),
                    title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingPhases = false;
            })
    }

    // menu fase
    // dalla tabella apre il menu con le azioni 
    openCloseMenu(phase: any) {
        this.closeAllMenus();
        phase.isMenuOpen = !phase.isMenuOpen;
    }

    // Chiude i menù di tutte le fasi se clicco fuori
    closeAllMenus() {
        if (this.phasesList?.length) {
            this.phasesList.forEach((phase) => {
                phase.isMenuOpen = false;
            })
        }
    }

    // gestisce le opzioni del menu utente
    onMenuOptionsClicked(options: any, phase: any) {
        phase.isMenuOpen = false;
        if (options.id == 'edit') {
            if (phase.isManagerPhase) {
                this.router.navigate(['admin/processSetup/phaseDefinition/' + this.processYear + '/editDate/' + phase.phaseKey + '/manager']);
            } else {
                this.router.navigate(['admin/processSetup/phaseDefinition/' + this.processYear + '/editDate/' + phase.phaseKey]);
            }
        } else if (options.id == 'highlight') {
            this.modalPhase = phase;
            this.openHighlightModal();
        }
    }

    // apre la modale di modifica della fase in highlight
    openHighlightModal() {
        this.modalService.open('highlight-phase');
    }

    // chiude (e conferma) la modale di modifica della fase in highlight
    closeHighlightModal(confirm?: boolean) {
        this.modalService.close('highlight-phase');
        if (confirm) {
            this.adminService.setPhaseAsHighlighted(this.processYear, this.modalPhase.phaseKey)
                .subscribe((data) => {
                    if (data && data.error) {
                        // Vedo se c'è la traduzione dell'errore
                        const messageObj: ApplicationModalMessage = {
                            modalId: "phd001",
                            text: this.translate.instant("errors." + data.error),
                            title: this.translate.instant("generic.WARNING")
                        }
                        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    }
                    this.getPhasesData();
                }, (err) => {
                    // Vedo se c'è la traduzione dell'errore
                    const messageObj: ApplicationModalMessage = {
                        modalId: "phd002",
                        text: this.translate.instant("errors." + ((err && err.message) || err)),
                        title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    this.isLoadingPhases = false;
                })
        }
    }


    orderBy(fieldName: string) {
    }

    // Apre la modale di informazioni sulle date delle fasi per il manager
    openManagerPhaseInfoModal(phase: any) {
        let messageObj: ApplicationModalMessage;
        if (phase && phase.phaseKey == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW) {
            // Vedo se c'è la traduzione dell'errore
            messageObj = {
                modalId: "ompim001",
                title: this.translate.instant('process.phases.modals.TITLE'),
                subtitle: this.translate.instant('process.phases.modals.MTR_TITLE'),
                text: this.translate.instant("process.phases.modals.MTR_TEXT")
            }
        } else {
            messageObj = {
                modalId: "ompim002",
                title: this.translate.instant('process.phases.modals.TITLE'),
                subtitle: this.translate.instant('process.phases.modals.FE_TITLE'),
                text: this.translate.instant("process.phases.modals.FE_TEXT")
            }
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }

    ngOnDestroy() {
    }
}