import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  GetNoteForIdpTalentesponse,
  GetTalentDevelopmentGoalResponse,
  GetTalentIndividualDevelopmentPlanResponse,
  GetTalentRoleGoalResponse,
  GetUserForTalentInterviewResponse,
  GetUserTalentHrInterviewQuestionsResponse,
  GetUserTalentPotentialQuestionsResponse,
  ListEvaluatorUsersForTalentInterviewResponse,
  ListTalentIndividualDevelopmentPlanResponse,
  PerformanceCareTalent,
  SenecaResponse,
  UpdateTalentInterviewQuestionsByYearResponse,
} from 'atfcore-commonclasses';
import { Observable } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';


@Injectable({
  providedIn: 'root',
})
export class PotentialTalentsService {
  appData$: Observable<any>;

  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>
  ) {
    this.appData$ = this.store
      .select(fromApp.getGlobalApplicationData)
      .pipe(pluck('applicationContext'));
  }

  getTalentEvaluation(
    param: PerformanceCareTalent.GetTalentEvaluationByYearForAdmin
  ): Observable<SenecaResponse<GetUserTalentPotentialQuestionsResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<GetUserTalentPotentialQuestionsResponse>>(
          `${appContext}rest-api/performancecare-mediator/get-talent-evaluation-by-year-for-admin/`,
          {
            params: {
              perfCareYear: param.perfCareYear.toString(),
              userId: param.userId,
            },
          }
        );
      })
    );
  }


  getTalentDetails(
    userId: PerformanceCareTalent.GetUserForTalentInterview['userId'],
    perfCareYear: PerformanceCareTalent.GetUserForTalentInterview['perfCareYear']
  ): Observable<SenecaResponse<GetUserForTalentInterviewResponse>> {
    const endpoint = 'rest-api/performancecare-mediator/get-user-for-talent-interview/';

    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<any>>(
          `${appContext}${endpoint}`,
          {
            params: {
              perfCareYear: perfCareYear.toString(),
              userId
            },
          }
        );
      })
    );

  }


  // Returns a boolean indicating whether the user has completed the interview and it's closed.
  isInterviewDone(
    userId: PerformanceCareTalent.IsTalentInterviewQuestionDone['userId'],
    perfCareYear: PerformanceCareTalent.IsTalentInterviewQuestionDone['perfCareYear']
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/is-talent-interview-question-done/`,
          {
            params: {
              perfCareYear: perfCareYear.toString(),
              userId: userId,
            },
          }
        );
      })
    );
  }


  getHRInterviewQuestions(
    userId: PerformanceCareTalent.GetUserTalentHrInterviewQuestions['userId'],
    perfCareYear: PerformanceCareTalent.GetUserTalentHrInterviewQuestions['perfCareYear']
  ): Observable<SenecaResponse<GetUserTalentHrInterviewQuestionsResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<any>>(
          `${appContext}rest-api/performancecare-mediator/get-hr-talent-interview-questions/`,
          {
            params: {
              perfCareYear: perfCareYear.toString(),
              userId: userId,
            }
          }
        );
      })
    );
  }



  updateInterviewAnswers(
    data: PerformanceCareTalent.UpdateTalentInterviewQuestionsByYear
  ): Observable<SenecaResponse<UpdateTalentInterviewQuestionsByYearResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<UpdateTalentInterviewQuestionsByYearResponse>>(
          `${appContext}rest-api/performancecare-mediator/update-talent-interview-questions-by-year/`,
          data
        );
      })
    );
  }


  getInterviewEvaluators(
    searchedText: PerformanceCareTalent.ListEvaluatorUsersForTalentInterview['searchedText'] = '',
    numRecords: PerformanceCareTalent.ListEvaluatorUsersForTalentInterview['numRecords'] = 50,
    fromRecord: PerformanceCareTalent.ListEvaluatorUsersForTalentInterview['fromRecord'] = 0
  ): Observable<SenecaResponse<ListEvaluatorUsersForTalentInterviewResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<any>>(
          `${appContext}rest-api/performancecare-mediator/list-evaluator-users-for-talent-interview/`, {
          params: {
            fromRecord: fromRecord.toString(),
            numRecords: numRecords.toString(),
            searchedText: searchedText
          }
        }
        );
      })
    );
  }


  listIndividualDevelopmentPlan(
    userId: PerformanceCareTalent.ListTalentIndividualDevelopmentPlan['userId'],
    perfCareYear: PerformanceCareTalent.ListTalentIndividualDevelopmentPlan['perfCareYear']
  ): Observable<SenecaResponse<ListTalentIndividualDevelopmentPlanResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<ListTalentIndividualDevelopmentPlanResponse>>(
          `${appContext}rest-api/performancecare-mediator/list-talent-individual-development-plan`,
          {
            params: {
              perfCareYear: perfCareYear.toString(),
              userId: userId,
            }
          }
        );
      })
    );
  }

  createIndiviadualDevelopmentPlan(
    data: PerformanceCareTalent.CreateTalentIndividualDevelopmentPlan
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/create-talent-individual-development-plan`,
          data
        );
      })
    );
  }

  updateIndividualDevelopmentPlan(
    payload: PerformanceCareTalent.UpdateTalentIndividualDevelopmentPlan
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/update-talent-individual-development-plan`,
          payload
        );
      })
    );
  }


  deleteIndividualDevelopmentPlan(
    payload: PerformanceCareTalent.DeleteTalentIndividualDevelopmentPlan
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/delete-talent-individual-development-plan`,
          payload
        );
      })
    );
  }


  updateTalentRoleGoal(
    data: PerformanceCareTalent.UpdateTalentRoleGoal
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/update-talent-role-goal`,
          data
        );
      })
    );
  }

  createTalentRoleGoal(
    data: PerformanceCareTalent.CreateTalentRoleGoal
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/create-talent-role-goal`,
          data
        );
      })
    );
  }

  getTalentRoleGoal(
    data: PerformanceCareTalent.GetTalentRoleGoal
  ): Observable<SenecaResponse<GetTalentRoleGoalResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<GetTalentRoleGoalResponse>>(
          `${appContext}rest-api/performancecare-mediator/get-talent-role-goal`,
          {
            params: {
              perfCareYear: data.perfCareYear.toString(),
              userId: data.userId,
            }
          }
        );
      })
    );
  }


  updateTalentDevelopmentGoal(
    data: PerformanceCareTalent.UpdateTalentDevelopmentGoal
  ) {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/update-talent-development-goal`,
          data
        );
      })
    );
  }

  createTalentDevelopmentGoal(
    data: PerformanceCareTalent.CreateTalentDevelopmentGoal
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/create-talent-development-goal`,
          data
        );
      })
    );
  }


  getTalentDevelopmentGoal(
    data: PerformanceCareTalent.GetTalentDevelopmentGoal
  ) {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<GetTalentDevelopmentGoalResponse>>(
          `${appContext}rest-api/performancecare-mediator/get-talent-development-goal`,
          {
            params: {
              perfCareYear: data.perfCareYear.toString(),
              userId: data.userId,
            }
          }
        );
      })
    );
  }


  createNoteForIdp(data: PerformanceCareTalent.CreateNoteForIdpTalent): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/create-note-for-idp-talent`,
          data
        );
      })
    );
  }


  getNoteByIdp(
    noteId: PerformanceCareTalent.GetNoteForIdpTalent['noteId'],
    perfCareYear: PerformanceCareTalent.GetNoteForIdpTalent['perfCareYear']
  ): Observable<SenecaResponse<GetNoteForIdpTalentesponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<GetNoteForIdpTalentesponse>>(
          `${appContext}rest-api/performancecare-mediator/get-note-for-idp-talent`,
          {
            params: {
              noteId,
              perfCareYear: perfCareYear.toString()
            }
          }
        );
      })
    );
  }

  deleteNoteByIdp(
    payload: PerformanceCareTalent.DeleteNoteForIdpTalent
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/delete-note-for-idp-talent`,
          payload
        );
      })
    );
  }


  updateNoteByIdp(
    data: PerformanceCareTalent.UpdateNoteForIdpTalent
  ): Observable<SenecaResponse<boolean>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.post<SenecaResponse<boolean>>(
          `${appContext}rest-api/performancecare-mediator/update-note-for-idp-talent`,
          data
        );
      })
    );
  }


  getIndividualDevelopmentPlan(
    payload: PerformanceCareTalent.GetTalentIndividualDevelopmentPlan
  ): Observable<SenecaResponse<GetTalentIndividualDevelopmentPlanResponse>> {
    return this.appData$.pipe(
      switchMap((appContext) => {
        return this.http.get<SenecaResponse<GetTalentIndividualDevelopmentPlanResponse>>(
          `${appContext}rest-api/performancecare-mediator/get-talent-individual-development-plan`,
          {
            params: {
              perfCareYear: payload.perfCareYear.toString(),
              userId: payload.userId,
              rentId: payload.rentId
            }
          }
        );
      })
    );
  }


}
