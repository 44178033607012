<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
      [title]="'speed.SIDENAV_TITLE' | translate" [description]="'speed.SIDENAV_DESCR' | translate">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <!-- contenuto pagina -->
      <ng-container *ngIf="!isLoadingUserIds">
        <div class="content-wrapper">
          <div class="title-step-container">
            <p> {{ ('speed.CANDIDATE' | translate) + ' ' + (currentUserNumber + 1) + ' ' +
              ('speed.OF' | translate) + ' ' + userIdsNumber}} </p>
          </div>
          <div class="progress-bar-container">
            <div class="progress-bar-container">
              <div class="progress" [ngStyle]="{ 'width': currentPercentage + '%'}">
                <!-- [ngClass]="{'completed': isComplete()}"-->
              </div>
            </div>
          </div>

          <ng-container *ngIf="!isLoadingUserQuestions">

            <div class="user-info-container" *ngIf="selectedUser">
              <avatar-img size="medium" [user]="selectedUser"></avatar-img>
              <div class="section">
                <p class="title">{{ selectedUser.surname + ' ' + selectedUser.forename}}</p>
                <p class="text">{{ selectedUser.denominazioneSO3}}</p>
              </div>
              <div class="section">
                <p class="title">{{ 'speed.AGE' | translate }}</p>
                <p class="text">{{ selectedUser.age}}</p>
              </div>
              <div class="section">
                <p class="title">{{ 'speed.ENGLISH' | translate }}</p>
                <p class="text">{{ selectedUser.englishLevel ? (
                  ('talent.LEVEL' | translate) + ' ' + selectedUser.englishLevel) :
                  ('talent.NOT_DEFINED' |
                  translate) }}</p>
              </div>
              <!-- Idoneo -->
              <ng-container *ngIf="selectedUser.status == 'ELIGIBLE'">
                <item-list-team-status styleItem="border noMinWidth" type="completed" [showPoint]="false"
                  [message]=" 'talent.status.ELIGIBLE' | translate ">
                </item-list-team-status>
              </ng-container>
              <!-- Da decidere -->
              <ng-container *ngIf="selectedUser.status == 'TO_DECIDE'">
                <item-list-team-status styleItem="border noMinWidth" type="inProgress" [showPoint]="false"
                  [message]=" 'talent.status.TO_DECIDE' | translate ">
                </item-list-team-status>
              </ng-container>
              <!-- Non idoneo -->
              <ng-container *ngIf="selectedUser.status == 'NOT_ELIGIBLE'">
                <item-list-team-status styleItem="border noMinWidth" type="inProgress" [showPoint]="false"
                  [message]=" 'talent.status.NOT_ELIGIBLE' | translate ">
                </item-list-team-status>
              </ng-container>
            </div>


            <!-- Domande Speed screening -->
            <ng-container *ngIf="questionList && questionList.length">
              <ng-container *ngFor="let question of questionList; let index = index">
                <input-container [isValued]="question.selectedAnswer" [title]="question.text"
                  [isWithoutInfoIcon]="true">
                  <europ-radio [items]="question.answersList" [bindValue]="question.selectedAnswer"
                    (onItemChange)="selectAnswer($event, index)" radioName="choice">
                  </europ-radio>
                </input-container>
              </ng-container>
            </ng-container>


            <!--  Inserisci note -->
            <input-container [numberCharsTextArea]="600" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
              [isValued]="userNote && userNote.trim().length" [title]="'speed.NOTES' | translate">
              <europ-text-area [bindValue]="userNote" [maxlength]="600" (onModelChanged)="userNoteChanged($event)"
                [placeholder]="'talent.modals.INSERT_NOTE_INPUT_PLACEHOLDER' | translate">
              </europ-text-area>
            </input-container>
            <!-- Upload documento -->
            <input-container [isWithoutInfoIcon]="true" [isValued]="fileObject"
              [title]="'talent.modals.INSERT_NOTE_DOC' | translate">
              <europ-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"></europ-upload>
            </input-container>

            <div class="eligible-container">
              <div class="label-wrapper">
                <p translate="speed.CANDIDATE_NEXT"></p>
                <svg-icon src="/assets/img/icons/info.svg" class="info" [applyClass]="true"
                  (click)="openNextStepTooltip()">
                </svg-icon>
              </div>
              <card-talent [bindValue]="selectedCardId" (onItemChange)="selectTalentCard($event)"></card-talent>
            </div>

            <div class="talent-action-container">
              <div class="navigation-row">
                <!--  <europ-button [disabled]="isLoadingUserQuestions && userIds[currentUserNumber - 1]" type="primary link"
                  [label]="'speed.PREV' | translate" (onButtonClicked)="prevUser()"
                  iconLeft="assets/img/icons/arrow-left.svg">
                </europ-button>-->

                <!-- <europ-button [disabled]="isLoadingUserQuestions && userIds[currentUserNumber + 1]" type="primary link"
                  [label]="'speed.NEXT' | translate" (onButtonClicked)="nextUser()"
                  iconRight="assets/img/icons/arrow-right.svg">
                </europ-button> -->
              </div>
              <div class="action-row">
                <europ-button [disabled]="isLoadingUserQuestions" type="primary"
                  [label]="'speed.CLOSE_SAVE' | translate" (onButtonClicked)="openCloseSaveModal(true)">
                </europ-button>
              </div>
            </div>

          </ng-container>

          <ng-container *ngIf="isLoadingUserQuestions">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingUserIds">
        <div class="loader spinner-medium"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<modal id="next-step-tooltip" (onCancel)="closeNextStepTooltip()" (onClose)="closeNextStepTooltip()"
  (onConfirm)="closeNextStepTooltip()" [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content modalId="nst001" [title]="'speed.modals.NEXT_STEP_TITLE' | translate"
    [subtitle]="'speed.modals.NEXT_STEP_SUBTITLE' | translate" [text]="'speed.modals.NEXT_STEP_DESCR' | translate">
  </modal-text-content>
</modal>

<!--modal id="next-step-tooltip" (onCancel)="closeNextStepTooltip()" (onClose)="closeNextStepTooltip()"
  (onConfirm)="closeNextStepTooltip()" [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content modalId="nst001" [title]="'speed.modals.NEXT_STEP_TITLE' | translate"
    [subtitle]="'speed.modals.NEXT_STEP_SUBTITLE' | translate" [text]="'speed.modals.NEXT_STEP_DESCR' | translate">
  </modal-text-content>
</modal-->

<modal id="close-save" (onCancel)="closeSaveModal()" (onClose)="closeSaveModal()"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeSaveModal(true)"
  [confirmLabel]="'speed.CLOSE_SAVE' | translate">
  <modal-text-content modalId="nst001" [title]="'speed.CLOSE_SAVE' | translate"
    [subtitle]="'speed.modals.NEXT_STEP_SUBTITLE' | translate" [text]="'speed.modals.NEXT_STEP_DESCR' | translate">
  </modal-text-content>
</modal>