import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfCareCareAttributesAreaTypes, PerfCareUserTalentPhaseStatuses } from 'src/commonclasses';


export class CardElement {
  id: string = '';
  icon: string = '';
  title: string = '';
}

@Component({
  selector: 'card-talent',
  templateUrl: 'card-talent.component.html',
  styleUrls: ['./card-talent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTalentComponent implements OnInit {
  items: CardElement[] = [
    {
      id: PerfCareUserTalentPhaseStatuses.NOT_ELIGIBLE,
      icon: '/assets/img/icons/x.svg',
      title: 'talent.status.CARD_NOT_ELIGIBLE'
    },
    {
      id: PerfCareUserTalentPhaseStatuses.TO_DECIDE,
      icon: '/assets/img/icons/help-circle.svg',
      title: 'talent.status.TO_DECIDE'
    },
    {
      id: PerfCareUserTalentPhaseStatuses.ELIGIBLE,
      icon: '/assets/img/icons/check-circle.svg',
      title: 'talent.status.CARD_ELIGIBLE'
    },
  ]
  @Input() bindValue: string = '';
  @Output() onItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  setValue(item: any) {
    this.bindValue = item.id;
    this.cdr.detectChanges();
    this.emitOnItemChange();
  }


  emitOnItemChange() {
    this.onItemChange.emit(this.bindValue);
  }

}