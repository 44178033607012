import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, GoalEvaluationScore, EnrichedSenecaResponseForBadges, PerfCareMacroGoalTypes } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { Subordinateservice } from '../services/subordinates.service';

@Component({
  selector: 'manager-finalEvaluation',
  templateUrl: './finalEvaluation.component.html',
  styleUrls: ['./finalEvaluation.component.scss']
})
export class FinalEvaluationManagerComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  getUserGoalData$: Subscription = new Subscription();
  runningYear: number = 0;
  loggedUser: any;
  finalObservationTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  cardTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isLoadingUserGoals: boolean = false;
  goalData: any;

  careCards: any[] = [];
  cardSelected: any;

  finalObservation: string = '';
  isOperations: boolean = false;
  goalId: string = '';
  userId: string = '';
  loggedUser$: Subscription = new Subscription;
  personDetails$: Subscription = new Subscription;
  managerData: any;
  personDetails: any;
  isLoadingPersonDetails: boolean = true;
  getPersonDetails$: any;
  exitLoseDataModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  confirmSetEvaluation: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  setFinalEvaluation$: any;
  selectedYear!: { id: any; name: string; };
  isOperationsSpecificGoal: boolean = false;

  constructor(
    public translate: TranslateService,
    public managerService: ManagerService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private subordinatesService: Subordinateservice
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {

              this.userId = params.userId;
              this.goalId = params.goalId;

              if (params.perfCareYear) {
                this.selectedYear = {
                  id: params.perfCareYear,
                  name: 'Anno ' + params.perfCareYear
                }
              } else {
                this.selectedYear = {
                  id: this.runningYear,
                  name: 'Anno ' + this.runningYear
                }
              }

              this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                this.managerData = loggedUser.user;
              })
              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];

                // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                if (!this.personDetails) {
                  this.getPersonDetails();
                }
                // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                if (this.personDetails && this.personDetails.userId) {
                  this.isLoadingPersonDetails = false;
                  this.getGoalData();
                }
              });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'final.WHAT_OBSERVE',
        'final.WHAT_OBSERVE_INFO',
        'final.CHOOSE_VALUE',
        'final.CHOOSE_VALUE_SUB',
        'final.SELF_OBS',
        'final.modals.EXIT_BACK_TITLE',
        'final.modals.EXIT_BACK_DESCR',
        'final.modals.SET_EVALUATION_TITLE',
        'final.modals.SET_EVALUATION_DESCR',
        'threeStepEvaluation.NOT_ACHIEVED',
        'threeStepEvaluation.ACHIEVED',
        'threeStepEvaluation.BEYOND_EXPECTATIONS'
      ])
      .subscribe(translations => {
        this.finalObservationTooltip = {
          modalId: "vf003",
          title: translations['final.WHAT_OBSERVE'],
          text: translations['final.WHAT_OBSERVE_INFO'],
        };

        this.cardTooltip = {
          modalId: "vf004",
          title: translations['final.SELF_OBS'],
          subtitle: translations['final.CHOOSE_VALUE_SUB'],
          text: translations['final.WHAT_OBSERVE_INFO'],
        }

        this.exitLoseDataModal = {
          modalId: 'pl005',
          title: translations['final.modals.EXIT_BACK_TITLE'],
          text: translations['final.modals.EXIT_BACK_DESCR']
        };

        this.confirmSetEvaluation = {
          modalId: 'pl007',
          title: translations['final.modals.SET_EVALUATION_TITLE'],
          text: translations['final.modals.SET_EVALUATION_DESCR']
        };

        this.careCards = [{
          careId: '',
          id: GoalEvaluationScore.NOT_ACHIEVED,
          title: translations['threeStepEvaluation.NOT_ACHIEVED'],
          isChecked: false,
          icon: "assets/img/icons/center-red.svg"
        },
        {
          careId: '',
          id: GoalEvaluationScore.ACHIEVED,
          title: translations['threeStepEvaluation.ACHIEVED'],
          isChecked: false,
          icon: "assets/img/icons/center-green.svg"
        },
        {
          careId: '',
          id: GoalEvaluationScore.BEYOND_EXPECTATIONS,
          title: translations['threeStepEvaluation.BEYOND_EXPECTATIONS'],
          isChecked: false,
          icon: "assets/img/icons/center-blue.svg"
        }]
      })
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return this.finalObservation && this.finalObservation.trim().length && this.cardSelected;
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return this.cardSelected || (this.finalObservation && this.finalObservation.trim().length);
  }

  setFinalEvaluation() {
    // this.goBackBrowser();
    this.isLoadingUserGoals = true;
    if (this.setFinalEvaluation$) {
      this.setFinalEvaluation$.unsubscribe();
    }

    this.setFinalEvaluation$ = this.managerService.setObservationForSubordinateGoal(this.selectedYear.id, this.userId, this.goalId, this.finalObservation, this.isOperationsSpecificGoal ? null : this.cardSelected.id)
      .subscribe((data: EnrichedSenecaResponseForBadges<any>) => {
        if (data.error || !data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pl008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "fe003",
            title: this.translate.instant('final.TITLE'),
            text: this.translate.instant('final.modals.CORRECTLY_SET'),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          sessionStorage.setItem(this.goalId, JSON.stringify({
            user: this.loggedUser,
            userValue: !this.isOperationsSpecificGoal ? this.cardSelected.id : null,
            userObservation: this.finalObservation
          }));

          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
        }
        this.goBackBrowser();
        this.isLoadingUserGoals = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pl006",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUserGoals = false;
      });
  }

  sendRevisionAction(isBack?: boolean) {
    if (isBack && !this.isObbligatoryDataInserted()) {
      if (this.checkIfDataIsChanged()) {
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      } else {
        this.goBackBrowser();
      }
    } else {
      if (isBack) {
        if (this.checkIfDataIsChanged()) {
          this.modalService.open("modalCreateRevisionNoDataForCollaborator");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modalCreateRevisionNoDataForCollaborator");
      }
    }
  }

  setValueAction(isBack?: boolean) {
    if (isBack && !this.isObbligatoryDataInserted()) {
      this.goBackBrowser();
    } else {
      if (isBack) {
        if (this.isObbligatoryDataInserted()) {
          this.modalService.open("modalExitLoseData");
        } else {
          this.goBackBrowser();
        }
      } else {
        this.modalService.open("modalSetObservation");
      }
    }
  }

  closeExitModal(goBack?: boolean) {
    this.modalService.close("modalExitLoseData");
    if (goBack) {
      this.goBackBrowser();
    }
  }

  closeSetObservation(confirm?: boolean) {
    this.modalService.close('modalSetObservation');
    if (confirm) {
      this.setFinalEvaluation();
    }
  }

  finalObservationChanged(text: string) {
    this.finalObservation = text;
  }

  onSelectCareCard(cards: any) {
    this.cardSelected = cards.filter((x: any) => {
      return x.isChecked == true;
    })[0];
  }

  getGoalData() {
    this.isLoadingUserGoals = true;
    if (this.getUserGoalData$) {
      this.getUserGoalData$.unsubscribe();
    }

    this.getUserGoalData$ = this.managerService.getGoalByIdForManager(this.selectedYear.id.toString(), this.goalId, this.personDetails.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "092",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else if (data && data.response) {
          if (data.response.macroGoalType == PerfCareMacroGoalTypes.OPERATIONS_KPI || data.response.macroGoalType == PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES) {
            this.isOperationsSpecificGoal = true;
          }
          this.goalData = GoalUtils.formatGoalCardData(this.translate, data.response, true, this.userId);
        }
        this.isLoadingUserGoals = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "093",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
          this.isLoadingUserGoals = false;
        })
  }

  openCardTooltip() {
    this.modalService.open('cardTooltip');
  }

  closeCardTooltip() {
    this.modalService.close('cardTooltip');
  }

  getPersonDetails() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.selectedYear.id)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.isLoadingPersonDetails = false;
          this.getGoalData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "091",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getUserGoalData$) {
      this.getUserGoalData$.unsubscribe();
    }
    if (this.setFinalEvaluation$) {
      this.setFinalEvaluation$.unsubscribe();
    }
  }
}