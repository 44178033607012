<div class="box-container" (click)="emitOnButtonClicked()" [ngClass]="{'disabled': isDisabled }">
  <div class="img-content" style='background-image:url({{imgPath}})'></div>
  <div class="info-container" ngClass.sm="mobile" ngClass.xs="mobile" ngClass.xxs="mobile">
    <div class="info">
      <!-- Lato sinistro manager -->
      <ng-container>
        <p class="title-text">{{title}}</p>
        <p class="description">{{ description }}</p>
        <item-list-team-status class="margin-top10" styleItem="border" type="toStart" [message]="statusMessage">
        </item-list-team-status>
      </ng-container>
    </div>
  </div>

  <div class="counter-wrapper" fxHide fxShow.gt-sm>
    <ng-container *ngIf="isManagerBox">
      <div class="counter-cell">
        <p class="value">{{ talentData.contributorWithRequirement }}</p>
        <p class="description" translate="talent.BOX_COUNTER_REQ"></p>
      </div>
      <div class="counter-cell">
        <p class="value">{{ talentData.candidate }}</p>
        <p class="description" translate="talent.BOX_COUNTER_EVAL"></p>
      </div>
    </ng-container>
    <ng-container *ngIf="!isManagerBox">
      <div class="counter-cell">
        <p class="value">{{ talentData.subordinateManagersWithTalentCompleted + '/' +
          talentData.totalSubordinateManagers}}</p>
        <p class="description" translate="talent.BOX_COUNTER_MAN"></p>
      </div>
      <div class="counter-cell">
        <p class="value">{{ talentData.subordinatesToEvaluate }}</p>
        <p class="description" translate="talent.BOX_COUNTER_TO_EVAL"></p>
      </div>
    </ng-container>
  </div>
</div>