import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfCareGoalRentStatuses, PerfCareMacroGoalTypes } from 'atfcore-commonclasses';
import moment = require('moment-timezone');


const DEV_OPE_ADD_EVIDENCE: string[] = [
  PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE,
  PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED,
]

@Component({
  selector: 'goal-card',
  templateUrl: 'goal-card.component.html',
  styleUrls: ['./goal-card.component.scss']
})
export class GoalCardComponent implements OnInit {
  @Input() goal?: any;
  @Input() attrAriaLabel: string = '';
  @Input() isInnerHtml: boolean = false;
  @Input() isCollaborator?: boolean;
  @Input() proposalStatus: string = '';
  @Input() menuButtonDisabled: boolean = false;
  @Input() loggedUser?: any;
  @Input() isOperations: boolean = false;
  @Input() isStructure: boolean = false;
  @Input() showFinalEvaluation: boolean = false;
  @Input() hideOptions: boolean = false;
  @Output() onDetailClicked: EventEmitter<any> = new EventEmitter();
  @Output() finalEvaluationClicked: EventEmitter<any> = new EventEmitter();
  @Output() onOptionClicked: EventEmitter<any> = new EventEmitter();
  descriptionIsOpened: Boolean = false;
  evaluationMap = {
    'NO_ACHIEVED': 1,
    'ACHIEVED': 2,
    'EXCEEDED': 3,
  }
  isCompactView: boolean = false;
  isMenuOpen: boolean = false;
  menuOptions: { id: string; title: any; icon: string, disabled: boolean }[] = [];
  translations: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth <= 600) {
      this.isCompactView = true;
    } else {
      this.isCompactView = false;
    }
  }
  today = moment().format('dd/mm/yyyy');

  constructor(
    private translate: TranslateService
  ) {

    if (window.innerWidth <= 600) {
      this.isCompactView = true;
    } else {
      this.isCompactView = false;
    }
  }

  ngOnInit() {
    this.translate.get([
      "cardOptions.EDIT",
      "cardOptions.DELETE",
      "cardOptions.ADD_EVIDENCE",
      "cardOptions.LINK_OBJECTIVE",
      "cardOptions.VALUE",
      "cardOptions.APPROVE_WITH_COMMENT",
    ]).subscribe((translations: any) => {
      this.translations = translations;
    })
    if (this.goal && this.goal.type && this.goal.type == "STRUCTURE") {
      this.isStructure = true;
    }
    if (this.goal && [PerfCareMacroGoalTypes.COMPANY, PerfCareMacroGoalTypes.STRUCTURE].includes(this.goal.type)) {
      this.hideOptions = true;
    }
  }

  getStructureGoalDescription() {
    let descr = '-';
    if (this.goal && this.goal.description && this.goal.description.length) {
      descr = this.goal.description.replace(/\n/g, '<br>');
    }
    return descr;
  }


  emitOnDetailClicked(data?: string) {
    this.onDetailClicked.emit(data);
  }

  onFinalEvaluationClicked(data: any) {
    this.finalEvaluationClicked.emit(data);
  }

  // Apri/Chiudi menu dropdown con le azioni
  toggleMenu() {
    this.menuOptions = [];
    if (this.goal && [PerfCareMacroGoalTypes.COMPANY, PerfCareMacroGoalTypes.STRUCTURE].includes(this.goal.type)) {
      this.hideOptions = true;
    } else if (this.goal && this.goal.type == PerfCareMacroGoalTypes.PEOPLE_EMPOWERMENT) {
      this.menuOptions = [
        {
          id: 'edit',
          title: this.translations['cardOptions.EDIT'],
          icon: 'assets/img/icons/edit.svg',
          disabled: this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED || !this.goal.isGoalSettingsPhaseOpen
        },
        {
          id: 'add_evidence',
          title: this.translations['cardOptions.ADD_EVIDENCE'],
          icon: 'assets/img/icons/message-circle.svg',
          disabled: false
        },
        {
          id: 'value',
          title: this.translations['cardOptions.VALUE'],
          icon: 'assets/img/icons/award.svg',
          disabled: ![PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE].includes(this.goal.goalStatus)
        }]
    } else if (this.isCollaborator) {
      if (!this.isOperations) {
        if (this.proposalStatus != 'PROPOSED' && this.loggedUser?.userId == this.goal?.creationUserId) {
          if (this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT) {
            this.menuOptions = [
              {
                id: 'edit',
                title: this.translations['cardOptions.EDIT'],
                icon: 'assets/img/icons/edit.svg',
                disabled: this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED || !this.goal.isGoalSettingsPhaseOpen
              }
            ]
          } else {
            this.menuOptions = [
              {
                id: 'edit',
                title: this.translations['cardOptions.EDIT'],
                icon: 'assets/img/icons/edit.svg',
                disabled: this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED || !this.goal.isGoalSettingsPhaseOpen
              },
              {
                id: 'delete',
                title: this.translations['cardOptions.DELETE'],
                icon: 'assets/img/icons/trash-2.svg',
                disabled: this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED || !this.goal.isGoalSettingsPhaseOpen
              }
            ]
          }
        }
        if (this.proposalStatus == 'APPROVED') {
          this.menuOptions.push(
            {
              id: 'add_evidence',
              title: this.translations['cardOptions.ADD_EVIDENCE'],
              icon: 'assets/img/icons/message-circle.svg',
              disabled: false
            },
            {
              id: 'value',
              title: this.translations['cardOptions.VALUE'],
              icon: 'assets/img/icons/award.svg',
              disabled: ![PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE].includes(this.goal.goalStatus)
            }
          )
        }
      } else {
        // se è operations
        this.menuOptions.push(
          {
            id: 'add_evidence',
            title: this.translations['cardOptions.ADD_EVIDENCE'],
            icon: 'assets/img/icons/message-circle.svg',
            disabled: this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS ? (!DEV_OPE_ADD_EVIDENCE.includes(this.goal.goalStatus) ? true : false) : false
          },
          {
            id: 'value',
            title: this.translations['cardOptions.VALUE'],
            icon: 'assets/img/icons/award.svg',
            disabled: ![PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE].includes(this.goal.goalStatus)
          }
        )
      }
    } else {
      this.menuOptions = [];
      // Se è un obiettivo senza peso creato dal manager allora posso modificarlo
      if (this.goal && this.goal.id && this.loggedUser && this.loggedUser.userId == this.goal.creationUserId) {
        this.menuOptions = [
          {
            id: 'edit',
            title: this.translations['cardOptions.EDIT'],
            icon: 'assets/img/icons/edit.svg',
            disabled: this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED || !this.goal.isGoalSettingsPhaseOpen
          },
          {
            id: 'delete',
            title: this.translations['cardOptions.DELETE'],
            icon: 'assets/img/icons/trash-2.svg',
            disabled: this.goal.goalStatus == PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED || !this.goal.isGoalSettingsPhaseOpen
          }
        ]
      }
      // sfida operations da approvare
      if (this.goal && this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS) {
        if (this.goal.sharedObjective && this.goal.goalStatus != PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED) {
          this.menuOptions.push({
            id: 'approve',
            title: this.translations['cardOptions.APPROVE_WITH_COMMENT'],
            icon: 'assets/img/icons/edit.svg',
            disabled: false
          });
        } else if ([
          PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE,
          PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE,
          PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED
        ].includes(this.goal.goalStatus)) {
          this.menuOptions = [
            {
              id: 'add_evidence',
              title: this.translations['cardOptions.ADD_EVIDENCE'],
              icon: 'assets/img/icons/message-circle.svg',
              disabled: this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS ? (!DEV_OPE_ADD_EVIDENCE.includes(this.goal.goalStatus) ? true : false) : false
            },
            {
              id: 'value',
              title: this.translations['cardOptions.VALUE'],
              icon: 'assets/img/icons/award.svg',
              disabled: ![PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE].includes(this.goal.goalStatus)
            }]
        }
      }
      // tutti gli altri
      if (this.goal.type != PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS && ['TO_PROPOSE', 'REJECTED'].indexOf(this.proposalStatus) < 0) {
        this.menuOptions.push(
          {
            id: 'add_evidence',
            title: this.translations['cardOptions.ADD_EVIDENCE'],
            icon: 'assets/img/icons/message-circle.svg',
            disabled: this.goal.type == PerfCareMacroGoalTypes.DEVELOPMENT_OPERATIONS ? (!DEV_OPE_ADD_EVIDENCE.includes(this.goal.goalStatus) ? true : false) : false
          },
          {
            id: 'value',
            title: this.translations['cardOptions.VALUE'],
            icon: 'assets/img/icons/award.svg',
            disabled: ![PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_AUTO_OBSERVE].includes(this.goal.goalStatus)
          }
        );
      }
    }

    this.isMenuOpen = !this.isMenuOpen;
  }

  onMenuOptionsClicked(option: any) {
    if (!option.disabled) {
      this.onOptionClicked.emit(option);
      this.closeMenu();
    }
  }

  getEvaluationScore() {
    if (this.goal && this.goal.goalEvaluation && this.loggedUser) {
      return this.goal.goalEvaluation.find((e: any) => e.creationUserId == this.loggedUser.userId)?.evaluationScore || null;
    }
    //if (this.isCollaborator) {
    //  return this.goal.goalEvaluation[0].evaluationScore;
    //} else if (!this.isCollaborator && this.goal.goalEvaluation[1]) {
    //  return this.goal.goalEvaluation[1].evaluationScore;
    //}
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  getDeadlineDate() {
    if (this.goal && this.goal.deadlineDate) {
      let temp = new Date(this.goal.deadlineDate);
      temp.setHours(temp.getHours() - 2);
      return moment(temp).format('DD/MM/YYYY');
      // return temp.getDay() + '/' + temp.getMonth() + '/' + temp.getFullYear();
      //return moment(this.goal.deadlineDate).format('DD/MM/YYYY');
    }
    return null;
  }

  isKpiOrCompetences() {
    return this.goal && this.goal.type == PerfCareMacroGoalTypes.OPERATIONS_KPI || this.goal.type == PerfCareMacroGoalTypes.OPERATIONS_COMPETENCES;
  }

}