import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { SenecaResponse, User } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as moment from 'moment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as AdminActions from "../ngrx/admin.actions";
import { AuthService } from 'src/app/auth/services/auth.service';

export type MenuOptionId =
  | 'handle-status'
  | 'evaluation'
  | 'interview'
  | 'development-plan';

interface MenuOption {
  id: MenuOptionId;
  title: string;
  icon: string;
  disabled?: boolean;
}

export type Talent = Pick<User, 'userId' | 'forename' | 'surname'> & {
  [key: string]: any;
};

@Component({
  selector: 'app-admin-talent-scounting',
  templateUrl: './talentScouting.component.html',
  styleUrls: ['./talentScouting.component.scss'],
})
export class AdminTalentScoutingComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  isLoadingUserData: boolean = false;
  userData: {
    list: any[];
    counter: number;
    fromRecord: number;
    numRecords: number;
    page: number;
  } = {
      list: [],
      page: 1,
      counter: 0,
      fromRecord: 0,
      numRecords: 20,
    };
  translations: any;
  getLoggedUser$: Subscription = new Subscription();
  loggedUser: any;
  selectedYear: any;
  isLoadingYearList: boolean = false;
  getYearsList$: any;
  yearList: any;

  searchedText: string = '';
  rowPerPageOptions = [
    {
      id: 20,
      title: 20,
    },
    {
      id: 50,
      title: 50,
    },
    {
      id: 100,
      title: 100,
    },
  ];
  selectedRows: { id: number; title: number };
  menuOptions: MenuOption[] = [];
  getTalentUserData$: Subscription = new Subscription();
  updateUser: any;
  selectedStatus: any;
  statusList: { id: string; title: string }[] = [];

  noteModalUser: any;
  isLoadingNoteList: boolean = false;
  getNoteListModal$: Subscription = new Subscription();

  noteListModalData: { user: any; list: any[] } = { user: null, list: [] };

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public modalService: ModalService,
    private adminService: AdminService,
    public redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.selectedRows = this.rowPerPageOptions[0];
    this.userData.numRecords = this.selectedRows.id;

    this.getLoggedUser$ = this.store
      .select(fromApp.getLoggedUser)
      .subscribe((loggedUser: any) => {
        this.loggedUser = loggedUser.user;
      });
    // Salvo l'anno corrente
    this.runningYear$ = this.store
      .select(fromApp.getRunningYear)
      .subscribe((runningYear) => {
        this.runningYear = runningYear;
        if (this.runningYear) {
          this.route.params.subscribe((params: Params) => {
            this.getPerformanceAssessmentYears();
            if (params.processYear) {
              this.selectedYear = {
                id: params.processYear,
                name: 'Anno ' + params.processYear,
              };
            } else {
              this.selectedYear = {
                id: this.runningYear,
                name: 'Anno ' + this.runningYear,
              };
            }

            this.translate
              .get([
                'talent.interview.CHANGE_STATUS',
                'talent.interview.TO_INTERVIEW',
                'talent.interview.TO_PLAN',
                'talent.interview.INTERVIEWED',
                'talent.interview.HANDLE_STATUS',
                'talent.interview.OBSERVATION_GRID',
                'talent.interview.INTERVIEW',
                'talent.interview.DEVELOPMENT_PLAN',
                'talent.interview.NOT_ELIGIBLE',
                'talent.interview.STAND_BY'
              ])
              .subscribe((translations) => {
                this.translations = translations;
                this.menuOptions = [
                  {
                    id: 'handle-status',
                    title: translations['talent.interview.HANDLE_STATUS'],
                    icon: '/assets/img/icons/filter.svg',
                  },
                  {
                    id: 'evaluation',
                    title: translations['talent.interview.OBSERVATION_GRID'],
                    icon: '/assets/img/icons/clipboard-list.svg',
                  },
                  {
                    id: 'interview',
                    title: translations['talent.interview.INTERVIEW'],
                    icon: '/assets/img/icons/contact.svg',
                  },
                  {
                    id: 'development-plan',
                    title: translations['talent.interview.DEVELOPMENT_PLAN'],
                    icon: '/assets/img/icons/sprout.svg',
                  },
                ];
                this.statusList = [
                  {
                    id: 'NOT_ELIGIBLE',
                    title: translations['talent.interview.NOT_ELIGIBLE'],
                  },
                  {
                    id: 'TO_INTERVIEW',
                    title: translations['talent.interview.TO_INTERVIEW'],
                  },
                  {
                    id: 'STAND_BY',
                    title: translations['talent.interview.STAND_BY'],
                  },
                  {
                    id: 'TO_PLAN',
                    title: translations['talent.interview.TO_PLAN'],
                  },
                  {
                    id: 'INTERVIEWED',
                    title: translations['talent.interview.INTERVIEWED'],
                  },
                ];

                this.getTalentUserData();
              });
          });
        }
      });
  }

  ngOnInit() { }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.adminService
      .getPerformanceAssessmentYears(this.loggedUser.userId)
      .subscribe(
        (data: SenecaResponse<any[]>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: 'year000',
              text: this.translate.instant('errors.' + data.error),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
            this.isLoadingYearList = false;
          } else {
            this.yearList = [];
            let keys = data.response.map((x) => x.assessmentYear);
            keys = keys.sort();
            for (let i = keys.length - 1; i >= 0; i--) {
              let yearData = data.response.find(
                (x) => x.assessmentYear == keys[i]
              );
              this.yearList.push({
                id: yearData.assessmentYear,
                name: 'Anno ' + yearData.assessmentYear,
                disabled: !yearData.isInProcess,
              });
            }
            this.isLoadingYearList = false;
          }
        },
        (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: 'year000',
            text: this.translate.instant('errors.' + err?.message),
            title: this.translate.instant('generic.WARNING'),
          };
          this.store.dispatch(
            CoreActions.SetApplicationModalMessage({ payload: messageObj })
          );
          this.isLoadingYearList = false;
        }
      );
  }


  onDowloadReport() {
    this.adminService.downloadTalentInterviewReport({
      perfCareYear: this.selectedYear.id
    }).pipe(
      switchMap(({ response, error }) => {
        if (error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          return EMPTY;
        }
        return combineLatest([
          of(response),
          this.authService.crateRetrieveTokenAfterLogin()
        ])
      }),
      switchMap(([filePath, { response, error }]) => {
        if (error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a007",
            text: this.translate.instant("errors." + error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        let downloadUrl = this.authService.getDownloadTempFileUrl(filePath, response);
        setTimeout(() => {
          window.open(downloadUrl, '_blank');
        }, 500)
        return EMPTY;
      })
    ).subscribe();
  }

  // cambio anno che sto osservando
  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split('/' + previous)[0];
    this.router.navigate([url + '/' + this.selectedYear.id]);
  }

  onSearch(reset?: boolean) {
    this.getTalentUserData(reset);
  }

  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.userData.numRecords = item.id;
    this.resetUserData();
    this.onSearch(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.userData.fromRecord = 0;
    this.userData.page = 1;
    this.userData.counter = 0;
    this.userData.numRecords = this.selectedRows.id;
    this.userData.list = [];
  }

  // Cambia la paginazione alla lista
  usersPageChanged(newPage: any) {
    this.userData.page = newPage;
    // Avvio una nuova ricerca
    this.onSearch();
    //this.getSpeedScreeningUserList();
  }
  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid();
    }
    user.isMenuOpen = !user.isMenuOpen;
  }

  // Associato alla direttiva clickOutside
  closeMenu(user: any) {
    user.isMenuOpen = false;
  }

  isMenuOptionValid() {
    // TODO fare condizioni
    return true;
  }

  onMenuOptionsClicked(option: MenuOption, user: any) {
    if (!option.disabled) {
      switch (option.id) {
        case 'handle-status':
          this.openUpdateStatusModal(user);
          break;
        case 'evaluation':
          this.navigateToTalentSection(user, 'evaluation');
          break;
        case 'interview':
          this.navigateToTalentSection(user, 'interview');
          break;
        case 'development-plan':
          this.navigateToTalentSection(user, 'development-plan');
          break;
      }
    }
  }

  navigateToTalentSection(user: any, section: MenuOptionId) {

    this.store.dispatch(AdminActions.SetCandidate({ candidate: user }));
    this.store.dispatch(AdminActions.SetProcessYear({ processYear: this.selectedYear.id }));

    if (section == 'interview') {
      this.router.navigate(['admin/talent/' + this.selectedYear.id + '/' + user.userId + '/interview/start']);
    } else {
      this.router.navigate([user.userId, section], {
        relativeTo: this.route,
        state: { user },
      });
    }
  }

  getTalentUserData(fromSearch?: boolean) {
    this.isLoadingUserData = true;

    if (fromSearch) {
      this.resetUserData();
    }

    if (this.getTalentUserData$) {
      this.getTalentUserData$.unsubscribe();
    }

    this.getTalentUserData$ = this.adminService
      .countUsersForTalentInterview(this.selectedYear.id, this.searchedText)
      .pipe(
        switchMap((counter: SenecaResponse<number>) => {
          if (counter.error) {
            // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
            return of(new SenecaResponse(counter.error, null));
          } else {
            // Salvo il counter
            this.userData.counter = counter.response;

            // Calcolo la paginazione
            let fromRecord = 0;
            if (this.userData.page && this.userData.numRecords) {
              fromRecord = (this.userData.page - 1) * this.userData.numRecords;
            } else {
              fromRecord = 0;
            }
            if (this.userData.counter) {
              return this.adminService.listUsersForTalentInterview(
                this.selectedYear.id,
                this.searchedText,
                fromRecord,
                this.userData.numRecords
              );
            } else {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(null, []));
            }
          }
        }),
        catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: 'sp001',
              text: this.translate.instant(
                'errors.' + ((err && err.message) || err)
              ),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          }
          this.isLoadingUserData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      )
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: 'sp002',
              text: this.translate.instant('errors.' + data.error),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          } else if (data.response && data.response.length) {
            this.userData.list = data.response;
          }
          this.isLoadingUserData = false;
        },
        (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: 'sp003',
              text: this.translate.instant(
                'errors.' + ((err && err.message) || err)
              ),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          }
          this.isLoadingUserData = false;
          return throwError(new Error(err.message));
        }
      );
  }

  openUpdateStatusModal(user: any) {
    this.updateUser = user;
    this.selectedStatus = this.statusList.find(
      (x: any) => x.id == user.interviewStatus
    );
    this.modalService.open('change-status');
  }

  closeUpdateStatusModal(confirm?: boolean) {
    this.modalService.close('change-status');
    if (confirm && this.selectedStatus) {
      this.updateInterviewStatus();
    }
  }

  selectStatus(newStatus: any) {
    this.selectedStatus = newStatus;
  }

  updateInterviewStatus() {
    this.isLoadingUserData = true;
    this.adminService
      .updateTalentInterviewStatus(
        this.selectedYear.id,
        this.updateUser.userId,
        this.selectedStatus.id
      )
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: 'confg001',
              text: this.translate.instant('errors.' + data.error),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          } else {
            this.getTalentUserData();
          }
          this.isLoadingUserData = false;
        },
        (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: 'confg002',
              text: this.translate.instant(
                'errors.' + ((err && err.message) || err)
              ),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          }
          this.isLoadingUserData = false;
          return throwError(new Error(err.message));
        }
      );
  }

  openNoteListModal(user: any) {
    if (user.notesCount) {
      this.isLoadingNoteList = true;
      this.noteListModalData.user = user;
      this.modalService.open('note-list');
      this.getNoteListModal(user);
    }
  }

  getNoteListModal(user: any) {
    if (this.getNoteListModal$) {
      this.getNoteListModal$.unsubscribe();
    }

    this.getNoteListModal$ = this.adminService
      .getUserTalentNote(this.selectedYear.id, user.userId)
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: 'in001',
              text: this.translate.instant('errors.' + data.error),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          } else {
            this.noteListModalData.list = [];
            for (let i = 0; i < data.response.notes.length; i++) {
              let tempNote = data.response.notes[i];
              this.noteListModalData.list.push({
                ...tempNote,
                insertDate: moment(tempNote.creationDate).format('D MMMM YYYY'),
              });
            }
          }
          if (
            this.noteListModalData.list &&
            !this.noteListModalData.list.length
          ) {
            this.closeNoteListModal();
          }
          this.isLoadingNoteList = false;
        },
        (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: 'in002',
              text: this.translate.instant(
                'errors.' + ((err && err.message) || err)
              ),
              title: this.translate.instant('generic.WARNING'),
            };
            this.store.dispatch(
              CoreActions.SetApplicationModalMessage({ payload: messageObj })
            );
          }
          this.isLoadingNoteList = false;
          return throwError(new Error(err.message));
        }
      );
  }

  closeNoteListModal() {
    this.modalService.close('note-list');
  }

  downloadLinkedFile(file: any) {
    let link = document.createElement('a');
    link.href = file.url;
    link.download = file.url.substr(file.url.lastIndexOf('/') + 1);
    link.click();
  }

  // Recupera la stringa per l'estensione del file
  getExtensionString(fileName: string) {
    let extension = fileName.slice(fileName.lastIndexOf('.'), fileName.length);
    extension = extension.replace('.', '');
    if (extension == 'pptx' || extension == 'PPTX') {
      extension = 'ppt';
    } else if (extension == 'jpg' || extension == 'JPG') {
      extension = 'jpeg';
    } else if (extension == 'xlsx' || extension == 'XLSX') {
      extension = 'xls';
    } else if (extension == 'docx' || extension == 'DOCX') {
      extension = 'doc';
    } else if (extension == 'eml' || extension == 'EML') {
      extension = 'eml';
    } else if (extension == 'msg' || extension == 'MSG') {
      extension = 'msg';
    } else if (extension == 'txt' || extension == 'TXT') {
      extension = 'txt';
    } else if (extension == 'rtf' || extension == 'RTF') {
      extension = 'rtf';
    } else if (extension == 'png' || extension == 'PNG') {
      extension = 'png';
    }
    return extension;
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getLoggedUser$) {
      this.getLoggedUser$.unsubscribe();
    }

    if (this.getTalentUserData$) {
      this.getTalentUserData$.unsubscribe();
    }
  }
}
