<div class="admin-table-container column-spacing">
    <p class="section-title">{{ ('process.phases.TITLE' | translate) }}</p>
    <div class="divisor-line"></div>

    <ng-container *ngIf="!isLoadingPhases && phasesList && phasesList.length">
        <div class="table-content process-structure phases">

            <table (clickOutside)="closeAllMenus()">
                <thead>
                    <tr>
                        <th [translate]="'process.phases.PROCESS_PHASE' | translate"></th>
                        <th [translate]="'process.phases.OPENING_DATE' | translate"></th>
                        <th [translate]="'process.phases.END_PHASE_DATE' | translate"></th>
                        <th [translate]="'process.phases.CLOSING_DATE' | translate"></th>
                        <th [translate]="'process.phases.STATUS' | translate"></th>
                        <th></th>
                        <th [translate]="'process.phases.HIGHLIGHT_PHASE' | translate"></th>
                        <th class="center" [translate]="'process.phases.ACTIONS' | translate"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let phase of phasesList">
                        <td>{{ phase.title || ('phases.'+ phase.phaseKey | translate) }}</td>
                        <!-- Data apertura fase -->
                        <td>{{ phase.phaseStartDate ? (phase.phaseStartDate) : '--' }}</td>
                        <!-- Data di fine fase -->
                        <td>{{ phase.phaseEndDate ? (phase.phaseEndDate) : '--' }}</td>
                        <!-- Data di chiusura fase -->
                        <td>{{ phase.phaseClosingDate ? (phase.phaseClosingDate) : '--' }}</td>
                        <td>
                            <item-list-team-status *ngIf="phase.statusType == 'PHASE_INACTIVE'; else phaseActiveStatus"
                                styleItem="border" type="disabled" [message]="('process.phases.DISABLED' | translate)">
                            </item-list-team-status>
                            <ng-template #phaseActiveStatus>
                                <item-list-team-status styleItem="border" type="completed"
                                    [message]="('process.phases.ACTIVE' | translate)">
                                </item-list-team-status>
                            </ng-template>
                        </td>
                        <td class="info-column"
                            [ngClass]="{'clickable': phase.phaseKey == 'MID_TERM_REVIEW' || phase.phaseKey == 'FINAL_EVALUATION'}">
                            <ng-container
                                *ngIf="phase.isManagerPhase && (phase.phaseKey == 'MID_TERM_REVIEW' || phase.phaseKey == 'FINAL_EVALUATION')">
                                <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                                    (click)="openManagerPhaseInfoModal(phase)">
                                </svg-icon>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="phase.isBannerPhase">
                                <item-list-team-status styleItem="border" type="completed"
                                    [message]="('generic.HIGHLIGHT' | translate)">
                                </item-list-team-status>
                            </ng-container>
                        </td>
                        <td class="action">
                            <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(phase)"
                                src="/assets/img/icons/more-horizontal.svg">
                            </svg-icon>
                            <ng-container *ngIf="phase.isMenuOpen">
                                <div class="dropdown-menu">
                                    <europ-dropdown-item (click)="onMenuOptionsClicked(option, phase)"
                                        [ngClass]="{'last': last}" class="full-width"
                                        *ngFor="let option of menuOptions; let last = last" [item]="option">
                                    </europ-dropdown-item>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoadingPhases">
        <div class="loader spinner-big"></div>
    </ng-container>
    <ng-container *ngIf="!isLoadingPhases && (!phasesList || !phasesList.length)">
        <div class="admin-no-data-wrapper">
            <p translate="process.details.NO_DATA"></p>
        </div>
    </ng-container>
</div>

<!--modale attiva fase -->
<modal id="highlight-phase" (onClose)="closeHighlightModal()" (onConfirm)="closeHighlightModal(true)"
    [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeHighlightModal()"
    [confirmLabel]="('generic.CONFIRM' | translate)">
    <ng-container *ngIf="modalPhase && modalPhase.phaseId">
        <modal-text-content modalId="hgps001" [title]="'process.phases.modals.HIGH_TITLE' | translate"
            [subtitle]="'process.phases.modals.HIGH_SUB' | translate"
            [text]="('process.phases.modals.HIGH_TEXT' | translate) + modalPhase.title + '?'">
        </modal-text-content>
    </ng-container>
</modal>