import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminHomeComponent } from './home/home.component';
import { adminReducer } from './ngrx/admin.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminEffects } from './ngrx/admin.effects';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { AdminService } from '../shared/services/admin.service';
import { ProcessSetupComponent } from './processSetup/processSetup.component';
import { CreateUpdateProcessComponent } from './createUpdateProcess/createUpdateProcess.component';
import { ProcessSetupDetailsComponent } from './processSetupDetails/processSetupDetails.component';
import { ProcessSetupParticipantComponent } from './processSetupParticipant/processSetupParticipant.component';
import { ProcessSetupObjectivesComponent } from './processSetupObjectives/processSetupObjectives.component';
import { DeclineObjectivesComponent } from './declineObjectives/declineObjectives.component';
import { ProcessSetupPhasesComponent } from './processSetupPhases/processSetupPhases.component';
import { PeopleAnagComponent } from './peopleAnag/peopleAnag.component';
import { StructureAnagComponent } from './structure-anag/structure-anag.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { CalibrationComponent } from './calibration/calibration.component';
import { CalibrationUserListComponent } from './calibration-user-list/calibration-user-list.component';
import { AdminDefinePerformanceLevelManagerComponent } from './definePerformanceLevel/definePerformanceLevel.component';
import { AdminConfirmPerformanceLevelManagerComponent } from './confirmPerformanceLevel/confirmPerformanceLevel.component';
import { CalibrationMatrixComponent } from './calibration-matrix/calibration-matrix.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DxButtonModule, DxChartModule, DxCircularGaugeModule, DxDataGridModule, DxPieChartModule } from 'devextreme-angular';
import { Subordinateservice } from '../manager/services/subordinates.service';
import { AdminTalentScoutingComponent } from './talentScoutingUsers/talentScouting.component';
import { AdminTalentProcessComponent } from './talentScoutingProcess/talentScoutingProcess.component';
import { PersonPotentialDetailsComponent } from './talent/person-potential-details/person-potential-details.component';
import { EvaluationTabComponent } from './talent/evaluation-tab/evaluation-tab.component';
import { InterviewTabComponent } from './talent/interview-tab/interview-tab.component';
import { DevelopmentPlanTabComponent } from './talent/development-plan-tab/development-plan-tab.component';
import { InterviewComponent } from './talent/interview/interview.component';
import { CreateDevelopmentActionComponent } from './talent/create-development-action/create-development-action.component';
import { EditDevelopmentActionComponent } from './talent/edit-development-action/edit-development-action.component';
import { EditDataListComponent } from './editDataList/editDataList.component';
import { EditDataDetailsComponent } from './editDataDetails/editDataDetails.component';
import { SidebarService } from '../shared/services/sidebar.service';
import { CascadingComponent } from './cascading/cascading.component';
import { CascadingCreateUpdateComponent } from './cascadingCreateUpdate/cascadingCreateUpdate.component';
import { EditPhaseDateComponent } from './processSetupPhases/editPhaseDate/editPhaseDate.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminHomeComponent,
    MonitoringComponent,
    ProcessSetupComponent,
    CreateUpdateProcessComponent,
    ProcessSetupDetailsComponent,
    ProcessSetupParticipantComponent,
    ProcessSetupObjectivesComponent,
    DeclineObjectivesComponent,
    ProcessSetupPhasesComponent,
    PeopleAnagComponent,
    StructureAnagComponent,
    ImpersonateComponent,
    CalibrationComponent,
    CalibrationUserListComponent,
    AdminDefinePerformanceLevelManagerComponent,
    AdminConfirmPerformanceLevelManagerComponent,
    CalibrationMatrixComponent,
    AdminDashboardComponent,
    AdminTalentScoutingComponent,
    AdminTalentProcessComponent,
    PersonPotentialDetailsComponent,
    EvaluationTabComponent,
    InterviewTabComponent,
    DevelopmentPlanTabComponent,
    InterviewComponent,
    CreateDevelopmentActionComponent,
    EditDevelopmentActionComponent,
    EditDataListComponent,
    EditDataDetailsComponent,
    CascadingComponent,
    CascadingCreateUpdateComponent,
    EditPhaseDateComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    SharedModule,
    CoreModule,
    NgxPaginationModule,
    DragDropModule,
    DxCircularGaugeModule,
    DxChartModule,
    DxPieChartModule,
    DxDataGridModule,
    DxButtonModule,
    StoreModule.forFeature('admin', adminReducer),
    EffectsModule.forFeature([AdminEffects])
  ],
  providers: [
    DatePipe,
    AdminService,
    Subordinateservice,
    SidebarService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AdminModule { }
