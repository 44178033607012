import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfCareDevelopmentActionStatuses } from 'atfcore-commonclasses';

@Component({
  selector: 'card-action',
  templateUrl: 'card-action.component.html',
  styleUrls: ['./card-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardActionComponent implements OnInit {
  @Input() object: any;
  @Input() placeholder: string = 'Inserisci una descrizione';
  @Input() bindValue: string = '';
  @Input() readonly: boolean = false;
  @Input() isCollaborator: boolean = false;
  @Input() isManager: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() loggedUserId: string = '';
  @Input() isHistory: boolean = false;
  @Input() isDevelopmentPlan: boolean = false;
  @Output() onReadComment: EventEmitter<any> = new EventEmitter();
  @Output() onInsertCommentToDevelopAction: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateStatusToDevelopAction: EventEmitter<any> = new EventEmitter();
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onEditComment: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteComment: EventEmitter<any> = new EventEmitter();
  @Output() onEditAction: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteAction: EventEmitter<any> = new EventEmitter();
  actionMenuOpened: boolean = false;
  actionMenuObject: any[] = [];
  isOnlyEdit: boolean = false;
  isOnlyChangeStatus: boolean = false;


  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
    if(this.isDevelopmentPlan) {
      this.actionMenuObject.push(
        {
          id: 'edit',
          name: this.translate.instant('generic.EDIT'),
          icon: '/assets/img/icons/edit.svg'
        },
        {
          id: 'updateStatus',
          name: this.translate.instant('developmentPlan.UPDATE_STATUS'),
          icon: '/assets/img/icons/refresh-cw.svg'
        },
        {
          id: 'delete',
          name: this.translate.instant('generic.DELETE'),
          icon: '/assets/img/icons/trash-2.svg'
        }
      )
    } else {
      if (this.isCollaborator) {
        if (this.object && this.object.statusObject && this.object.statusObject.statusMessage.indexOf('toShare') < 0) {
          this.isOnlyChangeStatus = true;
        }
        if (this.object && this.object.creationUserId == this.loggedUserId) {
          this.isOnlyChangeStatus = false;
          if (this.object.status.indexOf('TO_SHARE') < 0) {
            this.actionMenuObject.push(
              {
                id: 'updateStatus',
                name: '',
                icon: '/assets/img/icons/refresh-cw.svg'
              }
            )
            this.actionMenuObject[0].name = this.translate.instant('developmentPlan.UPDATE_STATUS');
          }
          if (this.object.status == PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_MANAGER || this.object.status == PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_MANAGER_DRAFT) {
            this.actionMenuObject.unshift(
              {
                id: 'edit',
                name: '',
                icon: '/assets/img/icons/edit.svg'
              },
              {
                id: 'delete',
                name: '',
                icon: '/assets/img/icons/trash-2.svg'
              }
            )
            this.actionMenuObject[0].name = this.translate.instant('generic.EDIT');
            this.actionMenuObject[1].name = this.translate.instant('generic.DELETE');
            // if (this.actionMenuObject[2]) {
            //   this.actionMenuObject[2].name = this.translate.instant('developmentPlan.UPDATE_STATUS');
            // }
          } else {
            this.actionMenuObject.unshift(
              {
                id: 'edit',
                name: '',
                icon: '/assets/img/icons/edit.svg'
              }
            )
            this.actionMenuObject[0].name = this.translate.instant('generic.EDIT');
          }


        }
      } else if (this.isManager && this.object && this.object.isDevelopmentAction && this.object.creationUserId == this.loggedUserId) {
        if (this.object.status == PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_SUBORDINATE || this.object.status == PerfCareDevelopmentActionStatuses.DEVELOPMENT_ACTION_TO_SHARE_TO_SUBORDINATE_DRAFT) {
          this.actionMenuObject.push(
            {
              id: 'edit',
              name: '',
              icon: '/assets/img/icons/edit.svg'
            },
            {
              id: 'delete',
              name: '',
              icon: '/assets/img/icons/trash-2.svg'
            }
          )
          this.actionMenuObject[0].name = this.translate.instant('generic.EDIT');
          this.actionMenuObject[1].name = this.translate.instant('generic.DELETE');
        } else {
          this.isOnlyEdit = true;
          /* this.actionMenuObject.push(
            {
              id: 'edit',
              name: '',
              icon: '/assets/img/icons/edit.svg'
            }
          )
          this.actionMenuObject[0].name = this.translate.instant('generic.EDIT'); */
        }
      }
    }
  }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  // Inserisci commento all'azione di sviluppo
  insertComment(data: any) {
    this.onInsertCommentToDevelopAction.emit(data);
  }

  // Aggiorna avanzameno dell'azione di sviluppo
  updateStatus(data: any) {
    this.onUpdateStatusToDevelopAction.emit(data);
  }

  // Modifica commento
  emitOnEditComment(object: any, comment: string) {
    this.onEditComment.emit({ action: object, comment: comment });
  }

  // Leggi commento
  emitOnReadComment(object: any) {
    this.onReadComment.emit(object);
  }

  // Elimina commento
  emitOnDeleteComment(object: any, commentId: string) {
    this.onDeleteComment.emit({ action: object, commentId: commentId });
  }

  openActionManageMenu(event: any) {
    event.stopPropagation()
    this.actionMenuOpened = true;
  }

  emitOnActionOptionClicked(item: any, object: any) {
    this.closeActionMenu();
    if (item.id == 'edit') {
      this.emitOnEditAction(object);
    } else if (item.id == 'updateStatus') {
      this.updateStatus(object);
    } else if (item.id == 'delete') {
      this.emitOnDeleteAction(object);
    }
  }

  emitOnEditAction(object: any) {
    this.onEditAction.emit(object);
  }

  emitOnDeleteAction(object: any) {
    this.onDeleteAction.emit(object.id);
  }

  closeActionMenu() {
    this.actionMenuOpened = false;
  }
}
