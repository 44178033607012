import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User, PerformanceCareTalent } from 'atfcore-commonclasses';
import { Observable, throwError, of, EMPTY, combineLatest } from 'rxjs';
import { switchMap, catchError, finalize, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Activity } from '../create-development-action/create-development-action.component';

@Component({
  selector: 'app-edit-development-action',
  templateUrl: './edit-development-action.component.html',
  styleUrls: ['./edit-development-action.component.scss']
})
export class EditDevelopmentActionComponent implements OnInit {
  readonly translationPrefix = 'talent.development-plan-tab.';

  readonly failedToUpdateActivityModalId = 'failed-to-update-activity-modal';

  readonly failedToGetRequiredParams = 'failed-to-get-required-params';

  readonly failedToFetchActionModalId = 'failed-to-fetch-action-modal';

  activity: Activity & {status?: string} = {
    developmentAction: '',
    date: '',
    duration: '',
    type: '',
    referentName: '',
    description: '',
  };

  private userIdProcessYearActionIdParams$: Observable<[string, number, string]> =
    this.route.paramMap.pipe(
      switchMap((params) => {
        const userId = params.get('userId');
        const processYear = params.get('processYear');
        const actionId = params.get('actionId');
        if (!userId || !processYear || !actionId) {
          return throwError('Missing userId or processYear or actionId');
        }
        const result: [string, number, string] = [userId, parseInt(processYear, 10), actionId];
        return of(result);
      })
    );

  selectedCandidate$: Observable<User> =  this.userIdProcessYearActionIdParams$.pipe(
    switchMap(([userId, perfCareYear]) =>
      this.potentialTalentSvc.getTalentDetails(userId, perfCareYear)
    ),
    switchMap(({ response, error }) => {
      if (error) {
        return throwError(error);
      }
      return of(response.user);
    }),
    catchError((err) => {
      console.error('[create-development plan]', err);
      this.modalSvc.open(this.failedToGetRequiredParams);
      return EMPTY;
    })
  );

  constructor(
    public redirectService: RedirectService,
    private router: Router,
    private route: ActivatedRoute,
    private modalSvc: ModalService,
    private potentialTalentSvc: PotentialTalentsService
  ) {}

  ngOnInit(): void {
    this.userIdProcessYearActionIdParams$.pipe(
      switchMap(([userId, perfCareYear, rentId]) => {
        return this.potentialTalentSvc.getIndividualDevelopmentPlan({
          perfCareYear,
          userId,
          rentId,
        })
      }),
      switchMap(({response, error}) => {
        if(error) {
          return throwError(error)
        }
        return of(response);
      }),
      tap(action => {
        this.activity = {
          developmentAction: action.developmentAction,
          date: action.date ?? '',
          duration: action.duration ?? '',
          type: action.type ?? '',
          referentName: action.referentName ?? '',
          description: action.description ?? '',
          status: action.status
        }
      }),
      catchError(err => {
        console.error('[create-development plan]', err);
        this.modalSvc.open(this.failedToFetchActionModalId);
        this.router.navigate(['../'], { relativeTo: this.route });
        return EMPTY;
      })
    ).subscribe()
  }

  updateActivity() {

    this.userIdProcessYearActionIdParams$.pipe(
      switchMap(([userId, perfCareYear, rentId]) => {
        return this.potentialTalentSvc.updateIndividualDevelopmentPlan({
          perfCareYear,
          idp: {
            rentId,
            userId,
            developmentAction: this.activity.developmentAction,
            status: this.activity.status || '',
            date: this.activity.date,
            duration: this.activity.duration,
            referentName: this.activity.referentName,
            type: this.activity.type,
            description: this.activity.description,
            perfCareYear,
          }
        });
      }),
      switchMap(({error}) => {
        if(error) return throwError(error);
        return this.router.navigate(['../../'], { relativeTo: this.route });
      }),
      catchError(err => {
        console.error('[create-development plan]', err);
        this.modalSvc.open(this.failedToUpdateActivityModalId);
        return EMPTY;
      }),
      finalize(() => this.resetActivity())
    ).subscribe()
  }


  resetActivity() {
    this.activity = {
      developmentAction: '',
      date: '',
      duration: '',
      type: '',
      referentName: '',
      description: '',
    };
  }

  closeFailedToUpdateActivityModal() {
    this.modalSvc.close(this.failedToUpdateActivityModalId);
  }

  closeFailedToGetUserIdAndPerCareYearFromRouteModal() {
    this.modalSvc.close(this.failedToGetRequiredParams);
  }

  closeFailedToFetchActionModal() {
    this.modalSvc.close(this.failedToFetchActionModalId);
  }

}
