import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, JwtPayload, PerfCareAssessmentPhaseKeys, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'admin-process-setup-phases-edit-date',
  templateUrl: './editPhaseDate.component.html',
  styleUrls: ['./editPhaseDate.component.scss']
})
export class EditPhaseDateComponent implements OnDestroy {
  runningYear$: Subscription;
  combinedSelected$: Subscription;
  runningYear: number = 0;
  loggedUser: any;

  processYear: string = '';

  isLoadingPhaseData: boolean = true;
  phaseData: any = {};
  phaseKey: any;
  getPhaseData$: any;
  isManagerDate: boolean = false;
  isMTRorFE: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
      .subscribe(
        ([loggedUser, runningPhase]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.route.params
              .subscribe(
                (params: Params) => {
                  this.processYear = params.processYear;
                  this.phaseKey = params.phaseKey;
                  if (this.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION || this.phaseKey == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW) {
                    this.isMTRorFE = true;
                  }
                  this.isManagerDate = window.location.href.indexOf('/manager') > -1;
                  this.getPhaseData();
                })
          }
        });
  }

  ngOnInit() {
  }

  // Servizio per il recupero dei dati della fase
  getPhaseData() {
    this.isLoadingPhaseData = true;

    if (this.getPhaseData$) {
      this.getPhaseData$.unsubscribe();
    }

    this.getPhaseData$ = this.adminService.getPerformanceAssessmentPhaseInfo(this.processYear, this.phaseKey)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "uphd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.phaseData = data.response;
          if (this.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION || this.phaseKey == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW) {
            if (this.isManagerDate) {
              this.phaseData.phaseStartDate = data.response.phaseStartDateForManager;
              this.phaseData.phaseEndDate = data.response.phaseEndDateForManager;
              if (this.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {

              }
            } else {
              this.phaseData.phaseStartDate = data.response.phaseStartDateForUser;
              this.phaseData.phaseEndDate = data.response.phaseEndDateForUser;
            }
          }
        }
        this.isLoadingPhaseData = false;
      }, (err) => {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "uphd002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPhaseData = false;
      })
  }


  selectDate(newDate: Date, type: string) {
    if (type == 'start') {
      this.phaseData.phaseStartDate = newDate;
    } else if (type == 'end') {
      this.phaseData.phaseEndDate = newDate;
    } else if (type == 'closing') {
      this.phaseData.phaseClosingDate = newDate;
      if (this.isManagerDate && this.phaseKey == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
        this.phaseData.phaseEndDate = newDate;
      }
    }
  }

  updatePhaseDate() {
    this.isLoadingPhaseData = true;
    this.adminService.updatePerformanceAssessmentPhaseDate(this.phaseData.phaseId, this.phaseData.phaseStartDate, this.phaseData.phaseEndDate, this.phaseData.phaseClosingDate, this.isManagerDate)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "uphd003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "uphd005",
            title: this.translate.instant("process.phases.DATES_UPDATED_TITLE"),
            text: this.translate.instant("process.phases.DATES_UPDATED_TEXT")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
        this.isLoadingPhaseData = false;
      }, (err) => {
        // Vedo se c'è la traduzione dell'errore
        const messageObj: ApplicationModalMessage = {
          modalId: "uphd004",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPhaseData = false;
      })

    this.isLoadingPhaseData = false;

  }

  orderBy(fieldName: string) {
  }

  ngOnDestroy() {
  }
}