<div class="objective-decline-container">
    <!-- Declinazione obiettivi company e di struttura per gruppi -->
    <div class="admin-table-container column-spacing">
        <h4 class="section-title with-sub" translate="process.objectives.DECLINE_COMPANY_STRUCTURE"></h4>
        <h5 class="section-subtitle" translate="process.objectives.DECLINE_COMPANY_STRUCTURE_SUB"></h5>

        <!-- Data grid per obiettivi company e di struttura-->
        <ng-container *ngIf="!isLoadingCompanyGoals; else loading">
            <dx-data-grid id="companyObjectives" [dataSource]="companyData" [remoteOperations]="false"
                [showBorders]="true" (onInitialized)="saveCompanyGridInstance($event)" [allowColumnResizing]="true"
                (onSaved)="saveCompanyObjectives($event)">
                <!-- Opzioni di editing elementi -->
                <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">
                </dxo-editing>
                <!-- (onContentReady)="contentReady($event)" -->
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-group-panel [visible]="true"></dxo-group-panel>

                <!-- Titolo obiettivo -->
                <dxi-column alignment="" cssClass="grid-cell-title" dataField="title"
                    [caption]="'process.objectives.fields.OBJECTIVE' | translate">
                </dxi-column>
                <!-- Peso -->
                <dxi-column [width]="67" alignment="center" cssClass="grid-cell-title" dataField="weight"
                    dataType="percent" [caption]="'process.objectives.fields.WEIGHT' | translate"
                    [cellTemplate]="'percentageCellTemplate'" [allowEditing]="false">
                </dxi-column>
                <!-- Tipo obiettivo -->
                <dxi-column alignment="center" [width]="120" cssClass="grid-cell-title" dataField="macroGoalType"
                    dataType="string" [caption]="'process.objectives.fields.TYPE' | translate" [allowEditing]="false"
                    [cellTemplate]="'evaluationsCellType'">
                </dxi-column>
                <!-- Target -->
                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="target" dataType="string"
                    [caption]="'process.objectives.fields.TARGET' | translate">
                </dxi-column>
                <!-- Unità -->
                <dxi-column alignment="center" [width]="90" cssClass="grid-cell-title" dataField="unit"
                    dataType="string" [caption]="'process.objectives.fields.UNIT' | translate">
                </dxi-column>
                <!-- Range (-) -->
                <dxi-column alignment="center" [width]="95" cssClass="grid-cell-title" dataField="rangeMinus"
                    dataType="number" [caption]="('process.objectives.fields.RANGE' | translate) + '(-)'"
                    [cellTemplate]="'percentageCellTemplate'">
                </dxi-column>
                <!-- Range (+) -->
                <dxi-column alignment="center" [width]="95" cssClass="grid-cell-title" dataField="rangePlus"
                    dataType="number" [caption]="('process.objectives.fields.RANGE' | translate) + '(+)'"
                    [cellTemplate]="'percentageCellTemplate'">
                </dxi-column>
                <!-- Risultati -->
                <!-- [cellTemplate]="'resultCellTemplate'" -->
                <dxi-column alignment="center" dataField="results" cssClass="grid-cell-title" dataType="string"
                    [caption]="'process.objectives.fields.RESULTS' | translate">
                </dxi-column>
                <!-- Raggiunto? -->
                <dxi-column alignment="center" [width]="130" dataField="goalAchieved" cssClass="grid-cell-title"
                    [caption]="'process.objectives.fields.ACHIEVED' | translate"
                    [cellTemplate]="'evaluationsCellTemplate'">
                    <dxo-lookup [dataSource]="objectiveEvaluationOptions" valueExpr="id" displayExpr="name">
                    </dxo-lookup>
                </dxi-column>
                <!-- Colonna per il raggruppamento-->
                <dxi-column dataField="groupName" cssClass="grid-cell-title" [groupIndex]="0" caption="Struttura">
                </dxi-column>
                <!-- Template cella valutazioni -->
                <!--div *dxTemplate="let d of 'resultCellTemplate'">
                    <p>{{ d.value | titlecase }}</p>
                </div-->
                <!-- Template cella Tipo obiettivo -->
                <div *dxTemplate="let d of 'evaluationsCellType'">
                    <p>{{ 'process.objectives.goalTypes.'+d.value | translate }}</p>
                </div>
                <!-- Template cella raggiunto? -->
                <div *dxTemplate="let d of 'evaluationsCellTemplate'">
                    <p>{{ d.value | titlecase }}</p>
                </div>
                <!-- Template cella range-->
                <div *dxTemplate="let d of 'percentageCellTemplate'">
                    <p>{{(d.value / 100) | percent}}</p>
                </div>
                <dxo-paging [enabled]="false"></dxo-paging>
            </dx-data-grid>
        </ng-container>
    </div>


    <!-- Declinazione obiettivi di operations -->
    <div class="admin-table-container column-spacing">
        <p class="section-title">{{ ('process.objectives.DECLINE_OPERATIONS_SHARED' | translate) }}</p>

        <ng-container *ngIf="!isLoadingOperationsGoal; else loading">
            <dx-data-grid id="gridContainer" [dataSource]="operationsData" [remoteOperations]="false"
                [showBorders]="true" (onInitialized)="saveOperationsGridInstance($event)"
                (onSaved)="saveOperationsObjectives($event)">
                <!-- Opzioni di editing elementi -->
                <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false">
                </dxo-editing>
                <!-- (onContentReady)="contentReady($event)" -->
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>

                <!-- Titolo obiettivo -->
                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="title"
                    [caption]="'process.objectives.fields.OBJECTIVE' | translate">
                </dxi-column>
                <!-- Descrizione -->
                <dxi-column alignment="center" cssClass="grid-cell-title" dataField="description"
                    [caption]="'process.objectives.fields.DESCR' | translate">
                </dxi-column>
            </dx-data-grid>

        </ng-container>
    </div>

</div>

<ng-template #loading>
    <div class="loader spinner-medium"></div>
</ng-template>

<!-- Modale per la la conferma delle modifiche degli obiettivi company -->
<!--modal id="confirm-company" (onCancel)="closeConfirmEditModal()" (onClose)="closeConfirmEditModal()"
    (onConfirm)="closeConfirmEditModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
    [confirmLabel]="'generic.CONFIRM' | translate">
    <modal-text-content modalId="cfme001" [title]="'process.objectives.modals.CONFIRM_EDIT_TITLE' | translate"
        [subtitle]="'process.objectives.modals.CONFIRM_EDIT_SUB' | translate"
        [text]="'process.objectives.modals.CONFIRM_EDIT_TEXT' | translate">
    </modal-text-content>
</modal-->