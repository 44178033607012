<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBack()" [isBackButton]="true"
      [title]="'qualifyCompetences.SIDENAV_TITLE' | translate"
      [description]="'qualifyCompetences.SIDENAV_DESCR' | translate"></sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <!-- contenuto pagina -->
      <ng-container *ngIf="!isLoadingCareTags">
        <div class="content-wrapper">
          <ng-container *ngIf="!isLoadingNext">
            <div class="title-step-container">
              <p> {{ ('qualifyCompetences.COMPETENCE' | translate) + ' ' + (currentStep + 1) + ' ' +
                ('qualifyCompetences.OF' | translate) + ' ' + totalSteps}} </p>
            </div>
            <div class="progress-bar-container">
              <div class="progress" [ngStyle]="{ 'width': currentPercentage + '%'}"
                [ngClass]="{'completed': isComplete()}">
              </div>
            </div>

            <div class="description-wrapper">
              <div class="care-content" *ngIf="competenceList"
                [innerHTML]="'<h3>' + competenceList[currentStep].title + '</h3>' + competenceList[currentStep].description">
              </div>
              <!--div class="section-wrapper">
                            <p class="descr-title">{{('qualifyCompetences.caring.TITLE' | translate)}}</p>
                            <p class="descr-subtitle">{{('qualifyCompetences.caring.SUBTITLE' | translate)}}</p>
                        </div>
                        <div class="section-wrapper">
                            <p class="descr-info" translate="qualifyCompetences.BEHAVIOUR"></p>
                            <p class="descr-content" [innerHTML]="'qualifyCompetences.caring.DESCR' | translate"></p>
                        </div-->
            </div>

            <div class="how-wrapper">
              <p translate="qualifyCompetences.HOW_FEEL"></p>
              <svg-icon src="./assets/img/icons/info.svg" class="medium-size blue-primary" (click)="openHowFeelModal()"
                [applyClass]="true"></svg-icon>
            </div>

            <div class="card-wrapper" *ngIf="competenceList">
              <card-radio class="full-width" [bindValue]="competenceList[currentStep].evaluation"
                (onItemChange)="setCompetenceEvaluation($event)"></card-radio>
            </div>

            <div class="button-container" *ngIf="competenceList"
              [ngClass]="{'center': (hasNext && hasPrev) || (!hasNext && hasPrev) , 'left': hasPrev && !hasNext, 'right': hasNext && !hasPrev}">
              <europ-button *ngIf="hasPrev" type="primary" [label]="'qualifyCompetences.PREV' | translate"
                (onButtonClicked)="prevCompetence()" iconLeft="assets/img/icons/arrow-left.svg">
              </europ-button>
              <europ-button [disabled]="!competenceList[currentStep].evaluation" *ngIf="hasNext" type="primary"
                [label]="'qualifyCompetences.NEXT' | translate" (onButtonClicked)="nextCompetence()"
                iconRight="assets/img/icons/arrow-right.svg">
              </europ-button>
              <europ-button [disabled]="lockCloseButton || isLoadingCareTags || !competenceList[currentStep].evaluation"
                *ngIf="!hasNext" type="primary" [label]="'qualifyCompetences.END' | translate"
                (onButtonClicked)="closeCompetenceEvaluation()">
              </europ-button>
            </div>
          </ng-container>
          <ng-container *ngIf="isLoadingNext">
            <div class="loader spinner-medium"></div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingCareTags">
        <div class="loader spinner-medium"></div>
      </ng-container>
    </div>
  </div>
</page-container>