import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AdminActions from "../../admin/ngrx/admin.actions";

@Injectable({
  providedIn: 'root',
})
export class HydrateCandidateGuard implements CanActivate {

  constructor(private store: Store, private router: Router, private route: ActivatedRoute) {}

  // Hydrate the candidate store
  // Idially, this should be done by a meta-reducer for the whole ngrx store
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):| boolean| UrlTree| Observable<boolean | UrlTree>| Promise<boolean | UrlTree> {

    const candidate = sessionStorage.getItem('selectedCandidate');
    const processYear = sessionStorage.getItem('processYear');

    if (candidate && processYear) {
      try {
        const parsedCandidate = JSON.parse(candidate);
        this.store.dispatch(AdminActions.SetCandidate({candidate: parsedCandidate}));

        const parsedProcessYear = JSON.parse(processYear);
        this.store.dispatch(AdminActions.SetProcessYear({processYear: parsedProcessYear}));

      } catch(e) {
        sessionStorage.removeItem('selectedCandidate');
        return this.router.navigate(['admin/talent', route.params['processYear']]);
      }
    } else {
      return this.router.navigate(['admin/talent', route.params['processYear']]);
    }

    return true;
  }
}
