import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CreateUpdateGoalComponent } from '../createUpdateGoal/createUpdateGoal.component';

@Injectable()
export class CanDeactivateUpdateGoalManagerGuardService implements CanDeactivate<CreateUpdateGoalComponent> {
  constructor() { }

  canDeactivate(component: CreateUpdateGoalComponent): boolean | Promise<boolean> {
    if (!component.noDataInserted() && (component.checkIfWhatSectionIsChanged() || component.checkIfHowSectionIsChanged() || (component.goal.managerComment != component.goalCopy.managerComment)) && !component.goToGoalObjectiveList) {
      component.formatDataOpenUpdateGoalModal();
      return new Promise<boolean>((resolve, reject) => {
        let clickedButton = document.getElementById("confirmBtn");
        if (clickedButton) {
          clickedButton.onclick = ((e: any) => {
            resolve(false);
          });
        }
      });
    } else {
      component.goToGoalObjectiveList = false;
      return true;
    }
    return true;
  }
}