import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownItem } from '../../models/dropdown.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'europ-select',
  templateUrl: 'europ-select.component.html',
  styleUrls: ['./europ-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropSelectComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() notSeeDescription: boolean = false;
  @Input() selectedItem: any;
  @Input() loadingData: boolean = false;
  @Input() clearable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() forceError: boolean = false;
  @Input() isCheckbox: boolean = false;
  @Input() placeholder: string = '';
  @Input() searchable: boolean = false;
  @Input() bindLabel: string = '';
  @Input() innerHtmlDescription: boolean = false;
  // Non serve al momento
  @Input() multiple: boolean = false;
  @Input() tablePagination: boolean = false;
  // Aggiunte nuove template
  @Input() startSearchLabel?: string;
  @Input() noDataFoundLabel?: string;
  @Input() inMinWidthForIe?: boolean = false;
  @Input() isLoadingSearchResult: boolean = false;
  private searchedStringChanged: Subject<string> = new Subject<string>();

  @Output() onSelectedItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAddItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  noDataObject: DropdownItem = {
    id: 'none',
    name: '',
    icon: '/assets/img/icons/coffee.svg'
  }

  loadingDataObject?: DropdownItem;

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.noDataObject.name = this.noDataFoundLabel || this.translate.instant('generic.NO_DATA_FOUND');;
    this.loadingDataObject = {
      id: "loading",
      name: this.loadingData || this.translate.instant('generic.LOADING'),
      icon: ''
    }

    if (this.searchable) {
      this.searchedStringChanged.pipe(
        debounceTime(150),
      ).subscribe((searchString: string) => {
        this.onSearch.emit(searchString);
      });
    }
  }

  emitOnSelectedItem(item: any) {
    if (item && item.disabled) {
      return;
    }
    if (this.multiple && item && item.length) {
      // non serve al momento
      this.clearList()
      for (let i = 0; i < item.length; i++) {
        for (let j = 0; j < this.items.length; j++) {
          if (this.items[i].id == item.id) {
            this.items[i].isChecked = !this.items[i].isChecked;
          }
        }
      }
    } else {
      this.selectedItem = item;
    }
    this.onSelectedItem.emit(item);
  }

  emitOnSearch(textData: any) {
    this.searchedStringChanged.next(textData);
  }

  // Clicco su un option
  onOptionClicked(event: any, isDisabled: boolean) {
    if (isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  clearList() {
    if (this.items && this.items.length) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].isChecked = false;
      }
    }
    this.cdr.detectChanges();
  }
}