import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { PerfCareAssessment, PerfCareAssessmentAttributeStatusTypes, SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
@Component({
    selector: 'app-admin-create-update-process',
    templateUrl: './createUpdateProcess.component.html',
    styleUrls: ['./createUpdateProcess.component.scss']
})
export class CreateUpdateProcessComponent implements OnInit {
    runningYear$: Subscription = new Subscription();
    runningYear: number = 0;
    processId: string = '';
    isLoadingProcess: boolean = true;

    processName: string = '';
    processNameCopy: string = '';

    nameModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };
    yearModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };
    statusModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };
    noteModalInfo: { modalId: string; title: any; subtitle: string; text: any; } =
        { modalId: '', title: 'any', subtitle: '', text: '' };


    yearOptions: any;
    selectedYear: any;
    selectedYearCopy: any;

    statusOptions: any;
    selectedStatus: any;
    selectedStatusCopy: any;

    modalUpdateId: string = '';
    modalUpdateTitle: string = '';
    modalUpdateSubtitle: string = '';
    modalUpdateText: string = '';
    modalUpdateTitleConfirmTextButton: string = '';
    modalUpdateTextCloseTextButton: string = '';
    createProcess$: Subscription = new Subscription();
    paramProcessYear: any;
    getProcessData$: Subscription = new Subscription();
    getLastCreatedProcessYear$: Subscription = new Subscription()
    isLoadingProcessYears: boolean = false;
    areAllDatesDefined: boolean = false;

    constructor(
        public translate: TranslateService,
        public adminService: AdminService,
        public redirectService: RedirectService,
        private store: Store<fromApp.AppState>,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private router: Router,
    ) {
        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
            this.runningYear = runningYear;
            if (this.runningYear) {
                this.route.params
                    .subscribe(
                        (params: Params) => {
                            if (params.processYear) {
                                this.paramProcessYear = params.processYear;
                            }
                            if (!this.paramProcessYear) {
                                this.getLastCreatedProcessYear();
                                this.isLoadingProcess = false;
                            } else {
                                this.getProcessData();
                            }
                        }
                    );
            }
        });
    }

    ngOnInit() {
        this.translate.get(
            [
                'process.create.NAME',
                'process.create.NAME_INFO',
                'process.create.YEAR',
                'process.create.YEAR_INFO',
                'process.create.STATUS',
                'process.create.STATUS_INFO',
                'process.create.NOTE',
                'process.create.NOTE_INFO',
                'process.create.ACTIVE',
                'process.create.STANDBY',
                'process.create.CLOSED'
            ])
            .subscribe(translations => {
                this.nameModalInfo = {
                    modalId: "ps001",
                    title: translations['process.create.NAME'],
                    subtitle: '',
                    text: translations['process.create.NAME_INFO'],
                };
                this.yearModalInfo = {
                    modalId: "ps002",
                    title: translations['process.create.YEAR'],
                    subtitle: '',
                    text: translations['process.create.YEAR_INFO'],
                };
                this.statusModalInfo = {
                    modalId: "ps003",
                    title: translations['process.create.STATUS'],
                    subtitle: '',
                    text: translations['process.create.STATUS_INFO'],
                };
                this.noteModalInfo = {
                    modalId: "ps004",
                    title: translations['process.create.NOTE'],
                    subtitle: '',
                    text: translations['process.create.NOTE_INFO'],
                };

                this.statusOptions = [
                    {
                        id: PerfCareAssessmentAttributeStatusTypes.GOAL_SETTING,
                        title: this.translate.instant('process.create.ACTIVE'),
                        disabled: false
                    },
                    {
                        id: PerfCareAssessmentAttributeStatusTypes.STANDBY,
                        title: this.translate.instant('process.create.STANDBY'),
                        disabled: false
                    },
                    {
                        id: PerfCareAssessmentAttributeStatusTypes.CLOSED,
                        title: this.translate.instant('process.create.CLOSED'),
                        disabled: false
                    }
                ];

                if (!this.processId) {
                    this.selectedStatus = this.statusOptions[1];
                }
            })
    }

    getLastCreatedProcessYear() {
        this.isLoadingProcessYears = true;
        if (this.getLastCreatedProcessYear$) {
            this.getLastCreatedProcessYear$.unsubscribe();
        }

        this.getLastCreatedProcessYear$ = this.adminService.lastCreatedProcessYear()
            .subscribe((data: SenecaResponse<number>) => {
                if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "cup001",
                        title: this.translate.instant("generic.WARNING"),
                        text: this.translate.instant("errors." + data.error)
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else if (data.response >= 0) {
                    this.yearOptions = [];
                    let startingYear = data.response || this.runningYear;
                    for (let i = 1; i < 4; i++) {
                        this.yearOptions.push({
                            id: startingYear + i,
                            title: (startingYear + i).toString()
                        })
                    }
                }
                this.isLoadingProcessYears = false;
            }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                    modalId: "cup002",
                    title: this.translate.instant("generic.WARNING"),
                    text: this.translate.instant("errors." + ((err && err.message) || err))
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingProcessYears = false;
            });
    }

    nameChanged(text: string) {
        this.processName = text;
    }


    changeYear(yearItem: any) {
        this.selectedYear = yearItem;
    }

    changeStatus(statusItem: any) {
        this.selectedStatus = statusItem;
    }

    getProcessData() {
        this.isLoadingProcess = true;
        if (this.getProcessData$) {
            this.getProcessData$.unsubscribe();
        }

        this.getProcessData$ = this.adminService.getProcess(this.paramProcessYear)
            .subscribe((data: SenecaResponse<PerfCareAssessment>) => {
                if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "cup003",
                        title: this.translate.instant("generic.WARNING"),
                        text: this.translate.instant("errors." + data.error)
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                } else if (data.response) {
                    this.processId = data.response.assessmentId;
                    this.processName = data.response.title;
                    this.processNameCopy = JSON.parse(JSON.stringify(this.processName));
                    this.selectedYear = {
                        id: data.response.year,
                        title: data.response.year
                    }
                    this.selectedYearCopy = JSON.parse(JSON.stringify(this.selectedYear));
                    // se il processo è chiuso, posso passarlo solo in standby, quindi disattivo le altre opzioni
                    if (data.response.statusType == PerfCareAssessmentAttributeStatusTypes.CLOSED) {
                        this.statusOptions[0].disabled = true;
                    }
                    // Se il processo è in standby, non posso cambiare lo stato
                    else if (data.response.statusType == PerfCareAssessmentAttributeStatusTypes.STANDBY) {
                        this.statusOptions[0].disabled = false;
                        this.statusOptions[2].disabled = false;
                    }
                    // Altrimenti se il processo è attivo posso chiuderlo
                    else {
                        this.statusOptions[1].disabled = true;
                    }
                    this.selectedStatus = this.statusOptions.find((option: any) => option.id == data.response.statusType);
                    if (!this.selectedStatus) {
                        this.selectedStatus = this.statusOptions.find((option: any) => option.id == 'ACTIVE');
                    }

                    if (this.selectedStatus) {
                        this.selectedStatusCopy = JSON.parse(JSON.stringify(this.selectedStatus));
                    }

                    this.areAllDatesDefined = data.response.hasAllPhasesDateSet || false;
                }
                this.isLoadingProcess = false;
            }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                    modalId: "cup004",
                    title: this.translate.instant("generic.WARNING"),
                    text: this.translate.instant("errors." + ((err && err.message) || err))
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingProcess = false;
            });
    }


    // Gestisce quando crei/aggiorni un'evidenza
    goToUpdateProcess(isBack?: boolean) {
        let setProcessToActive = this.selectedStatus && this.selectedStatus.id != PerfCareAssessmentAttributeStatusTypes.STANDBY && this.selectedStatus.id != PerfCareAssessmentAttributeStatusTypes.CLOSED;
        if (this.selectedStatus && ((setProcessToActive && this.areAllDatesDefined) || (!setProcessToActive))) {
            if (!this.checkIfDataIsChanged() && isBack) {
                this.goBackBrowser();
            } else {
                this.formatUpdateModal(isBack);
                this.openUpdateModal();
            }
        } else {
            this.modalService.open("set-phases-dates-modal");
        }
    }

    closeSetPhasesDatesModal(goToPhases?: boolean) {
        if (goToPhases) {
            this.router.navigate(['/admin/processSetup/phaseDefinition/', this.selectedYear.id]);
        } else {
            this.modalService.close("set-phases-dates-modal");
        }
    }

    openUpdateModal() {
        this.modalService.open('processUpdateModal');
    }

    closeUpdateModal() {
        this.modalService.close('processUpdateModal');
    }

    formatUpdateModal(isBack?: boolean) {
        this.modalUpdateId = this.getModalId(isBack);
        this.modalUpdateTitle = this.getModalSaveTitle(isBack);
        // this.modalUpdateSubtitle = this.getModalSaveSubtitle();
        this.modalUpdateText = this.getModalSaveDescription(isBack);
        this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
        this.modalUpdateTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
    }

    // Recupera il modalId per la modale di crea/aggiorna evidenza
    getModalId(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return 'ps005';
        } else {
            if (isBack) {
                if (this.processId && this.processId.length) {
                    return 'ps006';
                } else {
                    return 'ps007';
                }
            } else {
                if (this.processId) {
                    return 'ps008';
                } else {
                    return 'ps009';
                }
            }
        }
    }

    getModalSaveTitle(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('process.modals.OBBLIGATORY_DATA_NOT_INSERTED');
        } else {
            if (isBack) {
                return this.translate.instant('generic.GO_BACK');
            } else {
                if (this.processId) {
                    return this.translate.instant('process.modals.EDIT_PROCESS_TITLE');
                } else {
                    return this.translate.instant('process.modals.CREATE_PROCESS_TITLE');
                }
            }
        }
    }

    // Sottotitolo per il modale crea/aggiorna evidenza
    getModalSaveSubtitle() {
        // if (!this.processId && this.isObbligatoryDataInserted()) {
        //     return this.translate.instant('process.modals.YOU_ARE_INSERTING_A_NOTE');
        // } else if (this.processId && this.isObbligatoryDataInserted()) {
        //     return this.translate.instant('process.modals.YOU_ARE_UPDATING_A_NOTE');
        // } else {
        //     return '';
        // }
    }

    // Descrizione per il modale crea/aggiorna evidenza
    getModalSaveDescription(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            if (this.processId && this.processId.length) {
                return this.translate.instant('process.modals.OBBLIGATORY_DATA_NOT_INSERTED');
            } else {
                return this.translate.instant('process.modals.OBBLIGATORY_DATA_NOT_INSERTED');
            }
        } else {
            if (isBack) {
                if (this.processId && this.processId.length) {
                    if (this.selectedStatus.id == PerfCareAssessmentAttributeStatusTypes.CLOSED) {
                        return this.translate.instant('process.modals.EDIT_PROCESS_TEXT') + "<br><br>" + this.translate.instant('process.modals.CLOSE_PROCESS_WARNING');
                    } else {
                        return this.translate.instant('process.modals.EDIT_PROCESS_TEXT');
                    }
                } else {
                    return this.translate.instant('process.modals.CREATE_PROCESS_TEXT');
                }
            } else {
                if (this.processId && this.processId.length) {
                    if (this.selectedStatus.id == PerfCareAssessmentAttributeStatusTypes.CLOSED) {
                        return this.translate.instant('process.modals.EDIT_PROCESS_TEXT') + "<br><br>" + this.translate.instant('process.modals.CLOSE_PROCESS_WARNING');
                    } else {
                        return this.translate.instant('process.modals.EDIT_PROCESS_TEXT');
                    }
                } else {
                    return this.translate.instant('process.modals.CREATE_PROCESS_TEXT');
                }
            }
        }
    }

    // Testo modale pulsante close
    getCancelLabelCreateUpdateNoteModal() {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
        } else {
            return this.translate.instant('generic.ANNULL');
        }
    }

    // Testo modale pulsante crea/aggiorna
    getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
        if (!this.isObbligatoryDataInserted()) {
            return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
        } else {
            if (isBack) {
                if (this.processId && this.processId.length) {
                    return this.translate.instant('generic.CONFIRM');
                } else {
                    return this.translate.instant('generic.CONFIRM');
                }
            } else {
                if (this.processId && this.processId.length) {
                    return this.translate.instant('generic.CONFIRM');
                } else {
                    return this.translate.instant('generic.CONFIRM');
                }
            }
        }
    }

    // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
    isObbligatoryDataInserted() {
        return this.processName && this.processName.trim().length && this.selectedStatus && this.selectedStatus.id && this.selectedYear && this.selectedYear.id;
    }

    checkIfDataIsChanged() {
        return this.processName && this.processNameCopy && this.processName != this.processNameCopy && this.selectedStatus && this.selectedStatusCopy && this.selectedStatus.id != this.selectedStatusCopy.id && this.selectedYear && this.selectedYearCopy && this.selectedYear.id != this.selectedYearCopy.id;
    }


    createProcess() {
        this.isLoadingProcess = true;
        this.closeUpdateModal()

        if (this.createProcess$) {
            this.createProcess$.unsubscribe();
        }

        this.createProcess$ = this.adminService.createProcess(this.selectedYear.id, this.processName)
            .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "cup005",
                        title: this.translate.instant("generic.WARNING"),
                        text: this.translate.instant("errors." + data.error)
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                }
                this.redirectService.goBackBrowser();
            }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                    modalId: "cup006",
                    title: this.translate.instant("generic.WARNING"),
                    text: this.translate.instant("errors." + ((err && err.message) || err))
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingProcess = false;
            });
    }

    updateProcess() {
        this.isLoadingProcess = true;

        this.closeUpdateModal()

        if (this.createProcess$) {
            this.createProcess$.unsubscribe();
        }

        this.createProcess$ = this.adminService.updateProcess(this.selectedYear.id, this.processName, this.selectedStatus.id)
            .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                        modalId: "cup007",
                        title: this.translate.instant("generic.WARNING"),
                        text: this.translate.instant("errors." + data.error)
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                }
                this.redirectService.goBackBrowser();
            }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                    modalId: "cup008",
                    title: this.translate.instant("generic.WARNING"),
                    text: this.translate.instant("errors." + ((err && err.message) || err))
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isLoadingProcess = false;
            });
    }

    // Vai indietro back browser
    goBackBrowser() {
        this.redirectService.goBackBrowser();
    }

    ngOnDestroy() {
        if (this.runningYear$) {
            this.runningYear$.unsubscribe();
        }

        if (this.getProcessData$) {
            this.getProcessData$.unsubscribe();
        }
    }
}