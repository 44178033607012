import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetUserTalentHrInterviewQuestionsResponse } from 'atfcore-commonclasses';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import * as fromApp from '../../../ngrx/app.reducers';
import { Talent } from '../../talentScoutingUsers/talentScouting.component';

@Component({
  selector: 'app-interview',
  templateUrl: './interview-tab.component.html',
  styleUrls: ['./interview-tab.component.scss'],
})
export class InterviewTabComponent implements OnInit {
  translationPrefix = 'talent.interview-tab.';

  private modalId = 'failed-to-fetch-talent-interview';

  selectedPerformanceYear$: Observable<number | null> = this.store.select(
    fromApp.getProcessYear
  );

  selectedCandidate$: Observable<Talent | null> = this.store.select(
    fromApp.getSelectedCandidate
  );

  isInterviewDone$!: Observable<{ isDone: boolean }>;


  hrInterviewQuestions$!: Observable<GetUserTalentHrInterviewQuestionsResponse>;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private potentialTalentSvc: PotentialTalentsService,
    private translateSvc: TranslateService
  ) { }

  ngOnInit(): void {

    this.isInterviewDone$ = this.getCandidateIdAndPerformanceYear().pipe(
      switchMap(([performanceYear, candidate]) => {
        if (!candidate || !performanceYear)
          return throwError('No candidate found');

        return this.potentialTalentSvc.isInterviewDone(
          candidate.userId,
          performanceYear
        );
      }),
      switchMap(({ response, error }) => {
        if (error) {
          return throwError(error);
        }
        return of({ isDone: <any>response });
      }),
      catchError((e) => {
        console.error('[interview-tab]', e);
        this.modalService.open(this.modalId);
        return EMPTY;
      })
    );


    this.hrInterviewQuestions$ = this.getCandidateIdAndPerformanceYear().pipe(
      switchMap(([performanceYear, candidate]) => {
        if (!candidate || !performanceYear) {
          return throwError('No candidate found');
        }
        return this.potentialTalentSvc.getHRInterviewQuestions(candidate.userId, performanceYear);
      }),
      switchMap(({ response, error }) => {
        if (error) {
          return throwError(error);
        }
        return of(response);
      }),
      catchError((e) => {
        console.error('[interview]', e);
        this.modalService.open(this.modalId);
        return EMPTY;
      })
    );

  }

  closeModal() {
    this.modalService.close(this.modalId);
    this.router.navigate(['../../'], { relativeTo: this.route });
  }


  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length !== 0;
  }

  navigateToInterview(tab: number) {
    this.router.navigate(['start'], { relativeTo: this.route, queryParams: { tab } });
  }

  getAnswerOrPlaceholder(answer: string | undefined, isLast: boolean) {
    if (answer?.trim().length) {
      return answer;
    } else {
      return isLast ? this.translateSvc.instant('generic.NO_NOTE') : this.translateSvc.instant('generic.NO_ANSWER');
    }
  }

  getInterviewActionLabel(interview: any) {
    if (interview && interview.isDone == 'TO_START') {
      return this.translationPrefix + 'START_INTERVIEW';
    } else {
      return this.translationPrefix + 'EDIT_INTERVIEW';
    }
  }

  private getCandidateIdAndPerformanceYear() {
    return combineLatest([
      this.selectedPerformanceYear$,
      this.selectedCandidate$,
    ]).pipe(
      filter(
        ([candidate, performanceYear]) =>
          !!candidate && !!performanceYear
      ),
    )
  }

}
