<!-- Stepper component -->
<div class="stepper-component">

    <div class="generic-step" *ngFor="let status of statuses; let i = index; let first = first; let last = last;">
        <div class="generic-label"
            [ngClass]="{
                'left': first, 'right': last, 'active': !status.isComplete && status.id == currentStatus.id, 
                'active-complete':status.isComplete && status.id == currentStatus.id,  'disabled': status.disabled
            }"
            (click)="onStepClicked(status)">
            <p class="stepper-number" [ngClass]="{'completed': !status.isComplete}">
                <ng-container *ngIf="status.isComplete">
                    <div class="stepper-icon">
                        <svg-icon class="icon" [applyClass]="true" src="/assets/img/icons/check.svg">
                        </svg-icon>
                    </div>
                </ng-container>
                <ng-container *ngIf="!status.isComplete">
                    <p class="stepper-number">
                        {{ status.number }}
                    </p>
                </ng-container>
            </p>
            <ng-container *ngIf="showLabel(status.id == currentStatus.id)">
                <p class="stepper-label">{{ status.title }} </p>
            </ng-container>
        </div>
        <ng-container *ngIf="!last">
            <div class="stepper-line"></div>
        </ng-container>
    </div>

</div>