import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GetMyManagerUserDataResponse, GetPerformanceAssessmentBannerPhaseResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, JwtPayload, PerfCareAssessmentPhaseKeys, RequiredAuth, SenecaResponse } from '../../../commonclasses';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../core/profile/ngrx/profile.actions";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from "../../ngrx/app.reducers";
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { CollaboratorService } from 'src/app/shared/services/collaborator.service';
import * as moment from 'moment';

@Component({
  selector: 'app-collaborator-operations-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class CollaboratorOperationsHomeComponent implements OnInit {
  runningYear$: Subscription;
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "GOAL_SETTING";
  bannerImage: string = '/assets/img/banner/bg-banner-gs.png';
  updateUserAck$: any;
  userAcknowledges: any;
  runningPhase: any;
  isLoadingCurrentPhase: boolean = true;
  currentYear = new Date().getFullYear();

  feedbackDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  idpDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  applauseDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  requestDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };

  interviewDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  peerFeedbackAllowed: boolean = false;
  isLoadingManagerInfo: boolean = false;
  getManagerData$: Subscription = new Subscription;
  isChief: boolean = false;
  // box mid term review
  isLoadingMidTermData: boolean = false;
  getMidTermReviewBoxData$: Subscription = new Subscription;
  midTermData: any;
  userAuths: any;
  isOperation: any;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public collaboratorService: CollaboratorService,
    private authService: AuthService,
    public redirectService: RedirectService,
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });

    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const userAck$: Observable<any> = this.store.select(fromApp.getUserAcknowledges);
    const peerFeedbackAllowed$: Observable<boolean> = this.store.select(fromApp.getPeerFeedbackAllowed);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    this.combinedSelected$ = combineLatest([loggedUser$, userAck$, getRunningPhase$, peerFeedbackAllowed$])
      .subscribe(
        ([loggedUser, userAck, runningPhase, peerFeedbackAllowed]) => {
          if (runningPhase && loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            if (loggedUser && loggedUser.params && loggedUser.params.userProfilesByYear && Object.keys(loggedUser.params.userProfilesByYear).length) {
              this.userAuths = loggedUser.params.userProfilesByYear;
            }
            this.isOperation = this.userAuths[this.runningYear].find((x: string) => x == RequiredAuth.PERFORMANCECARE_USER_OPERATIONS);
            this.runningPhase = runningPhase;
            this.userAcknowledges = userAck;
            this.phaseName = this.runningPhase?.phaseKey; // 'CONTINUOS_FEEDBACK'  
            this.bannerImage = this.getBannerImage(this.phaseName);
            this.getMidTermReviewBoxData();
          }
          this.peerFeedbackAllowed = peerFeedbackAllowed;
          this.getManagerData();
        });
  }

  ngOnInit() {
    this.feedbackDisabledModal = {
      modalId: "148",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.idpDisabledModal = {
      modalId: "149",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.applauseDisabledModal = {
      modalId: "150",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.requestDisabledModal = {
      modalId: "151",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
    this.interviewDisabledModal = {
      modalId: "152",
      title: "Sezione non disponibile",
      subtitle: "Come mai non posso accedervi?",
      text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
    }
  }

  // Recupera i dati del manager, se non ha un manager è un chief
  getManagerData() {
    this.isLoadingManagerInfo = true;
    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }
    this.getManagerData$ = this.collaboratorService.getManagerByUserId()
      .subscribe((managerData: SenecaResponse<GetMyManagerUserDataResponse>) => {
        let isChief = false;
        if (managerData.error && managerData.error.length) {
          isChief = true;
        }
        this.store.dispatch(ProfileActions.SetIsChief({ payload: isChief }));
        this.isChief = isChief;
        this.isLoadingManagerInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerInfo = false;
      });
  }

  getMidTermReviewBoxData() {
    this.isLoadingMidTermData = true;
    if (this.getMidTermReviewBoxData$) {
      this.getMidTermReviewBoxData$.unsubscribe();
    }
    this.getMidTermReviewBoxData$ = this.collaboratorService.getMidTermReviewBoxData(this.runningYear)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.midTermData = data.response;
          if (this.midTermData) {
            this.formatMidTermData();
          }
          this.isLoadingMidTermData = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingMidTermData = false;
      });
  }

  // prepara i dati per il box
  formatMidTermData() {
    let today = moment().format();
    if (this.midTermData.midTermReviewData) {
      let startDate = moment(this.midTermData.midTermReviewData.phaseStartDateForUser).format('DD/MM/YYYY');
      let endDate = moment(this.midTermData.midTermReviewData.phaseEndDateForUser).format('DD/MM/YYYY');
      if (startDate && endDate) {
        this.midTermData.dateDescription = this.translate.instant('midTerm.home.AVAILABLE_FROM') + ' <b>' + startDate + '</b> ';
        this.midTermData.dateDescription += this.translate.instant('midTerm.home.TO') + ' <b>' + endDate + '</b>';
      }
      if (moment(today).isSameOrAfter(this.midTermData.midTermReviewData.phaseStartDateForUser) && moment(today).isSameOrBefore(this.midTermData.midTermReviewData.phaseEndDateForUser)) {
        this.midTermData.isDateValid = true;
      } else {
        this.midTermData.isDateValid = false;
      }
    }
    if (this.midTermData.feedbackCountData) {
      this.midTermData.feedbackNumber = this.midTermData.feedbackCountData.createdFeedbackDialogueCount;
      this.midTermData.evidenceNumber = this.midTermData.feedbackCountData.createdEvidenceCount;
    }
    this.midTermData.isPhaseActive = this.midTermData.midTermReviewData.statusType !== 'PHASE_INACTIVE'
    if (this.midTermData.revisionDataStatus && this.midTermData.revisionDataStatus.length) {
      this.midTermData.status = 'completed';
      this.midTermData.completedDescr = this.translate.instant('midTerm.home.COMPLETED_ON') + ' ' + moment(this.midTermData.midTermReviewUserObservationDate).format('DD.MM.YYYY') + ' ›';
    } else {
      this.midTermData.status = 'to_complete';
    }
    if (this.midTermData.newObservationToWatch) {
      this.midTermData.observationCount = 1;
    }
  }

  goToMidTerm() {
    if (!this.midTermData.isDateValid && this.midTermData.midTermRevisionManagerStatus == 'NOT_REVISABLE') {
      const messageObj: ApplicationModalMessage = {
        modalId: "mt004",
        text: this.translate.instant("errors.PERFCARE_DATES_NOT_VALID_FOR_OPERATE_IN_MID_TERM_REVIEW"),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    } else if (this.midTermData && this.midTermData.isPhaseActive) {
      this.redirectService.goToMidTermCollaborator(this.isOperation);
    }
  }

  // TODO solo per test
  resetOnboardingAck() {
    if (this.updateUserAck$) {
      this.updateUserAck$.unsubscribe();
    }
    let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
    acks.data['PERFORMANCE_CARE_ONBOARDING'] = null
    this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
      subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "044",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          window.location.reload();
        }
      })
  }

  // Vai agli obiettivi del collaboratore
  goToPersonDetails() {
    this.redirectService.goToPersonDetailsCollaborator((this.loggedUser && this.loggedUser.userId), this.isOperation, false, this.runningYear);
  }

  // crea e invia applauso
  goToSendApplause() {
    this.redirectService.goToSendClapPageCollaborator('', '', this.isOperation);
  }

  // Pagina feedback
  goToFeedback() {
    this.redirectService.goToPersonDetailsFeedbackCollaborator(this.loggedUser.userId, this.isOperation, this.runningYear);
  }

  goToInterview() {
    this.redirectService.goToFeedbackInterview(this.loggedUser.userId, this.isOperation);
  }

  goToTakeNote() {
    this.redirectService.goToCreatePrivateNote('', '', this.isOperation);
  }

  // Vai al piano di sviluppo del collaboratore
  goToDevelopmentPlanPersonDetails() {
    this.redirectService.goToPersonDetailsDevelopmentPlanCollaborator((this.loggedUser && this.loggedUser.userId), this.isOperation, this.runningYear);
  }

  goToRequestFeedback() {
    this.redirectService.goToCreatePeerFeedback('', '', this.isOperation);
  }

  getBannerImage(phaseName: string) {
    if (phaseName == PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW) {
      return 'assets/img/banner/bg-banner-mid-term.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.FINAL_EVALUATION) {
      return 'assets/img/banner/bg-banner-final-evaluation.png';
    } else if (phaseName == 'CONTINUOS_FEEDBACK') {
      return 'assets/img/banner/bg-banner-feedback.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.POST_CALIBRATION) {
      return 'assets/img/banner/bg-banner-final-evaluation.png';
    } else if (phaseName == PerfCareAssessmentPhaseKeys.DEVELOPMENT_PLAN) {
      return 'assets/img/banner/bg-banner-arrows.png';
    } else {
      return 'assets/img/banner/bg-banner-gs.png';
    }
  }

  getEndDate() {
    switch (this.runningPhase?.phaseKey) {
      case PerfCareAssessmentPhaseKeys.MID_TERM_REVIEW:
        return this.runningPhase?.phaseEndDateForUser;
      case PerfCareAssessmentPhaseKeys.FINAL_EVALUATION:
        return this.runningPhase?.phaseEndDateForUser;
      default:
        return this.runningPhase?.phaseEndDate;
    }
  }
}