import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetSubordinatesWithPhaseStatusesForManagerResponse } from '../../../../commonclasses';


@Component({
  selector: 'avatar-img',
  templateUrl: 'avatar-img.component.html',
  styleUrls: ['./avatar-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {
  @Input() src?: string;
  @Input() size: string = ''; // big, medium,
  @Input() whiteBackground: boolean = false;
  @Input() initials?: string = '';
  _user: Partial<GetSubordinatesWithPhaseStatusesForManagerResponse> | null = null;
  @Input() set user(value: Partial<GetSubordinatesWithPhaseStatusesForManagerResponse> | null) {
    this._user = value;
  };
  @Input() hideNew: boolean = false;
  @Output() onItemChange: EventEmitter<any> = new EventEmitter<any>();
  userName: string = '';
  isSvgImg: boolean = false;


  constructor() {
  }

  ngOnInit() {
    if (this._user) {
      this.initials = this._user && this._user.forename && this._user.surname && this._user.forename[0] + this._user.surname[0];
      this.userName = this._user.forename + ' ' + this._user.surname;
      this.src = this._user.userOptions && this._user.userOptions.avatarImage;
      if (this.src) {
        let extensionImg = this.src.split('.').pop();
        if (extensionImg == 'svg') {
          this.isSvgImg = true;
        } else {
          this.isSvgImg = false;
        }
      }
    }
  }
}


