import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreActions from "./core/ngrx/core.actions";
import * as fromApp from "./ngrx/app.reducers";
import { RedirectService } from './shared/services/redirect.service';
import { AuthService } from './auth/services/auth.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ModalService } from './shared/components/modal/modal.service';
import { ApplicationModalMessage } from './core/ngrx/core.reducers';
import { DropdownItem } from './shared/models/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { JwtPayload } from '../commonclasses';
import { Router } from '@angular/router';
import * as ManagerActions from "../app/manager/ngrx/manager.actions";
import { BadgeObject } from './profilePage/profilePage.component';
import { LangsService } from './core/services/langs.service';
import * as moment from 'moment';

import { loadMessages, locale } from 'devextreme/localization';
import 'devextreme-intl';
import * as messagesIt from 'devextreme/localization/messages/it.json';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  showApplicationLoader: boolean = false;
  applicationModalMessageText: string = '';
  applicationModalMessageTitle: string = '';
  withScrolledBody: boolean = true;
  applicationModalButtonCloseText: string = '';
  isFetchingLangs: boolean = true;
  loggedUser: any;
  initials: string = '';
  isManager: boolean = false;
  isCollaborator: boolean = false;
  isAdmin: boolean = false;

  isTeamPage: boolean = false;
  // Flag per la visualizzazione del tutorial
  onboardingDone: boolean = false;
  applicationModalMessageSubTitle: string = '';
  modalWithKalturaScript: string = '';
  kalturaCustomId: string = '';
  runningPhase: any;
  applicationModalBadge: BadgeObject[] = [];
  applicationModalMessageImage: string = '';
  applicationModalMessageNoBackground: boolean = false;
  noConfirmButton: boolean = false;
  confirmLabel: string = '';
  basicMenuItems: DropdownItem[] = [];
  managerMenuItems: DropdownItem[] = [];
  managerCollaboratorMenuItems: DropdownItem[] = [];
  isLoadingMenuItems: boolean = false;
  applicationModalId: string = '';
  isTeamButtonClicked: boolean = false;
  isClickedTeamButton$: Subscription;
  headerTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  profileDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  dashboardDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  }
  collaboratorRedirectMenuItem: any;
  managerRedirectMenuItem: any;
  isOperations: boolean = false;
  isSti: boolean = false;
  closeBadgeModalLabel: string = '';
  isAdminModule: boolean = false;
  showImpersonateBanner: boolean = false;

  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private modalService: ModalService,
    private translate: TranslateService,
    private router: Router,
    private langsService: LangsService,
  ) {

    loadMessages(messagesIt);
    locale('it');

    this.isClickedTeamButton$ = this.store.select(fromApp.getIsButtonTeamClicked).subscribe((isButtonClicked) => {
      this.isTeamButtonClicked = isButtonClicked;
    })

    this.store.select(fromApp.getUserAcknowledges)
      .subscribe((userAck: any) => {
        this.onboardingDone = userAck && userAck.data && !!userAck.data['PERFORMANCE_CARE_ONBOARDING'];
      })
    this.isLoadingMenuItems = true;

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.showImpersonateBanner = showImpersonateBanner;
      })

    // Sto in ascolto del potenziale messaggio generico da mostrare nella modale
    const applicationModalMessage$: Observable<ApplicationModalMessage> = this.store.select(fromApp.getApplicationModalMessage);
    const applicationModalBadge$: Observable<BadgeObject[]> = this.store.select(fromApp.getApplicationModalBadge);
    const isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    combineLatest([applicationModalMessage$, applicationModalBadge$, isFetchingLangs$]).subscribe(
      ([applicationModalMessage, applicationModalBadge, isFetchingLangs]) => {
        this.isFetchingLangs = isFetchingLangs;
        this.applicationModalId = applicationModalMessage && applicationModalMessage.modalId || '';
        this.applicationModalMessageText = applicationModalMessage && applicationModalMessage.text;
        this.applicationModalMessageTitle = applicationModalMessage && applicationModalMessage.title;
        this.applicationModalMessageSubTitle = applicationModalMessage && applicationModalMessage.subtitle || '';
        this.applicationModalMessageImage = applicationModalMessage && applicationModalMessage.image || '';
        this.applicationModalMessageNoBackground = applicationModalMessage && applicationModalMessage.noBackground || false;
        this.withScrolledBody = applicationModalMessage && applicationModalMessage.withScrolledBody || false;
        this.applicationModalButtonCloseText = applicationModalMessage && applicationModalMessage.applicationModalButtonCloseText || '';
        this.modalWithKalturaScript = applicationModalMessage && applicationModalMessage.modalWithKalturaScript || '';
        this.kalturaCustomId = applicationModalMessage && applicationModalMessage.kalturaCustomId || '';
        this.noConfirmButton = applicationModalMessage && applicationModalMessage.noConfirmButton || false;
        this.confirmLabel = applicationModalMessage && applicationModalMessage.confirmLabel || '';
        // oggetto badge modale
        this.applicationModalBadge = applicationModalBadge || [];

        if (this.applicationModalBadge && this.applicationModalBadge.length) {
          for (let i = 0; i < this.applicationModalBadge.length; i++) {
            setTimeout(() => { this.modalService.open('modalBadge' + this.applicationModalBadge[i].badgeId); }, 150)
          }
        }

        if (this.applicationModalMessageText && this.applicationModalMessageText.length) {
          // Ho un nuovo messaggio da mostrare, quindi apro lo modale per visualizzarlo
          this.modalService.open('modalMessage');
        }
      });
  }

  ngOnInit() {
    const isManager$: Observable<boolean> = this.store.select(fromApp.getIsManager)
    const isCollaborator$: Observable<boolean> = this.store.select(fromApp.getIsCollaborator);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const isOperations$: Observable<boolean> = this.store.select(fromApp.getIsOperation);
    const isSti$: Observable<boolean> = this.store.select(fromApp.getIsSti);
    const combinedSelectes$ = combineLatest([loggedUser$, isManager$, isCollaborator$, isAdmin$, isOperations$, isSti$])
      .subscribe(
        ([loggedUser, isManager, isCollaborator, isAdmin, isOperations, isSti]) => {
          this.basicMenuItems = [];
          this.managerCollaboratorMenuItems = [];
          this.managerMenuItems = [];
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            let language = this.langsService.getUserLang(this.loggedUser);
            moment.locale(language);
            this.isManager = isManager;
            this.isCollaborator = isCollaborator;
            this.isAdmin = isAdmin;
            this.isOperations = isOperations;
            this.isSti = isSti;
            this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
            if (this.loggedUser) {
              this.initials = this.loggedUser.forename[0] + this.loggedUser.surname[0];
              this.translate.get(
                [
                  'generic.WELCOME',
                  'generic.ONBOARDING_VIDEO',
                  'generic.CLOSE',

                ])
                .subscribe(translations => {
                  this.closeBadgeModalLabel = translations['generic.CLOSE'];
                  // Nuova modale
                  // manager modalId: "001"; collaboratore modalId: "002"
                  this.headerTooltip = {
                    modalId: isManager ? "001" : "002",
                    title: translations['generic.WELCOME'],
                    subtitle: translations['generic.ONBOARDING_VIDEO'],
                    text: 'test',
                    kalturaCustomId: 'kalturaVideoHeaderId',
                    modalWithKalturaScript: '<script src="https://cdnapisec.kaltura.com/p/1936311/sp/193631100/embedIframeJs/uiconf_id/42700011/partner_id/1936311?autoembed=true&entry_id=1_wmmq4is7&playerId=kalturaVideoHeaderId&cache_st=1545652499&width=533&height=122"></script>',
                    applicationModalButtonCloseText: translations['generic.CLOSE'],
                    noConfirmButton: true
                  }
                })

              if (!this.isAdminModule) {
                if (this.isManager) {
                  // elementi comuni menù
                  this.basicMenuItems = [
                    // aggiunto profilo di base
                    {
                      id: 'profile',
                      name: this.translate.instant('menu.PERSONAL_PROFILE'),
                      avatar: this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage,
                      initials: this.initials
                    },
                    {
                      id: 'dashboard',
                      name: this.translate.instant('menu.DASHBOARD'),
                      icon: '/assets/img/icons/pie-chart.svg'
                    },
                    {
                      id: 'notifications',
                      name: this.translate.instant('menu.NOTIFICATIONS'),
                      icon: '/assets/img/icons/bell.svg'
                    }
                  ];
                  // elementi manager vista collaboratore
                  this.managerCollaboratorMenuItems.push(
                    {
                      id: 'profile',
                      name: this.translate.instant('menu.PERSONAL_PROFILE'),
                      avatar: this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage,
                      initials: this.initials
                    });
                  // Oggetto del menu per redirect mobile da collaboratore a manager
                  if (this.isManager) {
                    this.managerRedirectMenuItem = {
                      id: 'manager',
                      name: this.translate.instant('menu.ENTER_AS_MANAGER'),
                      icon: '/assets/img/icons/users.svg'
                    }
                  }
                  // elementi menu manager Profilo
                  // this.managerMenuItems.push(
                  //   {
                  //     id: 'profile',
                  //     name: this.translate.instant('menu.MANAGER_PROFILE'),
                  //     avatar: this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage,
                  //     initials: this.initials
                  //   })

                  // Oggetto del menu per redirect mobile da manager a collaboratore
                  if (this.isCollaborator) {
                    this.collaboratorRedirectMenuItem = {
                      id: 'contributor',
                      name: this.translate.instant('menu.ENTER_AS_CONTRIBUTOR'),
                      icon: '/assets/img/icons/users.svg'
                    };
                  }
                  this.managerMenuItems.push(
                    {
                      id: 'tutorial',
                      name: this.translate.instant('menu.TUTORIAL'),
                      icon: '/assets/img/icons/help-circle.svg'
                    });
                } else {
                  this.basicMenuItems = [{
                    id: 'profile',
                    name: this.translate.instant('menu.PERSONAL_PROFILE'),
                    avatar: this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage,
                    initials: this.initials
                  },
                  {
                    id: 'notifications',
                    name: this.translate.instant('menu.NOTIFICATIONS'),
                    icon: '/assets/img/icons/bell.svg'
                  },
                  {
                    id: 'tutorial',
                    name: this.translate.instant('menu.TUTORIAL'),
                    icon: '/assets/img/icons/help-circle.svg'
                  }
                  ]
                }
                if (this.isAdmin) {
                  this.basicMenuItems.push({
                    id: 'admin',
                    name: this.translate.instant('menu.ADMIN'),
                    icon: '/assets/img/icons/settings.svg'
                  })
                }
              } else {
                this.basicMenuItems.push(
                  {
                    id: 'process_setup',
                    name: this.translate.instant('adminHome.cards.PROCESS_SETUP'),
                    icon: '../../../../assets/img/icons/settings.svg'
                  },
                  // {
                  //   id: 'people_anag',
                  //   name: this.translate.instant('adminHome.cards.ANAG'),
                  //   icon: '../../../../assets/img/icons/users.svg'
                  // },
                  {
                    id: 'impersonate',
                    name: this.translate.instant('adminHome.cards.IMPERSONATE'),
                    icon: '../../../../assets/img/icons/user.svg'
                  },
                  {
                    id: 'structure_anag',
                    name: this.translate.instant('adminHome.cards.STRUCTURE_ANAG'),
                    icon: '../../../../assets/img/icons/building.svg'
                  },
                  {
                    id: 'monitoring',
                    name: this.translate.instant('adminHome.cards.MONITORING'),
                    icon: '../../../../assets/img/icons/activity.svg'
                  },
                  {
                    id: 'dashboard',
                    name: this.translate.instant('adminHome.cards.DASHBOARD'),
                    icon: '../../../../assets/img/icons/pie-chart.svg'
                  },
                )
              }
              this.isLoadingMenuItems = false;
            }
          }
        });
    // Recupero le lingue disponibili
    this.store.dispatch(CoreActions.GetAvailableLangs());
  }

  // Chiude la modale contenente un errore
  closeModalMessage() {
    this.store.dispatch(CoreActions.DeleteApplicationModalMessage())
    this.modalService.close('modalMessage');
  }

  // Chiude la modale dei badge
  closeModalBadge(badgeId: string) {
    this.store.dispatch(CoreActions.DeleteApplicationModalBadge({ payload: badgeId }));
    this.modalService.close('modalBadge' + badgeId);
  }

  goToAdminHome() {
    this.router.navigate(['/admin/home'])
  }

  // Gestione redirect sidebar header
  onHeaderOptionClicked(item: DropdownItem) {
    if (item.id === 'profile') {
      if (this.isManager) {
        this.router.navigate(["/manager/profile"]);
      } else {
        if (this.isOperations) {
          this.router.navigate(["/collaboratorOperations/profile"]);
        } else {
          this.router.navigate(["/collaborator/profile"]);
        }
      }
    } else if (item.id === 'contributor') {
      // Redirect alla vista collaboratore
      this.router.navigate(["/collaborator/home"]);
    } else if (item.id === 'tutorial') {
      // Redirect alla vista collaboratore
      this.redirectService.goToOnboardingVideo();
    } else if (item.id === 'notifications') {
      this.redirectService.goToNotifications();
    } else if (item.id === 'admin') {
      // this.router.navigateByUrl(window.location.href.split('#')[0] + '#/admin/home');
      this.router.navigate(['/admin/home']);
    } else if (item.id == 'monitoring') {
      this.router.navigate(['/admin/monitoring']);
    } else {
      this.dashboardDisabledModal = {
        modalId: "156",
        title: "Sezione non disponibile",
        subtitle: "Come mai non posso accedervi?",
        text: "Questa sezione non è al momento disponibile. Quando verrà aperta verrai avvisato e potrai usufruire delle nuove funzionalità."
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.dashboardDisabledModal }));
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showHeader();
  }

  // Visualizza o meno l'header dell'app
  showHeader() {
    return !this.isLoadingMenuItems && !this.redirectService.isThisCurrentPage('404')
      && !this.redirectService.isThisCurrentPage('403') && !this.redirectService.isThisCurrentPage('compatibilityError')
      && !this.redirectService.isThisCurrentPage('createUpdateGoal') && !this.redirectService.isThisCurrentPage('organizeInterview')
      && !this.redirectService.isThisCurrentPage('errorPage')
      && !this.redirectService.isThisCurrentPage('notifications') && !this.redirectService.isThisCurrentPage('qualifyCompetences')
      && !this.redirectService.isThisCurrentPage('setCompetenceChallenge') && !this.redirectService.isThisCurrentPage('setUpdateCompetenceChallenge')
      && !this.redirectService.isThisCurrentPage('sendApplause')
      && !this.redirectService.isThisCurrentPage('createUpdateDevelopmentAction')
      && !this.redirectService.isThisCurrentPage('profile')
      && !this.redirectService.isThisCurrentPage('createUpdateEvidence')
      && !this.redirectService.isThisCurrentPage('createUpdatePrivateNote')
      && !this.redirectService.isThisCurrentPage('createUpdateProcess')
      && !this.redirectService.isThisCurrentPage('defineObjectives')
      && !this.redirectService.isThisCurrentPage('createUpdatePeerFeedback')
      && !this.redirectService.isThisCurrentPage('addMidTermReview')
      && !this.redirectService.isThisCurrentPage('finalEvaluation')
      && !this.redirectService.isThisCurrentPage('definePerformanceLevel')
      && !this.redirectService.isThisCurrentPage('postCalibrationEvaluation')
      && !this.redirectService.isThisCurrentPage('speedScreening')
      && !this.redirectService.isThisCurrentPage('observationGrid')
      && !this.redirectService.isThisCurrentPage('interview/start')
      && !this.redirectService.isThisCurrentPage('development-plan/create-action')
      && !this.redirectService.isThisCurrentPage('development-plan/edit-action')
      && !this.redirectService.isThisCurrentPage('createUpdateChallenge')
      && !this.redirectService.isThisCurrentPage('cascadingCreateUpdate')
      && !this.redirectService.isThisCurrentPage('createOptionalObjectives')
      && !this.redirectService.isThisCurrentPage('editDate')
      && !(this.redirectService.isThisCurrentPage('calibrationMatrix') && window.innerWidth <= 980);
  }

  onGoToMySection() {
    this.isTeamPage = !this.isTeamPage;
    if (this.isOperations && !this.isSti) {
      this.router.navigate(["/collaboratorOperations/personDetails/" + this.loggedUser.userId]);
    } else {
      this.router.navigate(["/collaborator/personDetails/" + this.loggedUser.userId]);
    }
  }

  goToMyTeam() {
    this.isTeamPage = !this.isTeamPage;
    this.isTeamButtonClicked = !this.isTeamButtonClicked;
    this.store.dispatch(ManagerActions.SetHeaderTeamClickedButton({ payload: this.isTeamButtonClicked }));
    this.router.navigate(["/manager/myTeam"]);
  }

  // Per il cambio vista dei manager
  collaboratorView() {
    return window.location.href.indexOf('/collaborator') > -1;
  }

  // Per il cambio vista dei manager
  managerView() {
    return window.location.href.indexOf('/manager') > -1;
  }

  menuId(index: number, el: any) {
    return el.id;
  }

  ngOnDestroy() {
    if (this.isClickedTeamButton$) {
      this.isClickedTeamButton$.unsubscribe();
    }
  }
}
