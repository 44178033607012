<!-- Sidebar con i dettagli utente -->
<sidebar [showAppHeader]="true" [hideCloseButton]="true" [sidenavTemplateRef]="detailsContent" direction="right"
    [navWidth]="625" [duration]="0.3">
</sidebar>

<!-- Template sidebar dettaglio utente -->
<ng-template #detailsContent>
    <div class="sidebar-admin" *ngIf="!isLoadingUserDetails && sidebarUser">
        <h4 translate="peopleAnag.SIDEBAR_TITLE_ADMIN"></h4>

        <div class="name-section section-divider">
            <svg-icon class="icon" [applyClass]="true" src="/assets/img/icons/contacts.svg">
            </svg-icon>
            <h5>{{ sidebarUser.user.surname + ' ' + sidebarUser.user.forename || '' }}</h5>
        </div>
        <!-- Informazioni generiche utente -->
        <div class="info-container section-divider">
            <div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.NAME_SURNAME"></h5>
                    <p>{{ sidebarUser.user.surname + ' ' + sidebarUser.user.forename || '' }}</p>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.SYNC"></h5>
                    <europ-switch [leftLabelObject]="('generic.OFF' | translate)"
                        [rightLabelObject]="('generic.ON' | translate)" [checked]="sidebarUser.user.isInSync"
                        [disabled]="isProcessClosed()" (onSwitch)="toggleUserSync()">
                    </europ-switch>
                </div>
            </div>
            <div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.CID"></h5>
                    <p>{{ sidebarUser.user.cid || '--' }}</p>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.CF"></h5>
                    <p>{{ sidebarUser.user.codFisc }}</p>
                </div>
            </div>
            <div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.EMAIL"></h5>
                    <p alt="sidebarUser.email">{{ sidebarUser.user.email }}</p>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.GENDER"></h5>
                    <p>{{ sidebarUser.user.chiaveSesso ? (('filter.' + sidebarUser.user.chiaveSesso) | translate) : '--'
                        }}</p>
                </div>
            </div>
            <div class="info-row divider">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.BIRTHDATE"></h5>
                    <p>{{ sidebarUser.user.dataNascita ? sidebarUser.user.dataNascita : '--'}}
                    </p>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.HIREDATE"></h5>
                    <p>{{ sidebarUser.user.dataAssunzione ? sidebarUser.user.dataAssunzione : '--'}}</p>
                </div>
            </div>
            <div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.COUNTRY"></h5>
                    <p>{{ sidebarUser.user.country || '--'}}</p>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.STRUCTURE"></h5>
                    <p>{{ sidebarUser.userStructure || '--'}}</p>
                </div>
            </div>
            <div class="info-row divider">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.BRANCH"></h5>
                    <p>{{ sidebarUser.user.ramo || '--'}}</p>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.DEPARTMENT"></h5>
                    <p>{{ sidebarUser.user.chief }}</p>
                </div>
            </div>

            <!-- Stato inclusione processo -->
            <div class="info-row divider">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.STATUS"></h5>
                    <div class="pill-status sidebar"
                        [ngClass]="{'included': sidebarUser.isInProcess, 'excluded': !sidebarUser.isInProcess}">
                        <p>{{ (sidebarUser.isInProcess ?
                            'process.details.STATUS_INCLUDED' :
                            'process.details.STATUS_EXCLUDED')
                            | translate }}</p>
                    </div>
                </div>
                <div class="info-content">
                    <europ-button
                        [label]="(sidebarUser.isInProcess ? 'peopleAnag.infoBox.EXCLUDE_PROCESS' : 'peopleAnag.infoBox.INCLUDE_PROCESS') | translate | uppercase"
                        [disabled]="isProcessClosed()" type="primary bordless" size="medium"
                        (onButtonClicked)="openIncludeExcludeUserModal()">
                    </europ-button>
                </div>
            </div>
            <!-- Ruolo -->
            <div class="info-row" [ngClass]="{'edit-container': sidebarUser.editStiOpened}">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.ROLE"></h5>
                    <p>{{ sidebarUser.roleName }}</p>
                </div>
                <div class="info-content">
                    <!-- Modifica ruolo -->
                    <ng-container *ngIf="!sidebarUser.editStiOpened">
                        <europ-button [label]="'peopleAnag.infoBox.EDIT_STI' | translate | uppercase"
                            [disabled]="isProcessClosed()" type="primary bordless" size="medium"
                            (onButtonClicked)="toggleEditSti()">
                        </europ-button>
                    </ng-container>
                </div>
                <!-- Container scelta ruolo, sti, validità sti -->
                <ng-container *ngIf="sidebarUser.editStiOpened">
                    <div class="sti-container">
                        <h5 translate="peopleAnag.infoBox.EDIT_STI"></h5>
                        <!-- Validità sti -->
                        <!--[tooltipModalMessage]="stiValidityTooltip"-->
                        <input-container [isValued]="!!selectedValidity" [isWithoutInfoIcon]="true"
                            [isObbligatory]="true" title="{{'peopleAnag.infoBox.STI_VALIDITY' | translate}}">
                            <europ-radio [items]="stiValidityList" [bindValue]="selectedValidity" radioName="choice"
                                (onItemChange)="chooseStiValidity($event)">
                            </europ-radio>
                        </input-container>
                        <!-- modifica ruolo sti -->
                        <input-container [isWithoutInfoIcon]="true"
                            title="{{'peopleAnag.infoBox.EDIT_USER_ROLE' | translate}}"
                            [isValued]="sidebarUser.role != null || newRole" [isObbligatory]="true">
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="chooseStiRole($event)"
                                [selectedItem]="newRole" [items]="profileList" [searchable]="false" bindLabel="id"
                                [placeholder]="'peopleAnag.infoBox.CHOOSE_ROLE' | translate">
                            </europ-select>
                        </input-container>
                        <!-- Valore sti -->
                        <input-container [isWithoutInfoIcon]="true"
                            [disabled]="(!sidebarUser.role || !sidebarUser.role.isSti) && (!newRole || !newRole.isSti)"
                            [isValued]="newStiValue" [isObbligatory]="true"
                            title="{{'peopleAnag.infoBox.INSERT_STI' | translate}}">
                            <europ-input [bindValue]="newStiValue" (onModelChanged)="stiValueChanged($event)"
                                typeInput="number"
                                placeholder="{{'peopleAnag.infoBox.INSERT_STI_PLACEHOLDER' | translate}}">
                            </europ-input>
                        </input-container>
                        <div class="manager-row-action">
                            <europ-button [label]="'generic.CANCEL' | translate" type="primary bordless" size="medium"
                                (onButtonClicked)="cancelRoleChange()" [disabled]="isLoadingManagerList">
                            </europ-button>
                            <europ-button [label]="'generic.CONFIRM' | translate" type="primary" size="medium"
                                (onButtonClicked)="openConfirmRoleChange()" [disabled]="isConfirmRoleChangeDisabled()">
                            </europ-button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Sti e modifiche sti -->
            <div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.CURRENT_STI"></h5>
                    <p [ngClass]="{'sti-value': sidebarUser.stiValue >= 0}">{{ sidebarUser.stiValue >= 0 ? ('ON - ' +
                        (sidebarUser.stiValue | currency:'':'') + " €") : '--'}}</p>
                </div>
                <!-- Modifiche programmate -->
                <div class="info-content">
                    <div class="title-with-info">
                        <h5 translate="peopleAnag.infoBox.PLANNED_EDIT"></h5>
                        <ng-container *ngIf="sidebarUser.hasPlannedChanges">
                            <!-- numero modifiche programmate, mostra sempre 1 pare -->
                            <p class="notify-edit">1</p>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="sidebarUser.plannedChanges != null; else noPlannedChanges">
                        <div class="planned-changes-container">
                            <div class="planned-row">
                                <p class="planned-text">
                                    {{'peopleAnag.infoBox.ROLE' | translate}}:
                                    <b class="planned-red">
                                        {{sidebarUser.plannedChanges.roleName}}
                                    </b>
                                </p>
                            </div>
                            <div class="planned-row">
                                <p class="planned-text">
                                    {{'peopleAnag.infoBox.STI' | translate}}:
                                    <b class="planned-red">
                                        {{sidebarUser.plannedChanges.stiValue > 0 ?
                                        ((sidebarUser.plannedChanges.stiValue | currency:'':'') + " €")
                                        :'--'}}
                                    </b>
                                </p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noPlannedChanges>
                        <p translate="peopleAnag.infoBox.NONE"></p>
                    </ng-template>
                </div>
            </div>

            <!-- Modifica responsabile -->
            <div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.MANAGER"></h5>
                    <p>{{ sidebarUser.title ? sidebarUser.title : ('generic.NO_ONE' | translate) }}
                    </p>
                </div>
                <!-- Apri modifica responsabile-->
                <div class="info-content">
                    <europ-button [label]="'peopleAnag.infoBox.EDIT_MANAGER' | translate | uppercase"
                        type="primary bordless" size="medium" (onButtonClicked)="toggleEditUserManager()"
                        [disabled]="isProcessClosed()">
                    </europ-button>
                </div>
            </div>

            <!-- container modifica responsabile-->
            <div class="info-row" *ngIf="sidebarUser.isEditManager">
                <div class="sti-container">
                    <h5 translate="peopleAnag.infoBox.EDIT_MANAGER"></h5>
                    <!-- Select per modificare il manager -->
                    <input-container [isWithoutInfoIcon]="true" [isValued]="sidebarUser.managerUser"
                        [isObbligatory]="true" title="{{'peopleAnag.infoBox.EDIT_MANAGER_SUB' | translate}}">
                        <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeUserManager($event)"
                            [selectedItem]="sidebarUser.managerUser" [items]="managerList" bindLabel="title"
                            [isLoadingSearchResult]="isLoadingManagerList" [searchable]="true"
                            [placeholder]="'peopleAnag.infoBox.EDIT_MANAGER' | translate"
                            [startSearchLabel]="'peopleAnag.infoBox.EDIT_MANAGER_SEARCH_PLH' | translate"
                            (onSearch)="onSearchTextChanged($event)">
                        </europ-select>
                    </input-container>
                    <div class="manager-row-action">
                        <europ-button [label]="'generic.CANCEL' | translate" type="primary bordless" size="medium"
                            (onButtonClicked)="cancelManagerChange()" [disabled]="isLoadingManagerList">
                        </europ-button>
                        <europ-button [label]="'generic.CONFIRM' | translate" type="primary" size="medium"
                            (onButtonClicked)="confirmManagerChange()" [disabled]="isLoadingManagerList">
                        </europ-button>
                    </div>
                </div>
            </div>

            <!-- Ruolo admin e ruolo talent - UPDATE 17/1/24: Eliminato dalle tavole -->
            <!--div class="info-row">
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.ADMIN_ROLE"></h5>
                    <europ-check id="adminCheck" class="margin-top8" [checked]="sidebarUser.isAdmin" [label]="((sidebarUser.isAdmin ? 'peopleAnag.infoBox.IS_ADMIN' : 'peopleAnag.infoBox.NOT_ADMIN' )|
                        translate)" [whiteBackground]="true" (click)="toggleIsAdmin()">
                    </europ-check>
                </div>
                <div class="info-content">
                    <h5 translate="peopleAnag.infoBox.TALENT_ROLE"></h5>
                    <europ-check id="talentCheck" class="margin-top8" [checked]="sidebarUser.isTalent" [label]="((sidebarUser.isTalent ? 'peopleAnag.infoBox.IS_TALENT' : 'peopleAnag.infoBox.NOT_TALENT') |
                        translate)" [whiteBackground]="true" (click)="toggleIsTalent()">
                    </europ-check>
                </div>
            </div-->
        </div>

        <div class="process-list-container">
            <h6 translate="peopleAnag.infoBox.LIST_PROCESS"></h6>
            <ng-container *ngFor="let item of sidebarUser.includedInProcess">
                <p class="list-item">{{ item.groupYear + ' - ' + item.processName }}</p>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="isLoadingUserDetails">
        <div class="loader-container">
            <div class="loader spinner-medium"></div>
        </div>
    </ng-container>
</ng-template>


<!-- Pagina lista partecipanti -->
<div class="admin-table-container column-spacing">
    <p class="section-title">{{ ('process.details.USER_LIST' | translate) }}</p>
    <!-- anagrafica -->
    <div class="gray-box-container">
        <ng-container *ngIf="!isLoadingCounterData && counterData">
            <div class="gray-box">
                <div class="text-info">
                    <p class="number">{{ counterData.usersInAnag }}</p>
                    <p class="description" translate="process.details.IN_ANAG"></p>
                </div>
                <div class="excels">
                    <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg"></svg-icon>
                    <p translate="process.details.DOWNLOAD_CURRENT_LIST" (click)="downloadProcessParticipantReport()">
                    </p>
                </div>
            </div>
            <!-- Inclusi -->
            <div class="gray-box">
                <div class="text-info">
                    <p class="number">{{counterData.usersInProcesscount }}</p>
                    <p class="description" [innerHTML]="('process.details.INCLUDED' | translate)"></p>
                </div>
                <div class="excels" [ngClass]="{'disabled': isProcessClosed()}" (click)="openIncludePeopleModal()">
                    <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg"></svg-icon>
                    <p translate="process.details.INCLUDE_PEOPLE"></p>
                </div>
            </div>
            <!-- Esclusi -->
            <div class="gray-box">
                <div class="text-info">
                    <p class="number">{{ counterData.usersNotInProcesscount }}</p>
                    <p class="description" [innerHTML]="('process.details.EXCLUDED' | translate)"></p>
                </div>
                <div class="excels" [ngClass]="{'disabled': isProcessClosed()}" (click)="openExcludePeopleModal()">
                    <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg"></svg-icon>
                    <p translate="process.details.EXCLUDE_PEOPLE"></p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoadingCounterData">
            <div class="loader-container counter-loader">
                <div class="loader spinner-medium"></div>
            </div>
        </ng-container>


    </div>

    <!-- Barra di ricerca -->
    <div class="search-filters-container">
        <div class="admin-search-container bottom-filters">
            <div class="search-wrapper">
                <europ-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                    (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                    [placeholder]="'process.details.SEARCH_BY' | translate" [isSearchBar]="true">
                </europ-input>
            </div>
        </div>
        <!-- Recap filtri -->
        <div class="filters-recap-container">
            <div class="left-column">
                <ng-container *ngIf="!selectedFiltersNumber">
                    <p class="placeholder" translate="adminSearch.NO_FILTER_ACTIVE"></p>
                </ng-container>
                <ng-container *ngIf="selectedFiltersNumber == 1">
                    <p class="placeholder" [innerHTML]="'adminSearch.ONE_FILTER' | translate"></p>
                </ng-container>
                <ng-container *ngIf="selectedFiltersNumber > 1">
                    <p class="placeholder"><b>{{selectedFiltersNumber}}</b>&nbsp;{{ 'adminSearch.MORE_FILTERS' |
                        translate }}
                    </p>
                </ng-container>
                <ng-container *ngIf="selectedFiltersNumber >= 1">
                    <p class="delete-filters" translate="adminSearch.DELETE_FILTERS" (click)="clearFilters()"></p>
                </ng-container>
                <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
            </div>
            <div class="right-column" (click)="openFiltersSection()">
                <p class="manage-filters" translate="adminSearch.MANAGE_FILTERS"></p>
                <ng-container *ngIf="!isFilterOpened">
                    <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
                </ng-container>
                <ng-container *ngIf="isFilterOpened">
                    <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
                </ng-container>
            </div>
        </div>
        <!-- contenitore filtri -->
        <ng-container *ngIf="isFilterOpened">
            <div class="filters-container">
                <div class="filters-content">
                    <div class="generic-container">
                        <!-- Struttura -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureTooltipModal"
                            [isValued]="selectedFilters.structures" [isObbligatory]="true"
                            title="{{'calibration.filters.STRUCTURE' | translate}}">
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'structures')"
                                [selectedItem]="selectedFilters.structures" [items]="filtersObject.structures"
                                [searchable]="false" bindLabel="id">
                            </europ-select>
                        </input-container>
                        <!-- Funzione -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="functionTooltipModal"
                            [isValued]="selectedFilters.funzione" [isObbligatory]="true"
                            title="{{'calibration.filters.FUNCTION' | translate}}">
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'funzione')"
                                [selectedItem]="selectedFilters.funzione" [items]="filtersObject.funzione"
                                [searchable]="true" bindLabel="id">
                            </europ-select>
                        </input-container>

                    </div>

                    <div class="generic-container">

                        <!-- Mansione -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="jobTooltipModal"
                            [isValued]="selectedFilters.mansione" [isObbligatory]="true"
                            title="{{'calibration.filters.JOB' | translate}}">
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'mansione')"
                                [selectedItem]="selectedFilters.mansione" [items]="filtersObject.mansione"
                                [searchable]="true" bindLabel="id">
                            </europ-select>
                        </input-container>

                        <!-- Area -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="areaTooltipModal"
                            [isValued]="selectedFilters.area" [isObbligatory]="true"
                            title="{{'calibration.filters.AREA' | translate}}">
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'area')"
                                [selectedItem]="selectedFilters.area" [items]="filtersObject.area" [searchable]="true"
                                bindLabel="id">
                            </europ-select>
                        </input-container>

                    </div>


                    <div class="generic-container">
                        <!-- Struttura 3 livello -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel3TooltipModal"
                            [isValued]="selectedFilters.denominazioneSO3" [isObbligatory]="true"
                            title="{{'calibration.filters.STRUCTURE_LEVEL_3' | translate}}">
                            <europ-select [inMinWidthForIe]="true"
                                (onSelectedItem)="selectFilter($event, 'denominazioneSO3')"
                                [selectedItem]="selectedFilters.denominazioneSO3"
                                [items]="filtersObject.denominazioneSO3" [searchable]="true" bindLabel="id">
                            </europ-select>
                        </input-container>

                        <!-- Struttura 5 livello -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="structureLevel5TooltipModal"
                            [isValued]="selectedFilters.denominazioneSO5" [isObbligatory]="true"
                            title="{{'calibration.filters.STRUCTURE_LEVEL_5' | translate}}">
                            <europ-select [inMinWidthForIe]="true"
                                (onSelectedItem)="selectFilter($event, 'denominazioneSO5')"
                                [selectedItem]="selectedFilters.denominazioneSO5"
                                [items]="filtersObject.denominazioneSO5" [searchable]="true" bindLabel="id">
                            </europ-select>
                        </input-container>

                    </div>

                    <div class="generic-container">
                        <!-- STI -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="stiFilterTooltipModal"
                            [isValued]="selectedFilters.sti" [isObbligatory]="true"
                            title="{{'calibration.filters.STI_FILTER' | translate}}">
                            <europ-select [inMinWidthForIe]="true" (onSelectedItem)="selectFilter($event, 'sti')"
                                [selectedItem]="selectedFilters.sti" [items]="filtersObject.sti" [searchable]="false"
                                bindLabel="id">
                            </europ-select>
                        </input-container>
                        <!-- Sesso -->
                        <input-container [isWithoutInfoIcon]="false" [tooltipModalMessage]="genderFilterTooltipModal"
                            [isValued]="selectedFilters.chiaveSesso" [isObbligatory]="true"
                            title="{{'calibration.filters.GENDER' | translate}}">
                            <europ-select [inMinWidthForIe]="true"
                                (onSelectedItem)="selectFilter($event, 'chiaveSesso')"
                                [selectedItem]="selectedFilters.chiaveSesso" [items]="filtersObject.chiaveSesso"
                                [searchable]="false" bindLabel="id">
                            </europ-select>
                        </input-container>
                    </div>

                    <div class="filter-action">
                        <europ-button [label]="'generic.APPLY_FILTERS' | translate" type="primary"
                            (onButtonClicked)="applyFilters()" [disabled]="isLoadingParticipantData">
                        </europ-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- contenitore tabella -->
    <ng-container
        *ngIf="!isLoadingParticipantData && participantData && participantData.list && participantData.list.length">

        <div class="table-content process-structure">
            <table>
                <thead>
                    <tr>
                        <th [translate]="'process.details.NAME' | translate"></th>
                        <th [translate]="'process.details.CID' | translate"></th>
                        <th [translate]="'process.details.ROLE' | translate"></th>
                        <th [translate]="'process.details.STI' | translate"></th>
                        <th [translate]="'process.details.HIRING_DATE' | translate"></th>
                        <th [translate]="'process.details.STATUS' | translate"></th>
                        <th [translate]="'adminTable.ACTIONS' | translate"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of participantData.list | 
                            slice:0:participantData.numRecords | 
                            paginate: { id: 'userList',
                                    itemsPerPage: participantData.numRecords,
                                    currentPage: participantData.page,
                                    totalItems: participantData.counter }">
                        <td>{{ user.surname + ' ' + user.forename }}</td>
                        <td>
                            <div class="count">
                                {{ user.cid }}
                            </div>
                        </td>
                        <td class="center-cell">
                            <div class="cell-with-alert">
                                {{ user.profileColumn }}
                                <svg-icon *ngIf="user.hasPlannedChanges" class="alert-icon" [applyClass]="true"
                                    src="assets/img/icons/alert-circle.svg">
                                </svg-icon>
                            </div>
                        </td>
                        <td>
                            <item-list-team-status
                                [message]="((user.stiAmountByYear && user.stiAmountByYear[processYear] ? 'generic.YES' : 'generic.NO' ) | translate)"
                                [type]="user.stiAmountByYear && user.stiAmountByYear[processYear] ? 'completed' : 'disabled' "
                                styleItem="tag">
                            </item-list-team-status>
                        </td>
                        <td>{{ user.dataAssunzione ? user.dataAssunzione : '--' }}</td>
                        <td>
                            <div class="pill-status"
                                [ngClass]="{'included': user.isInProcess, 'excluded': !user.isInProcess}">
                                <p>{{ (user.isInProcess ?
                                    'process.details.STATUS_INCLUDED' :
                                    'process.details.STATUS_EXCLUDED')
                                    | translate }}</p>
                            </div>
                        </td>
                        <td class="action">
                            <svg-icon class="action-icon" [applyClass]="true" (click)="toggleAnagSidebar(user)"
                                src="/assets/img/icons/contacts.svg">
                            </svg-icon>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="bottom-actions">
            <table-pagination id="userList" (pageChanged)="pageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
                <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                <europ-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                    [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                    [tablePagination]="true">
                </europ-select>
            </div>
        </div>

    </ng-container>

    <ng-container
        *ngIf="!isLoadingParticipantData && (!participantData || !participantData.list || !participantData.list.length)">
        <div class="admin-no-data-wrapper">
            <p translate="process.details.NO_DATA"></p>
        </div>
    </ng-container>
    <!-- loader -->
    <ng-container *ngIf="isLoadingParticipantData">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div>




<!-- Modale inclusione persone -->
<modal id="includePeople" (onClose)="closeIncludePeopleModal()" (onConfirm)="closeIncludePeopleModal(true)"
    [confirmLabel]="('process.manage.INCLUDE' | translate)" [isDisabled]="!includeFileObject">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.INCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="download-track-container">
            <europ-button (onButtonClicked)="downloadIncludeTrack()" [disabled]="isDownloadingIncludeTrack"
                type="primary bordless" size="medium" [label]="('process.manage.DOWNLOAD_TRACK' | translate)">
            </europ-button>
        </div>
        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="includeFileObject" [isObbligatory]="true"
            title="{{'process.manage.INCLUDE_TRACK' | translate}}">
            <europ-upload id="include" [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event, true)"
                [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet application/excel application/vnd.ms-excel application/x-excel application/x-msexcel'">
            </europ-upload>
        </input-container>
    </div>
</modal>

<!-- Modale esclusione persone -->
<modal id="excludePeople" (onClose)="closeExcludePeopleModal()" (onConfirm)="closeExcludePeopleModal(true)"
    [confirmLabel]="('process.manage.EXCLUDE' | translate)" [isDisabled]="!excludeFileObject">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.EXCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="download-track-container">
            <europ-button (onButtonClicked)="downloadExcludeTrack()" [disabled]="isDownloadingExcludeTrack"
                type="primary bordless" size="medium" [label]="('process.manage.DOWNLOAD_TRACK' | translate)">
            </europ-button>
        </div>
        <!-- Upload lista persone da escludere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="excludeFileObject" [isObbligatory]="true"
            title="{{'process.manage.EXCLUDE_TRACK' | translate}}">
            <europ-upload id="exclude" [isToReset]="resetExcludeUpload" (onUpload)="assignFileExclude($event)"
                [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet application/excel application/vnd.ms-excel application/x-excel application/x-msexcel'">
            </europ-upload>
        </input-container>
    </div>
</modal>

<!-- Modale filtri -->
<modal id="filtersModal" (onClose)="closeFiltersModal()" (onConfirm)="applyFilters()"
    [confirmLabel]="('generic.APPLY_FILTERS' | translate)" (onCancel)="clearFilters()"
    [cancelLabel]="('generic.DELETE_FILTERS' | translate)">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.EXCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="filter-recap">
            <p class="subtitle" translate="process.manage.APPLY_FILTERS"></p>
            <p>{{ 1 + ('process.manage.FILTER_ACTIVE' | translate ) }}</p>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per sti -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STI"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card" *ngFor="let sti of stiFilters" [ngClass]="{'selected': sti.isChecked}"
                    (click)="toggleFilter(sti)">
                    <p>{{ sti.title }}</p>
                </div>
            </div>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per stato -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STATUS"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card" *ngFor="let status of statusFilters" [ngClass]="{'selected': status.isChecked}"
                    (click)="toggleFilter(status)">
                    <p>{{ status.title }}</p>
                </div>
            </div>
        </div>
        <!-- Filtro per definizione (struttura) -->
        <div class="filter-container">
            <p class="name" translate="process.manage.DEFINITION"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <ng-container>
                    <div class="filter-card" *ngFor="let definition of definitionFilters"
                        [ngClass]="{'selected': definition.isChecked}" (click)="toggleFilter(definition)">
                        <p>{{ definition.title }}</p>
                    </div>
                </ng-container>
            </div>
            <p class="more-link" translate="process.manage.SHOW_ALL"></p>
            <p class="more-link" translate="process.manage.SHOW_LESS"></p>
        </div>
    </div>
</modal>

<modal id="includeExcludeUser" (onClose)="closeIncludeExcludeUser()" (onConfirm)="closeIncludeExcludeUser(true)"
    [confirmLabel]="((includeExcludeUserData && includeExcludeUserData.isInProcess ? 'process.manage.USER_EXCLUDE' : 'process.manage.USER_INCLUDE') | translate )"
    (onCancel)="closeIncludeExcludeUser()" [cancelLabel]="('generic.CANCEL' | translate)"
    [isDisabled]="includeExcludeUserData && includeExcludeUserData.isInProcess && !includeExcludeUserData.motivation">
    <ng-container *ngIf="includeExcludeUserData">
        <modal-text-content [modalId]="'exUs001'"
            [title]="(includeExcludeUserData.isInProcess ? 'process.manage.USER_EXCLUDE_TITLE' : 'process.manage.USER_INCLUDE_TITLE') | translate"
            [subtitle]="(includeExcludeUserData.isInProcess ? 'process.manage.USER_EXCLUDE_SUB' : 'process.manage.USER_INCLUDE_SUB') | translate"
            [text]="includeExcludeUserData.modalDescr">
        </modal-text-content>
        <div class="sti-modal-container" *ngIf="includeExcludeUserData.isInProcess">
            <input-container [isFromTextArea]="true"
                [isValued]="includeExcludeUserData.motivation && includeExcludeUserData.motivation.trim().length"
                [isObbligatory]="true" title="{{'process.manage.INSERT_MOTIVATION' | translate}}">
                <europ-text-area [bindValue]="includeExcludeUserData.motivation" [maxlength]="600"
                    (onModelChanged)="onExcludeMotivationChanged($event)"
                    placeholder="{{'process.manage.INSERT_MOTIVATION_PLH' | translate}}">
                </europ-text-area>
            </input-container>
        </div>
    </ng-container>
</modal>

<!-- Modale di congerma aggancio/sgancio utente dalla sync -->
<modal id="user-sync-modal" (onClose)="closeUserSyncModal()" (onConfirm)="closeUserSyncModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" (onCancel)="closeUserSyncModal()"
    [cancelLabel]="('generic.CANCEL' | translate)">
    <modal-text-content *ngIf="this.sidebarUser && this.sidebarUser.user" modalId="ussy001"
        [title]="(this.sidebarUser.user.isInSync ? 'process.manage.USER_ADD_SYNC_TITLE' : 'process.manage.USER_REMOVE_SYNC_TITLE') | translate"
        [text]="(this.sidebarUser.user.isInSync ? 'process.manage.USER_ADD_SYNC_TEXT' : 'process.manage.USER_REMOVE_SYNC_TEXT') | translate">
    </modal-text-content>
</modal>

<!-- Modale di conferma cambio ruolo -->
<modal id="confirm-role-change" (onClose)="closeConfirmRoleChange()" (onConfirm)="closeConfirmRoleChange(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" (onCancel)="closeConfirmRoleChange()"
    [cancelLabel]="('generic.CANCEL' | translate)">
    <modal-text-content modalId="crc001" [title]="'process.manage.CONFIRM_ROLE_TITLE'| translate"
        [text]="'process.manage.CONFIRM_ROLE_TEXT'| translate">
    </modal-text-content>
</modal>