import { Component, Input } from '@angular/core';

@Component({
  selector: 'talent-tab-header',
  template: `
    <div class="tab-header">
      <figure>
        <img src="../../../assets/img/talent/image.svg" alt="valutazione del potenziale">
      </figure>
      <div class="tab-header__content">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
      </div>
    </div>
  `,
  styleUrls: ['./talent-tab-header.component.scss']
})
export class TalentTabHeaderComponent {

  @Input() title: string = '';

  @Input() description: string = '';

  constructor() { }

}
