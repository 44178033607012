import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { reducers } from './ngrx/app.reducers';
import { CoreEffects } from './core/ngrx/core.effects';
import { AuthEffects } from './auth/ngrx/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { StoreModule, Store } from '@ngrx/store';
import { AuthService } from './auth/services/auth.service';
import { ModalService } from './shared/components/modal/modal.service';
import { SidebarService } from './shared/services/sidebar.service';
import { ComponentExampleComponent } from './components-example/components-example.component';
import { TranslateService } from '@ngx-translate/core';
@NgModule({
  declarations: [
    AppComponent,
    ComponentExampleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, CoreEffects]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    Store,
    AuthService,
    ModalService,
    SidebarService,
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
