<page-container>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="('phases.' + phaseKey) | translate" [description]="('process.phases.SIDENAV_DESCR' | translate)">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingPhaseData">
                <div class="form-container">
                    <!-- Data di inizio fase -->
                    <input-container [isObbligatory]="true" [isValued]="phaseData.phaseStartDate"
                        title="{{'process.phases.PHASE_START' | translate}}" [isWithoutInfoIcon]="true">
                        <europ-datepicker [value]="phaseData.phaseStartDate" (dateChanged)="selectDate($event, 'start')"
                            [maxDate]="phaseData.phaseEndDate" placeholder="process.phases.START_DATE_PLH">
                        </europ-datepicker>
                    </input-container>

                    <ng-container *ngIf="phaseKey == 'FINAL_EVALUATION' && isManagerDate; else others">
                        <!-- Data di fine fase Per manager in final evaluation è la stessa della data di chiusura. E' quindi disabilitata e modificata con quella -->
                        <input-container [isValued]="phaseData.phaseEndDate" [isWithoutInfoIcon]="true"
                            [disabled]="true"
                            [title]="(phaseKey == 'MID_TERM_REVIEW'? 'process.phases.PHASE_END_COLL_MTR' : 'process.phases.PHASE_END_DESCR') | translate">
                            <europ-datepicker [value]="phaseData.phaseEndDate" (dateChanged)="selectDate($event, 'end')"
                                placeholder="process.phases.END_DATE_PLH">
                            </europ-datepicker>
                        </input-container>
                        <!-- Data di chiusura fase -->
                        <input-container [isValued]="phaseData.phaseClosingDate" [isWithoutInfoIcon]="true"
                            title="{{('process.phases.PHASE_CLOSE' | translate) + (isMTRorFE ? ('process.phases.PHASE_CLOSE_ADDITION' | translate) : '')}}">
                            <europ-datepicker [value]="phaseData.phaseClosingDate" [minDate]="phaseData.phaseStartDate"
                                (dateChanged)="selectDate($event, 'closing')"
                                placeholder="process.phases.CLOSING_DATE_PLH">
                            </europ-datepicker>
                        </input-container>
                    </ng-container>

                    <ng-template #others>
                        <!-- Data di fine fase -->
                        <input-container [isValued]="phaseData.phaseEndDate" [isWithoutInfoIcon]="true"
                            [title]="(phaseKey == 'MID_TERM_REVIEW'? 'process.phases.PHASE_END_COLL_MTR' : 'process.phases.PHASE_END_DESCR') | translate">
                            <europ-datepicker [value]="phaseData.phaseEndDate" [minDate]="phaseData.phaseStartDate"
                                [maxDate]="phaseData.phaseClosingDate" (dateChanged)="selectDate($event, 'end')"
                                placeholder="process.phases.END_DATE_PLH">
                            </europ-datepicker>
                        </input-container>
                        <!-- Data di chiusura fase -->
                        <input-container [isValued]="phaseData.phaseClosingDate" [isWithoutInfoIcon]="true"
                            title="{{('process.phases.PHASE_CLOSE' | translate) + (isMTRorFE ? ('process.phases.PHASE_CLOSE_ADDITION' | translate) : '')}}">
                            <europ-datepicker [value]="phaseData.phaseClosingDate" [minDate]="phaseData.phaseEndDate"
                                (dateChanged)="selectDate($event, 'closing')"
                                placeholder="process.phases.CLOSING_DATE_PLH">
                            </europ-datepicker>
                        </input-container>
                    </ng-template>

                    <div class="button-container">
                        <europ-button (onButtonClicked)="redirectService.goBackBrowser()" type="primary bordless"
                            size="large" [label]="'generic.CANCEL' | translate">
                        </europ-button>
                        <europ-button (onButtonClicked)="updatePhaseDate()" [disabled]="isLoadingPhaseData"
                            type="primary default" size="large" [label]="'process.phases.EDIT_DATES' | translate">
                        </europ-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingPhaseData">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>