import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { GetPerformanceAssessmentBannerPhaseResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, JwtPayload, PerformanceAssessmentPhase, SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ManagerService } from '../../shared/services/manager.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subordinateservice } from '../services/subordinates.service';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { catchError, switchMap, take } from 'rxjs/operators';
import { UserCard } from 'src/app/utils/classes.utils';

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  runningYear: number = 0;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponse[] = [];
  loggedUser: any;
  combinedSelected$: Subscription;
  phaseName: string = "";
  bannerImage: string = '';
  updateUserAck$: any;
  userAcknowledges: any;
  runningPhase: any;
  isLoadingCurrentPhase: boolean = true;
  currentYear = new Date().getFullYear();
  isLoadingYearList: boolean = false;
  getYearsList$: any;
  yearList: any;
  selectedYear: any;
  // Filtri
  isLoadingFilters: boolean = true;
  getFiltersData$: Subscription = new Subscription;
  filtersObject: any = {};
  allChecked: boolean = false;
  selectedFilters: any = {};
  genderFilterTooltipModal: ApplicationModalMessage = {
    modalId: 'cal021',
    title: '',
    text: ''
  }
  selectedFiltersNumber: number = 0;
  areaTooltipModal: ApplicationModalMessage = {
    modalId: 'cal023',
    title: '',
    text: ''
  }
  structureLevel5TooltipModal: ApplicationModalMessage = {
    modalId: 'cal024',
    title: '',
    text: ''
  }

  whoTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal014',
    title: '',
    text: ''
  };
  structureTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal015',
    title: '',
    text: ''
  };
  structureLevel3TooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal016',
    title: '',
    text: ''
  };
  jobTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal017',
    title: '',
    text: ''
  };
  functionTooltipModal: { modalId: string; title: any; text: any; } = {
    modalId: 'cal019',
    title: '',
    text: ''
  };
  // fine filtri

  loadAccordionData: boolean = false;
  // Goal Setting
  goalSettingOpen: boolean = false;
  goalSettingPercentage: number = 25;
  /** Obiettivi individuali */
  individualObjectives: any;

  /** Indicatore sfide */
  challengeObjectives: any;

  careColors: string[] = ["#E4002B", "#F5B336", "#88DBFF", "#25BB48"];

  // Mid Term Review
  midTermOpen: boolean = false;
  /** Mid term review */
  midTermData: any;

  /** Valutazione finale degli obiettivi */
  objectiveFEData: any;
  /** Valutazione finale performance level */
  performanceLevelData: any;

  actionColor: string[] = ["#E4002B", "#F5B336", "#25BB48"];
  midTermColor: string[] = ["#E4002B", "#25BB48", "#DBDBDB"];
  /** Piano di sviluppo. Azioni inserite dal manager */
  managerActions: any;

  /** Piano di sviluppo. Azioni inserite dal collaboratore */
  contributorAction: any;

  feedbackData: any[] = [];
  feedbackColors: string[] = ['#FF809F', '#E4002B', '#88DBFF', '#4C5BDD', '#25BB48', '#F7B435'];
  translations: any;

  /** Overview finale */
  productionData: any[] = [];

  finalPalette: string[] = ['#F7B435', '#E4002B', '#88DBFF', '#3656F7']
  chartInstances: any = {};
  allowedFormats = ['PNG', 'PDF', 'JPEG'];

  getGoalSettingData$: Subscription = new Subscription;
  isLoadingGoalSettingData: boolean = false;

  getMidTermData$: Subscription = new Subscription;
  isLoadingMidTermData: boolean = false;
  getFinalEvaluationData$: Subscription = new Subscription;
  isLoadingFinalEvaluation: boolean = false;
  isFilterOpened: boolean = false;
  isLoadingDevelopmentPlanData: boolean = false;
  getDevelopmentData$: Subscription = new Subscription;
  contributorActionTotal: any;
  managerActionTotal: any;
  // dati filtro utenti
  searchUserText: string = '';
  userCardData: {
    fromRecord: number,
    numRecords: number,
    counter: number,
    currentPage: number,
    list: UserCard[]
  } = {
      fromRecord: 0,
      numRecords: 10,
      counter: 0,
      currentPage: 1,
      list: []
    };

  selectedUserList: any[] = [];
  isFetchingUsers: boolean = false;
  whoNoteModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  personFilter: boolean = false;
  isLoadingFeedbackData: boolean = false;
  getFeedbackData$: Subscription = new Subscription;
  finalEvaluationPercentage: number = 0;
  midTermPercentage: number = 0;

  isProcessClosed$: Subscription = new Subscription;
  isProcessClosed: boolean = false;
  isLoadingOverviewData: boolean = false;
  getOverviewData$: Subscription = new Subscription;
  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  isFinalEvaluationActive: boolean = false;
  isMidTermActive: boolean = false;

  // dashboard
  dashboardType: string = 'MY_TEAM';
  dashboardTypeStructure: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private managerService: ManagerService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    public redirectService: RedirectService) {

    this.loadAccordionData = true;
    // loader
    this.isLoadingGoalSettingData = true;
    this.isLoadingMidTermData = true;
    this.isLoadingFinalEvaluation = true;
    this.isLoadingDevelopmentPlanData = true;

    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const getRunningPhase$: Observable<GetPerformanceAssessmentBannerPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    const runningYear$: Observable<number> = this.store.select(fromApp.getRunningYear);
    this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$, runningYear$])
      .subscribe(
        ([loggedUser, runningPhase, runningYear]) => {
          if (runningPhase && loggedUser && loggedUser.user && runningYear) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.runningPhase = runningPhase;
            this.runningYear = runningYear;

            this.getPerformanceAssessmentYears();
            this.route.params
              .subscribe((params: Params) => {
                if (params.perfCareYear) {
                  this.selectedYear = {
                    id: params.perfCareYear,
                    name: 'Anno ' + params.perfCareYear
                  }
                } else if(this.runningYear) {
                  this.selectedYear = {
                    id: this.runningYear,
                    name: 'Anno ' + this.runningYear
                  }
                }
                this.getUsers();
                this.getOverviewData()
                this.getGraphData()
              }
              );
          }
        });
  }


  ngOnInit(): void {
    this.translate.get([
      'dashboard.feedbackTypes.SENT_SMART_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_SMART_FEEDBACK',
      'dashboard.feedbackTypes.SENT_PEER_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_PEER_FEEDBACK',
      'dashboard.feedbackTypes.EVIDENCES',
      'dashboard.feedbackTypes.INTERVIEWS',
      'calibration.filters.STRUCTURE',
      'calibration.filters.STRUCTURE_LEVEL',
      'calibration.filters.JOB',
      'calibration.filters.FUNCTION',
      'calibration.filters.STI_FILTER',
      'calibration.filters.DELETE_ALL_FILTERS',
      'calibration.filters.FILTER_ACTIVE',
      'calibration.tooltip.STRUCTURE_DESCR',
      'calibration.tooltip.STRUCTURE_LEVEL_DESCR',
      'calibration.tooltip.JOB_DESCR',
      'calibration.tooltip.FUNCTION_DESCR',
      'calibration.tooltip.STI_FILTER_DESCR',
      'calibration.tooltip.DELETE_ALL_FILTERS_DESCR',
      'calibration.tooltip.FILTER_ACTIVE_DESCR',
      'dashboard.NOT_SHARED',
      'dashboard.TO_APPROVE',
      'dashboard.SHARED',
      'dashboard.APPROVED',
      'dashboard.NOT_REVISIONED',
      'dashboard.REVISIONABLE',
      'dashboard.REVISIONED',
      'filter.ALL',
      'filter.M',
      'filter.F',
      'dashboard.TO_START',
      'dashboard.STARTED',
      'dashboard.COMPLETED',
      'generic.INPUT_INFO',
      'dashboard.MY_EVALS',
      'dashboard.MY_DEPT',
      'dashboard.ENTIRE_COMPANY',
      'dashboard.MY_STRUCTURE',
    ]).subscribe((translations: any) => {
      this.translations = translations;

      this.structureTooltipModal = {
        modalId: "da009",
        title: translations['calibration.filters.STRUCTURE'],
        text: translations['calibration.tooltip.STRUCTURE_DESCR'],
      }

      this.structureLevel3TooltipModal = {
        modalId: "da010",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }
      this.structureLevel5TooltipModal = {
        modalId: "da011",
        title: translations['calibration.filters.STRUCTURE_LEVEL_3'],
        text: translations['calibration.tooltip.STRUCTURE_LEVEL_3_DESCR'],
      }

      this.jobTooltipModal = {
        modalId: "da012",
        title: translations['calibration.filters.JOB'],
        text: translations['calibration.tooltip.JOB_DESCR'],
      }

      this.functionTooltipModal = {
        modalId: "da013",
        title: translations['calibration.filters.FUNCTION'],
        text: translations['calibration.tooltip.FUNCTION_DESCR'],
      }

      this.genderFilterTooltipModal = {
        modalId: "da014",
        title: translations['calibration.filters.GENDER'],
        text: translations['calibration.tooltip.GENDER_DESCR'],
      }

      this.areaTooltipModal = {
        modalId: "da015",
        title: translations['calibration.filters.AREA'],
        text: translations['calibration.tooltip.AREA_DESCR'],
      }

      this.whoNoteModal = {
        modalId: "da016",
        title: translations['generic.INPUT_INFO'],
        subtitle: "Lorem Ipsum",
        text: "Lorem Ipsum"
      };

      setTimeout(() => { this.loadAccordionData = false; }, 2000);
    })
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }

    this.getYearsList$ = this.managerService.getPerformanceAssessmentYears(this.loggedUser.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          let keys = data.response.map((x) => x.assessmentYear);
          keys = keys.sort();
          for (let i = (keys.length - 1); i >= 0; i--) {
            let yearData = data.response.find((x) => x.assessmentYear == keys[i])
            this.yearList.push({
              id: yearData.assessmentYear,
              name: 'Anno ' + yearData.assessmentYear,
              disabled: !yearData.isInProcess
            });
          }
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.router.navigate([url + '/' + this.selectedYear.id])
  }

  openDashboardTooltip() {
    this.modalService.open('dashboard-tooltip');
  }

  closeDashboardTooltip() {
    this.modalService.close('dashboard-tooltip');
  }

  // Personalizza le label delle tabelle
  customizePercentageText(arg: any) {
    return `${arg.valueText} %`;
  }

  customizeSeries(valueFromNameField: string) {
    return valueFromNameField === 'Azienda intera'
      ? { type: 'spline', label: { visible: true }, color: '#3656F7' } : {};
  }


  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
  }

  redrawGraphs(graphSection: any) {
    let graphToRedraw: string[] = [];
    switch (graphSection) {
      case 'goal-setting': {
        graphToRedraw = ["individualObjective", "individualObjetiveCare", "challenge", "challengeCare"];
        break;
      }
      case 'mid-term': {
        graphToRedraw = ["midTermProgress", "individualObjetiveCare"];
        break;
      }
      case 'final-evaluation': {
        graphToRedraw = ["objectiveFinalEvaluation", "performanceLevel"];
        break;
      }
      case 'idp': {
        graphToRedraw = ["actionPie", "contributorActionChart"];
        break;
      }
      case 'feedback': {
        graphToRedraw = ["feedbackChart"];
        break;
      }
      default:
        graphToRedraw = Object.keys(this.chartInstances);
        break;
    }

    if (graphToRedraw && graphToRedraw.length) {
      setTimeout(() => {
        //let keys = Object.keys(this.chartInstances);
        for (let i = 0; i < graphToRedraw.length; i++) {
          this.chartInstances[graphToRedraw[i]].render();
        }
      }, 100);
    }
  }

  selectFilter(data: any, id: any) {
    if (data.id == 'all') {
      this.selectedFiltersNumber--;
    }
    switch (id) {
      case 'structures':
        if (!this.selectedFilters.structures.value && this.selectedFilters.structures != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.structures = data;
        break;
      case 'denominazioneSO3':
        if (!this.selectedFilters.denominazioneSO3.value && this.selectedFilters.denominazioneSO3 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO3 = data;
        break;
      case 'denominazioneSO5':
        if (!this.selectedFilters.denominazioneSO5.value && this.selectedFilters.denominazioneSO5 != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.denominazioneSO5 = data;
        break;
      case 'mansione':
        if (!this.selectedFilters.mansione.value && this.selectedFilters.mansione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.mansione = data;
        break;
      case 'funzione':
        if (!this.selectedFilters.funzione.value && this.selectedFilters.funzione != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.funzione = data;
        break;
      case 'area':
        if (!this.selectedFilters.area.value && this.selectedFilters.area != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.area = data;
        break;
      case 'sti':
        if (!this.selectedFilters.sti.value && this.selectedFilters.sti != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.sti = data;
        break;
      case 'chiaveSesso':
        if (!this.selectedFilters.chiaveSesso.value && this.selectedFilters.chiaveSesso != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.chiaveSesso = data;
        break;
      case 'calibrationStatusTypes':
        if (!this.selectedFilters.calibrationStatusTypes.value && this.selectedFilters.calibrationStatusTypes != data && data.id != 'all') {
          this.selectedFiltersNumber++;
        }
        this.selectedFilters.calibrationStatusTypes = data;
        break;
      default:
        break;
    }
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.getGraphData();
  }

  clearFilters() {
    this.selectedFilters = {
      chiaveSesso: this.filtersObject.chiaveSesso[0],
      denominazioneSO3: this.filtersObject.denominazioneSO3[0],
      denominazioneSO5: this.filtersObject.denominazioneSO5[0],
      area: this.filtersObject.area[0],
      funzione: this.filtersObject.funzione[0],
      mansione: this.filtersObject.mansione[0],
      structures: this.filtersObject.structures[0],
    }
    this.selectedUserList = [];
    this.selectedFiltersNumber = 0;
    this.isFilterOpened = false;
    this.getGraphData();
  }

  // apre i filtri
  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }


  searchUsers() {
    this.getUsers(true);
  }

  switchFilterType() {
    this.personFilter = !this.personFilter;
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetUserData(notDeleteSelected?: boolean) {
    this.userCardData.fromRecord = 0;
    this.userCardData.numRecords = 16;
    this.userCardData.currentPage = 1;
    this.userCardData.counter = 0;
    this.userCardData.list = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }
  // Recupera una lista di utenti per gli applausi
  getUsers(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetUserData(true);
    }
    // Avvio il loader
    this.isFetchingUsers = true;
    this.managerService.countTeamUsers(0, this.searchUserText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.userCardData.counter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.userCardData.currentPage && this.userCardData.numRecords) {
                fromRecord = (this.userCardData.currentPage - 1) * this.userCardData.numRecords;
              } else {
                fromRecord = 0;
              }

              if (this.userCardData.counter) {
                return this.managerService.listTeamUsers(fromRecord, this.userCardData.numRecords, 0, this.searchUserText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "pn010",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isFetchingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "pn011",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.userCardData.list = UserDataUtils.getUserCardData(data.response) || [];
          }
          this.isFetchingUsers = false;
        }
        , (err: any) => {
          this.isFetchingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn012",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Cambia la paginazione alla lista degli utenti per applausi aggiunti
  usersPageChanged(newPage: any) {
    this.userCardData.currentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsers();
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  getSelectedFiltersNumber() {
    if (this.selectedUserList && this.selectedUserList.length) {
      return this.selectedFiltersNumber + 1;
    } else {
      return this.selectedFiltersNumber;
    }
  }

  // inizializza i dati dei grafici
  getGraphData() {
    this.isLoadingGoalSettingData = true;
    this.isLoadingMidTermData = true;
    this.isLoadingFinalEvaluation = true;
    this.isLoadingDevelopmentPlanData = true;
    this.isLoadingFeedbackData = true;
    this.selectedFilters.userIds = this.selectedUserList.map((x: any) => x.id) || [];
    this.getGoalSettingData();
    this.getMidTermData();
    this.getDashboardFinalEvaluation();
    this.getDevelopmentPlanData();
    this.getFeedbackData();
  }


  // controlla se sto caricando qualcosa
  isLoading() {
    return this.isLoadingGoalSettingData || this.isLoadingMidTermData || this.isLoadingFinalEvaluation || this.isLoadingDevelopmentPlanData || this.isLoadingFeedbackData;
  }

  getGoalSettingData() {
    this.isLoadingGoalSettingData = true;
    if (this.getGoalSettingData$) {
      this.getGoalSettingData$.unsubscribe()
    }

    this.getGoalSettingData$ = this.managerService.getDashboardGoalSetting(this.selectedYear.id, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalSettingData = false;
        } else {
          this.individualObjectives = {};
          this.goalSettingPercentage = Math.round(data.response.progress * 100) || 0;
          if (data.response.individuals) {
            let individuals = data.response.individuals
            this.individualObjectives.completedPercentage = Math.round((individuals.shared / individuals.total) * 100);
            this.individualObjectives.toShare = individuals.total - individuals.shared;
            this.individualObjectives.careData = [{
              attribute: 'Caring',
              number: individuals.careAttributes.CARE_PERFCARE,
              color: '#E4002B'
            }, {
              attribute: 'Available',
              number: individuals.careAttributes.AVAILABLE_PERFCARE,
              color: '#25BB48',
            }, {
              attribute: 'Reliable',
              number: individuals.careAttributes.RELIABLE_PERFCARE,
              color: '#88DBFF',
            }, {
              attribute: 'Easy to work with',
              number: individuals.careAttributes.EASY_TO_WORK_WITH_PERFCARE,
              color: '#F7B435',
            }]
          }
          if (data.response.objectives) {
            let objectives = data.response.objectives
            this.challengeObjectives = {};
            this.challengeObjectives.total = objectives.notShared + objectives.needsApproval + objectives.approved
            this.challengeObjectives.sharedData = [
              {
                status: this.translations['dashboard.NOT_SHARED'],
                total: Math.round((objectives.notShared / this.challengeObjectives.total) * 100),
                tooltip: objectives.notShared
              },
              {
                status: this.translations['dashboard.TO_APPROVE'],
                total: Math.round((objectives.needsApproval / this.challengeObjectives.total) * 100),
                tooltip: objectives.needsApproval
              },
              {
                status: this.translations['dashboard.APPROVED'],
                total: Math.round((objectives.approved / this.challengeObjectives.total) * 100),
                tooltip: objectives.approved
              },
            ]
            this.challengeObjectives.careData = [{
              attribute: 'Caring',
              number: objectives.careAttributes.CARE_PERFCARE,
              color: '#E4002B',
            }, {
              attribute: 'Available',
              number: objectives.careAttributes.AVAILABLE_PERFCARE,
              color: '#25BB48',
            }, {
              attribute: 'Reliable',
              number: objectives.careAttributes.RELIABLE_PERFCARE,
              color: '#88DBFF',
            }, {
              attribute: 'Easy to work with',
              number: objectives.careAttributes.EASY_TO_WORK_WITH_PERFCARE,
              color: '#F7B435',
            }]
          }
          this.isLoadingGoalSettingData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalSettingData = false;
        });
  }


  getMidTermData() {
    this.isLoadingMidTermData = true;
    if (this.getMidTermData$) {
      this.getMidTermData$.unsubscribe()
    }

    this.getMidTermData$ = this.managerService.getDashboardMidTerm(this.selectedYear.id, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingMidTermData = false;
        } else {
          this.midTermData = {};
          this.midTermPercentage = Math.round(data.response.progress * 100) || 0;
          this.isMidTermActive = data.response.isPhaseActive;
          this.midTermData.total = data.response.notRevisionable + data.response.revisionable + data.response.revisioned;
          this.midTermData.completeData = [
            {
              status: this.translations['dashboard.REVISIONABLE'],
              total: Math.round((data.response.revisionable / this.midTermData.total) * 100) || 0,
              tooltip: data.response.revisionable
            },
            {
              status: this.translations['dashboard.REVISIONED'],
              total: Math.round((data.response.revisioned / this.midTermData.total) * 100) || 0,
              tooltip: data.response.revisioned
            },
            {
              status: this.translations['dashboard.NOT_REVISIONED'],
              total: Math.round((data.response.notRevisionable / this.midTermData.total) * 100) || 0,
              tooltip: data.response.notRevisionable
            },
          ]

          this.midTermData.lateCount = data.response.late;
          this.midTermData.onLineCount = data.response.onLine;
          this.midTermData.advancedCount = data.response.wellAdvanced;
          this.midTermData.skipped = data.response.skipped;
          this.isLoadingMidTermData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da004",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingMidTermData = false;
        });
  }

  getDashboardFinalEvaluation() {
    this.isLoadingFinalEvaluation = true;
    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe()
    }

    this.getFinalEvaluationData$ = this.managerService.getDashboardFinalEvaluation(this.selectedYear.id, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalEvaluation = false;
        } else {
          this.finalEvaluationPercentage = Math.round(data.response.progress * 100) || 0
          this.isFinalEvaluationActive = data.response.isPhaseActive;
          if (data.response.goalEvaluation) {
            let goalEvaluation = data.response.goalEvaluation;
            let total = goalEvaluation.totalGoals;
            this.objectiveFEData = [
              {
                label: 'Not achieved',
                selfObservations: Math.round((goalEvaluation.NOT_ACHIEVED.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((goalEvaluation.NOT_ACHIEVED.evaluation / total) * 100) || 0,
                tooltipSelf: goalEvaluation.NOT_ACHIEVED.selfEvaluation,
                tooltipEva: goalEvaluation.NOT_ACHIEVED.evaluation
              },
              {
                label: 'Achieved',
                selfObservations: Math.round((goalEvaluation.ACHIEVED.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((goalEvaluation.ACHIEVED.evaluation / total) * 100) || 0,
                tooltipSelf: goalEvaluation.ACHIEVED.selfEvaluation,
                tooltipEva: goalEvaluation.ACHIEVED.evaluation
              },
              {
                label: 'Exceeded',
                selfObservations: Math.round((goalEvaluation.BEYOND_EXPECTATIONS.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((goalEvaluation.BEYOND_EXPECTATIONS.evaluation / total) * 100) || 0,
                tooltipSelf: goalEvaluation.BEYOND_EXPECTATIONS.selfEvaluation,
                tooltipEva: goalEvaluation.BEYOND_EXPECTATIONS.evaluation
              }];
          }
          if (data.response.goalEvaluation) {
            let performanceLevel = data.response.performanceLevel;
            let total = performanceLevel.totalGoals;
            /** Valutazione finale performance level */
            this.performanceLevelData = [
              {
                label: 'Unsatisfactory',
                selfObservations: Math.round((performanceLevel.UNSATISFACTORY.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((performanceLevel.UNSATISFACTORY.evaluation / total) * 100) || 0,
                tooltipSelf: performanceLevel.UNSATISFACTORY.selfEvaluation,
                tooltipEva: performanceLevel.UNSATISFACTORY.evaluation
              },
              {
                label: 'Improvable',
                selfObservations: Math.round((performanceLevel.IMPROVABLE.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((performanceLevel.IMPROVABLE.evaluation / total) * 100) || 0,
                tooltipSelf: performanceLevel.IMPROVABLE.selfEvaluation,
                tooltipEva: performanceLevel.IMPROVABLE.evaluation
              },
              {
                label: 'Solid',
                selfObservations: Math.round((performanceLevel.MEET.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((performanceLevel.MEET.evaluation / total) * 100) || 0,
                tooltipSelf: performanceLevel.MEET.selfEvaluation,
                tooltipEva: performanceLevel.MEET.evaluation
              },
              {
                label: 'Exceeded',
                selfObservations: Math.round((performanceLevel.EXCEEDED.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((performanceLevel.EXCEEDED.evaluation / total) * 100) || 0,
                tooltipSelf: performanceLevel.EXCEEDED.selfEvaluation,
                tooltipEva: performanceLevel.EXCEEDED.evaluation
              },
              {
                label: 'Exceptional',
                selfObservations: Math.round((performanceLevel.EXCEPTIONAL.selfEvaluation / total) * 100) || 0,
                evaluations: Math.round((performanceLevel.EXCEPTIONAL.evaluation / total) * 100) || 0,
                tooltipSelf: performanceLevel.EXCEPTIONAL.selfEvaluation,
                tooltipEva: performanceLevel.EXCEPTIONAL.evaluation
              }
            ];
          }
          this.isLoadingFinalEvaluation = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da006",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalEvaluation = false;
        });
  }


  getDevelopmentPlanData() {
    this.isLoadingDevelopmentPlanData = true;
    if (this.getDevelopmentData$) {
      this.getDevelopmentData$.unsubscribe()
    }

    this.getDevelopmentData$ = this.managerService.getDashboardDevelopmentPlan(this.selectedYear.id, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDevelopmentPlanData = false;
        } else {
          let manager = data.response.manager;
          this.managerActionTotal = manager.toStart + manager.started + manager.completed;
          this.managerActions = [
            {
              status: this.translations['dashboard.TO_START'],
              total: Math.round((manager.toStart / this.managerActionTotal) * 100) || 0,
              tooltip: manager.toStart
            },
            {
              status: this.translations['dashboard.STARTED'],
              total: Math.round((manager.started / this.managerActionTotal) * 100) || 0,
              tooltip: manager.started
            },
            {
              status: this.translations['dashboard.COMPLETED'],
              total: Math.round((manager.completed / this.managerActionTotal) * 100) || 0,
              tooltip: manager.completed
            },
          ]
          let contributor = data.response.subordinate;
          this.contributorActionTotal = contributor.toStart + contributor.started + contributor.completed;
          this.contributorAction = [
            {
              status: this.translations['dashboard.TO_START'],
              total: contributor.toStart,
              tooltip: contributor.toStart
            },
            {
              status: this.translations['dashboard.STARTED'],
              total: contributor.started,
              tooltip: contributor.started
            },
            {
              status: this.translations['dashboard.COMPLETED'],
              total: contributor.completed,
              tooltip: contributor.completed
            },
          ]
          this.isLoadingDevelopmentPlanData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDevelopmentPlanData = false;
        });
  }


  getFeedbackData() {
    this.isLoadingFeedbackData = true;
    if (this.getFeedbackData$) {
      this.getFeedbackData$.unsubscribe()
    }

    this.getFeedbackData$ = this.managerService.getDashboardFeedback(this.selectedYear.id, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedbackData = false;
        } else {
          this.feedbackData = [
            {
              name: this.translations['dashboard.feedbackTypes.SENT_SMART_FEEDBACK'],
              value: data.response.smartFeedbackSent
            },
            {
              name: this.translations['dashboard.feedbackTypes.RECEIVED_SMART_FEEDBACK'],
              value: data.response.smartFeedbackReceived
            },
            {
              name: this.translations['dashboard.feedbackTypes.SENT_PEER_FEEDBACK'],
              value: data.response.peerFeedbackSent
            },
            {
              name: this.translations['dashboard.feedbackTypes.RECEIVED_PEER_FEEDBACK'],
              value: data.response.peerFeedbackReceived
            },
            {
              name: this.translations['dashboard.feedbackTypes.EVIDENCES'],
              value: data.response.evidenceFeedbacks
            },
            {
              name: this.translations['dashboard.feedbackTypes.INTERVIEWS'],
              value: data.response.feedbackDialogue
            },
          ]
          this.isLoadingFeedbackData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedbackData = false;
        });
  }


  getOverviewData() {
    this.isLoadingOverviewData = true;
    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }

    this.getOverviewData$ = this.managerService.getDashboardOverview(this.selectedYear.id, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        } else {
          if (data.response.isPhaseActive) {
            this.isProcessClosed = true;
            this.productionData = [];
            // fatto a mano per mantenere l'ordine dei dati nel grafico
            let structures = ['myEvaluations', 'myStructure', 'myDepartment', 'entireCompany']
            for (let i = 0; i < structures.length; i++) {
              let structureLabel = '';
              if (structures[i] == 'myEvaluations') {
                structureLabel = this.translations['dashboard.MY_EVALS'];
              } else if (structures[i] == 'myStructure') {
                structureLabel = this.translations['dashboard.MY_STRUCTURE'];
              } else if (structures[i] == 'myDepartment') {
                structureLabel = this.translations['dashboard.MY_DEPT'];
              } else {
                structureLabel = this.translations['dashboard.ENTIRE_COMPANY'];
              }

              let evaluations = data.response[structures[i]];
              // vedi sopra
              let evaluationKeys = ['UNSATISFACTORY', 'IMPROVABLE', 'MEET', 'EXCEEDED', 'EXCEPTIONAL'];
              for (let j = 0; j < evaluationKeys.length; j++) {
                let raw = evaluationKeys[j] + '_raw';
                this.productionData.push({
                  status: evaluationKeys[j] == 'MEET' ? 'Solid' : evaluationKeys[j].charAt(0).toUpperCase() + evaluationKeys[j].slice(1).toLowerCase(),
                  structure: structureLabel,
                  value: Math.round(evaluations[evaluationKeys[j]] * 100),
                  tooltip: evaluations[raw]
                })
              }
            }
          }
          this.isLoadingOverviewData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingOverviewData = false;
        });
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.managerService.downloadDashboardReport(this.selectedYear.id, this.selectedFilters, this.dashboardTypeStructure)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  customizeTooltip = (info: any) => ({
    html: `<div><div class='tooltip-header'>${info.argumentText}</div>`
      + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
      + `<span class='top-series-name'>${info.points[0].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='top-series-value'>${info.points[0].point.data.tooltip}</span>`
      + '</div><div class=\'series-name\'>'
      + `<span class='bottom-series-name'>${info.points[1].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='bottom-series-value'>${info.points[1].point.data.tooltip}</span>`
      + '</div><div class=\'series-name\'>'
      + `<span class='bottom-series-name'>${info.points[2].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='bottom-series-value'>${info.points[2].point.data.tooltip}</span>`
      + '</div><div class=\'series-name\'>'
      + `<span class='bottom-series-name'>${info.points[3].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='bottom-series-value'>${info.points[3].point.data.tooltip}</span>`
      + '</div></div></div>'
  }
  );

  customizeTooltipChallenge = (info: any) => (
    {
      html: '</div><div class=\'series-name\'>'
        + `<span class='bottom-series-name'>${info.points[0].argumentText}: </span>`
        + `<span class='bottom-series-value'>${info.point.data.tooltip}</span>`
        + '</div></div>',
    }
  );

  customizeTooltipLevel = (info: any) => ({
    html: `<div><div class='tooltip-header'>${info.argumentText}</div>`
      + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
      + `<span class='top-series-name'>${info.points[0].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='top-series-value'>${info.point.data.tooltipSelf}</span>`
      + '</div><div class=\'series-name\'>'
      + `<span class='bottom-series-name'>${info.points[1].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='bottom-series-value'>${info.point.data.tooltipEva}</span>`
      + '</div></div></div>',
  });

  customizeTooltipGeneric = (info: any) => ({
    html: `<div><div class='tooltip-header'>${info.argumentText}</div>`
      + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
      + `<span class='top-series-name'>${info.points[0].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='top-series-value'>${info.points[0].valueText}</span>`
      + '</div><div class=\'series-name\'>'
      + `<span class='bottom-series-name'>${info.points[1].seriesName}</span>`
      + ': </div><div class=\'value-text\'>'
      + `<span class='bottom-series-value'>${info.points[1].valueText}</span>`
      + '% </div></div></div>',
  });

  switchDashboardType() {
    if (this.dashboardType == 'MY_TEAM') {
      this.dashboardType = 'MY_STRUCTURE';
      this.dashboardTypeStructure = true;
    } else {
      this.dashboardType = 'MY_TEAM';
      this.dashboardTypeStructure = false;
    }
    this.getGraphData();
  }

  ngOnDestroy(): void {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    if (this.getGoalSettingData$) {
      this.getGoalSettingData$.unsubscribe()
    }
    if (this.getMidTermData$) {
      this.getMidTermData$.unsubscribe()
    }
    if (this.getDevelopmentData$) {
      this.getDevelopmentData$.unsubscribe()
    }
    if (this.getFeedbackData$) {
      this.getFeedbackData$.unsubscribe()
    }
    if (this.isProcessClosed$) {
      this.isProcessClosed$.unsubscribe();
    }
    if (this.getOverviewData$) {
      this.getOverviewData$.unsubscribe()
    }
  }

}
