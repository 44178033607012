import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { CanCreateDevelopmentActionForManagerResponse, CanShareDevelopmentActionToManagerResponse, DeleteSubordinateGoalForManagerResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, PerfCareGoalRentStatuses, PerfCarePeerFeedback, SenecaResponse } from 'src/commonclasses';
import { ManagerService } from 'src/app/shared/services/manager.service';

import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ManagerActions from "../../manager/ngrx/manager.actions";
import { Subscription } from 'rxjs';
import { GoalUtils, NON_VALUABLE_TYPES } from 'src/app/utils/goal.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { Subordinateservice } from 'src/app/manager/services/subordinates.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActionUtils } from 'src/app/utils/action.utils';

@Component({
  selector: 'app-manager-operations-goalDetails',
  templateUrl: './goalDetails.component.html',
  styleUrls: ['./goalDetails.component.scss']
})
export class ManagerGoalDetailsComponent implements OnInit, OnDestroy {
  getPersonDetails$: any;
  personDetails$: Subscription = new Subscription;
  personDetails: any;
  isLoadingPersonDetails = true;

  userId: string = '';
  runningYear$: Subscription;
  runningYear: number = 0;
  noDataPlaceholder: string = '';

  canShareGoals$: Subscription = new Subscription;
  goalId: any;
  isLoadingGoalData: boolean = true;
  getGoalData$: Subscription = new Subscription;
  goalData: any;
  deleteModalContent: ApplicationModalMessage = {
    modalId: 'deleteGoal',
    title: '',
    text: ''
  };

  feedbackTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  evidencesTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  milestonesTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  evaluationTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  idpTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  linkOrFileModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  fileTitleModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  uploadAreaModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  linkTitleModalTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  objectiveCompleteTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  careAttributesTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  commentData?: { text: any; date: any; };
  loggedUser$?: Subscription;
  managerData: any;
  isLinkUpload: boolean = false;
  fileObject: any;
  linkTitle: string = '';
  linkToUpload: string = '';
  linkToDelete: string = '';
  isUpdate: boolean = false;
  editLinkId: string = '';
  fileTitle: string = '';
  editUploadId: string = '';
  uploadIdToDelete: string = '';
  isLoadingGetDownloadUrl: boolean = false;
  isToResetUploadComponent: boolean = false;
  // Piano di sviluppo
  isLoadingUserDevelopmentActions: boolean = false;
  getDevelopmentActions$: Subscription = new Subscription;
  developmentActionsList: any;
  developActionModalText: string = '';
  developActionForModal: any = {
    comment: ''
  };
  addCommentToDevelopPlan$: Subscription = new Subscription;
  isFetchingAddCommentToDevelopPlan: boolean = false;
  actionForCommentDelete: any;
  commentIdToDelete: any;
  addCommentToDevelopAction$: Subscription = new Subscription;
  commentToEdit: any;
  // oggetti azioni per modali
  developAction: any;
  developActionEdit: any;
  newActionComment: string = '';
  isLoadingDeleteDevelopAction: boolean = false;
  deleteDevelopAction$: Subscription = new Subscription;
  developActionIdToDelete: string = '';
  isFetchingCanAddActions: boolean = false;
  canCreateDevActions: boolean = false;
  isFetchingCanShareActions: boolean = false;
  canShareDevelopActions$: Subscription = new Subscription;
  canShareDevActions: boolean = false;
  shareDevelopActions$: Subscription = new Subscription;
  evidencesList: any[] = [];
  isLoadingGetDownloadUrlOnEvidence: boolean = false;
  isAfterSharing: boolean = false;
  getObjectiveOnHold$: Subscription = new Subscription;
  hasDraft: boolean = false;
  // Peer feedback
  peerFeedbackPlaceholder: string = '';
  peerFeedbackList: PerfCarePeerFeedback[] = [];
  peerFeedbackToDelete: string = '';
  deletePeerFeedback$: Subscription = new Subscription;
  feedbackForRequestModal: any;
  today = moment().format();
  finalEvaluationStatusData: any;
  finalEvaluationForModal: any;
  getRunningPhase$: Subscription;
  runningPhase: any;
  evalPhaseEnd: any;
  evaluationStatus = [PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_EVALUATE, PerfCareGoalRentStatuses.PERFCARE_GOAL_COMPLETED];
  isLoadingEvaluationDates: boolean = false;
  getFinalEvaluationDates$: Subscription = new Subscription;
  selectedYear: { id: any; name: string; } = {
    id: '',
    name: ''
  };

  excludeStatus = [
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_MANAGER,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_SHARED_TO_MANAGER,
    PerfCareGoalRentStatuses.PERFCARE_GOAL_TO_SHARE_TO_SUBORDINATE,
  ]

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private managerService: ManagerService,
    private router: Router,
    public redirectService: RedirectService,
    public modalService: ModalService,
    private authService: AuthService) {

    this.getRunningPhase$ = this.store.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
      this.runningPhase = runningPhase;
    })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;

              if (params.perfCareYear) {
                this.selectedYear = {
                  id: params.perfCareYear,
                  name: 'Anno ' + params.perfCareYear
                }
              } else {
                this.selectedYear = {
                  id: this.runningYear,
                  name: 'Anno ' + this.runningYear
                }
              }

              // Redirect per salvare vecchie notifiche
              this.router.navigate(['/manager/personDetails/', this.userId, this.selectedYear.id]);
              /*
                            this.store.dispatch(ManagerActions.SetActiveUserId({ payload: this.userId }));
                            if (window.location.href.indexOf('afterSharing') > -1) {
                              this.isAfterSharing = true;
                            }
                            this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                              this.managerData = loggedUser.user;
                            })
                            // Recupero la persona selezionata, se è presente nello store
                            this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                              this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                                return person.userId === this.userId;
                              })[0];
              
                              // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                              if (!this.personDetails) {
                                this.getPersonDetails();
                              }
                              // Se ho l'utente recupero i dati dei pesi e numero di obiettivi
                              if (this.personDetails && this.personDetails.userId) {
                                this.getObjectiveOnHold();
                                this.isLoadingPersonDetails = false;
                                this.getFinalEvaluationDates()
                                this.getGoalData();
                              }
                            });
              
                            */
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.INFORMATIONS',
        'goalSetting.WHAT_OBSERVE_WHEN_OBJECTIVE_REACHED',
        'goalSetting.DESCRIBE_ELEMENTS_YOU_OBSERVE',
        'goalSetting.FEEDBACK',
        'goalSetting.FEEDBACK_DESCRIPTION',
        'goalSetting.EVIDENCES',
        'goalSetting.EVIDENCES_DESCRIPTION_MANAGER',
        'goalSetting.PRATICABLE_STEPS',
        'goalSetting.PRATICABLE_STEPS_DESCRIPTION',
        'goalSetting.VALUTATION',
        'goalSetting.VALUTATION_DESCRIPTION',
        'goalSetting.DEVELOPMENT_PLAN',
        'goalSetting.DEVELOPMENT_PLAN_DESCRIPTION'
      ])
      .subscribe(translations => {
        this.objectiveCompleteTooltipModal = {
          modalId: "003",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.WHAT_OBSERVE_WHEN_OBJECTIVE_REACHED'],
          text: translations['goalSetting.DESCRIBE_ELEMENTS_YOU_OBSERVE']
        };
        this.feedbackTooltipModal = {
          modalId: "004",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.FEEDBACK'],
          text: translations['goalSetting.FEEDBACK_DESCRIPTION']
        };
        this.evidencesTooltipModal = {
          modalId: "005",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.EVIDENCES'],
          text: translations['goalSetting.EVIDENCES_DESCRIPTION_MANAGER']
        };
        this.milestonesTooltipModal = {
          modalId: "006",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.PRATICABLE_STEPS'],
          text: translations['goalSetting.PRATICABLE_STEPS_DESCRIPTION']
        };
        this.evaluationTooltipModal = {
          modalId: "007",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.VALUTATION'],
          text: translations['goalSetting.VALUTATION_DESCRIPTION']
        };
        this.idpTooltipModal = {
          modalId: "008",
          title: translations['generic.INFORMATIONS'],
          subtitle: translations['goalSetting.DEVELOPMENT_PLAN'],
          text: translations['goalSetting.DEVELOPMENT_PLAN_DESCRIPTION']
        };
      })

    this.linkOrFileModalTooltip = {
      modalId: 'opm004',
      title: "Cosa vuoi collegare?",
      subtitle: 'Lorem ipsum dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien.'
    }
    this.fileTitleModalTooltip = {
      modalId: 'opm005',
      title: "Titolo",
      subtitle: 'Lorem ipsum dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien.'
    }
    this.uploadAreaModalTooltip = {
      modalId: 'opm006',
      title: "Carica file",
      subtitle: 'Lorem ipsum dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien.'
    }
    this.linkTitleModalTooltip = {
      modalId: 'opm009',
      title: "Inserisci link",
      subtitle: 'Lorem ipsum dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien.'
    }
    this.careAttributesTooltipModal = {
      modalId: 'op033',
      title: "Attributi C.A.R.E",
      subtitle: 'Lorem ipsum dolor sit amet',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean porta luctus augue a varius. Nunc gravida non nunc sit amet congue. Curabitur hendrerit metus ac tellus lobortis consequat et ac sapien.'
    }
  };

  // Chiudi modale per la cancellazione dell'azione di sviluppo
  closeDeleteModalDevelopAction() {
    this.modalService.close("deleteDevelopmentAction");
    this.developActionIdToDelete = '';
  }

  // Apri modale per la cancellazione dell'azione di sviluppo
  openDeleteModalDevelopAction(developActionIdToDelete: string) {
    this.developActionIdToDelete = developActionIdToDelete;
    this.modalService.open("deleteDevelopmentAction");
  }

  // Vai a creare l'evidenza associata all'obiettivo
  goToCreateEvidenceToGoal() {
    this.router.navigate(['./manager/createUpdateEvidence/' + this.userId + '/' + this.goalId]);
  }

  // Vai a gestire le evidenze
  goToManageEvidences() {
    this.router.navigate(['./manager/personDetails/feedback/evidences/' + this.userId]);
  }

  // Download file nell'evidenza
  downloadFileOnEvidences(file: any, feedbackId: string) {
    this.isLoadingGetDownloadUrlOnEvidence = true;

    this.managerService.retrieveNewMaskedUrlForUploadForEvidence(this.userId, file.uploadId, feedbackId, this.selectedYear.id.toString())
      .subscribe((data: any) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          let downloadUrl = this.authService.getMaskedUrlDownload(data.response, file.fileName);
          setTimeout(() => {
            document.location.assign(downloadUrl);
          }, 500)
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGetDownloadUrlOnEvidence = false;
        })
  }

  // Cancella azione di sviluppo
  deleteDevelopAction() {

    let idToDelete = this.developActionIdToDelete;
    this.closeDeleteModalDevelopAction();
    this.isLoadingDeleteDevelopAction = true;
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }

    this.deleteDevelopAction$ = this.managerService.deleteDevelopmentActionForManager(this.selectedYear.id.toString(), idToDelete, this.userId)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.getGoalData();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.DELETED_DEVELOPMENT_ACTION_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingDeleteDevelopAction = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDeleteDevelopAction = false;
      }
      );
  }

  goToEditDevelopmentAction(developmentAction: any) {
    this.router.navigate(['manager/createUpdateDevelopmentActionEdit/' + this.personDetails.userId + '/' + developmentAction.id]);
  }

  getPersonDetails() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.subordinatesService.getSubordinateByUserId(this.userId, this.runningYear)
      .subscribe((personDetailsData: SenecaResponse<any>) => {
        if (personDetailsData.error || !personDetailsData.response) {
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.isLoadingPersonDetails = false;
          this.getObjectiveOnHold();
          this.getGoalData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "091",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  // Recupera la stringa per l'estensione del file
  getExtensionString(fileName: string) {
    let extension = fileName.slice(fileName.lastIndexOf('.'), fileName.length);
    extension = extension.replace('.', '');
    if (extension == 'pptx' || extension == 'PPTX') {
      extension = 'ppt';
    } else if (extension == 'jpg' || extension == 'JPG') {
      extension = 'jpeg';
    } else if (extension == 'xlsx' || extension == 'XLSX') {
      extension = 'xls';
    } else if (extension == 'docx' || extension == 'DOCX') {
      extension = 'doc';
    } else if (extension == 'eml' || extension == 'EML') {
      extension = 'eml';
    } else if (extension == 'msg' || extension == 'MSG') {
      extension = 'msg';
    } else if (extension == 'txt' || extension == 'TXT') {
      extension = 'txt';
    } else if (extension == 'rtf' || extension == 'RTF') {
      extension = 'rtf';
    } else if (extension == 'png' || extension == 'PNG') {
      extension = 'png';
    }
    return extension;
  }

  // Se il manager può creare azioni di sviluppo
  canAddActions() {
    this.isFetchingCanAddActions = true;

    this.addCommentToDevelopPlan$ = this.managerService.canCreateDevelopmentActions(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<CanCreateDevelopmentActionForManagerResponse>) => {
        if (data && data.error) {
          this.isFetchingCanAddActions = false;
        } else {
          this.isFetchingCanAddActions = false;
          this.canCreateDevActions = true;
        }
      }, (err?: any) => {
        this.isFetchingCanAddActions = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "000",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  // Apre la modale per condividere le azioni di sviluppo
  openDevelopActionsShareModal() {
    this.modalService.open('developActionsShareModal');
  }

  // Chiude la modale per condividere le azioni di sviluppo
  closeDevelopActionsShareModal() {
    this.modalService.close('developActionsShareModal');
  }

  // Condividi le azioni di sviluppo
  shareDevelopActions() {
    this.closeDevelopActionsShareModal();
    this.isLoadingGoalData = true;
    if (this.shareDevelopActions$) {
      this.shareDevelopActions$.unsubscribe();
    }

    this.shareDevelopActions$ = this.managerService.shareDevelopActions(this.selectedYear.id.toString(), this.userId)
      .subscribe((data: any) => {
        if (data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d027",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d028",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d029",
            text: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_TEXT_ERROR"),
            title: this.translate.instant("developmentPlan.SHARED_DEVELOP_ACTIONS_ERROR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.canShareActions();
        this.getGoalData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d030",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalData = false;
      }
      );
  }

  // Se il collaboratore può condividere le azioni di sviluppo
  canShareActions() {
    this.isFetchingCanShareActions = true;

    this.canShareDevelopActions$ = this.managerService.canShareDevelopmentActions(this.selectedYear.id, this.userId)
      .subscribe((data: SenecaResponse<CanShareDevelopmentActionToManagerResponse>) => {
        if (data && data.error) {
          this.canShareDevActions = false;
          this.isFetchingCanShareActions = false;
        } else if (data && data.response) {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = true;
        } else {
          this.isFetchingCanShareActions = false;
          this.canShareDevActions = false;
        }
      }, (err?: any) => {
        this.isFetchingCanShareActions = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "000",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      }
      );
  }

  getObjectiveOnHold() {
    if (this.getObjectiveOnHold$) {
      this.getObjectiveOnHold$.unsubscribe();
    }
    this.getObjectiveOnHold$ = this.managerService.listUserGoalForManager(this.selectedYear.id, this.personDetails.userId, "DRAFT_INDIVIDUAL_TAB")
      .subscribe((goalDrafts: SenecaResponse<any>) => {
        this.hasDraft = false;
        if (goalDrafts && goalDrafts.response && goalDrafts.response.length) {
          this.hasDraft = true;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "af001",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        window.history.back();
        // this.isLoadingUserGoals = false;
      });
  }

  // Recupera l'obiettivo dal goalId
  getGoalData() {
    this.isLoadingGoalData = true;
    if (this.getGoalData$) {
      this.getGoalData$.unsubscribe();
    }

    this.getGoalData$ = this.managerService.getGoalByIdForManager(this.selectedYear.id.toString(), this.goalId, this.personDetails.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          this.goToPersonDetails();
          const messageObj: ApplicationModalMessage = {
            modalId: "092",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.goalData = GoalUtils.formatGoalCardData(this.translate, data.response, true, this.userId);
          this.developmentActionsList = ActionUtils.formatActionList(data.response.developmentAction, this.managerData, true, this.translate);
          this.evidencesList = data.response.evidenceFeedbacks || [];
          if (this.evidencesList && this.evidencesList.length) {
            this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
              moment.locale(applicationLang);
              for (let i = 0; i < this.evidencesList.length; i++) {
                this.evidencesList[i].formattedCreationDate = moment(this.evidencesList[i].creationDate).fromNow();
              }
            })
          }
          if (this.goalData.managerComment) {
            this.commentData = {
              text: this.goalData.managerComment,
              date: moment(this.goalData.managerCommentDate).format('DD.MM.YYYY')
            }
          }
          if (this.goalData && this.goalData.isNew) {
            this.store.dispatch(ManagerActions.UpdateCollaboratorData());
          }
          if (this.goalData.isChallenge) {
            this.canAddActions();
            this.canShareActions();
          }
          if (this.evaluationStatus.includes(this.goalData.goalStatus)) {
            this.finalEvaluationStatusData = {
              status: this.goalData.status,
              statusMessage: this.goalData.statusMessage

            }
          }
          this.isLoadingGoalData = false;
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "093",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalData = false;
        })
  }

  // funzioni modale upload
  onFileLinkUpload() {
    this.isLinkUpload = false;
    this.linkTitle = '';
    this.linkToUpload = '';
    this.isUpdate = false;
    this.editLinkId = '';
    this.fileTitle = '';
    this.fileObject = null;
    this.isToResetUploadComponent = true;
    this.modalService.open('uploadFileOrLink');
  }

  closeUploadFileOrLinkModal() {
    this.modalService.close('uploadFileOrLink');
  }

  onLinkTitleChanged(newTitle: string) {
    this.linkTitle = newTitle;
  }

  onLinkChanged(newText: string) {
    this.linkToUpload = newText;
  }

  assignFile(file: any) {
    this.fileObject = file;
  }

  confirmUploadLinkOrFile() {
    if (this.isLinkUpload) {
      if (this.isUpdate) {
        this.updateLink();
      } else {
        this.addLink();
      }
    } else {
      if (this.isUpdate) {
        this.updateFile();
      } else {
        this.uploadFile();
      }
    }
  }


  updateLink() {
    this.isLoadingGoalData = true;
    this.closeUploadFileOrLinkModal();

    this.managerService.updateLinkForGoal(this.selectedYear.id.toString(), this.goalId, this.userId, this.editLinkId, this.linkTitle, this.linkToUpload)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.isLinkUpload = false;
          this.linkTitle = '';
          this.linkToUpload = '';
          this.isUpdate = false;
          this.editLinkId = '';
          this.getGoalData();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm011",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          // this.goToPersonDetails();
          this.isLoadingGoalData = false;
        })
  }

  addLink() {
    this.isLoadingGoalData = true;
    this.closeUploadFileOrLinkModal();

    this.managerService.createLinkForGoal(this.selectedYear.id.toString(), this.goalId, this.userId, this.linkTitle, this.linkToUpload)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.linkTitle = '';
          this.linkToUpload = '';
          this.getGoalData();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm013",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          // this.goToPersonDetails();
          this.isLoadingGoalData = false;
        })
  }

  uploadFile() {
    this.isLoadingGoalData = true;
    this.isToResetUploadComponent = false;
    this.closeUploadFileOrLinkModal();

    this.managerService.uploadFileForGoal(this.selectedYear.id.toString(), this.goalId, this.userId, this.fileTitle, this.fileObject)
      .subscribe((data: any) => {
        // this.goToPersonDetails();
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goToPersonDetails();
        } else {
          this.fileObject = null;
          this.getGoalData();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          // this.goToPersonDetails();
          this.isLoadingGoalData = false;
        })
  }

  // Se l'utente può fare azioni nei link e files
  canDoActionOnLinkFiles(object: any) {
    if ((this.managerData && this.managerData.userId) == object.creationUserId) {
      return true;
    } else {
      return false;
    }
  }

  changeTypeOfUpload() {
    this.isLinkUpload = !this.isLinkUpload;
  }

  onFileTitleChanged(newTitle: string) {
    this.fileTitle = newTitle;
  }

  deleteLink(linkId: string) {
    this.linkToDelete = linkId;
    this.modalService.open('deleteLinkModal');
  }

  closeDeleteLinkModal(confirmDelete: boolean) {
    if (confirmDelete) {
      this.isLoadingGoalData = true;
      this.managerService.deleteLinkForGoal(this.selectedYear.id.toString(), this.goalId, this.userId, this.linkToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm014",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalData = false;
          } else {
            this.getGoalData();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalData = false;
          })
    }
    this.linkToDelete = '';
    this.modalService.close('deleteLinkModal');
  }

  editLink(linkId: string) {
    let link = this.goalData.links.filter((x: any) => x.linkId == linkId);
    if (link && link.length) {
      link = link[0];
    } else {
      link = null;
    }
    if (link) {
      this.isLinkUpload = true;
      this.linkTitle = link.linkName;
      this.linkToUpload = link.url;
      this.isUpdate = true;
      this.editLinkId = linkId;
      this.modalService.open('uploadFileOrLink');
    }
  }

  updateFile() {
    this.isLoadingGoalData = true;
    this.closeUploadFileOrLinkModal();

    this.managerService.updateFileForGoal(this.selectedYear.id.toString(), this.goalId, this.userId, this.fileTitle, this.fileObject, this.editUploadId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm010",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGoalData = false;
        } else {
          this.isLinkUpload = false;
          this.linkTitle = '';
          this.linkToUpload = '';
          this.isUpdate = false;
          this.editLinkId = '';
          this.getGoalData();
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm011",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          // this.goToPersonDetails();
          this.isLoadingGoalData = false;
        })
  }

  editFile(uploadId: string) {
    let file = this.goalData.files.filter((x: any) => x.uploadId == uploadId);
    if (file && file.length) {
      file = file[0];
    } else {
      file = null;
    }
    if (file) {
      this.fileObject = file;
      this.isLinkUpload = false;
      this.fileTitle = file.fileName;
      this.isUpdate = true;
      this.editUploadId = uploadId;
      this.modalService.open('uploadFileOrLink');
    }
  }

  deleteFile(uploadId: string) {
    this.uploadIdToDelete = uploadId;
    this.modalService.open('deleteFileModal');
  }

  closeDeleteFileModal(confirmDelete: boolean) {
    if (confirmDelete) {
      this.isLoadingGoalData = true;
      this.managerService.deleteFileForGoal(this.selectedYear.id.toString(), this.userId, this.uploadIdToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm014",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalData = false;
          } else {
            this.getGoalData();
          }
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "opm015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalData = false;
          })
    }
    this.uploadIdToDelete = '';
    this.modalService.close('deleteFileModal');
  }

  openLink(url: string) {
    if (url.includes('http')) {
      window.open(url, '_blank');
    } else {
      window.open('https://' + url, '_blank');
    }
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp() {
    return new Promise((resolve, reject) => {
      this.authService.crateRetrieveTokenAfterLogin().subscribe((senecaResponse: any) => {
        if (senecaResponse.error) {
          reject();
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      }, (err: any) => {
        reject();
      })
    }
    )
  }

  downloadFile(file: any) {
    this.isLoadingGetDownloadUrl = true;

    this.managerService.retrieveNewMaskedUrlForUploadForManager(file.uploadId, this.goalData.id, this.selectedYear.id.toString(), this.userId)
      .subscribe((data: any) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goToPersonDetails();
        } else if (data.response) {
          let downloadUrl = this.authService.getMaskedUrlDownload(data.response, file.originalFileName);
          setTimeout(() => {
            document.location.assign(downloadUrl);
          }, 500)
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "opm008",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingGetDownloadUrl = false;
        })
  }

  // Vai alla lista obiettivi dell'utente in caso di errore o back
  goToPersonDetails() {
    this.redirectService.goBackBrowser();
  }

  // Vai alla modifica dell'obiettivo
  goToEditGoal() {
    if (this.personDetails.isOperation) {
      if (this.isAfterSharing) {
        this.router.navigate(["/manager/createUpdateGoalManager/afterSharing/" + this.userId + '/' + this.goalData.id]);
      } else {
        this.router.navigate(["/manager/createUpdateGoalManager/" + this.userId + '/' + this.goalData.id]);
      }
    } else {
      if (this.isAfterSharing) {
        this.router.navigate(["/manager/createUpdateGoalManager/afterSharing/individual/" + this.userId + '/' + this.goalData.id]);
      } else {
        this.router.navigate(["/manager/createUpdateGoalManager/individual/" + this.userId + '/' + this.goalData.id]);
      }
    }
  }

  deleteGoal() {
    this.deleteModalContent = {
      modalId: "137",
      title: this.translate.instant('goal.DELETE_MODAL_TITLE'),
      text: this.translate.instant('goal.DELETE_MODAL_CONTENT')
    }
    this.modalService.open('confirmDeleteForManager')
  }

  confirmDeleteGoal() {
    this.isLoadingGoalData = true;
    let functionToCall;
    if (this.isAfterSharing && !this.goalData.originalGoalId) {
      functionToCall = this.managerService.cancelGoalAfterSharing(this.selectedYear.id, this.userId, this.goalId)
    } else {
      functionToCall = this.managerService.deleteSubordinateGoal(this.selectedYear.id, this.userId, [this.goalId])
    }
    functionToCall.subscribe((result: SenecaResponse<any>) => {
      if (result && result.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "094",
          text: this.translate.instant("errors." + result.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else if (result && result.response) {
        this.goToPersonDetails();
      }
      this.isLoadingGoalData = false;
    },
      (err: string) => {
        this.goToPersonDetails();
        const messageObj: ApplicationModalMessage = {
          modalId: "095",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalData = false;
      })
  }

  closeDeleteModal() {
    this.modalService.close('confirmDeleteForManager')
  }

  goToInterview() {
    this.router.navigate(['./manager/organizeInterview/' + this.userId]);
  }

  showComment() {
    if (this.commentData) {
      let comment: ApplicationModalMessage = {
        modalId: "157",
        title: this.translate.instant("modals.COMMENT_APPROVED.TITLE"),
        subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + this.commentData.date,
        text: this.commentData.text
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: comment }));
    }
  }


  // funzioni commento azioni

  // Apre la modale per cancellare il commento dell'azione di sviluppo
  openDeleteCommentModalDevelopAction(actionObject: any) {
    this.actionForCommentDelete = actionObject.action;
    this.commentIdToDelete = actionObject.commentId;
    this.modalService.open('deleteDevelopActionCommentModal');
  }

  // Chiude la modale per cancellare il commento dell'azione di sviluppo
  closeDeleteCommentModalDevelopAction() {
    this.modalService.close('deleteDevelopActionCommentModal');
  }

  // Cancella commento dell'azione di sviluppo
  deleteCommentDevelopAction() {
    this.isLoadingGoalData = true;
    this.isLoadingUserDevelopmentActions = true;
    this.closeDeleteCommentModalDevelopAction();
    this.addCommentToDevelopAction$ = this.managerService.deleteCommentToDevelopPlan(this.selectedYear.id.toString(), this.actionForCommentDelete.id, this.commentIdToDelete, this.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d033",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d034",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_DELETED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getGoalData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "d035",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.actionForCommentDelete = null;
        this.commentIdToDelete = '';
        this.getGoalData();
      });
  }
  // Apre modale per aggiungere il commento all'azione di sviluppo
  openInsertCommentToDevelopAction(developAction: any) {
    this.developAction = developAction;
    this.newActionComment = '';
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + developAction.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      developAction.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + developAction.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + developAction.toStartDate + '</p>';
    this.modalService.open('insertCommentDevelopmentPlanModal');
  }

  // Aggiungi il commento all'azione di sviluppo
  addCommentToDevelopAction() {
    this.isLoadingGoalData = true;
    this.closeInserCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.managerService.addCommentToDevelopPlan(this.selectedYear.id.toString(), this.userId, this.developAction.id, this.newActionComment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d024",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d025",
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT_CORRECTLY"),
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.ADDED_COMMENT")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.newActionComment = '';
        this.developAction = null;
        this.getGoalData();
      }, (err?: any) => {
        this.newActionComment = '';
        const messageObj: ApplicationModalMessage = {
          modalId: "d026",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.newActionComment = '';
        this.developAction = null;
        this.getGoalData();
      });
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeInserCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('insertCommentDevelopmentPlanModal');
    if (cancel) {
      this.newActionComment = '';
      this.developAction = null;
    }
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionCommentChanged(text: string) {
    this.newActionComment = text;
  }

  // Apre modale per modificare il commento all'azione di sviluppo
  openEditCommentToDevelopAction(actionObject: any) {
    this.developActionEdit = actionObject.action;
    this.commentToEdit = actionObject.comment;
    this.developActionModalText = '<p><b><i>' + this.translate.instant('developmentPlan.TITLE_CARD') + '</i></b>' + this.developActionEdit.title + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.TYPE_CARD') + '</i></b>' +
      this.developActionEdit.typeText + '</p>' + '<p><b><i>' + this.translate.instant('developmentPlan.DESCRIPTION_CARD') + '</i></b>' + this.developActionEdit.description + '</p>' +
      '<p><b><i>' + this.translate.instant('developmentPlan.TO_START_CARD') + '</i></b>' + this.developActionEdit.toStartDate + '</p>';
    this.modalService.open('editCommentDevelopmentPlanModal');
  }

  // Chiude modale per aggiungere il commento all'azione di sviluppo
  closeEditCommentModalDevelopAction(cancel?: boolean) {
    this.modalService.close('editCommentDevelopmentPlanModal');
    if (cancel) {
      this.developActionEdit = null;
      this.commentToEdit = null;
    }
  }

  // Aggiungi il commento all'azione di sviluppo
  updateCommentForDevelopAction() {
    this.isLoadingGoalData = true;
    this.closeEditCommentModalDevelopAction();

    if (this.addCommentToDevelopAction$) {
      this.addCommentToDevelopAction$.unsubscribe();
    }
    this.addCommentToDevelopAction$ = this.managerService.updateCommentForDevelopPlan(this.selectedYear.id.toString(), this.userId, this.developActionEdit.id, this.commentToEdit.commentId, this.commentToEdit.comment)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d036",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "d037",
            title: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED"),
            text: this.translate.instant("developmentPlan.createUpdateDevelopAction.COMMENT_EDITED_DESCR"),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getGoalData();
      }, (err?: any) => {
        this.newActionComment = '';
        const messageObj: ApplicationModalMessage = {
          modalId: "d038",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.commentToEdit = null;
        this.developActionEdit = null;
        this.getGoalData();
      });
  }

  // Il commento dell'azione di sviluppo sta cambiando in textarea
  developActionEditCommentChanged(text: string) {
    this.commentToEdit.comment = text;
  }


  // Mostra commento azione di sviluppo
  showActionComment(object: any) {
    let objectModal: ApplicationModalMessage = {
      modalId: "d031",
      title: this.translate.instant("developmentPlan.DEVELOP_ACTION_COMMENT"),
      subtitle: this.translate.instant("modals.COMMENT_APPROVED.SUBTITLE") + ' ' + moment(object.creationDate).format('DD/MM/YYYY'),
      text: object.comment
    }
    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: objectModal }));
  }

  // Vai a creare l'azione di sviluppo associata all'obiettivo
  goToCreateActionToGoal() {
    if (this.canCreateDevActions) {
      this.router.navigate(['./manager/createUpdateDevelopmentAction/' + this.userId + '/' + this.goalId]);
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "603",
        title: this.translate.instant("developmentPlan.ADD_DEVELOPMENT_PLAN"),
        subtitle: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION"),
        text: this.translate.instant("developmentPlan.YOU_CANT_ADD_DEVELOPMENT_ACTION_TEXT"),
        confirmLabel: this.translate.instant("generic.CLOSE")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
  }


  // Funzioni accordion peer feedback
  goToPeerFeedbackAnswer(feedbackId: string) {
    this.redirectService.goToCreatePeerFeedbackManager(this.userId, feedbackId, true);
  }

  openDeletePeerFeedbackModal(id: string) {
    this.peerFeedbackToDelete = id;
    this.modalService.open('deletePeerFeedback');
  }

  closeDeletePeerFeedbackModal(confirm?: boolean) {
    this.modalService.close('deletePeerFeedback');
    if (confirm) {
      this.isLoadingGoalData = true;
      if (this.deletePeerFeedback$) {
        this.deletePeerFeedback$.unsubscribe();
      }
      this.deletePeerFeedback$ = this.managerService.deletePeerFeedback(this.selectedYear.id, this.userId, this.peerFeedbackToDelete)
        .subscribe(
          (data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pf021",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else if (data.response) {
              const messageObj: ApplicationModalMessage = {
                modalId: "d028",
                title: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED"),
                text: this.translate.instant("peerFeedback.modals.FEEDBACK_DELETED_DESCR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "d029",
                title: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_ERROR"),
                text: this.translate.instant("peerFeedback.modals.DELETED_FEEDBACK_TEXT_ERROR"),
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.getGoalData();
          },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf022",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingGoalData = false;
          }
        )
    }
    this.peerFeedbackToDelete = '';
  }

  openShowFeedbackRequest(feedback: any) {
    this.feedbackForRequestModal = feedback;
    this.modalService.open('modalShowRequest');
  }

  closeShowFeedbackRequest() {
    this.modalService.close('modalShowRequest')
    this.feedbackForRequestModal = null;
  }

  goToFinalEvaluation() {
    this.router.navigate(['manager/finalEvaluation/' + this.selectedYear.id + '/' + this.userId + '/' + this.goalId]);
  }

  openFinalEvaluationModal(evaluation: any) {
    this.finalEvaluationForModal = evaluation;
    this.modalService.open('finalEvaluationModal');
  }

  closeFinalEvaluationModal() {
    this.modalService.close('finalEvaluationModal');
    this.finalEvaluationForModal = null;
  }

  finalEvaluationEnabled() {
    if (this.evaluationStatus.includes(this.goalData.goalStatus)) {
      return true;
    }
    return false;
  }

  hasFinalEvaluation() {
    return NON_VALUABLE_TYPES.indexOf(this.goalData.type) < 0;
  }

  checkFinalEvaluationDatesValid() {
    if (this.evaluationStatus.includes(this.goalData.goalStatus)) {
      let phaseKickoff = moment(this.runningPhase.phaseStartDateForManager).format();
      let phaseDeadline = moment(this.runningPhase.phaseEndDateForManager).format();
      return moment(this.today).isSameOrAfter(phaseKickoff) && moment(this.today).isSameOrBefore(phaseDeadline);
    }
    return false;
  }

  getFinalEvaluationDates() {
    this.isLoadingEvaluationDates = true;
    if (this.getFinalEvaluationDates$) {
      this.getFinalEvaluationDates$.unsubscribe();
    }
    this.getFinalEvaluationDates$ = this.managerService.getFinalEvaluationDate(this.selectedYear.id)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.response && data.response.finalEvaluationManagerPhaseDates) {
          this.evalPhaseEnd = data.response.finalEvaluationManagerPhaseDates.endDate;
        }
        this.isLoadingEvaluationDates = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pf022",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingEvaluationDates = false;
      })
  }

  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  canAddEvidence() {
    if (this.excludeStatus.includes(this.goalData.goalStatus)) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.personDetails$) {
      this.personDetails$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.getGoalData$) {
      this.getGoalData$.unsubscribe();
    }
    if (this.deleteDevelopAction$) {
      this.deleteDevelopAction$.unsubscribe();
    }
    if (this.getObjectiveOnHold$) {
      this.getObjectiveOnHold$.unsubscribe();
    }
    if (this.deletePeerFeedback$) {
      this.deletePeerFeedback$.unsubscribe();
    }
    if (this.getFinalEvaluationDates$) {
      this.getFinalEvaluationDates$.unsubscribe();
    }
  }
}