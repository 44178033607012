<page-container>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
            [title]="('cascading.CU_SIDE_TITLE' | translate)  + (personDetails?.surname ? (personDetails.surname + ' ' + personDetails.forename) : 'Mario Rossi')"
            [description]="('cascading.CU_SIDE_DESCR' | translate) + (personDetails?.surname ? (personDetails.surname + ' ' + personDetails.forename) : 'Mario Rossi')">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingData && cascadingGoal">
                <div class="form-container">
                    <!-- Testo introduttivo -->
                    <input-container [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="cascadingGoal?.introductionText && cascadingGoal.introductionText.trim().length"
                        [isObbligatory]="false" title="{{'cascading.INTRO_TEXT' | translate}}">
                        <europ-text-area [bindValue]="cascadingGoal.introductionText" [maxlength]="600"
                            (onModelChanged)="introTextChanged($event)"
                            placeholder="{{'cascading.INTRO_TEXT_PLACEHOLDER' | translate}}">
                        </europ-text-area>
                    </input-container>
                    <!-- Elenco obiettivi -->
                    <input-container [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="cascadingGoal?.description && cascadingGoal.description.trim().length"
                        [isObbligatory]="false" title="{{'cascading.OBJECTIVE_LIST' | translate}}">
                        <europ-text-area [bindValue]="cascadingGoal.description" [maxlength]="600"
                            (onModelChanged)="objectiveListChanged($event)"
                            placeholder="{{'cascading.OBJECTIVE_LIST_PLACEHOLDER' | translate}}">
                        </europ-text-area>
                    </input-container>
                    <!-- Condividi obiettivi   -->
                    <div class="button-container">
                        <europ-button (onButtonClicked)="createUpdate()"
                            [disabled]="isLoadingData || !isObbligatoryDataInserted()" type="primary default"
                            size="large" [label]="'cascading.SHARE_OBJECTIVES' | translate">
                        </europ-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingData || isLoadingPersonDetails">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un processo -->
<modal id="confirm-modal" (onCancel)="closeConfirmModal()" (onClose)="closeConfirmModal()"
    (onConfirm)="closeConfirmModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
    [confirmLabel]="'generic.CONFIRM' | translate" *ngIf="cascadingGoal">
    <modal-text-content modalId="cascShare001" [title]="'generic.CONFIRM' | translate"
        [subtitle]="'cascading.CREATE_MODAL_SUBTITLE' | translate"
        [text]="('cascading.CREATE_MODAL_TEXT_1' | translate) + ' ' + totalSubordinates + ' ' + ('cascading.CREATE_MODAL_TEXT_2' | translate)">
    </modal-text-content>
</modal>