<talent-tab-header *ngIf="selectedPerformanceYear$ | async as perfYear"
  [title]=" translationPrefix + 'EVALUATION_TITLE' | translate"
  [description]="(translationPrefix + 'EVALUATION_DESCRIPTION' | translate) + ' ' + perfYear">
</talent-tab-header>


<div class="card-container" *ngIf="talentEvaluation$ | async as evaluation; else loading">

  <div class="evaluators">

    <ng-container *ngIf="isObjectNotEmpty(evaluation.managerEvaluation)">

      <talent-evaluator-avatar *ngIf="evaluation.managerEvaluation as manager" [user]="manager.user"
        [evaluatedBy]="manager?.user | fullName" [onDate]="manager.evaluationDate | date: 'dd.MM.yyyy'"
        [withLevel]="'talent.potentialLevel.' + manager.evaluation | translate">
      </talent-evaluator-avatar>

    </ng-container>

    <ng-container *ngIf="isObjectNotEmpty(evaluation.chiefEvaluation)">

      <talent-evaluator-avatar *ngIf="evaluation?.chiefEvaluation as chief" [user]="chief.user"
        [evaluatedBy]="chief.user | fullName" [onDate]="chief.evaluationDate | date: 'dd.MM.yyyy'"
        [withLevel]="'talent.potentialLevel.' + chief.evaluation | translate">
      </talent-evaluator-avatar>

    </ng-container>

  </div>


  <div class="evaluation-answers" *ngFor="let page of evaluation.pages; let pageIdx = index">

    <h3 class="page-title"> {{ page.pageTitle }} </h3>

    <div class="question" *ngFor="let question of page.questions; let i = index">
      <p class="question-title"><span *ngIf="pageIdx === 0">{{ i + 1 }}. </span> {{question.text}}</p>
      <ng-container [ngSwitch]="question.answerType">

        <!-- Checkbox (multi) -->
        <ng-container *ngSwitchCase="'M'">
          <ul>
            <li class="answer" *ngFor="let answerIdx of question.selectedAnswersIndex">
              {{ question.answers[answerIdx]}}
            </li>
          </ul>
        </ng-container>

        <!-- Match answer type === 'E' | ' S' -->
        <ng-container *ngSwitchDefault>
          <p class="answer" *ngFor="let answerIdx of question.selectedAnswersIndex">
            {{ question.answers[answerIdx]}}
          </p>
        </ng-container>

      </ng-container>

    </div>

  </div>

  <div class="evaluation-answers">

    <h3 class="page-title">Valutazione</h3>

    <div class="question">
      <p class="question-title">Aree di forza</p>
      <p class="answer">{{ evaluation.lastPage.areasOfStrength }}</p>
    </div>

    <div class="question">
      <p class="question-title">Punti di miglioramento</p>
      <p class="answer">{{ evaluation.lastPage.pointsOfImprovement }}</p>
    </div>

    <div class="question">
      <p class="question-title">Livello di potenziale</p>
      <p class="answer">{{ 'talent.potentialLevel.' + evaluation.lastPage.potentialLevel | translate }}</p>
    </div>

    <ng-container *ngIf="isObjectNotEmpty(evaluation.chiefEvaluation)">
      <div class="question" *ngIf="evaluation.chiefEvaluation as chief">
        <p class="question-title">Livello di Potenziale modificato dal Chief {{ chief.user | fullName }}</p>
        <p class="answer">{{ 'talent.potentialLevel.' + chief.evaluation | translate }}</p>
      </div>
    </ng-container>

  </div>

</div>

<!-- Loader caricamento dati utente -->
<ng-template #loading>
  <div class="loader spinner-big"></div>
</ng-template>


<!-- Display http-error -->
<modal id="failed-to-fetch-talent-evaluation" (onClose)="closeModal()">
  <modal-text-content modalId="cal022" [title]="'errors.WARNING' | translate"
    [text]="'errors.TALENT_COULD_NOT_FETCH_EVALUATION' | translate">
  </modal-text-content>
</modal>