<div class="page-content-wrapper hide-matrix">

  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <header-dossier [yearItems]="yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
    [isTalentHeader]="true" [hasTabs]="false">
  </header-dossier>


  <!-- Ricerca -->
  <div class="search-wrapper">
    <div class="search-content">
      <div class="title-container">
        <p>{{ 'grid.EVALUATION_GRID' | translate }}</p>
      </div>
      <div class="search-btn-wrapper">
        <div class="grid-tooltip-container" (click)="openObservationGridTooltip()">
          <svg-icon src="/assets/img/icons/info.svg" class="tooltip-icon" [applyClass]="true">
          </svg-icon>
          <svg-icon src="/assets/img/icons/observation-grid.svg" class="grid-icon" [applyClass]="true">
          </svg-icon>
        </div>
        <europ-input (onIconClicked)="getUsersList()" typeInput="text" [bindValue]="searchedText"
          (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
          [placeholder]="'process.details.SEARCH_BY' | translate" [isSearchBar]="true">
        </europ-input>
        <europ-button type="primary link" size="medium" iconLeft="assets/img/icons/file/xls.svg" label="Report"
          [disabled]="isDownloadingReport" (onButtonClicked)="downloadReport()"></europ-button>
      </div>
    </div>
  </div>

  <div class="main-content-wrapper">
    <ng-container *ngIf="isLoadingUsers">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLoadingUsers">

      <div class="matrix-lock">
        <svg-icon src="assets/img/icons/lock.svg" class="locker" [applyClass]="true"></svg-icon>
      </div>

      <ng-container *ngIf="noData">
        <div class="center-container">
          <p translate="calibration.NO_VALID_USER"></p>
        </div>
      </ng-container>
      <ng-container *ngIf="!noData">
        <!--ninebox-calibration-->
        <div class="ninebox-calibration-wrapper">
          <div class="boxes-wrapper">

            <ng-container *ngFor="let rowKey of keys">
              <div class="columns-wrapper">
                <ng-container *ngFor="let column of matrixData[rowKey]; let index = index;">
                  <ng-container *ngIf="column.id == 'label'">
                    <div class="row-label">
                      <p>{{ column.title }}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column.id != 'label'">
                    <!-- Box dati [ngClass]="{'box-1': row == 'UNSATISFACTORY' || row == 'IMPROVABLE', 'box-2': row == 'MEET', 'box-3': row == 'EXCEEDED' || row == 'EXCEPTIONAL'}"-->
                    <div class="box-ninebox" [ngStyle]="{'background-color': column.color}"
                      cdkDropListOrientation="vertical" cdkDropList (cdkDropListDropped)="drop($event,rowKey, index)"
                      [cdkDropListData]="column.list" [cdkDropListConnectedTo]="getConnectedList(rowKey, index)"
                      id="{{ column.cellId }}">
                      <!-- Placeholder nessun utente-->
                      <ng-container *ngIf="!column.list || !column.list.length">
                        <p class="no-data-box" translate="calibration.NO_ONE_PRESENT"></p>
                      </ng-container>
                      <!-- Contenitore lista tag -->
                      <!-- cdkDropListOrientation="vertical" cdkDropList
                        (cdkDropListDropped)="drop($event,rowKey, index)" [cdkDropListData]="column.list"
                        [cdkDropListConnectedTo]="getConnectedList(rowKey, index)" id="{{ column.cellId }}"
                      -->
                      <div class="tag-action-wrapper">
                        <ng-container *ngIf="column.list && column.list.length">
                          <div class="tag-action-container" *ngFor="let user of column.list" cdkDrag
                            [cdkDragData]="user" (cdkDragStarted)="setSelected(user)"
                            [ngClass]="{'disabled': user.isTalentPotentialConfirmedByChief}">
                            <!-- Tag utente -->
                            <div id="{{ user.userId }}" class="tag-user" [ngClass]="{'new': user.edited}">
                              <avatar-img [user]="user" size="small" [hideNew]="true"></avatar-img>
                              <p class="name" (click)="openNoteListModal(user)">{{ user.forename + ' ' + user.surname }}
                              </p>
                              <!-- drag matrice-->
                              <ng-container *ngIf="!user.isTalentPotentialConfirmedByChief">
                                <svg-icon src="assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                                  [applyClass]="true" cdkDragHandle>
                                </svg-icon>
                              </ng-container>
                              <!-- locked -->
                              <ng-container *ngIf="user.isTalentPotentialConfirmedByChief">
                                <svg-icon src="assets/img/icons/lock.svg" class="info-icon medium-size cursor-pointer"
                                  [applyClass]="true">
                                </svg-icon>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <!-- Più utenti -->
                      <p class="more-tag" *ngIf="column.more && column.more.length"
                        (click)="openMore(column, rowKey, index)">
                        {{ '+ ' + column.more.length}}</p>

                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <!-- Contenitore + utenti / Altri utenti-->
              <div class="more" *ngIf="currentMoreData && currentMoreData.rowId == rowKey && currentMoreData.isOpen">
                <div class="title-container">
                  <p class="title">{{ currentMoreData.title }}</p>
                  <svg-icon class="close-icon" [applyClass]="true" src="/assets/img/icons/x.svg">
                  </svg-icon>
                </div>
                <div class="more-content" cdkDropList
                  (cdkDropListDropped)="drop($event,rowKey, currentMoreData.columnIndex)"
                  [cdkDropListData]="currentMoreData.more"
                  [cdkDropListConnectedTo]="getConnectedList(rowKey, currentMoreData.columnIndex)"
                  id="{{ 'more-' + currentMoreData.id }}">
                  <ng-container *ngIf="currentMoreData && currentMoreData.list.length">
                    <div class="tag-action-container" *ngFor="let user of currentMoreData.list" cdkDrag
                      [cdkDragData]="user" (cdkDragStarted)="setSelected(user)"
                      [ngClass]="{'disabled': user.isTalentPotentialConfirmedByChief}">
                      <!-- Tag utente -->
                      <div id="{{ user.userId }}" class="tag-user" [ngClass]="{'new': user.edited}">
                        <avatar-img [user]="user" size="small" [hideNew]="true"></avatar-img>
                        <p class="name">{{ user.forename + ' ' + user.surname }}</p>
                        <ng-container *ngIf="!user.isTalentPotentialConfirmedByChief">
                          <svg-icon src="assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                            [applyClass]="true" cdkDragHandle>
                          </svg-icon>
                        </ng-container>
                        <ng-container *ngIf="user.isTalentPotentialConfirmedByChief">
                          <svg-icon src="assets/img/icons/lock.svg" class="info-icon medium-size cursor-pointer"
                            [applyClass]="true">
                          </svg-icon>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>

              </div>

            </ng-container>


          </div>
          <div class="levels-wrapper">
            <div class="box-level placeholder">
              <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                (click)="openMatrixTooltip()">
              </svg-icon>
            </div>
            <div class="box-level">
              <p translate="evaluations.MEET"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.EXCEEDED"></p>
            </div>
            <div class="box-level">
              <p translate="evaluations.EXCEPTIONAL"></p>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="action-container">
    <!-- Salva modifiche -->
    <europ-button [label]="'generic.SAVE_EDIT' | translate" type="primary" (onButtonClicked)="saveEvaluationGrid()">
    </europ-button>
    <!-- Conferma -->
    <europ-button [label]="'generic.CONFIRM' | translate" type="secondary" (onButtonClicked)="openConfimModal()">
    </europ-button>
  </div>
</div>

<div class="unusable">
  <div class="page-content">
    <svg-icon class="logo-container" [applyClass]="true" src="assets/img/icons/app-logo.svg">
    </svg-icon>
    <p>
      <span class="small-font-weight" translate="404.WE_ARE_SORRY"></span><br>
      <span translate="genericError.PAGE_NOT_USABLE" class="bold-text"></span>
    </p>
    <div>
      <p translate="genericError.MATRIX_ERROR"></p>
    </div>
    <div class="back-button-container">
      <europ-button type="primary default" size="large" label="{{'generic.GO_BACK' | translate}}"
        (onButtonClicked)="redirectService.goBackBrowser()"></europ-button>
    </div>
  </div>
</div>



<!-- Tooltip griglia pagina intera -->
<modal id="grid-tooltip" [fullPage]="true" (onClose)="closeObservationGridTooltip()"
  (onConfirm)="closeObservationGridTooltip()" [confirmLabel]="'generic.CLOSE' | translate">
  <p class="modal-title center">
    {{ ('grid.dowels.TITLE' | translate)}}</p>
  <div class="mapwrapper">
    <div class="row">
      <div class="card card1">
        <div class="header">
          <h3 translate="grid.dowels.1_TITLE"></h3>

        </div>
        <div class="body">
          <p [innerHTML]="'grid.dowels.1_DESCR' | translate"></p>
        </div>
      </div>
      <div class="card card2">
        <div class="header">
          <h3 translate="grid.dowels.2_TITLE"></h3>

        </div>
        <div class="body">
          <p [innerHTML]="'grid.dowels.2_DESCR' | translate"></p>
        </div>
      </div>
    </div>
    <div class="card card3">
      <div class="row">
        <div class="card card3">
          <div class="header">
            <h3 translate="grid.dowels.3_TITLE"></h3>
          </div>
          <div class="body">
            <p [innerHTML]="'grid.dowels.3_DESCR' | translate"></p>
          </div>
        </div>
        <div class="card card4">
          <div class="header">
            <h3 translate="grid.dowels.4_TITLE"></h3>

          </div>
          <div class="body">
            <p [innerHTML]="'grid.dowels.4_DESCR' | translate"></p>
          </div>
        </div>
      </div>
    </div>

    <svg-icon class="puzzle" src="assets/img/icons/puzzle.svg" [applyClass]="true">
    </svg-icon>

  </div>
</modal>

<!-- Tooltip matrice -->
<modal id="matrix-tooltip" (onClose)="closeMatrixTooltip()" (onConfirm)="closeMatrixTooltip()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content modalId="cevg000" [title]="'grid.modals.EVALUATION_GRID' | translate"
    [subtitle]="('grid.modals.EVALUATION_GRID_SUB' | translate)"
    [text]="'grid.modals.EVALUATION_GRID_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- conferma -->
<modal id="confirm-modal" (onClose)="closeConfirmModal()" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeConfirmModal()" [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeConfirmModal(true)">
  <modal-text-content modalId="cevg000" [title]="'grid.modals.CONFIRM_GRID_CHIEF_TITLE' | translate"
    [subtitle]="('grid.modals.CONFIRM_GRID_CHIEF_TITLE' | translate) + ' ' + selectedYear.id"
    [text]="'grid.modals.CONFIRM_GRID_CHIEF_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale inserisci annotazioni -->
<modal id="insert-note" (onClose)="closeInsertNote()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="closeInsertNote(true)" [isDisabled]="!modalNote || !modalNote.trim().length">
  <p class="modal-title">
    {{ ('talent.modals.INSERT_NOTE_TITLE' | translate)}}</p>
  <div class="confirmation-container">
    <!--  Inserisci note -->
    <input-container [numberCharsTextArea]="charsNote" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
      [isValued]="modalNote && modalNote.trim().length" [title]="'talent.modals.INSERT_NOTE_INPUT' | translate">
      <europ-text-area [bindValue]="modalNote" [maxlength]="600" (onModelChanged)="modalNoteChanged($event)"
        [placeholder]="'talent.modals.INSERT_NOTE_INPUT_PLACEHOLDER' | translate">
      </europ-text-area>
    </input-container>
    <!-- Upload documento -->
    <ng-container *ngIf="showFile">
      <input-container [isWithoutInfoIcon]="true" [isValued]="fileObject"
        [title]="'talent.modals.INSERT_NOTE_DOC' | translate">
        <europ-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"></europ-upload>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Modale con elenco note per utente -->
<modal id="note-list" (onClose)="closeNoteListModal()"
  (onConfirm)="openInsertNote(noteListModalData && noteListModalData.user)"
  [confirmLabel]="'talent.modals.INSERT_NOTE_ACTION' | translate">
  <ng-container *ngIf="noteListModalData && noteListModalData.user">
    <p class="modal-title" translate="talent.modals.NOTE_LIST_TITLE"></p>
    <p class="modal-subtitle"> {{ ('talent.modals.NOTE_LIST_DESCR' | translate) +
      noteListModalData.user.surname +
      ' ' + noteListModalData.user.forename}}</p>
    <div class="note-modal-container">
      <ng-container *ngIf="!isLoadingNoteList">
        <ng-container *ngIf="!noteListModalData || !noteListModalData.list || !noteListModalData.list.length">
          <p class="no-data-placeholder" translate="grid.modals.NO_NOTES"></p>
        </ng-container>
        <ng-container *ngIf="noteListModalData && noteListModalData.list && noteListModalData.list.length">
          <ng-container *ngFor="let note of noteListModalData.list">
            <div class="note-container">
              <svg-icon src="assets/img/icons/message-square.svg" [applyClass]="true" class="note-modal-icon">
              </svg-icon>
              <div class="note-content">
                <div class="date-delete-container">
                  <p class="date">{{('talent.modals.NOTE_INSERT' | translate) + note.insertDate}}</p>
                  <svg-icon src="assets/img/icons/trash-2.svg" [applyClass]="true" class="note-modal-icon pointer"
                    (click)="openDeleteNoteModal(note)">
                  </svg-icon>
                </div>
                <p class="title">{{ 'talent.modals.NOTE' | translate }}</p>
                <p class="content">{{ note.note }}</p>
                <p class="created-by">{{('talent.modals.CREATED_BY' | translate) + note.authorSurname + ' '
                  +
                  note.authorForename}}</p>
                <ng-container *ngIf="note.files && note.files.length">
                  <div class="file-list-container">
                    <ng-container *ngFor="let file of note.files">
                      <list-doc-item [canDoActions]="false" id="file.uploadId"
                        srcIcon="assets/img/icons/file/{{getExtensionString(file.fileName)}}.svg"
                        [fileName]="file.fileName" (onDownload)="downloadLinkedFile(file)">
                      </list-doc-item>
                    </ng-container>

                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoadingNoteList">
        <div class="loader spinner-medium"></div>
      </ng-container>
    </div>
  </ng-container>
</modal>

<!-- Conferma eliminazione nota -->
<modal id="confirm-delete-note" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeDeleteNoteModal()"
  (onClose)="closeDeleteNoteModal()" (onConfirm)="closeDeleteNoteModal(true)"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content modalId="deln003" [title]="'talent.modals.CONFIRM_DELETE_NOTE_TITLE' | translate"
    [text]="'talent.modals.CONFIRM_DELETE_NOTE_DESCR' | translate">
  </modal-text-content>
</modal>