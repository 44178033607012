/*
 * Gestione dei dispatch che devono eseguire qualcosa di asincrono e che non alterano lo State (i così chiamati Effect). Struttura molto simile ai Reducer,
 * che verificano il tipo di azione e concatenano una serie di operatori degli Observable per fare qualcosa. L'unica differenza, appunto, è che qui non
 * andiamo a modificare lo State, gestiamo solo lo Side Effect
*/

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as AdminActions from "../ngrx/admin.actions";


@Injectable()
export class AdminEffects {

  constructor(private actions$: Actions) {
  }

  storeSelectedCandidate$ = createEffect(() => this.actions$.pipe(
    ofType(AdminActions.SetCandidate),
    mergeMap(({candidate}) => {
      sessionStorage.setItem('selectedCandidate', JSON.stringify(candidate));
      return of(AdminActions.SetCandidate({candidate}));
    })
  ), {dispatch: false})


  storeProcessYear$ = createEffect(() => this.actions$.pipe(
    ofType(AdminActions.SetProcessYear),
    mergeMap(({processYear}) => {
      sessionStorage.setItem('processYear', JSON.stringify(processYear));
      return of(AdminActions.SetProcessYear({processYear}));
    })
  ), {dispatch: false})



}
