import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DossierMenuButton } from 'src/app/shared/components/header-dossier/header-dossier.component';
import { MenuOptionId } from '../../talentScoutingUsers/talentScouting.component';
import { PotentialTalentsService } from 'src/app/shared/services/potential-talents.service';
import { GetUserForTalentInterviewResponse } from 'atfcore-commonclasses';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';


type Tab = Exclude<MenuOptionId, 'handle-status'>;

// Restrict id to be only of type Tab
type MenuButton = Exclude<DossierMenuButton, 'id'> & { id: Tab };


@Component({
  selector: 'app-person-potential-details',
  templateUrl: './person-potential-details.component.html',
  styleUrls: ['./person-potential-details.component.scss']
})
export class PersonPotentialDetailsComponent implements OnInit {

  private modalId: string = 'failed-to-retrieve-candidate-details';

  selectedCandidate$!: Observable<GetUserForTalentInterviewResponse>;

  tabs$!: Observable<MenuButton[]>;

  headerDossier$!: Observable<{ candidate: GetUserForTalentInterviewResponse, tabs: MenuButton[] }>;

  processYear$: Observable<number> = this.route.params.pipe(
    map(params => params['processYear'] ? parseInt(params['processYear']) : new Date().getFullYear())
  );

  candidateId$: Observable<string> = this.route.params.pipe(
    map(params => params['userId'])
  );
  selectedYear: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public redirectService: RedirectService,
    private potentialTalentSvc: PotentialTalentsService,
    public modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.getTranslatedTabNames();

    this.selectedCandidate$ = combineLatest([
      this.candidateId$,
      this.processYear$
    ]).pipe(
      switchMap(([candidateId, processYear]) =>
        this.potentialTalentSvc.getTalentDetails(candidateId, processYear)
      ),
      switchMap(({ error, response }) => {
        if (error) {
          return throwError(error);
        }
        return of(response);
      }),
      catchError(err => {
        console.error('[person-potential-details]', err);
        this.modalService.open(this.modalId)
        this.router.navigate(['../'], { relativeTo: this.route });
        return EMPTY;
      })
    )

    this.headerDossier$ = combineLatest([
      this.selectedCandidate$,
      this.tabs$
    ]).pipe(
      map(([candidate, tabs]) => ({ candidate, tabs }))
    )

  }



  onTabChange(tab: Tab) {
    this.router.navigate([tab], { relativeTo: this.route, replaceUrl: true });
  }

  private getTranslatedTabNames() {
    this.tabs$ = this.translate.get([
      'talent.tabs.OBSERVATION_GRID',
      'talent.tabs.INTERVIEW',
      'talent.tabs.DEVELOPMENT_PLAN'
    ]).pipe(
      withLatestFrom(this.processYear$),
      map(([translations, year]): MenuButton[] => {
        return [
          {
            id: 'evaluation',
            name: `${translations['talent.tabs.OBSERVATION_GRID']} ${year}`,
          },
          {
            id: 'interview',
            name: translations['talent.tabs.INTERVIEW'],
          },
          {
            id: 'development-plan',
            name: translations['talent.tabs.DEVELOPMENT_PLAN'],
          }
        ]
      })
    )
  }


  closeModal() {
    this.modalService.close(this.modalId);
  }

  goToUserList() {
    this.route.params.subscribe((params) => {
      this.router.navigate(['admin/talent/' + params.processYear])
    })
  }

}
