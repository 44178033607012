<div id="snackbar">
  <p translate="generic.SAVE_SUCCESSFUL"></p>
</div>


<ng-container>
  <div class="interview-header">
    <talent-tab-header [title]="translationPrefix + 'TITLE' | translate"
      [description]="translationPrefix + 'DESCRIPTION' | translate">
    </talent-tab-header>
  </div>

  <div style="margin-top: 3em">

    <ng-container *ngTemplateOutlet="postInterviewTargets"></ng-container>

    <ng-container *ngIf="selectedUserId">
      <div class="development-action" *ngFor="let devPlan of developmentPlans">
        <card-action [object]="devPlan" [isDevelopmentPlan]="true" [loggedUserId]="selectedUserId" [isManager]="true"
          [isCollaborator]="true" (onReadComment)="openReadCommentModal($event)"
          (onDeleteComment)="deleteComment($event)" (onEditComment)="openAddOrEditCommentModal($event, 'edit')"
          (onInsertCommentToDevelopAction)="openAddOrEditCommentModal($event, 'add')"
          (onDeleteAction)="openDeleteActionModal($event)" (onEditAction)="navigateToEditPage($event)"
          (onUpdateStatusToDevelopAction)="openUpdateActionStatusModal($event)">
        </card-action>
      </div>
    </ng-container>
  </div>

  <div id="add-action-container">
    <europ-button [label]="'developmentPlan.ADD_ACTION' | translate" type="primary" (onButtonClicked)="addAction()">
    </europ-button>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loader spinner-big"></div>
</ng-template>

<!-- POST-INTERVIEW TARGETS -->
<ng-template #postInterviewTargets>
  <div class="card-container">
    <div class="header-type-container">
      <div class="type-container">
        <div class="type-container-text-icon">
          <svg-icon src="assets/img/icons/target.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
          <p>{{ 'feedbackType.INTERVIEW_TARGETS' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="divisor-line"></div>
    <div class="card-body">


      <!-- ROLE GOAL -->
      <input-container [isWithoutInfoIcon]="true" [title]="'talent.development-plan-tab.ROLE_TARGETS' | translate">
        <europ-text-area (onModelChanged)="roleGoal.value = $event"
          [placeholder]="'talent.development-plan-tab.TARGET_PLACEHOLDER' | translate" [bindValue]="roleGoal.value">
        </europ-text-area>

        <div class="actions">
          <europ-button (onButtonClicked)="deleteRoleGoal()" size="medium" type="primary link"
            [disabled]="roleGoal.value.length === 0" [label]="'generic.DELETE' | translate">
          </europ-button>

          <europ-button (onButtonClicked)="saveRoleGoal()" size="medium" type="primary link"
            [disabled]="roleGoal.value.length === 0 || originalRoleValue === roleGoal.value"
            [label]="'generic.SAVE' | translate"></europ-button>
        </div>
        <!-- <ng-container
          *ngTemplateOutlet="roleGoal.inEditMode ? roleEditMode : roleReadMode;context: { $implicit: roleGoal.value }">
        </ng-container> -->
      </input-container>

      <!--ng-template #roleEditMode>
        <europ-text-area (onModelChanged)="roleGoal.value = $event"
          [placeholder]="'talent.development-plan-tab.TARGET_PLACEHOLDER' | translate" [bindValue]="roleGoal.value">
        </europ-text-area>

        <div class="actions">
          <europ-button (onButtonClicked)="toggleRoleGoalMode()" size="medium" type="primary link"
            [disabled]="roleGoal.isNew === true || roleGoal.value.length === 0" [label]="'generic.CANCEL' | translate">
          </europ-button>

          <europ-button (onButtonClicked)="saveRoleGoal()" size="medium" type="primary link"
            [disabled]="roleGoal.value.length === 0" [label]="'generic.SAVE' | translate"></europ-button>
        </div>
      </ng-template>

      <ng-template #roleReadMode let-text>
        <p class="break-line">{{ text }}</p>

        <europ-button (onButtonClicked)="toggleRoleGoalMode()" [label]="'generic.EDIT' | translate"
          iconLeft="assets/img/icons/edit.svg" size="medium" type="primary link"></europ-button>
      </ng-template-->

      <!-- DEVELOPMENT GOAL -->
      <input-container class="m" [isWithoutInfoIcon]="true"
        [title]="'talent.development-plan-tab.DEVELOPMENT_TARGETS' | translate">
        <!--ng-container *ngTemplateOutlet="
            developmentGoal.inEditMode ? develEditMode : develReadMode;context: { $implicit: developmentGoal.value }">
        </ng-container-->
        <europ-text-area (onModelChanged)="developmentGoal.value = $event"
          [placeholder]="'talent.development-plan-tab.TARGET_PLACEHOLDER' | translate"
          [bindValue]="developmentGoal.value"></europ-text-area>

        <div class="actions">
          <europ-button (onButtonClicked)="deleteDevelopmentGoal()" size="medium" type="primary link"
            [disabled]="developmentGoal.value.length === 0" [label]="'generic.DELETE' | translate"></europ-button>
          <europ-button (onButtonClicked)="saveDevelopmentGoal()" size="medium" type="primary link"
            [disabled]="developmentGoal.value.length === 0 || originalGoalValue === developmentGoal.value"
            [label]="'generic.SAVE' | translate"></europ-button>
        </div>
      </input-container>

      <!--ng-template #develEditMode>

      </ng-template>

      <ng-template #develReadMode let-text>
        <p class="break-line">{{ text }}</p>

        <europ-button (onButtonClicked)="toggleDevelopmentGoalMode()" [label]="'generic.EDIT' | translate"
          iconLeft="assets/img/icons/edit.svg" size="medium" type="primary link"></europ-button>
      </ng-template-->
    </div>
  </div>
</ng-template>




<!-- SPINNER -->
<ng-template #loading>
  <div class="loader spinner-big"></div>
</ng-template>

<!-- ########################### MODALS #################### -->


<!-- Failed to add comment to action -->
<modal [id]="failedToAddCommentModalId" (onClose)="closeFailedToAddCommentModal()">
  <modal-text-content modalId="devplan001" [title]="'errors.WARNING' | translate"
    [text]="'talent.modals.COULD_NOT_ADD_NOTE_TO_ACTION' | translate">
  </modal-text-content>
</modal>



<!-- Failed to delete action -->
<modal [id]="failedToDeleteActionModalId" (onClose)="closeModal(failedToDeleteActionModalId)">
  <modal-text-content modalId="devplan006" [title]="'errors.WARNING' | translate"
    [text]="'talent.modals.FAILED_TO_DELETE_ACTION' | translate">
  </modal-text-content>
</modal>


<!-- ADD OR EDIT COMMENT MODAL -->
<modal [id]="addOrEditCommentModalId" (onClose)="onCloseAddOrEditComment()" (onCancel)="onCloseAddOrEditComment()"
  (onConfirm)="onConfirmAddOrEditCommentModal()" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="(selectedActionCommentMode === 'add' ? 'talent.modals.INSERT_NOTE_ACTION' : 'talent.development-plan-tab.EDIT_NOTE') | translate">
  <modal-text-content modalId="devplan002"
    [title]="(selectedActionCommentMode === 'add' ? 'talent.development-plan-tab.ADD_NOTE' : 'talent.development-plan-tab.EDIT_NOTE') | translate"
    [text]="'talent.development-plan-tab.OVERVIEW' | translate">
  </modal-text-content>

  <ng-container *ngTemplateOutlet="actionOverview; context: { $implicit: selectedAction }"></ng-container>

  <input-container class="margin-bottom-1" [isWithoutInfoIcon]="true"
    [title]="(selectedActionCommentMode === 'add' ? 'talent.development-plan-tab.ADD_NOTE' : 'talent.development-plan-tab.EDIT_NOTE') | translate">
    <europ-text-area (onModelChanged)="selectedActionCommentText = $event" [bindValue]="selectedActionCommentText">
    </europ-text-area>
  </input-container>

</modal>



<!-- UPDATE ACTION STATUS MODAL -->
<modal [id]="updateActionStatusModalId" (onClose)="onCloseUpdateActionStatus()" (onCancel)="onCloseUpdateActionStatus()"
  (onConfirm)="onConfirmUpdateActionStatus()" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'developmentPlan.UPDATE_STATUS_ACTION' | translate">
  <modal-text-content modalId="devplan003" [title]="'developmentPlan.UPDATE_STATUS_MODAL_TITLE' | translate"
    [text]="'talent.development-plan-tab.OVERVIEW' | translate">
  </modal-text-content>

  <ng-container *ngTemplateOutlet="actionOverview; context: { $implicit: selectedAction }"></ng-container>

  <input-container class="margin-bottom-2 full-width" [isWithoutInfoIcon]="true"
    [title]="'developmentPlan.UPDATE_STATUS_MODAL_TITLE' | translate">
    <europ-select bindLabel="value" (onSelectedItem)="selectedActionStatus = $event" [items]="actionStatuses"
      [selectedItem]="selectedAction?.status"></europ-select>
  </input-container>

</modal>


<!-- READ COMMENT MODAL -->
<modal [id]="readCommentModalId" (onClose)="onCloseReadCommentModal()" (onConfirm)="onCloseReadCommentModal()"
  [confirmLabel]="'generic.CLOSE' | translate">

  <modal-text-content modalId="devplan004" [title]="'talent.development-plan-tab.READ_NOTE' | translate"
    [text]="('talent.development-plan-tab.NOTE_INSERTED_BY' | translate) + ' ' + (selectedActionComment?.creationUser| fullName)">
  </modal-text-content>

  <p class="margin-bottom-1">{{ selectedActionComment?.text }}</p>

</modal>



<!-- DELETE ACTION MODAL -->
<modal [id]="confirmDeleteActionModalId" (onClose)="closeModal(confirmDeleteActionModalId)"
  (onConfirm)="onConfirmDeleteActionModal()" [confirmLabel]="'generic.CONFIRM' | translate">

  <modal-text-content modalId="devplan005" [title]="'errors.WARNING' | translate"
    [text]="('talent.development-plan-tab.DELETE_ACTION' | translate)">
  </modal-text-content>

</modal>








<ng-template #actionOverview let-action>
  <div class="comment-overview">
    <div>
      <strong><em class="text-gray">{{ 'talent.development-plan-tab.ACTIVITY_NAME' | translate}}: </em></strong>
      <span class="text-gray">{{ action?.idp?.developmentAction }}</span>
    </div>

    <div>
      <strong><em class="text-gray">{{ 'talent.development-plan-tab.ACTIVITY_PERIOD' | translate}}: </em></strong>
      <span class="text-gray">{{ action?.idp?.date }}</span>
    </div>


    <div>
      <strong><em class="text-gray">{{ 'talent.development-plan-tab.ACTIVITY_DURATION' | translate}}: </em></strong>
      <span class="text-gray">{{ action?.idp?.duration }}</span>
    </div>


    <div>
      <strong><em class="text-gray">{{ 'talent.development-plan-tab.ACTIVITY_TYPE' | translate}}: </em></strong>
      <span class="text-gray">{{ action?.idp?.type }}</span>
    </div>


    <div>
      <strong><em class="text-gray">{{ 'talent.development-plan-tab.ACTIVITY_REFERENT' | translate}}: </em></strong>
      <span class="text-gray">{{ action?.idp?.referentName }}</span>
    </div>


    <div>
      <strong><em class="text-gray">{{ 'talent.development-plan-tab.ACTIVITY_DESCRIPTION' | translate}}: </em></strong>
      <span class="text-gray">{{ action?.idp?.description }}</span>
    </div>

  </div>
</ng-template>